<template>
  <div>
    <div v-if="loaded" class="filterButtonPosition">
      <f7-button
        fill
        class="whiteFontColor button-filter"
        panel-toggle="right"
      >
        <span class="ic-filter"></span> {{ $t('filter') }}
      </f7-button>
    </div>

    <div v-if="!loaded" class="loadingFalse">
      <div class="preloader">
        <span class="preloader-inner">
          <span class="preloader-inner-circle"></span>
        </span>
      </div>
    </div>
    <div v-else>
      <DxDataGrid
        :data-source="store"
        :show-borders="true"
        :allow-column-reordering="false"
        :column-auto-width="true"
        :show-colon="false"
        :hover-state-enabled="true"
        :show-row-lines="true"
        :remote-operations="true"
        :row-alternation-enabled="true"
      >
        <DxScrolling mode="standard" />
        <DxStateStoring
          :enabled="false"
          type="localStorage"
          storage-key="nutritionalFollowingTable"
        />
        <DxPaging :page-size="10" />
        <DxPager
          :show-page-size-selector="true"
          :allowed-page-sizes="[5, 10, 20]"
          :show-info="true"
          info-text="{2} items"
          :visible="true"
        />
        <DxSearchPanel
          :visible="true"
          :width="300"
          :placeholder="$t('search')"
        />
        <DxExport :enabled="false" />
        <DxColumn
          data-field="farm"
          :caption="$t('agronomySample.summary.estate')"
          alignment="left"
          :allow-header-filtering="false"
        />
        <DxColumn
          data-field="parcel"
          :caption="$t('agronomySample.summary.parcel')"
          alignment="left"
          :allow-header-filtering="false"
        />

        <DxColumn
          data-field="cultive"
          :caption="$t('agronomySample.cultive')"
          alignment="left"
          :allow-header-filtering="false"
        />
        <DxColumn
          data-field="variety"
          :caption="$t('variety')"
          alignment="left"
          :allow-header-filtering="false"
        />
        <DxColumn
          :caption="$t('lastReceipt')"
          alignment="left"
          data-field="sampling_date"
          data-type="date"
          format="dd/MM/yyyy"
          :allow-header-filtering="false"
        />

        <DxColumn
          :buttons="editButtons"
          :fixed="true"
          fixed-position="right"
          type="buttons"
          width="80"
        />
      </DxDataGrid>
    </div>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxStateStoring,
  DxPager,
  DxPaging,
  DxScrolling,
  DxExport,
} from 'devextreme-vue/data-grid';
import { mapActions } from 'vuex';

export default {
  name: 'NutritionalFollowingMainTable',
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxStateStoring,
    DxPager,
    DxPaging,
    DxScrolling,
    DxExport,
  },
  props: {
    store: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loaded: true,
      data: [],
      editButtons: [
        {
          icon: 'find',
          onClick: this.viewNutritionalMonitoring,
        },
      ],
    };
  },
  methods: {
    viewNutritionalMonitoring(e) {
      this.setRedirectedFromSamePage(true);
      this.setReportSampleId(e.row.data.sample_id);
      this.setParcelId(e.row.data.parcel_id);
      this.setParcelStr(e.row.data.parcel);
      this.setFarmId(e.row.data.farm_id);
      this.setVarietyId(e.row.data.variety_id);
      this.setCultiveId(e.row.data.cultive_id);
      this.setSamplingDateProfile(e.row.data.sampling_date);
      this.setId(e.row.data.id);

      this.$f7router.navigate(
        { name: 'NutritionalMonitoringPage' },
        {
          animate: true,
          transition: 'f7-fade',
        }
      );
    },
    ...mapActions('samples', ['setReportSampleId', 'getReport']),
    ...mapActions('nutritionalFollowing', [
      'getNutritionalMonitoringWithoutGeometry',
      'setRedirectedFromSamePage',
    ]),
    ...mapActions('nutritionalMonitoring', [
      'setParcelId',
      'setVarietyId',
      'setCultiveId',
      'setSamplingDateProfile',
      'setId',
    ]),
    ...mapActions('registerFarm', ['setParcelStr', 'setFarmId']),
  },
};
</script>
