/* ISO 639-1 used to name files. 
   In general we don't add specificity ("en-US") just if
   we have more then one "en" in this case.
*/

import en from './en.json';
import es from './es.json';
import fr from './fr.json';
import pt from './pt.json';
import it from './it.json';

export const defaultLocale = 'es';
export const locales = {
  en,
  es,
  fr,
  pt,
  it,
};

export const whiteList = Array.from(Object.keys(locales));
