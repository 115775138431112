export default {
  namespaced: true,
  state: {
    selectedFarms: [],
    selectedCultives: [],
    selectedParcels: [],
    selectedVarieties: [],
  },
  actions: {
    setSelectedFarms({ commit }, value) {
      commit('setSelectedFarms', value);
    },
    setSelectedCultives({ commit }, value) {
      commit('setSelectedCultives', value);
    },
    setSelectedParcels({ commit }, value) {
      commit('setSelectedParcels', value);
    },
    setSelectedVarieties({ commit }, value) {
      commit('setSelectedVarieties', value);
    },
    clearFilters({ commit }) {
      commit('clearFilters');
    },
  },
  mutations: {
    setSelectedFarms(state, value) {
      state.selectedFarms = value;
    },
    setSelectedCultives(state, value) {
      state.selectedCultives = value;
    },
    setSelectedParcels(state, value) {
      state.selectedParcels = value;
    },
    setSelectedVarieties(state, value) {
      state.selectedVarieties = value;
    },
    clearFilters(state) {
      state.selectedParcels = [];
      state.selectedFarms = [];
      state.selectedCultives = [];
      state.selectedVarieties = [];
    },
  },
};
