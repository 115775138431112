import Api from '@/services/Api';
import EventBus from '@/services/event-bus';

export default {
  namespaced: true,
  state: {
    reports: null,
    pendingNotifications: false,
  },
  actions: {
    async getReports({ rootState, commit }, { all, params }) {
      try {
        const xhr = await Api.getNotificationsReports(
          rootState.contact.company,
          rootState.contact.address,
          all,
          params
        );

        const response = JSON.parse(xhr.response);

        commit('setReports', response);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getReportsNoSet({ rootState }) {
      try {
        const xhr = await Api.getNotificationsReports(
          rootState.contact.company,
          rootState.contact.address,
          true,
          null
        );

        const response = JSON.parse(xhr.response);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async deleteReport({ dispatch }, { company, address, id }) {
      try {
        await Api.deleteNotificationReport(id, company, address);
        dispatch('deleteFromSource', id);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getAttributeList({ rootState }, attribute) {
      try {
        const xhr = await Api.getNotificationsDetailAttributeList(
          attribute,
          rootState.contact.company,
          rootState.contact.address
        );

        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async setNotificationAsSeen({ rootState, dispatch }, id) {
      try {
        await Api.setNotificationAsSeen(
          id,
          rootState.contact.company,
          rootState.contact.address
        );

        dispatch('setReportAsSeen', id);

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    setReportAsSeen({ state, dispatch }, id) {
      state.reports = state.reports.map((report) => {
        if (report.id === id) {
          report.been_seen = true;
        }

        return report;
      });

      const everyReportHasBeenSeen = state.reports.every((report) => {
        return report.been_seen;
      });

      if (everyReportHasBeenSeen) {
        dispatch('setPendingNotifications', false);
      }
    },
    callWithFilters({ state }, params) {
      EventBus.$emit('NotificationsDetails', params);
    },
    clearFilteredDataDetails({ state }, params) {
      EventBus.$emit('NotificationsDetails', params);
    },
    deleteFromSource({ state, commit }, id) {
      const filteredReports = state.reports.filter(
        (report) => report.id !== id
      );

      commit('setReports', filteredReports);
    },
    setPendingNotifications({ commit }, value) {
      commit('setPendingNotifications', value);
    },
  },
  mutations: {
    setReports(state, value) {
      state.reports = value;
    },
    setPendingNotifications(state, value) {
      state.pendingNotifications = value;
    },
  },
};
