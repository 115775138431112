<template>
  <div>
    <div v-if="!loaded" class="loadingFalse tableContainer">
      <div class="preloader">
        <span class="preloader-inner">
          <span class="preloader-inner-circle"></span>
        </span>
      </div>
    </div>
    <div v-else>
      <weather :title="station" wind-units="m/s" :current-day="currentDay" :forecast="forecast">
      </weather>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import format from 'date-fns/format';
import { defaultLocale } from '@/i18n/index';
import { NOTIFY_TIME_LONG } from '@/js/constants';
import weather from './weather/vue-weather-component.vue';

export default {
  name: 'WeatherForecast',
  components: {
    weather,
  },
  data() {
    return {
      currentDay: null,
      forecast: [],
      loaded: false,
      data: null,
      ocultar: false
    };
  },
  computed: {
    getLanguage() {
      return this.enabled
        ? this.userProfile?.language || defaultLocale
        : defaultLocale;
    },
    ...mapState('contact', ['enabled']),
    ...mapGetters('userProfile', ['language']),
    ...mapState('weather', ['station']),
  },
  async beforeMount() {
    this.loaded = false;
    try {
      const data = await this.getWidgetData({
        initDate: format(
          new Date().setDate(new Date().getDate() - 1),
          'yyyy-MM-dd HH:mm:ss'
        ),
        endDate: format(
          new Date().setDate(new Date().getDate() + 7),
          'yyyy-MM-dd HH:mm:ss'
        ),
      });

      this.currentDay = {
        icon: data[0].name.ICON[0].value.icon,
        code: data[0].name.ICON[0].value.code,
        temp: data[0].name.TEMPERATURE[0].value,
        currentSummary: data[0].name.ICON[0].value.description,
        hourlySummary: '',
        windSpeed: data[0].name.WIND_SPEED[0].value,
      };
      const forecasts = [];

      for (let i = 0; i < 8; i += 1) {
        forecasts.push({
          name: new Date(
            data[0].name.ICON[i].date
          ).toLocaleDateString(this.getLanguage, {
            weekday: 'short',
          }),
          icon: data[0].name.ICON[i].value.icon,
          highTemp: data[0].name.TEMPERATURE_MAX_DAY[i].value,
          lowTemp: data[0].name.TEMPERATURE_MIN_DAY[i].value,
          water: `${data[0].name.PLUVIOMETER[i].value.toFixed(2)} mm`,
          wind: {
            value: data[0].name.WIND_SPEED[i].value,
            direction: data[0].name.WIND_DIRECTION[i].value,
          },
          sun: `${data[0].name.SUN_HOURS[i].value.split(':')[0]}h`,
        });
      }

      this.forecast = forecasts;
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(error.message).error
            )
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    } finally {
      this.loaded = true;
    }
  },
  methods: {
    ...mapActions('weather', ['getWidgetData']),
  },
};
</script>
