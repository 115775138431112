<template>
  <f7-page>
    <div v-if="loaded">
      <FileManager :file-system="fs" />
    </div>
  </f7-page>
</template>

<script>
import { mapActions } from 'vuex';
import FileManager from '@/components/Documentation/FileManager/index.vue';
import { ATCS_ES, NOTIFY_TIME_LONG } from '@/js/constants';
import EventBus from '@/services/event-bus';

export default {
  name: 'MiningDocumentationPage',
  components: {
    FileManager,
  },
  data() {
    return {
      fs: [],
      loaded: false,
    };
  },
  async beforeMount() {
    this.setCurrentRoute([
      {
        name: this.$t('Mining'),
        disabled: false,
        page: 'MiningSamplesPage',
        active: false,
      },
      {
        name: this.$t('documentation'),
        disabled: true,
        page: 'MiningSamplesPage',
        active: true,
      },
    ]);

    this.setAtc(ATCS_ES.mining);

    try {
      this.$f7.preloader.show();

      this.fs = await this.getTree(ATCS_ES.mining);
      this.loaded = true;

      this.$f7.preloader.hide();
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(error.message).error
            )
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }

    EventBus.$on('MiningDocumentationPage', async () => {
      try {
        this.loaded = false;
        this.$f7.preloader.show();

        this.fs = await this.getTree(ATCS_ES.agronomy);
        this.loaded = true;

        this.$f7.preloader.hide();
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(error.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      } finally {
        this.$f7.preloader.hide();
        this.loaded = true;
      }
    });
  },
  methods: {
    ...mapActions('breadcrumb', ['setCurrentRoute']),
    ...mapActions('documentation', ['getTree', 'setAtc']),
  },
};
</script>
