import { SEPARATOR, UE_COUNTRIES } from './constants';

export default {
  applySeparator(array, toBeReplaced = ',') {
    return array.map((val) => {
      return val.includes(',')
        ? val.replaceAll(toBeReplaced, SEPARATOR)
        : val;
    });
  },
  getDateFormatedForBackEnd(date) {
    if (date !== null) {
      const year = date.getFullYear();
      const month = `0${date.getMonth() + 1}`.slice(-2);
      const day = `0${date.getDate()}`.slice(-2);
      return `${year}-${month}-${day}`;
    }
  },
  getDateFormatedDayMonthYear(date) {
    if (date !== null) {
      const year = date.getFullYear();
      const month = `0${date.getMonth() + 1}`.slice(-2);
      const day = `0${date.getDate()}`.slice(-2);
      return `${day}-${month}-${year}`;
    }
  },
  isEmptyObject(obj) {
    let name;
    for (name in obj) {
      if (obj.hasOwnProperty(name)) {
        return false;
      }
    }
    return true;
  },
  getFilteredErrorMessage(response) {
    const strResponse = String(response);
    if (strResponse.includes('company not found'))
      return 'company_not_found';
    if (strResponse.includes('task type not found'))
      return 'task_type_not_found';
    if (strResponse.includes('planner not found'))
      return 'planner_not_found';
    if (strResponse.includes('task not found'))
      return 'task_not_found';
    if (strResponse.includes('item not found'))
      return 'item_not_found';
    if (strResponse.includes('farm not found'))
      return 'farm_not_found';
    if (strResponse.includes('product not found'))
      return 'product_not_found';
    if (strResponse.includes('product not found in prescription'))
      return 'product_not_in_prescription';
    if (strResponse.includes('product not found in task'))
      return 'product_not_in_task';
    if (strResponse.includes('product stock is less'))
      return 'product_stock_is_less';
    if (strResponse.includes('prescription not found'))
      return 'prescription_not_found';
    if (strResponse.includes('badget not found'))
      return 'badget_not_found';
    if (strResponse.includes('badget version not found'))
      return 'badget_version_not_found';
    if (strResponse.includes('badget version task not found'))
      return 'badget_version_task_not_found';
    if (strResponse.includes('variety not found'))
      return 'variety_not_found';
    if (strResponse.includes('scouting not found'))
      return 'scouting_not_found';
    if (strResponse.includes('scouting point not found'))
      return 'scouting_point_not_found';
    if (strResponse.includes('warehouse log not found'))
      return 'warehouse_log_not_found';
    if (strResponse.includes('warehouse not found'))
      return 'warehouse_not_found';
    if (strResponse.includes('cost center not found'))
      return 'cost_center_not_found';
    if (strResponse.includes('badget versio is closed'))
      return 'badget_version_is_closed';
    if (strResponse.includes('planner not in planning status'))
      return 'planner_not_in_planning_status';
    if (strResponse.includes('task location exist with same date'))
      return 'task_location_exist_with_same_date';
    if (strResponse.includes('task not referenced'))
      return 'task_not_referenced';
    if (strResponse.includes('planner not referenced'))
      return 'planner_not_referenced';
    if (strResponse.includes('task deleted correctly'))
      return 'task_deleted_correctly';
    if (strResponse.includes('no task for selected date'))
      return 'no_task_for_selected_date_404';
    if (strResponse.includes('location not found'))
      return 'location_not_found';
    if (strResponse.includes('worker not found'))
      return 'worker_not_found';
    if (strResponse.includes('worker already exist in task'))
      return 'worker_already_exist_in_task';
    if (strResponse.includes('worker already exist in other task'))
      return 'worker_already_exist_in_other_task';
    if (strResponse.includes('worker not exist in task'))
      return 'worker_not_exist_in_task';
    if (strResponse.includes('code not exist in request'))
      return 'code_not_exist_in_request';
    if (strResponse.includes('code not exist'))
      return 'code_not_exist';
    if (strResponse.includes('outdated')) return 'outdated';
    if (strResponse.includes('invalid responsible'))
      return 'invalid_responsible';
    if (strResponse.includes('invalid item family'))
      return 'invalid_item_family';
    if (strResponse.includes('Not Found')) return 'Not_Found';
    if (strResponse.includes('ID Must be especified'))
      return 'ID_Must_be_especified';
    if (strResponse.includes('Phenological phase not found'))
      return 'Phenological_phase_not_found';
    if (strResponse.includes('Plagues and diseases not found'))
      return 'Plagues_and_diseases_not_found';
    if (strResponse.includes('Scouting_point required'))
      return 'Scouting_point_required';
    if (strResponse.includes('incorrect authentication'))
      return 'error_401';
    if (
      strResponse.includes('TASK_WORDER_PRODUCT_PERFORMANCE_REQUIRED')
    )
      return 'TASK_WORDER_PRODUCT_PERFORMANCE_REQUIRED';
    if (strResponse.includes('You do not have permission'))
      return 'error_403';
    if (
      strResponse.includes(
        'TASK_WORDER_MACHINERY_PERFORMANCE_INCOMPLETE'
      )
    )
      return 'TASK_WORDER_MACHINERY_PERFORMANCE_INCOMPLETE';
    if (
      strResponse.includes(
        'TASK_WORDER_PRODUCT_PERFORMANCE_TOTAL_REAL_PRESCRIPTION_ZERO'
      )
    )
      return 'TASK_WORDER_PRODUCT_PERFORMANCE_TOTAL_REAL_PRESCRIPTION_ZERO';
    if (strResponse.includes("property 'getLatLngs' of undefined"))
      return 'SectorValidation';
    if (strResponse.includes("undefined (reading 'geometry')"))
      return 'Gis.GeometryNotFound';
    if (strResponse.includes('JSON at position 0 sigpac'))
      return 'Gis.SigpacFormIncomplete';
    if (strResponse.includes('maximum of')) return 'mustFilter';
    if (strResponse.includes('nternal Server Error'))
      return 'internalServerError';
    return `defaultError`;
  },
  isUECountry(country) {
    return UE_COUNTRIES.includes(country);
  },
  getCoordinates(latLngs) {
    const coords = [];
    for (const latLng of latLngs) {
      if (Array.isArray(latLng)) {
        for (const latLngInner of latLng) {
          coords.push([latLngInner.lng, latLngInner.lat]);
        }
      } else {
        coords.push([latLng.lng, latLng.lat]);
      }
    }
    return [coords];
  },
  getStatusClass(status) {
    const code = parseInt(status, 10);

    const statusClass = {
      1: 'greyStatus',
      2: 'orangeStatus',
      3: 'greenStatus',
      4: 'greenStatus',
      5: 'greenStatus redStatus',
      6: 'greenStatus redStatus',
      7: 'orangeStatus',
      8: 'greyStatus',
    };

    return statusClass[code] || 'greenStatus';
  },
};
