<template>
  <f7-page>
    <div v-if="loaded">
      <div class="tableContainer">
        <UsersTable />
      </div>
    </div>
  </f7-page>
</template>
<script>
import UsersTable from '@/components/UsersTable';
import { mapActions } from 'vuex';
import EventBus from '@/services/event-bus';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  components: {
    UsersTable,
  },
  data() {
    return {
      loaded: false,
    };
  },
  async beforeMount() {
    this.$f7.preloader.show();

    this.setCurrentRoute([
      {
        name: this.$t('profile'),
        disabled: false,
        page: 'ProfilePage',
        active: false,
      },
      {
        name: this.$t('contactManagement'),
        disabled: true,
        page: 'UsersManagementPage',
        active: true,
      },
    ]);

    try {
      await this.getUsers();
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(error.message).error
            )
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },
  methods: {
    goToCreateUserPage() {
      this.$f7router.navigate('/users/create', {
        transition: 'f7-fade',
      });
    },
    ...mapActions('breadcrumb', ['setCurrentRoute']),
    ...mapActions('company', ['getUsers']),
  },
};
</script>
<style lang="scss" scoped>
.navbar--specific {
  height: 53.75px;
}
</style>
