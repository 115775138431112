<template>
  <div>
    <DxForm class="input-full">
      <DxGroupItem :col-count="setThreeCols ? 3 : 2">
        <DxSimpleItem
          :col-span="1"
          :data-field="$t('index')"
          :caption="$t('index')"
          editor-type="dxSelectBox"
          :editor-options="{
            items: indexAvailables,
            value: currentIndexes[`${keyId}`],
            placeholder: $t('index'),
            onValueChanged: onSelectedIndex,
          }"
        >
          <DxRequiredRule
            :message="$t('DxRegisterCampania_end_date_require')"
          />
        </DxSimpleItem>
        <DxSimpleItem :col-span="1">
          <template #default>
            <f7-input
              class="recepDate"
              type="datepicker"
              :placeholder="$t('agronomySample.chooseDates')"
              :clear-button="true"
              :close-on-select="true"
              :calendar-params="{
                value: actualDate,
                closeOnSelect: true,
                rangePicker: false,
                dateFormat: {
                  month: 'numeric',
                  day: 'numeric',
                  year: 'numeric',
                },
                disabled: disabledDates,
              }"
              @calendar:change="onChangeInitDate"
            />
          </template>
        </DxSimpleItem>
      </DxGroupItem>
    </DxForm>
  </div>
</template>

<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';

import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import EventBus from '@/services/event-bus';

export default {
  name: 'DatePickerWMS',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  props: {
    keyId: {
      type: String,
      default: 'layerOptions',
    },
    defaultIndex: {
      type: String,
      default: 'NDVI',
    },
    datesList: {
      type: Object,
      default: () => {},
    },
    setThreeCols: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      actualDate: this.datesList
        ? moment(
            this.datesList.avaiable_dates[
              this.datesList.avaiable_dates.length - 1
            ]
          )
        : this.datesList[this.datesList.length - 1],
      dateRequired: '',
      inputAttr: { inputmode: 'none' },
    };
  },
  computed: {
    ...mapState('gis', [
      'indexAvailables',
      'currentIndexes',
      'currentInitIntervalDates',
      'currentEndIntervalDates',
      'currentOverlayLayer',
    ]),
  },
  beforeMount() {
    this.setCurrentIndexes({
      key: this.keyId,
      newCurrentIndex: this.defaultIndex,
    });
    this.dateRequired = moment(
      this.datesList.avaiable_dates[
        this.datesList.avaiable_dates.length - 1
      ]
    ).format('YYYY-MM-DDT00:00:00');
  },
  methods: {
    disabledDates(date) {
      const dateFormatted = moment(date).format('YYYY-MM-DD');

      if (
        this.datesList.avaiable_dates.find(
          (datel) =>
            moment(datel).format('YYYY-MM-DD') === dateFormatted
        )
      ) {
        return false;
      }
      return true;
    },
    onChangeInitDate(e) {
      const date = e.length > 0 ? e[0] : this.actualDate;
      e[0] = date;
      const dateInit = moment(date, 'YYYY/MM/DD').format(
        'YYYY-MM-DDT00:00:00'
      );
      this.setCurrentInitIntervalDates({
        key: this.keyId,
        newCurrentInitIntervalDate: dateInit,
      });
      const dateList = this.datesList.avaiable_dates.filter(
        (currentDate) =>
          moment(
            this.currentInitIntervalDates[this.keyId]
          ).isSameOrBefore(currentDate)
      );
      if (dateList.length > 0) {
        this.dateRequired = dateList[0];
        EventBus.$emit(`updateDateLayer${this.keyId}`, dateList[0]);
      }
    },
    async onSelectedIndex(e) {
      this.setCurrentIndexes({
        key: this.keyId,
        newCurrentIndex: e.value,
      });
      EventBus.$emit(`indexLayer${this.keyId}`, e.value);
      this.callToFetchTemperatureHistogram(true);
    },

    filterHistogramByIndex() {
      if (
        !this.isDateAvailable(
          this.currentInitIntervalDates.GisWMS
            ? this.currentInitIntervalDates.GisWMS
            : this.actualDate
        )
      )
        return;

      const histogramDate = this.currentInitIntervalDates.GisWMS
        ? this.currentInitIntervalDates.GisWMS
        : moment(this.actualDate).format('YYYY-MM-DD');
      const histogramInformation = [];
      this.histogramValues.forEach((histogramWithIndex) => {
        if (
          histogramWithIndex.index.toString().toUpperCase() ===
          this.currentIndexes.GisWMS.toString()
        ) {
          histogramInformation.push({
            histogram: histogramWithIndex.histogram,
            iso_date: histogramDate,
          });
        }
      });

      if (histogramInformation.length < 1) return;
      this.setHistogram(histogramInformation);
    },
    isDateAvailable(date) {
      if (this.datesList.avaiable_dates.length > 0) {
        const dateFormatted = moment(date).format('YYYY-MM-DD');

        for (
          let i = 0;
          i < this.datesList.avaiable_dates.length;
          i += 1
        ) {
          const dateAvailableFormatted = moment(
            this.datesList.avaiable_dates[i]
          ).format('YYYY-MM-DD');

          if (
            moment(dateFormatted).isSame(
              dateAvailableFormatted.toString()
            )
          ) {
            return true;
          }
        }
      }

      return false;
    },

    ...mapActions('gis', [
      'setCurrentIndexes',
      'setCurrentInitIntervalDates',
      'fetchHistogram',
      'setHistogram',
      'setHistogramValues',
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import './datePicker.styles.scss';
</style>
