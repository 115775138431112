<template>
  <div>
    <DxPopup
      :visible="showNewAlertSamplePopup"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-close-button="true"
      :show-title="true"
      width="800"
      height="auto"
      container=".dx-viewport"
      :title="
        !editData.isEditing
          ? `${$t('alert.register')} - ${$t('sample')}`
          : `${$t('alert.edit')} - ${$t('sample')}`
      "
      @hiding="hide"
    >
      <DxScrollView
        :scroll-by-content="true"
        :scroll-by-thumb="true"
        show-scrollbar="onScroll"
        :use-native="true"
        :bounce-enabled="true"
      >
        <DxForm
          style="padding: 20px"
          class="ha-form"
          col-count="2"
          :form-data="formData"
        >
          <DxItem
            :editor-options="{
              items: formData.areas,
              onValueChanged: areaChanged,
              value: selectedArea,
              width: '230px',
              placeholder: $t('select'),
            }"
            data-field=""
            editor-type="dxSelectBox"
          >
            <DxRequiredRule :message="$t('alert.requiredArea')" />

            <DxLabel :text="$t('alert.area')" />
          </DxItem>

          <DxItem
            :editor-options="{
              items: formData.farms,
              onValueChanged: farmChanged,
              displayExpr: 'name',
              valueExpr: 'name',
              value: selectedFarm,
              width: '230px',
              placeholder: $t('select'),
            }"
            data-field=""
            editor-type="dxSelectBox"
          >
            <DxLabel :text="$t('agronomySample.summary.estate')" />
          </DxItem>

          <DxItem
            :editor-options="{
              items: formData.valueReports,
              onValueChanged: valueReportChanged,
              value: selectedValueReport,
              width: '230px',
              placeholder: $t('select'),
              visible: showSelectedValueReport,
            }"
            data-field=""
            editor-type="dxSelectBox"
          >
            <DxLabel
              :visible="showSelectedValueReport"
              :text="$t('valueReport')"
            />

            <DxRequiredRule
              :message="$t('alert.requiredValueReport')"
            />
          </DxItem>

          <DxEmptyItem
            :editor-options="{ visible: showSelectedValueReport }"
          />
          <DxItem
            :editor-options="{
              items: formData.sampleTypes,
              onValueChanged: sampleTypeChanged,
              displayExpr: 'value',
              valueExpr: 'value',
              value: selectedSampleType,
              width: '235px',
              placeholder: $t('select'),
            }"
            data-field=""
            editor-type="dxSelectBox"
          >
            <DxRequiredRule
              :message="$t('alert.requiredSampleType')"
            />

            <DxLabel
              :text="$t('agronomySample.summary.sampleType')"
            />
          </DxItem>

          <DxItem
            :editor-options="{
              items: formData.parcels,
              onValueChanged: parcelChanged,
              displayExpr: 'name',
              valueExpr: 'name',
              value: selectedParcel,
              width: '235px',
              placeholder: $t('select'),
            }"
            data-field=""
            editor-type="dxSelectBox"
          >
            <DxLabel :text="$t('agronomySample.summary.parcel')" />
          </DxItem>

          <DxItem
            :editor-options="{
              items: formData.studies,
              onValueChanged: studyChanged,
              value: selectedStudy,
              width: '235px',
              placeholder: $t('select'),
            }"
            data-field=""
            editor-type="dxSelectBox"
          >
            <DxLabel :text="$t('project')" />
          </DxItem>
        </DxForm>

        <div class="marginLeft20 marginBottom10">
          <DxCheckBox
            :value="sendingNotifications"
            @valueChanged="sendingNotificationsChanged"
          />
          <span>{{ $t('sendingNotifications') }}</span>
        </div>

        <span style="padding: 20px; font-weight: bold">
          {{ $t('alertCriteria') }}
        </span>

        <DxForm
          style="padding: 20px"
          class="ha-form"
          col-count="4"
          label-location="top"
          :form-data="formDataCriteria"
        >
          <DxGroupItem>
            <DxLabel
              location="left"
              :text="$t('alert.notifyIf')"
              :show-colon="false"
            />
            <DxItem
              style="width: 100px"
              :editor-options="{
                disabled: false,
                width: '60px',
                format: ';#',
              }"
              data-field="selectedNumOfParams"
              editor-type="dxNumberBox"
            >
              <DxRequiredRule
                :message="$t('alert.requiredNumParams')"
              />
              <DxLabel
                alignment="right"
                location="right"
                :text="
                  $t('alert.notifySample') +
                  ' ' +
                  (selectedValueReport ? selectedValueReport : '') +
                  `${selectedValueReport ? '(s)' : ''}`
                "
                :show-colon="false"
              />
            </DxItem>
          </DxGroupItem>
        </DxForm>

        <DxForm
          v-show="showSelectedValueReport"
          style="padding: 20px"
          class="ha-form"
          col-count="4"
          label-location="top"
          :form-data="formDataCriteria"
        >
          <DxGroupItem>
            <DxLabel
              location="left"
              :text="$t('notifyIfChains')"
              :show-colon="false"
            />
            <DxItem
              style="width: 100px"
              :editor-options="{
                disabled: false,
                width: '60px',
                format: ';#',
              }"
              data-field="selectedNumOfChains"
              editor-type="dxNumberBox"
            >
              <DxLabel
                alignment="right"
                location="right"
                :text="$t('chains').toLowerCase()"
                :show-colon="false"
              />
            </DxItem>
          </DxGroupItem>
        </DxForm>

        <DxForm
          style="padding: 20px"
          class="ha-form"
          col-count="1"
          label-location="top"
          :form-data="formDataCriteria"
        >
          <DxGroupItem>
            <DxLabel
              location="left"
              :text="$t('notifyIfChains')"
              :show-colon="false"
            />
            <DxItem
              :editor-options="{
                disabled: false,
                width: '170px',
                items: formData.chains,
                searchEnabled: true,
                value: formDataCriteria.selectedChains,
                onValueChanged: setSelectedChains,
              }"
              editor-type="dxTagBox"
            >
            </DxItem>
          </DxGroupItem>
        </DxForm>

        <f7-row>
          <f7-col
            class="marginTop15"
            width="100"
            style="text-align: right; padding: 20px"
          >
            <DxButton
              class="standardBtn"
              :text="$t('save')"
              type="outlined"
              @click="save"
            />
          </f7-col>
        </f7-row>
      </DxScrollView>
    </DxPopup>
  </div>
</template>
<script>
import { DxPopup } from 'devextreme-vue/popup';
import notify from 'devextreme/ui/notify';
import { mapActions, mapState } from 'vuex';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import { DxCheckBox } from 'devextreme-vue/check-box';

import {
  DxForm,
  DxItem,
  DxLabel,
  DxGroupItem,
  DxEmptyItem,
} from 'devextreme-vue/form';
import DxButton from 'devextreme-vue/button';

import { DxRequiredRule } from 'devextreme-vue/validator';
import { ATCS_TRANSLATED, NOTIFY_TIME_LONG } from '@/js/constants';
import EventBus from '@/services/event-bus';

export default {
  name: 'Sample',
  components: {
    DxPopup,
    DxForm,
    DxItem,
    DxLabel,
    DxButton,
    DxGroupItem,
    DxScrollView,
    DxEmptyItem,
    DxRequiredRule,
    DxCheckBox,
  },
  props: {
    editData: {
      type: Object,
      default: () => {
        return {
          isEditing: false,
          data: null,
        };
      },
    },
    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formData: {
        areas: [
          this.$t(ATCS_TRANSLATED.Environment),
          this.$t(ATCS_TRANSLATED.Food),
          this.$t(ATCS_TRANSLATED['Health and Security']),
        ],
        sampleTypes: [],
        farms: [],
        parcels: [],
        valueReports: [this.$t('foodSamples.lmr'), this.$t('cma')],
        studies: [],
        chains: [],
      },
      selectedArea: '',
      selectedFarm: '',
      selectedSampleType: '',
      selectedParcel: '',
      sendingNotifications: false,
      selectedStudy: '',
      selectedValueReport: '',
      showSelectedValueReport: false,
      formDataCriteria: {
        // hacer abs
        selectedNumOfParams: 0,
        selectedNumOfChains: 0,
        selectedChains: [],
      },
    };
  },
  computed: {
    ...mapState('alerts', ['showNewAlertSamplePopup']),
    ...mapState('contact', ['company']),
  },
  async beforeMount() {
    if (this.editData.isEditing) {
      await this.areaChanged({
        value: this.editData.data.area,
      });
    }
  },

  methods: {
    hide() {
      this.setShowNewAlertSamplePopup(false);
    },
    setSelectedChains(e) {
      if (!e.event && e.value.length === 0) return;
      this.formDataCriteria.selectedChains = e.value;
    },
    async fetchFarms() {
      this.formData.farms = await this.getSampleFarms({
        atc: this.selectedArea,
      });
      // Función para quitar valores repetidos
      this.formData.farms = this.formData.farms.reduce(
        (newFarms, current) => {
          const isRepeated = newFarms.find(
            (item) => item.id === current.id
          );
          if (!isRepeated) {
            newFarms.push(current);
          }

          return newFarms;
        },
        []
      );
    },
    async fetchSampleTypes() {
      this.formData.sampleTypes = await this.getSampleFilter({
        atc: this.selectedArea,
        attribute: 'sample_type',
      });
    },
    async fetchParcels() {
      this.formData.parcels = await this.getSampleParcels({
        atc: this.selectedArea,
        farm: this.selectedFarm,
      });
    },
    async fetchStudies() {
      this.formData.studies = await this.getStudySatellite({
        company_code: this.company,
        atc_name: this.selectedArea,
      });
    },
    async fetchChains() {
      this.formData.chains = await this.getFoodChains();
    },
    async areaChanged(e) {
      if (!e.value) return;

      this.selectedArea = e.value;

      try {
        this.$f7.preloader.show();

        await Promise.all([
          this.fetchFarms(),
          this.fetchSampleTypes(),
          this.fetchStudies(),
          this.fetchChains(),
        ]);
        this.selectedSampleType =
          this.editData.isEditing && !e.event
            ? this.editData.data.sampleType
            : '';
        this.selectedFarm =
          this.editData.isEditing && !e.event
            ? this.editData.data.farm
            : '';
        this.selectedParcel =
          this.editData.isEditing && !e.event
            ? this.editData.data.parcel
            : '';
        this.selectedStudy =
          this.editData.isEditing && !e.event
            ? this.editData.data.study
            : '';
        this.sendingNotifications =
          this.editData.isEditing && !e.event
            ? this.editData.data.sendingNotifications
            : false;
        this.setValueReportOnAreaSelected(e);
        if (this.editData.isEditing && !e.event) {
          this.formDataCriteria.selectedNumOfParams =
            -this.editData.data.nrSuperiorParams;
          this.formDataCriteria.selectedNumOfChains =
            -this.editData.data.retailersNum;
          this.formDataCriteria.selectedChains =
            this.editData.data.retailers;
        } else {
          this.formDataCriteria.selectedNumOfParams = 0;
          this.formDataCriteria.selectedNumOfChains = 0;
          this.formDataCriteria.selectedChains = [];
        }
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(error.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      } finally {
        this.$f7.preloader.hide();
      }
    },
    setValueReportOnAreaSelected(e = null) {
      if (
        this.selectedArea === this.$t(ATCS_TRANSLATED.Food) ||
        (this.editData.isEditing &&
          !e.event &&
          this.editData.data.atc === this.$t(ATCS_TRANSLATED.Food))
      ) {
        this.showSelectedValueReport = true;
        this.selectedValueReport =
          this.editData.isEditing && !e.event
            ? this.editData.data.valueReport
            : '';
      } else {
        this.showSelectedValueReport = false;
        this.selectedValueReport = this.$t('cma');
      }
    },
    sendingNotificationsChanged(e) {
      if (!e.event) return;
      this.sendingNotifications = !this.sendingNotifications;
    },
    farmChanged(e) {
      if (!e.value) return;
      this.selectedFarm = e.value;
      this.fetchParcels();
    },
    async studyChanged(e) {
      if (!e.value) return;
      this.selectedStudy = e.value;
    },
    sampleTypeChanged(e) {
      if (!e.value) return;
      this.selectedSampleType = e.value;
    },
    parcelChanged(e) {
      if (!e.value) return;
      this.selectedParcel = e.value;
    },
    valueReportChanged(e) {
      if (!e.value) return;
      this.selectedValueReport = e.value;
    },
    async save() {
      try {
        if (this.validate()) {
          if (this.editData.isEditing) {
            await this.saveOnEdit();
            return;
          }
          const sampleTypeId = this.formData.sampleTypes.find(item => item.value === this.selectedSampleType);
          const data = {
            farm: this.selectedFarm,
            atc: this.selectedArea,
            sampleType: this.selectedSampleType,
            sampleTypeId: sampleTypeId.id,
            sampleName: '',
            parcel: this.selectedParcel,
            isActive: true,
            valueReport: this.selectedValueReport,
            nrSuperiorParams: Math.abs(
              this.formDataCriteria.selectedNumOfParams
            ),
            retailersNum: Math.abs(
              this.formDataCriteria.selectedNumOfChains
            ),
            retailers: this.formDataCriteria.selectedChains,
            study: this.selectedStudy,
            sendingNotifications: this.sendingNotifications,
          };
          notify(
            {
              message: this.$t('alert.save'),
              width: 450,
            },
            'info',
            2000
          );
          await this.saveAlert(data);

          notify(
            {
              closeOnClick: true,
              message: this.$t('alert.saved'),
              width: 450,
            },
            'success',
            2000
          );

          this.setShowNewAlertSamplePopup(false);

          EventBus.$emit('newSampleAlert');
        }
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(error.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      }
    },
    validate() {
      if (!this.selectedArea) {
        this.selectedArea = null;
        return false;
      }

      if (!this.selectedSampleType) {
        this.selectedSampleType = null;
        return false;
      }

      if (this.showSelectedValueReport && !this.selectedValueReport) {
        this.selectedValueReport = null;
        return false;
      }

      /** 
      if (!this.selectedFarm) {
        this.selectedFarm = null;
        return false;
      }

      if (!this.selectedParcel) {
        this.selectedParcel = null;
        return false;
      }
      */

      if (!this.formDataCriteria.selectedNumOfParams) {
        this.formDataCriteria.selectedNumOfParams = null;
        return false;
      }

      return true;
    },
    async saveOnEdit() {
      try {
        notify(
          {
            message: this.$t('editing'),
            width: 450,
          },
          'info',
          2000
        );
        await this.updateAlert({
          id: this.editData.data.id,
          params: {
            farm: this.selectedFarm,
            atc: this.selectedArea,
            sampleType: this.selectedSampleType,
            parcel: this.selectedParcel,
            valueReport: this.selectedValueReport,
            nrSuperiorParams: Math.abs(
              this.formDataCriteria.selectedNumOfParams
            ),
            retailers: this.formDataCriteria.selectedChains,
            retailersNum: this.formData.selectedNumOfChains,
            study: this.selectedStudy,
            sendingNotifications: this.sendingNotifications,
          },
        });

        notify(
          {
            closeOnClick: true,
            message: this.$t('editSuccess'),
            width: 450,
          },
          'success',
          2000
        );

        this.setShowNewAlertSamplePopup(false);

        EventBus.$emit('newSampleAlert', this.params);
      } catch (e) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(e.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      }
    },
    ...mapActions('alerts', ['setShowNewAlertSamplePopup']),
    ...mapActions('samples', [
      'getSampleFilter',
      'getSampleFarms',
      'getSampleParcels',
    ]),
    ...mapActions('alertsSample', ['saveAlert', 'updateAlert']),
    ...mapActions('agronomySamples', ['getStudySatellite']),
    ...mapActions('foodSamples', ['getFoodChains']),
  },
};
</script>
