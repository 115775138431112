<template>
  <div>
    <div class="tableContainer content-agronomy-detail">
      <DxDataGrid
        :data-source="sampleDetails"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :row-alternation-enabled="true"
        :show-borders="true"
        :show-row-lines="true"
        :no-data-text="$t('noDataTextDetail')"
        @exporting="onExporting"
        @toolbar-preparing="onToolbarPreparing"
        @contentReady="onInit"
      >
        <DxExport :enabled="true" />
        <DxColumn
          :group-index="0"
          caption="Grupo"
          data-field="parameter_group"
        />
        <DxColumn
          data-field="parameter"
          :caption="$t('foodSamples.parameter')"
          alignment="left"
        />
        <DxColumn
          :caption="$t('foodSamples.result')"
          alignment="center"
          data-field="value"
          :customize-text="analyticValueText"
        />
        <DxColumn
          alignment="center"
          :caption="$t('agronomySample.summary.avgRange')"
          :customize-text="rangeValueText"
          data-field="value"
        />
        <DxColumn
          :allow-grouping="false"
          :allow-exporting="false"
          data-field="value"
          :caption="$t('agronomySample.summary.interpretation')"
          data-type="number"
          alignment="center"
          cell-template="interpretationCellTemplate"
          css-class="bullet"
        />
        <DxGroupPanel :visible="true" />
        <DxSearchPanel
          :visible="true"
          :highlight-case-sensitive="true"
          :placeholder="$t('search')"
        />

        <DxGrouping expand-mode="rowClick" :auto-expand-all="true" />
        <DxPager
          :allowed-page-sizes="[10, 25, 50, 100]"
          :show-page-size-selector="true"
          info-text="{2} items"
          :visible="false"
        />
        <DxPaging :page-size="100" />
        <template #interpretationCellTemplate="{ data: cellData }">
          <InterpretationCell
            :cell-data="cellData"
            :max-value="getMaxValueParameter"
          />
        </template>
      </DxDataGrid>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxGrouping,
  DxGroupPanel,
  DxPager,
  DxPaging,
  DxSearchPanel,
  DxExport,
} from 'devextreme-vue/data-grid';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';

import InterpretationCell from '../InterpretationCell/InterpretationCell.vue';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxGrouping,
    DxGroupPanel,
    DxPager,
    DxPaging,
    DxSearchPanel,
    InterpretationCell,
    DxExport,
  },
  computed: {
    ...mapState('filter', ['currentSampleId']),
    ...mapState('agronomySamples', ['sampleDetails']),
    ...mapState('samples', ['reportSampleCode']),
    getMaxValueParameter() {
      const values = [];

      this.sampleDetails.forEach((sample) => {
        values.push(sample.value.result);
      });
      return Math.max(...values);
    },
  },
  methods: {
    interpretationValueText(e) {
      if (e.value.very_high_level < e.value.result) return 'Muy alto';
      if (
        e.value.high < e.value.result &&
        e.value.result < e.value.very_high_level
      )
        return 'Alto';
      if (
        e.value.low < e.value.result &&
        e.value.result < e.value.high
      )
        return 'Medio';
      if (
        e.value.very_low_level < e.value.result &&
        e.value.result < e.value.low
      )
        return 'Bajo';
      if (e.value.result < e.value.very_low_level) return 'Muy bajo';
    },
    onInit() {
      Array.from(
        document.getElementsByClassName('dx-group-cell')
      ).forEach((el) => {
        el.innerHTML = el.innerHTML.split(':')[1].trim();
      });
    },
    rangeValueText(e) {
      if (e.value.unit_new_id > 0) {
        return `${e.value.low_level_new || ''} - ${
          e.value.high_level_new || ''
        }`;
      }

      return `${e.value.low_level || ''} - ${
        e.value.high_level || ''
      }`;
    },
    analyticValueText(e) {
      return `${
        e.value.ResultadoPresentacion.includes('En Proceso')
          ? this.$t('inProcess')
          : e.value.ResultadoPresentacion
      } 
      ${
        e.value.ResultadoPresentacion.includes('En Proceso')
          ? ''
          : e.value.unit
      }`;
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items;

      toolbarItems.forEach((item) => {
        if (item.name === 'searchPanel') {
          item.location = 'before';
        }
        if (item.name === 'exportButton') {
          item.location = 'after';
        }

        if (item.name === 'groupPanel') {
          item.location = 'after';
        }
      });
    },
    onExporting(e) {
      e.component.beginUpdate();
      e.component.columnOption('ID', 'visible', true);
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(
        `${`${this.$t(
          'agronomySample.summary.sampleSummary'
        )}-${this.$t('Agronomy')}`}`
      );

      exportDataGrid({
        component: e.component,
        worksheet,
      })
        .then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(
              new Blob([buffer], {
                type: 'application/octet-stream',
              }),
              `besafer-${this.reportSampleCode}.xlsx`
            );
          });
        })
        .then(() => {
          e.component.columnOption('ID', 'visible', false);
          e.component.endUpdate();
        });

      e.cancel = true;
    },
  },
};
</script>

<style scoped>
::v-deep .dx-toolbar-after {
  left: initial !important;
}
</style>
