var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('DxPopup',{attrs:{"visible":_vm.showNewContactPopup,"drag-enabled":false,"close-on-outside-click":false,"show-close-button":true,"show-title":true,"width":"525","height":"auto","container":".dx-viewport","title":_vm.editData.isEditing ? _vm.$t('editContact') : _vm.$t('registerContact')},on:{"hiding":_vm.hide}},[_c('f7-row',{staticClass:"margin padding"},[_c('f7-col',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveNewContact.apply(null, arguments)}}},[_c('DxForm',{staticClass:"ha-form",attrs:{"id":"user-personal-info","col-count":1,"form-data":_vm.formData,"label-location":"top"}},[_c('DxItem',{attrs:{"editor-options":{ disabled: false, width: 459 },"data-field":"name"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('newContactForm.requiredName')}}),_vm._v(" "),_c('DxLabel',{attrs:{"text":_vm.$t('name')}})],1),_vm._v(" "),_c('DxItem',{attrs:{"data-field":"surname","editor-options":{ disabled: false, width: 459 }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('newContactForm.requiredSurname')}}),_vm._v(" "),_c('DxLabel',{attrs:{"text":_vm.$t('surname')}})],1),_vm._v(" "),_c('DxItem',{staticClass:"ha-form__language",attrs:{"editor-options":{
              items: _vm.formData.langs,
              displayExpr: _vm.translateLanguageOption,
              width: 459,
              placeholder: _vm.$t('select'),
            },"data-field":"language","editor-type":"dxSelectBox"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('newContactForm.requiredLang')}}),_vm._v(" "),_c('DxLabel',{attrs:{"text":_vm.$t('language')}})],1),_vm._v(" "),_c('DxItem',{attrs:{"editor-options":{
              disabled: false,
              width: 459,
            },"data-field":"phone"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('newContactForm.requiredPhone')}}),_vm._v(" "),_c('DxLabel',{attrs:{"text":_vm.$t('phone')}})],1),_vm._v(" "),_c('DxItem',{attrs:{"data-field":"email","editor-options":{
              disabled: _vm.editData.isEditing,
              width: 459,
              elementAttr: { id: 'itemEmail' },
            }}},[(!_vm.editData.isEditing)?_c('DxRequiredRule',{attrs:{"message":_vm.$t('newContactForm.requiredEmail')}}):_vm._e(),_vm._v(" "),(!_vm.editData.isEditing)?_c('DxEmailRule',{attrs:{"message":_vm.$t('newContactForm.invalidMail')}}):_vm._e(),_vm._v(" "),_c('DxLabel',{attrs:{"text":_vm.$t('email')}})],1)],1),_vm._v(" "),_c('f7-row',[_c('f7-col',{staticClass:"treeview-column margin-top",attrs:{"width":"100"}},[_c('div',{staticClass:"treeview-container"},[_c('p',{staticClass:"addresses-title"},[_vm._v(_vm._s(_vm.$t('addresses'))+": "),_c('i',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_vm._v(" "),_c('DxTreeView',{staticClass:"custom-treeview",attrs:{"width":443,"height":120,"items":_vm.addresses,"show-check-boxes-mode":"normal","selection-mode":"multiple","select-nodes-recursive":true,"select-by-click":false,"display-expr":"name"},on:{"selection-changed":_vm.treeViewSelectionChanged,"content-ready":_vm.treeViewContentReady}})],1)]),_vm._v(" "),(_vm.notEnoughAddressesSelected)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.$t('newContactForm.addressesRequired'))+"\n            ")]):_vm._e()],1),_vm._v(" "),_c('f7-row',{staticClass:"justify-content-center"},[_c('f7-col',{staticClass:"marginTop",staticStyle:{"text-align":"center"},attrs:{"width":"100"}},[_c('DxButton',{staticClass:"custom-button",attrs:{"id":"botonGuardar","use-submit-behavior":true,"text":_vm.$t('save'),"type":"outlined"}})],1)],1)],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }