<template>
  <div class="tableContainer">
    <div class="filterButtonPosition" style="right: 64px">
      <f7-button
        fill
        class="whiteFontColor button-filter"
        panel-toggle="right"
      >
        <span class="ic-filter"></span> {{ $t('filter') }}
      </f7-button>
    </div>
    <DxDataGrid
      :data-source="store"
      :show-borders="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :show-colon="false"
      :remote-operations="true"
      :hover-state-enabled="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :no-data-text="$t('noDataTextDetail')"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing"
      @content-ready="init"
    >
      <DxScrolling mode="standard" />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="environmentSamplesDetailsTable"
      />
      <DxSelection mode="single" />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="[5, 10, 20]"
        :show-info="true"
        info-text="{2} items"
        :visible="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="300"
        :placeholder="$t('search')"
      />
      <DxExport :enabled="true" />

      <DxColumn
        data-field="cma_is_apt"
        caption=""
        header-cell-template="cellTemplateHeaderDots"
        :width="35"
        :allow-sorting="false"
        cell-template="cellTemplateDots"
        :fixed="true"
        fixed-position="left"
      />
      <template #cellTemplateDots="{ data }">
        <div class="dots-parent-container">
          <img width="20" height="20" :src="getDotColorImage(data)" />
        </div>
      </template>
      <DxColumn
        data-field="parameter_group"
        :caption="$t('group')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="parameter"
        :caption="$t('foodSamples.parameter')"
        alignment="left"
        :allow-header-filtering="false"
      />

      <DxColumn
        data-field="value.ResultadoPresentacion"
        cell-template="cellTemplateResultadoPresentacion"
        :caption="$t('foodSamples.result')"
        :allow-header-filtering="false"
      />

      <template #cellTemplateResultadoPresentacion="{ data }">
        <div>
          {{
            data.data.value.ResultadoPresentacion.includes(
              'En Proceso'
            )
              ? $t('inProcess')
              : data.data.value.ResultadoPresentacion
          }}
        </div>
      </template>

      <DxColumn
        data-field="value.unit"
        :caption="$t('foodSamples.unit')"
        alignment="left"
        :allow-header-filtering="false"
      />

      <DxColumn
        data-field=""
        :caption="$t('foodSamples.range')"
        :allow-header-filtering="false"
        cell-template="cellTemplateRange"
      />

      <template #cellTemplateRange="{ data }">
        <div>
          {{ data.data.value.r_min }} - {{ data.data.value.r_max }}
        </div>
      </template>

      <DxColumn
        data-field="cma[0].CMASuperior"
        :caption="$t('cma')"
        :allow-header-filtering="false"
      />

      <template #cellTemplateHeaderDots="{ data }">
        <div>
          <PopUp position="top">
            <div style="padding: 10px">
              <div class="pop-up-info">
                <img
                  :src="getDotImage('grey')"
                  width="15"
                  height="15"
                />
                <div style="padding-left: 5px">
                  {{
                    $t('environmentSamples.detail.greyDotDescription')
                  }}
                </div>
              </div>
              <div class="pop-up-info">
                <img
                  :src="getDotImage('green')"
                  width="15"
                  height="15"
                />
                <div style="padding-left: 5px">
                  {{
                    $t(
                      'environmentSamples.detail.greenDotDescription'
                    )
                  }}
                </div>
              </div>
              <div class="pop-up-info">
                <img
                  :src="getDotImage('red')"
                  width="15"
                  height="15"
                />
                <div style="padding-left: 5px">
                  {{
                    $t('environmentSamples.detail.redDotDescription')
                  }}
                </div>
              </div>
            </div>
          </PopUp>
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxStateStoring,
  DxPager,
  DxPaging,
  DxScrolling,
  DxExport,
  DxSelection,
} from 'devextreme-vue/data-grid';

import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import PopUp from '@/components/UtilityComponents/popUpInfo/popUp.vue';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxStateStoring,
    DxPager,
    DxPaging,
    DxScrolling,
    DxExport,
    DxSelection,
    PopUp,
  },
  props: {
    store: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    environmentSamplesDetails() {
      return this.filteredSampleDetails !== null
        ? this.filteredSampleDetails
        : this.sampleDetails;
    },
    ...mapState('environmentSamples', [
      'sampleDetails',
      'filteredSampleDetails',
    ]),
    ...mapState('samples', ['reportSampleCode']),
  },
  methods: {
    getDotImage(color) {
      return require(`../../../../../assets/dot-colors/${color}.svg`)
        .default;
    },
    getDotColorImage(data) {
      if (!data.value && data.value !== false) {
        return require(`../../../../../assets/dot-colors/grey.svg`)
          .default;
      }
      if (data.value === false) {
        return require(`../../../../../assets/dot-colors/red.svg`)
          .default;
      }
      return require(`../../../../../assets/dot-colors/green.svg`)
        .default;
    },
    init(e) {
      this.setSampleDetails(e.component.getDataSource().items());
    },
    async onExporting(e) {
      this.setIsExporting(true);
      e.component.beginUpdate();
      e.component.columnOption('ID', 'visible', true);
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(
        `${`${this.$t(
          'agronomySample.summary.sampleSummary'
        )}-${this.$t('Environment')}`}`
      );

      exportDataGrid({
        component: e.component,
        worksheet,
      })
        .then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(
              new Blob([buffer], {
                type: 'application/octet-stream',
              }),
              `besafer-${this.reportSampleCode}.xlsx`
            );
          });
        })
        .then(() => {
          e.component.columnOption('ID', 'visible', false);
          e.component.endUpdate();
        });

      e.cancel = true;
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items;

      toolbarItems.forEach((item) => {
        if (item.name === 'searchPanel') {
          item.location = 'before';
        }
        if (item.name === 'exportButton') {
          item.location = 'after';
        }

        if (item.name === 'groupPanel') {
          item.location = 'after';
        }
      });
    },
    ...mapActions('environmentSamples', [
      'setSampleDetails',
      'setIsExporting',
    ]),
  },
};
</script>

<style scoped>
::v-deep .dx-toolbar-after {
  left: initial !important;
}
</style>
