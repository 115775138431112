import CustomStore from 'devextreme/data/custom_store';
import EventBus from '@/services/event-bus';
import Api from '../../../services/Api';

export default {
  namespaced: true,
  state: {
    allSamples: [],
    sampleDetails: null,
    isExporting: false,
  },
  actions: {
    async getSamples({ rootState }, params) {
      try {
        const xhr = await Api.getEnvironmentSamples(
          params,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve({
          data: response.data,
          totalCount: response.totalCount,
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getSamplesWithFilters({ rootState }, params) {
      try {
        const xhr = await Api.getEnvironmentSamplesWithFilters(
          params,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve({
          data: response.data,
          totalCount: response.totalCount,
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getSampleDetails({ rootState }, { id, params }) {
      try {
        const xhr = await Api.getSampleDetails(
          id,
          params,
          null,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve({
          data: response.data,
          totalCount: response.totalCount,
        });
      } catch (error) {
        return Promise.resolve({
          data: [],
          totalCount: 0,
        });
      }
    },
    async getSampleDetailsWithFilters({ rootState }, data) {
      try {
        const xhr = await Api.getSamplesDetailsWithFilters(
          data.params,
          data.id,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve({
          data: response.data,
          totalCount: response.totalCount,
        });
      } catch (error) {
        return Promise.resolve({
          data: [],
          totalCount: 0,
        });
      }
    },
    getStore({ dispatch, rootState }) {
      return new CustomStore({
        key: 'id',
        load: async (loadOptions) => {
          let params = '?';
          let sortSettings = [
            { selector: 'sampling_date', desc: true },
          ];

          ['skip', 'take', 'sort'].forEach((i) => {
            if (i in loadOptions) {
              if (i === 'sort' && loadOptions[i] !== null) {
                sortSettings = loadOptions[i];
              } else {
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
              }
            }
          });

          params = params.slice(0, -1);
          let subsistuteParams = params.replace('skip', 'offset');
          subsistuteParams = subsistuteParams.replace(
            'take',
            'limit'
          );

          const sortParams = await dispatch(
            'filter/applySort',
            {
              sortSettings,
              isFilter: false,
            },
            { root: true }
          );

          subsistuteParams += sortParams;

          if (!subsistuteParams.startsWith('?')) {
            subsistuteParams = `?${subsistuteParams}`;
          }
          if (loadOptions.filter) {
            if (loadOptions.filter[0]) {
              subsistuteParams += `&filter_search=${loadOptions.filter[0].filterValue}`;
            }
          }
          return dispatch('getSamples', subsistuteParams);
        },
      });
    },
    getDetailsStore({ dispatch, rootState, state, commit }) {
      return new CustomStore({
        key: 'parameter_id',
        load: async (loadOptions) => {
          let params = '';
          let sortSettings = [
            { selector: 'group_order', desc: false },
            // { selector: 'parameter_order', desc: false },
            { selector: 'parameter', desc: false },
          ];

          ['skip', 'take', 'sort'].forEach((i) => {
            if (i in loadOptions) {
              if (i === 'sort' && loadOptions[i] !== null) {
                sortSettings = loadOptions[i];
              } else {
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
              }
            }
          });

          params = params.slice(0, -1);
          let subsistuteParams = params.replace('skip', 'offset');
          subsistuteParams = subsistuteParams.replace(
            'take',
            'limit'
          );
          if (state.isExporting) {
            subsistuteParams += '&all=true';
          }
          const sortParams = await dispatch(
            'filter/applySort',
            {
              sortSettings,
              isFilter: false,
            },
            { root: true }
          );

          subsistuteParams += sortParams;

          if (!subsistuteParams.startsWith('?')) {
            subsistuteParams = `?${subsistuteParams}`;
          }

          if (
            Array.isArray(loadOptions.filter) &&
            loadOptions.filter?.[0]?.filterValue != null
          ) {
            subsistuteParams += `&filter_search=${loadOptions.filter[0].filterValue}`;
          }

          commit('setIsExporting', false);
          return dispatch('getSampleDetails', {
            id: rootState.filter.currentSampleId.toString(),
            params: subsistuteParams,
          });
        },
      });
    },
    async getStoreWithFilters({ dispatch, rootState }, paramFilters) {
      return new CustomStore({
        key: 'id',
        load: async (loadOptions) => {
          let params = '?';
          let sortSettings = [];

          ['skip', 'take', 'sort'].forEach((i) => {
            if (i in loadOptions) {
              if (i === 'sort' && loadOptions[i] !== null) {
                sortSettings = loadOptions[i];
              } else {
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
              }
            }
          });

          params = params.slice(0, -1);

          if (params.includes('skip')) {
            let subsistuteParams = params.replace('skip', 'offset');
            subsistuteParams = subsistuteParams.replace(
              'take',
              'limit'
            );

            const offsetLimit = subsistuteParams.split('&');

            const offset = offsetLimit[0].split('=')[1];

            const limit = offsetLimit[1].split('=')[1];
            paramFilters.offset = offset;
            paramFilters.limit = limit;
          } else {
            paramFilters.limit = null;
            paramFilters.offset = null;
          }

          const sortParams = await dispatch(
            'filter/applySort',
            {
              sortSettings,
              isFilter: true,
            },
            { root: true }
          );

          if (loadOptions.filter) {
            if (loadOptions.filter[0]) {
              paramFilters.filter_search =
                loadOptions.filter[0].filterValue;
            }
          } else {
            paramFilters.filter_search = null;
          }

          paramFilters.ordering = sortParams.sort;
          
          return dispatch('getSamplesWithFilters', paramFilters);
        },
      });
    },
    async getStoreDetailsWithFilters(
      { dispatch },
      { id, paramFilters }
    ) {
      return new CustomStore({
        key: 'parameter_id',
        load: async (loadOptions) => {
          let params = '?';
          let sortSettings = [];

          ['skip', 'take', 'sort'].forEach((i) => {
            if (i in loadOptions) {
              if (i === 'sort' && loadOptions[i] !== null) {
                sortSettings = loadOptions[i];
              } else {
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
              }
            }
          });

          params = params.slice(0, -1);

          if (params.includes('skip')) {
            let subsistuteParams = params.replace('skip', 'offset');
            subsistuteParams = subsistuteParams.replace(
              'take',
              'limit'
            );

            if (!subsistuteParams) return [];

            const offsetLimit = subsistuteParams.split('&');

            const offset = offsetLimit[0].split('=')[1];

            const limit = offsetLimit[1].split('=')[1];
            paramFilters.offset = offset;
            paramFilters.limit = limit;
          } else {
            paramFilters.limit = null;
            paramFilters.offset = null;
          }

          const sortParams = await dispatch(
            'filter/applySort',
            {
              sortSettings,
              isFilter: true,
            },
            { root: true }
          );

          paramFilters.ordering = sortParams.sort;

          paramFilters.filter_search = 'ab';
          return dispatch('getSampleDetailsWithFilters', {
            id,
            params: paramFilters,
          });
        },
      });
    },
    async getCmaAptCount({ rootState }) {
      try {
        const xhr = await Api.getCmaAptCount(
          rootState.filter.currentSampleId,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    callStoreWithFilters({ commit }, params) {
      EventBus.$emit('EnvironmentSamplesPage', params);
    },
    callStoreDetailsWithfilters({ commit }, { id, params }) {
      EventBus.$emit('environmentDetailsFilteredSamples', {
        id,
        params,
      });
    },
    setFilteredSamples({ commit }, value) {
      EventBus.$emit('EnvironmentSamplesPage', null);
    },
    setFilteredSampleDetails({ commit }, value) {
      EventBus.$emit('environmentDetailsFilteredSamples', {
        id: null,
        params: null,
      });
    },
    setSamples({ commit }, value) {
      commit('setSamples', value);
    },
    setSampleDetails({ commit }, value) {
      commit('setSampleDetails', value);
    },
    setIsExporting({ commit }, value) {
      commit('setIsExporting', value);
    },
  },
  mutations: {
    setSamples(state, value) {
      state.allSamples = value;
    },
    setSampleDetails(state, value) {
      state.sampleDetails = value;
    },
    setIsExporting(state, value) {
      state.isExporting = value;
    },
  },
};
