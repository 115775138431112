export const getAuthorizedHeader = (accessToken) => {
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
};

export const getAuthorizedHeaderWithCompanyCode = (
  accessToken,
  userCompanyCode,
  addressCode = null,
  contact = null
) => {
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      company: userCompanyCode,
      address: addressCode,
      contact,
    },
  };
};
