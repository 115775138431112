<template>
  <div class="main-chart-weather-line-graph">
    <div class="content-devx no-padding">
      <f7-block class="content-linesgraph">
        <DxChart
          id="chart"
          :data-source="dataSource"
          @legend-click="onLegendClick"
          @initialized="onInit"
        >
          <DxCommonSeriesSettings argument-field="date" />
          <DxSeries
            pane="top"
            color="#36A2EB"
            :value-field="`minmeasured${weatherElement}`"
            :name="`Min ${$t(weatherElement)}, ${measurement}`"
          />

          <DxSeries
            pane="top"
            color="#9c88ff"
            :value-field="`avgmeasured${weatherElement}`"
            :name="`${$t('average')} ${$t(
              weatherElement
            )}, ${measurement}`"
          />

          <DxSeries
            color="#FF6384"
            :value-field="`maxmeasured${weatherElement}`"
            :name="`Max ${$t(weatherElement)}, ${measurement}`"
          />

          <DxSeries
            pane="top"
            :value-field="`minforecast${weatherElement}`"
            type="line"
            color="#82ccdd"
            :name="`${$t('minForecasted')} ${$t(
              weatherElement
            )}, ${measurement}`"
          />
          <DxSeries
            pane="top"
            :value-field="`avgforecast${weatherElement}`"
            type="line"
            color="#1dd1a1"
            :name="`${$t('averageForecasted')} ${$t(
              weatherElement
            )}, ${measurement}`"
          />
          <DxSeries
            pane="top"
            :value-field="`maxforecast${weatherElement}`"
            type="line"
            color="#b71540"
            :name="`${$t('maxForecasted')} ${$t(
              weatherElement
            )}, ${measurement}`"
          />

          <DxPane name="top" />
          <DxArgumentAxis
            discrete-axis-division-mode="crossLabels"
            argument-type="datetime"
            :value-margins-enabled="false"
          >
            <DxLabel
              display-mode="stagger"
              format="d MMM yyyy - hh:mm"
            />
          </DxArgumentAxis>
          <DxAdaptiveLayout :width="80" :keep-labels="true" />
          <DxValueAxis pane="top" :axis-division-factor="20">
            <DxGrid :visible="true" />
            <DxTitle
              :text="`${$t(weatherElement)}, ${measurement}`"
            />
          </DxValueAxis>
          <DxLegend
            :margin="30"
            vertical-alignment="bottom"
            horizontal-alignment="center"
            item-text-position="top"
          />
          <DxExport :enabled="false" />
          <DxTooltip
            :enabled="true"
            :z-index="999999"
            :customize-tooltip="customizeTooltip"
          />
          <DxTitle :text="`${$t(weatherElement)}`">
            <DxFont family="Roboto" size="14" />
          </DxTitle>
          <DxScrollBar :visible="true" />
          <DxZoomAndPan argument-axis="both" />
        </DxChart>
      </f7-block>
    </div>
  </div>
</template>

<script>
import DxChart, {
  DxCommonSeriesSettings,
  DxSeries,
  DxPane,
  DxScrollBar,
  DxArgumentAxis,
  DxValueAxis,
  DxTitle,
  DxLegend,
  DxLabel,
  DxTooltip,
  DxZoomAndPan,
  DxExport,
  DxGrid,
  DxAdaptiveLayout,
  DxFont,
} from 'devextreme-vue/chart';

export default {
  components: {
    DxChart,
    DxCommonSeriesSettings,
    DxSeries,
    DxPane,
    DxGrid,
    DxArgumentAxis,
    DxValueAxis,
    DxTitle,
    DxLegend,
    DxLabel,
    DxTooltip,
    DxScrollBar,
    DxZoomAndPan,
    DxExport,
    DxAdaptiveLayout,
    DxFont,
  },
  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
    weatherElement: {
      type: String,
      default: 'temperature',
    },
  },
  data() {
    return {
      measurement: '',
    };
  },
  beforeMount() {
    if (
      typeof this.dataSource !== 'undefined' &&
      this.dataSource.length > 0
    ) {
      if (typeof this.dataSource[1].unit !== 'undefined') {
        const foundByWeatherElement = this.dataSource.find((el) => {
          const keys = Object.keys(el);
          for (let i = 0; i < keys.length; i += 1) {
            if (keys[i].includes(this.weatherElement)) {
              return el;
            }
          }
        });

        if (foundByWeatherElement) {
          switch (foundByWeatherElement.unit) {
            case 'meter_second':
              this.measurement = 'm/s';
              break;
            case 'liter_m2':
              this.measurement = 'L/m²';
              break;
            case 'percentage':
              this.measurement = '%';
              break;
            case 'celsius':
              this.measurement = '°C';
              break;
            case 'wat_m2':
              this.measurement = 'W/m²';
              break;
            default:
              break;
          }
        }
      }
    }
  },
  methods: {
    onInit(e) {
      // delay al event loop
      setTimeout(() => {
        e.component.series.forEach((serie) => {
          if (this.$helpers.isEmptyObject(serie.pointsByArgument)) {
            serie.hide();
          }
        });
      }, 0);
    },
    customizeTooltip(pointInfo) {
      const seriesName = pointInfo.seriesName.split(',')[0];
      const seriesMeasureUnit = pointInfo.seriesName
        .split(',')[1]
        .trim();

      return {
        text: `${seriesName} on <strong>${pointInfo.argument}</strong>
          :<strong>${pointInfo.value}</strong> ${this.measurement}`,
      };
    },
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#chart-demo {
  height: 700px;
}
#chart {
  width: 100%;
  display: flex;
  height: 400px;
}
.content-linesgraph {
  z-index: 0;
}
</style>
