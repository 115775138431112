<template>
  <div class="content-gis-editor">
    <div
      id="mapSigpac"
      style="border-radius: 10px"
      :style="minHeight"
    />
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '@fortawesome/fontawesome-free';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import turf from 'turf';
import { mapState, mapActions } from 'vuex';
import { NOTIFY_TIME_LONG } from '@/js/constants';
import Api from '../../../services/Api';

export default {
  name: 'GISEditorSigpac',
  props: {
    zoom: { type: Number, default: 1 },
    height: { type: String, default: '55vh' },
    nameMap: { type: String, default: '' },
    treeLocations: {
      type: Boolean,
      default: false,
    },
    disabledEditParcel: {
      type: Boolean,
      default: true,
    },
    disabledEditSector: {
      type: Boolean,
      default: true,
    },
    createSector: {
      type: Boolean,
      default: false,
    },
    showMarker: {
      type: Boolean,
      default: false,
    },
    showHomeButton: {
      type: Boolean,
      default: true,
    },
    showGPSButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentLayerBase: {},
      event: 'updateGIS',
      map: null,
      layerBounds: null,
      parcel: null,
      sector: null,
      initialDate: Date.now(),
      layerGroup: L.layerGroup(),
      layerGroupParcel: L.layerGroup(),
      copyLayer: null,
      controlLayer: null,
      oldImageSigpac: null,
      pmControlConfig: {
        position: 'topright',
        drawPolygon: true,
        drawCircle: false,
        drawRectangle: false,
        drawPolyline: false,
        drawMarker: false,
        editMode: false,
        dragMode: false,
        cutPolygon: false,
        removalMode: false,
        drawCircleMarker: false,
        drawText: false,
        rotateMode: false,
      },
      pmControlConfigEdit: {
        position: 'topright',
        drawPolygon: false,
        drawCircle: false,
        drawRectangle: false,
        drawPolyline: false,
        drawMarker: false,
        editMode: true,
        dragMode: false,
        cutPolygon: false,
        removalMode: true,
        drawCircleMarker: false,
        drawText: false,
        rotateMode: false,
      },
      hectareToM2: 10000,
      layerStyles: {
        default: {
          color: 'rgba(255,0,224,0.7)',
          weight: 1,
          fillOpacity: 0.5,
        },
        active: {
          weight: 3,
          color: 'rgba(241,234,234,0.71)',
          dashArray: '',
          fillOpacity: 0.7,
        },
        selected: {
          weight: 2,
          color: 'rgba(250,250,120,0.71)',
          dashArray: '',
          fillOpacity: 0.7,
        },
      },
      markerLayer: L.layerGroup(),
      layer: {},
      layer2: {},
      drawMode: false,
      visibleSigpac: false,
      SIGPACSearch: null,
      polylineGroup: L.layerGroup(),
      center: null,
    };
  },
  computed: {
    minHeight() {
      return `min-height: ${this.height}`;
    },
    ...mapState('gis', ['osm', 'currentGeojsonFeature']),
    ...mapState('gisEditor', [
      'featureInfoSigpac',
      'sigpacMap',
      'centroide',
      'currentGeoFeature',
      'tempParcelGeoFeatures',
    ]),
    ...mapState('registerFarm', ['isEditingFarm']),
  },
  mounted() {
    if (this.treeLocations) {
      this.center = {
        latitude: this.centroide.lat,
        longitude: this.centroide.lng,
      };
    }
    this.renderMap();
    this.addFuncionalities();
    this.addOverlayLayer();
    this.addDrawControl();
    this.createLayer();
    this.layerEvents();
    this.layerParcelEvents();
    this.mapEvents();
    this.controlLayerEvents();
  },
  methods: {
    /**
     * Añadimos una capa tanto de tipo GeoJson como WMS a la lista de capas activas
     */
    async addOverlayLayer() {
      this.$f7.preloader.show();
      try {
        if (this.treeLocations) {
          this.map.setView(
            [this.centroide.lat, this.centroide.lng],
            this.zoom
          );
          this.layer = L.geoJson(this.currentGeoFeature, {
            pmIgnore: this.disabledEditParcel,
          }).addTo(this.map);

          this.parcel = this.layer;

          if (!this.disabledEditParcel) {
            this.layerGroup.addLayer(this.layer);
          }
        }
        if (this.center !== null) {
          this.map.setView(
            [this.center.latitude, this.center.longitude],
            this.zoom
          );
          if (this.showMarker) {
            this.setMarker();
          }
        } else if (!this.$helpers.isEmptyObject(this.layer)) {
          const bounds = this.layer.getBounds();
          this.map.fitBounds(bounds);
          this.centerMapGeoJsonLayer(this.layer);
        }
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },

    /**
     * Centramos el mapa cuando tenemos un GeoJson
     */
    centerMapGeoJsonLayer(layer) {
      const bounds = layer.getBounds();
      this.layerBounds = bounds;
      this.map.flyToBounds(bounds);
    },

    renderMap() {
      this.map = L.map('mapSigpac').setView(
        [41.6164989248106, -4.873517847881886],
        15
      );
      L.tileLayer(
        this.osm.googleHybrid.route,
        this.osm.googleHybrid.properties
      ).addTo(this.map);
      this.currentLayerBase = L.tileLayer(
        this.osm.googleHybrid.route,
        this.osm.googleHybrid.properties
      ).addTo(this.map);
      this.currentLayerBase.bringToBack();
      this.layerGroup.addTo(this.map);
      this.markerLayer.addTo(this.map);
      this.layerGroupParcel.addTo(this.map);
      this.polylineGroup.addTo(this.map);

      this.controlLayer = L.control
        .layers(null, null, { collapsed: false })
        .addTo(this.map);
      this.controlLayer.addBaseLayer(
        this.currentLayerBase,
        'Google Hybrid'
      );

      if (this.isEditingFarm) return;
      this.map
        .locate({ setView: true, maxZoom: 15 })
        .on('locationfound', (e) => {
          const marker = new L.Marker(e.latlng, {
            draggable: false,
          }).addTo(this.map);

          const redIcon = new L.Icon({
            iconUrl:
              'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-violet.png',
            shadowUrl:
              'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41],
          });
          marker.setLatLng(e.latlng);

          marker.setIcon(redIcon);
        })
        .on('locationerror', (error) => {
          this.map.setView(
            [41.6164989248106, -4.873517847881886],
            13
          );
        });
    },

    setMarker() {
      const marker = new L.Marker(
        {
          lat: this.center.latitude,
          lng: this.center.longitude,
        },
        {
          draggable: false,
        }
      ).addTo(this.map);
      const redIcon = new L.Icon({
        iconUrl:
          'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
        shadowUrl:
          'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      });
      marker.setIcon(redIcon);
    },

    addFuncionalities() {
      const self = this;
      try {
        L.control.scale().addTo(self.map);
        self.map.attributionControl.addAttribution(
          'Margaret from <a href="https://hispatecanalytics.com//">HispatecAnalytics SA</a>'
        );
        if (this.showHomeButton) {
          this.addHomeButton();
        }
        if (this.showGPSButton) {
          this.addGPSButton();
        }
        this.addSIGPACButton();
      } catch (e) {
        this.$f7.dialog.alert(e);
      }
    },

    addHomeButton() {
      const self = this;
      L.Control.zoomHome = L.Control.extend({
        options: {
          position: 'topleft',
          zoomHomeText: '<i class="f7-icons">house_fill</i>',
          zoomHomeTitle: 'Zoom geometría',
        },
        onAdd() {
          const controlName = 'gin-control-zoom';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          this._zoomHomeButton = this.createButton(
            options.zoomHomeText,
            options.zoomHomeTitle,
            `${controlName}-home`,
            container,
            this.zoomHome
          );

          return container;
        },

        zoomHome() {
          if (self.centroide) {
            self.center = {
              latitude: self.centroide.lat,
              longitude: self.centroide.lng,
            };
          } else {
            self.center = null;
          }

          if (self.center !== null) {
            self.map.setView(
              [self.center.latitude, self.center.longitude],
              20
            );
          } else {
            self.$notifyDX(
              {
                message: self.$t('Gis.mustDrawGeometry'),
                width: 350,
              },
              'error',
              NOTIFY_TIME_LONG
            );
          }
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      const zoomHome = new L.Control.zoomHome();
      zoomHome.addTo(self.map);
    },

    addGPSButton() {
      const self = this;
      L.Control.zoomLocation = L.Control.extend({
        options: {
          position: 'topleft',
          zoomLocationText: '<i class="f7-icons">placemark_fill</i>',
          zoomLocationTitle: 'Zoom localización',
        },
        onAdd() {
          const controlName = 'gin-control-zoom';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          this._zoomLocationButton = this.createButton(
            options.zoomLocationText,
            options.zoomLocationTitle,
            `${controlName}-home`,
            container,
            this.zoomLocation
          );

          return container;
        },

        zoomLocation() {
          let marker;

          self.map
            .locate({
              setView: true,
              maxZoom: 120,
            })
            .on('locationfound', (e) => {
              if (!marker) {
                marker = new L.Marker(e.latlng, {
                  draggable: false,
                }).addTo(self.markerLayer);
                const redIcon = new L.Icon({
                  iconUrl:
                    'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-violet.png',
                  shadowUrl:
                    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                  iconSize: [25, 41],
                  iconAnchor: [12, 41],
                  popupAnchor: [1, -34],
                  shadowSize: [41, 41],
                });
                marker.setIcon(redIcon);
                self.map.setZoom(self.zoom);
              } else {
                marker.setLatLng(e.latlng);
              }
            })
            .on('locationerror', (error) => {
              if (marker) {
                self.map.removeLayer(marker);
                marker = undefined;
              }
            });
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      const zoomLocation = new L.Control.zoomLocation();
      zoomLocation.addTo(self.map);
    },

    addSIGPACButton() {
      const self = this;
      L.Control.SIGPACSearch = L.Control.extend({
        options: {
          position: 'topright',
          SIGPACSearchText: '<i class="f7-icons">search</i>',
          SIGPACSearchTitle: 'SIGPAC search',
        },
        onAdd() {
          const controlName = 'gin-control-zoom';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          this._SIGPACSearchButton = this.createButton(
            options.SIGPACSearchText,
            options.SIGPACSearchTitle,
            `${controlName}-home`,
            container,
            this.SIGPACSearch
          );
          return container;
        },

        SIGPACSearch() {
          const html = self.getSigpacSearchForm();
          self.createModal(html);
          const searchSIGPACButton =
            document.getElementById('search-sigpac');

          searchSIGPACButton.addEventListener('click', (e) => {
            e.preventDefault();
            self.sigpacSearch();
            self.map.closePopup();
          });
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      this.SIGPACSearch = new L.Control.SIGPACSearch();
    },

    getSigpacSearchForm() {
      return `
      <div class="sigpac-inputs">
        <form id="sigpac-form">
          <label for="province">${this.$t('Gis.Province')}</label>
          <input id="province" name="provincia" type="number" min="0" value=''></input>
          <label for="municipality">${this.$t(
            'Gis.Municipality'
          )}</label>
          <input id="municipality" name="municipio" type="number" min="0" value=''></input>
          <label for="aggregate">${this.$t('Gis.Aggregate')}</label>
          <input id="aggregate" name="agregado" type="number" min="0" value=''></input>
          <label for="zone">${this.$t('Gis.Zone')}</label>
          <input id="zone" name="zona" type="number" min="0" value=''></input>
          <label for="polygon">${this.$t('Gis.Polygon')}</label>
          <input id="polygon" name="poligono" type="number" min="0" value=''></input>
          <label for="plot">${this.$t('Gis.Plot')}</label>
          <input id="plot" name="parcela" type="number" min="0" value=''></input>
          <label for="enclosure">${this.$t('Gis.Enclosure')}</label>
          <input id="enclosure" name="recinto" type="number" min="0" value=''></input>
          
        <br>
        </form>
        <div class="button-container">
          <button id='search-sigpac'>${this.$t('Search')}</button>
          <p class="modal-message"></p>
        </div>
      </div>
      `;
    },

    async sigpacSearch() {
      const urlBase = `${Api.getBaseUrlGis()}/gisw/ws/`;
      const searchSIGPACButton =
        document.getElementById('sigpac-form').elements;
      const obj = {};
      for (let i = 0; i < searchSIGPACButton.length; i++) {
        const item = searchSIGPACButton.item(i);
        obj[item.name] = item.value;
      }

      const route =
        'sigpac_ref/?cql_filter=(' +
        `provincia=${obj.provincia}%20` +
        `and%20municipio=${obj.municipio}%20` +
        `and%20agregado=${obj.agregado}%20` +
        `and%20zona=${obj.zona}%20` +
        `and%20poligono=${obj.poligono}%20` +
        `and%20parcela=${obj.parcela}%20` +
        `and%20recinto=${obj.recinto})`;

      const finalUrl = urlBase + route;
      try {
        this.$f7.preloader.show();
        await this.fetchCurrentGeojsonFeature(finalUrl);
        const parcel = this.currentGeojsonFeature;
        const features = parcel.features[0];
        const geoJson = L.geoJson([features.geometry]);
        const turfGeoJson = geoJson.toGeoJSON();
        const inverseGeoJsonCoordinates = turf.flip(turfGeoJson);
        const coordinates =
          inverseGeoJsonCoordinates.features[0].geometry
            .coordinates[0];
        const bounds = L.latLngBounds(coordinates);
        this.map.fitBounds(bounds);
        if (this.polyline !== null) {
          this.polylineGroup.clearLayers();
          this.polyline = L.polyline(coordinates, {
            color: 'red',
          }).addTo(this.polylineGroup);
        }
        this.polyline = L.polyline(coordinates, {
          color: 'red',
        }).addTo(this.polylineGroup);
        this.map.fitBounds(this.polyline.getBounds());
      } catch (error) {
        const isDataNotFilled = error
          .toString()
          .includes('at position 0');

        if (isDataNotFilled) {
          error.message += ' sigpac';

          this.$notifyDX(
            {
              message: this.$t(
                this.$helpers.getFilteredErrorMessage(error)
              ),
              width: 450,
            },
            'error',
            NOTIFY_TIME_LONG
          );

          return;
        }

        this.$f7.dialog.alert(
          this.$t(this.$helpers.getFilteredErrorMessage(error))
        );
      } finally {
        this.$f7.preloader.hide();
      }
    },

    createModal(content) {
      const html = `
            <div id="myModal" class="modal">
                <div id="modal-content">
                    ${content}
                </div>
            </div>`;

      const center = this.map.getBounds().getCenter();
      this.openPopup(html, [center.lat, center.lng]);
    },

    addDrawControl() {
      if (this.treeLocations.length === 0 || this.createSector) {
        this.map.pm.addControls(this.pmControlConfig);
      } else {
        this.map.pm.addControls(this.pmControlConfigEdit);
      }
    },

    createLayer() {
      const self = this;
      this.map.on('pm:create', (e) => {
        try {
          self.$f7.preloader.show();
          if (this.parcel !== null && this.disabledEditParcel) {
            const sector = e.layer;

            // const sectorToGeoJson = sector.toGeoJSON();
            // const parcelToGeoJson = this.parcel.toGeoJSON();

            // const sectorToTurf = turf.polygon(sectorToGeoJson.geometry.coordinates);
            // const parcelToTurf = turf.polygon(parcelToGeoJson.features[0].geometry.coordinates);

            // const intersection = turf.intersect(sectorToTurf, parcelToTurf);

            // const result = L.geoJson(intersection);
            sector.setStyle(this.layerStyles.active);
            // this.layerGroup.addLayer(result);
            // this.map.removeLayer(sector);
            // this.sector = result;
            this.map.pm.addControls(this.pmControlConfigEdit);
            this.setLocation(this.getNewLocation(sector, 0));
            this.layerEvents();
          } else {
            this.layerGroup.addLayer(e.layer);
            this.map.pm.addControls(this.pmControlConfigEdit);
            this.setLocation(this.getNewLocation(e.layer, 0));
            this.layerEvents();
          }
          this.shouldSaveBtnBeDisabledOnEdit();
        } catch (error) {
          this.$f7.dialog.alert(e);
        } finally {
          self.$f7.preloader.hide();
        }
      });
      this.map.on('pm:globaldrawmodetoggled', (e) => {
        this.drawMode = e.enabled;
      });
    },
    farmIntersectsWithParcels() {
      const farmToTurf = turf.polygon(
        this.currentGeoFeature.features[0].geometry.coordinates
      );

      return this.tempParcelGeoFeatures.some((parcel) =>
        turf.intersect(
          farmToTurf,
          turf.polygon(
            parcel.geo_feature.features[0].geometry.coordinates
          )
        )
      );
    },
    shouldSaveBtnBeDisabledOnEdit() {
      if (this.isEditingFarm) {
        if (
          !this.currentGeoFeature.features[0].geometry.coordinates
            .length > 0
        ) {
          this.$emit('disableSaveButton', true);
        } else {
          this.$emit('disableSaveButton', false);
        }
      }
    },
    getNewLocation(layer, index, areaAndCentroide = false) {
      const polygon = layer.toGeoJSON();
      let coordinates = [];

      if (areaAndCentroide) {
        return {
          area: turf.area(polygon) / this.hectareToM2,
          centroide: layer.getBounds().getCenter(),
        };
      }

      // eslint-disable-next-line no-underscore-dangle
      if (typeof layer._latlngs !== 'undefined') {
        coordinates = this.$helpers.getCoordinates(
          layer.getLatLngs()[index]
        );
        coordinates[0].push(coordinates[0][0]);
      } else {
        coordinates = this.$helpers.getCoordinates(
          layer.pm._layers[0].getLatLngs()[0]
        );
        coordinates[0].push(coordinates[0][0]);
      }

      const obj = {
        newCoordinates: coordinates,
        index,
        area: turf.area(polygon) / this.hectareToM2,
        centroide: layer.getBounds().getCenter(),
        originSigpacEditor: true,
        atIndex: this.currentGeoFeature.numberReturned ? 0 : -1,
      };

      if (this.createSector) {
        this.setParcelArea(obj.area);
        this.setParcelCentroide(obj.centroide);
      }

      return obj;
    },
    layerEvents() {
      const self = this;
      this.layerGroup.eachLayer((layer) => {
        layer.on({
          mouseout(e) {
            self.map.closePopup(e);
          },
          'pm:edit': (e) => {
            try {
              self.$f7.preloader.show();
              if (this.parcel !== null && this.disabledEditParcel) {
                // const sector = e.layer;

                // const sectorToGeoJson = sector.toGeoJSON();
                // const parcelToGeoJson = self.parcel.toGeoJSON();

                // const sectorToTurf = turf.polygon(sectorToGeoJson.geometry.coordinates);
                // const parcelToTurf = turf.polygon(parcelToGeoJson.features[0].geometry.coordinates);

                // const intersection = turf.intersect(sectorToTurf, parcelToTurf);

                // const result = L.geoJson(intersection);
                // result.setStyle(self.layerStyles.active);
                // this.layerGroup.addLayer(result);
                this.layerGroup.addLayer(e.layer);

                // this.sector = result;
                // self.map.removeLayer(sector);

                self.layerEvents();
                self.setLocation(self.getNewLocation(e.layer, 0));
                // self.setLocation(self.getNewLocation(this.sector, 0));
              } else {
                self.setLocation(self.getNewLocation(e.layer, 0));
              }

              if (
                this.isEditingFarm &&
                !this.currentGeoFeature.numberReturned
              ) {
                if (!self.farmIntersectsWithParcels()) {
                  this.$notifyDX(
                    {
                      message: this.$t('Gis.parcelOutsideGeometry'),
                      width: 500,
                    },
                    'error',
                    NOTIFY_TIME_LONG
                  );

                  this.$emit('disableSaveButton', true);
                } else {
                  this.$emit('disableSaveButton', false);
                }
              }
            } catch (error) {
              this.$f7.dialog.alert(e);
            } finally {
              self.$f7.preloader.hide();
            }
          },
          // eslint-disable-next-line no-unused-vars
          'pm:remove': (e) => {
            self.map.pm.addControls(self.pmControlConfig);
            self.resetAll({ isCreatingParcel: self.createSector });

            self.shouldSaveBtnBeDisabledOnEdit();
          },
          'pm:enable': () => {
            this.drawMode = true;
          },
          'pm:disable': () => {
            this.drawMode = false;
          },
        });
      });
    },
    layerParcelEvents() {
      const self = this;
      this.layerGroupParcel.eachLayer((layer) => {
        layer.on({
          mouseout(e) {
            self.map.closePopup(e);
          },
          'pm:edit': (e) => {
            // if (self.visibleSigpac) {
            //   self.intersectSIGPACLayer(e);
            // }
            const editLayer = e.layer.toGeoJSON();
            if (e.layer.type === 'Draw Layer') {
              self.setLocation(self.getNewLocation(e.layer, 0));
            } else {
              self.featureInfoSigpac.features[0].geometry =
                editLayer.geometry;
            }
          },
          'pm:remove': (e) => {
            this.layerGroupParcel.clearLayers();
            self.map.pm.addControls(self.pmControlConfig);
            self.resetAll({ isCreatingParcel: self.createSector });
          },
          'pm:enable': () => {
            this.drawMode = true;
          },
          'pm:disable': () => {
            this.drawMode = false;
          },
        });
      });
    },
    createTemporalLayer(shape) {
      const geojsonFeature = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: shape.feature.geometry.coordinates,
        },
      };

      this.copyLayer = L.geoJson(geojsonFeature);
    },
    controlLayerEvents() {
      this.map.on('overlayadd', (e) => {
        if (e.name === 'SIGPAC') {
          this.visibleSigpac = true;
          this.SIGPACSearch.addTo(this.map);
        }
      });
      this.map.on('overlayremove', (e) => {
        if (e.name === 'SIGPAC') {
          this.visibleSigpac = false;
          this.SIGPACSearch.remove(this.map);
        }
      });
    },
    async mapEvents() {
      const overlaysGroup = L.layerGroup();

      this.map.on('click', async (e) => {
        if (this.drawMode) return;
        if (!this.visibleSigpac) return;
        L.DomEvent.stopPropagation(e);
        const BBOX = `${e.latlng.lng - 0.00000002},${
          e.latlng.lat - 0.00000002
        },${e.latlng.lng + 0.00000002},${e.latlng.lat + 0.00000002}`;
        const queryParams = {
          // eslint-disable-next-line no-underscore-dangle
          LowerCorner: `${`${
            this.map.getBounds()._southWest.lng
          } `}${`${this.map.getBounds()._southWest.lat} `}`,
          // eslint-disable-next-line no-underscore-dangle
          UpperCorner: `${`${
            this.map.getBounds()._northEast.lng
          } `}${`${this.map.getBounds()._northEast.lat} `}`,
          bboxgetfeatureinfo: BBOX,
        };
        const finalUrl =
          Api.getGeoServerFeatureInfo() +
          decodeURIComponent(
            L.Util.getParamString(
              queryParams,
              Api.getGeoServerFeatureInfo()
            )
          );
        try {
          this.$f7.preloader.show();
          await this.fetchFeatureInfoSigpac(finalUrl);
        } catch (error) {
          this.$f7.dialog.alert(this.$t(`${error}`));
        } finally {
          this.$f7.preloader.hide();
        }
        this.showSigpacPopup(this.featureInfoSigpac, e);
      });
      await this.showSigpacLayer(overlaysGroup);
      this.map.on('moveend', async () => {
        if (!this.visibleSigpac) return;
        await this.showSigpacLayer(overlaysGroup);
      });
    },
    async showSigpacLayer(overlaysGroup) {
      const sSIGPACLayerName = 'SIGPAC';
      let url = null;

      const defaultParameters = {
        // eslint-disable-next-line no-underscore-dangle
        LowerCorner: `${this.map.getBounds()._southWest.lng} ${
          this.map.getBounds()._southWest.lat
        }`,
        // eslint-disable-next-line no-underscore-dangle
        UpperCorner: `${this.map.getBounds()._northEast.lng} ${
          this.map.getBounds()._northEast.lat
        }`,
        width: this.map.getSize().x,
        height: this.map.getSize().y,
      };
      const parameters = L.Util.extend(defaultParameters);

      url =
        Api.getGeoServerSigpacMap() +
        L.Util.getParamString(parameters);
      try {
        this.$f7.preloader.show();
        await this.fetchSigpacMap(url);
        const imageBounds = [
          // eslint-disable-next-line no-underscore-dangle
          [
            this.map.getBounds()._southWest.lat,
            this.map.getBounds()._southWest.lng,
          ],
          // eslint-disable-next-line no-underscore-dangle
          [
            this.map.getBounds()._northEast.lat,
            this.map.getBounds()._northEast.lng,
          ],
        ];
        if (this.oldImageSigpac) {
          this.controlLayer.removeLayer(this.oldImageSigpac);
        }

        const imageOverlayNew = L.imageOverlay(
          this.sigpacMap.url,
          imageBounds
        );
        overlaysGroup.clearLayers();
        imageOverlayNew.addTo(overlaysGroup);
        this.oldImageSigpac = overlaysGroup;
        this.controlLayer.addOverlay(overlaysGroup, sSIGPACLayerName);
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },
    showSigpacPopup(data, e) {
      const features = data.features[0];
      const { properties } = features;
      const sigpacReference = `${properties.provincia}${properties.municipio}${properties.agregado}${properties.zona}${properties.poligono}${properties.parcela}${properties.recinto}`;
      const before = `<span><b>Referencia SIGPAC: </b> ${sigpacReference} </span>\n`;

      if (this.layerGroupParcel.getLayers().length === 0) {
        const after = `<div class="button-container"></div><button id="drawUHCbutton">${this.$t(
          'Gis.Editor.PopupGetGeometry'
        )}</button>`;

        this.openLayerDataPopup(properties, e, { before, after });
        const button = document.getElementById('drawUHCbutton');

        button.addEventListener('click', () => {
          const SIGPAClayer = L.geoJson([features.geometry]);
          SIGPAClayer.pm._layers[0].type = 'SIGPAC Layer';
          this.layerGroupParcel.addLayer(SIGPAClayer);
          this.map.closePopup();
          this.map.pm.addControls(this.pmControlConfigEdit);
          this.replaceCurrentGeoFeature(this.featureInfoSigpac);
          const areaData = this.getNewLocation(SIGPAClayer, 0, true);
          this.setCentroide(areaData.centroide);
          this.setArea(areaData.area);
          // this.setLocation(this.getNewLocation(SIGPAClayer, 0));
          this.layerParcelEvents();
        });
      } else {
        this.openLayerDataPopup(properties, e, { before });
      }
    },

    openLayerDataPopup(properties, e, extra = {}) {
      let htmlString = "<div class='popup-content'>";
      htmlString += extra.before || '';
      let listHtml = '<ul>';
      listHtml += this.getLayerDataPropertiesHtml(properties);
      listHtml += '</ul>';
      htmlString += `${listHtml}</div>`;
      htmlString += extra.after || '';
      this.openPopup(htmlString, e.latlng);
    },
    getLayerDataPropertiesHtml(properties) {
      let listHtml = '';
      for (const property in properties) {
        // eslint-disable-next-line no-continue
        if (
          properties[property] == null ||
          property === 'dn_surface' ||
          property === 'uso_sigpac' ||
          property === 'pend_media' ||
          property === 'coef_rega'
        )
          continue;
        if (typeof properties[property] === 'object') {
          listHtml += this.getLayerDataPropertiesHtml(
            properties[property]
          );
        } else if (property !== 'grp_cult' && property !== 'region') {
          listHtml += `
          <div style="display:flex; justify-content: space-between">
     
            <div>
              <strong>${property.capitalize()}:</strong>
            </div>


            <div>${Number(properties[property])}</div>
          </div>`;
        }
      }
      return listHtml;
    },
    openPopup(html, latlng) {
      L.popup({
        maxWidth: 40000,
        minWidth: 200,
        offset: [0, 6],
        className: 'SIGPACPopup',
      })
        .setLatLng(latlng)
        .setContent(html)
        .openOn(this.map);
    },
    ...mapActions('gisEditor', [
      'setLocation',
      'resetAll',
      'fetchFeatureInfoSigpac',
      'fetchSigpacMap',
      'setParcelArea',
      'setParcelCentroide',
      'replaceCurrentGeoFeature',
      'setCentroide',
      'setArea',
    ]),
    ...mapActions('gis', ['fetchCurrentGeojsonFeature']),
  },
};
</script>
<style lang="scss">
@import './Map.styles.scss';
</style>
