var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-summary-grouped-list"},[_c('div',{staticClass:"content-search margin-top-half"},[_c('div',[_c('DxTextBox',{attrs:{"value":_vm.searchText,"width":"250","placeholder":_vm.$t('search')},on:{"input":_vm.input}})],1),_vm._v(" "),_c('div',{staticClass:"margin-right-half buttons-container"},[(!_vm.enabled)?_c('button',{staticClass:"button button-fill display-flex",attrs:{"id":"newFarmBtn"}},[_c('span',{staticClass:"ic-newcrop margin-horizontal-half"})]):_vm._e(),_vm._v(" "),_c('DxContextMenu',{attrs:{"data-source":[
          {
            text: _vm.$t('registerFarm'),
            onClick: _vm.newFarm,
          },
          { text: _vm.$t('registerParcel'), onClick: _vm.newParcel },
        ],"width":200,"target":"#newFarmBtn","show-event":"dxclick"}})],1)]),_vm._v(" "),_c('f7-row',[_c('f7-col',{staticClass:"margin-top-half"},[_c('div',[_c('DxAccordion',{attrs:{"data-source":_vm.filteredList && _vm.filteredList.length > 0
              ? _vm.filteredList
              : _vm.dataSource,"collapsible":_vm.collapsible,"multiple":_vm.multiple,"animation-duration":_vm.animationDuration,"selected-index":-1},scopedSlots:_vm._u([{key:"title",fn:function({ data }){return [_c('div',{staticClass:"accordion-title"},[_c('div',{staticClass:"display-flex align-item-center"},[_c('div',{staticClass:"margin-right time-item badge",attrs:{"title":_vm.$t('lastSampling')}},[_c('span',{staticClass:"ic_clock margin-right-half"}),_vm._v("\n                  "+_vm._s(data.farmGeoFeature.geo_feature.features[0]
                      .properties.sampling_date
                      ? _vm.getMoment(
                          data.farmGeoFeature.geo_feature
                            .features[0].properties.sampling_date
                        ).format('DD-MM-YYYY')
                      : _vm.getMoment(new Date()).format('DD-MM-YYYY'))+"\n                ")]),_vm._v(" "),_c('span',{staticClass:"ic_crop margin-right-half"}),_vm._v(" "),_c('strong',{staticClass:"margin-right",attrs:{"title":_vm.$t('agronomySample.summary.estate') +
                    ' ' +
                    data.farm}},[_vm._v("\n                  "+_vm._s(data.farm)+"\n                ")]),_vm._v(" "),_c('span',{staticClass:"ic_user margin-right-half"}),_vm._v(" "),_c('span',{staticStyle:{"text-transform":"capitalize"},attrs:{"title":_vm.$t('accountable') + ' ' + data.farm}},[_vm._v("\n                  "+_vm._s(data.accountable)+"\n                ")])]),_vm._v(" "),_c('div',{staticClass:"accordion-buttons"},[(!_vm.enabled)?_c('a',{staticClass:"dx-link dx-icon-edit dx-link-icon",on:{"click":(e) => _vm.goToEditFarm(e, data)}}):_vm._e(),_vm._v(" "),_c('a',{staticClass:"dx-link dx-icon-find dx-link-icon",on:{"click":(e) => _vm.goToDashboard(e, data)}})])])]}},{key:"item",fn:function({ data }){return [_c('div',_vm._l((data.parcels),function(parcel,index){return _c('div',{key:parcel.geo_feature.features[0].geometry.coordinates.toString(),staticClass:"accordion-content margin-top-half"},[_c('div',{staticClass:"display-flex align-items-center"},[_c('span',{staticClass:"ic_trip margin-right-half"}),_vm._v(" "),_c('span',{staticClass:"item-title margin-right",staticStyle:{"text-transform":"capitalize"}},[_vm._v("\n                    "+_vm._s(parcel.geo_feature.features[0].properties
                        .parcel_name)+"\n                  ")]),_vm._v(" "),(
                      parcel.geo_feature.features[0].properties
                        .cultive
                    )?_c('span',{staticClass:"ic_cultive margin-right-half"}):_vm._e(),_vm._v(" "),(
                      parcel.geo_feature.features[0].properties
                        .cultive
                    )?_c('span',{staticClass:"margin-right"},[_vm._v("\n                    "+_vm._s(parcel.geo_feature.features[0].properties
                        .cultive)+"\n                  ")]):_vm._e(),_vm._v(" "),(
                      parcel.geo_feature.features[0].properties
                        .variety
                    )?_c('span',{staticClass:"ic_variety margin-right-half"}):_vm._e(),_vm._v(" "),(
                      parcel.geo_feature.features[0].properties
                        .variety
                    )?_c('span',{staticClass:"margint-right"},[_vm._v("\n                    "+_vm._s(parcel.geo_feature.features[0].properties
                        .variety)+"\n                  ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"accordion-content-buttons"},[(!_vm.enabled)?_c('a',{staticClass:"dx-link dx-icon-map",on:{"click":(e) => _vm.goToAssignStations(e, parcel, data)}}):_vm._e(),_vm._v(" "),(!_vm.enabled)?_c('a',{staticClass:"dx-link dx-icon-edit dx-link-icon",on:{"click":(e) => _vm.goToEditParcel(e, parcel, index, data)}}):_vm._e()])])}),0)]}}])})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }