export default {
  namespaced: true,
  state: {
    showPopover: false,
  },
  actions: {
    setShowPopover({ commit }, value) {
      commit('setShowPopover', value);
    },
  },
  mutations: {
    setShowPopover(state, value) {
      state.showPopover = value;
    },
  },
};
