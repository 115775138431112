export default {
  namespaced: true,
  state: {
    fromDate: null,
    toDate: null,
    selectedSampleTypes: [],
    selectedLMRs: null,
    selectedParameters: [],
    isParamsSelected: false,
    isCompanyAggregated: false,
    selectedAnalyseTypes: [],
    selectedThirdClients: [],
    selectedSamplers: [],
    availableLmrs: [],
    isComparative: false,
    isGlobalAGQ: false,
  },
  actions: {
    setToDate({ commit }, value) {
      commit('setToDate', value);
    },
    setFromDate({ commit }, value) {
      commit('setFromDate', value);
    },
    setSelectedSampleTypes({ commit }, value) {
      commit('setSelectedSampleTypes', value);
    },
    setSelectedLmrs({ commit }, value) {
      commit('setSelectedLmrs', value);
    },
    setSelectedParameters({ commit }, value) {
      commit('setSelectedParameters', value);
    },
    setIsParamsSelected({ commit }, value) {
      commit('setIsParamsSelected', value);
    },
    setIsCompanyAggregated({ commit }, value) {
      commit('setIsCompanyAggregated', value);
    },
    setSelectedAnalyseTypes({ commit }, value) {
      commit('setSelectedAnalyseTypes', value);
    },
    setSelectedThirdClients({ commit }, value) {
      commit('setSelectedThirdClients', value);
    },
    setSelectedSamplers({ commit }, value) {
      commit('setSelectedSamplers', value);
    },
    setIsComparative({ commit }, value) {
      commit('setIsComparative', value);
    },
    setIsGlobalAGQ({ commit }, value) {
      commit('setIsGlobalAGQ', value);
    },
    setAvailableLmrs({ commit }, value) {
      commit('setAvailableLmrs', value);
    },
    clearFilters({ commit }) {
      commit('clearFilters');
    },
  },
  mutations: {
    setFromDate(state, value) {
      state.fromDate = value;
    },
    setToDate(state, value) {
      state.toDate = value;
    },
    setSelectedSampleTypes(state, value) {
      state.selectedSampleTypes = value;
    },
    setSelectedLmrs(state, value) {
      state.selectedLMRs = value;
    },
    setSelectedParameters(state, value) {
      state.selectedParameters = value;
    },
    setIsParamsSelected(state, value) {
      state.isParamsSelected = value;
    },
    setIsCompanyAggregated(state, value) {
      state.isCompanyAggregated = value;
    },
    setSelectedAnalyseTypes(state, value) {
      state.selectedAnalyseTypes = value;
    },
    setSelectedThirdClients(state, value) {
      state.selectedThirdClients = value;
    },
    setSelectedSamplers(state, value) {
      state.selectedSamplers = value;
    },
    setIsComparative(state, value) {
      state.isComparative = value;
    },
    setIsGlobalAGQ(state, value) {
      state.isGlobalAGQ = value;
    },
    setAvailableLmrs(state, value) {
      state.availableLmrs = [];
      state.availableLmrs = value;
    },
    clearFilters(state) {
      state.fromDate = null;
      state.toDate = null;
      state.selectedSampleTypes = [];
      state.selectedLMRs = [];
      state.selectedParameters = [];
      state.isParamsSelected = false;
      state.isCompanyAggregated = false;
      state.selectedAnalyseTypes = [];
      state.selectedThirdClients = [];
      state.selectedSamplers = [];
      state.availableLmrs = [];
      state.isComparative = false;
      state.isGlobalAGQ = false;
    },
  },
};
