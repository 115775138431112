<template>
  <f7-page>
    <div v-if="loaded">
      <div class="tableContainer">
        <!-- <br /> -->
        <HealthSecuritySamplesTable :store="customStore" />
      </div>
    </div>
  </f7-page>
</template>
<script>
import { mapActions } from 'vuex';
import HealthSecuritySamplesTable from '@/components/HealthAndSecurity/SamplesTable/index.vue';
import EventBus from '@/services/event-bus';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  name: 'HealthAndSecuritySamplesPage',
  components: {
    HealthSecuritySamplesTable,
  },
  data() {
    return {
      loaded: false,
      customStore: null,
    };
  },
  beforeMount() {
    this.setCurrentRoute([
      {
        name: this.$t('Health and Security'),
        disabled: false,
        page: 'HealthAndSecuritySamplesPage',
        active: false,
      },
      {
        name: this.$t('agronomySample.exportExcelSampleTable'),
        disabled: true,
        page: 'HealthAndSecuritySamplesPage',
        active: true,
      },
    ]);
    this.setCurrentFilter('healthSecurity');
  },
  async mounted() {
    try {
      this.$f7.preloader.show();

      setTimeout(() => {
        this.applyCurrentFilters();
      }, 0);

      EventBus.$on('HealthAndSecuritySamplesPage', async (params) => {
        if (params) {
          this.customStore = await this.getStoreWithFilters(params);
        } else {
          this.customStore = await this.getStore();
        }
      });
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(error.message).error
            )
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    ...mapActions('breadcrumb', ['setCurrentRoute']),
    ...mapActions('healthSecuritySamples', [
      'getStore',
      'getStoreWithFilters',
    ]),
    ...mapActions('filter', [
      'setCurrentFilter',
      'applyCurrentFilters',
    ]),
  },
};
</script>
