<template>
  <f7-page class="nutritional-monitoring-page">
    <PopupPhitomonitoring
      v-if="showPopUpPhitomonitoring"
      @close="closePopUpPhitomonitoring"
    />
    <DxTabPanel
      class="tabs-full-width"
      :selected-index="selectedIndex"
      :data-source="tabsConfig"
      :loop="false"
      :animation-enabled="true"
      :swipe-enabled="false"
      @optionChanged="setSelectedIndex"
    >
      <template #title="{ data }">
        <span>{{ data.text }}</span>
      </template>
      <template #item="{ data }">
        <div>
          <div v-if="data.selectedIndex === 1">
            <div
              class="justify-content-end margin-bottom"
              style="padding-top: 10px"
            >
              <ContextMenu :id="'context-menu-btn'" :items="items" />
            </div>
            <div v-if="loaded" class="tableContainer">
              <DetailedTableSuelo
                :nutritional-monitoring-data="
                  NutritionalMonitoringData
                "
              />
              <DetailedTableAguas
                :nutritional-monitoring-data="
                  NutritionalMonitoringData
                "
              />
              <DetailedTableSfrDrenaje
                :nutritional-monitoring-data="
                  NutritionalMonitoringData
                "
              />
              <DetailedTableMaterialVegetal
                :nutritional-monitoring-data="
                  NutritionalMonitoringData
                "
              />
              <DetailedTableFloresFrutos
                :nutritional-monitoring-data="
                  NutritionalMonitoringData
                "
              />

              <div
                v-show="showEmptyTableMessage"
                style="text-align: center"
              >
                {{ $t('emptyTable') }}
              </div>
            </div>
            <div v-else>
              <div class="loadingFalse">
                <div class="preloader">
                  <span class="preloader-inner">
                    <span class="preloader-inner-circle"></span>
                  </span>
                </div>
              </div>
            </div>

            <NutritionalMonitoringChart />
          </div>
          <div v-else-if="data.selectedIndex === 2">
            <ExpertSystemPage />
          </div>
          <div v-else-if="data.selectedIndex === 3">
            <Phytomonitoring />
          </div>
        </div>
      </template>
    </DxTabPanel>
    <GoBack
      class="margin-top"
      :go-to-url-name="true"
      :url-name="getUrlName"
    />
  </f7-page>
</template>

<script>
import EventBus from '@/services/event-bus';
import { DxTabPanel } from 'devextreme-vue/tab-panel';
import DxButton from 'devextreme-vue/button';
import { mapActions, mapState } from 'vuex';
import format from 'date-fns/format';
import GoBack from '@/components/UtilityComponents/GoBack/index.vue';
import DxContextMenu from 'devextreme-vue/context-menu';
import ContextMenu from '@/components/contextMenu/index.vue';
import { NOTIFY_TIME_LONG } from '@/js/constants';
import PopupPhitomonitoring from '../../../../components/Agronomy/AdvancedServices/NutritionalMonitoring/PopupPhitomonitoring.vue';
import DetailedTableSfrDrenaje from '../../../../components/Agronomy/AdvancedServices/NutritionalMonitoring/DetailedTableSfrDrenaje.vue';
import DetailedTableAguas from '../../../../components/Agronomy/AdvancedServices/NutritionalMonitoring/DetailedTableAguas.vue';
import DetailedTableSuelo from '../../../../components/Agronomy/AdvancedServices/NutritionalMonitoring/DetailedTableSuelo.vue';
import DetailedTableMaterialVegetal from '../../../../components/Agronomy/AdvancedServices/NutritionalMonitoring/DetailedTableMaterialVegetal.vue';
import DetailedTableFloresFrutos from '../../../../components/Agronomy/AdvancedServices/NutritionalMonitoring/DetailedTableFloresFrutos.vue';
import NutritionalMonitoringChart from '../../../../components/Agronomy/AdvancedServices/NutritionalMonitoring/NutritionalMonitoringChart.vue';
import ExpertSystemPage from '../ExpertSystem/index.vue';
import LysimeterIoTPage from '../LysimeterIoTPage/index.vue';
import Phytomonitoring from '../Phytomonitoring/index.vue';

export default {
  name: 'NutritionalMonitoring',
  components: {
    DxTabPanel,
    DxButton,
    PopupPhitomonitoring,
    GoBack,
    DetailedTableSfrDrenaje,
    DetailedTableAguas,
    DetailedTableSuelo,
    DetailedTableMaterialVegetal,
    DetailedTableFloresFrutos,
    NutritionalMonitoringChart,
    ExpertSystemPage,
    LysimeterIoTPage,
    Phytomonitoring,
    DxContextMenu,
    ContextMenu,
  },
  data() {
    return {
      selectedValueChart: null,
      loaded: false,
      selectedIndex: 0,
      selectedRoute: 0,
      items: [
        {
          icon: 'download',
          text: `${this.$t('download')} ${this.$t('report')}`,
          onClick: this.downloadReport,
        },
      ],
      environment: process.env.NODE_ENV,
      NutritionalMonitoringData: {},
    };
  },
  computed: {
    ...mapState('nutritionalFollowing', ['redirectedFromSamePage']),
    ...mapState('nutritionalMonitoring', [
      'NutritionalGraphicData',
      'showPopUpPhitomonitoring',
      'parcelId',
      'lastRouteGeotoolbox',
      'id',
    ]),
    ...mapState('samples', ['last_sample_id']),
    ...mapState('registerFarm', ['farmStr', 'parcelStr']),
    ...mapState('user', ['userName', 'authenticatedUser']),
    getIconPath() {
      return `${
        this.environment === 'development' ? '../../' : './'
      }static/icons/options_burger.svg`;
    },
    showEmptyTableMessage() {
      let show = true;

      const types = [
        'Aguas',
        'SFR_y_drenaje',
        'Material_Vegetal_hojas',
        'Suelo',
        'Flores_y_frutos',
      ];

      for (const type of types) {
        if (this.NutritionalMonitoringData?.[type]?.data != null) {
          this.NutritionalMonitoringData[type].data.forEach(
            (element) => {
              Object.entries(element?.parameters ?? {}).forEach(
                ([key, value]) => {
                  if (
                    (value?.value.ResultadoPresentacion ?? '') !== ''
                  ) {
                    show = false;
                  }
                }
              );
            }
          );
        }
      }

      return show;
    },
    getUrlName() {
      if (this.lastRouteGeotoolbox) {
        return 'GisActionsPage';
      }

      return this.redirectedFromSamePage
        ? 'NutritionalMonitoringMainPage'
        : 'DashboardPage';
    },

    tabsConfig() {
      const tabsConfig = [
        {
          id: 0,
          text: this.$t('nutritionalMonitoring'),
          selectedIndex: 1,
        },
      ];
      if (this.authenticatedUser.is_agq) {
        tabsConfig.push({
          id: 1,
          text: this.$t('expertSystem'),
          selectedIndex: 2,
        });
      }
      tabsConfig.push({
        id: 2,
        text: this.$t('Phytomonitoring'),
        selectedIndex: 3,
      });
      return tabsConfig;
    },
  },
  async beforeMount() {
    this.cleanData();
    this.cleanDataChart();
    this.getLastSampleId(this.parcelId);

    if (this.redirectedFromSamePage) {
      this.setCurrentRoute([
        {
          name: this.$t('Agronomy'),
          disabled: false,
          page: 'AgronomySamplesPage',
          active: false,
        },
        {
          name: this.$t('nutritionalMonitoring'),
          disabled: false,
          page: 'NutritionalMonitoringMainPage',
          active: false,
        },
        {
          name: this.parcelStr,
          disabled: true,
          page: 'NutritionalMonitoringMainPage',
          active: true,
        },
      ]);
    } else {
      this.setCurrentRoute([
        {
          name: this.$t('Agronomy'),
          disabled: false,
          page: 'AgronomySamplesPage',
          active: false,
        },
        {
          name: this.$t('advancedServices'),
          disabled: false,
          page: 'AdvancedServicesPage',
          active: false,
        },
        {
          name: this.farmStr,
          disabled: false,
          page: 'DashboardPage',
          active: false,
        },
        // {
        //   name: this.$t('nutritionalMonitoring'),
        //   disabled: true,
        //   page: 'NutritionalMonitoringPage',
        //   active: true,
        // },
        {
          name: this.parcelStr,
          disabled: true,
          page: 'NutritionalMonitoringMainPage',
          active: true,
        },
      ]);
    }

    const types = [
      'Aguas',
      'SFR_y_drenaje',
      'Material_Vegetal_hojas',
      'Suelo',
      'Flores_y_frutos',
    ];

    try {
      this.loaded = false;

      const NutritionalMonitoringData = {};
      const nutritionalsMonitoringData =
        await this.getDetalledNutritionalMonitoring();
      types.map(async (type) => {
        NutritionalMonitoringData[type] =
          nutritionalsMonitoringData[type];
        return nutritionalsMonitoringData;
      });
      this.NutritionalMonitoringData = NutritionalMonitoringData;
    } catch (e) {
      this.$notifyDX(
        {
          message: JSON.parse(e.message).error,
          width: 450,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    } finally {
      EventBus.$emit('setDefaultYearNutritionalMonitoring');
      this.loaded = true;
    }
  },
  methods: {
    ...mapActions('nutritionalMonitoring', [
      'getDetalledNutritionalMonitoring',
      'getNutritionalGraphicData',
      'togglePopUpPhitomonitoring',
      'cleanData',
      'cleanDataChart',
      'cleanStateFromAnotherModule',
    ]),
    ...mapActions('samples', [
      'setReportSampleId',
      'getReport',
      'getLastSampleId',
    ]),
    ...mapActions('breadcrumb', [
      'setCurrentRoute',
      'replaceCurrent',
    ]),
    setSelectedIndex(e) {
      if (e.name === 'selectedIndex') {
        this.selectedRoute = e.value;
      }
    },
    async downloadReport() {
      if (this.last_sample_id == null || this.last_sample_id === '') {
        return;
      }

      this.setReportSampleId(this.last_sample_id);
      const report = await this.getReport();

      if (report.name && report.pdfB64) {
        const link = document.createElement('a');
        link.href = `data:application/octet-stream;base64,${report.pdfB64}`;
        link.download = report.name;
        link.click();

        URL.revokeObjectURL(link.href);
      } else {
        this.$notifyDX(
          {
            message: this.$t('sampleHasNoReport'),
            width: 450,
          },
          'info',
          2000
        );
      }
    },
    openPopUpPhitomonitoring() {
      this.togglePopUpPhitomonitoring(true);
    },
    closePopUpPhitomonitoring() {
      this.togglePopUpPhitomonitoring(false);
    },
    formatDate(date) {
      if (!date) return '';
      return ` ${format(new Date(date), 'dd-MM-yyyy')}`;
    },
    showDataGrid(data) {
      let show = false;
      for (const row of data) {
        const copyRow = { ...row };
        delete copyRow.tipo_muestra;
        const values = Object.values(copyRow);
        const isNotEmpty = values.some(
          (value) =>
            value !== '' && value !== null && value !== undefined
        );

        if (isNotEmpty) {
          show = true;
          break;
        }
      }

      return show;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './NutritionalMonitoring.styles.scss';
</style>
