<template>
  <div>
    <DxPopup :visible="showNewContactPopup" :drag-enabled="false" :close-on-outside-click="false"
      :show-close-button="true" :show-title="true" width="525" height="auto" container=".dx-viewport" :title="editData.isEditing ? $t('editContact') : $t('registerContact')
        " @hiding="hide">
      <f7-row class="margin padding">
        <f7-col>
          <form @submit.prevent="saveNewContact">
            <DxForm id="user-personal-info" class="ha-form" :col-count="1" :form-data="formData" label-location="top">
              <DxItem :editor-options="{ disabled: false, width: 459 }" data-field="name">
                <DxRequiredRule :message="$t('newContactForm.requiredName')" />

                <DxLabel :text="$t('name')" />
              </DxItem>
              <DxItem data-field="surname" :editor-options="{ disabled: false, width: 459 }">
                <DxRequiredRule :message="$t('newContactForm.requiredSurname')" />

                <DxLabel :text="$t('surname')" />
              </DxItem>

              <DxItem class="ha-form__language" :editor-options="{
                items: formData.langs,
                displayExpr: translateLanguageOption,
                width: 459,
                placeholder: $t('select'),
              }" data-field="language" editor-type="dxSelectBox">
                <DxRequiredRule :message="$t('newContactForm.requiredLang')" />
                <DxLabel :text="$t('language')" />
              </DxItem>

              <DxItem :editor-options="{
                disabled: false,
                width: 459,
              }" data-field="phone">
                <DxRequiredRule :message="$t('newContactForm.requiredPhone')" />
                <DxLabel :text="$t('phone')" />
              </DxItem>

              <DxItem data-field="email" :editor-options="{
                disabled: editData.isEditing,
                width: 459,
                elementAttr: { id: 'itemEmail' },
              }">
                <DxRequiredRule v-if="!editData.isEditing" :message="$t('newContactForm.requiredEmail')" />

                <DxEmailRule v-if="!editData.isEditing" :message="$t('newContactForm.invalidMail')" />

                <DxLabel :text="$t('email')" />
              </DxItem>
            </DxForm>

            <f7-row>
              <f7-col width="100" class="treeview-column margin-top">
                <div class="treeview-container">
                  <p class="addresses-title">{{ $t('addresses') }}: <i style="color: red">*</i></p>
                  <DxTreeView class="custom-treeview" :width="443" :height="120" :items="addresses"
                    show-check-boxes-mode="normal" selection-mode="multiple" :select-nodes-recursive="true"
                    :select-by-click="false" display-expr="name" @selection-changed="treeViewSelectionChanged"
                    @content-ready="treeViewContentReady" />
                </div>
              </f7-col>
              <span v-if="notEnoughAddressesSelected" style="color: red">{{ $t('newContactForm.addressesRequired') }}
              </span>
            </f7-row>
            <f7-row class="justify-content-center">
              <f7-col class="marginTop" width="100" style="text-align: center">
                <DxButton id="botonGuardar" class="custom-button" :use-submit-behavior="true" :text="$t('save')"
                  type="outlined" />
              </f7-col>
            </f7-row>
          </form>
        </f7-col>
      </f7-row>
    </DxPopup>
  </div>
</template>

<script>
import { DxPopup } from 'devextreme-vue/popup';
import { mapActions, mapState } from 'vuex';

import { DxForm, DxItem, DxLabel } from 'devextreme-vue/form';
import DxButton from 'devextreme-vue/button';

import notify from 'devextreme/ui/notify';
import { ALL_LANGUAGES, NOTIFY_TIME_LONG } from '@/js/constants';
import DxTreeView from 'devextreme-vue/tree-view';

import {
  DxRequiredRule,
  DxEmailRule,
} from 'devextreme-vue/validator';

export default {
  components: {
    DxPopup,
    DxForm,
    DxItem,
    DxRequiredRule,
    DxLabel,
    DxButton,
    DxEmailRule,
    DxTreeView,
  },
  props: {
    editData: {
      type: Object,
      default: () => {
        return {
          isEditing: false,
          data: null,
        };
      },
    },
  },
  data() {
    return {
      formData: {
        name: '',
        surname: '',
        langs: ALL_LANGUAGES,
        phone: '',
        email: '',
        language: '',
      },
      addresses: [],
      selectedAddresses: [],
      notEnoughAddressesSelected: false,
    };
  },
  computed: {
    ...mapState('contact', {
      availableAddresses: 'addresses',
      showNewContactPopup: 'showNewContactPopup',
    }),
    ...mapState('clientMenu', ['items']),
    ...mapState('contact', ['company']),
  },
  beforeMount() {
    this.addresses = this.availableAddresses;

    if (this.editData.isEditing) {
      this.formData.name = this.editData.data.name;
      this.formData.surname = this.editData.data.surname;
      this.formData.language = this.getLanguageObj(
        this.editData.data.lang
      );
      this.formData.phone = this.editData.data.phone;
      this.formData.email = this.editData.data.email;
    }
  },
  methods: {
    hide() {
      this.setShowNewContactPopup(false);
    },
    async saveNewContact() {
      if (!this.validate()) return;
      this.$f7.preloader.show();
      if (this.editData.isEditing) {
        try {
          const data = {
            name: this.formData.name,
            surname: this.formData.surname,
            language: this.formData.language.key,
            companyId: this.editData.data.companyId,
            contactCode: this.editData.data.contactCode,
            addresses: this.selectedAddresses,
            phone: this.formData.phone,
          };

          notify(
            {
              message: this.$t('editing'),
              width: 450,
            },
            'info',
            2000
          );

          await this.edit(data);

          setTimeout(async () => {
            this.$f7.preloader.show();
            await this.getUsers();
            this.$f7.preloader.hide();
          }, 500);

          this.hide();
          notify(
            {
              closeOnClick: true,
              message: this.$t('editSuccess'),
              width: 450,
            },
            'success',
            2000
          );
        } catch (error) {
          this.$notifyDX(
            {
              message: this.$t(
                this.$helpers.getFilteredErrorMessage(
                  JSON.parse(error.message).error
                )
              ),
              width: 550,
            },
            'error',
            NOTIFY_TIME_LONG
          );
        }
      } else {
        try {
          const data = {
            name: this.formData.name,
            surname: this.formData.surname,
            email: this.formData.email,
            language: this.formData.language.key,
            companyId: this.company,
            addresses: this.selectedAddresses,
            phone: this.formData.phone,
          };

          notify(
            {
              message: this.$t('contactCreating'),
              width: 450,
            },
            'info',
            2000
          );

          await this.create(data);

          setTimeout(async () => {
            this.$f7.preloader.show();
            await this.getUsers();
            this.$f7.preloader.hide();
          }, 500);

          this.hide();
          notify(
            {
              closeOnClick: true,
              message: this.$t('contactCreatedSuccess'),
              width: 450,
            },
            'success',
            2000
          );
        } catch (error) {
          notify(
            {
              message: this.$t(`errors.${error.message}`),
              width: 450,
            },
            'error',
            NOTIFY_TIME_LONG
          );

          this.hide();
        }
      }
      this.$f7.preloader.hide();
    },
    validate(e) {
      if (!this.formData.name) {
        this.formData.name = null;
        return false;
      }
      if (!this.formData.name.trim()) {
        return false;
      }

      if (!this.formData.surname) {
        this.formData.surname = null;
        return false;
      }

      if (!this.formData.surname.trim()) {
        return false;
      }

      if (!this.formData.language) {
        this.formData.language = null;
        return false;
      }

      if (!this.formData.phone) {
        this.formData.phone = null;
        return false;
      }

      if (!this.formData.phone.trim()) {
        return false;
      }

      if (!this.formData.email) {
        this.formData.email = null;
        return false;
      }

      if (!this.formData.email.trim()) {
        return false;
      }

      if (
        document.getElementById('itemEmail').children[0].children[0]
          .children[0].attributes['aria-invalid']
      ) {
        if (
          document.getElementById('itemEmail').children[0].children[0]
            .children[0].attributes['aria-invalid'].value === 'true'
        )
          return false;
      }

      if (this.selectedAddresses < 1) return false;

      return true;
    },
    treeViewSelectionChanged(e) {
      this.syncSelection(e.component);
    },
    syncSelectionEdit(treeView) {
      for (
        let i = 0;
        i < this.editData.data.addresses.length;
        i += 1
      ) {
        treeView.selectItem(
          this.editData.data.addresses[i].addressId
        );
        this.selectedAddresses.push(
          this.editData.data.addresses[i].code
        );
      }
    },
    treeViewContentReady(e) {
      if (this.editData.isEditing) {
        if (this.editData.data.addresses.length > 0) {
          e.component.unselectAll();
          this.syncSelectionEdit(e.component);
        } else {
          e.component.unselectAll();
        }
      } else {
        e.component.unselectAll();
        this.syncSelection(e.component);
      }
    },
    syncSelection(treeView) {
      const selectedAddresses = treeView
        .getSelectedNodes()
        .map((node) => node.itemData.addressCode);

      if (selectedAddresses.length > 0) {
        this.notEnoughAddressesSelected = false;
      } else {
        this.notEnoughAddressesSelected = true;
      }

      this.selectedAddresses = selectedAddresses;
    },
    translateLanguageOption(e) {
      if (e) return this.$t(e.value);
    },
    getLanguageObj(val) {
      let key = '';
      for (const lang in ALL_LANGUAGES) {
        if (ALL_LANGUAGES[lang].key === val) {
          key = ALL_LANGUAGES[lang];
        }
      }

      return key;
    },
    ...mapActions('contact', [
      'setShowNewContactPopup',
      'edit',
      'create',
    ]),
    ...mapActions('company', ['getUsers']),
  },
};
</script>
<style>
.treeview-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  background-color: #f9f9f9;
}

.addresses-title {
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.custom-treeview {
  border: none;
  border-radius: 0;
  margin-top: 5px;
}

#botonGuardar {
  width: 100%;
  margin-bottom: 0px;
  background-color: #595295;
  border: 2px solid #595295;
  color: #ffffff;
  border-radius: 5px;
  font-weight: bold;
  padding: 2px;
}

#botonGuardar:hover {
  background-color: #423d76;
  color: #fff;
}
</style>