<template>
  <div class="margin-top">
    <div class="filter-box">
      <div style="label-input">
        {{ $t('agronomySample.summary.sampleType') }}
        <span class="requiered-icon color-orange">*</span>
      </div>
      <div class="marginTop5">
        <DxSelectBox
          :data-source="sampleTypes"
          :search-enabled="true"
          :placeholder="$t('agronomySample.summary.sampleType')"
          display-expr="name"
          :value="selectedSampleTypes"
          :on-value-changed="sampleTypesChanged"
          :on-opened="openedSampleTypes"
          :on-content-ready="contentReady"
          :show-clear-button="true"
        >
        </DxSelectBox>
      </div>

      <div class="label-input margin-top-half">
        {{ $t('originCountry') }}
        <span class="requiered-icon color-orange">*</span>
      </div>
      <div class="marginTop5">
        <DxSelectBox
          :items="originCountries"
          :search-enabled="true"
          :placeholder="$t('originCountry')"
          display-expr="name"
          :value="selectedOriginCountry"
          :on-value-changed="originCountriesChanged"
          :on-opened="openedOriginCountries"
          :on-content-ready="contentReady"
          :show-clear-button="true"
        >
        </DxSelectBox>
      </div>

      <div class="label-input margin-top-half">
        {{ $t('destinationCountry') }}
        <span class="requiered-icon color-orange">*</span>
      </div>

      <div class="marginTop5">
        <DxTagBox
          :data-source="destinationCountries"
          :search-enabled="true"
          :multiline="true"
          :placeholder="$t('destinationCountry')"
          tag-template="tag"
          display-expr="name"
          value-expr="code"
          :value="selectedDestinationCountries"
          :on-value-changed="destinationCountriesChanged"
          :on-opened="openedDestinationCountries"
          :on-content-ready="contentReady"
          :show-selection-controls="true"
        >
          <template #tag="{ data }">
            <div class="purpleTag">
              <b>{{ data.name }}</b>
              <div class="dx-tag-remove-button xButton"></div>
            </div>
          </template>
        </DxTagBox>
      </div>
    </div>
  </div>
</template>

<script>
import { DxTagBox } from 'devextreme-vue/tag-box';
import { mapActions, mapState, mapGetters } from 'vuex';
import DxSelectBox from 'devextreme-vue/select-box';
import DataSource from 'devextreme/data/data_source';
import { ATCS_ES, UE_COUNTRIES } from '../../../../js/constants';

export default {
  name: 'RiskAnalysisFilter',
  components: {
    DxTagBox,
    DxSelectBox,
  },
  data() {
    return {
      sampleTypes: [],
      destinationCountries: [],
      originCountries: [],
      loaded: false,
    };
  },
  computed: {
    getOriginCountry() {
      return this.selectedOriginCountry != null
        ? this.selectedOriginCountry.code
        : this.userProfile.userCountry;
    },
    ...mapState('riskAnalysisFilter', [
      'selectedSampleTypes',
      'selectedDestinationCountries',
      'selectedOriginCountry',
    ]),
    ...mapGetters('user', ['userProfile']),
    ...mapState('contact', ['enabled']),
  },
  async mounted() {
    await this.setDefaultCountryInFilter();
  },
  methods: {
    async setDefaultCountryInFilter() {
      if (this.enabled) {
        const destinationCountries = await this.getDestinationCountries();
        const userCountry = destinationCountries.find(
          (x) => x.code === this.getOriginCountry
        );
        if (!userCountry) {
          // not in the list, check if european country
          if (UE_COUNTRIES.includes(this.getOriginCountry)) {
            const euCountry = destinationCountries.find(
              (x) => x.code === 'UE'
            );
            this.setSelectedEuropeanOriginCountry(
              this.getOriginCountry
            );
            this.originCountries.push(euCountry);
            this.setSelectedOriginCountry(euCountry);
          }
        } else {
          // in the list, show that country
          this.originCountries.push(userCountry);
          this.setSelectedOriginCountry(userCountry);
        }
      }
    },
    async openedSampleTypes(e) {
      const sampleTypes = await this.getSampleTypesSat({
        atc_name: ATCS_ES.food,
      });

      const nameOnlyFromsampleTypes = sampleTypes.map((name) => {
        return {
          name: name.sample,
          id: name.id,
        };
      });

      const samplesFormatted = nameOnlyFromsampleTypes.sort((a, b) =>
        a.name > b.name ? 1 : -1
      );

      this.sampleTypes = new DataSource({
        store: samplesFormatted,
        key: 'id',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedDestinationCountries(e) {
      const destinationCountries = await this.getDestinationCountries();

      const destinationCountriesFormatted = destinationCountries.sort(
        (a, b) => (a.name > b.name ? 1 : -1)
      );

      this.destinationCountries = new DataSource({
        store: destinationCountriesFormatted,
        key: 'id',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedOriginCountries(e) {
      if (this.originCountries.length > 0) {
        this.originCountries = [];
        this.originCountries.push({
          name: this.$t('loading'),
          code: this.$t('loading'),
          disabled: true,
        });
      }
      const originCountries = await this.getDestinationCountries();
      this.originCountries = originCountries.sort((a, b) =>
        a.name > b.name ? 1 : -1
      );

      this.loaded = true;
    },
    contentReady(e) {
      const arrayNotFoundItems = Array.from(
        document.getElementsByClassName('dx-empty-message')
      );

      this.loaded = false;
      arrayNotFoundItems.forEach((element) => {
        if (!this.loaded) {
          element.innerHTML = this.$t('loading');
        } else {
          element.innerHTML = this.$t('dataNotAvailable');
        }
      });

      this.loaded = false;
    },
    sampleTypesChanged(e) {
      this.setSelectedSampleTypes(e.value);
    },
    destinationCountriesChanged(e) {
      this.setSelectedDestinationCountries(e.value);
    },
    originCountriesChanged(e) {
      this.setSelectedOriginCountry(e.value);
    },
    ...mapActions('riskAnalysisFilter', [
      'setSelectedSampleTypes',
      'setSelectedDestinationCountries',
      'setSelectedOriginCountry',
      'setSelectedEuropeanOriginCountry',
    ]),
    ...mapActions('riskAnalysis', [
      'getSampleTypes',
      'getSampleTypesSat',
      'getDestinationCountries',
    ]),
  },
};
</script>
