<template>
  <div>
    <DxChart
      id="chart"
      :data-source="dataSource"
      @legend-click="onLegendClick"
    >
      <DxCommonSeriesSettings
        argument-field="name"
        type="stackedbar"
      />
      <DxLegend
        vertical-alignment="bottom"
        horizontal-alignment="center"
        item-text-position="right"
      />
      <DxTooltip
        :enabled="true"
        :z-index="999999"
        :interactive="true"
      />
      <DxSize :height="250" />
      <DxValueAxis :title="$t('stats.numSamples')" />
      <DxSeries
        value-field="samples_count"
        :name="samplesTitle"
        color="#16a34a"
      />
    </DxChart>
  </div>
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxLegend,
  DxTitle,
  DxTooltip,
  DxFont,
  DxValueAxis,
  DxSize,
} from 'devextreme-vue/chart';
import moment from 'moment';

export default {
  components: {
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxLegend,
    DxTitle,
    DxSize,
    DxTooltip,
    DxFont,
    DxValueAxis,
  },
  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
    samplesTitle: {
      type: String,
      default: 'Samples',
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },
    customizeTooltip(pointInfo) {
      if (pointInfo.seriesName === this.samplesTitle) {
        const code = pointInfo.point.data.sampleCode;
        const date = moment(pointInfo.point.data.date).format(
          'DD/MM/YYYY'
        );
        const result = pointInfo.point.data.value;

        const tableStyle =
          'border-spacing: 0;border-collapse: collapse;font-size: 11px;width: 120px;overflow: auto;border-bottom: 1px solid #ccc;';
        const thStyle =
          'width: 33%;min-width: 90px;white-space: nowrap;border: 1px solid #ccc;padding: 0 5px;';
        const tdStyle =
          'width: 70%;border: 1px solid #ccc;padding: 0 14px;';

        setTimeout(() => {
          document
            .getElementById('mir-graph-btn')
            .addEventListener('click', (e) => {
              this.$f7router.navigate(
                { name: 'MIRDetail' },
                {
                  animate: true,
                  transition: 'f7-fade',
                }
              );
            });
        }, 10);

        return {
          html: `<div>
                  <div style="font-size: 14px;font-weight: 600;margin-bottom: 10px;text-align: center;">${code}</div>
                  <table style="${tableStyle}">
                    <tbody>
                      <tr>
                        <th style="${thStyle}">sampling_date</th>
                        <td style="${tdStyle}">${date}</td>
                      </tr>
                      <tr>
                        <th style="${thStyle}">result</th>
                        <td style="${tdStyle}">${result}</td>
                      </tr>
                    </tbody>
                  </table>
                  <a 
                    id="mir-graph-btn"
                    style="display: flex; justify-content: center;background: #f3f4f6;margin-top: 10px; padding: 6px;border-radius: 5px;"
                    class="dx-link dx-icon-find dx-link-icon"
                  ></a>
                </div>`,
        };
      }

      return {
        html: '<div></div>',
      };
    },
  },
};
</script>
