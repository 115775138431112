<template>
  <div class="gis-actions-comparation">
    <div class="images-selector">
      <!-- <div>Section</div> -->
      <div class="selector-container">
        <div style="width: 100%">
          <div>{{ $t('geotoolbox.leftImage') }}</div>
          <div>
            <DxSelectBox
              v-model="leftImage"
              :items="images"
              display-expr="name"
            />
          </div>
        </div>
        <div style="width: 100%">
          <div>{{ $t('geotoolbox.rightImage') }}</div>
          <div>
            <DxSelectBox
              v-model="rightImage"
              :items="images"
              display-expr="name"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="tabs-container">
      <div
        class="tab-item"
        :class="{ active: selectedTabComparation === 1 }"
        @click="selectedTabComparation = 1"
      >
        {{ $t('geotoolbox.lateral') }}
      </div>
      <div
        class="tab-item"
        :class="{ active: selectedTabComparation === 2 }"
        @click="selectedTabComparation = 2"
      >
        {{ $t('geotoolbox.sweep') }}
      </div>
    </div>
    <div class="tabs-content">
      <div
        v-if="selectedTabComparation === 1"
        class="container-comparation"
      >
        <MapImageInterpolation
          :geometry="geometry"
          :image="leftImage"
        />
        <MapImageInterpolation
          :geometry="geometry"
          :image="rightImage"
        />
      </div>
      <div v-if="selectedTabComparation === 2" style="height: 100%">
        <GISWMSSwipeMap
          :image-left="leftImage"
          :image-right="rightImage"
          :geometry="geometry"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { DxSelectBox } from 'devextreme-vue/select-box';
import MapImageInterpolation from './MapImageInterpolation.vue';
import GISWMSSwipeMap from './GISWMSSwipeMap.vue';

export default {
  name: 'GisActionComparation',
  components: {
    DxSelectBox,
    MapImageInterpolation,
    GISWMSSwipeMap,
  },
  props: {
    geometry: {
      type: Object,
      default: () => null,
    },
    images: {
      type: Array,
      default: () => [],
    },
    selectedImage: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      selectedTabComparation: 1,
      leftImage: null,
      leftImages: [],
      rightImage: null,
      rightImages: [],
      center: {},
      bbox: {},
      zoom: 17,
    };
  },
  mounted() {
    if (this.selectedImage != null) {
      this.leftImage = this.images[this.selectedImage];
      this.rightImage = this.images[this.selectedImage];
    }
  },
};
</script>

<style scoped lang="scss">
@import './GisActionsComparation.styles.scss';
</style>
