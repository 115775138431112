import EventBus from '@/services/event-bus';

export default {
  namespaced: true,
  state: {
    samplingFromDate: null,
    samplingToDate: null,
    selectedFarms: [],
    selectedCrops: [],
    selectedParcels: [],
    selectedVarieties: [],
    //
    selectedFamilyTypes: null,
    selectedSampleTypes: null,
    selectedStudies: null,
  },
  actions: {
    setSamplingFromDate({ commit }, value) {
      commit('setSamplingFromDate', value);
    },
    setSamplingToDate({ commit }, value) {
      commit('setSamplingToDate', value);
    },
    setSelectedCrops({ commit }, value) {
      commit('setSelectedCrops', value);
    },
    setSelectedVarieties({ commit }, value) {
      commit('setSelectedVarieties', value);
    },
    setSelectedFarms({ commit }, value) {
      commit('setSelectedFarms', value);
    },
    setSelectedParcels({ commit }, value) {
      commit('setSelectedParcels', value);
    },
    clearFilters({ commit }, value) {
      commit('clearFilters', value);
    },
    //
    setSelectedFamilyTypes({ commit }, value) {
      commit('setSelectedFamilyTypes', value);
    },
    setSelectedSampleTypes({ commit }, value) {
      commit('setSelectedSampleTypes', value);
    },
    setSelectedStudies({ commit }, value) {
      commit('setSelectedStudies', value);
    },
    filterSamples({ commit, rootState }) {
      EventBus.$emit('filter-geotoolbox-samples', {
        sampling_date: [rootState.geotoolbox.samplingFromDate, rootState.geotoolbox.samplingToDate],
        sample_type_family_id:
          rootState.geotoolbox.selectedFamilyTypes,
        sample_type_id: rootState.geotoolbox.selectedSampleTypes,
        parcel_id: rootState.geotoolbox.selectedParcels,
        study: rootState.geotoolbox.selectedStudies,
      });
    },
  },
  mutations: {
    setSamplingFromDate(state, value) {
      state.samplingFromDate = value;
    },
    setSamplingToDate(state, value) {
      state.samplingToDate = value;
    },
    setSelectedCrops(state, value) {
      state.selectedCrops = value;
    },
    setSelectedVarieties(state, value) {
      state.selectedVarieties = value;
    },
    setSelectedFarms(state, value) {
      state.selectedFarms = value;
    },
    setSelectedParcels(state, value) {
      state.selectedParcels = value;
    },
    clearFilters(state) {
      state.samplingFromDate = null;
      state.samplingToDate = null;
      state.selectedFarms = [];
      state.selectedCrops = [];
      state.selectedParcels = [];
      state.selectedVarieties = [];
      //
      state.selectedFamilyTypes = null;
      state.selectedSampleTypes = null;
      state.selectedStudies = null;
    },
    //
    setSelectedFamilyTypes(state, value) {
      state.selectedFamilyTypes = value;
    },
    setSelectedSampleTypes(state, value) {
      state.selectedSampleTypes = value;
    },
    setSelectedStudies(state, value) {
      state.selectedStudies = value;
    },
  },
};
