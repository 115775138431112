import Api from '@/services/Api';

export default {
  namespaced: true,
  state: {
    farm: '',
    farmId: '',
    farmStr: '',
    parcel: '',
    parcelStr: '',
    parcelId: '',
    parcelGisId: '',
    parentParcelId: '',
    accountable: '',
    parcelIdStation: '',
    reset: false,
    stations: [],
    selectedStations: [],
    isEditingFarm: false,
    isCreatingParcel: false,
    isRecreatingParcel: false,
    parcelOverlaps: false,
    parcelsMirRs: [],
  },
  actions: {
    setFarm({ commit }, value) {
      commit('setFarm', value);
    },
    setParcelsMirRs({ commit }, value) {
      commit('setParcelsMirRs', value);
    },
    setFarmId({ commit }, value) {
      commit('setFarmId', value);
    },
    setFarmStr({ commit }, value) {
      commit('setFarmStr', value);
    },
    setParcel({ commit }, value) {
      commit('setParcel', value);
    },
    setParcelStr({ commit }, value) {
      commit('setParcelStr', value);
    },
    setAccountable({ commit }, value) {
      commit('setAccountable', value);
    },
    setIsReset({ commit }, value) {
      commit('setIsReset', value);
    },
    setStations({ commit }, value) {
      commit('setStations', value);
    },
    setSelectedStations({ commit }, value) {
      commit('setSelectedStations', value);
    },
    removeSelectedStation({ commit }, value) {
      commit('removeSelectedStation', value);
    },
    emptySelectedStations({ commit }, value) {
      commit('emptySelectedStations', value);
    },
    setIsEditingFarm({ commit }, value) {
      commit('setIsEditingFarm', value);
    },
    setParcelId({ commit }, value) {
      commit('setParcelId', value);
    },
    setParcelGisId({ commit }, value) {
      commit('setParcelGisId', value);
    },
    setParentParcelId({ commit }, value) {
      commit('setParentParcelId', value);
    },
    setIsCreatingParcel({ commit }, value) {
      commit('setIsCreatingParcel', value);
    },
    setParcelIdStation({ commit }, value) {
      commit('setParcelIdStation', value);
    },
    setIsRecreatingParcel({ commit }, value) {
      commit('setIsRecreatingParcel', value);
    },
    setParcelOverlaps({ commit }, value) {
      commit('setParcelOverlaps', value);
    },
    async saveFarmGeoFeature({ rootState, state, dispatch }, data) {
      try {
        const farmGeoFeature = rootState.gisEditor.currentGeoFeature;
        farmGeoFeature.features[0].properties.farm =
          rootState.registerFarm.farmStr;

        farmGeoFeature.features[0].properties.accountable =
          rootState.registerFarm.accountable;

        farmGeoFeature.features[0].properties.area =
          rootState.gisEditor.area;

        farmGeoFeature.features[0].properties.latitude =
          rootState.gisEditor.centroide.lat;

        farmGeoFeature.features[0].properties.longitude =
          rootState.gisEditor.centroide.lng;

        const childrenGeoFeature =
          rootState.gisEditor.currentParcelGeoFeature.map(
            (geoFeature) => {
              if (!geoFeature.features[0].properties) {
                geoFeature.features[0].properties = {};
              }

              geoFeature.features[0].properties.farm =
                geoFeature.features[0].properties.farm ||
                rootState.registerFarm.farmStr;
              geoFeature.features[0].properties.accountable =
                geoFeature.features[0].properties.accountable ||
                rootState.registerFarm.accountable;
              geoFeature.features[0].properties.area =
                geoFeature.features[0].properties.area ||
                rootState.gisEditor.parcelArea;
              geoFeature.features[0].properties.latitude =
                geoFeature.features[0].properties.latitude ||
                rootState.gisEditor.parcelCentroide.lat;
              geoFeature.features[0].properties.longitude =
                geoFeature.features[0].properties.longitude ||
                rootState.gisEditor.parcelCentroide.lng;
              geoFeature.features[0].properties.parcel_name =
                geoFeature.features[0].properties.parcel_name ||
                state.parcelStr;
              geoFeature.features[0].properties.parcel_id =
                geoFeature.features[0].properties.parcel_id ||
                state.parcelId;

              return geoFeature;
            }
          );

        if (state.isEditingFarm) {
          /** childrenGeoFeature.push(
            ...rootState.gisEditor.tempParcelGeoFeatures.map(
              (el) => el.geo_feature
            )
          ); */
          const res = await Api.updateGeoFeature(
            {
              id: state.parcelId,
              name: childrenGeoFeature[0].features[0].properties
                .parcel_name,
              code: state.farmId,
              type: 'ORGANIZATIVE',
              coordinates: {
                latitude:
                  childrenGeoFeature[0].features[0].properties
                    .latitude,
                longitude:
                  childrenGeoFeature[0].features[0].properties
                    .longitude,
              },
              geoFeature: childrenGeoFeature[0],
              parent: state.farm,
            },
            rootState.contact.company,
            rootState.contact.address
          );

          return Promise.resolve(JSON.parse(res.response));
        }

        if (state.isCreatingParcel) {
          const parcelsWithoutId = [
            rootState.gisEditor.currentParcelGeoFeature[
              rootState.gisEditor.currentParcelGeoFeature.length - 1
            ],
          ];
          parcelsWithoutId[0].features[0].properties.accountable =
            state.accountable;
          parcelsWithoutId[0].features[0].properties.area =
            rootState.gisEditor.parcelArea;
          parcelsWithoutId[0].features[0].properties.farm =
            state.farmStr;
          parcelsWithoutId[0].features[0].properties.latitude =
            rootState.gisEditor.parcelCentroide.lat;
          parcelsWithoutId[0].features[0].properties.longitude =
            rootState.gisEditor.parcelCentroide.lng;
          parcelsWithoutId[0].features[0].properties.parcel_id =
            state.parcelId;
          parcelsWithoutId[0].features[0].properties.parcel_name =
            state.parcelStr;

          const res = await Api.saveGeoFeature(
            {
              name: state.parcelStr,
              code: state.parcel,
              type: 'ORGANIZATIVE',
              coordinates: {
                latitude: rootState.gisEditor.parcelCentroide.lat,
                longitude: rootState.gisEditor.parcelCentroide.lng,
              },
              geoFeature: parcelsWithoutId[0],
              parent: state.farmId,
            },
            rootState.contact.company,
            rootState.contact.address
          );

          return Promise.resolve(JSON.parse(res.response));
        }

        if (state.isRecreatingParcel) {
          const parcelGeoFeature =
            rootState.gisEditor.currentParcelGeoFeature[
              rootState.gisEditor.parcelIndex
            ];
          const res = await Api.saveGeoFeature(
            {
              name: state.parcelStr,
              code: state.parcel,
              type: 'ORGANIZATIVE',
              coordinates: {
                latitude: rootState.gisEditor.parcelCentroide.lat,
                longitude: rootState.gisEditor.parcelCentroide.lng,
              },
              geoFeature: parcelGeoFeature,
              parent: state.farmId,
            },
            rootState.contact.company,
            rootState.contact.address
          );

          return Promise.resolve(JSON.parse(res.response));
        }

        const res = await Api.saveGeoFeature(
          {
            name: data.includesParent
              ? state.parcelStr
              : state.farmStr,
            code: data.includesParent ? state.parcel : state.farm,
            type: 'ORGANIZATIVE',
            coordinates: {
              latitude: rootState.gisEditor.centroide.lat,
              longitude: rootState.gisEditor.centroide.lng,
            },
            geoFeature: data.includesParent
              ? childrenGeoFeature[0]
              : farmGeoFeature,
            parent: data.includesParent ? data.parentId : null,
          },
          rootState.contact.company,
          rootState.contact.address
        );

        if (!data.includesParent) {
          const parsed = JSON.parse(res.response);
          state.farmId = parsed.id;
        }

        return Promise.resolve(JSON.parse(res.response));
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async updateFarmGeoFeature({ rootState, state }) {
      try {
        const farmGeoFeature = rootState.gisEditor.currentGeoFeature;

        farmGeoFeature.features[0].properties.farm =
          rootState.registerFarm.farmStr;

        farmGeoFeature.features[0].properties.accountable =
          rootState.registerFarm.accountable;

        farmGeoFeature.features[0].properties.area =
          rootState.gisEditor.area;

        farmGeoFeature.features[0].properties.latitude =
          rootState.gisEditor.centroide.lat;

        farmGeoFeature.features[0].properties.longitude =
          rootState.gisEditor.centroide.lng;

        const res = await Api.updateGeoFeature(
          {
            id: state.farm,
            name: state.farmStr,
            code: state.farmId,
            type: 'ORGANIZATIVE',
            coordinates: {
              latitude: rootState.gisEditor.centroide.lat,
              longitude: rootState.gisEditor.centroide.lng,
            },
            geoFeature: farmGeoFeature,
            parent: null,
          },
          rootState.contact.company,
          rootState.contact.address
        );

        return Promise.resolve(JSON.parse(res.response));
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async postHidrosophStationsForParcels({ rootState }) {
      try {
        const parcelGeoFeature = [
          rootState.gisEditor.currentParcelGeoFeature[
            rootState.gisEditor.parcelIndex
          ].features[0].geometry,
        ];
        const xhr = await Api.postHidrosophStationsForParcels(
          parcelGeoFeature,
          rootState.contact.company,
          rootState.contact.address
        );

        const response = JSON.parse(xhr.response);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async postFarmStations({ rootState, state }) {
      try {
        const xhr = await Api.postFarmStations(
          state.farm,
          state.selectedStations,
          rootState.contact.company,
          rootState.contact.address
        );

        const response = JSON.parse(xhr.response);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getParcelServices({ rootState, state }) {
      try {
        const xhr = await Api.getParcelServices(
          state.farmId,
          rootState.contact.company,
          rootState.contact.address
        );

        const response = JSON.parse(xhr.response);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    reset({ commit }, value) {
      commit('reset');
    },
    resetSelectedStations({ commit }, value) {
      commit('resetSelectedStations', value);
    },
    resetRecreateParcel({ commit }, value) {
      commit('resetRecreateParcel', value);
    },
  },
  mutations: {
    setFarm(state, value) {
      state.farm = value;
    },
    setFarmId(state, value) {
      state.farmId = value;
    },
    setParcel(state, value) {
      state.parcel = value;
    },
    setAccountable(state, value) {
      state.accountable = value;
    },
    setIsReset(state, value) {
      state.reset = value;
    },
    setFarmStr(state, value) {
      state.farmStr = value;
    },
    setParcelStr(state, value) {
      state.parcelStr = value;
    },
    setParcelOverlaps(state, value) {
      state.parcelOverlaps = value;
    },
    setStations(state, value) {
      state.stations = value.map((station, index) => {
        station.geo_json[0].features[0].properties.index = index;
        return station;
      });
    },
    setSelectedStations(state, value) {
      const foundStation = state.selectedStations.find(
        (station) => station.station_id === value.station_id
      );
      if (!foundStation) {
        state.selectedStations.push(value);
      }
    },
    removeSelectedStation(state, value) {
      state.selectedStations = state.selectedStations.filter(
        (station) => {
          return station.station_id !== value;
        }
      );
    },
    setIsEditingFarm(state, value) {
      state.isEditingFarm = value;
    },
    emptySelectedStations(state, value) {
      state.selectedStations = value;
    },
    setParcelId(state, value) {
      state.parcelId = value;
    },
    setParcelGisId(state, value) {
      state.parcelGisId = value;
    },
    setParentParcelId(state, value) {
      state.parentParcelId = value;
    },
    setIsCreatingParcel(state, value) {
      state.isCreatingParcel = value;
    },
    setParcelIdStation(state, value) {
      state.parcelIdStation = value;
    },
    resetSelectedStations(state, value) {
      state.selectedStations = [];
    },
    setParcelsMirRs(state, value) {
      state.parcelsMirRs = value;
    },
    setIsRecreatingParcel(state, value) {
      state.isRecreatingParcel = value;
    },
    reset(state, value) {
      state.farm = '';
      state.farmId = '';
      state.farmStr = '';
      state.parcel = '';
      state.parcelStr = '';
      state.parcelId = '';
      state.parentParcelId = '';
      state.accountable = '';
      state.parcelOverlaps = false;
    },
    resetRecreateParcel(state, value) {
      state.parcel = '';
      state.parcelStr = '';
      state.parcelId = '';
      state.parentParcelId = '';
    },
  },
};
