import countries from '../json/langs/countries_es.json';

export default {
  getCountryNameByCode(code) {
    const countryName = countries.find((x) => x.code === code);
    return countryName ? countryName.name : code;
  },
  getKeyValuePairByCode(code) {
    return countries.find((x) => x.code === code);
  },
};
