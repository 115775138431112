<template>
  <div>
    <div class="filterButtonPosition">
      <f7-button
        fill
        class="whiteFontColor button-filter"
        panel-toggle="right"
      >
        <span class="ic-filter"></span> {{ $t('filter') }}
      </f7-button>
    </div>

    <div class="content-notification-details">
      <DxTextBox
        :value="searchText"
        width="250"
        :placeholder="$t('search')"
        @input="input"
      />
      <div class="margin-top-half" style="margin: 0 -8px -8px">
        <DxAccordion
          :data-source="getReportsData"
          :collapsible="true"
          :multiple="false"
          :hover-state-enabled="false"
          :focus-state-enabled="false"
          :animation-duration="300"
          :selected-index="-1"
          :rtl-enabled="true"
          @selectionChanged="selectionChanged"
        >
          <template #title="{ data }">
            <div>
              <div
                :class="
                  !data.been_seen
                    ? 'item-title-notification'
                    : 'item-title-notification'
                "
              >
                {{ data.sample_code }}
                <a
                  href="#"
                  class="dx-link dx-icon-find dx-link-icon icon-notification-search"
                  @click="(e) => goToDetails(e, data)"
                ></a>
              </div>
              <span
                :class="!data.been_seen ? 'time-item' : 'time-item'"
              >
                {{
                  fromNow(
                    new Date(
                      data.created_at.split(' ')[0].split('-')[2],
                      Number(
                        data.created_at.split(' ')[0].split('-')[1]
                      ) - 1,
                      data.created_at.split(' ')[0].split('-')[0],
                      data.created_at.split(' ')[1].split(':')[0],
                      data.created_at.split(' ')[1].split(':')[1],
                      data.created_at.split(' ')[1].split(':')[2]
                    ),
                    new Date(),
                    {
                      addSuffix: true,
                      includeSeconds: true,
                      locale: locales[curLocale],
                    }
                  ).capitalize()
                }}
              </span>
            </div>
          </template>

          <template #item="{ data }">
            <div>
              <div class="list media-list no-ios-edges no-margin">
                <ul>
                  <li class="item-content">
                    <div class="item-inner">
                      <div class="item-subtitle small">
                        {{ $t('atc') }}
                      </div>
                      <div class="item-title-row">
                        <div class="item-title">{{ data.atc }}</div>
                      </div>
                    </div>
                  </li>
                  <li class="item-content">
                    <div class="item-inner">
                      <div class="item-subtitle small">
                        {{ $t('company') }}
                      </div>
                      <div class="item-title-row">
                        <div class="item-title">
                          {{ data.company_name }}
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="item-content">
                    <div class="item-inner">
                      <div class="item-subtitle small">
                        {{ $t('address') }}
                      </div>
                      <div class="item-title-row">
                        <div class="item-title">
                          {{ data.address_name }}
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                    v-if="
                      data.alert_report_type.includes('Parameter')
                    "
                    class="item-content"
                  >
                    <div class="item-inner">
                      <div class="item-subtitle small">
                        {{ $t('alert.parameters') }}
                      </div>
                      <div class="item-title-row">
                        <div class="item-title">
                          {{ data.array_values.toString() }}
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                    v-else-if="
                      data.alert_report_type.includes(
                        'ReceptionAlertReport'
                      )
                    "
                    class="item-content"
                  >
                    <div class="item-inner">
                      <div class="item-subtitle small">
                        {{ $t('agronomySample.summary.sampleType') }}
                      </div>
                      <div class="item-title-row">
                        <div class="item-title">
                          {{ data.sample_type }}
                        </div>
                      </div>
                    </div>
                  </li>
                  <li v-else class="item-content">
                    <div class="item-inner">
                      <div class="item-subtitle small">
                        {{ $t('sample') }}
                      </div>
                      <div class="item-title-row">
                        <div class="item-title">
                          {{ data.n_values }}
                        </div>
                        <div class="item-after">
                          {{
                            data.sample_alert_type === 'CMA'
                              ? $t('alert.cmaPassed')
                              : $t('alert.lmrPassed')
                          }}
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </DxAccordion>
      </div>
    </div>
  </div>
</template>
<script>
import DxAccordion from 'devextreme-vue/accordion';
import { mapActions, mapState } from 'vuex';
import DxTextBox from 'devextreme-vue/text-box';
import formatDistance from 'date-fns/formatDistance';
import { es, enGB, fr, pt, it } from 'date-fns/esm/locale';
import moment from 'moment';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  components: {
    DxAccordion,
    DxTextBox,
  },
  data() {
    return {
      accordion: null,
      searchText: '',
      filteredReports: null,
      locales: { es, en: enGB, fr, pt, it },
      types: {
        food: this.$t('Food'),
        agronomy: this.$t('Agronomy'),
        environment: this.$t('Environment'),
        mining: this.$t('Mining'),
        healthAndSecurity: this.$t('Health and Security'),
      },
    };
  },
  computed: {
    currenDate() {
      return new Date();
    },
    curLocale() {
      return this.languageInterface;
    },
    getReportsData() {
      return this.filteredReports !== null
        ? this.formatReports(this.filteredReports)
        : this.formatReports(this.reports);
    },
    ...mapState('user', ['languageInterface']),
    ...mapState('notifications', ['reports']),
    ...mapState('clientMenu', ['items', 'currentAddress']),
  },
  methods: {
    async goToDetails(e, data) {
      e.preventDefault();
      e.stopPropagation();

      if (data.alert_report_type.includes('Parameter')) {
        this.setParamReportFocusedRowKey(data.alert_report_id);

        this.$f7router.navigate(
          {
            name: 'AlertsParamsReportsPage',
          },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );
      } else if (
        data.alert_report_type.includes('ReceptionAlertReport')
      ) {
        this.viewAtc(data);
      } else {
        this.setSampleReportFocusedRowKey(data.alert_report_id);

        this.$f7router.navigate(
          {
            name: 'AlertsSampleReportsPage',
          },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );
      }

      try {
        await this.setNotificationAsSeen(data.id);
      } catch (e) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(e.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      }
    },
    formatReports(data) {
      return data.map((report) => {
        const date = new Date(
          report.created_at.split(' ')[0].split('/')[2],
          Number(report.created_at.split(' ')[0].split('/')[1]) - 1,
          report.created_at.split(' ')[0].split('/')[0],
          report.created_at.split(' ')[1].split(':')[0],
          report.created_at.split(' ')[1].split(':')[1],
          report.created_at.split(' ')[1].split(':')[2]
        );

        return {
          ...report,
          created_at: moment(`${date}+00:00`)
            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
            .format('DD-MM-YYYY HH:mm:ss'),
        };
      });
    },
    async selectionChanged(e) {},
    input(e) {
      const textToFilter = e.event.target.value;

      if (textToFilter.length > 0) {
        const res = this.findNode(textToFilter, this.reports);
        this.filteredReports = res;
      } else {
        this.filteredReports = null;
      }
    },
    fromNow(o, d, t) {
      return formatDistance(o, d, t);
    },
    findNode(searchText, array) {
      const res = [];

      for (const node of array) {
        if (
          node.company_name
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          node.address_name
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          node.sample_code
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          (node.alert_report_type === 'ParameterAlertReport' &&
            node.array_values.filter((el) =>
              el.toLowerCase().includes(searchText.toLowerCase())
            ).length > 0)
        ) {
          res.push(node);
        }

        if (node.items) {
          const child = this.findNode(searchText, node.items);
          if (child) {
            res.push(...child);
          }
        }
      }

      return res;
    },
    async viewAtc(data) {
      this.setSampleFetchedFromEtl(false);
      this.setLastRouteHome(true);
      this.setLastRouteGeotoolboxSamples(false);

      this.setCurrentSampleId(data.sample_id);
      this.setReportSampleId(data.sample_id);
      this.setReportSampleCode(data.sample_code);

      await this.getCurrentSample();

      await this.goToSampleDetailsByAtc(data.atc_code, data);
    },
    async goToSampleDetailsByAtc(atcName, data) {
      this.types = {
        food: this.$t('Food'),
        agronomy: this.$t('Agronomy'),
        environment: this.$t('Environment'),
        mining: this.$t('Mining'),
        healthAndSecurity: this.$t('Health and Security'),
      };

      if (atcName === this.types.food) {
        this.setCurrentFilter('foodDetails');
        this.setIsNoRp(data.value_report === 'CMA');
        this.setSelectedLimits([this.$t('positives')]);

        this.clearRoutes();

        this.pushRoute({
          name: this.$t('Food'),
          disabled: false,
          page: 'FoodSamplesPage',
          active: false,
        });

        this.pushRoute({
          name: this.$t('sampleSummary'),
          disabled: true,
          page: 'FoodSampleDetailPage',
          active: true,
        });

        this.$f7.views.main.router.navigate(
          {
            name: 'FoodSampleDetailPage',
          },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );
      } else if (atcName === this.types.agronomy) {
        this.setCurrentFilter('agronomy');

        this.clearRoutes();

        this.pushRoute({
          name: this.$t('Agronomy'),
          disabled: false,
          page: 'AgronomySamplesPage',
          active: false,
        });

        this.pushRoute({
          name: this.$t('sampleSummary'),
          disabled: true,
          page: 'AgronomySamplesDetailsPage',
          active: true,
        });

        this.$f7.views.main.router.navigate(
          {
            name: 'AgronomySamplesDetailsPage',
          },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );
      } else if (atcName === this.types.environment) {
        this.setCurrentFilter('environmentDetails');

        this.clearRoutes();

        this.pushRoute({
          name: this.$t('Environment'),
          disabled: false,
          page: 'EnvironmentSamplesPage',
          active: false,
        });

        this.pushRoute({
          name: this.$t('sampleSummary'),
          disabled: true,
          page: 'EnvironmentSamplesDetailsPage',
          active: true,
        });

        this.$f7.views.main.router.navigate(
          {
            name: 'EnvironmentSamplesDetailsPage',
          },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );
      } else if (atcName === this.types.mining) {
        this.setCurrentFilter('miningDetails');

        this.clearRoutes();

        this.pushRoute({
          name: this.$t('Mining'),
          disabled: false,
          page: 'MiningSamplesPage',
          active: false,
        });

        this.pushRoute({
          name: this.$t('sampleSummary'),
          disabled: true,
          page: 'MiningSamplesDetailsPage',
          active: true,
        });

        this.$f7.views.main.router.navigate(
          {
            name: 'MiningSamplesDetailsPage',
          },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );
      } else if (atcName === this.types.healthAndSecurity) {
        this.setCurrentFilter('healthSecurityDetails');

        this.clearRoutes();

        this.pushRoute({
          name: this.$t('Health and Security'),
          disabled: false,
          page: 'HealthSecuritySamplesPage',
          active: false,
        });

        this.pushRoute({
          name: this.$t('sampleSummary'),
          disabled: true,
          page: 'HealthSecuritySamplesDetailsPage',
          active: true,
        });

        this.$f7.views.main.router.navigate(
          {
            name: 'HealthSecuritySamplesDetailsPage',
          },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );
      }
    },
    ...mapActions('navbar', ['setShowPopover']),
    ...mapActions('notifications', [
      'deleteReport',
      'setNotificationAsSeen',
    ]),
    ...mapActions('alertsParams', {
      setParamReportFocusedRowKey: 'setReportFocusedRowKey',
    }),
    ...mapActions('alertsSample', {
      setSampleReportFocusedRowKey: 'setReportFocusedRowKey',
    }),
    //
    ...mapActions('filter', [
      'setCurrentFilter',
      'setCurrentSampleId',
    ]),
    ...mapActions('samples', [
      'getReport',
      'setReportSampleId',
      'getCurrentSample',
      'setReportSampleCode',
      'setSampleFetchedFromEtl',
      'setLastRouteHome',
      'setLastRouteGeotoolboxSamples',
    ]),
    ...mapActions('foodSamples', ['setIsNoRp']),
    ...mapActions('foodDetailsFilter', ['setSelectedLimits']),
    ...mapActions('breadcrumb', ['pushRoute', 'clearRoutes']),
  },
};
</script>

<style scoped lang="scss">
@import './DetailsAccordion.styles.scss';
</style>
