import Api from '@/services/Api';
import EventBus from '@/services/event-bus';
import countryTranslationServiceEs from '../../i18n/translated_countries_service_es';

export default {
  namespaced: true,
  state: {},
  actions: {
    async getSampleTypesSat({ rootState }, params) {
      try {
        const xhr = await Api.getSampleTypesFromSatellite(
          params,
          rootState.contact.company
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getSample({ rootState }, idSample) {
      try {
        const xhr = await Api.getEtlSample(
          rootState.contact.company,
          rootState.contact.address,
          idSample
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getSampleTypes({ rootState }) {
      try {
        const xhr = await Api.getEtlSampleTypes(
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getDestinationCountries({ rootState }) {
      try {
        const xhr = await Api.getDestinationCountries(
          rootState.contact.company,
          rootState.contact.address,
          rootState.riskAnalysisFilter?.selectedSampleTypes?.id
        );
        const response = JSON.parse(xhr.response) ?? [];

        // const responseTranslated = response.map((x) => {
        //   const newKeyValuePair = countryTranslationServiceEs.getKeyValuePairByCode(
        //     x.code
        //   );
        //   return newKeyValuePair || x;
        // });
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getDataEntityCountries({ rootState }) {
      try {
        const xhr = await Api.getEntityV2(
          {
            sampleType:
              rootState.riskAnalysisFilter.selectedSampleTypes.id,
            lmrOrigin:
              rootState.riskAnalysisFilter.selectedOriginCountry.code,
            lmrsDestiny:
              rootState.riskAnalysisFilter
                .selectedDestinationCountries,
          },
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    callStoreWithFilters({ commit }, params) {
      EventBus.$emit('RiskAnalysisPage', params);
    },
    clearFilteredData({ commit }, params) {
      EventBus.$emit('RiskAnalysisPage', params);
    },
  },
  mutations: {},
};
