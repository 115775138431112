<template>
  <f7-page>
    <div v-if="loaded" class="tableContainer">
      <GisGeoJsonMap :name-map="'map'" :tree-locations="geometries" />
      <SummaryGroupedList :geometries-data="geometries" />
    </div>
  </f7-page>
</template>
<script>
import { mapActions } from 'vuex';
import GisGeoJsonMap from '@/components/Gis/GeoJsonMap/index.vue';
import SummaryGroupedList from '@/components/Agronomy/AdvancedServices/SummaryGroupedList/index.vue';
import EventBus from '@/services/event-bus';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  name: 'AdvancedServicesPage',
  components: { GisGeoJsonMap, SummaryGroupedList },
  data() {
    return {
      loaded: false,
      geometries: [],
    };
  },
  async beforeMount() {
    this.$f7.preloader.show();
    this.loaded = false;

    this.setRedirectedFromSamePage(false);

    this.setCurrentRoute([
      {
        name: this.$t('Agronomy'),
        disabled: false,
        page: 'AgronomySamplesPage',
        active: false,
      },
      {
        name: this.$t('advancedServices'),
        disabled: true,
        page: 'AgronomySamplesPage',
        active: true,
      },
    ]);
    this.setCurrentFilter('advancedServices');
    this.setIsEditingFarm(false);
    this.setIsCreatingParcel(false);
    this.setIsRecreatingParcel(false);
    this.setParcelIndex(0);
    this.setTempParcelGeoFeatures([]);
    this.clearGeoJsonPoints();
    this.resetCurrentGeoFeature();
    this.resetCentroide();

    try {
      this.geometries = await this.getFarmsGeometries();

      EventBus.$on('AdvancedServicesPage', async (params) => {
        this.$f7.preloader.show();
        this.loaded = false;

        if (!params) {
          this.geometries = await this.getFarmsGeometries();
        } else {
          this.geometries = await this.getFarmsGeometriesWithFilters(
            params
          );
        }

        const farms = await this.getFarms(false);
        this.addSamplingDateToGeoFeatures(farms);

        this.loaded = true;
        this.$f7.preloader.hide();
      });

      const farms = await this.getFarms(false);
      this.addSamplingDateToGeoFeatures(farms);
    } catch (e) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(e.message).error
            )
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },
  beforeDestroy() {
    EventBus.$off('AdvancedServicesPage');
  },
  methods: {
    addSamplingDateToGeoFeatures(farms) {
      this.geometries = this.geometries.map((geometry) => {
        const foundFarm = farms.find(
          (farm) =>
            farm.name ===
            geometry.geo_feature.features[0].properties.farm
        );

        if (foundFarm) {
          geometry.geo_feature.features[0].properties.sampling_date =
            foundFarm.sampling_date;
        }

        return geometry;
      });
    },
    ...mapActions('breadcrumb', ['setCurrentRoute']),
    ...mapActions('filter', ['setCurrentFilter']),
    ...mapActions('gis', [
      'getFarmsGeometries',
      'getFarmsGeometriesWithFilters',
      'getFarms',
      'clearGeoJsonPoints',
    ]),
    ...mapActions('gisEditor', [
      'resetParcelGeoFeature',
      'setTempParcelGeoFeatures',
      'resetCurrentGeoFeature',
      'resetCentroide',
      'setParcelIndex',
    ]),
    ...mapActions('registerFarm', [
      'setIsEditingFarm',
      'setIsCreatingParcel',
      'getParcelServices',
      'setIsRecreatingParcel',
    ]),
    ...mapActions('nutritionalFollowing', [
      'setRedirectedFromSamePage',
    ]),
  },
};
</script>
