export default [
  {
    agronomy_ID: 1,
    agronomy_Head_ID: 0,
    name: 'Samples',
  },
  {
    agronomy_ID: 2,
    agronomy_Head_ID: 1,
    name: 'Read',
  },
];
