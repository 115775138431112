var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"filterButtonPosition"},[_c('f7-button',{staticClass:"whiteFontColor button-filter",attrs:{"fill":"","panel-toggle":"right"}},[_c('span',{staticClass:"ic-filter"}),_vm._v(" "+_vm._s(_vm.$t('filter'))+"\n    ")])],1),_vm._v(" "),_c('div',{staticClass:"content-notification-details"},[_c('DxTextBox',{attrs:{"value":_vm.searchText,"width":"250","placeholder":_vm.$t('search')},on:{"input":_vm.input}}),_vm._v(" "),_c('div',{staticClass:"margin-top-half",staticStyle:{"margin":"0 -8px -8px"}},[_c('DxAccordion',{attrs:{"data-source":_vm.getReportsData,"collapsible":true,"multiple":false,"hover-state-enabled":false,"focus-state-enabled":false,"animation-duration":300,"selected-index":-1,"rtl-enabled":true},on:{"selectionChanged":_vm.selectionChanged},scopedSlots:_vm._u([{key:"title",fn:function({ data }){return [_c('div',[_c('div',{class:!data.been_seen
                  ? 'item-title-notification'
                  : 'item-title-notification'},[_vm._v("\n              "+_vm._s(data.sample_code)+"\n              "),_c('a',{staticClass:"dx-link dx-icon-find dx-link-icon icon-notification-search",attrs:{"href":"#"},on:{"click":(e) => _vm.goToDetails(e, data)}})]),_vm._v(" "),_c('span',{class:!data.been_seen ? 'time-item' : 'time-item'},[_vm._v("\n              "+_vm._s(_vm.fromNow(
                  new Date(
                    data.created_at.split(' ')[0].split('-')[2],
                    Number(
                      data.created_at.split(' ')[0].split('-')[1]
                    ) - 1,
                    data.created_at.split(' ')[0].split('-')[0],
                    data.created_at.split(' ')[1].split(':')[0],
                    data.created_at.split(' ')[1].split(':')[1],
                    data.created_at.split(' ')[1].split(':')[2]
                  ),
                  new Date(),
                  {
                    addSuffix: true,
                    includeSeconds: true,
                    locale: _vm.locales[_vm.curLocale],
                  }
                ).capitalize())+"\n            ")])])]}},{key:"item",fn:function({ data }){return [_c('div',[_c('div',{staticClass:"list media-list no-ios-edges no-margin"},[_c('ul',[_c('li',{staticClass:"item-content"},[_c('div',{staticClass:"item-inner"},[_c('div',{staticClass:"item-subtitle small"},[_vm._v("\n                      "+_vm._s(_vm.$t('atc'))+"\n                    ")]),_vm._v(" "),_c('div',{staticClass:"item-title-row"},[_c('div',{staticClass:"item-title"},[_vm._v(_vm._s(data.atc))])])])]),_vm._v(" "),_c('li',{staticClass:"item-content"},[_c('div',{staticClass:"item-inner"},[_c('div',{staticClass:"item-subtitle small"},[_vm._v("\n                      "+_vm._s(_vm.$t('company'))+"\n                    ")]),_vm._v(" "),_c('div',{staticClass:"item-title-row"},[_c('div',{staticClass:"item-title"},[_vm._v("\n                        "+_vm._s(data.company_name)+"\n                      ")])])])]),_vm._v(" "),_c('li',{staticClass:"item-content"},[_c('div',{staticClass:"item-inner"},[_c('div',{staticClass:"item-subtitle small"},[_vm._v("\n                      "+_vm._s(_vm.$t('address'))+"\n                    ")]),_vm._v(" "),_c('div',{staticClass:"item-title-row"},[_c('div',{staticClass:"item-title"},[_vm._v("\n                        "+_vm._s(data.address_name)+"\n                      ")])])])]),_vm._v(" "),(
                    data.alert_report_type.includes('Parameter')
                  )?_c('li',{staticClass:"item-content"},[_c('div',{staticClass:"item-inner"},[_c('div',{staticClass:"item-subtitle small"},[_vm._v("\n                      "+_vm._s(_vm.$t('alert.parameters'))+"\n                    ")]),_vm._v(" "),_c('div',{staticClass:"item-title-row"},[_c('div',{staticClass:"item-title"},[_vm._v("\n                        "+_vm._s(data.array_values.toString())+"\n                      ")])])])]):(
                    data.alert_report_type.includes(
                      'ReceptionAlertReport'
                    )
                  )?_c('li',{staticClass:"item-content"},[_c('div',{staticClass:"item-inner"},[_c('div',{staticClass:"item-subtitle small"},[_vm._v("\n                      "+_vm._s(_vm.$t('agronomySample.summary.sampleType'))+"\n                    ")]),_vm._v(" "),_c('div',{staticClass:"item-title-row"},[_c('div',{staticClass:"item-title"},[_vm._v("\n                        "+_vm._s(data.sample_type)+"\n                      ")])])])]):_c('li',{staticClass:"item-content"},[_c('div',{staticClass:"item-inner"},[_c('div',{staticClass:"item-subtitle small"},[_vm._v("\n                      "+_vm._s(_vm.$t('sample'))+"\n                    ")]),_vm._v(" "),_c('div',{staticClass:"item-title-row"},[_c('div',{staticClass:"item-title"},[_vm._v("\n                        "+_vm._s(data.n_values)+"\n                      ")]),_vm._v(" "),_c('div',{staticClass:"item-after"},[_vm._v("\n                        "+_vm._s(data.sample_alert_type === 'CMA'
                            ? _vm.$t('alert.cmaPassed')
                            : _vm.$t('alert.lmrPassed'))+"\n                      ")])])])])])])])]}}])})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }