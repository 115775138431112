export default {
  namespaced: true,
  state: {
    samplingFromDate: null,
    samplingToDate: null,
    selectedFarms: [],
    selectedCrops: [],
    selectedVarieties: [],
  },
  actions: {
    setSamplingFromDate({ commit }, value) {
      commit('setSamplingFromDate', value);
    },
    setSamplingToDate({ commit }, value) {
      commit('setSamplingToDate', value);
    },
    setSelectedCrops({ commit }, value) {
      commit('setSelectedCrops', value);
    },
    setSelectedVarieties({ commit }, value) {
      commit('setSelectedVarieties', value);
    },
    setSelectedFarms({ commit }, value) {
      commit('setSelectedFarms', value);
    },
    clearFilters({ commit }, value) {
      commit('clearFilters', value);
    },
  },
  mutations: {
    setSamplingFromDate(state, value) {
      state.samplingFromDate = value;
    },
    setSamplingToDate(state, value) {
      state.samplingToDate = value;
    },
    setSelectedCrops(state, value) {
      state.selectedCrops = value;
    },
    setSelectedVarieties(state, value) {
      state.selectedVarieties = value;
    },
    setSelectedFarms(state, value) {
      state.selectedFarms = value;
    },
    clearFilters(state) {
      state.samplingFromDate = null;
      state.samplingToDate = null;
      state.selectedFarms = [];
      state.selectedCrops = [];
      state.selectedVarieties = [];
    },
  },
};
