var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f7-page',{staticClass:"main-time-dimension-comparator"},[(_vm.loadedGIS)?_c('div',[_c('div',{staticClass:"content-comparation"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header color-primary"},[_c('div',[_vm._v(_vm._s(_vm.leftParcel.name))])]),_vm._v(" "),_c('div',{staticClass:"card-content padding"},[_c('WMSMap',{attrs:{"zoom":_vm.zoom,"name-map":`${_vm.mainMap}Left`,"layer":_vm.layerLeft,"index":_vm.currentIndexes[`${_vm.mainMap}Left`],"height":'450px',"center":_vm.leftParcel.coordinates}})],1),_vm._v(" "),_c('div',{staticClass:"card-footer justify-content-center"},[_c('DatePicker',{attrs:{"key-id":`${_vm.mainMap}Left`,"default-index":'NDVI',"dates-list":_vm.datesListLeft}})],1)]),_vm._v(" "),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header color-primary"},[_c('div',[_vm._v(_vm._s(_vm.rightParcel.name))])]),_vm._v(" "),_c('div',{staticClass:"card-content padding"},[_c('WMSMap',{attrs:{"zoom":_vm.zoom,"name-map":`${_vm.mainMap}Right`,"layer":_vm.layerRight,"index":_vm.currentIndexes[`${_vm.mainMap}Right`],"height":'450px',"center":_vm.rightParcel.coordinates}})],1),_vm._v(" "),_c('div',{staticClass:"card-footer justify-content-center"},[_c('DatePicker',{attrs:{"key-id":`${_vm.mainMap}Right`,"default-index":'NDVI',"dates-list":_vm.datesListRight}})],1)])]),_vm._v(" "),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header color-primary display-flex justify-content-space-between align-items-center"},[_c('div',[_vm._v(_vm._s(_vm.$t('temporalLine')))]),_vm._v(" "),_c('div',[_c('f7-input',{staticClass:"recepDate",attrs:{"type":"datepicker","placeholder":_vm.$t('agronomySample.chooseDates'),"clear-button":true,"close-on-select":true,"calendar-params":{
              value: [_vm.timeLineIndexInitDate, _vm.timeLineIndexEndDate],
              closeOnSelect: true,
              rangePicker: true,
              dateFormat: {
                month: 'numeric',
                day: 'numeric',
                year: 'numeric',
              },
            }},on:{"calendar:change":_vm.calendarChange}})],1)]),_vm._v(" "),_c('div',{staticClass:"card-content padding-half"},[_c('TimelineGraphWMS',{attrs:{"timeline-indexes":_vm.timeLineIndexesWMS,"index":_vm.selectedIndex}})],1)]),_vm._v(" "),_c('f7-row',{staticClass:"padding"},[_c('f7-col',[_c('GoBack',{attrs:{"go-to-url-name":true,"url-name":"GisComparatorPage"}})],1)],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }