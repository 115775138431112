export default {
  namespaced: true,
  state: {
    receptionFromDate: null,
    receptionToDate: null,
    samplingFromDate: null,
    samplingToDate: null,
    selectedSampleTypes: [],
    selectedStatuses: [],
    selectedFarms: [],
    selectedParcels: [],
    selectedAnalyseTypes: [],
    selectedProjects: [],
    noOfIneligible: null,
    greaterThan: false,
  },
  actions: {
    setReceptionDateFrom({ commit }, value) {
      commit('setReceptionDateFrom', value);
    },
    setGreaterThan({ commit }, value) {
      commit('setGreaterThan', value);
    },
    setReceptionDateTo({ commit }, value) {
      commit('setReceptionDateTo', value);
    },
    setSamplingFromDate({ commit }, value) {
      commit('setSamplingFromDate', value);
    },
    setSamplingToDate({ commit }, value) {
      commit('setSamplingToDate', value);
    },
    setSelectedSampleTypes({ commit }, value) {
      commit('setSelectedSampleTypes', value);
    },
    setSelectedFarms({ commit }, value) {
      commit('setSelectedFarms', value);
    },
    setSelectedParcels({ commit }, value) {
      commit('setSelectedParcels', value);
    },
    setSelectedProjects({ commit }, value) {
      commit('setSelectedProjects', value);
    },
    setNoOfIneligible({ commit }, value) {
      commit('setNoOfIneligible', value);
    },
    setSelectedStatuses({ commit }, value) {
      commit('setSelectedStatuses', value);
    },
    setSelectedAnalyseTypes({ commit }, value) {
      commit('setSelectedAnalyseTypes', value);
    },
    clearFilters({ commit }) {
      commit('clearFilters');
    },
  },
  mutations: {
    setReceptionDateFrom(state, value) {
      state.receptionFromDate = value;
    },
    setReceptionDateTo(state, value) {
      state.receptionToDate = value;
    },
    setSamplingFromDate(state, value) {
      state.samplingFromDate = value;
    },
    setSamplingToDate(state, value) {
      state.samplingToDate = value;
    },
    setSelectedSampleTypes(state, value) {
      state.selectedSampleTypes = value;
    },
    setSelectedFarms(state, value) {
      state.selectedFarms = value;
    },
    setSelectedParcels(state, value) {
      state.selectedParcels = value;
    },
    setNoOfIneligible(state, value) {
      state.noOfIneligible = value;
    },
    setSelectedStatuses(state, value) {
      state.selectedStatuses = value;
    },
    setSelectedProjects(state, value) {
      state.selectedProjects = value;
    },
    setSelectedAnalyseTypes(state, value) {
      state.selectedAnalyseTypes = value;
    },
    setGreaterThan(state, value) {
      state.greaterThan = value;
    },
    clearFilters(state) {
      state.receptionFromDate = null;
      state.receptionToDate = null;
      state.samplingFromDate = null;
      state.samplingToDate = null;
      state.selectedSampleTypes = [];
      state.selectedFarms = [];
      state.selectedParcels = [];
      state.selectedStatuses = [];
      state.noOfIneligible = null;
      state.selectedAnalyseTypes = [];
      state.selectedProjects = [];
      state.greaterThan = false;
    },
  },
};
