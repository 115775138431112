<template>
  <f7-page>
    <div v-if="loaded">
      <FoodSamplesDetailSummary />
      <br />
      <div v-if="showDetails">
        <DxTabPanel
          v-if="!isNoRp && europeanChains.length > 0"
          :loop="false"
          :data-source="tabs"
          :animation-enabled="true"
          :swipe-enabled="true"
          :selected-index="selectedIndex"
          :on-title-click="onItemClick"
        >
          <template #title="{ data: tab }">
            <span class="tab-title">{{ tab.text }}</span>
          </template>
          <template #item="{ data: tab }">
            <div>
              <div v-if="tab.id === 0">
                <FoodSamplesDetailTable
                  v-if="!isNoRp"
                  :is-applied-filter="appliedFilter"
                  :store="customStore"
                />
              </div>
              <div v-if="tab.id === 1">
                <FoodSamplesDetailChainTable />
              </div>
            </div>
          </template>
        </DxTabPanel>

        <FoodSamplesDetailTable
          v-if="!isNoRp && europeanChains.length === 0"
          :is-applied-filter="appliedFilter"
          :store="customStore"
        />

        <FoodSamplesDetailTableNoRP
          v-if="isNoRp"
          :store="customStore"
        />
      </div>

      <NotificationSpinner
        v-if="!showDetails"
        :message="$t('pendingPaymentSampleResult')"
        :alert="true"
      />

      <NotificationSpinner
        v-if="inProcess"
        :message="$t('inProcessSampleResult')"
        :warning="true"
      />

      <GoBack
        class="margin-top"
        :url-name="urlName"
        :go-to-url-name="true"
      />
    </div>
  </f7-page>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import FoodSamplesDetailSummary from '@/components/Food/FoodSamplesDetail/Summary/index.vue';
import FoodSamplesDetailTable from '@/components/Food/FoodSamplesDetail/Table/index.vue';
import FoodSamplesDetailChainTable from '@/components/Food/FoodSamplesDetail/ChainsTable/index.vue';
import FoodSamplesDetailTableNoRP from '@/components/Food/FoodSamplesDetail/Table/No_RP/index.vue';
import GoBack from '@/components/UtilityComponents/GoBack/index.vue';
import EventBus from '@/services/event-bus';
import DxTabPanel from 'devextreme-vue/tab-panel';
import NotificationSpinner from '@/components/NotificationSpinner';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  name: 'FoodSampleDetailPage',
  components: {
    FoodSamplesDetailTable,
    FoodSamplesDetailSummary,
    GoBack,
    FoodSamplesDetailTableNoRP,
    DxTabPanel,
    FoodSamplesDetailChainTable,
    NotificationSpinner,
  },
  data() {
    return {
      loaded: false,
      customStore: null,
      appliedFilter: false,
      tabs: [
        { id: 0, text: this.$t('foodSamples.results') },
        { id: 1, text: this.$t('chains') },
      ],
      selectedIndex: 0,
      currentSampleSummary: {},
    };
  },
  computed: {
    urlName() {
      return this.lastRouteHome ? 'Home' : 'FoodSamplesPage';
    },
    showDetails() {
      return this.currentSampleSummary?.status_code !== '5';
    },
    inProcess() {
      return this.currentSampleSummary?.status_code === '2';
    },
    ...mapState('filter', ['currentSampleId']),
    ...mapState('foodSamples', ['isNoRp', 'europeanChains']),
    ...mapState('samples', ['lastRouteHome']),
  },
  async mounted() {
    try {
      this.$f7.preloader.show();

      this.customStore = await this.getDetailsStore();
      this.applyCurrentFilters();

      EventBus.$on(
        'foodDetailsFilteredSamples',
        async ({ id, params }) => {
          if (params) {
            this.customStore = await this.getStoreDetailsWithFilters({
              id,
              paramFilters: params,
            });

            this.appliedFilter = true;
          } else {
            this.customStore = await this.getDetailsStore();
            this.appliedFilter = false;
          }
        }
      );
    } catch (error) {
      this.customStore = null;
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(error.message).error
            )
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }

    this.currentSampleSummary =
      await this.getCurrentSampleBasedOnFilter();

    // if (!this.showDetails) {
    //   this.$notifyDX(
    //     {
    //       message: this.$t('pendingPaymentSampleResult'),
    //       width: 550,
    //     },
    //     'warning',
    //     7000
    //   );
    // }
  },
  methods: {
    onItemClick(e) {
      this.selectedIndex = e.itemIndex;
    },
    ...mapActions('foodSamples', [
      'getDetailsStore',
      'getStoreDetailsWithFilters',
    ]),
    ...mapActions('filter', [
      'setCurrentFilter',
      'applyCurrentFilters',
    ]),
    ...mapActions('samples', ['getCurrentSampleBasedOnFilter']),
  },
};
</script>
