<template>
  <f7-page name="PasswordRecovery" login-screen>
    <PasswordRecovery />
  </f7-page>
  
</template>

<script>
import PasswordRecovery from '@/components/PasswordRecovery/';

export default {
  components: {
    PasswordRecovery,
  },
  data() {
    return {
      PasswordRecovery,
    };
  },
};
</script>

<style lang="scss" scoped></style>
