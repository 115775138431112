export default {
  namespaced: true,
  state: {
    tableData: [],
    filteredData: [],
    selectedAlert: [],
    selectedBlocks: [],
    selectedUnits: [],
    selectedElements: [],
    selectedPoints: [],
  },
  actions: {
    setTableData({ commit }, value) {
      commit('clearFilters');
      commit('SET_DATA', value);
    },
    setSelectedAlert({ commit }, value) {
      commit('SET_SELECTED_ALERT', value.value);
    },
    setSelectedBlocks({ commit }, value) {
      commit('SET_SELECTED_BLOCKS', value.value);
    },
    setSelectedUnits({ commit }, value) {
      commit('SET_SELECTED_UNITS', value.value);
    },
    setSelectedElements({ commit }, value) {
      commit('SET_SELECTED_ELEMENTS', value.value);
    },
    setSelectedPoints({ commit }, value) {
      commit('SET_SELECTED_POINTS', value.value);
    },
    filterData({ commit }) {
      commit('FILTER_DATA');
    },
    clearFilters({ commit }, value) {
      commit('clearFilters', value);
    },
  },
  mutations: {
    SET_DATA(state, value) {
      state.tableData = value;
      state.filteredData = value;
    },
    SET_SELECTED_ALERT(state, value) {
      state.selectedAlert = value;
    },
    SET_SELECTED_BLOCKS(state, value) {
      state.selectedBlocks = value;
    },
    SET_SELECTED_UNITS(state, value) {
      state.selectedUnits = value;
    },
    SET_SELECTED_ELEMENTS(state, value) {
      state.selectedElements = value;
    },
    SET_SELECTED_POINTS(state, value) {
      state.selectedPoints = value;
    },
    FILTER_DATA(state) {
      state.filteredData = state.tableData.filter((row) => {
        if (state.selectedAlert.length > 0) {
          const alert =
            row.alert === null ? 4 : parseInt(row.alert, 10) + 1;
          if (!state.selectedAlert.includes(alert)) return false;
        }
        if (state.selectedBlocks.length > 0) {
          if (!state.selectedBlocks.includes(row.block)) return false;
        }
        if (state.selectedUnits.length > 0) {
          if (!state.selectedUnits.includes(row.unit)) return false;
        }
        if (state.selectedElements.length > 0) {
          if (!state.selectedElements.includes(row.element))
            return false;
        }
        if (state.selectedPoints.length > 0) {
          if (!state.selectedPoints.includes(row.point)) return false;
        }
        return true;
      });
    },
    clearFilters(state) {
      state.tableData = [];
      state.filteredData = [];
      state.selectedAlert = [];
      state.selectedBlocks = [];
      state.selectedUnits = [];
      state.selectedElements = [];
      state.selectedPoints = [];
    },
  },
};
