<template>
  <f7-page name="loginPage" login-screen>
    <login
      :has-restore-pass="true"
      :has-remember-me="true"
      :has-need-help="true"
    />
  </f7-page>
</template>

<script>
import login from '@/components/Login/';
import { mapActions } from 'vuex';

export default {
  components: {
    login,
  },
  data() {
    return {
      login,
    };
  },
  mounted() {
    this.resetData();
  },
  methods: {
    ...mapActions('contact', ['resetData']),
  },
};
</script>

<style lang="scss" scoped></style>
