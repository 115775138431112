<template>
  <div class="margin-top filter-expert-system">
    <div class="margin-top-half">
      <div class="label-input">
        {{ $t('diagnosisTable.block') }}
      </div>

      <DxTagBox
        :data-source="blockTags"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('agronomySample.summary.sampleType')"
        tag-template="tag"
        :value="selectedBlocks"
        :on-value-changed="setSelectedBlocks"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>

    <div class="margin-top-half">
      <div class="label-input">
        {{ $t('diagnosisTable.unit') }}
      </div>

      <DxTagBox
        :data-source="unitTags"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('agronomySample.summary.sampleType')"
        tag-template="tag"
        :value="selectedUnits"
        :on-value-changed="setSelectedUnits"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>

    <div class="margin-top-half">
      <div class="label-input">
        {{ $t('diagnosisTable.element') }}
      </div>

      <DxTagBox
        :data-source="elementTags"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('agronomySample.summary.sampleType')"
        tag-template="tag"
        :value="selectedElements"
        :on-value-changed="setSelectedElements"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>

    <div class="margin-top-half">
      <div class="label-input">
        {{ $t('diagnosisTable.point') }}
      </div>

      <DxTagBox
        :data-source="PointTags"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('agronomySample.summary.sampleType')"
        tag-template="tag"
        :value="selectedPoints"
        :on-value-changed="setSelectedPoints"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>

    <div class="margin-top-half">
      <div class="label-input">
        {{ $t('diagnosisTable.alert') }}
      </div>

      <DxTagBox
        :data-source="alarmTags"
        :multiline="true"
        :search-enabled="false"
        :placeholder="$t('agronomySample.summary.sampleType')"
        item-template="tag"
        :value="selectedAlert"
        :on-value-changed="setSelectedAlert"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <!-- <div class="purpleTag">
            <b>{{ data }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div> -->
          <div class="dots-parent-container">
            <div class="dot" :class="getDotClass(data)"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { DxTagBox } from 'devextreme-vue/tag-box';
import DataSource from 'devextreme/data/data_source';

export default {
  components: { DxTagBox },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    alarmTags() {
      const set = new Set();

      this.tableData.forEach((item) => {
        const alarm =
          item?.alert === null ? 4 : parseInt(item.alert, 10) + 1;
        set.add(alarm);
      });

      return [...set];
    },
    blockTags() {
      const set = new Set();

      this.tableData.forEach((item) => {
        set.add(item.block);
      });

      return [...set];
    },
    unitTags() {
      const set = new Set();

      this.tableData.forEach((item) => {
        set.add(item.section);
      });

      return [...set];
    },
    elementTags() {
      const set = new Set();

      this.tableData.forEach((item) => {
        set.add(item.element);
      });

      return [...set];
    },
    PointTags() {
      const set = new Set();

      this.tableData.forEach((item) => {
        set.add(item.item);
      });

      return [...set];
    },
    ...mapState('expertSystemFilter', [
      'tableData',
      'selectedAlert',
      'selectedBlocks',
      'selectedUnits',
      'selectedElements',
      'selectedPoints',
    ]),
  },
  methods: {
    getDotClass(alert) {
      const number = alert === null ? 4 : parseInt(alert, 10);
      return `dot-${number}`;
    },
    sampleTypesChanged(e) {
      this.setSelectedSampleTypes(e.value);
    },
    // async openedAlarmTags(e) {
    //   const sampleTypes = await this.getSampleFilter({
    //     atc: '',
    //     attribute: 'sample_type',
    //   });

    //   this.sampleTypes = new DataSource({
    //     store: sampleTypes,
    //     key: '',
    //     paginate: true,
    //     pageSize: 10,
    //   });
    //   this.loaded = true;
    // },
    // async openedAlarmTags(e) {
    //   const sampleTypes = await this.getSampleFilter({
    //     atc: '',
    //     attribute: 'sample_type',
    //   });

    //   this.sampleTypes = new DataSource({
    //     store: sampleTypes,
    //     key: '',
    //     paginate: true,
    //     pageSize: 10,
    //   });
    //   this.loaded = true;
    // },
    // contentReady(e) {
    //   const arrayNotFoundItems = Array.from(
    //     document.getElementsByClassName('dx-empty-message')
    //   );

    //   arrayNotFoundItems.forEach((element) => {
    //     if (!this.loaded) {
    //       element.innerHTML = this.$t('loading');
    //     } else {
    //       element.innerHTML = this.$t('dataNotAvailable');
    //     }
    //   });

    //   this.loaded = false;
    // },
    ...mapActions('expertSystemFilter', [
      'setSelectedAlert',
      'setSelectedBlocks',
      'setSelectedUnits',
      'setSelectedElements',
      'setSelectedPoints',
    ]),
    ...mapActions('samples', ['getSampleFilter']),
    ...mapActions('agronomySamples', ['getStudySatellite']),
  },
};
</script>

<style>
.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #d1d5db;
  margin: 0 5px;
}

.filter-expert-system .dx-texteditor-input-container .dx-tag {
  padding: 5px 0;
}

.dot-1 {
  background-color: #e0f3f8;
}

.dot-2 {
  background-color: #ffd966;
}

.dot-3 {
  background-color: #de7e7e;
}

.dot-4 {
  background-color: #d1d5db;
}
</style>
