<template>
  <div class="legend">
    <div class="legend-items">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="legend-item"
      >
        <div
          class="legend-color"
          :style="{ backgroundColor: item.color }"
        ></div>
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Legends',
  props: {
    items: {
      type: Array,
      default() {
        return [
          { color: '#FF654F', label: 'Alarma' },
          { color: '#FFDE4A', label: 'Alerta' },
          { color: '#D9F0C0', label: 'Adecuado' },
        ];
      },
    },
  },
};
</script>

<style scoped>
.legend {
  background-color: #fff;
  padding: 10px;
  max-width: 200px;
}

.legend-items {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.legend-color {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border: 1px solid #ccc;
  border-radius: 50%;
}
</style>
