import EventBus from '@/services/event-bus';
import CustomStore from 'devextreme/data/custom_store';
import Api from '../../../services/Api';

export default {
  namespaced: true,
  state: {
    allSamples: [],
  },
  actions: {
    async getSamples({ rootState }, params) {
      try {
        const xhr = await Api.getHomeSamples(
          params,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve({
          data: response.data,
          totalCount: response.totalCount,
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getSamplesWithFilters({ rootState }, params) {
      try {
        const xhr = await Api.getHomeSamplesWithFilters(
          params,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve({
          data: response.data,
          totalCount: response.totalCount,
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    getStore({ dispatch, rootState }) {
      return new CustomStore({
        key: 'id',
        load: async (loadOptions) => {
          let params = '?';
          let sortSettings = [
            { selector: 'reception_date', desc: true },
          ];

          ['skip', 'take', 'sort'].forEach((i) => {
            if (i in loadOptions) {
              if (i === 'sort' && loadOptions[i] !== null) {
                sortSettings = loadOptions[i];
              } else {
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
              }
            }
          });

          params = params.slice(0, -1);
          let subsistuteParams = params.replace('skip', 'offset');
          subsistuteParams = subsistuteParams.replace(
            'take',
            'limit'
          );

          const sortParams = await dispatch(
            'filter/applySort',
            {
              sortSettings,
              isFilter: false,
            },
            { root: true }
          );

          subsistuteParams += sortParams;

          if (!subsistuteParams.startsWith('?')) {
            subsistuteParams = `?${subsistuteParams}`;
          }

          if (loadOptions.filter) {
            if (loadOptions.filter[0]) {
              subsistuteParams += `&filter_search=${loadOptions.filter[0].filterValue}`;
            }
          }

          return dispatch('getSamples', subsistuteParams);
        },
      });
    },
    async getStoreWithFilters({ rootState, dispatch }, paramFilters) {
      return new CustomStore({
        key: 'id',
        load: async (loadOptions) => {
          let params = '?';
          let sortSettings = [];

          ['skip', 'take', 'sort'].forEach((i) => {
            if (i in loadOptions) {
              if (i === 'sort' && loadOptions[i] !== null) {
                sortSettings = loadOptions[i];
              } else {
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
              }
            }
          });

          params = params.slice(0, -1);

          if (params.includes('skip')) {
            let subsistuteParams = params.replace('skip', 'offset');
            subsistuteParams = subsistuteParams.replace(
              'take',
              'limit'
            );

            const offsetLimit = subsistuteParams.split('&');

            const offset = offsetLimit[0].split('=')[1];

            const limit = offsetLimit[1].split('=')[1];
            paramFilters.offset = offset;
            paramFilters.limit = limit;
          } else {
            paramFilters.limit = null;
            paramFilters.offset = null;
          }

          const sortParams = await dispatch(
            'filter/applySort',
            {
              sortSettings,
              isFilter: true,
            },
            { root: true }
          );

          if (loadOptions.filter) {
            if (loadOptions.filter[0]) {
              paramFilters.filter_search =
                loadOptions.filter[0].filterValue;
            }
          } else {
            paramFilters.filter_search = null;
          }

          paramFilters.ordering = sortParams.sort;
          return dispatch('getSamplesWithFilters', paramFilters);
        },
      });
    },
    callStoreWithFilters({ commit, rootState }, params) {
      EventBus.$emit('Home', params);
    },
    setFilteredSamples({ commit, rootState }) {
      EventBus.$emit('Home', null);
    },
    setSamples({ commit }, value) {
      commit('setSamples', value);
    },
  },
  mutations: {
    setSamples(state, value) {
      state.allSamples = value;
    },
  },
};
