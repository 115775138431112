<template>
  <div class="tableContainer">
    <DxDataGrid
      :data-source="europeanChains"
      :show-borders="true"
      :allow-column-reordering="false"
      :column-auto-width="true"
      :show-colon="false"
      :hover-state-enabled="true"
      :remote-operations="false"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing"
    >
      <DxScrolling mode="standard" />
      <DxSelection mode="single" />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="[5, 10, 20]"
        :show-info="true"
        info-text="{2} items"
        :visible="true"
      />

      <DxSearchPanel
        :visible="true"
        :width="300"
        :placeholder="$t('search')"
      />

      <DxColumn
        data-field="Supermercado"
        :caption="$t('chain')"
        :allow-sorting="false"
        alignment="left"
        :fixed="true"
        fixed-position="left"
      />

      <DxColumn
        data-field=""
        :caption="$t('chainsList.% LMR UE')"
        :allow-sorting="false"
        alignment="left"
        :word-wrap-enabled="true"
        cell-template="lmrUeTemplate"
      />

      <DxColumn
        data-field=""
        :caption="$t('chainsList.% LMR UE (sumatorio)')"
        :allow-sorting="false"
        alignment="left"
        :word-wrap-enabled="true"
        cell-template="lmrUeTemplate"
      />

      <DxColumn
        data-field=""
        :caption="$t('chainsList.% ARFD')"
        :allow-sorting="false"
        alignment="left"
        :word-wrap-enabled="true"
        cell-template="lmrUeTemplate"
      />

      <DxColumn
        data-field=""
        :caption="$t('chainsList.% ARFD (sumatorio)')"
        :allow-sorting="false"
        alignment="left"
        :word-wrap-enabled="true"
        cell-template="lmrUeTemplate"
      />

      <DxColumn
        data-field=""
        :caption="$t('chainsList.Nº Sustancias Activas')"
        :allow-sorting="false"
        alignment="left"
        :word-wrap-enabled="true"
        cell-template="lmrUeTemplate"
      />

      <DxColumn
        data-field=""
        :caption="$t('chainsList.Lista Negra')"
        :allow-sorting="false"
        alignment="left"
        :word-wrap-enabled="true"
        cell-template="lmrUeTemplate"
      />

      <DxColumn
        data-field=""
        :caption="$t('chainsList.Nº Sust Por Matriz')"
        :allow-sorting="false"
        alignment="left"
        :word-wrap-enabled="true"
        cell-template="lmrUeTemplate"
      />

      <DxColumn
        data-field=""
        :caption="$t('chainsList.% LMR por Matriz')"
        :allow-sorting="false"
        alignment="left"
        :word-wrap-enabled="true"
        cell-template="lmrUeTemplate"
      />

      <DxColumn
        data-field=""
        :caption="$t('chainsList.Valor LMR por Matriz')"
        :allow-sorting="false"
        alignment="left"
        :word-wrap-enabled="true"
        cell-template="lmrUeTemplate"
      />

      <DxColumn
        data-field=""
        :caption="$t('chainsList.PRP-UL % ')"
        :allow-sorting="false"
        alignment="left"
        :word-wrap-enabled="true"
        cell-template="lmrUeTemplate"
      />

      <DxColumn
        data-field=""
        :caption="$t('chainsList.PRP-UL % (sumatorio)')"
        :allow-sorting="false"
        alignment="left"
        :word-wrap-enabled="true"
        cell-template="lmrUeTemplate"
      />

      <DxColumn
        data-field=""
        :caption="$t('chainsList.ADI %')"
        :allow-sorting="false"
        alignment="left"
        :word-wrap-enabled="true"
        cell-template="lmrUeTemplate"
      />

      <DxColumn
        data-field=""
        :caption="$t('confirmation')"
        :allow-sorting="false"
        alignment="left"
        :word-wrap-enabled="true"
        :fixed="true"
        fixed-position="right"
        cell-template="confirmationTemplate"
      />

      <template #lmrUeTemplate="{ data }">
        <div
          v-if="
            data.data[`Resultado ` + data.column.caption] !==
            undefined
          "
          style="text-align: center"
        >
          <img
            width="20"
            height="20"
            :src="
              data.data[`Resultado ` + data.column.caption]
                ? getDotColorImage('green')
                : getDotColorImage('red')
            "
          />
        </div>
        <div v-else style="text-align: center">-</div>
      </template>

      <template #confirmationTemplate="{ data }">
        <div style="text-align: center">
          <i
            v-if="!data.data.Confirmado"
            style="color: red"
            class="f7-icons"
          >
            xmark_circle_fill
          </i>
          <i
            v-if="data.data.Confirmado"
            style="color: green"
            class="f7-icons"
          >
            checkmark_alt_circle_fill
          </i>
        </div>
      </template>

      <DxExport :enabled="true" />
    </DxDataGrid>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPager,
  DxPaging,
  DxScrolling,
  DxExport,
  DxSelection,
  DxSearchPanel,
} from 'devextreme-vue/data-grid';

import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';

export default {
  name: 'ChainsTable',
  components: {
    DxDataGrid,
    DxColumn,
    DxPager,
    DxPaging,
    DxScrolling,
    DxExport,
    DxSelection,
    DxSearchPanel,
  },
  data() {
    return {
      gridInstance: null,
    };
  },
  computed: {
    ...mapState('foodSamples', ['europeanChains']),
  },
  methods: {
    getDotColorImage(color) {
      return require(`../../../../../assets/dot-colors/${color}.svg`)
        .default;
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Companies');
      worksheet.columns = [
        { width: 40 },
        { width: 40 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
      ];
      exportDataGrid({
        component: e.component,
        worksheet,
        keepColumnWidths: false,
        topLeftCell: { row: 2, column: 2 },
        customizeCell: ({ gridCell, excelCell }) => {
          if (gridCell.rowType === 'data') {
            if (gridCell.column.caption === this.$t('chain')) {
              excelCell.value = gridCell.data.Supermercado;
            } else if (
              gridCell.column.caption === this.$t('confirmation')
            ) {
              excelCell.value = gridCell.data.Confirmado
                ? this.$t('yes')
                : this.$t('no');
            } else if (
              gridCell.data[`Resultado ${gridCell.column.caption}`]
            ) {
              excelCell.value = this.$t('green');
            } else if (
              gridCell.data[
                `Resultado ${gridCell.column.caption}`
              ] === false
            ) {
              excelCell.value = this.$t('red');
            } else {
              excelCell.value = '-';
            }
          }
        },
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: 'application/octet-stream' }),
            `besafer-${this.$t(
              'chains'
            )}-${new Date().toLocaleString()}.xlsx`
          );
        });
      });
      e.cancel = true;
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items;

      toolbarItems.forEach((item) => {
        if (item.name === 'searchPanel') {
          item.location = 'before';
        }
        if (item.name === 'exportButton') {
          item.location = 'after';
        }

        if (item.name === 'groupPanel') {
          item.location = 'after';
        }
      });
    },
    ...mapActions('foodSamples', ['setSampleDetails']),
  },
};
</script>

<style scoped>
::v-deep .dx-toolbar-after {
  left: initial !important;
}
</style>
