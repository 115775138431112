import CustomStore from 'devextreme/data/custom_store';
import Api from '@/services/Api';
import EventBus from '@/services/event-bus';

export default {
  namespaced: true,
  state: {
    showReportPopup: false,
    focusedRowKey: null,
    focusedReportRowKey: null,
  },
  actions: {
    async getEntity(
      { rootState },
      { entity, sampleTypeId, countryCode }
    ) {
      try {
        const xhr = await Api.getEntity(
          entity,
          sampleTypeId,
          countryCode,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getParameters({ rootState }, { sampleType, atc }) {
      try {
        const xhr = await Api.getSatelliteParameters(
          atc,
          sampleType,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getParametersUnits({ rootState }, { sampleType, atc }) {
      try {
        const xhr = await Api.getSatelliteParametersUnits(
          atc,
          sampleType,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getAlerts({ rootState }, params) {
      try {
        const xhr = await Api.getParamsAlerts(
          params,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve({
          data: response.data,
          totalCount: response.totalCount,
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getReports({ rootState }, params) {
      try {
        const xhr = await Api.getParameterAlertReports(
          params,
          rootState.contact.company,
          rootState.contact.address
        );

        const response = JSON.parse(xhr.response);

        return Promise.resolve({
          data: response.data,
          totalCount: response.totalCount,
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async updateAlertState({ rootState }, params) {
      try {
        const xhr = await Api.updateAlertState(
          params.id,
          params.newState,
          rootState.contact.company,
          rootState.contact.address,
          false
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async updateAlert({ rootState }, { id, params }) {
      try {
        const xhr = await Api.updateParamsAlert(
          id,
          params,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async deleteAlert({ rootState }, id) {
      try {
        await Api.deleteParamsAlert(
          id,
          rootState.contact.company,
          rootState.contact.address
        );

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async deleteAlertReport({ rootState }, id) {
      try {
        await Api.deleteParamsAlertReport(
          id,
          rootState.contact.company,
          rootState.contact.address
        );

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    getStore({ dispatch }) {
      return new CustomStore({
        key: 'id',
        load: async (loadOptions) => {
          let params = '?';
          let sortSettings = [];

          ['skip', 'take', 'sort'].forEach((i) => {
            if (i in loadOptions) {
              if (i === 'sort' && loadOptions[i] !== null) {
                sortSettings = loadOptions[i];
              } else {
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
              }
            }
          });

          params = params.slice(0, -1);
          let subsistuteParams = params.replace('skip', 'offset');
          subsistuteParams = subsistuteParams.replace(
            'take',
            'limit'
          );

          const sortParams = await dispatch(
            'filter/applySort',
            {
              sortSettings,
              isFilter: false,
            },
            { root: true }
          );

          subsistuteParams += sortParams;

          return dispatch('getAlerts', subsistuteParams);
        },
        update(key, val) {
          if (key === 'status') {
            return dispatch('updateAlertState', val);
          }

          return null;
        },
      });
    },
    async getStoreWithFilters({ dispatch }, paramFilters) {
      return new CustomStore({
        key: 'id',
        load: async (loadOptions) => {
          let params = '?';
          let sortSettings = [];

          ['skip', 'take', 'sort'].forEach((i) => {
            if (i in loadOptions) {
              if (i === 'sort' && loadOptions[i] !== null) {
                sortSettings = loadOptions[i];
              } else {
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
              }
            }
          });

          params = params.slice(0, -1);
          let subsistuteParams = params.replace('skip', 'offset');
          subsistuteParams = subsistuteParams.replace(
            'take',
            'limit'
          );

          const offsetLimit = subsistuteParams.split('&');
          const offset = offsetLimit[0].split('=')[1];

          const limit = offsetLimit[1].split('=')[1];

          paramFilters.offset = offset;
          paramFilters.limit = limit;

          const sortParams = await dispatch(
            'filter/applySort',
            {
              sortSettings,
              isFilter: true,
            },
            { root: true }
          );

          paramFilters.ordering = sortParams.sort;

          return dispatch('getAlerts', paramFilters);
        },
        update(key, val) {
          if (key === 'status') {
            return dispatch('updateAlertState', val);
          }

          return null;
        },
      });
    },
    getReportsStore({ dispatch }) {
      return new CustomStore({
        key: 'id',
        load: async (loadOptions) => {
          let params = '?';
          let sortSettings = [];

          ['skip', 'take', 'sort'].forEach((i) => {
            if (i in loadOptions) {
              if (i === 'sort' && loadOptions[i] !== null) {
                sortSettings = loadOptions[i];
              } else {
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
              }
            }
          });

          params = params.slice(0, -1);
          let subsistuteParams = params.replace('skip', 'offset');
          subsistuteParams = subsistuteParams.replace(
            'take',
            'limit'
          );

          const sortParams = await dispatch(
            'filter/applySort',
            {
              sortSettings,
              isFilter: false,
            },
            { root: true }
          );

          subsistuteParams += sortParams;

          return dispatch('getReports', subsistuteParams);
        },
      });
    },
    async getReportsStoreWithFilters({ dispatch }, paramFilters) {
      return new CustomStore({
        key: 'id',
        load: async (loadOptions) => {
          let params = '?';
          let sortSettings = [];

          ['skip', 'take', 'sort'].forEach((i) => {
            if (i in loadOptions) {
              if (i === 'sort' && loadOptions[i] !== null) {
                sortSettings = loadOptions[i];
              } else {
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
              }
            }
          });

          params = params.slice(0, -1);
          let subsistuteParams = params.replace('skip', 'offset');
          subsistuteParams = subsistuteParams.replace(
            'take',
            'limit'
          );

          const offsetLimit = subsistuteParams.split('&');
          const offset = offsetLimit[0].split('=')[1];

          const limit = offsetLimit[1].split('=')[1];

          paramFilters.offset = offset;
          paramFilters.limit = limit;

          const sortParams = await dispatch(
            'filter/applySort',
            {
              sortSettings,
              isFilter: true,
            },
            { root: true }
          );

          paramFilters.ordering = sortParams.sort;

          return dispatch('getReports', paramFilters);
        },
        update(key, val) {
          if (key === 'status') {
            return dispatch('updateAlertState', val);
          }

          return null;
        },
      });
    },
    async saveAlert({ rootState }, data) {
      try {
        const xhr = await Api.saveParamsAlert(
          data,
          rootState.contact.company,
          rootState.contact.address
        );

        const response = JSON.parse(xhr.response);

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async duplicateAlert({ rootState }, data) {
      try {
        const xhr = await Api.duplicateParamsAlert(
          data,
          rootState.contact.company,
          rootState.contact.address
        );

        const response = JSON.parse(xhr.response);

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getParameterAttributeList({ rootState }, attribute) {
      try {
        const xhr = await Api.getParameterAlertAttributeList(
          attribute,
          rootState.contact.company,
          rootState.contact.address
        );

        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getParameterReportAttributeList({ rootState }, attribute) {
      try {
        const xhr = await Api.getParameterAlertReportAttributeList(
          attribute,
          rootState.contact.company,
          rootState.contact.address
        );

        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    setShowReportPopup({ commit }, value) {
      commit('setShowReportPopup', value);
    },
    setFocusedRowKey({ commit }, value) {
      commit('setFocusedRowKey', value);
    },
    setReportFocusedRowKey({ commit }, value) {
      commit('setReportFocusedRowKey', value);
    },
    callStoreWithFilters({ commit }, params) {
      EventBus.$emit('AlertsParamsPage', params);
    },
    callReportStoreWithFilters({ commit }, params) {
      EventBus.$emit('AlertsParamsReportsPage', params);
    },
    clearFilteredDataSettings({ commit }, params) {
      EventBus.$emit('AlertsParamsPage', params);
    },
    clearFilteredDataReport({ commit }, params) {
      EventBus.$emit('AlertsParamsReportsPage', params);
    },
  },
  mutations: {
    setShowReportPopup(state, value) {
      state.showReportPopup = value;
    },
    setFocusedRowKey(state, value) {
      state.focusedRowKey = value;
    },
    setReportFocusedRowKey(state, value) {
      state.focusedReportRowKey = value;
    },
  },
};
