<template>
  <div class="main-mir-graph">
    <div class="botonderech">
      <div class="graph-title">{{ $t('probesTitle') }}</div>
      <a
        v-if="displayFindBtn"
        class="mir-icon-find dx-link dx-icon-map"
        @click="(e) => goToMirStations()"
      ></a>
    </div>
    <div>
      <div v-if="graphDataLoading" class="loadingFalse">
        <div class="preloader">
          <span class="preloader-inner">
            <span class="preloader-inner-circle"></span>
          </span>
        </div>
      </div>
      <div v-else class="graph-container">
        
        <div
          v-for="(graph, index) in graphData"
          :key="index"
          class="graph-item"
        >
          <div
            v-if="
              enabled
                ? parcelsMirRs.find(
                    (parcelMirRs) =>
                      parcelMirRs.parcel_id === +graph.parcel_id
                  ).mir
                : true
            "
            class="graph-item-content"
          >
            <div
              class="center clickable"
              @click="goToMirDetail(graph.source)"
            >
              {{ graph.parcel_name }}
            </div>
            <div class="center">{{ graph.station_name }}</div>
            <div class="center padding" style="position: relative">
              <div class="tool-tip">
                
                {{
                  ((graph.data.SMT / graph.data.CC) * 100).toFixed(2)
                }}
                %
              </div>
              <div class="graph-stack">
                <div
                  class="smt-line"
                  :style="{
                    bottom:
                      (graph.data.SMT / graph.data.CC) * 100 + 'px',
                  }"
                ></div>
                <div
                  class="level-3"
                  :style="{
                    height: 20 + 'px',
                  }"
                ></div>
                <div
                  class="level-2"
                  :style="{
                    height:
                      100 -
                      (graph.data.IS / graph.data.CC) * 100 +
                      'px',
                  }"
                ></div>
                <div
                  class="level-1"
                  :style="{
                    height:
                      (graph.data.IS / graph.data.CC) * 100 + 'px',
                  }"
                ></div>
              </div>
            </div>
            <div class="center">
              {{ graph.data.Date }}
            </div>
            <div class="center">{{ graph.data.SMT }} mm</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { NOTIFY_TIME_LONG } from '@/js/constants';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'MIRGraphResume',
  components: {},
  props: {
    displayFindBtn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      graphDataLoading: true,
      graphData: [],
    };
  },
  computed: {
    ...mapState('contact', ['enabled']),
    ...mapState('registerFarm', ['farm', 'farmStr', 'parcelsMirRs']),
  },
  mounted() {
    this.fetchGraphData();
  },
  methods: {
    goToMirDetail(station) {
      this.$f7router.navigate(
        { name: 'MIRDetail', query: { station } },
        {
          animate: true,
          transition: 'f7-fade',
        }
      );
    },
    goToMirStations() {
      this.$f7router.navigate(
        { name: 'MIRStationsPage' },
        {
          animate: true,
          transition: 'f7-fade',
        }
      );
    },
    async fetchGraphData() {
      try {
        this.graphDataLoading = true;
        const lastData = await this.getLastMirData(this.farm);
        this.graphData = lastData;
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(error.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      } finally {
        this.graphDataLoading = false;
      }
    },
    ...mapActions('hidrosoph', ['getLastMirData']),
  },
};
</script>
<style lang="scss" scoped>
@import './MIRGraphResume.styles.scss';
</style>
