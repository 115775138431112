export default [
  {
    environment_ID: 1,
    environment_Head_ID: 0,
    name: 'Samples',
  },
  {
    environment_ID: 2,
    environment_Head_ID: 1,
    name: 'Read',
  },
];
