var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('DxPopup',{attrs:{"visible":_vm.showUploadFormPopup,"drag-enabled":false,"close-on-outside-click":false,"show-close-button":true,"show-title":true,"width":"700","height":"auto","container":".dx-viewport","title":_vm.editData.isEditing
        ? _vm.$t('editFile') + ' - ' + _vm.formatRoute
        : _vm.$t('uploadFile') + ' - ' + _vm.formatRoute},on:{"hiding":_vm.hide}},[_c('form',{ref:"formUpload",attrs:{"id":"form"},on:{"submit":_vm.onSubmit}},[_c('div',{staticClass:"dx-fieldset"},[_c('div',{staticClass:"dx-field"},[_c('div',{staticClass:"dx-field-label"},[_vm._v("\n            "+_vm._s(_vm.$t('title'))+": "),_c('i',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_vm._v(" "),_c('DxTextBox',{staticClass:"dx-field-value",attrs:{"value":_vm.editData.isEditing ? _vm.editData.data.name : _vm.name,"name":"title","placeholder":_vm.$t('title'),"on-value-changed":_vm.onNameChanged}},[_c('DxValidator',[_c('DxRequiredRule',{attrs:{"message":_vm.$t('requiredTitle')}})],1)],1)],1),_vm._v(" "),_c('div',{staticClass:"dx-field"},[_c('div',{staticClass:"dx-field-label"},[_vm._v(_vm._s(_vm.$t('contractNum'))+":")]),_vm._v(" "),_c('DxTextBox',{staticClass:"dx-field-value",attrs:{"name":"contractNumber","placeholder":_vm.$t('contractNum'),"value":_vm.editData.isEditing
                ? _vm.editData.data.contractNumber
                : _vm.contractNumber,"on-value-changed":_vm.onContractNumChanged}})],1),_vm._v(" "),_c('div',{staticClass:"dx-field"},[_c('div',{staticClass:"dx-field-label"},[_vm._v("\n            "+_vm._s(_vm.$t('agronomySample.summary.description'))+":\n          ")]),_vm._v(" "),_c('DxTextArea',{staticClass:"dx-field-value",attrs:{"name":"description","placeholder":_vm.$t('agronomySample.summary.description'),"value":_vm.editData.isEditing
                ? _vm.editData.data.description
                : _vm.description,"on-value-changed":_vm.onDescriptionChanged}})],1)]),_vm._v(" "),(!_vm.editData.isEditing)?_c('div',[_c('p',{staticClass:"item-title-bold marginLeft25"},[_vm._v("\n          "+_vm._s(_vm.$t('notification'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"dx-field"},[_c('div',{staticClass:"dx-field-label"},[_c('span',{staticClass:"marginLeft25"},[_vm._v(_vm._s(_vm.$t('emails')))])]),_vm._v(" "),_c('div',{staticClass:"dx-field-value"},[_c('DxTagBox',{attrs:{"accept-custom-value":true,"name":"emails","placeholder":_vm.$t('emails'),"width":400,"value":_vm.editData.isEditing ? _vm.editData.data.emails : _vm.emails,"on-value-changed":_vm.emailsChanged},on:{"customItemCreating":_vm.onCustomEmailCreating}})],1)])]):_vm._e(),_vm._v(" "),(!_vm.editData.isEditing)?_c('div',{staticClass:"fileuploader-container marginLeft15"},[_c('DxFileUploader',{attrs:{"select-button-text":_vm.$t('uploadFile'),"label-text":"","max-file-size":5000000,"upload-mode":"useForm"},on:{"valueChanged":_vm.checkSize}},[_c('DxValidator',[_c('DxRequiredRule',{attrs:{"message":_vm.$t('requiredUpload')}})],1)],1),_vm._v(" "),_c('em',{staticClass:"marginLeft10"},[_vm._v("\n          Max\n          "),_c('strong',{staticStyle:{"color":"red"}},[_vm._v("\n            "+_vm._s(_vm.sizeLimit)+" "+_vm._s(_vm.sizeLimitUnit)+"\n          ")])]),_vm._v(" "),(_vm.exceedsSizeLimit)?_c('p',{staticClass:"marginLeft10",staticStyle:{"color":"red"}},[_vm._v("\n          "+_vm._s(_vm.$t('fileExceedsSize'))+"\n        ")]):_vm._e()],1):_vm._e(),_vm._v(" "),_c('f7-row',[_c('f7-col',{staticStyle:{"text-align":"center"},attrs:{"width":"100"}},[_c('div',{staticClass:"dx-fieldset"},[_c('DxButton',{staticClass:"standardBtn",attrs:{"use-submit-behavior":true,"text":_vm.$t('save'),"type":"outlined"}})],1)])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }