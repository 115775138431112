<template>
  <div class="main-summary-grouped-list">
    <div class="content-search margin-top-half">
      <div>
        <DxTextBox
          :value="searchText"
          width="250"
          :placeholder="$t('search')"
          @input="input"
        />
      </div>
      <div class="margin-right-half buttons-container">
        <button
          v-if="!enabled"
          id="newFarmBtn"
          class="button button-fill display-flex"
        >
          <span class="ic-newcrop margin-horizontal-half"></span>
        </button>

        <DxContextMenu
          :data-source="[
            {
              text: $t('registerFarm'),
              onClick: newFarm,
            },
            { text: $t('registerParcel'), onClick: newParcel },
          ]"
          :width="200"
          target="#newFarmBtn"
          show-event="dxclick"
        />

        <!--<div>
          <f7-button
            fill
            class="whiteFontColor button-filter"
            panel-toggle="right"
            style="width: 115px"
          >
            <span class="ic-filter"></span> {{ $t('filter') }}
          </f7-button>
        </div>-->
      </div>
    </div>

    <f7-row>
      <f7-col class="margin-top-half">
        <div>
          <DxAccordion
            :data-source="
              filteredList && filteredList.length > 0
                ? filteredList
                : dataSource
            "
            :collapsible="collapsible"
            :multiple="multiple"
            :animation-duration="animationDuration"
            :selected-index="-1"
          >
            <template #title="{ data }">
              <div class="accordion-title">
                <div class="display-flex align-item-center">
                  <div
                    class="margin-right time-item badge"
                    :title="$t('lastSampling')"
                  >
                    <span class="ic_clock margin-right-half"></span>
                    {{
                      data.farmGeoFeature.geo_feature.features[0]
                        .properties.sampling_date
                        ? getMoment(
                            data.farmGeoFeature.geo_feature
                              .features[0].properties.sampling_date
                          ).format('DD-MM-YYYY')
                        : getMoment(new Date()).format('DD-MM-YYYY')
                    }}
                  </div>
                  <span class="ic_crop margin-right-half"></span>
                  <strong
                    class="margin-right"
                    :title="
                      $t('agronomySample.summary.estate') +
                      ' ' +
                      data.farm
                    "
                  >
                    {{ data.farm }}
                  </strong>

                  <span class="ic_user margin-right-half"></span>
                  <span
                    style="text-transform: capitalize"
                    :title="$t('accountable') + ' ' + data.farm"
                  >
                    {{ data.accountable }}
                  </span>
                </div>
                <div class="accordion-buttons">
                  <a
                    v-if="!enabled"
                    class="dx-link dx-icon-edit dx-link-icon"
                    @click="(e) => goToEditFarm(e, data)"
                  ></a>
                  <a
                    class="dx-link dx-icon-find dx-link-icon"
                    @click="(e) => goToDashboard(e, data)"
                  ></a>
                </div>
              </div>
            </template>
            <template #item="{ data }">
              <div>
                <div
                  v-for="(parcel, index) in data.parcels"
                  :key="
                    parcel.geo_feature.features[0].geometry.coordinates.toString()
                  "
                  class="accordion-content margin-top-half"
                >
                  <div class="display-flex align-items-center">
                    <!-- Icon -->
                    <span class="ic_trip margin-right-half"></span>
                    <!-- Variable -->
                    <span
                      class="item-title margin-right"
                      style="text-transform: capitalize"
                    >
                      {{
                        parcel.geo_feature.features[0].properties
                          .parcel_name
                      }}
                    </span>

                    <!-- Icon -->
                    <span
                      v-if="
                        parcel.geo_feature.features[0].properties
                          .cultive
                      "
                      class="ic_cultive margin-right-half"
                    ></span>
                    <!-- Variable -->
                    <span
                      v-if="
                        parcel.geo_feature.features[0].properties
                          .cultive
                      "
                      class="margin-right"
                    >
                      {{
                        parcel.geo_feature.features[0].properties
                          .cultive
                      }}
                    </span>

                    <!-- Icon -->
                    <span
                      v-if="
                        parcel.geo_feature.features[0].properties
                          .variety
                      "
                      class="ic_variety margin-right-half"
                    ></span>
                    <!-- Variable -->
                    <span
                      v-if="
                        parcel.geo_feature.features[0].properties
                          .variety
                      "
                      class="margint-right"
                    >
                      {{
                        parcel.geo_feature.features[0].properties
                          .variety
                      }}
                    </span>
                  </div>
                  <div class="accordion-content-buttons">
                    <a
                      v-if="!enabled"
                      class="dx-link dx-icon-map"
                      @click="
                        (e) => goToAssignStations(e, parcel, data)
                      "
                    ></a>

                    <a
                      v-if="!enabled"
                      class="dx-link dx-icon-edit dx-link-icon"
                      @click="
                        (e) => goToEditParcel(e, parcel, index, data)
                      "
                    ></a>
                  </div>
                </div>
              </div>
            </template>
          </DxAccordion>
        </div>
      </f7-col>
    </f7-row>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DxContextMenu from 'devextreme-vue/context-menu';
import DxAccordion from 'devextreme-vue/accordion';
import DxTextBox from 'devextreme-vue/text-box';
import EventBus from '@/services/event-bus';
import moment from 'moment';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  components: {
    DxContextMenu,
    DxAccordion,
    DxTextBox,
  },
  props: {
    geometriesData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      multiple: true,
      collapsible: true,
      animationDuration: 300,
      dataSource: [],
      filteredList: null,
      searchText: '',
    };
  },
  computed: {
    getMoment() {
      return moment;
    },
    ...mapState('contact', ['enabled']),
  },
  beforeMount() {
    this.reset();
  },
  mounted() {
    this.resetParcelGeoFeature([]);
    this.resetSigpacFeatureInfo();
    this.setTempParcelGeoFeatures([]);

    if (this.geometriesData.length > 0) {
      this.geometriesData.forEach((geometryData) => {
        if (this.$helpers.isEmptyObject(geometryData.geo_feature))
          return;
        const data = {};

        data.farm =
          geometryData.geo_feature.features[0].properties.farm;
        data.accountable =
          geometryData.geo_feature.features[0].properties.accountable;
        data.samplingDate = geometryData.sampling_date;

        data.cultive = geometryData.cultive;
        data.variety = geometryData.variety;
        data.id = geometryData.id;
        data.parcels = geometryData.children.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        data.station = `${geometryData.measured_station}-${geometryData.measured_provider}`;
        data.farmId = geometryData.farm_id;
        data.farmGeoFeature = geometryData;
        data.code = geometryData.code;
        this.dataSource.push(data);
      });
    }

    EventBus.$on('goToDashboard', (data) => {
      this.$notifyDX(
        {
          message: this.$t('loading'),
          width: 250,
        },
        'info',
        2000
      );

      this.goToDashboard(undefined, data);
    });
  },
  methods: {
    goToDashboard(e, data) {
      if (e) {
        e.stopPropagation();
      }
      if (!data.parcels[0]) return;
      const newCoordinates = data.parcels[0].geo_feature.features[0];
      newCoordinates.properties.gis_id =
        data.parcels[0].gis_id || data.parcels[0].id;

      this.setParcelIndex(0);
      this.resetParcelGeoFeature([]);
      this.setFarmStr(data.farm);
      this.setFarm(data.id);
      this.setFarmId(data.code);
      this.setFarmStr(data.farm);
      this.setParcel(null);
      this.setParcelId(newCoordinates.properties.id);
      this.setParcelGisId(newCoordinates.properties.gis_id);
      this.setParentParcelId(newCoordinates.properties.id);
      // this.setParentParcelId(data.parcels[0].id);
      this.setParcelStr(null);
      this.setAccountable(null);
      this.setStation(data.station);

      // farm
      // this.setLocation({
      //   newCoordinates:
      //     data.farmGeoFeature.geo_feature.features[0].geometry
      //       .coordinates,
      //   index: 0,
      //   area:
      //     data.farmGeoFeature.geo_feature.features[0].properties.area,
      //   centroide: {
      //     lat:
      //       data.farmGeoFeature.geo_feature.features[0].properties
      //         .latitude,
      //     lng:
      //       data.farmGeoFeature.geo_feature.features[0].properties
      //         .longitude,
      //   },
      //   originSigpacEditor: true,
      //   type:
      //     data.farmGeoFeature.geo_feature.features[0].geometry.type,
      // });

      this.setLocation({
        newCoordinates: newCoordinates.geometry.coordinates,
        index: 0,
        area: newCoordinates.properties.area,
        centroide: {
          lat: newCoordinates.properties.latitude,
          lng: newCoordinates.properties.longitude,
        },
        properties: {
          ...data.parcels[0].geo_feature.features[0].properties,
          gis_id: data.parcels[0].gis_id || data.parcels[0].id,
        },
        originSigpacEditor: false,
      });

      const dataParcels = [...data.parcels];
      dataParcels.splice(0, 1);

      this.setTempParcelGeoFeatures(
        dataParcels.map((parcel) => {
          parcel.geo_feature.features[0].properties.id = parcel.id;
          parcel.geo_feature.features[0].properties.gis_id =
            parcel.gis_id || parcel.id;

          return {
            ...parcel.geo_feature,
          };
        })
      );

      this.$f7router.navigate(
        { name: 'DashboardPage' },
        {
          animate: true,
          transition: 'f7-fade',
        }
      );
    },
    input(e) {
      const textToFilter = e.event.target.value;

      if (textToFilter.length > 0) {
        const res = this.findNode(textToFilter, this.dataSource);
        this.filteredList = res;
      } else {
        this.filteredList = null;
      }
    },
    async goToEditFarm(e, data) {
      e.stopPropagation();
      this.$f7.preloader.show();
      try {
        this.setTempParcelGeoFeatures(data.parcels);

        const geometry = await this.getFarmGeoFeature(data.id);
        const { properties } = geometry[0].geo_feature.features[0];

        const { area } = properties;
        const { latitude } = properties;
        const { longitude } = properties;
        const newCoordinates =
          geometry[0].geo_feature.features[0].geometry;

        const centroide = {
          lat: latitude,
          lng: longitude,
        };

        this.setFarm(data.id);
        this.setFarmId(data.farmGeoFeature.code);
        this.setAccountable(properties.accountable);
        this.setFarmStr(properties.farm);

        if (geometry[0].geo_feature.numberReturned) {
          this.replaceCurrentGeoFeature(geometry[0].geo_feature);
          this.setArea(properties.area);
          this.setCentroide({
            lat: properties.latitude,
            lng: properties.longitude,
          });
        } else {
          this.setLocation({
            newCoordinates: newCoordinates.coordinates,
            index: 0,
            area,
            centroide,
            originSigpacEditor: true,
          });
        }

        this.setIsEditingFarm(true);
        this.$f7router.navigate(
          { name: 'AdvancedServicesManagementPage' },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(error.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      } finally {
        this.$f7.preloader.hide();
      }
    },
    async goToEditParcel(e, parcel, index, data) {
      e.stopPropagation();
      this.$f7.preloader.show();
      try {
        const dataParcels = [...data.parcels];
        dataParcels.splice(index, 1);

        this.setTempParcelGeoFeatures(
          dataParcels.map((p) => p.geo_feature)
        );

        const farmGeoFeature = await this.getFarmGeoFeature(data.id);
        const geometry = parcel;

        const { properties } = geometry.geo_feature.features[0];
        const { area } = properties;
        const { latitude } = properties;
        const { longitude } = properties;
        const newCoordinates =
          geometry.geo_feature.features[0].geometry;

        const centroide = {
          lat: latitude,
          lng: longitude,
        };

        this.setFarm(data.id);
        this.setFarmId(farmGeoFeature[0].children[0].code);
        this.setAccountable(properties.accountable);
        this.setFarmStr(properties.farm);
        this.setParcel(properties.parcel_id);
        this.setParcelId(parcel.id);
        this.setParcelStr(properties.parcel_name);

        // Set farm

        if (farmGeoFeature[0].geo_feature.numberReturned) {
          this.replaceCurrentGeoFeature(
            farmGeoFeature[0].geo_feature
          );
          this.setArea(
            farmGeoFeature[0].geo_feature.features[0].properties.area
          );
          this.setCentroide({
            lat: farmGeoFeature[0].geo_feature.features[0].properties
              .latitude,
            lng: farmGeoFeature[0].geo_feature.features[0].properties
              .longitude,
          });
        } else {
          this.setLocation({
            newCoordinates:
              farmGeoFeature[0].geo_feature.features[0].geometry
                .coordinates,
            index: 0,
            area: farmGeoFeature[0].geo_feature.features[0].properties
              .area,
            centroide: {
              lat: farmGeoFeature[0].geo_feature.features[0]
                .properties.latitude,
              lng: farmGeoFeature[0].geo_feature.features[0]
                .properties.longitude,
            },
            originSigpacEditor: true,
          });
        }

        this.setLocation({
          newCoordinates: newCoordinates.coordinates,
          index: 0,
          area,
          centroide,
          properties,
          originSigpacEditor: false,
        });
        this.setIsEditingFarm(true);

        this.$f7router.navigate(
          { name: 'AdvancedServicesParcelRegistrationPage' },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );
      } catch (e) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(e.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      } finally {
        this.$f7.preloader.hide();
      }
    },
    async goToAssignStations(e, parcel, data) {
      e.stopPropagation();
      this.$f7.preloader.show();
      try {
        this.resetParcelGeoFeature([]);
        this.setParcelIndex(0);
        this.setParcelId(parcel.id);
        this.resetSelectedStations();

        const farmGeoFeature = await this.getFarmGeoFeature(data.id);
        const geometry = parcel;

        const { properties } = geometry.geo_feature.features[0];
        const { area } = properties;
        const { latitude } = properties;
        const { longitude } = properties;
        const newCoordinates =
          geometry.geo_feature.features[0].geometry;
        const centroide = {
          lat: latitude,
          lng: longitude,
        };

        this.setFarm(data.id);
        this.setAccountable(properties.accountable);
        this.setFarmStr(properties.farm);

        // Set farm
        this.setLocation({
          newCoordinates:
            farmGeoFeature[0].geo_feature.features[0].geometry
              .coordinates,
          index: 0,
          area: farmGeoFeature[0].geo_feature.features[0].properties
            .area,
          centroide: {
            lat: farmGeoFeature[0].geo_feature.features[0].properties
              .latitude,
            lng: farmGeoFeature[0].geo_feature.features[0].properties
              .longitude,
          },
          originSigpacEditor: true,
        });

        this.setLocation({
          newCoordinates: newCoordinates.coordinates,
          index: 0,
          area,
          centroide,
          originSigpacEditor: false,
        });

        this.setIsEditingFarm(true);

        this.$f7router.navigate(
          { name: 'AdvancedServicesStationRegistrationPage' },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(error.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      } finally {
        this.$f7.preloader.hide();
      }
    },
    findNode(searchText, array) {
      const res = [];

      for (const node of array) {
        if (
          node.farm
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          node.accountable
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          node.parcels.some((parcel) =>
            parcel.name
              .toLowerCase()
              .includes(searchText.toLowerCase())
          )
        ) {
          res.push(node);
        }

        if (node.items) {
          const child = this.findNode(searchText, node.items);
          if (child) {
            res.push(...child);
          }
        }
      }

      return res;
    },
    newParcel(e) {
      this.setIsCreatingParcel(true);
      this.setFarm(null);
      this.setParcel(null);
      this.setAccountable(null);
      this.resetAll({ isCreatingParcel: false });
      this.resetAll({ isCreatingParcel: true });
      this.setCurrentGeoFeature({ index: 0, newCoordinates: [] });
      this.setParcelIndex(0);

      this.$f7router.navigate(
        { name: 'AdvancedServicesParcelRegistrationPage' },
        {
          animate: true,
          transition: 'f7-fade',
        }
      );
    },
    newFarm(e) {
      this.setFarm(null);
      this.setParcel(null);
      this.setAccountable(null);
      this.resetCurrentGeoFeature();
      this.resetCentroide();

      this.$f7router.navigate(
        { name: 'AdvancedServicesManagementPage' },
        {
          animate: true,
          transition: 'f7-fade',
        }
      );
    },
    ...mapActions('registerFarm', [
      'setFarm',
      'setFarmId',
      'setParcel',
      'setParentParcelId',
      'setParcelGisId',
      'setParcelStr',
      'setParcelId',
      'setAccountable',
      'setIsEditingFarm',
      'setFarmStr',
      'setStations',
      'postHidrosophStationsForParcels',
      'setIsCreatingParcel',
      'reset',
      'resetSelectedStations',
    ]),
    ...mapActions('gisEditor', [
      'setCurrentGeoFeature',
      'resetAll',
      'setLocation',
      'resetParcelGeoFeature',
      'setParcelIndex',
      'setTempParcelGeoFeatures',
      'resetCurrentGeoFeature',
      'resetCentroide',
      'resetSigpacFeatureInfo',
      'replaceCurrentGeoFeature',
      'setArea',
      'setCentroide',
      'setFeature',
    ]),
    ...mapActions('gis', ['getFarmGeoFeature']),
    ...mapActions('weather', ['setStation']),
  },
};
</script>
<style lang="scss" scoped>
@import './SummaryGroupedList.styles.scss';
</style>
