<template>
  <span class="icon-container">
    <svg
      v-if="name === 'menu'"
      width="20"
      height="15"
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0H20V2.5H0V0ZM0 6.25H20V8.75H0V6.25ZM0 12.5H20V15H0V12.5Z"
        fill="#23465E"
      />
    </svg>

    <svg
      v-if="name === 'download'"
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.333496 2.33464C0.333496 1.89261 0.509091 1.46868 0.821651 1.15612C1.13421 0.843563 1.55814 0.667969 2.00016 0.667969H8.66683C8.88782 0.668016 9.09975 0.755844 9.25599 0.912135L13.4227 5.0788C13.579 5.23505 13.6668 5.44697 13.6668 5.66797V15.668C13.6668 16.11 13.4912 16.5339 13.1787 16.8465C12.8661 17.159 12.4422 17.3346 12.0002 17.3346H2.00016C1.55814 17.3346 1.13421 17.159 0.821651 16.8465C0.509091 16.5339 0.333496 16.11 0.333496 15.668V2.33464ZM11.6552 5.66797L8.66683 2.67964V5.66797H11.6552ZM7.00016 2.33464H2.00016V15.668H12.0002V7.33464H7.8335C7.61248 7.33464 7.40052 7.24684 7.24424 7.09056C7.08796 6.93428 7.00016 6.72232 7.00016 6.5013V2.33464ZM7.00016 8.58464C7.22118 8.58464 7.43314 8.67243 7.58942 8.82871C7.7457 8.98499 7.8335 9.19695 7.8335 9.41797V11.573L8.07766 11.3288C8.23483 11.177 8.44533 11.093 8.66383 11.0949C8.88233 11.0968 9.09134 11.1844 9.24584 11.339C9.40035 11.4935 9.48799 11.7025 9.48989 11.921C9.49179 12.1395 9.40779 12.35 9.25599 12.5071L7.58933 14.1738C7.43306 14.33 7.22113 14.4178 7.00016 14.4178C6.77919 14.4178 6.56727 14.33 6.411 14.1738L4.74433 12.5071C4.66474 12.4303 4.60125 12.3383 4.55758 12.2366C4.5139 12.135 4.49092 12.0256 4.48995 11.915C4.48899 11.8043 4.51008 11.6946 4.55198 11.5922C4.59388 11.4898 4.65576 11.3967 4.734 11.3185C4.81224 11.2402 4.90529 11.1784 5.0077 11.1365C5.11011 11.0946 5.21985 11.0735 5.3305 11.0744C5.44115 11.0754 5.5505 11.0984 5.65217 11.1421C5.75384 11.1857 5.84579 11.2492 5.92266 11.3288L6.16683 11.573V9.41797C6.16683 9.19695 6.25463 8.98499 6.41091 8.82871C6.56719 8.67243 6.77915 8.58464 7.00016 8.58464Z"
        fill="black"
      />
    </svg>

    <svg
      v-if="name === 'edit'"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.3758 3.14844H3.15746C2.76203 3.14844 2.3828 3.30551 2.10319 3.5851C1.82359 3.8647 1.6665 4.24391 1.6665 4.63931V15.0754C1.6665 15.4709 1.82359 15.8501 2.10319 16.1297C2.3828 16.4093 2.76203 16.5663 3.15746 16.5663H13.5941C13.9896 16.5663 14.3688 16.4093 14.6484 16.1297C14.928 15.8501 15.0851 15.4709 15.0851 15.0754V9.85738"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.9672 2.02956C14.2637 1.73301 14.666 1.56641 15.0854 1.56641C15.5048 1.56641 15.907 1.73301 16.2036 2.02956C16.5002 2.32612 16.6668 2.72833 16.6668 3.14772C16.6668 3.56711 16.5002 3.96932 16.2036 4.26588L9.12156 11.3475L6.13965 12.093L6.88513 9.11122L13.9672 2.02956Z"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <svg
      v-if="name === 'delete'"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.53125 1.35H4.375C4.46094 1.35 4.53125 1.2825 4.53125 1.2V1.35H10.4688V1.2C10.4688 1.2825 10.5391 1.35 10.625 1.35H10.4688V2.7H11.875V1.2C11.875 0.538125 11.3145 0 10.625 0H4.375C3.68555 0 3.125 0.538125 3.125 1.2V2.7H4.53125V1.35ZM14.375 2.7H0.625C0.279297 2.7 0 2.96813 0 3.3V3.9C0 3.9825 0.0703125 4.05 0.15625 4.05H1.33594L1.81836 13.8563C1.84961 14.4956 2.40039 15 3.06641 15H11.9336C12.6016 15 13.1504 14.4975 13.1816 13.8563L13.6641 4.05H14.8438C14.9297 4.05 15 3.9825 15 3.9V3.3C15 2.96813 14.7207 2.7 14.375 2.7ZM11.7832 13.65H3.2168L2.74414 4.05H12.2559L11.7832 13.65Z"
        fill="black"
      />
    </svg>

    <svg
      v-if="name === 'copy'"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5558 2.22213H11.9724C11.8207 1.80553 11.5444 1.44569 11.1812 1.19143C10.8179 0.937162 10.3853 0.800781 9.94188 0.800781C9.49849 0.800781 9.06583 0.937162 8.70259 1.19143C8.33935 1.44569 8.0631 1.80553 7.91133 2.22213H7.44466C7.09149 2.22359 6.7532 2.36454 6.50347 2.61427C6.25374 2.864 6.11279 3.20229 6.11133 3.55547V5.55547H13.8891V3.55547C13.8876 3.20229 13.7467 2.864 13.497 2.61427C13.2472 2.36454 12.9089 2.22359 12.5558 2.22213V2.22213ZM12.778 4.44435H7.22244V3.47213C7.22244 3.4353 7.23707 3.39997 7.26312 3.37392C7.28917 3.34788 7.32449 3.33324 7.36133 3.33324H8.85577L8.92244 2.71658C8.93426 2.61687 8.96774 2.52096 9.02052 2.43555C9.0733 2.35014 9.14411 2.2773 9.22799 2.22213C9.3771 2.11098 9.55171 2.03903 9.73583 2.01288C9.91996 1.98673 10.1077 2.00721 10.2819 2.07245C10.456 2.13769 10.611 2.2456 10.7326 2.38628C10.8542 2.52697 10.9386 2.69593 10.978 2.87769L11.028 3.34435H12.6391C12.6759 3.34435 12.7113 3.35899 12.7373 3.38503C12.7634 3.41108 12.778 3.44641 12.778 3.48324V4.44435Z"
        fill="black"
      />
      <path
        d="M18.4722 10.0323H11.8499L13.4277 8.46009C13.4795 8.40829 13.5206 8.34679 13.5486 8.27911C13.5767 8.21143 13.5911 8.1389 13.5911 8.06564C13.5911 7.99239 13.5767 7.91985 13.5486 7.85217C13.5206 7.78449 13.4795 7.723 13.4277 7.6712C13.3759 7.6194 13.3144 7.57831 13.2468 7.55027C13.1791 7.52224 13.1065 7.50781 13.0333 7.50781C12.96 7.50781 12.8875 7.52224 12.8198 7.55027C12.7521 7.57831 12.6906 7.6194 12.6388 7.6712L9.72217 10.5879L12.6388 13.5045C12.6907 13.556 12.7523 13.5968 12.82 13.6244C12.8877 13.6521 12.9602 13.6661 13.0333 13.6656C13.1425 13.6652 13.2491 13.6326 13.3399 13.5718C13.4307 13.5111 13.5015 13.425 13.5436 13.3242C13.5857 13.2234 13.5972 13.1125 13.5766 13.0052C13.5559 12.898 13.5042 12.7992 13.4277 12.7212L11.8499 11.1434H18.4722C18.6195 11.1434 18.7608 11.0849 18.865 10.9807C18.9692 10.8765 19.0277 10.7352 19.0277 10.5879C19.0277 10.4405 18.9692 10.2992 18.865 10.195C18.7608 10.0908 18.6195 10.0323 18.4722 10.0323Z"
        fill="black"
      />
      <path
        d="M16.1111 8.88846H17.2222V3.71068C17.2237 3.5891 17.2011 3.46844 17.1557 3.35563C17.1104 3.24282 17.0432 3.14008 16.958 3.05334C16.8728 2.9666 16.7713 2.89756 16.6593 2.85019C16.5474 2.80282 16.4271 2.77807 16.3055 2.77734H15.0444V3.88845H16.1111V8.88846Z"
        fill="black"
      />
      <path
        d="M16.1112 17.2218H3.88901V3.88845H5.00012V2.77734H3.68901C3.44539 2.78027 3.21282 2.87949 3.04211 3.05333C2.8714 3.22716 2.77641 3.46149 2.7779 3.70512V17.3996C2.77643 17.5211 2.79902 17.6418 2.84436 17.7546C2.8897 17.8674 2.9569 17.9702 3.0421 18.0569C3.12729 18.1436 3.2288 18.2127 3.34077 18.2601C3.45275 18.3074 3.57299 18.3322 3.69457 18.3329H16.3112C16.5549 18.33 16.7874 18.2308 16.9581 18.0569C17.1288 17.8831 17.2238 17.6488 17.2223 17.4051V12.2551H16.1112V17.2218Z"
        fill="black"
      />
    </svg>
    <i v-if="name === 'excel'" class="dx-icon dx-icon-xlsxfile"></i>
    <i v-if="name === 'pdf'" class="dx-icon dx-icon-pdffile"></i>
  </span>
</template>

<script>
export default {
  name: 'ContextMenuIcons',
  props: {
    name: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import './contextMenu.styles.scss';
</style>
