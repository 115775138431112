import i18next from 'i18next';

export default {
  namespaced: true,
  state: {
    selectedParameters: [],
    selectedLimits: [],
    selectedLMRs: [],
    selectedParameterGroups: [],
  },
  actions: {
    setSelectedParameters({ commit }, value) {
      commit('setSelectedParameters', value);
    },
    setSelectedLMRs({ commit }, value) {
      commit('setSelectedLMRs', value);
    },
    setSelectedParameterGroup({ commit }, value) {
      commit('setSelectedParameterGroup', value);
    },
    setSelectedLimits({ commit }, value) {
      commit('setSelectedLimits', value);
    },
    includesCMA({ state }) {
      if (state.selectedLimits.length > 0) {
        return state.selectedLimits.includes('> CMA');
      }

      return false;
    },
    includesLMR({ state }) {
      if (state.selectedLimits && state.selectedLimits.length > 0) {
        return state.selectedLimits.includes('> LMR');
      }

      return false;
    },
    includesPositives({ state }) {
      if (state.selectedLimits && state.selectedLimits.length > 0) {
        return state.selectedLimits.includes(i18next.t('positives'));
      }

      return false;
    },
    clearFilters({ commit }) {
      commit('clearFilters');
    },
  },
  mutations: {
    setSelectedParameters(state, value) {
      state.selectedParameters = value;
    },
    setSelectedLimits(state, value) {
      state.selectedLimits = value;
    },
    setSelectedLMRs(state, value) {
      state.selectedLMRs = value;
    },
    setSelectedParameterGroup(state, value) {
      state.selectedParameterGroups = value;
    },
    clearFilters(state) {
      state.selectedParameters = [];
      state.selectedLimits = [];
      state.selectedLMRs = [];
      state.selectedParameterGroups = [];
    },
  },
};
