<template>
  <f7-page name="usersManagement">
    <UserEditForm />
    <f7-row>
      <f7-col>
        <GoBack
          class="margin-top-half"
          url-name="UsersManagementPage"
        />
      </f7-col>
      <f7-col>
        <f7-button
          class="dx-btn-back buttonPositionRight buttonRight30px"
          raised
          type="default"
          styling-mode="contained"
          style="
            color: white;
            background: purple;
            border-radius: 3px;
            margin-top: 10px;
          "
          name="ButtonSave"
          @click="updateUserNewData"
        >
          {{ $t('save') }}
        </f7-button>
      </f7-col>
    </f7-row>
  </f7-page>
</template>
<script>
import UserEditForm from '@/components/UserEditForm';
import GoBack from '@/components/UtilityComponents/GoBack/index.vue';
import { mapActions } from 'vuex';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  components: {
    UserEditForm,
    GoBack,
  },
  methods: {
    async updateUserNewData() {
      try {
        this.$f7.dialog.preloader(
          `${this.$t('updating_user')}`,
          'primary'
        );
        await this.updateUserAddresses();
        this.$f7.dialog.close();
        this.$f7.dialog.alert(`${this.$t('user_updated')}`);
        this.$f7router.navigate(
          { name: 'UsersManagementPage' },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );
      } catch (error) {
        this.$f7.dialog.close();
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(error.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      }
    },
    ...mapActions('company', [
      'resetStatesForNewEditOrCreate',
      'updateUserAddresses',
    ]),
  },
};
</script>
<style lang="scss" scoped></style>
