<template>
  <div class="margin-top">
    <div class="label-input margin-top-half">
      {{ $t('foodSamples.receptionDate') }}
    </div>
    <div class="marginTop5 recepDate">
      <f7-input
        class="shadow"
        type="datepicker"
        :placeholder="$t('foodSamples.receptionDate')"
        :clear-button="true"
        :close-on-select="true"
        :value="calculatedReceptionDate"
        :calendar-params="{
          closeOnSelect: true,
          rangePicker: true,
          dateFormat: {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
          },
          disabled: isDisabled,
        }"
        @calendar:change="setReceptionDate"
      />
    </div>

    <div class="margin-top-half label-input">
      {{ $t('foodSamples.endDate') }}
    </div>
    <div class="recepDate marginTop5">
      <f7-input
        class="shadow"
        type="datepicker"
        :placeholder="$t('foodSamples.endDate')"
        :clear-button="true"
        :close-on-select="true"
        :value="calculatedFoodEndDate"
        :calendar-params="{
          closeOnSelect: true,
          rangePicker: true,
          dateFormat: {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
          },
          disabled: isDisabled,
        }"
        @calendar:change="setEndDate"
      />
    </div>

    <div class="margin-top-half label-input">
      {{ $t('agronomySample.summary.sampleType') }}
    </div>

    <div class="marginTop5">
      <DxTagBox
        :data-source="sampleTypes"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('agronomySample.summary.sampleType')"
        tag-template="tag"
        display-expr="value"
        value-expr="id"
        :value="selectedSampleTypes"
        :on-value-changed="sampleTypesChanged"
        :on-opened="openedSampleTypes"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>

    <div class="margin-top-half label-input">{{ $t('status') }}</div>
    <div class="marginTop5">
      <DxTagBox
        :data-source="statuses"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('status')"
        tag-template="tag"
        display-expr="value"
        value-expr="id"
        :value="selectedStatuses"
        :on-value-changed="statusesChanged"
        :on-opened="openedStatuses"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>

    <div class="margin-top-half label-input">
      {{ $t('foodSamples.analysisType') }}
    </div>
    <div class="marginTop5">
      <DxTagBox
        :data-source="analyseType"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('foodSamples.analysisType')"
        tag-template="tag"
        display-expr="value"
        value-expr="value"
        :value="selectedAnalyseTypes"
        :on-value-changed="analyseTypesChanged"
        :on-opened="openedAnalyseType"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { DxTagBox } from 'devextreme-vue/tag-box';
import DataSource from 'devextreme/data/data_source';
import moment from 'moment';
import { AFTER_DATE_LIMIT } from '@/js/constants';

export default {
  name: 'HealthSecuritySamplesFilter',
  components: {
    DxTagBox,
  },
  data() {
    return {
      sampleTypes: [],
      statuses: [],
      analyseType: [],
      loaded: false,
    };
  },
  computed: {
    calculatedReceptionDate() {
      if (
        this.receptionFromDate !== null &&
        this.receptionToDate !== null
      ) {
        return [this.receptionFromDate, this.receptionToDate];
      }
      return [];
    },
    calculatedFoodEndDate() {
      if (this.endToDate !== null && this.endFromDate !== null) {
        return [this.endFromDate, this.endToDate];
      }
      return [];
    },
    ...mapState('healthSecurityFilter', [
      'receptionFromDate',
      'receptionToDate',
      'endToDate',
      'endFromDate',
      'selectedSampleTypes',
      'selectedStatuses',
      'selectedAnalyseTypes',
    ]),
    ...mapState('healthSecuritySamples', ['allSamples']),
  },
  methods: {
    isDisabled(date) {
      return (
        moment(date).isBefore(moment(AFTER_DATE_LIMIT), 'day') ||
        moment(date).isAfter(moment(), 'day')
      );
    },
    async openedSampleTypes(e) {
      const sampleTypes = await this.getSampleFilter({
        atc: 'Salud y Seguridad',
        attribute: 'sample_type',
      });

      this.sampleTypes = new DataSource({
        store: sampleTypes,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedStatuses(e) {
      const statuses = await this.getSampleFilter({
        atc: 'Salud y Seguridad',
        attribute: 'status',
      });

      this.statuses = statuses.sort();
      this.loaded = true;
    },
    async openedAnalyseType(e) {
      const analyseType = await this.getSampleFilter({
        atc: 'Salud y Seguridad',
        attribute: 'analyse_type',
      });

      this.analyseType = new DataSource({
        store: analyseType,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    contentReady(e) {
      const arrayNotFoundItems = Array.from(
        document.getElementsByClassName('dx-empty-message')
      );

      arrayNotFoundItems.forEach((element) => {
        if (!this.loaded) {
          element.innerHTML = this.$t('loading');
        } else {
          element.innerHTML = this.$t('dataNotAvailable');
        }
      });
      this.loaded = false;
    },
    setReceptionDate(e) {
      if (e.length === 2) {
        this.setReceptionFromDate(e[0]);
        this.setReceptionToDate(e[1]);
      } else if (e.length === 0) {
        this.setReceptionFromDate(null);
        this.setReceptionToDate(null);
      }
    },
    setEndDate(e) {
      if (e.length === 2) {
        this.setEndFromDate(e[0]);
        this.setEndToDate(e[1]);
      } else if (e.length === 0) {
        this.setEndFromDate(null);
        this.setEndToDate(null);
      }
    },
    sampleTypesChanged(e) {
      this.setSelectedSampleTypes(e.value);
    },
    statusesChanged(e) {
      this.setSelectedStatuses(e.value);
    },
    analyseTypesChanged(e) {
      this.setSelectedAnalyseTypes(e.value);
    },
    ...mapActions('healthSecurityFilter', [
      'setReceptionFromDate',
      'setReceptionToDate',
      'setEndToDate',
      'setEndFromDate',
      'setSelectedSampleTypes',
      'setSelectedStatuses',
      'setSelectedAnalyseTypes',
    ]),
    ...mapActions('samples', ['getSampleFilter']),
  },
};
</script>
