<template>
  <div class="diagnosis-table">
    <div class="filterButtonPosition">
      <f7-button
        fill
        class="whiteFontColor button-filter"
        panel-toggle="right"
      >
        <span class="ic-filter"></span> {{ $t('filter') }}
      </f7-button>
    </div>
    <DxDataGrid
      :data-source="filteredData"
      :show-borders="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :show-colon="false"
      :remote-operations="true"
      :hover-state-enabled="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
    >
      <DxScrolling mode="standard" />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="[5, 10, 20]"
        :show-info="true"
        info-text="{2} items"
        :visible="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="300"
        :placeholder="$t('search')"
      />
      <DxExport :enabled="false" />
      <DxColumn
        data-field="alarm"
        alignment="left"
        caption=""
        cell-template="cellTemplateDots"
        :allow-header-filtering="false"
      />
      <!-- <template #cellTemplateHeaderDots="{ data }">
        <div>
          <PopUp position="top">
            <div style="padding: 10px">
              <div class="pop-up-info">
                <div class="dots-parent-container">
                  <div class="dot" :class="getDotClass('0')"></div>
                </div>
                <div style="padding-left: 5px">
                  {{
                    $t('agronomySample.summary.greyDotDescription')
                  }}
                </div>
              </div>
              <div class="pop-up-info">
                <div class="dot" :class="getDotClass('0')"></div>
                <div style="padding-left: 5px">
                  {{
                    $t('agronomySample.summary.greenDotDescription')
                  }}
                </div>
              </div>
              <div class="pop-up-info">
                <div class="dot" :class="getDotClass('0')"></div>
                <div style="padding-left: 5px">
                  {{
                    $t('agronomySample.summary.orangeDotDescription')
                  }}
                </div>
              </div>
              <div class="pop-up-info">
                <div class="dot" :class="getDotClass('0')"></div>
                <div style="padding-left: 5px">
                  {{ $t('agronomySample.summary.redDotDescription') }}
                </div>
              </div>
            </div>
          </PopUp>
        </div>
      </template> -->
      <template #cellTemplateDots="{ data }">
        <div class="dots-parent-container">
          <div
            class="dot"
            :class="getDotClass(data.data.alert)"
          ></div>
        </div>
      </template>
      <DxColumn
        data-field="block"
        :caption="$t('diagnosisTable.block')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="section"
        :caption="$t('diagnosisTable.unit')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="module"
        :caption="$t('diagnosisTable.element')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="item"
        :caption="$t('diagnosisTable.point')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="diagnostic"
        :caption="$t('diagnosisTable.diagnosis')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="amendment"
        :caption="$t('diagnosisTable.measures')"
        alignment="left"
        :allow-header-filtering="false"
      />
    </DxDataGrid>

    <NotificationSpinner
      v-if="notificationMessage.length > 0"
      :message="notificationMessage"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxPager,
  DxPaging,
  DxScrolling,
  DxExport,
} from 'devextreme-vue/data-grid';

import { ALLOWED_SAMPLES_PARAMETERS, ATCS_ES } from '@/js/constants';
import NotificationSpinner from '@/components/NotificationSpinner';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxPager,
    DxPaging,
    DxScrolling,
    DxExport,
    NotificationSpinner,
  },
  props: {
    store: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      notificationMessage: '',
    };
  },
  computed: {
    ...mapState('expertSystemFilter', ['filteredData']),
  },
  beforeMount() {
    this.setCurrentFilter('expertSystem');
    this.setAllFilterParams({ atc_name: ATCS_ES.agronomy });
  },
  methods: {
    getDotClass(alert) {
      const number = alert === null ? 4 : parseInt(alert, 10) + 1;
      return `dot-${number}`;
    },
    ...mapActions('filter', [
      'setCurrentFilter',
      'setCurrentSampleId',
    ]),
    ...mapActions('samples', [
      'setReportSampleId',
      'getReport',
      'setCurrentSample',
      'setReportSampleCode',
      'setDisableContext',
      'getSamplesWithParameters',
      'setAllFilterParams',
      'getSamplesZip',
      'setIsDownloadingReport',
      'setSampleFetchedFromEtl',
      'setLastRouteHome',
    ]),
    ...mapActions('breadcrumb', ['replaceCurrent']),
  },
};
</script>

<style scoped>
.diagnosis-table {
  background: #ffffff;
  padding: 0.5rem;
  border-radius: 4px;
}
.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #d1d5db;
  margin: 0 5px;
}

.dot-1 {
  background-color: #e0f3f8;
}

.dot-2 {
  background-color: #ffd966;
}

.dot-3 {
  background-color: #de7e7e;
}

.dot-4 {
  background-color: #d1d5db;
}
</style>
