import Dexie from 'dexie';

const db = new Dexie('besafer');

db.version(1).stores({
  //   {
  //     "addressCode": "PO01",
  //     "addressName": "AGQ PORTUGAL",
  //     "name": "",
  //     "companyName": "",
  //     "companyCode": "PO01-00005978"
  // }
  headquarters:
    '++uid, addressCode, addressName, name, companyName, companyCode',
  // {
  //     "id": "624584f198a550e8707a25ef_002",
  //     "name": "CHILEXPRESS CALERA",
  //     "companyId": "624584f198a550e8707a25ef",
  //     "addressCompany": "CL01-00022909",
  //     "companyName": "ANDRES NICOLAS RAMIREZ CASTILLO -",
  //     "addressCode": "002",
  //     "contactIndex": 1,
  //     "itemsData": [
  //       {
  //         "id": "624584f198a550e8707a25ef_002",
  //         "name": "CHILEXPRESS CALERA",
  //         "companyId": "624584f198a550e8707a25ef",
  //         "addressCompany": "CL01-00022909",
  //         "companyName": "ANDRES NICOLAS RAMIREZ CASTILLO -",
  //         "addressCode": "002"
  //       }
  //     ]
  //   }
  contacts:
    '++uid, id, name, companyId, addressCompany, city, language, companyName, addressCode, contactIndex, itemsData',
});

export default db;
