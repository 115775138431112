<template>
  <div class="margin-top">
    <div class="filter-box">
      <div class="label-input">
        {{ $t('agronomySample.summary.sampleType')
        }}<span class="requiered-icon color-orange">*</span>
      </div>

      <div class="marginTop5">
        <DxTagBox
          :data-source="sampleTypes"
          :search-enabled="true"
          :multiline="true"
          :placeholder="$t('agronomySample.summary.sampleType')"
          tag-template="tag"
          display-expr="sample"
          value-expr="id"
          :value="selectedSampleTypes"
          :on-value-changed="sampleTypesChanged"
          :on-opened="openedSampleTypes"
          :on-content-ready="contentReady"
          :show-selection-controls="true"
        >
          <template #tag="{ data }">
            <div class="purpleTag">
              <b>{{ data.sample }}</b>
              <div class="dx-tag-remove-button xButton"></div>
            </div>
          </template>
        </DxTagBox>
      </div>

      <div class="label-input margin-top-half">
        {{ $t('foodSamples.lmr')
        }}<span class="requiered-icon color-orange">*</span>
      </div>
      <div class="marginTop5">
        <DxSelectBox
          :items="availableLmrs"
          :placeholder="$t('selectCountry')"
          :value="selectedLMRs"
          display-expr="name"
          value-expr="code"
          :on-value-changed="lmrChanged"
          :on-opened="openedLmr"
          :on-content-ready="contentReady"
          :show-clear-button="true"
        >
        </DxSelectBox>
      </div>

      <div class="label-input margin-top-half">
        {{ $t('dateRange')
        }}<span class="requiered-icon color-orange">*</span>
      </div>
      <div class="recepDate marginTop5">
        <f7-input
          class="shadow"
          type="datepicker"
          :placeholder="$t('date')"
          :clear-button="true"
          :close-on-select="true"
          :value="calculatedDate"
          :calendar-params="{
            closeOnSelect: true,
            rangePicker: true,
            dateFormat: {
              month: 'numeric',
              day: 'numeric',
              year: 'numeric',
            },
            disabled: isDisabled,
          }"
          @calendar:change="setDates"
        />
      </div>
    </div>

    <div v-if="disableOnSelectedSampleTypes" class="margin-top">
      <div class="content-toogle">
        <div class="title-label">
          <strong class="label-input no-margin-left">{{
            $t('alert.parameters')
          }}</strong>
          <span
            id="parameters-info"
            class="analysis-risk-info"
            @mouseenter="toggleWithAnimationOptions"
            @mouseleave="toggleWithAnimationOptions"
          >
            <i class="f7-icons"> info_circle_fill </i>
          </span>
        </div>
        <div>
          <DxPopover
            :visible="withAnimationOptionsVisible"
            :animation="animationConfig"
            target="#parameters-info"
            position="top"
          >
            <div class="padding">
              {{ $t('stats.paramsMessage') }}
            </div>
          </DxPopover>

          <DxSwitch
            :on-value-changed="isParamsSelectedChanged"
            :value="isParamsSelected"
          />
        </div>
      </div>
    </div>
    <div v-if="isParamsSelected" class="margin-top-half">
      <DxTagBox
        :data-source="parameters"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('alert.parameters')"
        tag-template="tag"
        display-expr="Parametro"
        value-expr="idParametro"
        :value="selectedParameters"
        :on-value-changed="parametersChanged"
        :on-opened="openedParameters"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
        :disabled="disabledOptionalParameters"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.Parametro }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>

    <div class="margin-top">
      <div class="content-toogle">
        <div class="title-label">
          <div
            class="display-flex flex-direction-column margin-bottom-half"
          >
            <strong class="label-input no-margin-left">{{
              $t('samples')
            }}</strong>
          </div>
        </div>
      </div>
    </div>

    <div>
      <span class="italic text-color-gray marginLeft5">
        {{ $t('stats.companyAggregation') }}
      </span>

      <div style="float: right">
        <DxSwitch
          :on-value-changed="isCompanyAggregatedChanged"
          :value="isCompanyAggregated"
          :disabled="!loggedUserIsContact"
        />
      </div>
    </div>

    <div
      v-if="disableOnSelectedSampleTypes"
      class="margin-top-half label-input"
    >
      {{ $t('foodSamples.analysisType') }}
    </div>

    <div v-if="disableOnSelectedSampleTypes" class="marginTop5">
      <DxTagBox
        :data-source="analyseTypes"
        :multiline="true"
        :placeholder="$t('foodSamples.analysisType')"
        :search-enabled="true"
        tag-template="tag"
        :value="selectedAnalyseTypes"
        :on-value-changed="analyseTypesChanged"
        :on-opened="openedAnalyseTypes"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
        :disabled="disabledOptionalParameters"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>

    <div
      v-if="disableOnSelectedSampleTypes"
      class="label-input margin-top-half"
    >
      {{ $t('foodSamples.thirdClient') }}
    </div>

    <div v-if="disableOnSelectedSampleTypes" class="marginTop5">
      <DxTagBox
        :data-source="thirdClients"
        :multiline="true"
        :placeholder="$t('foodSamples.thirdClient')"
        tag-template="tag"
        :value="selectedThirdClients"
        :on-value-changed="thirdClientsChanged"
        :on-opened="openedThirdClients"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
        :disabled="disabledOptionalParameters"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>

    <div class="margin-top">
      <div class="content-toogle">
        <div>
          <strong class="label-input no-margin-left">{{
            $t('stats.comparatives')
          }}</strong>
        </div>
        <DxSwitch
          :on-value-changed="isComparativeChanged"
          :value="isComparative"
        />
      </div>
    </div>

    <div v-if="isComparative" class="margin-top-half">
      <div class="content-toogle">
        <div class="title-label">
          <strong class="label-input no-margin-left">{{
            $t('stats.agqGlobal')
          }}</strong>

          <span
            id="global-agq-info"
            class="analysis-risk-info"
            @mouseenter="toggleWithAnimationOptionsGlobalAGQ"
            @mouseleave="toggleWithAnimationOptionsGlobalAGQ"
          >
            <i class="f7-icons"> info_circle_fill </i>
          </span>
        </div>
        <div>
          <DxPopover
            :width="300"
            :visible="withAnimationOptionsVisibleGlobalAGQ"
            :animation="animationConfig"
            target="#global-agq-info"
            position="top"
          >
            <div class="padding-horizontal-half">
              <p>
                {{ $t('stats.defaultComparative') }}
              </p>

              <p>
                {{ $t('stats.globalSamples') }}
              </p>
            </div>
          </DxPopover>
        </div>
        <DxSwitch
          :on-value-changed="isGlobalAGQChanged"
          :value="isGlobalAGQ"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { DxTagBox } from 'devextreme-vue/tag-box';
import { mapActions, mapGetters, mapState } from 'vuex';
import DxSelectBox from 'devextreme-vue/select-box';
import { DxSwitch } from 'devextreme-vue/switch';
import { DxPopover } from 'devextreme-vue/popover';
import DataSource from 'devextreme/data/data_source';
import moment from 'moment';
import { AFTER_DATE_LIMIT } from '@/js/constants';

export default {
  name: 'RiskAnalysisFilter',
  components: {
    DxTagBox,
    DxSelectBox,
    DxSwitch,
    DxPopover,
  },
  data() {
    return {
      MAX_CROP_TYPE_SELECTIONS: 5,
      MAX_NUMBER_OF_PARAMETERS: 10,
      sampleTypes: [],
      lmrs: [],
      parameters: [],
      analyseTypes: [],
      thirdClients: [],
      samplers: [],
      withAnimationOptionsVisible: false,
      withAnimationOptionsVisibleGlobalAGQ: false,
      animationConfig: {
        show: {
          type: 'pop',
          from: {
            scale: 0,
          },
          to: {
            scale: 1,
          },
        },
        hide: {
          type: 'fade',
          from: 1,
          to: 0,
        },
      },
      loaded: false,
    };
  },
  computed: {
    disabledOptionalParameters() {
      return (
        this.selectedSampleTypes?.length === 0 ||
        this.selectedLMRs?.length === 0 ||
        this.calculatedDate?.length === 0
      );
    },
    loggedUserIsContact() {
      return this.enabled && this.isAdmin;
    },
    calculatedDate() {
      if (this.toDate !== null && this.fromDate !== null) {
        return [this.fromDate, this.toDate];
      }
      return [];
    },
    disableOnSelectedSampleTypes() {
      return (
        this.selectedSampleTypes.length >= 0 &&
        this.selectedSampleTypes.length < 2
      );
    },
    ...mapGetters('user', ['isAdmin']),
    ...mapState('contact', ['enabled', 'company']),
    ...mapState('foodStatsFilter', [
      'selectedSampleTypes',
      'selectedLMRs',
      'selectedParameters',
      'toDate',
      'fromDate',
      'isParamsSelected',
      'isCompanyAggregated',
      'selectedAnalyseTypes',
      'selectedThirdClients',
      'selectedSamplers',
      'availableLmrs',
      'isComparative',
      'isGlobalAGQ',
    ]),
  },
  methods: {
    isDisabled(date) {
      return (
        moment(date).isBefore(moment(AFTER_DATE_LIMIT), 'day') ||
        moment(date).isAfter(moment(), 'day')
      );
    },
    async openedSampleTypes(e) {
      const sampleTypes = await this.getSampleTypes({
        atc_name: 'Alimentaria',
        company_code: this.company,
      });

      const sampleTypesSorted = sampleTypes.sort((a, b) =>
        a.sample > b.sample ? 1 : -1
      );

      this.sampleTypes = new DataSource({
        store: sampleTypesSorted,
        key: '',
        paginate: true,
        pageSize: 10,
      });

      this.loaded = true;
    },
    toggleWithAnimationOptions() {
      this.withAnimationOptionsVisible =
        !this.withAnimationOptionsVisible;
    },

    toggleWithAnimationOptionsGlobalAGQ() {
      this.withAnimationOptionsVisibleGlobalAGQ =
        !this.withAnimationOptionsVisibleGlobalAGQ;
    },
    async openedAnalyseTypes(e) {
      const analyseTypes = await this.getAnalyseTypes({
        atc_name: 'Alimentaria',
      });

      this.analyseTypes = new DataSource({
        store: analyseTypes,
        key: '',
        paginate: true,
        pageSize: 10,
      });

      this.loaded = true;
    },
    async openedLmr(e) {
      const countries = await this.getDestinationCountries();

      countries.sort((a, b) => (a.name > b.name ? 1 : -1));

      this.setAvailableLmrs(
        countries.map((country) => {
          return {
            ...country,
            name: country.name.capitalize(),
          };
        })
      );

      this.loaded = true;
    },
    async openedParameters(e) {
      const parameters = await this.getEntity({
        entity: 'lmrByTypeSample_Country',
        sampleTypeId: 2024,
        countryCode: 'UE',
      });

      this.parameters = new DataSource({
        store: parameters,
        key: '',
        paginate: true,
        pageSize: 10,
      });

      this.loaded = true;
    },
    async openedThirdClients(e) {
      const thirdClients = await this.getThirdClients();

      this.thirdClients = new DataSource({
        store: thirdClients,
        key: '',
        paginate: true,
        pageSize: 10,
      });

      this.loaded = true;
    },
    async openedSamplers(e) {
      const samplers = await this.getSamplers();

      this.samplers = new DataSource({
        store: samplers,
        key: '',
        paginate: true,
        pageSize: 10,
      });

      this.loaded = true;
    },
    isParamsSelectedChanged(e) {
      this.setIsParamsSelected(e.value);
      this.setSelectedParameters([]);
    },
    isCompanyAggregatedChanged(e) {
      this.setIsCompanyAggregated(e.value);
    },
    isComparativeChanged(e) {
      this.setIsComparative(e.value);
    },
    isGlobalAGQChanged(e) {
      this.setIsGlobalAGQ(e.value);
    },
    contentReady(e) {
      const arrayNotFoundItems = Array.from(
        document.getElementsByClassName('dx-empty-message')
      );

      arrayNotFoundItems.forEach((element) => {
        if (!this.loaded) {
          element.innerHTML = this.$t('loading');
        } else {
          element.innerHTML = this.$t('dataNotAvailable');
        }
      });

      this.loaded = false;
    },
    setDates(e) {
      if (e.length === 2) {
        this.setFromDate(e[0]);
        this.setToDate(e[1]);
      } else if (e.length === 0) {
        this.setFromDate(null);
        this.setToDate(null);
      }
    },
    sampleTypesChanged(e) {
      if (e.value.length > this.MAX_CROP_TYPE_SELECTIONS) {
        const allPrevValues = e.previousValue;
        e.component.option('value', allPrevValues);
      } else {
        this.setSelectedSampleTypes(e.value);
      }
    },
    lmrChanged(e) {
      this.setSelectedLmrs(e.value);
    },
    parametersChanged(e) {
      if (e.value.length > this.MAX_NUMBER_OF_PARAMETERS) {
        const allPrevValues = e.previousValue;
        e.component.option('value', allPrevValues);
      } else {
        this.setSelectedParameters(e.value);
      }
    },
    analyseTypesChanged(e) {
      this.setSelectedAnalyseTypes(e.value);
    },
    thirdClientsChanged(e) {
      this.setSelectedThirdClients(e.value);
    },
    samplersChanged(e) {
      this.setSelectedSamplers(e.value);
    },
    ...mapActions('riskAnalysis', ['getDestinationCountries']),
    ...mapActions('foodStatsFilter', [
      'setSelectedSampleTypes',
      'setToDate',
      'setFromDate',
      'setSelectedLmrs',
      'setSelectedParameters',
      'setIsParamsSelected',
      'setIsCompanyAggregated',
      'setSelectedAnalyseTypes',
      'setSelectedThirdClients',
      'setSelectedSamplers',
      'setIsComparative',
      'setIsGlobalAGQ',
      'setAvailableLmrs',
    ]),
    ...mapActions('alertsParams', ['getEntity']),
    ...mapActions('samples', ['getSampleFilter']),
    ...mapActions('foodStats', [
      'getAnalyseTypes',
      'getSamplers',
      'getThirdClients',
      'getSampleTypes',
    ]),
  },
};
</script>
<style>
.content-toogle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-toogle .title-label {
  display: flex;
  align-items: center;
}
</style>
