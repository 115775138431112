export default {
  namespaced: true,
  state: {
    receptionFromDate: null,
    receptionToDate: null,
    endFromDate: null,
    endToDate: null,
    selectedSampleTypes: [],
    selectedATCs: [],
    selectedStatuses: [],
    selectedAnalyseTypes: [],
  },
  actions: {
    setSelectedStatuses({ commit }, value) {
      commit('setSelectedStatuses', value);
    },
    setSelectedATCs({ commit }, value) {
      commit('setSelectedAtcs', value);
    },
    setSelectedSampleTypes({ commit }, value) {
      commit('setSelectedSampleTypes', value);
    },
    setReceptionFromDate({ commit }, value) {
      commit('setReceptionFromDate', value);
    },
    setReceptionToDate({ commit }, value) {
      commit('setReceptionToDate', value);
    },
    setEndFromDate({ commit }, value) {
      commit('setEndFromDate', value);
    },
    setEndToDate({ commit }, value) {
      commit('setEndToDate', value);
    },
    setSelectedAnalyseTypes({ commit }, value) {
      commit('setSelectedAnalyseTypes', value);
    },
    clearFilters({ commit }) {
      commit('clearFilters');
    },
  },
  mutations: {
    setReceptionFromDate(state, value) {
      state.receptionFromDate = value;
    },
    setReceptionToDate(state, value) {
      state.receptionToDate = value;
    },
    setEndFromDate(state, value) {
      state.endFromDate = value;
    },
    setEndToDate(state, value) {
      state.endToDate = value;
    },
    setSelectedSampleTypes(state, value) {
      state.selectedSampleTypes = value;
    },
    setSelectedStatuses(state, value) {
      state.selectedStatuses = value;
    },
    setSelectedAtcs(state, value) {
      state.selectedATCs = value;
    },
    setSelectedAnalyseTypes(state, value) {
      state.selectedAnalyseTypes = value;
    },
    clearFilters(state) {
      state.receptionFromDate = null;
      state.receptionToDate = null;
      state.endFromDate = null;
      state.endToDate = null;
      state.selectedSampleTypes = [];
      state.selectedATCs = [];
      state.selectedStatuses = [];
      state.selectedAnalyseTypes = [];
    },
  },
};
