<template>
  <f7-page>
    <div v-if="loaded" class="tableContainer">
      <MiningSamplesTable :store="customStore" />
    </div>
  </f7-page>
</template>
<script>
import MiningSamplesTable from '@/components/Mining/SamplesTable/index.vue';
import { mapActions } from 'vuex';
import EventBus from '@/services/event-bus';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  name: 'MiningSamplesPage',
  components: {
    MiningSamplesTable,
  },
  data() {
    return {
      loaded: false,
      customStore: null,
    };
  },
  beforeMount() {
    this.setCurrentRoute([
      {
        name: this.$t('Mining'),
        disabled: false,
        page: 'MiningSamplesPage',
        active: false,
      },
      {
        name: this.$t('agronomySample.exportExcelSampleTable'),
        disabled: true,
        page: 'MiningSamplesPage',
        active: true,
      },
    ]);

    this.setCurrentFilter('mining');
  },
  async mounted() {
    try {
      this.$f7.preloader.show();

      setTimeout(() => {
        this.applyCurrentFilters();
      }, 0);

      EventBus.$on('MiningSamplesPage', async (params) => {
        if (params) {
          this.customStore = await this.getStoreWithFilters(params);
        } else {
          this.customStore = await this.getStore();
        }
      });
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(error.message).error
            )
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    ...mapActions('miningSamples', [
      'getStore',
      'getStoreWithFilters',
    ]),
    ...mapActions('breadcrumb', ['setCurrentRoute']),
    ...mapActions('filter', [
      'setCurrentFilter',
      'applyCurrentFilters',
    ]),
  },
};
</script>
