<template>
  <div class="margin-top">
    <div class="filter-box">
      <div class="label-input">
        {{ $t('agronomySample.summary.sampleType')
        }}<span class="requiered-icon color-orange">*</span>
      </div>

      <div class="marginTop5">
        <DxLookup
          :data-source="sampleTypes"
          :value="selectedSampleTypes"
          display-expr="value"
          :search-enabled="false"
          :hide-on-outside-click="true"
          @value-changed="sampleTypesChanged"
          @opened="openedSampleTypes()"
          @content-ready="contentReady()"
        >
          <DxDropDownOptions
            :hide-on-outside-click="true"
            :show-title="false"
          />
        </DxLookup>
      </div>

      <div class="label-input margin-top-half">
        {{ $t('alert.parameters') }}
        <span class="requiered-icon color-orange">*</span>
      </div>
      <div class="marginTop5">
        <DxSelectBox
          :data-source="parameters"
          :value="selectedParameters"
          display-expr="parameter"
          :search-enabled="true"
          @value-changed="parameterChanged"
          @opened="openedParameters()"
          @content-ready="contentReady()"
        />
      </div>

      <div class="label-input margin-top-half">
        {{ $t('dateRange')
        }}<span class="requiered-icon color-orange">*</span>
      </div>
      <div class="recepDate marginTop5">
        <f7-input
          class="shadow"
          type="datepicker"
          :placeholder="$t('date')"
          :clear-button="true"
          :close-on-select="true"
          :value="calculatedDate"
          :calendar-params="{
            closeOnSelect: true,
            rangePicker: true,
            dateFormat: {
              month: 'numeric',
              day: 'numeric',
              year: 'numeric',
            },
          }"
          @calendar:change="setDates"
        />
      </div>
    </div>

    <div class="label-input margin-top-half">
      {{ $t('Gis.Zone') }}
    </div>
    <div class="marginTop5">
      <DxSelectBox
        :data-source="zones"
        :value="selectedZone"
        display-expr="value"
        :search-enabled="true"
        @value-changed="zonesChanged"
        @opened="openedZones()"
        @content-ready="contentReady()"
      />
    </div>

    <div class="label-input margin-top-half">
      {{ $t('environmentSamples.samplingPoint') }}
    </div>
    <div class="marginTop5">
      <DxSelectBox
        :data-source="samplingPoints"
        :value="selectedSamplingPoint"
        display-expr="value"
        :search-enabled="true"
        @value-changed="samplingPointChanged"
        @opened="openedSamplingPoint()"
        @content-ready="contentReady()"
      />
    </div>

    <div class="margin-top-half">
      <div class="content-toogle">
        <div class="title-label">
          <strong class="label-input no-margin-left">
            {{ $t('AggregationByMonth') }}
          </strong>

          <span
            id="global-agq-info"
            class="analysis-risk-info"
            @mouseenter="toggleWithAnimationOptionsGlobalAGQ"
            @mouseleave="toggleWithAnimationOptionsGlobalAGQ"
          >
            <i class="f7-icons"> info_circle_fill </i>
          </span>
        </div>
        <div>
          <DxPopover
            :width="300"
            :visible="withAnimationOptionsVisibleGlobalAGQ"
            :animation="animationConfig"
            target="#global-agq-info"
            position="top"
          >
            <div class="padding-horizontal-half">
              <p>
                {{ $t('environmentStatsMessage') }}
              </p>
            </div>
          </DxPopover>
        </div>
        <DxSwitch
          :on-value-changed="groupByMonthChanged"
          :value="groupByMonth"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { DxTagBox } from 'devextreme-vue/tag-box';
import { mapActions, mapGetters, mapState } from 'vuex';
import DxSelectBox from 'devextreme-vue/select-box';
import { DxSwitch } from 'devextreme-vue/switch';
import { DxPopover } from 'devextreme-vue/popover';
import DataSource from 'devextreme/data/data_source';
import { DxLookup, DxDropDownOptions } from 'devextreme-vue/lookup';

export default {
  name: 'EnvironmentFilters',
  components: {
    DxTagBox,
    DxSelectBox,
    DxSwitch,
    DxPopover,
    DxLookup,
    DxDropDownOptions,
  },
  data() {
    return {
      sampleTypes: [],
      parameters: [],
      zones: [],
      samplingPoints: [],
      withAnimationOptionsVisible: false,
      withAnimationOptionsVisibleGlobalAGQ: false,
      animationConfig: {
        show: {
          type: 'pop',
          from: {
            scale: 0,
          },
          to: {
            scale: 1,
          },
        },
        hide: {
          type: 'fade',
          from: 1,
          to: 0,
        },
      },
      loaded: false,
    };
  },
  computed: {
    disabledOptionalParameters() {
      return (
        this.selectedSampleTypes?.length === 0 ||
        this.selectedLMRs?.length === 0 ||
        this.calculatedDate?.length === 0
      );
    },
    loggedUserIsContact() {
      return this.enabled && this.isAdmin;
    },
    calculatedDate() {
      if (this.toDate !== null && this.fromDate !== null) {
        return [this.fromDate, this.toDate];
      }
      return [];
    },
    disableOnSelectedSampleTypes() {
      return (
        this.selectedSampleTypes.length >= 0 &&
        this.selectedSampleTypes.length < 2
      );
    },
    ...mapGetters('user', ['isAdmin']),
    ...mapState('contact', ['enabled', 'company']),
    ...mapState('environmentStats', [
      'selectedSampleTypes',
      'selectedLMRs',
      'selectedParameters',
      'selectedZone',
      'selectedSamplingPoint',
      'toDate',
      'fromDate',
      'isParamsSelected',
      'isCompanyAggregated',
      'selectedAnalyseTypes',
      'selectedThirdClients',
      'selectedSamplers',
      'availableLmrs',
      'isComparative',
      'isGlobalAGQ',
      'groupByMonth',
    ]),
  },
  methods: {
    async openedSampleTypes(e) {
      const sampleTypes = await this.getSampleFilterGeotoolbox({
        atc: 'Medio Ambiente',
        attribute: 'sample_type',
        // sampleTypeForGeometry: null,
      });

      this.sampleTypes = new DataSource({
        store: sampleTypes,
        key: 'id',
        paginate: true,
        pageSize: 10,
      });
      // if (!Array.isArray(this.sampleTypes)) return;

      // const families = await this.getSampleFilterGeotoolbox({
      //   // atc: 'Medio Ambiente',
      //   attribute: 'sample_type_family',
      //   sampleTypeForGeometry: null,
      // });

      // const allSampleTypes = [];

      // for (const family of families) {
      //   const sampleTypes = await this.getSampleFilterGeotoolbox({
      //     atc: 'Medio Ambiente',
      //     attribute: 'sample_type_family',
      //     sampleTypeForGeometry: family.id,
      //   });

      //   sampleTypes.forEach((sampleType) => {
      //     sampleType.sample_types?.forEach((item) => {
      //       allSampleTypes.push({
      //         ...sampleType,
      //         value: item.value,
      //         id: item.id,
      //       });
      //     });
      //   });
      // }

      // this.sampleTypes = new DataSource({
      //   store: allSampleTypes,
      //   key: 'sample_subfamily_type_id',
      //   group: 'sample_subfamily_type',
      //   paginate: true,
      //   pageSize: 10,
      // });

      this.loaded = true;
    },
    toggleWithAnimationOptions() {
      this.withAnimationOptionsVisible =
        !this.withAnimationOptionsVisible;
    },

    toggleWithAnimationOptionsGlobalAGQ() {
      this.withAnimationOptionsVisibleGlobalAGQ =
        !this.withAnimationOptionsVisibleGlobalAGQ;
    },
    async openedParameters(e) {
      const elements = await this.getParameters({
        atc: 'Medio Ambiente',
        sampleType: this.selectedSampleTypes.id,
      });

      this.parameters = new DataSource({
        store: elements,
        key: '',
        paginate: true,
        pageSize: 10,
      });

      this.loaded = true;
    },
    async openedZones(e) {
      const zones = await this.getSampleFilterGeotoolbox({
        atc: 'Medio Ambiente',
        attribute: 'farm',
        // sampleId: this.selectedSampleTypes.id,
      });

      this.zones = new DataSource({
        store: zones,
        key: '',
        paginate: true,
        pageSize: 10,
      });

      this.loaded = true;
    },
    async openedSamplingPoint(e) {
      const samplingPoints = await this.getSampleFilterGeotoolbox({
        atc: 'Medio Ambiente',
        attribute: 'parcel',
        // sampleId: this.selectedSampleTypes.id,
      });

      this.samplingPoints = new DataSource({
        store: samplingPoints,
        key: '',
        paginate: true,
        pageSize: 10,
      });

      this.loaded = true;
    },
    parameterChanged(e) {
      this.setSelectedParameters(e.value);
    },
    groupByMonthChanged(e) {
      this.setGroupByMonth(e.value);
    },
    contentReady(e) {
      const arrayNotFoundItems = Array.from(
        document.getElementsByClassName('dx-empty-message')
      );

      arrayNotFoundItems.forEach((element) => {
        if (!this.loaded) {
          element.innerHTML = this.$t('loading');
        } else {
          element.innerHTML = this.$t('dataNotAvailable');
        }
      });

      this.loaded = false;
    },
    setDates(e) {
      if (e.length === 2) {
        this.setFromDate(e[0]);
        this.setToDate(e[1]);
      } else if (e.length === 0) {
        this.setFromDate(null);
        this.setToDate(null);
      }
    },
    sampleTypesChanged(e) {
      this.setSelectedSampleTypes(e.value);
    },
    zonesChanged(e) {
      this.setSelectedZone(e.value);
    },
    samplingPointChanged(e) {
      this.setSelectedSamplingPoint(e.value);
    },
    ...mapActions('riskAnalysis', ['getDestinationCountries']),
    ...mapActions('environmentStats', [
      'setSelectedSampleTypes',
      'setToDate',
      'setFromDate',
      'setSelectedLmrs',
      'setSelectedParameters',
      'setIsParamsSelected',
      'setIsCompanyAggregated',
      'setSelectedAnalyseTypes',
      'setSelectedThirdClients',
      'setSelectedSamplers',
      'setIsComparative',
      'setIsGlobalAGQ',
      'setAvailableLmrs',
      'setSelectedZone',
      'setSelectedSamplingPoint',
      'setGroupByMonth',
    ]),
    ...mapActions('alertsParams', ['getEntity', 'getParameters']),
    ...mapActions('samples', [
      'getSampleFilter',
      'getSampleFilterGeotoolbox',
    ]),
  },
};
</script>
<style>
.content-toogle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-toogle .title-label {
  display: flex;
  align-items: center;
}
</style>
