var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-notification-popup"},[(!_vm.loaded)?_c('div',[_c('div',{staticClass:"padding"},[_c('f7-preloader',{staticClass:"margin-right",attrs:{"size":24}}),_vm._v("\n      "+_vm._s(_vm.$t('loading'))+"\n    ")],1)]):_c('div',[_c('f7-block-title',{staticClass:"no-margin card-header"},[_vm._v("\n      "+_vm._s(_vm.$t('recentNotifications'))+"\n    ")]),_vm._v(" "),_c('DxAccordion',{attrs:{"id":"notificationAccordion","data-source":_vm.getReportsFormatted,"collapsible":true,"multiple":false,"hover-state-enabled":false,"focus-state-enabled":false,"animation-duration":300,"width":"280","selected-index":-1,"rtl-enabled":true},on:{"selectionChanged":_vm.selectionChanged},scopedSlots:_vm._u([(_vm.loaded)?{key:"title",fn:function({ data }){return [_c('div',[_c('div',{class:!data.been_seen
                ? 'item-title-notification'
                : 'item-title-notification'},[_vm._v("\n            "+_vm._s(data.sample_code)+"\n            "),_c('a',{staticClass:"dx-link dx-icon-find dx-link-icon icon-notification-search",attrs:{"href":"#"},on:{"click":(e) => _vm.goToDetails(e, data)}})]),_vm._v(" "),_c('span',{class:!data.been_seen ? 'time-item' : 'time-item'},[_vm._v("\n            "+_vm._s(_vm.fromNow(
                new Date(
                  data.created_at.split(' ')[0].split('-')[2],
                  Number(
                    data.created_at.split(' ')[0].split('-')[1]
                  ) - 1,
                  data.created_at.split(' ')[0].split('-')[0],
                  data.created_at.split(' ')[1].split(':')[0],
                  data.created_at.split(' ')[1].split(':')[1],
                  data.created_at.split(' ')[1].split(':')[2]
                ),
                new Date(),
                {
                  addSuffix: true,
                  includeSeconds: true,
                  locale: _vm.locales[_vm.curLocale],
                }
              ).capitalize())+"\n          ")])])]}}:null,(_vm.loaded)?{key:"item",fn:function({ data }){return [_c('div',[_c('div',{staticClass:"list media-list no-ios-edges no-margin"},[_c('ul',[_c('li',{staticClass:"item-content"},[_c('div',{staticClass:"item-inner"},[_c('div',{staticClass:"item-subtitle small"},[_vm._v("\n                    "+_vm._s(_vm.$t('atc'))+"\n                  ")]),_vm._v(" "),_c('div',{staticClass:"item-title-row"},[_c('div',{staticClass:"item-title"},[_vm._v(_vm._s(data.atc))])])])]),_vm._v(" "),_c('li',{staticClass:"item-content"},[_c('div',{staticClass:"item-inner"},[_c('div',{staticClass:"item-subtitle small"},[_vm._v("\n                    "+_vm._s(_vm.$t('company'))+"\n                  ")]),_vm._v(" "),_c('div',{staticClass:"item-title-row"},[_c('div',{staticClass:"item-title"},[_vm._v("\n                      "+_vm._s(data.company_name)+"\n                    ")])])])]),_vm._v(" "),_c('li',{staticClass:"item-content"},[_c('div',{staticClass:"item-inner"},[_c('div',{staticClass:"item-subtitle small"},[_vm._v("\n                    "+_vm._s(_vm.$t('address'))+"\n                  ")]),_vm._v(" "),_c('div',{staticClass:"item-title-row"},[_c('div',{staticClass:"item-title"},[_vm._v("\n                      "+_vm._s(data.address_name)+"\n                    ")])])])]),_vm._v(" "),(data.alert_report_type.includes('Parameter'))?_c('li',{staticClass:"item-content"},[_c('div',{staticClass:"item-inner"},[_c('div',{staticClass:"item-subtitle small"},[_vm._v("\n                    "+_vm._s(_vm.$t('alert.parameters'))+"\n                  ")]),_vm._v(" "),_c('div',{staticClass:"item-title-row"},[_c('div',{staticClass:"item-title"},[_vm._v("\n                      "+_vm._s(data.array_values.toString())+"\n                    ")])])])]):(
                  data.alert_report_type.includes(
                    'ReceptionAlertReport'
                  )
                )?_c('li',{staticClass:"item-content"},[_c('div',{staticClass:"item-inner"},[_c('div',{staticClass:"item-subtitle small"},[_vm._v("\n                    "+_vm._s(_vm.$t('agronomySample.summary.sampleType'))+"\n                  ")]),_vm._v(" "),_c('div',{staticClass:"item-title-row"},[_c('div',{staticClass:"item-title"},[_vm._v("\n                      "+_vm._s(data.sample_type)+"\n                    ")])])])]):_c('li',{staticClass:"item-content"},[_c('div',{staticClass:"item-inner"},[_c('div',{staticClass:"item-subtitle small"},[_vm._v("\n                    "+_vm._s(_vm.$t('sample'))+"\n                  ")]),_vm._v(" "),_c('div',{staticClass:"item-title-row"},[_c('div',{staticClass:"item-title"},[_vm._v("\n                      "+_vm._s(data.n_values)+"\n                    ")]),_vm._v(" "),_c('div',{staticClass:"item-after"},[_vm._v("\n                      "+_vm._s(data.sample_alert_type === 'CMA'
                          ? _vm.$t('alert.cmaPassed')
                          : _vm.$t('alert.lmrPassed'))+"\n                    ")])])])])])])])]}}:null],null,true)}),_vm._v(" "),(_vm.reports.length > 0)?_c('div',[_c('DxButton',{attrs:{"type":"normal","styling-mode":"outlined"},on:{"click":_vm.goToNotifications}},[_vm._v("\n        "+_vm._s(_vm.$t('alert.viewMore'))+"\n      ")])],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }