<template>
  <div class="margin-top">
    <div class="label-input">{{ $t('alert.creationDate') }}</div>
    <div class="recepDate marginTop5">
      <f7-input
        class="shadow"
        type="datepicker"
        :placeholder="$t('alert.creationDate')"
        :clear-button="true"
        :close-on-select="true"
        :value="calculatedCreationDate"
        :calendar-params="{
          closeOnSelect: true,
          rangePicker: true,
          dateFormat: {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
          },
        }"
        @calendar:change="setCreationDate"
      />
    </div>

    <div class="margin-top-half label-input">
      {{ $t('alert.modificationDate') }}
    </div>
    <div class="recepDate marginTop5">
      <f7-input
        class="shadow"
        type="datepicker"
        :placeholder="$t('alert.modificationDate')"
        :clear-button="true"
        :close-on-select="true"
        :value="calculatedUpdateDate"
        :calendar-params="{
          closeOnSelect: true,
          rangePicker: true,
          dateFormat: {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
          },
        }"
        @calendar:change="setUpdateDate"
      />
    </div>

    <div class="label-input margin-top-half">
      {{ $t('alert.area') }}
    </div>
    <div class="marginTop5">
      <DxTagBox
        :data-source="atcs"
        :multiline="true"
        :placeholder="$t('alert.area')"
        tag-template="tag"
        :value="selectedAtcs"
        display-expr="value"
        value-expr="id"
        :on-value-changed="atcsChanged"
        :on-opened="openedAtcs"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>

    <div class="margin-top-half label-input">
      {{ $t('agronomySample.summary.sampleType') }}
    </div>
    <div class="marginTop5">
      <DxTagBox
        :data-source="sampleTypes"
        :multiline="true"
        :placeholder="$t('agronomySample.summary.sampleType')"
        tag-template="tag"
        :value="selectedSampleTypes"
        :on-value-changed="sampleTypesChanged"
        :on-opened="openedSampleTypes"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>

    <div class="margin-top-half label-input">
      {{ $t('foodSamples.samplingSite') }}
    </div>
    <div class="marginTop5">
      <DxTagBox
        :data-source="farms"
        :multiline="true"
        :placeholder="$t('foodSamples.samplingSite')"
        tag-template="tag"
        :value="selectedFarms"
        :on-value-changed="farmsChanged"
        :on-opened="openedFarms"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>

    <div class="margin-top-half label-input">
      {{ $t('environmentSamples.samplingPoint') }}
    </div>
    <div class="marginTop5">
      <DxTagBox
        :data-source="parcels"
        :multiline="true"
        :placeholder="$t('environmentSamples.samplingPoint')"
        tag-template="tag"
        :value="selectedParcels"
        :on-value-changed="parcelsChanged"
        :on-opened="openedParcels"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>

    <div class="margin-top-half label-input">
      {{ $t('agronomySample.summary.description') }}
    </div>
    <div class="marginTop5">
      <DxTagBox
        :data-source="valueReport"
        :multiline="true"
        :placeholder="$t('agronomySample.summary.description')"
        tag-template="tag"
        :value="selectedValueReports"
        :on-value-changed="valueReportChanged"
        :on-opened="openedValueReports"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>

    <div class="margin-top-half label-input">
      {{ $t('status') }}
    </div>
    <div class="marginTop5">
      <DxSelectBox
        :items="statuses"
        :placeholder="$t('status')"
        :value="selectedStatuses"
        :on-value-changed="statusesChanged"
        :on-content-ready="contentReady"
        :show-clear-button="true"
      >
      </DxSelectBox>
    </div>
  </div>
</template>

<script>
import { DxTagBox } from 'devextreme-vue/tag-box';
import { mapActions, mapState } from 'vuex';
import DxSelectBox from 'devextreme-vue/select-box';

export default {
  name: 'AlertsSettingsParamsFilter',
  components: {
    DxTagBox,
    DxSelectBox,
  },
  data() {
    return {
      loaded: false,
      atcs: [],
      sampleTypes: [],
      parameters: [],
      farms: [],
      parcels: [],
      statuses: ['Activas', 'Inactivas'],
      valueReport: [],
    };
  },
  computed: {
    calculatedCreationDate() {
      if (
        this.creationToDate !== null &&
        this.creationFromDate !== null
      ) {
        return [this.creationFromDate, this.creationToDate];
      }
      return [];
    },
    calculatedUpdateDate() {
      if (
        this.updateToDate !== null &&
        this.updateFromDate !== null
      ) {
        return [this.updateFromDate, this.updateToDate];
      }
      return [];
    },
    ...mapState('alertSettingsSampleFilter', [
      'creationToDate',
      'creationFromDate',
      'updateToDate',
      'updateFromDate',
      'selectedAtcs',
      'selectedSampleTypes',
      'selectedParameters',
      'selectedFarms',
      'selectedParcels',
      'selectedStatuses',
      'selectedValueReports',
    ]),
  },
  methods: {
    setCreationDate(e) {
      if (e.length === 2) {
        this.setCreationFromDate(e[0]);
        this.setCreationToDate(e[1]);
      } else if (e.length === 0) {
        this.setCreationFromDate(null);
        this.setCreationToDate(null);
      }
    },
    setUpdateDate(e) {
      if (e.length === 2) {
        this.setUpdateFromDate(e[0]);
        this.setUpdateToDate(e[1]);
      } else if (e.length === 0) {
        this.setUpdateFromDate(null);
        this.setUpdateToDate(null);
      }
    },
    atcsChanged(e) {
      this.setSelectedAtcs(e.value);
    },
    sampleTypesChanged(e) {
      this.setSelectedSampleTypes(e.value);
    },
    parametersChanged(e) {
      this.setSelectedParameters(e.value);
    },
    farmsChanged(e) {
      this.setSelectedFarms(e.value);
    },
    parcelsChanged(e) {
      this.setSelectedParcels(e.value);
    },
    statusesChanged(e) {
      this.setSelectedStatuses(e.value);
    },
    valueReportChanged(e) {
      this.setSelectedValueReports(e.value);
    },
    async openedAtcs(e) {
      const atcs = await this.getSampleAttributeList('atc');

      this.atcs = atcs.sort();
      this.loaded = true;
    },
    async openedSampleTypes(e) {
      const sampleTypes = await this.getSampleAttributeList(
        'sample_type'
      );

      this.sampleTypes = sampleTypes.sort();
      this.loaded = true;
    },
    async openedParameters(e) {
      const parameters = await this.getSampleAttributeList(
        'parameters'
      );

      this.parameters = parameters.sort();
      this.loaded = true;
    },
    async openedFarms(e) {
      const farms = await this.getSampleAttributeList('farm');

      this.farms = farms.sort();
      this.loaded = true;
    },
    async openedParcels(e) {
      const parcels = await this.getSampleAttributeList('parcel');

      this.parcels = parcels.sort();
      this.loaded = true;
    },
    async openedValueReports(e) {
      const valueReport = await this.getSampleAttributeList(
        'value_report'
      );

      this.valueReport = valueReport.sort();
      this.loaded = true;
    },
    contentReady(e) {
      const arrayNotFoundItems = Array.from(
        document.getElementsByClassName('dx-empty-message')
      );

      arrayNotFoundItems.forEach((element) => {
        if (!this.loaded) {
          element.innerHTML = this.$t('loading');
        } else {
          element.innerHTML = this.$t('dataNotAvailable');
        }
      });

      this.loaded = false;
    },
    ...mapActions('alertSettingsSampleFilter', [
      'setCreationToDate',
      'setCreationFromDate',
      'setUpdateToDate',
      'setUpdateFromDate',
      'setSelectedAtcs',
      'setSelectedSampleTypes',
      'setSelectedParameters',
      'setSelectedFarms',
      'setSelectedParcels',
      'setSelectedStatuses',
      'setSelectedValueReports',
    ]),
    ...mapActions('alertsSample', ['getSampleAttributeList']),
  },
};
</script>
