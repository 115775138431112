<template>
  <div>
    <f7-block class="content__login">
      <div class="login__logo text-align-center">
        <img
          class="login__logo__img img_logo"
          :src="APP_LOGO"
          :alt="APP_NAME"
        />
      </div>

      <f7-list form no-hairlines-between class="no-margin-bottom">
        <f7-list-item class="login__label label-text">
          {{ $t('access') }}
        </f7-list-item>

        <f7-list-input
          class="input__text input__text--login"
          outline
          item-label
          type="text"
          :placeholder="$t('email')"
          clear-button
          max="255"
          required
          validate
          :error-message="$t('completeThisField')"
          @input="email = $event.target.value"
        />
        <f7-list-input
          class="input__password input__password--login"
          outline
          item-label
          type="password"
          :placeholder="$t('password')"
          clear-button
          max="255"
          required
          autocomplete="current-password"
          validate
          :error-message="$t('completeThisField')"
          @input="password = $event.target.value"
        />
      </f7-list>

      <f7-list class="no-margin-top">
        <f7-list-item
          v-if="hasRememberMe"
          class="login__remember margin-bottom-half"
          checkbox
          name="checkRememberMe"
          :title="$t('remember_me')"
          :checked="wantRememberPassword"
          @change="TOGGLE_WANT_REMEMBER_PASSWORD"
        />

        <f7-button
          name="btnSignIn"
          class="margin-horizontal"
          large
          fill
          :disabled="isSignInDisabled"
          @click="signIn"
        >
          <span class="text-color-white"> {{ $t('sign_in') }}</span>
        </f7-button>
      </f7-list>
      <f7-row>
        <f7-col>
          <div
            v-if="hasRestorePass"
            class="login__recovery text-align-center"
          >
            <f7-link
              class="login__recovery__link"
              href="#"
              @click="addNewUser"
            >
              {{ $t('addNewUser') }}
            </f7-link>
          </div>
        </f7-col>
        <f7-col>
          <div
            v-if="hasRestorePass"
            class="login__recovery text-align-center"
          >
            <f7-link
              class="login__recovery__link"
              href="#"
              @click="initUserRecoveryPassword"
            >
              {{ $t('forgot_password') }}
            </f7-link>
          </div>
        </f7-col>
      </f7-row>

      <div
        v-if="hasNeedHelp"
        class="login__help text-align-center margin-top"
        name="needHelp"
      >
        <div class="padding-half text-color-gray">
          {{ $t('need_help') }}
        </div>
        <f7-row>
          <f7-col>
            <f7-link
              class="login__recovery__link"
              href="#"
              @click="onOpenDocumentationHandler"
            >
              <i class="f7-icons"> globe </i>
              {{ $t('documentation') }}
            </f7-link>
          </f7-col>
          <f7-col>
            <div class="login__recovery__link">
              <a
                style="color: var(--f7-theme-color-tint)"
                @click="onOpenSupportHandler"
              >
                <i class="f7-icons"> envelope </i>
                {{ $t('supportCenter') }}
              </a>
            </div>
          </f7-col>
        </f7-row>

        <DxPopup
          :visible="openedPopup"
          :drag-enabled="false"
          :close-on-outside-click="true"
          :show-title="false"
          height="auto"
          :width="850"
          :show-close-button="true"
          @hiding="close()"
        >
          <f7-block class="padding margin-half">
            <iframe
              v-if="chosenLanguage === 'es'"
              width="800"
              height="400"
              src="https://www.youtube.com/embed/zVQmA4l4FT8"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>

            <iframe
              v-if="chosenLanguage === 'en'"
              width="800"
              height="400"
              src="https://www.youtube.com/embed/eqARzGssciE"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>

            <iframe
              v-if="chosenLanguage === 'fr'"
              width="800"
              height="400"
              src="https://www.youtube.com/embed/i_DdS4Rg_7k"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>

            <iframe
              v-if="chosenLanguage === 'pt'"
              width="800"
              height="400"
              src="https://www.youtube.com/embed/NCdfpu1OlUc"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>

            <iframe
              v-if="chosenLanguage === 'it'"
              width="800"
              height="400"
              src="https://www.youtube.com/embed/ryTkmqeuA6g"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </f7-block>
        </DxPopup>
      </div>
    </f7-block>

    <footer style="position: fixed; bottom: 0px; width: 100%">
      <f7-row>
        <f7-col></f7-col>
        <f7-col style="display: flex; justify-content: flex-end">
          <f7-link
            class="login__recovery__link"
            href="#"
            @click="onOpenPrevBesafer"
          >
            {{ $t('prevBesafer') }}
          </f7-link>
        </f7-col>
      </f7-row>
    </footer>
  </div>
</template>

<script>
import {
  APP_LOGO,
  APP_NAME,
  ALL_LANGUAGES,
  NOTIFY_TIME_LONG,
} from '@/js/constants';
import notify from 'devextreme/ui/notify';
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import { defaultLocale } from '@/i18n';
import esMessages from '@/json/langs/es.json';
import itMessages from '@/json/langs/it.json';
import ptMessages from '@/json/langs/pt.json';
import enMessages from '@/json/langs/en.json';
import frMessages from '@/json/langs/fr.json';

import { locale, loadMessages } from 'devextreme/localization';
import { DxPopup } from 'devextreme-vue/popup';

export default {
  name: 'Login',
  components: {
    DxPopup,
  },
  props: {
    hasRestorePass: {
      type: Boolean,
      default: false,
    },
    hasSignUp: { type: Boolean, default: false },
    hasRememberMe: {
      type: Boolean,
      default: false,
    },
    hasNeedHelp: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: '',
      password: '',
      APP_LOGO,
      APP_NAME,
      failedLoginCounter: 0,
      langsDx: {
        esMessages,
        frMessages,
        ptMessages,
        enMessages,
        itMessages,
      },
      openedPopup: false,
      chosenLanguage: '',
    };
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
    ...mapState('user', [
      'isUserLoggedIn',
      'wantRememberPassword',
      'authenticatedUser',
    ]),
    ...mapState('contact', ['enabled']),
    isSignInDisabled() {
      return (
        !this.username && !this.wantRememberPassword && !this.password
      );
    },
  },
  mounted() {
    const navigatorLanguage = navigator.language
      .substring(0, 2)
      .toLowerCase();

    this.chosenLanguage = this.isLanguageValid(navigatorLanguage)
      ? navigatorLanguage
      : defaultLocale;
  },
  methods: {
    onOpenSupportHandler() {
      let mail = '';

      if (this.chosenLanguage === 'es') {
        mail = 'atencioncliente.esp@agqlabs.com';
      } else if (this.chosenLanguage === 'en') {
        mail = 'info@agqlabs.com';
      } else if (this.chosenLanguage === 'it') {
        mail = 'italia@agqlabs.com';
      } else if (this.chosenLanguage === 'pt') {
        mail = 'infoportugal@agqlabs.com';
      }
      window.open(`mailto:${mail}`, '_blank');
    },
    isLanguageValid(language) {
      let valid = false;
      for (const lang in ALL_LANGUAGES) {
        if (ALL_LANGUAGES[lang].value === language) {
          valid = true;
        }
      }

      return valid;
    },
    ...mapActions('user', [
      'authenticate',
      'toggleWantRememberPassword',
    ]),
    ...mapMutations('user', [
      'TOGGLE_WANT_REMEMBER_PASSWORD',
      'SET_USER_RECOVERING_PASSWORD',
    ]),
    translateLanguage(value) {
      let key = '';
      for (const lang in ALL_LANGUAGES) {
        if (ALL_LANGUAGES[lang].key === value) {
          key = ALL_LANGUAGES[lang].value;
        }
      }

      return key;
    },
    getLangDevx(lang) {
      if (lang === 'it') {
        return itMessages;
      }
      if (lang === 'fr') {
        return frMessages;
      }

      if (lang === 'es') {
        return esMessages;
      }

      if (lang === 'pt') {
        return ptMessages;
      }

      if (lang === 'en') {
        return enMessages;
      }
    },
    onOpenDocumentationHandler() {
      this.openedPopup = true;
    },
    close() {
      this.openedPopup = false;
    },
    onOpenPrevBesafer() {
      window.open('https://prev.besafer.info/', '_blank');
    },
    async signIn() {
      try {
        this.$f7.preloader.show();

        await this.authenticate({
          email: this.email,
          password: this.password,
          isEmail: this.isEmail(this.email),
        });

        if (this.enabled) {
          const lang = this.userProfile.language
            ? this.translateLanguage(this.userProfile.language)
            : navigator.language.substring(0, 2).toLowerCase();

          locale(`${lang}-${lang.toUpperCase()}`);
          loadMessages(this.langsDx[`${lang}Messages`]);

          setTimeout(() => {
            this.$i18n.i18next.changeLanguage(lang);
            this.updateLanguageInterface(lang);
          }, 0);
        } else {
          const lang = this.authenticatedUser.language_code
            ? this.translateLanguage(
                this.authenticatedUser.language_code
              )
            : navigator.language.substring(0, 2).toLowerCase();

          locale(`${lang}-${lang.toUpperCase()}`);
          loadMessages(this.langsDx[`${lang}Messages`]);

          setTimeout(() => {
            this.$i18n.i18next.changeLanguage(lang);
            this.updateLanguageInterface(lang);
          }, 0);
        }
      } catch (error) {
        if (error.message === 'contacts_not_found') {
          notify(
            {
              message: this.$t('empty_contacts'),
              width: 500,
            },
            'error',
            NOTIFY_TIME_LONG
          );
        } else if (error.message === 'empty_headquarters') {
          notify(
            {
              message: this.$t('empty_headquarters'),
              width: 500,
            },
            'error',
            NOTIFY_TIME_LONG
          );
        } else {
          notify(
            {
              message: this.$t(error.message),
              width: 500,
            },
            'error',
            NOTIFY_TIME_LONG
          );
        }
      } finally {
        this.$f7.preloader.hide();
      }
    },
    isEmail(email) {
      return email.includes('@');
    },
    initUserRecoveryPassword() {
      this.setIsCreatingNewUser(false);
      this.SET_USER_RECOVERING_PASSWORD();
    },
    addNewUser() {
      this.setIsCreatingNewUser(true);
      this.SET_USER_RECOVERING_PASSWORD();
    },
    ...mapActions('user', [
      'updateLanguageInterface',
      'setIsCreatingNewUser',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.login__label {
  font-size: 24px;
  font-weight: 200;
}
.login__logo__img {
  width: 200px;
  height: auto;
}
.login__recovery__link {
  color: var(--f7-theme-color);
  background: var(--his-mora-tint);
  border: 1px solid var(--his-mora-tint);
  padding: 4px 12px;
  display: block;
  margin: 0 15px;
  border-radius: 4px;
}
.login__recovery__link:hover {
  background: var(--his-mora-tint);
  border-color: var(--his-mora-light);
}
</style>
