import Client from './Clients/Axios/AxiosClient';
import { getAuthorizedHeaderWithCompanyCode } from './utils';

const resource = '/company';

export default {
  // Can get parameters limit & offset in case
  // of async pagination needed (Not required)
  async getUsers(accessToken, userCompanyCode, addressCode = null) {
    try {
      const users = await Client.get(
        `${resource}/user?no_cache=${Date.now()}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            company: userCompanyCode,
            address: addressCode,
          },
        }
      );
      return Promise.resolve(users);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async addUserToCompany(accessToken, userCompanyCode, newUser) {
    const data = {
      user_email: newUser.email,
      role: newUser.role,
      permissions: newUser.permissions,
      address_code: newUser.addresses,
      name: newUser.name,
      surname: newUser.surname,
      phone: newUser.phone,
    };
    try {
      await Client.post(
        `${resource}/user?no_cache=${Date.now()}`,
        data,
        getAuthorizedHeaderWithCompanyCode(
          accessToken,
          userCompanyCode
        )
      );
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async updateUsersPermissions(
    accessToken,
    userCompanyCode,
    userID,
    newRole,
    newPermissions
  ) {
    const data = {
      role: newRole,
      permissions: newPermissions,
    };

    try {
      await Client.put(
        `${resource}/user/${userID}/permission?no_cache=${Date.now()}`,
        data,
        getAuthorizedHeaderWithCompanyCode(
          accessToken,
          userCompanyCode
        )
      );
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async updateUsersAddresses(
    accessToken,
    userCompanyCode,
    userID,
    newAddresses
  ) {
    const data = {
      address_code: newAddresses,
    };

    try {
      await Client.put(
        `${resource}/user/${userID}/address?no_cache=${Date.now()}`,
        data,
        getAuthorizedHeaderWithCompanyCode(
          accessToken,
          userCompanyCode
        )
      );
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async deleteUserFromCompany(
    accessToken,
    userCompanyCode,
    userID,
    addressCode
  ) {
    try {
      await Client.delete(
        `${resource}/user/${userID}?no_cache=${Date.now()}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            company: userCompanyCode,
            address: addressCode,
          },
        }
      );
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
