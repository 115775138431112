import Api from '@/services/Api';
import Helpers from '@/js/helpers';

export default {
  namespaced: true,
  state: {
    showUploadFormPopup: false,
    atc: '',
  },

  actions: {
    setUploadFormPopup({ commit }, value) {
      commit('setUploadFormPopup', value);
    },
    async uploadFile({ rootState }, data) {
      try {
        await Api.uploadFile(
          {
            name: data.name,
            file: data.fileB64,
            atc: data.atc,
            extension: data.extension,
            size: data.fileSize,
            description: data.description,
            contractNumber: data.contractNumber,
            emails: data.emails,
          },
          rootState.contact.company,
          data.address
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async editFile({ rootState }, data) {
      try {
        await Api.editFile(
          {
            id: data.id,
            name: data.name,
            description: data.description,
            contractNumber: data.contractNumber,
            emails: data.emails,
          },
          rootState.contact.company,
          data.address
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getTree({ rootState }, atc) {
      try {
        const xhr = await Api.getDocumentationTree(
          {
            atc,
          },
          rootState.contact.company,
          rootState.contact.address
        );

        const res = JSON.parse(xhr.response).data;

        return Promise.resolve(
          Helpers.isEmptyObject(res) ? [] : [res]
        );
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async deleteFile({ rootState }, id) {
      try {
        await Api.deleteFile(
          id,
          rootState.contact.company,
          rootState.contact.address
        );

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    setAtc({ commit }, value) {
      commit('setAtc', value);
    },
  },
  mutations: {
    setUploadFormPopup(state, value) {
      state.showUploadFormPopup = value;
    },
    setAtc(state, value) {
      state.atc = value;
    },
  },
};
