<template>
  <div>
    <div class="tableContainer">
      <div
        class="filterButtonPosition"
        style="display: flex; align-items: center; gap: 0.4rem"
      >
        <f7-button
          fill
          class="whiteFontColor button-filter"
          panel-toggle="right"
        >
          <span class="ic-filter"></span> {{ $t('filter') }}
        </f7-button>
      </div>
      <DxDataGrid
        :data-source="store"
        :allow-column-reordering="false"
        :allow-column-resizing="true"
        :row-alternation-enabled="true"
        :allow-column-auto-width="true"
        :show-colon="false"
        :remote-operations="true"
        :hover-state-enabled="true"
        :show-borders="true"
        :show-row-lines="true"
        :no-data-text="$t('noDataTextSummaryOffers')"
        @toolbar-preparing="onToolbarPreparing"
        @cell-prepared="onCellPrepared"
        @contentReady="onInit"
      >
        <DxStateStoring
          :enabled="true"
          type="custom"
          storage-key="OfferListTableDetailsPntsPts"
          :custom-load="loadState"
          :custom-save="customSave"
        />
        <DxColumn caption="PNT" data-field="PNT" />
        <DxColumn
          alignment="center"
          :caption="this.$t('ProductionCenter')"
          data-field="centroProduccion[0].Nombre"
        />
        <DxColumn
          alignment="center"
          :caption="this.$t('agronomySample.summary.sampleType')"
          data-field="SubfamiliaTM"
        />
        <DxColumn
          alignment="center"
          :caption="this.$t('netAmount')"
          data-field="PrecioBrutoCodDivisa"
        />
        <DxSearchPanel
          :visible="true"
          :highlight-case-sensitive="true"
          :placeholder="$t('search')"
        />
        <DxPager
          :allowed-page-sizes="[10, 25, 50, 100]"
          :show-page-size-selector="true"
          info-text="{2} items"
          :visible="true"
        />
        <DxPaging :page-size="5" />
      </DxDataGrid>
    </div>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxPager,
  DxStateStoring,
  DxPaging,
  DxSearchPanel,
} from 'devextreme-vue/data-grid';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPager,
    DxPaging,
    DxStateStoring,
    DxSearchPanel,
  },
  props: {
    store: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      pageSizes: [5, 10, 15],
    };
  },
  computed: {
    ...mapState('Offers', ['', 'detallesLineas']),
  },
  methods: {
    loadState() {
      let state = localStorage.getItem(
        'OfferListTableDetailsPntsPts'
      );
      if (state) {
        state = JSON.parse(state);
        state.searchText = '';
      }
      return state;
    },
    customSave(state) {
      localStorage.setItem(
        'OfferListTableDetailsPntsPts',
        JSON.stringify(state)
      );
    },
    onInit(e) {
      this.grid = e;
      Array.from(
        document.getElementsByClassName('dx-group-cell')
      ).forEach((el) => {
        el.innerHTML = el.innerHTML.split(':')[1].trim();
      });
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items;
      toolbarItems.forEach((item) => {
        if (item.name === 'searchPanel') {
          item.location = 'before';
        }
      });
    },

    onCellPrepared(e) {
      if (e.rowType === 'data') {
        if (e.data.idPNT !== null && e.data.IdPnt !== null) {
          e.cellElement.classList.add('pnts');
        }

        if (e.data.idPNT === null && e.column.dataField === 'PNT') {
          const parametrotecnica = e.data.ParametroTecnica;
          e.cellElement.innerText = parametrotecnica;
        }
      }
    },
    ...mapActions('Offers', [
      'fetchDetallesLineas',
      'get_empresas_centro_produccion',
    ]),
  },
};
</script>
