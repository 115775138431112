<template>
  <div>
    <DxDataGrid
      :data-source="details.parameters"
      :show-borders="true"
      :allow-column-reordering="false"
      :column-auto-width="true"
      :show-colon="false"
      :remote-operations="true"
      :hover-state-enabled="true"
    >
      <DxScrolling mode="standard" />
      <DxStateStoring :enabled="false" type="localStorage" />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="[5, 10, 20]"
        :show-info="true"
        :visible="false"
      />

      <DxSelection mode="single" />

      <DxSearchPanel
        :visible="false"
        :width="300"
        :placeholder="$t('search')"
      />

      <DxColumn
        data-field="parameter"
        :caption="$t('foodSamples.parameter')"
        alignment="left"
        :allow-header-filtering="false"
      />

      <DxColumn
        data-field=""
        name="result"
        :caption="$t('foodSamples.result')"
        alignment="left"
        :allow-header-filtering="false"
        cell-template="cellTemplateResults"
      />
      <DxColumn
        v-if="details.parameters[0].cma_value !== null"
        data-field=""
        name="notificationRange"
        caption="CMA"
        alignment="left"
        :allow-header-filtering="false"
        cell-template="cellTemplateCma"
      />

      <DxColumn
        v-if="details.parameters[0].cma_value === null"
        data-field=""
        name="notificationRange"
        :caption="$t('alert.lmrPassed')"
        alignment="left"
        :allow-header-filtering="false"
        cell-template="cellTemplateLmr"
      />

      <DxColumn
        data-field=""
        :caption="$t('unfulfilledChains')"
        alignment="left"
        :allow-header-filtering="false"
        cell-template="cellTemplateChains"
      />

      <template #cellTemplateResults="{ data }">
        <div>
          {{ data.data.result.toFixed(2) }} {{ data.data.unit }}
        </div>
      </template>

      <template #cellTemplateChains="{ data }">
        <div>
          <div v-if="data.data.retailers">
            {{ data.data.retailers.toString() }}
          </div>
          <div v-else></div>
        </div>
      </template>

      <template #cellTemplateCma="{ data }">
        <div>{{ data.data.cma_value }} {{ data.data.unit }}</div>
      </template>

      <template #cellTemplateLmr="{ data }">
        <div>
          {{
            getCountryFullName(
              data.data.lmrs
                .flatMap((lmrs) => lmrs.country_code)
                .toString()
            )
          }}
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxPaging,
  DxScrolling,
} from 'devextreme-vue/data-grid';
import countryTranslationServiceEs from '../../../../../../i18n/translated_countries_service_es';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxStateStoring,
    DxPager,
    DxPaging,
    DxScrolling,
    DxSelection,
  },
  props: {
    details: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getCountryFullName(code) {
      return countryTranslationServiceEs.getCountryNameByCode(code);
    },
  },
};
</script>
