<template>
  <DxPopover
    :width="300"
    :visible="visible"
    :shading="shading"
    :target="target"
    position="top"
    @hiding="onHiding"
  >
    <div>
      <div
        style="
          padding: 1rem 0.4rem;
          display: flex;
          justify-content: space-between;
          gap: 3rem;
        "
        :class="`bg-popover-color-${child.alert}`"
      >
        <span style="font-weight: 700">{{ child.item }}</span>
        <span>{{ child.dop }}%</span>
      </div>
      <div class="header-title">
        <p>
          {{ $t('expertSystemTiles.alertDiagnosis') }} ({{
            child.name
          }})
        </p>
      </div>
      <div>
        <p style="padding: 0.4rem">
          {{ child.diagnostic }}
        </p>
      </div>
      <div class="header-title">
        <p>{{ $t('expertSystemTiles.correctiveMeasures') }}</p>
      </div>
      <div>
        <p style="padding: 0.4rem">
          {{ child.amendment }}
        </p>
      </div>
      <!-- <div class="header-title">
        <p>{{ $t('expertSystemTiles.commentary') }}</p>
      </div>
      <div style="padding-top: 10px">
        <DxTextArea
          :height="90"
          :read-only="true"
          :value="commentary"
          @change="onChangeTextAreaValueHandler"
        />

        <f7-row
          style="
            text-align: right;
            padding-top: 10px;
            padding-bottom: 10px;
          "
        >
          <f7-col>
            <DxButton
              type="normal"
              styling-mode="outlined"
              class="standardBtn"
              :text="$t('save')"
              @click="onSubmitCommentaryHandler"
            />
          </f7-col>
        </f7-row>
      </div> -->
    </div>
  </DxPopover>
</template>

<script>
import { DxPopover } from 'devextreme-vue/popover';
import DxTextArea from 'devextreme-vue/text-area';
import DxButton from 'devextreme-vue/button';

export default {
  name: 'ExpertSystemTilePopover',
  components: {
    DxPopover,
    DxTextArea,
    DxButton,
  },
  props: {
    shading: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
      default: '',
    },
    child: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      commentary: '',
    };
  },
  methods: {
    onChangeTextAreaValueHandler(e) {
      this.commentary = e.event.target.value;
    },
    onSubmitCommentaryHandler(e) {
      if (this.commentary) {
        // console.log(this.child);
      }
    },
    onHiding() {
      this.$emit('closeChild');
    },
  },
};
</script>

<style scoped lang="scss">
@import './TilePopover.styles.scss';
</style>
