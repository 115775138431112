var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"margin-top"},[_c('div',{staticClass:"label-input"},[_vm._v(_vm._s(_vm.$t('date')))]),_vm._v(" "),_c('div',{staticClass:"recepDate marginTop5"},[_c('f7-input',{staticClass:"shadow",attrs:{"type":"datepicker","placeholder":_vm.$t('date'),"clear-button":true,"close-on-select":true,"value":_vm.calculatedDates,"calendar-params":{
        closeOnSelect: true,
        rangePicker: true,
        dateFormat: {
          month: 'numeric',
          day: 'numeric',
          year: 'numeric',
        },
      }},on:{"calendar:change":_vm.setDates}})],1),_vm._v(" "),_c('div',{staticClass:"margin-top-half"},[_c('div',{staticClass:"label-input"},[_vm._v(_vm._s(_vm.$t('dataFrequency')))]),_vm._v(" "),_c('DxSelectBox',{attrs:{"items":_vm.dataFrequency,"value":_vm.selectedFrequency,"on-value-changed":_vm.setFrequency,"display-expr":"value","value-expr":"key","on-content-ready":_vm.contentReady,"show-clear-button":true}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }