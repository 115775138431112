<template>
  <f7-page name="agronomySamples">
    <div v-if="loaded">
      <div class="tableContainer">
        <!-- <br /> -->
        <AgronomySamplesTable :store="customStore" />
      </div>
    </div>
  </f7-page>
</template>
<script>
import { mapActions } from 'vuex';
import EventBus from '@/services/event-bus';
import { NOTIFY_TIME_LONG } from '@/js/constants';
import AgronomySamplesTable from '../../components/Agronomy/AgronomySamplesTable/AgronomySamplesTable.vue';

export default {
  components: {
    AgronomySamplesTable,
  },
  data() {
    return {
      loaded: false,
      customStore: null,
    };
  },
  beforeMount() {
    this.setCurrentRoute([
      {
        name: this.$t('Agronomy'),
        disabled: false,
        page: 'AgronomySamplesPage',
        active: false,
      },
      {
        name: this.$t('agronomySample.exportExcelSampleTable'),
        disabled: true,
        page: 'AgronomySamplesPage',
        active: true,
      },
    ]);

    this.setCurrentFilter('agronomy');
  },
  async mounted() {
    try {
      this.$f7.preloader.show();

      setTimeout(() => {
        this.applyCurrentFilters();
      }, 0);

      EventBus.$on('AgronomySamplesPage', async (params) => {
        if (params) {
          this.customStore = await this.getStoreWithFilters(params);
        } else {
          this.customStore = await this.getStore();
        }
      });
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(error.message).error
            )
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  beforeDestroy() {
    EventBus.$off('AgronomySamplesPage');
  },
  methods: {
    ...mapActions('agronomySamples', [
      'getStore',
      'getStoreWithFilters',
    ]),
    ...mapActions('samples', ['setAllFilterParams']),
    ...mapActions('filter', [
      'applyCurrentFilters',
      'setCurrentFilter',
    ]),
    ...mapActions('breadcrumb', ['setCurrentRoute']),
  },
};
</script>
