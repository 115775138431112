<template>
  <f7-page>
    <div v-if="loaded" class="tableContainer">
      <RiskAnalysisTable :data="data" />
    </div>
  </f7-page>
</template>

<script>
import RiskAnalysisTable from '@/components/Food/RiskAnalysisTable/index.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import EventBus from '@/services/event-bus';
import { NOTIFY_TIME_LONG } from '@/js/constants';
import countryTranslationServiceEs from '../../../i18n/translated_countries_service_es';

export default {
  name: 'RiskAnalysisPage',
  components: { RiskAnalysisTable },
  data() {
    return {
      loaded: true,
      data: [],
    };
  },
  computed: {
    ...mapState('riskAnalysisFilter', [
      'selectedSampleTypes',
      'selectedDestinationCountries',
      'selectedOriginCountry',
    ]),
    ...mapGetters('user', ['userProfile']),
  },
  mounted() {
    this.clearFilters();

    this.setCurrentRoute([
      {
        name: this.$t('Food'),
        disabled: false,
        page: 'FoodSamplesPage',
        active: false,
      },
      {
        name: this.$t('riskAnalysis'),
        disabled: true,
        page: 'FoodSamplesPage',
        active: true,
      },
    ]);

    EventBus.$on('RiskAnalysisPage', async (params) => {
      if (!params) {
        this.data = [];
        return;
      }
      if (params.error) {
        this.$notifyDX(
          {
            message: this.$t('stats.requiredFilters'),
            width: 450,
          },
          'error',
          NOTIFY_TIME_LONG
        );
        return;
      }

      this.loaded = false;
      this.$f7.preloader.show();

      this.data = [];

      try {
        const res = await this.getDataEntityCountries();
        if (!this.$helpers.isEmptyObject(res)) {
          const dataParams = [];

          const allCountriesDestination = res.lmr_destinations.map(
            (destination) => {
              destination.parameters.sort((a, b) =>
                a.parameter.localeCompare(b.parameter)
              );

              return {
                country: destination.lmr_destiny,
                parameters: destination.parameters,
              };
            }
          );

          res.parameters.sort((a, b) =>
            a.parameter.localeCompare(b.parameter)
          );

          const originCountryParameters = res.parameters;

          originCountryParameters.forEach((param) => {
            dataParams.push({
              [`lmr${res.lmr_origin}`]: param.lmr,
              Parametro: param.parameter,
            });
          });

          allCountriesDestination.forEach((data) => {
            data.parameters.forEach((param, index) => {
              dataParams[index] = {
                ...dataParams[index],
                [`lmr${this.getDestinationCountryTranslated(
                  data.country
                )}`]: param.lmr,
              };
            });
          });

          this.data = dataParams;
          this.$f7.preloader.hide();

          this.loaded = true;
        }
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t('riskAnalysisFilterError'),
            width: 500,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      } finally {
        this.$f7.preloader.hide();
        this.loaded = true;
      }
    });
    document.getElementById('panel-right-risk-btn').click();
  },
  beforeDestroy() {
    EventBus.$off('RiskAnalysisPage');
  },
  methods: {
    getDestinationCountryTranslated(code) {
      return countryTranslationServiceEs.getCountryNameByCode(code);
    },
    ...mapActions('breadcrumb', ['setCurrentRoute']),
    ...mapActions('riskAnalysis', ['getDataEntityCountries']),
    ...mapActions('riskAnalysisFilter', ['clearFilters']),
  },
};
</script>
