import Framework7 from 'framework7';
import store from '@/store';
import { ALL_LANGUAGES, RELEASE_VERSION } from '@/js/constants';
import EventBus from './event-bus';

export default {
  request(
    url,
    payload,
    verb,
    processData = true,
    contentType = 'application/x-www-form-urlencoded',
    headerAuth,
    enabledCache,
    headerCompany = null,
    headerAdressCode = null,
    headerLang = null,
    callback,
    timeout = 180000
  ) {
    Framework7.request.setup({
      headers: {
        Authorization: headerAuth,
        company: headerCompany,
        address: headerAdressCode,
        lang: this.translateLanguage(headerLang),
        'app-version': RELEASE_VERSION,
      },
    });
    Framework7.request({
      url,
      method: verb,
      cache: enabledCache,
      data: payload.data,
      processData,
      contentType,
      timeout,
      complete: callback,
    });
  },

  translateLanguage(value) {
    let key = '';
    for (const lang in ALL_LANGUAGES) {
      if (ALL_LANGUAGES[lang].value === value) {
        key = ALL_LANGUAGES[lang].key;
      }
    }

    return key;
  },

  async(
    url,
    payload,
    verb,
    processData,
    contentType,
    headerAuth = null,
    headerCompany = null,
    headerAdressCode = null,
    enabledCache = false
  ) {
    const self = this;
    return new Promise((resolve, reject) => {
      self.request(
        url,
        payload,
        verb,
        processData,
        contentType,
        headerAuth,
        enabledCache,
        headerCompany,
        headerAdressCode,
        store.getters['user/getLanguage'],
        (xhr, status) => {
          if (status === 406) {
            window.location.reload();
          }
          if (status >= 300 || status === 'timeout') {
            if (status === 401) {
              const code = JSON.parse(xhr?.response)?.code ?? '';

              if (code === 'token_not_valid') {
                EventBus.$emit('refreshToken');
              } else if (
                JSON.parse(xhr?.response).errors[0].code ===
                'authentication_failed'
              ) {
                EventBus.$emit('authenticationFailed');
              } else {
                EventBus.$emit('invalidTokenDetected');
              }
            }
            if (status === 409) {
              resolve(xhr);
            }
            reject(new Error(`${xhr.response}`));
          }
          try {
            resolve(xhr);
          } catch (e) {
            reject(e);
          }
        }
      );
    });
  },

  async blobRequest(url, callback) {
    const logoRes = await fetch(url);
    const blob = await logoRes.blob();
    const reader = new FileReader();
    reader.onloadend = () => callback(reader.result);
    reader.readAsDataURL(blob);
  },
};
