<template>
  <f7-page name="profilePage">
    <Profile
      @languageChanged="newLanguageSelected"
      @avatar-changed="avatarchanged"
    />

    <div
      class="content-setting display-flex justify-content-flex-start no-margin-top"
    >
      <f7-button
        class="color-green whiteFontColor"
        style="max-width: 150px"
        fill
        type="default"
        styling-mode="contained"
        name="ButtonSave"
        @click="saveNewPersonalData"
      >
        {{ $t('save_changes') }}
      </f7-button>
      <f7-button
        name="btnGoBack"
        class="color-gray margin-left"
        @click="goToBack"
      >
        {{ $t('cancel') }}
      </f7-button>
    </div>
  </f7-page>
</template>

<script>
import Profile from '@/components/Profile';
import { mapActions, mapState, mapGetters } from 'vuex';
import esMessages from '@/json/langs/es.json';
import frMessages from '@/json/langs/fr.json';
import ptMessages from '@/json/langs/pt.json';
import enMessages from '@/json/langs/en.json';
import itMessages from '@/json/langs/it.json';
import { locale, loadMessages } from 'devextreme/localization';
import L from 'leaflet';
import { ALL_LANGUAGES, NOTIFY_TIME_LONG } from '@/js/constants';
export default {
  components: {
    Profile,
  },
  data() {
    return {
      lastLanguageSelected: '',
      avatarSelected: '',
      languageKey: '',
      loaded: false,
      langsDx: {
        esMessages,
        frMessages,
        ptMessages,
        enMessages,
        itMessages,
      },
    };
  },
  computed: {
    ...mapGetters('user', ['isAdmin']),
    ...mapGetters('user', ['userProfile']),
    ...mapState('user', ['userPasswords']),
  },
  methods: {
    newLanguageSelected(lang) {
      this.languageKey = lang.key;
      this.lastLanguageSelected = lang.value;
    },
    avatarchanged(avatar) {
      this.avatarSelected = avatar;
    },
    async saveNewPersonalData() {
      const { name, surname, phone } = this.userProfile;
      try {
        this.$notifyDX(
          {
            message: this.$t('updating_personal_information'),
            width: 450,
          },
          'info',
          2000
        );

        if (this.avatarSelected) {
          await this.updateAvatar({ newAvatar: this.avatarSelected });
        }

        if (this.lastLanguageSelected?.length === 0) {
          this.lastLanguageSelected =
            this.$root.$i18n.i18next.language;

          const language = ALL_LANGUAGES.find(
            (lang) => lang.value === this.lastLanguageSelected
          );

          this.languageKey = language?.key ?? '';
        }

        await this.updateUserPersonalData({
          name,
          surname,
          phone,
          language: this.languageKey,
        });
        if (this.lastLanguageSelected) {
          this.updateLanguageInterface(this.lastLanguageSelected);
          this.$i18n.i18next.changeLanguage(
            this.lastLanguageSelected
          );

          loadMessages(
            this.langsDx[`${this.lastLanguageSelected}Messages`]
          );
          locale(
            `${
              this.lastLanguageSelected
            }-${this.lastLanguageSelected.toUpperCase()}`
          );
          L.PM.activeLang = this.lastLanguageSelected;
        }

        const { contacts } = await this.getUserData();

        this.updateUserContacts(contacts);

        this.$notifyDX(
          {
            message: this.$t('personal_information_updated'),
            width: 450,
          },
          'success',
          2000
        );
      } catch (error) {
        this.$f7.dialog.close();

        this.$notifyDX(
          {
            message: this.$t(error.errorCodes?.[0] ?? ''),
            width: 450,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      } finally {
        this.$f7.dialog.close();

        if (
          this.userPasswords &&
          this.userPasswords.oldPassword.length > 0
        ) {
          await this.saveNewPassword();
        }
      }
    },
    initUserPasswordsForm() {
      this.setUserOldPassword('');
      this.setUserNewPassword('');
      this.setConfirmNewPassword('');
    },
    async saveNewPassword() {
      try {
        await this.updatePassword({
          newPassword: this.userPasswords.newPassword,
          oldPassword: this.userPasswords.oldPassword,
        });
        this.$f7.dialog.close();

        this.$notifyDX(
          {
            message: this.$t('password_updated'),
            width: 450,
          },
          'success',
          2000
        );
      } catch (error) {
        this.$f7.dialog.close();

        this.$notifyDX(
          {
            message: this.$t(error.errorCodes[0]),
            width: 450,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      } finally {
        // self.$f7.dialog.close();
        this.initUserPasswordsForm();
      }
    },
    goToBack() {
      this.$f7router.navigate(
        { name: this.$f7router.previousRoute.name },
        {
          animate: true,
          transition: 'f7-fade',
        }
      );
    },
    ...mapActions('breadcrumb', ['setCurrentRoute']),
    ...mapActions('company', ['getUsers']),
    ...mapActions('user', [
      'updateLanguageInterface',
      'updateUserPersonalData',
      'updatePassword',
      'setUserNewPassword',
      'setUserOldPassword',
      'setConfirmNewPassword',
      'updateAvatar',
      'getUserData',
      'updateUserContacts',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.content-setting {
  max-width: 1280px;
  margin: 0 auto;
}
</style>