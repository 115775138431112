<template>
  <div class="tableContainer">
    <div class="filterButtonPosition" style="right: 64px">
      <f7-button
        fill
        class="whiteFontColor button-filter"
        panel-toggle="right"
      >
        <span class="ic-filter"></span> {{ $t('filter') }}
      </f7-button>
    </div>
    <DxDataGrid
      :data-source="store"
      :show-borders="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :show-colon="false"
      :hover-state-enabled="true"
      :remote-operations="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :no-data-text="$t('noDataTextDetail')"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing"
      @content-ready="init"
    >
      <DxScrolling mode="standard" />
      <DxSelection mode="single" />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="[5, 10, 20]"
        :show-info="true"
        info-text="{2} items"
        :visible="true"
      />

      <DxSearchPanel
        :visible="true"
        :width="300"
        :placeholder="$t('search')"
      />

      <DxColumn
        data-field="lmr_semaphore"
        header-cell-template="cellTemplateHeaderDots"
        caption=""
        :width="35"
        :allow-sorting="false"
        cell-template="cellTemplateDots"
        alignment="center"
        :fixed="true"
        fixed-position="left"
      />

      <template #cellTemplateDots="{ data }">
        <div class="dots-parent-container">
          <img
            width="20"
            height="20"
            :src="getDotColorImage(data.value)"
          />
        </div>
      </template>

      <DxColumn
        data-field="parameter_group"
        :caption="$t('group')"
        alignment="left"
        :allow-header-filtering="false"
      />

      <DxColumn
        data-field="parameter"
        :caption="$t('foodSamples.parameter')"
        :allow-header-filtering="false"
      />

      <DxColumn
        data-field="value.ResultadoPresentacion"
        :caption="$t('foodSamples.result')"
        cell-template="cellTemplateResultadoPresentacion"
        :allow-header-filtering="false"
      />
      <template #cellTemplateResultadoPresentacion="{ data }">
        <div>
          {{
            data.data.value.ResultadoPresentacion.includes(
              'En Proceso'
            )
              ? $t('inProcess')
              : data.data.value.ResultadoPresentacion
          }}
        </div>
      </template>

      <DxColumn
        data-field="value.unit"
        :caption="$t('foodSamples.unit')"
        :allow-header-filtering="false"
      />

      <DxColumn
        :caption="$t('foodSamples.range')"
        :allow-header-filtering="false"
        alignment="right"
        :calculate-cell-value="calculateRange"
      />

      <DxExport :enabled="true" />
      <template #cellTemplateHeaderDots="{ data }">
        <div>
          <PopUp position="top">
            <div style="padding: 10px">
              <div class="pop-up-info">
                <img
                  :src="getDotColorImage('grey')"
                  width="15"
                  height="15"
                />
                <div style="padding-left: 5px">
                  {{ $t('foodSamples.detailRP.greyDotDescription') }}
                </div>
              </div>
              <div class="pop-up-info">
                <img
                  :src="getDotColorImage('green')"
                  width="15"
                  height="15"
                />
                <div style="padding-left: 5px">
                  {{ $t('foodSamples.detailRP.greenDotDescription') }}
                </div>
              </div>
              <div class="pop-up-info">
                <img
                  :src="getDotColorImage('red')"
                  width="15"
                  height="15"
                />
                <div style="padding-left: 5px">
                  {{ $t('foodSamples.detailRP.redDotDescription') }}
                </div>
              </div>
              <div class="pop-up-info">
                <img
                  :src="getDotColorImage('orange')"
                  width="15"
                  height="15"
                />
                <div style="padding-left: 5px">
                  {{
                    $t('foodSamples.detailRP.orangeDotDescription')
                  }}
                </div>
              </div>
            </div>
          </PopUp>
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPager,
  DxPaging,
  DxScrolling,
  DxExport,
  DxSelection,
  DxSearchPanel,
} from 'devextreme-vue/data-grid';

import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import PopUp from '@/components/UtilityComponents/popUpInfo/popUp.vue';

export default {
  name: 'FoodSamplesDetailTable',
  components: {
    DxDataGrid,
    DxColumn,
    DxPager,
    DxPaging,
    DxScrolling,
    DxExport,
    DxSelection,
    DxSearchPanel,
    PopUp,
  },
  props: {
    store: {
      type: Object,
      default: () => {},
    },
    isAppliedFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      gridInstance: null,
    };
  },
  computed: {
    ...mapState('foodDetailsFilter', ['selectedLMRs']),
    ...mapState('samples', ['reportSampleCode']),
  },
  beforeUpdate() {
    if (!this.gridInstance) return;

    const dataSource = this.gridInstance.getDataSource().items();

    if (this.isAppliedFilter) {
      if (this.selectedLMRs.length > 0) {
        dataSource.forEach((item) => {
          item.lmr.forEach((itemLMR, itemLMRIndex) => {
            this.selectedLMRs.forEach((selectedLMR) => {
              if (selectedLMR === itemLMR.CodPais) {
                if (
                  this.columnExists(
                    this.gridInstance.columnCount(),
                    `LMR ${selectedLMR}`
                  ) === -1
                ) {
                  this.gridInstance.addColumn({
                    caption: `LMR ${selectedLMR}`,
                    dataField: `lmr[${itemLMRIndex}].Valor`,
                    alignment: 'left',
                    allowHeaderFiltering: 'false',
                  });
                }
              }
            });
          });
        });

        this.deleteColumns();
      } else if (
        this.gridInstance
          .columnOption(this.gridInstance.columnCount() - 1)
          .caption.includes('LMR')
      ) {
        this.gridInstance.deleteColumn(
          this.gridInstance.columnCount() - 1
        );
      }
    } else {
      this.deleteColumns();

      for (let i = 0; i < this.gridInstance.columnCount(); i += 1) {
        if (
          this.gridInstance.columnOption(i).caption.includes('LMR')
        ) {
          this.gridInstance.deleteColumn(i);
        }
      }

      if (
        this.gridInstance
          .columnOption(this.gridInstance.columnCount() - 1)
          .caption.includes('LMR')
      ) {
        this.gridInstance.deleteColumn(
          this.gridInstance.columnCount() - 1
        );
      }
    }
  },

  methods: {
    getDotColorImage(color) {
      return require(`../../../../../assets/dot-colors/${color}.svg`)
        .default;
    },
    init(e) {
      this.setSampleDetails(e.component.getDataSource().items());

      this.gridInstance = e.component;
    },

    calculateRange(rowData) {
      return `${rowData.value.r_min} - ${rowData.value.r_max}`;
    },
    onExporting(e) {
      this.setIsExporting(true);
      e.component.beginUpdate();
      e.component.columnOption('ID', 'visible', true);
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(
        `${`${this.$t(
          'agronomySample.summary.sampleSummary'
        )}-${this.$t('Food')}`}`
      );

      exportDataGrid({
        component: e.component,
        worksheet,
      })
        .then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(
              new Blob([buffer], {
                type: 'application/octet-stream',
              }),
              `besafer-${this.reportSampleCode}.xlsx`
            );
          });
        })
        .then(() => {
          e.component.columnOption('ID', 'visible', false);
          e.component.endUpdate();
        });

      e.cancel = true;
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items;

      toolbarItems.forEach((item) => {
        if (item.name === 'searchPanel') {
          item.location = 'before';
        }
        if (item.name === 'exportButton') {
          item.location = 'after';
        }

        if (item.name === 'groupPanel') {
          item.location = 'after';
        }
      });
    },
    columnExists(columnCount, name) {
      for (let i = 0; i < columnCount; i += 1) {
        if (this.gridInstance.columnOption(i).caption === name) {
          return i;
        }
      }

      return -1;
    },
    deleteColumns() {
      for (let i = 0; i < this.gridInstance.columnCount(); i += 1) {
        const columnName = this.gridInstance.columnOption(i).caption;
        if (columnName.includes('LMR')) {
          const formattedColumnName = columnName.split(' ')[1].trim();

          if (!this.selectedLMRs.includes(formattedColumnName)) {
            this.gridInstance.deleteColumn(i);
          }
        }
      }
    },
    ...mapActions('foodSamples', ['setSampleDetails', 'setIsExporting']),
  },
};
</script>

<style scoped>
::v-deep .dx-toolbar-after {
  left: initial !important;
}
</style>
