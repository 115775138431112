<template>
  <div>
    <f7-block>
      <div class="text-align-center margin-bottom">
        <img class="img_logo" :src="APP_LOGO" :alt="APP_NAME" />
      </div>
      <div name="txtDataAccess" class="login__label">
        {{
          !isCreatingNewUser
            ? $t('password_recovery')
            : $t('addNewUser')
        }}
      </div>
      <f7-list form class="no-margin-top">
        <f7-list-input
          name="emailInput"
          outline
          item-label
          type="email"
          :placeholder="$t('email')"
          clear-button
          max="255"
          required
          validate
          :error-message="$t('completeThisField')"
          @input="email = $event.target.value"
        />
      </f7-list>
      <!-- Buttons -->
      <f7-block>
        <f7-row>
          <f7-col>
            <f7-button
              name="btnGoBack"
              class="color-gray"
              button
              button-large
              outline
              @click="cancelRecoveryPassword"
            >
              {{ $t('cancel') }}
            </f7-button>
          </f7-col>
          <f7-col>
            <f7-button
              name="recoverPasswordButton "
              class="botonPrimary"
              button
              button-large
              fill
              :disabled="isRecoverPasswordDisabled"
              @click="recoverPassword"
            >
              {{
                !isCreatingNewUser
                  ? $t('request_reset_password')
                  : $t('requestPassword')
              }}
            </f7-button>
          </f7-col>
        </f7-row>
      </f7-block>
    </f7-block>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import {
  APP_NAME,
  APP_LOGO,
  NOTIFY_TIME_LONG,
} from '../../js/constants';

const { mapActions, mapMutations, mapState } =
  createNamespacedHelpers('user');

export default {
  name: 'PasswordRecovery',
  data() {
    return {
      APP_NAME,
      APP_LOGO,
      email: '',
    };
  },
  computed: {
    ...mapState(['isCreatingNewUser']),
    isRecoverPasswordDisabled() {
      return !this.email;
    },
  },

  methods: {
    ...mapMutations(['UNSET_USER_RECOVERING_PASSWORD']),
    ...mapActions(['requestRecoveryPassword']),
    async recoverPassword() {
      this.$f7.preloader.show();
      try {
        await this.requestRecoveryPassword({
          email: this.email,
        });
        this.$f7.dialog.alert(
          `${this.$t('reset_password_in_progress')}`
        );
      } catch (error) {
        if (error.status === 404) {
          this.$notifyDX(
            {
              message: this.$t('wrongUser'),
              width: 400,
            },
            'error',
            NOTIFY_TIME_LONG
          );
        } else {
          this.$notifyDX(
            {
              message: error.message,
              width: 400,
            },
            'error',
            NOTIFY_TIME_LONG
          );
        }
      } finally {
        this.cleanEmailInput();
        this.cancelRecoveryPassword();
        this.$f7.preloader.hide();
      }
    },
    cancelRecoveryPassword() {
      this.UNSET_USER_RECOVERING_PASSWORD();
    },
    cleanEmailInput() {
      this.email = '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import './PasswordRecovery.styles.scss';
</style>
