<template>
  <div style="display: flex; justify-content: flex-end">
    <DxTagBox
      :items="avaliableDistances"
      style="min-width: 180px"
      :value="selectedDistance"
      :on-value-changed="setDistance"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DxTagBox from 'devextreme-vue/tag-box';

export default {
  name: 'MIRGraphDistancePicker',
  components: {
    DxTagBox,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('hidrosoph', [
      'selectedDistance',
      'avaliableDistances',
    ]),
  },
  mounted() {
    this.setSelectedDistance(this.avaliableDistances ?? []);
  },
  methods: {
    setDistance(e) {
      this.setSelectedDistance(e.value);
    },
    ...mapActions('hidrosoph', ['setSelectedDistance']),
  },
};
</script>

<style lang="scss" scoped></style>
