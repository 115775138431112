import Vue from 'vue';
import Framework7 from 'framework7/framework7-lite.esm.bundle';
import i18next from 'i18next';
import VueI18Next from '@panter/vue-i18next';
import { defaultLocale, locales, whiteList } from '@/i18n/index';
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle';
import 'framework7/css/framework7.bundle.css';
import '../css/icons.css';
import '../css/app.less';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import '../css/dx.hispatec.css'; // Theme DevXtreme
import * as overlay from 'devextreme/ui/overlay';

import store from '@/store';
import notify from 'devextreme/ui/notify';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import App from '../components/app.vue';

import Helpers from './helpers';
import Dates from './dates';

const xlsx = require('json-as-xlsx');

Framework7.use(Framework7Vue);
Vue.use(VueI18Next);

// Librería de apoyo + librería propia para el manejo de fechas
Vue.prototype.$helpers = Helpers;
Vue.prototype.$dates = Dates;
Vue.prototype.$notifyDX = notify;
Vue.prototype.$jsonToXLSX = xlsx;

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

i18next.init({
  lng: defaultLocale,
  fallbackLng: defaultLocale,
  whitelist: whiteList,
  resources: locales,
});
overlay.baseZIndex(9999);

L.PM.activeLang = defaultLocale;
const i18n = new VueI18Next(i18next);

Object.defineProperty(String.prototype, 'capitalize', {
  value() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false,
});

Number.prototype.pad = function (size) {
  let s = String(this);
  while (s.length < (size || 2)) {
    s = `0${s}`;
  }
  return s;
};
// eslint-disable-next-line no-new
new Vue({
  el: '#app',
  components: {
    app: App,
  },
  render: (h) => h(App),
  i18n,
  store,
});
