import routes from '@/js/routes';

const findPath = (page) => {
  return routes.find((item) => item.name === page) || '/';
};

export default {
  namespaced: true,
  state: {
    items: [],
  },
  actions: {
    setCurrentRoute({ commit }, value) {
      const title = Array.isArray(value)
        ? value.map((item) => item.name).join(' / ')
        : 'Inicio';

      const path = Array.isArray(value)
        ? findPath(value[value.length - 1].page).path
        : '/';

      // Google Analytics - Event page_view
      window.gtag('event', 'page_view', {
        page_title: title,
        page_path: path,
      });

      commit('setCurrentRoute', value);
    },
    pushRoute({ commit }, value) {
      commit('pushRoute', value);
    },
    replaceCurrent({ commit }, value) {
      commit('replaceCurrent', value);
    },
    popRoute({ commit }) {
      commit('popRoute');
    },
    clearRoutes({ commit }) {
      commit('setCurrentRoute', []);
    },
  },
  mutations: {
    setCurrentRoute(state, value) {
      state.items = [];
      state.items = value;
    },
    pushRoute(state, value) {
      this.commit('breadcrumb/clearActives');

      state.items.push(value);
    },
    replaceCurrent(state, value) {
      this.commit('breadcrumb/clearActives');

      state.items[state.items.length - 1] = value;
    },
    clearActives(state, value) {
      state.items = state.items.map((item) => {
        if (item.active) {
          item.active = false;
        }
        return {
          ...item,
        };
      });
    },
    popRoute(state) {
      state.items.pop();
    },
  },
};
