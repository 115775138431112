// Here is where you import, and just here, your http client
import axios from 'axios';
import {
  IS_DEV,
  PROD_BASE_DOMAIN,
  DEV_BASE_DOMAIN,
  RELEASE_VERSION,
} from '@/js/constants';

// Here are defined our Response Interceptors
import {
  successfulResponseHandler,
  failedResponseHandler,
} from './responseInterceptors';

// Customers production base domain (CPBD)
let baseDomain = PROD_BASE_DOMAIN;

// If we are in dev mode: please use my dev dockerized backend, for example :)
if (IS_DEV) {
  baseDomain = DEV_BASE_DOMAIN;
}

const baseURL = `${baseDomain}`;

// Creating our AxiosInstance with the AxiosRequestConfig as an object:
const axiosClient = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'app-version': RELEASE_VERSION,
  },
});

// Telling our axiosClient to use our response interceptors
axiosClient.interceptors.response.use(
  successfulResponseHandler,
  failedResponseHandler
);

export default axiosClient;
