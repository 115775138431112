var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f7-page',[(_vm.loaded)?_c('div',[_c('GeoJsonTreeLocation',{attrs:{"tree-locations":true}}),_vm._v(" "),_c('RegisterStationTable',{attrs:{"ds":_vm.ds}}),_vm._v(" "),_c('f7-row',{staticClass:"margin-top-half"},[_c('f7-col',[(_vm.isEditingFarm)?_c('GoBack',{attrs:{"url-name":"AdvancedServicesPage"}}):_vm._e()],1),_vm._v(" "),_c('f7-col',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('DxButton',{staticClass:"standardBtn marginTop15",attrs:{"width":120,"type":"normal","styling-mode":"outlined","text":_vm.$t('confirm')},on:{"click":() => {
              if (_vm.isEditingFarm) {
                _vm.onSaveEditStation();
              } else {
                _vm.save();
              }
            }}})],1)],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }