<template>
  <div class="menu-sections-container">
    <MenuMuestras v-if="showMuestras" :menu="menu" />
    <MenuAreas v-if="showAreas" :menu="menu" />
    <MenuImages v-if="showImages" :menu="menu" />
    <MenuMapaBase v-if="showBaseMap" />
  </div>
</template>

<script>
import MenuMuestras from './MenuMuestras.vue';
import MenuAreas from './MenuAreas.vue';
import MenuImages from './MenuImages.vue';
import MenuMapaBase from './MenuMapaBase.vue';

export default {
  name: 'MenuSections',
  components: { MenuMuestras, MenuAreas, MenuImages, MenuMapaBase },
  props: {
    showBaseMap: {
      type: Boolean,
      default: true,
    },
    menu: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    showMuestras() {
      return (
        (this.menu?.samples != null &&
          Object.keys(this.menu?.samples).length !== 0) ||
        (this.menu?.sets != null &&
          Object.keys(this.menu?.sets).length !== 0)
      );
    },
    showAreas() {
      return (
        (this.menu?.areas != null &&
          Object.keys(this.menu?.areas).length !== 0) ||
        (this.menu?.interpolations != null &&
          Object.keys(this.menu?.interpolations).length !== 0)
      );
    },
    showImages() {
      return (
        this.menu?.images != null &&
        Object.keys(this.menu?.images).length !== 0
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import './MenuSections.styles.scss';
</style>
