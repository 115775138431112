<template>
  <div>
    <div style="display: flex; align-items: center">
      <!-- <p>
        <strong>
          {{ $t('Phytomonitoring') }}
        </strong>
      </p> -->
    </div>
    <div class="tableContainer">
      <div class="filterButtonPosition">
        <f7-button
          fill
          class="whiteFontColor button-filter"
          panel-toggle="right"
        >
          <span class="ic-filter"></span> {{ $t('filter') }}
        </f7-button>
      </div>
      <DxDataGrid
        id="gridContainer"
        :data-source="customStore"
        :remote-operations="true"
        :allow-column-reordering="false"
        :show-borders="true"
        :repaint-changes-only="true"
        key-expr="id"
        @row-inserted="RowInserted"
        @row-updated="rowUpdated"
        @row-removing="rowRemoved"
        @initNewRow="onInitNewRow"
        @edit-canceled="editCanceled"
        @editorPreparing="onEditorPreparing"
      >
        <DxSearchPanel :visible="true" />
        <DxPaging :page-size="10" />
        <DxPager
          :show-page-size-selector="true"
          :allowed-page-sizes="[5, 10, 20]"
          :show-info="true"
          info-text="{2} items"
          :visible="true"
        />
        <DxEditing
          :allow-updating="true"
          :allow-deleting="true"
          :allow-adding="true"
          mode="row"
        />

        <DxColumn
          data-field="date"
          :caption="$t('date')"
          data-type="date"
          format="dd/MM/yyyy"
          :allow-editing="true"
        />

        <DxColumn
          data-field="category"
          :caption="$t('category')"
          :set-cell-value="setCategoryValue"
        >
          <DxRequiredRule />
          <DxLookup
            :data-source="categoryList"
            display-expr="category"
            value-expr="category"
          />
        </DxColumn>

        <DxColumn
          data-field="type"
          :caption="$t('type')"
          :set-cell-value="setCellValue"
        >
          <DxRequiredRule />
          <DxLookup
            :data-source="getFilteredTypeList"
            display-expr="type"
            value-expr="type"
          />
        </DxColumn>

        <DxColumn
          data-field="value"
          :caption="$t('chartValue')"
          data-type="string"
        >
          <DxRequiredRule />
        </DxColumn>

        <DxColumn
          data-field="unit"
          :caption="$t('diagnosisTable.unit')"
          :allow-editing="true"
        >
          <!-- <DxRequiredRule /> -->
        </DxColumn>

        <DxColumn type="buttons">
          <DxButton name="save" icon="save" text="" />
          <DxButton name="edit" icon="edit" text="" />
          <DxButton name="delete" icon="trash" text="" />
          <DxButton name="cancel" icon="remove" text="" />
        </DxColumn>
      </DxDataGrid>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxPager,
  DxPaging,
  DxLookup,
  DxSearchPanel,
  DxButton,
  DxRequiredRule,
} from 'devextreme-vue/data-grid';

import DxSelectBox from 'devextreme-vue/select-box';
import Vue from 'vue';
import EventBus from '@/services/event-bus';
import Api from '@/services/Api';
import moment from 'moment';

export default {
  name: 'LysimeterIoTPage',
  components: {
    DxSelectBox,
    DxSearchPanel,
    DxEditing,
    DxRequiredRule,
    DxLookup,
    DxButton,
    DxDataGrid,
    DxColumn,
    DxPager,
    DxPaging,
  },
  data() {
    return {
      categoryChanged: false,
      typeChanged: false,
      typeList: [],
      currentRow: null,
      selectedCategory: null,
      selectedTypeUpdate: null,
      isCreating: false,
      categoryList: [],
      customStore: null,
      setCategoryValue(rowData, value) {
        rowData.type = null;
        this.defaultSetCellValue(rowData, value);
      },
    };
  },
  computed: {
    ...mapState('phitomonitoringPopup', ['phitomonitoringData']),
    ...mapState('registerFarm', ['farmId']),
    ...mapState('nutritionalMonitoring', ['parcelId']),
  },
  async mounted() {
    try {
      await this.getOptions();
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(error.message).error
            )
          ),
          width: 550,
        },
        'error',
        3000
      );
    }

    // this.customStore = await this.getPhitomonitoringData();
    this.setCurrentFilter('advancedServicesPhytomonitoring');
    this.clearFilters();

    this.customStore = await this.getStore();

    EventBus.$on('phitomonitoringFilters', async (params) => {
      this.$f7.preloader.show();
      this.loaded = false;

      if (!params) {
        this.customStore = await this.getStore();
      } else {
        this.customStore = await this.getStoreWithFilters(params);
      }

      this.loaded = true;
      this.$f7.preloader.hide();
    });
  },
  beforeDestroy() {
    // this.cleanPhitomonitoringData();
  },
  methods: {
    ...mapActions('phitomonitoringPopup', [
      'getStore',
      'getStoreWithFilters',
      'getPhitomonitoringData',
      'createPhitomonitoringData',
      'deletePhitomonitoringData',
      'editPhitomonitoringData',
      'cleanPhitomonitoringData',
      'clearFilters',
    ]),
    ...mapActions('filter', ['setCurrentFilter']),
    getFilteredTypeList(options) {
      const category = options?.data?.category || null;

      if (Object.keys(options).length === 0) {
        return this.typeList;
      }

      return {
        store: this.typeList,
        filter: category ? ['category', '=', category] : null,
      };
    },
    async getOptions() {
      const options = await Api.getPhitomonitoringOptions().then(
        (xhr) => {
          return JSON.parse(xhr.response)?.data ?? [];
        }
      );

      if (Array.isArray(options)) {
        this.typeList = options.map((item) => {
          return {
            allow_edit_unit: item.allow_edit_unit,
            category: item.category,
            category_id: item.category_id,
            type: item.type,
            type_id: item.type_id,
            name: item.type,
            unit: item.unit,
          };
        });

        const categories = [
          ...new Set(options.map((item) => item.category_id)),
        ];

        this.categoryList = categories.map((item) => {
          return options.find(
            (option) => option.category_id === item
          );
        });
      }
    },
    async RowInserted(e) {
      await this.createPhitomonitoringData({
        farm_id: this.farmId,
        parcel_id: this.parcelId,

        type: e.data.type,
        type_id: this.typeList.find(
          (item) => item.name === e.data.type
        ).type_id,
        category: e.data.category,
        category_id: this.typeList.find(
          (item) => item.category === e.data.category
        ).category_id,

        value: e.data.value,
        date: moment(e.data.date).format('YYYY-MM-DD'),
        unit: e.data.unit,
      });

      this.customStore = await this.getStore();
      this.isCreating = false;
    },
    async rowUpdated(e) {
      const updated = { ...e.data };

      if (updated.category) {
        const categorySelected = this.typeList.find(
          (item) => item.category === updated.category
        );

        updated.category_id = categorySelected.category_id;
      }

      if (updated.type) {
        const typeSelected = this.typeList.find(
          (item) => item.type === updated.type
        );

        updated.type_id = typeSelected.type_id;
      }

      await this.editPhitomonitoringData({
        id: e.key,
        farm_id: this.farmId,
        parcel_id: this.parcelId,
        ...updated,
      });

      this.customStore = await this.getStore();
    },
    async rowRemoved(e) {
      await this.deletePhitomonitoringData(e.data.id);

      this.customStore = await this.getStore();
    },
    onInitNewRow(e) {
      e.data.date = new Date();
      this.isCreating = true;
      this.selectedTypeUpdate = null;
      this.selectedCategory = null;
    },
    editCanceled() {
      this.isCreating = false;
      this.selectedTypeUpdate = null;
      this.selectedCategory = null;
    },
    onEditorPreparing(e) {
      if (e.parentType === 'dataRow' && e.dataField === 'unit') {
        const allowEdit = this.typeList.find(
          (item) => item.type === e.row.data.type
        )?.allow_edit_unit;

        if (allowEdit != null) {
          e.editorOptions.disabled = !allowEdit;
        }
      }
    },
    setCellValue(rowdata, value) {
      const selected = this.typeList.find(
        (item) => item.name === value
      );

      rowdata.type = value;
      rowdata.unit = selected.unit;
    },
  },
};
</script>
