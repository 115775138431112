<template>
  <div class="day">
    <div>
      <div class="day-name">
        {{ name }}
      </div>

      <div class="day-icon">
        <img
          width="44"
          height="44"
          :src="iconPath"
          :alt="icon + ' Icon'"
        />
      </div>

      <div class="high-temp">{{ highTemp }}˚</div>

      <div class="low-temp">{{ lowTemp }}˚</div>
    </div>

    <div>
      <div id="wind" class="wind">
        <div>
          <i
            class="f7-icons"
            :style="{ transform: `rotate(${wind.direction}deg)` }"
          >
            arrow_right
          </i>
        </div>
        <div>{{ wind.value }}</div>
      </div>

      <div id="water" class="water">
        <div>💧</div>
        <div>{{ water }}</div>
      </div>

      <div id="sun" class="sun">
        <div>☀️</div>
        <div>{{ sun }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Day',

  props: [
    'name',
    'icon',
    'highTemp',
    'lowTemp',
    'wind',
    'water',
    'sun',
  ],

  computed: {
    iconPath() {
      return require(`../../../../../../assets/weather-icons/${this.icon}.png`)
        .default;
    },
  },
};
</script>

<style scoped>
.day {
  background: var(--his-metal-tint);
  display: flex;
  column-gap: 12px;
  align-items: center;
  justify-content: space-between;
  /* padding: 1px 6px; */
  margin: 0px;
  border-radius: 6px;
}

.day > div:nth-child(1) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
}

.day > div:nth-child(2) {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  column-gap: 6px;
  width: 46%;
}

.day-icon {
  width: 80px;
}

.day-name {
  font-size: 12px;
  text-transform: uppercase;
  color: #999;
  padding-left: 6px; 
  width: 90px;
}

.day canvas {
  width: 26px;
  height: 26px;
}

.day p {
  margin: 0;
}

.day p:last-child {
  margin-top: -3px;
}

.day .high-temp,
.day .low-temp {
  border-radius: 50px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 5px;
  font-weight: 600;
  letter-spacing: -0.02em;
  font-size: 14px;
  height: 20px;
  overflow: hidden;
  margin: 0 4px;
}

.day .high-temp {
  background: var(--his-tomate);
  color: white;
}

.day .low-temp {
  background: var(--his-mora-light);
  color: white;
}

.wind,
.water, 
.sun {
  white-space: nowrap;
  display: flex;
  font-size: 10px;
  justify-content: flex-start;
  align-items: center;
  column-gap: 6px;
  background: white;
  border-radius: 40px;
  height: 20px;
  padding: 2px 6px;
}
.wind {
  color: var(--his-banana-shade);
  width: 46px;
}

.wind i {
  font-weight: 800;
  color: var(--his-orange);
}

.sun {
  width: 50px;
  justify-content: center;
}

@media (max-width: 530px) {
  .day {
  }
  .day-name {
    width: 40px;
  }
  .day-icon {
    width: auto;
  }
}

</style>
