<template>
  <div class="margin-top">
    <div class="margin-top-half">
      <div class="label-input">Tipo de muestra</div>
      <DxTagBox
        :data-source="tipoMuestras"
        :multiline="false"
        :search-enabled="true"
        placeholder="Tipo de muestra"
        tag-template="tag"
        :value="selectedSamplesType"
        display-expr="SubfamiliaTM"
        value-expr="SubfamiliaTM"
        :on-value-changed="samplesTypeChanged"
        :on-opened="openedSamplesType"
        :on-content-ready="contentReady"
        :show-selection-controls="false"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.SubfamiliaTM }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { DxTagBox } from 'devextreme-vue/tag-box';
import DataSource from 'devextreme/data/data_source';

export default {
  components: { DxTagBox },
  data() {
    return {
      tipoMuestras: [],
      loaded: false,
    };
  },
  computed: {
    ...mapState('Offers', ['detallesPresupuestos', 'subFamilias']),
    selectedSamplesType: {
      get() {
        return this.$store.state.OffersListFilters.tipoMuestras;
      },
      set(value) {
        this.setTipoMuestras(value);
      },
    },
  },
  methods: {
    async openedSamplesType() {
      const tipomuestras = this.extractStatusesFromPresupuestos(
        this.subFamilias
      );
      this.tipoMuestras = new DataSource({
        store: tipomuestras,
        key: 'SubfamiliaTM',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    extractStatusesFromPresupuestos(detallesPresupuestos) {
      const tipomuestrasSet = new Set();
      detallesPresupuestos.forEach((detalle) => {
        if (detalle.SubfamiliaTM) {
          tipomuestrasSet.add(
            JSON.stringify({ SubfamiliaTM: detalle.SubfamiliaTM })
          );
        }
      });
      return Array.from(tipomuestrasSet).map((item) =>
        JSON.parse(item)
      );
    },
    samplesTypeChanged(e) {
      this.setTipoMuestras(e.value);
    },
    contentReady() {
      const arrayNotFoundItems = Array.from(
        document.getElementsByClassName('dx-empty-message')
      );
      arrayNotFoundItems.forEach((element) => {
        if (!this.loaded) {
          element.innerHTML = this.$t('loading');
        } else {
          element.innerHTML = this.$t('dataNotAvailable');
        }
      });
      this.loaded = false;
    },
    ...mapActions('OffersListFilters', ['setTipoMuestras']),
  },
};
</script>
