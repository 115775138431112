<template>
  <div>
    <DxPopup
      :visible="showUploadFormPopup"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-close-button="true"
      :show-title="true"
      width="700"
      height="auto"
      container=".dx-viewport"
      :title="
        editData.isEditing
          ? $t('editFile') + ' - ' + formatRoute
          : $t('uploadFile') + ' - ' + formatRoute
      "
      @hiding="hide"
    >
      <form id="form" ref="formUpload" @submit="onSubmit">
        <div class="dx-fieldset">
          <div class="dx-field">
            <div class="dx-field-label">
              {{ $t('title') }}: <i style="color: red">*</i>
            </div>
            <DxTextBox
              :value="editData.isEditing ? editData.data.name : name"
              name="title"
              :placeholder="$t('title')"
              class="dx-field-value"
              :on-value-changed="onNameChanged"
            >
              <DxValidator>
                <DxRequiredRule :message="$t('requiredTitle')" />
              </DxValidator>
            </DxTextBox>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">{{ $t('contractNum') }}:</div>
            <DxTextBox
              name="contractNumber"
              :placeholder="$t('contractNum')"
              :value="
                editData.isEditing
                  ? editData.data.contractNumber
                  : contractNumber
              "
              class="dx-field-value"
              :on-value-changed="onContractNumChanged"
            />
          </div>
          <div class="dx-field">
            <div class="dx-field-label">
              {{ $t('agronomySample.summary.description') }}:
            </div>
            <DxTextArea
              name="description"
              :placeholder="$t('agronomySample.summary.description')"
              :value="
                editData.isEditing
                  ? editData.data.description
                  : description
              "
              class="dx-field-value"
              :on-value-changed="onDescriptionChanged"
            />
          </div>
        </div>

        <div v-if="!editData.isEditing">
          <p class="item-title-bold marginLeft25">
            {{ $t('notification') }}
          </p>

          <div class="dx-field">
            <div class="dx-field-label">
              <span class="marginLeft25">{{ $t('emails') }}</span>
            </div>
            <div class="dx-field-value">
              <DxTagBox
                :accept-custom-value="true"
                name="emails"
                :placeholder="$t('emails')"
                :width="400"
                :value="
                  editData.isEditing ? editData.data.emails : emails
                "
                :on-value-changed="emailsChanged"
                @customItemCreating="onCustomEmailCreating"
              />
            </div>
          </div>
        </div>

        <div
          v-if="!editData.isEditing"
          class="fileuploader-container marginLeft15"
        >
          <DxFileUploader
            :select-button-text="$t('uploadFile')"
            label-text=""
            :max-file-size="5000000"
            upload-mode="useForm"
            @valueChanged="checkSize"
          >
            <DxValidator>
              <DxRequiredRule :message="$t('requiredUpload')" />
            </DxValidator>
          </DxFileUploader>
          <em class="marginLeft10">
            Max
            <strong style="color: red">
              {{ sizeLimit }} {{ sizeLimitUnit }}
            </strong>
          </em>

          <p
            v-if="exceedsSizeLimit"
            class="marginLeft10"
            style="color: red"
          >
            {{ $t('fileExceedsSize') }}
          </p>
        </div>

        <f7-row>
          <f7-col width="100" style="text-align: center">
            <div class="dx-fieldset">
              <DxButton
                class="standardBtn"
                :use-submit-behavior="true"
                :text="$t('save')"
                type="outlined"
              />
            </div>
          </f7-col>
        </f7-row>
      </form>
    </DxPopup>
  </div>
</template>

<script>
import { DxPopup } from 'devextreme-vue/popup';
import { mapActions, mapState } from 'vuex';

import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { DxTextBox } from 'devextreme-vue/text-box';

import DxButton from 'devextreme-vue/button';

import { DxTextArea } from 'devextreme-vue/text-area';

import DxTagBox from 'devextreme-vue/tag-box';
import EventBus from '@/services/event-bus';
import notify from 'devextreme/ui/notify';

import {
  DxValidator,
  DxRequiredRule,
} from 'devextreme-vue/validator';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  components: {
    DxPopup,
    DxFileUploader,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxButton,
    DxTextArea,
    DxTagBox,
  },
  props: {
    editData: {
      type: Object,
      default: () => {
        return {
          isEditing: false,
          data: null,
        };
      },
    },
    address: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      name: '',
      contractNumber: '',
      description: '',
      emails: [],
      sizeLimit: 5,
      sizeLimitUnit: 'MB',
      exceedsSizeLimit: false,
    };
  },
  computed: {
    ...mapState('breadcrumb', ['items']),
    ...mapState('documentation', ['showUploadFormPopup', 'atc']),
    formatRoute() {
      return this.items[0].name;
    },
  },
  beforeMount() {
    if (this.editData.isEditing) {
      this.name = this.editData.data.name;
      this.emails = this.editData.data.emails;
      this.contractNumber = this.editData.data.contractNumber;
      this.description = this.editData.data.description;
    }
  },
  methods: {
    hide() {
      this.setUploadFormPopup(false);
    },
    onCustomEmailCreating() {
      // TODO: Definir como se quieren los correos de este campo
    },
    checkSize(e) {
      const kb = e.value[0].size / 1024;
      const mb = (kb / 1024).toFixed(2);

      if (Number(mb) > this.sizeLimit) {
        this.exceedsSizeLimit = true;
      } else {
        this.exceedsSizeLimit = false;
      }
    },
    async onSubmit(e) {
      e.preventDefault();

      if (this.exceedsSizeLimit) return;

      if (this.editData.isEditing) {
        this.onSubmitEdit(e);
        return;
      }

      const file = this.$refs.formUpload[5].files[0];

      try {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        const extension = this.getFileExtension(file);

        reader.onload = async () => {
          notify(
            {
              message: this.$t('uploading'),
              width: 450,
            },
            'info',
            2000
          );
          this.$f7.preloader.show();
          await this.uploadFile({
            name: this.name,
            fileB64: reader.result,
            atc: this.atc,
            extension,
            fileSize: file.size,
            description: this.description,
            contractNumber: this.contractNumber,
            emails: this.emails,
            address: this.address,
          });

          const fs = await this.getTree(this.atc);

          EventBus.$emit('fileUploaded', fs);

          this.setUploadFormPopup(false);
          notify(
            {
              closeOnClick: true,
              message: this.$t('uploadSuccess'),
              width: 450,
            },
            'success',
            2000
          );

          this.$f7.preloader.hide();
        };

        reader.onerror = (error) => {
          // TODO: ERROR. TRATAR CON HELPERS
          console.error('Error: ', error);
        };
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(error.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      }
    },
    async onSubmitEdit(e) {
      try {
        notify(
          {
            message: this.$t('editing'),
            width: 450,
          },
          'info',
          2000
        );
        this.$f7.preloader.show();
        await this.editFile({
          id: this.editData.data.id,
          name: this.name,
          description: this.description,
          contractNumber: this.contractNumber,
          emails: this.emails,
          address: this.address,
        });

        const fs = await this.getTree(this.atc);

        EventBus.$emit('fileUploaded', fs);

        this.setUploadFormPopup(false);
        notify(
          {
            closeOnClick: true,
            message: this.$t('editSuccess'),
            width: 450,
          },
          'success',
          2000
        );
        this.$f7.preloader.hide();
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(error.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      }
    },
    getFileExtension(file) {
      const lastIndexDot = file.name.lastIndexOf('.');
      const splitted = file.name.split(file.name[lastIndexDot]);

      return splitted[splitted.length - 1];
    },
    emailsChanged(e) {
      this.emails = e.value;
    },
    onNameChanged(e) {
      this.name = e.value;
    },
    onContractNumChanged(e) {
      this.contractNumber = e.value;
    },
    onDescriptionChanged(e) {
      this.description = e.value;
    },
    ...mapActions('documentation', [
      'setUploadFormPopup',
      'uploadFile',
      'editFile',
      'getTree',
    ]),
  },
};
</script>
