<template>
  <div>
    <div
      :id="`${nameMap}_${initialDate}`"
      :style="{ height: height + 'px', 'border-radius': '5px' }"
    ></div>
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import { mapState, mapActions } from 'vuex';
import EventBus from '@/services/event-bus';

export default {
  props: {
    height: {
      type: Number,
      default: 500,
    },
    zoom: { type: Number, default: 1 },
    selectedMinHeight: { type: String, default: '50vh' },
    nameMap: { type: String, default: '' },
    treeLocations: {
      type: Array,
      default: () => [],
    },
    center: {
      type: Array,
      default: () => null,
    },
  },
  data() {
    return {
      currentLayerBase: {},
      event: 'updateGIS',
      map: null,
      layerBounds: null,
      groupBounds: null,
      initialDate: Date.now(),
      locationProperties: {},
      actualDate: new Date(),
    };
  },
  computed: {
    ...mapState('gis', ['osm', 'treeLocationData']),
  },
  mounted() {
    if (this.treeLocations.length > 0) {
      this.renderMap();
      this.addFuncionalities();
      this.addOverlayLayer();
    } else {
      this.renderMap();
      this.addFuncionalities();
      this.locateUser();
    }
  },
  methods: {
    locateUser() {
      this.map
        .locate({ setView: true, maxZoom: 16 })
        .on('locationfound', (e) => {
          const marker = new L.Marker(e.latlng, {
            draggable: false,
          }).addTo(this.map);
          const group = L.featureGroup();
          group.addLayer(marker);
          this.groupBounds = group.getBounds();

          const redIcon = new L.Icon({
            iconUrl:
              'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-violet.png',
            shadowUrl:
              'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41],
          });
          marker.setLatLng(e.latlng);

          marker.setIcon(redIcon);
        })
        .on('locationerror', (error) => {
          this.map.setView(
            [41.6164989248106, -4.873517847881886],
            13
          );
        });
    },
    /**
     * Añadimos una capa tanto de tipo GeoJson como WMS a la lista de capas activas
     */
    async addOverlayLayer() {
      this.$f7.preloader.show();
      try {
        let layer;
        let layer2;
        const layerStyles = {
          default: {
            color: 'rgba(255,0,224,0.7)',
            weight: 1,
            fillOpacity: 0.5,
          },
          active: {
            weight: 3,
            color: 'rgba(241,234,234,0.71)',
            dashArray: '',
            fillOpacity: 0.7,
          },
          selected: {
            weight: 2,
            color: 'rgba(250,250,120,0.71)',
            dashArray: '',
            fillOpacity: 0.7,
          },
        };
        let treeLocationIds = {};
        const group = L.featureGroup();

        for (const geoData of this.treeLocations) {
          if (this.$helpers.isEmptyObject(geoData.geo_feature))
            continue;

          layer = L.geoJson(geoData.geo_feature).addTo(this.map);
          group.addLayer(layer);

          const bounds = layer.getBounds();
          this.layerBounds = bounds;

          treeLocationIds = {};

          this.layersEvents(layer, geoData);

          /** layer2 = L.geoJson(geoData.children_geofeatures).addTo(
            this.map
          );
          layer2.setStyle(layerStyles.active);
          treeLocationIds = {};
          this.layersEvents(layer2, treeLocationIds); */
        }
        if (this.center !== null) {
          this.map.setView(
            [this.center.latitude, this.center.longitude],
            this.zoom
          );
        } else {
          const bounds = group.getBounds();
          this.groupBounds = bounds;
          this.map.fitBounds(bounds);
        }
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },

    /**
     * Centramos el mapa cuando tenemos un GeoJson
     */
    centerMapGeoJsonLayer(layer) {
      const bounds = layer.getBounds();
      this.layerBounds = bounds;
      this.map.flyToBounds(bounds);
    },

    layersEvents(target, treeLocationIds) {
      const self = this;
      target.eachLayer((layer) => {
        layer.on({
          click(e) {
            L.DomEvent.stopPropagation(e);
            self.setTreeLocationData(treeLocationIds);
            self.locationProperties = {};
            self.openLayerDataPopup(
              e.target.feature.properties,
              e,
              treeLocationIds
            );
          },
          dblclick(e) {},
          mouseover(e) {},
          mouseout(e) {
            self.map.closePopup(e);
          },
        });
      });
    },

    renderMap() {
      this.map = L.map(`${this.nameMap}_${this.initialDate}`);
      L.tileLayer(
        this.osm.googleHybrid.route,
        this.osm.googleHybrid.properties
      ).addTo(this.map);
      this.currentLayerBase = L.tileLayer(
        this.osm.googleHybrid.route,
        this.osm.googleHybrid.properties
      ).addTo(this.map);
      this.currentLayerBase.bringToBack();
    },
    addFuncionalities() {
      const self = this;
      try {
        L.control.scale().addTo(self.map);
        self.map.attributionControl.addAttribution(
          'Margaret from <a href="https://hispatecanalytics.com//">HispatecAnalytics SA</a>'
        );
        L.Control.zoomHome = L.Control.extend({
          options: {
            position: 'topleft',
            zoomHomeText: '<i class="f7-icons">house_fill</i>',
            zoomHomeTitle: 'Ver geometrías',
          },
          onAdd() {
            const controlName = 'gin-control-zoom';
            const container = L.DomUtil.create(
              'div',
              `${controlName} leaflet-bar`
            );
            const { options } = this;
            // eslint-disable-next-line no-underscore-dangle
            this._zoomHomeButton = this.createButton(
              options.zoomHomeText,
              options.zoomHomeTitle,
              `${controlName}-home`,
              container,
              this.zoomHome
            );

            return container;
          },

          zoomHome() {
            self.map.fitBounds(self.groupBounds);
          },

          createButton(html, title, className, container, fn) {
            const link = L.DomUtil.create('a', className, container);
            link.innerHTML = html;
            link.href = '#';
            link.title = title;
            L.DomEvent.on(
              link,
              'mousedown dblclick',
              L.DomEvent.stopPropagation
            )
              .on(link, 'click', L.DomEvent.stop)
              .on(link, 'click', fn, this);
            return link;
          },
        });
        // eslint-disable-next-line new-cap
        const zoomHome = new L.Control.zoomHome();
        zoomHome.addTo(self.map);
      } catch (e) {
        this.$f7.dialog.alert(e);
      }
    },
    async openLayerDataPopup(properties, e, treeLocationIds) {
      this.locationProperties = {
        ...properties,
        ...treeLocationIds,
      };
      let htmlString = `
      <div class='popup-content'>
        <div>
          <p>
          
          <b>${this.$t('agronomySample.summary.estate')}:</b> ${
        properties.farm
      }
          </p>
            <b>${this.$t('accountable')}:</b> ${
        properties.accountable
      }
            </p>
            <a
              id='farmBtn'
              data-parcel=${treeLocationIds.id}
              style="display: flex; justify-content: flex-end"
              class="dx-link dx-icon-find dx-link-icon"
            ></a>
          </div>
          </div>`;
      const htmlStringSector = ``;
      if (typeof treeLocationIds.sectorId !== 'undefined') {
        htmlString += htmlStringSector;
      }

      htmlString += `
        </div>
      </div>`;
      this.openPopup(htmlString, e.latlng, treeLocationIds);

      setTimeout(() => {
        const parcel = document.getElementById('farmBtn');

        parcel.addEventListener('click', (e) => {
          const id = e.target.getAttribute('data-parcel');
          if (id !== 'undefined') {
            const {
              properties: props,
            } = this.treeLocationData.geo_feature.features[0];

            const formattedData = {
              accountable: props.accountable,
              code: this.treeLocationData.code,
              cultive: undefined,
              farm: props.farm,
              farmGeoFeature: {
                geo_feature: this.treeLocationData.geo_feature,
              },
              farmId: undefined,
              id: this.treeLocationData.id,
              parcels: this.treeLocationData.children,
              samplingDate: undefined,
              station: `${this.treeLocationData.measured_station} ${this.treeLocationData.measured_provider}`,
              variety: undefined,
            };

            EventBus.$emit('goToDashboard', formattedData);
          }
        });
      }, 0);
    },
    getLayerDataPropertiesHtml(properties) {
      let listHtml = '';
      for (const property in properties) {
        // eslint-disable-next-line no-continue
        if (properties[property] == null) continue;
        if (typeof properties[property] === 'object') {
          listHtml += this.getLayerDataPropertiesHtml(
            properties[property]
          );
        } else {
          listHtml += `<li><b>${property}</b>: ${properties[property]}</li>`;
        }
      }
      return listHtml;
    },
    openPopup(html, latlng, treeLocationIds) {
      this.map.openPopup(html, latlng, {
        maxHeight: 4000,
      });
    },
    ...mapActions('gis', ['setTreeLocationData']),
  },
};
</script>
