<template>
  <div class="tableContainer margin-top">
    <div class="justify-content-end">
      <div v-if="loadingChartData">
        <div
          class="loadingFalse"
          style="height: 15px; margin-top: 10px; margin-right: 27px"
        >
          <div class="preloader" style="width: 35px; height: 35px">
            <span class="preloader-inner">
              <span class="preloader-inner-circle"></span>
            </span>
          </div>
        </div>
      </div>
      <DxSelectBox
        class="margin-right-half"
        :data-source="valuesChart"
        :value="selectedValueChart"
        :on-value-changed="changeSelectedValueChart"
        display-expr="text"
        value-expr="id"
        :grouped="true"
      >
      </DxSelectBox>
      <div class="width-200">
        <DxDateBox
          display-format="yyyy"
          picker-type="calendar"
          type="date"
          width="100%"
          :value="selectedYear"
          :accept-custom-value="false"
          :open-on-field-click="true"
          @value-changed="setchartDate"
        >
          <DxCalendarOptions
            :max-zoom-level="'decade'"
            min-zoom-level="decade"
            zoom-level="year"
          />
        </DxDateBox>
      </div>
    </div>

    <div>
      <DxChart
        id="chart"
        style="
          width: calc(100% - 30px);
          display: block;
          padding-top: 10px;
        "
        :data-source="getChartData"
      >
        <DxCommonSeriesSettings argument-field="month" />
        <DxSeries
          pane="bottomPane"
          value-field="sfr_value"
          name="SFR"
          type="bar"
          color="#4BC0C0"
        />
        <DxSeries
          pane="bottomPane"
          value-field="ss_value"
          name="SS"
          type="bar"
          color="#36A2EB"
        />
        <DxSeries
          pane="topPane"
          value-field="current_leaf_value"
          :name="$t('chart_current_leaf')"
          type="spline"
          color="#1dd1a1"
        />
        <DxSeries
          pane="topPane"
          value-field="previous_leaf_value"
          :name="$t('chart_previous_leaf')"
          type="spline"
          dash-style="dash"
          color="#badc58"
        />
        <DxSeries
          pane="topPane"
          value-field="foliar_ref_values_value_min"
          :name="$t('chart_foliar_ref_min')"
          type="spline"
          color="#36A2EB"
        />
        <DxSeries
          pane="topPane"
          value-field="foliar_ref_values_value_max"
          :name="$t('chart_foliar_ref_max')"
          type="spline"
          color="#FF6384"
        />

        <DxLegend
          :margin="30"
          vertical-alignment="bottom"
          horizontal-alignment="center"
          item-text-position="top"
        />
        <DxValueAxis pane="top" :axis-division-factor="20">
          <DxGrid :visible="true" />
          <DxTitle text="Valor" />
        </DxValueAxis>
        <DxTitle :text="getChartTitle" horizontal-alignment="left" />

        <DxPane v-if="showTopPane" name="topPane" />
        <DxPane name="bottomPane" />

        <DxValueAxis pane="bottomPane" :axis-division-factor="9">
          <DxGrid :visible="true" />
          <DxTitle :text="bottomUnit" />
          <DxTick :visible="true" />
        </DxValueAxis>
        <DxValueAxis v-if="showTopPane" pane="topPane">
          <DxGrid :visible="true" />
          <DxTitle :text="topUnit" />
        </DxValueAxis>
        <DxTooltip
          :enabled="true"
          :z-index="999999"
          :customize-tooltip="customizeTooltip"
          :interactive="true"
        />
      </DxChart>
    </div>
  </div>
</template>

<script>
import EventBus from '@/services/event-bus';
import {
  DxDateBox,
  DxCalendarOptions,
} from 'devextreme-vue/date-box';
import { mapActions, mapState } from 'vuex';
import format from 'date-fns/format';
import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxLegend,
  DxValueAxis,
  DxGrid,
  DxTitle,
  DxPane,
  DxTooltip,
  DxArgumentAxis,
  DxTick,
} from 'devextreme-vue/chart';
import DxSelectBox from 'devextreme-vue/select-box';
import DataSource from 'devextreme/data/data_source';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  name: 'NutritionalMonitoringChart',
  components: {
    DxTooltip,
    DxDateBox,
    DxCalendarOptions,
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxLegend,
    DxValueAxis,
    DxGrid,
    DxTitle,
    DxPane,
    DxSelectBox,
    DxArgumentAxis,
    DxTick,
  },
  data() {
    return {
      selectedValueChart: 'PH',
      selectedYear: null,
      chartDate: [],
      valuesChartData: [
        // Parámetros físico-químicos
        {
          id: 'PH',
          text: 'PH',
          category: 'Parámetros físico-químicos',
        },
        {
          id: 'CE',
          text: 'CE',
          category: 'Parámetros físico-químicos',
        },
        // Macronutrientes
        {
          id: 'N_total',
          text: 'N total',
          category: 'Macronutrientes',
        },
        {
          id: 'P_H2PO4',
          text: 'P / H₂PO₄',
          category: 'Macronutrientes',
        },
        {
          id: 'K',
          text: 'K',
          category: 'Macronutrientes',
        },
        {
          id: 'Ca',
          text: 'Ca',
          category: 'Macronutrientes',
        },
        {
          id: 'Mg',
          text: 'Mg',
          category: 'Macronutrientes',
        },
        {
          id: 'S_SO4',
          text: 'S / SO₄',
          category: 'Macronutrientes',
        },
        // Micronutrientes
        {
          id: 'B',
          text: 'B',
          category: 'Micronutrientes',
        },
        {
          id: 'Fe',
          text: 'Fe',
          category: 'Micronutrientes',
        },
        {
          id: 'Mn',
          text: 'Mn',
          category: 'Micronutrientes',
        },
        {
          id: 'Zn',
          text: 'Zn',
          category: 'Micronutrientes',
        },
        // Elementos fitotóxicos
        {
          id: 'Cl',
          text: 'Cl',
          category: 'Elementos fitotóxicos',
        },
      ],
      loadingChartData: false,
      topUnit: '',
      bottomUnit: '',
      showTopPane: false,
    };
  },
  computed: {
    getDefaultYear() {
      if (this.samplingDate != null) {
        return new Date(this.samplingDate).getFullYear();
      }

      return null;
    },
    getChartTitle() {
      if (this.selectedValueChart) {
        const text =
          this.valuesChartData.find(
            (item) => item.id === this.selectedValueChart
          )?.text ?? '';

        return text;
      }
      return '';
    },
    getChartData() {
      const data = [];
      const selected = this.selectedValueChart;

      if (Object.keys(this.NutritionalGraphicData).length === 0)
        return;

      Object.entries(this.NutritionalGraphicData).map(
        ([key, value]) => {
          const unit = value[selected]?.unit ?? '';
          const formatedKey = `${key.toLowerCase()}_value`;

          if (key === 'ss' || key === 'sfr') {
            if (this.bottomUnit.length === 0) {
              this.bottomUnit = unit;
            }
          } else {
            this.showTopPane = true;

            if (this.topUnit.length === 0) {
              this.topUnit = unit;
            }
          }

          if (value[selected]?.data != null) {
            if (formatedKey === 'foliar_ref_values_value') {
              Object.entries(value[selected].data).map(
                ([key2, value2]) => {
                  data.push({
                    [`${formatedKey}_min`]: value2.low_level,
                    month: key2,
                    unit,
                  });

                  data.push({
                    [`${formatedKey}_max`]: value2.high_level,
                    month: key2,
                    unit,
                  });
                }
              );
            } else {
              Object.entries(value[selected].data).map(
                ([key2, value2]) => {
                  data.push({
                    [formatedKey]: value2,
                    month: key2,
                    unit,
                  });
                }
              );
            }
          }

          return value;
        }
      );

      return data;
    },
    valuesChart() {
      return new DataSource({
        store: {
          type: 'array',
          data: this.valuesChartData,
          key: 'id',
        },
        group: 'category',
      });
    },
    ...mapState('nutritionalMonitoring', [
      'NutritionalMonitoringData',
      'NutritionalGraphicData',
      'showPopUpPhitomonitoring',
      'parcelId',
      'samplingDate',
    ]),
    ...mapState('samples', ['last_sample_id']),
    ...mapState('registerFarm', ['farmStr', 'parcelStr']),
  },
  watch: {
    async selectedValueChart(newValue) {
      this.cleanDataChart();
      await this.loadChartData();
    },
    async chartDate(newValue) {
      this.cleanDataChart();
      await this.loadChartData();
    },
  },
  mounted() {
    EventBus.$on('setDefaultYearNutritionalMonitoring', () => {
      const year = this.getDefaultYear;
      if (year != null) {
        this.selectedYear = new Date(year, 0, 1);

        this.chartDate = [
          new Date(year, 0, 1),
          new Date(year, 11, 31),
        ];
      }
    });
  },
  methods: {
    async loadChartData() {
      if (this.selectedValueChart === null) return;
      if (this.chartDate.length === 0) return;

      this.showTopPane = false;
      this.topUnit = '';
      this.bottomUnit = '';

      try {
        this.loadingChartData = true;

        await this.getNutritionalGraphicData({
          parcelId: this.parcelId,
          parameters: this.selectedValueChart,
          dateAfter: format(
            new Date(this.chartDate[0]),
            'yyyy-MM-dd'
          ),
          dateBefore: format(
            new Date(this.chartDate[1]),
            'yyyy-MM-dd'
          ),
        });
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(error.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      } finally {
        this.loadingChartData = false;
      }
    },
    setchartDate(e) {
      const year = format(new Date(e.value), 'yyyy');

      if (year) {
        this.chartDate = [`${year}-01-01`, `${year}-12-31`];
      }
    },
    changeSelectedValueChart(e) {
      this.selectedValueChart = e.value;
    },
    customizeTooltip(data) {
      const chartLegendClass = data.seriesName;
      const chartTimeRange = data.argumentText;
      const chartValue = parseFloat(data.value.toFixed(2)) || 0;
      const chartValueUnit =
        chartLegendClass === 'SS' || chartLegendClass === 'SFR'
          ? this.bottomUnit
          : this.topUnit;

      return {
        html: `
        <div class="custom-tooltip">
          <span class='bold'>${this.$t('chartLegendClass')}: </span> 
          ${chartLegendClass}
        </div>
        <div class="custom-tooltip">
          <span class='bold'>${this.$t('chartTimeRange')}: </span> 
          ${chartTimeRange}
        </div>
        <div class="custom-tooltip">
          <span class='bold'>${this.$t('chartValue')}: </span> 
          ${chartValue} ${chartValueUnit}
        </div>
        `,
      };
    },
    ...mapActions('nutritionalMonitoring', [
      'getDetalledNutritionalMonitoring',
      'getNutritionalGraphicData',
      'togglePopUpPhitomonitoring',
      'cleanData',
      'cleanDataChart',
    ]),
    ...mapActions('samples', [
      'setReportSampleId',
      'getReport',
      'getLastSampleId',
    ]),
  },
};
</script>

<style scoped>
.nutritional-monitoring-page .justify-content-end {
  display: flex;
  justify-content: flex-end;
}
</style>
