<template>
  <div>
    <div class="filterButtonPosition" style="right: 64px">
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <div>
          <a
            id="analysis-risk-info"
            class="analysis-risk-info"
            @mouseenter="toggleWithAnimationOptions"
            @mouseleave="toggleWithAnimationOptions"
          >
            <i class="f7-icons">info_circle_fill</i>
          </a>
        </div>

        <f7-button
          id="panel-right-risk-btn"
          fill
          class="whiteFontColor button-filter"
          panel-toggle="right"
        >
          <span class="ic-filter"></span> {{ $t('filter') }}
        </f7-button>
      </div>
    </div>
    <DxDataGrid
      :data-source="data"
      :show-borders="true"
      :allow-column-reordering="false"
      :column-auto-width="true"
      :show-colon="false"
      :remote-operations="false"
      :hover-state-enabled="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @toolbar-preparing="onToolbarPreparing"
      @exporting="onExporting"
    >
      <DxScrolling mode="standard" />

      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="riskAnalysisTable"
      />

      <DxPaging :page-size="20" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="[5, 10, 20]"
        :show-info="true"
        :visible="true"
        info-text="{2} items"
      />
      <DxSearchPanel
        :visible="true"
        :width="300"
        :placeholder="$t('search')"
      />

      <DxExport :enabled="true" />

      <DxGrouping />
      <DxGroupPanel :visible="false" />

      <DxColumn
        :caption="
          selectedSampleTypes !== null
            ? `${$t('agronomySample.summary.sampleType')}: ${
                selectedSampleTypes.name
              }`
            : $t('agronomySample.summary.sampleType')
        "
      >
        <DxColumn
          :name="data.Parametro"
          :caption="$t('foodSamples.parameter')"
          data-field="Parametro"
        />
      </DxColumn>

      <DxColumn
        :caption="$t('originCountry')"
        alignment="center"
        data-field="originCountryMain"
      >
        <DxColumn
          :caption="getOriginCountryTranslated"
          alignment="center"
          cell-template="templateCountry"
          data-field="originCountry"
        />
      </DxColumn>

      <DxColumn
        :caption="$t('destinationCountry')"
        alignment="center"
        data-field="destinationCountryMain"
      >
        <DxColumn
          v-for="columnData in selectedDestinationCountries"
          :key="columnData"
          :caption="getDestinationCountryTranslated(columnData)"
          alignment="center"
          cell-template="templateDestination"
          data-field="destinationCountry"
        />
      </DxColumn>

      <template #templateCountry="{ data }">
        <div style="text-align: center">
          <span>
            {{
              data.data[
                `lmr${
                  selectedOriginCountry != null
                    ? selectedOriginCountry.code
                    : userProfile.userCountry
                }`
              ]
            }}
          </span>
        </div>
      </template>

      <template #params="{ data }">
        <div>
          {{ data.data.Parametro }}
        </div>
      </template>

      <template #templateDestination="{ data }">
        <div style="display: flex; justify-content: center">
          <div
            :class="{
              lowRisk:
                +data.data[`lmr${getOriginCountry}`] <
                +data.data[`lmr${data.column.caption}`],
              avgRisk:
                +data.data[`lmr${getOriginCountry}`] ==
                +data.data[`lmr${data.column.caption}`],
              highRisk:
                +data.data[`lmr${getOriginCountry}`] >
                +data.data[`lmr${data.column.caption}`],
            }"
          >
            {{ data.data[`lmr${data.column.caption}`] }}
          </div>
        </div>
      </template>
    </DxDataGrid>
    <DxPopover
      :width="200"
      :visible="withAnimationOptionsVisible"
      :animation="animationConfig"
      target="#analysis-risk-info"
      position="bottom"
    >
      <div style="padding: 10px">
        <f7-row>
          <f7-col>
            <div
              style="height: 20px; margin-bottom: 10px"
              class="highRisk"
            ></div>
          </f7-col>
          <f7-col> {{ $t('highRisk') }} </f7-col>
        </f7-row>

        <f7-row>
          <f7-col>
            <div
              style="height: 20px; margin-bottom: 10px"
              class="avgRisk"
            ></div>
          </f7-col>
          <f7-col> {{ $t('avgRisk') }} </f7-col>
        </f7-row>

        <f7-row>
          <f7-col>
            <div
              style="height: 20px; margin-bottom: 10px"
              class="lowRisk"
            ></div>
          </f7-col>
          <f7-col> {{ $t('lowRisk') }} </f7-col>
        </f7-row>
      </div>
    </DxPopover>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxStateStoring,
  DxPager,
  DxPaging,
  DxGrouping,
  DxGroupPanel,
  DxScrolling,
  DxExport,
} from 'devextreme-vue/data-grid';

import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { DxPopover } from 'devextreme-vue/popover';
import countryTranslationServiceEs from '../../../i18n/translated_countries_service_es';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxStateStoring,
    DxPager,
    DxPaging,
    DxScrolling,
    DxExport,
    DxGrouping,
    DxGroupPanel,
    DxPopover,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      editButtons: [
        {
          icon: 'find',
          onClick: this.viewAtc,
        },
        {
          icon: 'download',
          onClick: this.triggerContextualMenu,
        },
      ],
      withAnimationOptionsVisible: false,
      animationConfig: {
        show: {
          type: 'pop',
          from: {
            scale: 0,
          },
          to: {
            scale: 1,
          },
        },
        hide: {
          type: 'fade',
          from: 1,
          to: 0,
        },
      },
    };
  },
  computed: {
    getOriginCountryTranslated() {
      let code = '';

      if (this.enabled) {
        code =
          this.selectedOriginCountry != null
            ? this.selectedOriginCountry.code
            : this.userProfile.userCountry;
      } else {
        code =
          this.selectedOriginCountry !== null
            ? this.selectedOriginCountry.code
            : this.$t('originCountry');
      }

      if (code === 'UE') {
        return countryTranslationServiceEs.getCountryNameByCode(
          this.selectedEuropeanOriginCountry
        );
      }

      return countryTranslationServiceEs.getCountryNameByCode(code);
    },

    getOriginCountry() {
      return this.selectedOriginCountry != null
        ? this.selectedOriginCountry.code
        : this.userProfile.userCountry;
    },
    ...mapState('riskAnalysisFilter', [
      'selectedSampleTypes',
      'selectedDestinationCountries',
      'selectedOriginCountry',
      'selectedEuropeanOriginCountry',
    ]),
    ...mapGetters('user', ['userProfile']),
    ...mapState('contact', ['enabled']),
  },
  beforeMount() {
    this.setCurrentFilter('riskAnalysis');
  },
  methods: {
    getDestinationCountryTranslated(code) {
      return countryTranslationServiceEs.getCountryNameByCode(code);
    },
    toggleWithAnimationOptions() {
      this.withAnimationOptionsVisible =
        !this.withAnimationOptionsVisible;
    },
    onExporting(e) {
      e.component.beginUpdate();
      e.component.columnOption('ID', 'visible', true);
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(
        `${`${this.$t(
          'environmentSamples.exportExcelSampleTable'
        )}-${this.$t('Food')}`}`
      );

      exportDataGrid({
        component: e.component,
        worksheet,
        customizeCell: ({ gridCell, excelCell }) => {
          if (gridCell.rowType === 'data') {
            if (
              gridCell.column.dataField === 'originCountry' ||
              gridCell.column.dataField === 'originCountryMain'
            ) {
              excelCell.value =
                gridCell.data[
                  `lmr${
                    this.selectedOriginCountry != null
                      ? this.selectedOriginCountry.code
                      : this.userProfile.userCountry
                  }`
                ];
            }
            if (
              gridCell.column.dataField === 'destinationCountry' ||
              gridCell.column.dataField === 'destinationCountryMain'
            ) {
              const lowRisk =
                +gridCell.data[`lmr${this.getOriginCountry}`] <
                +gridCell.data[`lmr${gridCell.column.caption}`];

              const avgRisk =
                +gridCell.data[`lmr${this.getOriginCountry}`] ==
                +gridCell.data[`lmr${gridCell.column.caption}`];

              const highRisk =
                +gridCell.data[`lmr${this.getOriginCountry}`] >
                +gridCell.data[`lmr${gridCell.column.caption}`];

              if (highRisk) {
                excelCell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'ff726f' },
                };
              }

              if (avgRisk) {
                excelCell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'f6d200' },
                };
              }

              if (lowRisk) {
                excelCell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: '00c56a' },
                };
              }

              excelCell.value =
                gridCell.data[`lmr${gridCell.column.caption}`];
            }
          }
        },
      })
        .then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(
              new Blob([buffer], {
                type: 'application/octet-stream',
              }),
              `${`${this.$t(
                'environmentSamples.exportExcelSampleTable'
              )}-${this.$t('Food')}`}.xlsx`
            );
          });
        })
        .then(() => {
          e.component.columnOption('ID', 'visible', false);
          e.component.endUpdate();
        });

      e.cancel = true;
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items;

      toolbarItems.forEach((item) => {
        if (item.name === 'searchPanel') {
          item.location = 'before';
        }
        if (item.name === 'exportButton') {
          item.location = 'after';
        }

        if (item.name === 'groupPanel') {
          item.location = 'after';
        }
      });
    },
    ...mapActions('filter', ['setCurrentFilter']),
  },
};
</script>

<style scoped>
::v-deep .dx-toolbar-after {
  left: initial !important;
}

.analysis-risk-info {
  border: 1px solid var(--his-mora-tint);
  background: var(--his-mora-tint);
  margin-right: 6px;
  display: flex;
  padding: 4px;
  border-radius: 4px;
}

.analysis-risk-info i {
  color: var(--his-arandano);
}
</style>
