import Api from '@/services/Api';
import { ATCS_ES } from '@/js/constants';

export default {
  namespaced: true,
  state: {
    osm: {
      googleHybrid: {
        route:
          'https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}?apikey=AIzaSyAlUKxY4pMzMwUK7OQ9N737Zj0TbLcW6TA',
        type: 'Google',
        properties: {
          maxZoom: 19,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        },
      },
      osm: {
        route: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        type: 'OSM',
        properties: {
          maxZoom: 19,
          attribution:
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        },
      },
    },
    currentGeojsonFeature: {},
    geoJsonPoints: {
      type: 'FeatureCollection',
      features: [],
    },
    datesList: [],
    featureInfo: {},
    currentLayersMap: [],
    currentOverlayLayer: '',
    currentBoundsWMSLayer: null,
    currentInitIntervalDates: {},
    currentEndIntervalDates: {},
    indexAvailables: ['NDVI'],
    currentIndexes: {},
    selectedIndex: '',
    timelineIndexes: null,
    timeLineIndexesWMS: null,
    treeLocationData: {},
  },
  actions: {
    async fetchCurrentGeojsonFeature({ commit }, url) {
      try {
        const requestOptions = {
          method: 'GET',
          redirect: 'follow',
        };

        await fetch(url, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            commit('setCurrentGeojsonFeature', result);
          })
          .catch((error) => Promise.reject(error));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error); // Esta llamada no tiene los mensajes de error normalizados
      }
    },
    setGeoJsonPoints({ commit }, value) {
      commit('setGeoJsonPoints', value);
    },
    setTreeLocationData({ commit }, value) {
      commit('setTreeLocationData', value);
    },
    removeGeoJsonPoint({ commit }, value) {
      commit('removeGeoJsonPoint', value);
    },
    clearGeoJsonPoints({ commit }, value) {
      commit('clearGeoJsonPoints', value);
    },
    setCurrentLayersMap({ commit }, value) {
      commit('setCurrentLayersMap', value);
    },
    async getFarms({ rootState }, withoutGeometries = false) {
      try {
        const xhr = await Api.getFarms(
          withoutGeometries,
          rootState.contact.company,
          rootState.contact.address,
          ATCS_ES.agronomy
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async getParcels({ rootState }) {
      try {
        const xhr = await Api.getParcels(
          {
            farm: rootState.registerFarm.farm,
            without_geometry: true,
          },
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getParcelsGeometries({ rootState }, withoutGeometries) {
      try {
        const xhr = await Api.getParcels(
          {
            farm: rootState.registerFarm.farm,
            without_geometry: withoutGeometries,
          },
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getFarmGeoFeature({ rootState }, id = null) {
      try {
        const xhr = await Api.getFarmGeoFeature(
          id || rootState.registerFarm.farmId,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getFarmGeoFeatureByCode({ rootState }) {
      try {
        const xhr = await Api.getFarmGeoFeatureByCode(
          rootState.registerFarm.farm,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getFarmsGeometries({ rootState }) {
      try {
        const xhr = await Api.getTreeLocation(
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getSamplesGeometry({ rootState }, params) {
      try {
        const xhr = await Api.getSamplesWithGeometry(
          rootState.contact.company,
          rootState.contact.address,
          params
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getFarmsGeometriesWithFilters({ rootState }, params) {
      try {
        const xhr = await Api.getFarmsWithFilters(
          rootState.contact.company,
          rootState.contact.address,
          params
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async boundsWMSLayer({ commit }, currentOverlayLayer) {
      try {
        const requestOptions = {
          method: 'GET',
          redirect: 'follow',
        };
        const layerId = currentOverlayLayer.split(':');
        const url = `${Api.getGeoServer()}/getbbox/?ws=${
          layerId[0]
        }&layer=${layerId[1]}&ds=undefined`;
        await fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            commit('setCurrentBoundsWMSLayer', JSON.parse(result));
          })
          .catch((error) => Promise.reject(error));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error); // Esta llamada no tiene los mensajes de error normalizados
      }
    },
    async fetchDatesLayer({ commit }, layer) {
      try {
        const requestOptions = {
          method: 'GET',
          redirect: 'follow',
        };
        const url = `${Api.getGeoServerRasterDates()}?layer=${layer}&server=${Api.getBaseUrlGis()}&ws=${Api.getGeoServerWorkspace()}`;
        await fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            commit('setDatesLayer', JSON.parse(result));
          })
          .catch((error) => Promise.reject(error));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async fetchFeatureInfo({ commit }, { queryParams }) {
      try {
        const requestOptions = {
          method: 'GET',
          redirect: 'follow',
        };
        const url = `${Api.getGeoServerPort()}/${queryParams}`;
        await fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            commit('setFeatureInfo', JSON.parse(result));
          })
          .catch((error) => Promise.reject(error));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error); // Esta llamada no tiene los mensajes de error normalizados
      }
    },
    async fetchTimelineIndexes(
      { commit, rootState },
      { location, initDate, endDate, index }
    ) {
      try {
        const xhr = await Api.fetchTimelineIndexes(
          location,
          initDate,
          endDate,
          index,
          rootState.contact.company,
          rootState.contact.address
        );
        const result = JSON.parse(xhr.response).data;

        commit('setTimelineIndexes', result);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async fetchTimelineIndexesWMS(
      { commit, rootState },
      { location, initDate, endDate, index }
    ) {
      try {
        const xhr = await Api.fetchTimelineIndexes(
          location,
          initDate,
          endDate,
          index,
          rootState.contact.company,
          rootState.contact.address
        );
        const result = JSON.parse(xhr.response).data;

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    setTimelineIndexesWMS({ commit }, value) {
      commit('setTimelineIndexesWMS', value);
    },
    setTimelineIndexes({ commit }, value) {
      commit('setTimelineIndexes', value);
    },
    setCurrentOverlayLayer(context, value) {
      context.commit('setCurrentOverlayLayer', value);
    },
    setCurrentInitIntervalDates(
      { commit },
      { key, newCurrentInitIntervalDate }
    ) {
      commit('setCurrentInitIntervalDates', {
        key,
        newCurrentInitIntervalDate,
      });
    },
    setCurrentIndexes({ commit }, { key, newCurrentIndex }) {
      commit('setCurrentIndexes', { key, newCurrentIndex });
    },
    setCurrentEndIntervalDates(
      { commit },
      { key, newCurrentEndIntervalDate }
    ) {
      commit('setCurrentEndIntervalDates', {
        key,
        newCurrentEndIntervalDate,
      });
    },
    setSelectedIndex({ commit }, value) {
      commit('setSelectedIndex', value);
    },
  },
  mutations: {
    setCurrentBoundsWMSLayer(state, newCurrentBoundsWMSLayer) {
      state.currentBoundsWMSLayer = newCurrentBoundsWMSLayer;
    },
    setTreeLocationData(state, value) {
      state.treeLocationData = value;
    },
    setDatesLayer(state, newDatesLayer) {
      state.datesList = newDatesLayer;
    },
    setFeatureInfo(state, newFeatureInfor) {
      state.featureInfo = newFeatureInfor;
    },
    setCurrentOverlayLayer(state, value) {
      state.currentOverlayLayer = value;
    },
    setSelectedIndex(state, value) {
      state.selectedIndex = value;
    },
    setTimelineIndexesWMS(state, value) {
      state.timeLineIndexesWMS = value;
    },
    setCurrentInitIntervalDates(
      state,
      { key, newCurrentInitIntervalDate }
    ) {
      state.currentInitIntervalDates[
        key
      ] = newCurrentInitIntervalDate;
    },
    setCurrentIndexes(state, { key, newCurrentIndex }) {
      state.currentIndexes[key] = newCurrentIndex;
    },
    setCurrentEndIntervalDates(
      state,
      { key, newCurrentEndIntervalDate }
    ) {
      state.currentEndIntervalDates[key] = newCurrentEndIntervalDate;
    },
    setCurrentGeojsonFeature(state, newCurrentGeojsonFeature) {
      state.currentGeojsonFeature = newCurrentGeojsonFeature;
    },
    setCurrentLayersMap(state, value) {
      state.currentLayersMap = value;
    },
    setGeoJsonPoints(state, value) {
      state.geoJsonPoints.features.push(value);
    },
    setTimelineIndexes(state, value) {
      state.timelineIndexes = value;
    },
    removeGeoJsonPoint(state, value) {
      state.geoJsonPoints.features = state.geoJsonPoints.features.filter(
        (feature) => {
          return feature.properties.station_id !== value;
        }
      );
    },
    clearGeoJsonPoints(state, value) {
      state.geoJsonPoints.features = [];
    },
  },
};
