import { render, staticRenderFns } from "./offers-details-page.vue?vue&type=template&id=ea65f43a&scoped=true"
import script from "./offers-details-page.vue?vue&type=script&lang=js"
export * from "./offers-details-page.vue?vue&type=script&lang=js"
import style0 from "./offers-details-page.vue?vue&type=style&index=0&id=ea65f43a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea65f43a",
  null
  
)

export default component.exports