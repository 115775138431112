<template>
  <div>
    <div>
      <span
        id="pop-up-info"
        style="color: black"
        @mouseenter="togglePopUpInfoDotsVisible"
        @mouseleave="togglePopUpInfoDotsVisible"
      >
        <i class="f7-icons standardIconWidth"> info_circle_fill </i>
      </span>
    </div>
    <DxPopover
      :visible="visible"
      :animation="animationConfig"
      target="#pop-up-info"
      :position="position"
    >
      <slot />
    </DxPopover>
  </div>
</template>
<script>
import { DxPopover } from 'devextreme-vue/popover';

export default {
  components: {
    DxPopover,
  },
  props: {
    position: {
      type: String,
      default: 'top',
    },
  },

  data() {
    return {
      visible: false,
      animationConfig: {
        show: {
          type: 'pop',
          from: {
            scale: 0,
          },
          to: {
            scale: 1,
          },
        },
        hide: {
          type: 'fade',
          from: 1,
          to: 0,
        },
      },
    };
  },
  computed: {
    theTarget() {
      return `#${this.target}`;
    },
  },
  methods: {
    togglePopUpInfoDotsVisible() {
      this.visible = !this.visible;
    },
  },
};
</script>
