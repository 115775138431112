var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f7-block',[_c('f7-row',[_c('f7-col',[_c('div',{staticClass:"ha-card card"},[_c('div',{staticClass:"ha-card__title"},[_vm._v("\n          "+_vm._s(_vm.$t('personal_information'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"ha-card__content"},[_c('DxForm',{staticClass:"ha-form",attrs:{"id":"user-personal-info","col-count":1,"form-data":_vm.user}},[_c('DxSimpleItem',{staticClass:"ha-form__first-name",attrs:{"validation-rules":_vm.validationRules.name,"editor-options":{ disabled: false },"data-field":"name"}},[_c('DxLabel',{attrs:{"text":_vm.$t('name')}})],1),_vm._v(" "),_c('DxSimpleItem',{staticClass:"ha-form__last-name",attrs:{"validation-rules":_vm.validationRules.surname,"editor-options":{ disabled: false },"data-field":"surname"}},[_c('DxLabel',{attrs:{"text":_vm.$t('surname')}})],1),_vm._v(" "),_c('DxSimpleItem',{staticClass:"ha-form__email",attrs:{"data-field":"email","validation-rules":_vm.validationRules.email,"editor-options":{ disabled: false }}},[_c('DxLabel',{attrs:{"text":_vm.$t('email')}})],1),_vm._v(" "),_c('DxSimpleItem',{staticClass:"ha-form__phone",attrs:{"editor-options":{
                mask: '+34 000-000-000',
                maskRules: { X: /[02-9]/ },
              },"data-field":"phone"}},[_c('DxLabel',{attrs:{"text":_vm.$t('phone')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"editor-options":{
                value: _vm.user.role,
                searchEnabled: true,
                items: _vm.roles.roles,
                onValueChanged: _vm.roleChanged,
              },"validation-rules":_vm.validationRules.role,"data-field":"role","editor-type":"dxSelectBox"}},[_c('DxLabel',{attrs:{"text":_vm.$t('role')}})],1)],1)],1)])]),_vm._v(" "),_c('f7-col',[_c('div',{staticClass:"ha-card ha-card--addresses card"},[_c('div',{staticClass:"ha-card__title"},[_vm._v("\n          "+_vm._s(_vm.$t('addresses'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"ha-card__content"},[_c('AddressesTreeList')],1)])])],1),_vm._v(" "),_c('div',{staticClass:"ha-card ha-card--permissions card"},[_c('div',{staticClass:"ha-card__title"},[_vm._v("\n      "+_vm._s(_vm.$t('permissions'))+"\n    ")]),_vm._v(" "),_c('f7-row',[_c('f7-col',[_c('f7-row',[_c('f7-col',[_c('div',{staticClass:"ha-card card"},[_c('PermissionsTreeList',{attrs:{"permissions-available":_vm.agronomyPermissions,"permissions-tree-list-of":"Agronomy"}})],1)]),_vm._v(" "),_c('f7-col',[_c('div',{staticClass:"ha-card card"},[_c('PermissionsTreeList',{attrs:{"permissions-available":_vm.alimentaryPermissions,"permissions-tree-list-of":"Alimentary"}})],1)]),_vm._v(" "),_c('f7-col',[_c('div',{staticClass:"ha-card card"},[_c('PermissionsTreeList',{attrs:{"permissions-available":_vm.miningPermissions,"permissions-tree-list-of":"Mining"}})],1)]),_vm._v(" "),_c('f7-col',[_c('div',{staticClass:"ha-card card"},[_c('PermissionsTreeList',{attrs:{"permissions-available":_vm.environmentPermissions,"permissions-tree-list-of":"Environment"}})],1)])],1)],1)],1)],1),_vm._v(" "),_c('f7-row',[_c('div',{staticClass:"flex-center"},[_c('DxButton',{attrs:{"width":240,"text":_vm.$t('create_user'),"type":"success","styling-mode":"contained","disabled":_vm.areAllRequiredFieldsFullfilled},on:{"click":_vm.createNewUser}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }