<template>
  <div class="tile-container" :class="getParentType(parent)">
    <div
      :id="`parent-${parent.name}`"
      class="tile-parent"
      :class="getParentType(parent)"
    >
      {{ parent.name }}
    </div>
    <div style="display: flex; column-gap: 6px">
      <div
        v-for="(child, index) in childrenCopy"
        :id="`child-${parent.name}-${index}`"
        :key="`child-${parent.name}-${index}`"
        class="tile-child"
        :class="getChildType(child)"
        @click="onClickTileHandler(child)"
      >
        <span
          :style="childDop(child) !== '' ? 'margin-bottom: 16px' : ''"
          class="title"
          >{{ child.name }}
        </span>
        <div
          v-if="childDop(child) !== ''"
          class="tile-child-bottom"
          :class="getChildBottomType(child)"
        >
          <svg
            v-if="child.value < 0"
            width="30"
            height="25"
            viewBox="0 0 30 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.7327 21.0352L3.77232 14.0352H9.84925L9.86143 3.03516H19.604L19.604 14.0352H25.6931L14.7327 21.0352Z"
              stroke="#DC1414"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            v-if="child.value > 0"
            width="26"
            height="21"
            viewBox="0 0 26 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.9703 1.90918L24.198 8.69706H17.9729L17.9604 19.3637H7.98018V8.69706H1.74255L12.9703 1.90918Z"
              stroke="#16BE31"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <span>{{ childDop(child) }}</span>
        </div>
        <TilePopover
          :target="`#child-${parent.name}-${index}`"
          :visible="child.visible"
          :shading="true"
          :child="child"
          @closeChild="child.visible = false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TilePopover from '@/components/Agronomy/AdvancedServices/ExpertSystem/TilePopover/index.vue';

export default {
  name: 'ExpertSystemTile',
  components: {
    TilePopover,
  },
  props: {
    parent: {
      type: Object,
      default: () => {},
      required: true,
    },
    children: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      showTilePopoverData: false,
      childrenCopy: this.children,
    };
  },
  methods: {
    getParentType(parent) {
      const types = {
        1: 'tile-parent--macronutrientes',
        2: 'tile-parent--micronutrientes',
        3: 'tile-parent--fitotoxicos',
        4: 'tile-parent--salinity',
        5: 'tile-parent--fertilizacion',
        6: 'tile-parent--leaching',
        7: 'tile-parent--phytotoxicity',
      };
      return types[parent.type];
    },
    onClickTileHandler(child) {
      child.visible = true;
    },
    getChildType(child) {
      const types = {
        1: 'tile-child--adecuado',
        2: 'tile-child--alerta',
        3: 'tile-child--alarma',
        4: 'tile-child--empty',
      };
      return types[child.type];
    },
    getChildBottomType(child) {
      const types = {
        1: 'tile-child--adecuado',
        2: 'tile-child--alerta',
        3: 'tile-child--alarma',
      };
      return types[child.type];
    },
    childDop(child) {
      if (
        [1, 2, 3].includes(this.parent.type) &&
        [2, 3].includes(child.type)
      ) {
        return `${child.value}%`;
      }
      return '';
    },
  },
};
</script>

<style scoped lang="scss">
@import './ExpertSystemTile.styles.scss';
</style>
