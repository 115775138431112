<template>
  <f7-app :params="f7params">
    <!--<FirstLogin v-if="isUserLoggedIn" />-->

    <ConsentGDPR />

    <LeftPanel
      v-if="
        isUserLoggedIn && !showTokenPage && !showPrivacyPolicyPage
      "
      :panel-options="options"
    />
    <FilterPanel
      v-if="
        isUserLoggedIn && !showTokenPage && !showPrivacyPolicyPage
      "
    />
    <Navbar
      v-if="
        isUserLoggedIn && !showTokenPage && !showPrivacyPolicyPage
      "
      :socket="webSocket"
    />
    <login-page
      v-if="showLogin && !showTokenPage && !showPrivacyPolicyPage"
    />
    <password-recovery-page
      v-if="
        showPasswordRecovery &&
        !showTokenPage &&
        !showPrivacyPolicyPage
      "
    />
    <f7-view
      v-if="isUserLoggedIn && !showPrivacyPolicyPage"
      main
      class="safe-areas"
      url="/"
      :reload-pages="true"
    >
    </f7-view>

    <f7-view
      v-if="showTokenPage && !showPrivacyPolicyPage"
      main
      class="safe-areas"
      url="/token-recovery"
      :reload-pages="true"
    >
    </f7-view>

    <f7-view
      v-if="showPrivacyPolicyPage"
      main
      class="safe-areas"
      url="/privacy-policy"
      :reload-pages="true"
    >
    </f7-view>
  </f7-app>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import LoginPage from '@/pages/login-page';
import PasswordRecoveryPage from '@/pages/password-recovery-page';
import Navbar from '@/components/Navbar';
import LeftPanel from '@/components/LeftPanel';
import FilterPanel from '@/components/FilterPanel/FilterPanel.vue';
import EventBus from '@/services/event-bus';
import LeftPanelOptions from '@/json/leftPanelOptions.json';
import ConsentGDPR from '@/components/PrivacyPolicy/ConsentGDPR.vue';
import { ALL_LANGUAGES, baseUrlPre } from '@/js/constants';
import { defaultLocale } from '@/i18n/index';
import { locale, loadMessages } from 'devextreme/localization';
import esMessages from '@/json/langs/es.json';
import itMessages from '@/json/langs/it.json';
import ptMessages from '@/json/langs/pt.json';
import enMessages from '@/json/langs/en.json';
import frMessages from '@/json/langs/fr.json';

import routes from '../js/routes';

export default {
  components: {
    LoginPage,
    Navbar,
    PasswordRecoveryPage,
    LeftPanel,
    FilterPanel,
    ConsentGDPR,
  },
  data() {
    return {
      // Framework7 Parameters
      f7params: {
        name: 'Besafer 2.0', // App name
        theme: 'aurora',
        // App routes
        routes,
        dialog: {
          buttonCancel: this.$t('cancel'),
        },
      },
      langsDx: {
        esMessages,
        frMessages,
        ptMessages,
        enMessages,
        itMessages,
      },
      shouldNotify: false,
      interval: null,
      canContinue: true,
      webSocket: null,
    };
  },
  computed: {
    options() {
      const options = LeftPanelOptions.filter((option) => {
        return this.enabled
          ? option
          : !option.url.includes('alert')
          ? option
          : false;
      });
      if (!this.farm) {
        const newOptions = JSON.parse(JSON.stringify(options));
        for (let index = 0; index < newOptions.length; index++) {
          if (newOptions[index].url === '/agronomy/') {
            newOptions[index].subMenu.forEach((subMenu) => {
              if (subMenu.url === '/agronomy/advanced-services') {
                delete subMenu.subMenu;
              }
            });
          }
        }

        return newOptions;
      }
      return options;
    },
    showLogin() {
      return !this.isUserLoggedIn && !this.isUserRecoveringPassword;
    },
    showPasswordRecovery() {
      return !this.isUserLoggedIn && this.isUserRecoveringPassword;
    },
    showPrivacyPolicyPage() {
      return this.showPrivacyPolicy;
    },
    ...mapGetters('user', ['userProfile']),
    ...mapState('registerFarm', ['farm']),
    ...mapState('clientMenu', ['items']),
    ...mapState('contact', ['enabled', 'company']),
    ...mapState('user', [
      'isUserLoggedIn',
      'accessToken',
      'isUserRecoveringPassword',
      'authenticatedUser',
      'languageInterface',
      'showTokenPage',
      'showPrivacyPolicy',
    ]),
  },
  beforeMount() {
    const token = this.getQueryParam('token');
    if (token) {
      setTimeout(() => {
        this.logOut();
        if (this.webSocket) {
          this.webSocket.close();
        }
      }, 0);
      this.setShowTokenPage(true);

      this.canContinue = false;
    }
  },
  async mounted() {
    // this.$f7ready(f7 => {});
    EventBus.$on('newLoggedUser', () => {
      this.setContactIndex(0);

      this.createMenuStructure();
    });
    this.setPendingNotifications(false);
    this.setPreferedLanguage();

    EventBus.$off('onChangeCompany');
    EventBus.$on('onChangeCompany', this.busListener);
    EventBus.$on('tokenRefreshed', this.realoadPage);
    // this.setShowTokenPage(true);

    const token = this.getQueryParam('token');
    if (!token) {
      this.canContinue = true;
    }

    if (!this.canContinue) return;

    this.setShowTokenPage(false);
  },
  async beforeUpdate() {
    if (!this.isUserLoggedIn) return;

    const token = this.getQueryParam('token');
    if (token) {
      this.setIsUserLoggedIn(false);
      setTimeout(() => {
        this.logOut();
        if (this.webSocket) {
          this.webSocket.close();
        }
        // this.setPreferedLanguage();
      }, 0);
      this.setShowTokenPage(true);
      return;
    }

    if (this.enabled) {
      this.setPendingNotifications(false);
    }
  },
  methods: {
    isLanguageValid(language) {
      let valid = false;
      for (const lang in ALL_LANGUAGES) {
        if (ALL_LANGUAGES[lang].value === language) {
          valid = true;
        }
      }

      return valid;
    },
    busListener() {
      if (this.webSocket) {
        this.webSocket.close();
        this.webSocket = null;
      }

      if (this.isUserLoggedIn && !this.webSocket && this.enabled) {
        this.webSocket = new WebSocket(
          `wss://${baseUrlPre}/notify?token=${this.accessToken}&company=${this.company}`
        );

        this.webSocket.onmessage = (event) => {
          this.checkReports(JSON.parse(event.data));
        };
      }
    },
    getQueryParam(queryParam) {
      const params = new Proxy(
        new URLSearchParams(window.location.search),
        {
          get: (searchParams, prop) => searchParams.get(prop),
        }
      );
      return params[queryParam];
    },
    checkReports(reports) {
      const reportsNotSeen = reports.some((report) => {
        return (
          !report.been_seen && report.company_code === this.company
        );
      });

      if (reportsNotSeen) {
        this.shouldNotify = true;
        this.setPendingNotifications(true);
      } else {
        this.shouldNotify = false;
        this.setPendingNotifications(false);
      }

      if (this.shouldNotify) {
        this.$notifyDX(
          {
            message: this.$t('pendingNotifications'),
            width: 350,
          },
          'warning',
          4000
        );
      }
    },
    setMenu() {
      if (this.items.length === 0) {
        return;
      }

      this.setAddress(this.items[0].addressCode);
      this.setCompany(this.items[0].addressCompany);
      this.setAddressName(this.items[0].name);
      this.setCompanyName(this.items[0].companyName);
      this.setMenuAddress();

      this.items.forEach((contact) => {
        if (contact.id === this.items[0].id) {
          this.setAddresses(contact.itemsData);
        }
      });
    },
    translateLanguage(value) {
      let key = '';
      for (const lang in ALL_LANGUAGES) {
        if (ALL_LANGUAGES[lang].key === value) {
          key = ALL_LANGUAGES[lang].value;
        }
      }

      return key;
    },
    setPreferedLanguage() {
      if (this.isUserLoggedIn) {
        if (this.enabled) {
          const lang = this.userProfile.language
            ? this.translateLanguage(this.userProfile.language)
            : navigator.language.substring(0, 2).toLowerCase();

          locale(`${lang}-${lang.toUpperCase()}`);
          loadMessages(this.langsDx[`${lang}Messages`]);

          setTimeout(() => {
            this.$i18n.i18next.changeLanguage(lang);
            this.updateLanguageInterface(lang);
          }, 0);
        } else {
          const lang = this.authenticatedUser.language_code
            ? this.translateLanguage(
                this.authenticatedUser.language_code
              )
            : navigator.language.substring(0, 2).toLowerCase();

          locale(`${lang}-${lang.toUpperCase()}`);
          loadMessages(this.langsDx[`${lang}Messages`]);

          setTimeout(() => {
            this.$i18n.i18next.changeLanguage(lang);
            this.updateLanguageInterface(lang);
          }, 0);
        }
      } else {
        const navigatorLanguage = navigator.language
          .substring(0, 2)
          .toLowerCase();

        setTimeout(() => {
          this.$i18n.i18next.changeLanguage(
            this.isLanguageValid(navigatorLanguage)
              ? navigatorLanguage
              : defaultLocale
          );
        }, 0);
      }
    },
    createMenuStructure() {
      if (
        this.authenticatedUser.email !== undefined &&
        this.authenticatedUser.email.toString().includes('@')
      ) {
        this.setEnabled(true);
        const { contacts } = this.authenticatedUser;

        const items = [];
        contacts.forEach((cntact, index) => {
          return cntact.companies.forEach((company) => {
            items.push(
              ...company.addresses.map((address) => {
                return {
                  id: `${company.id}_${address.code}`,
                  name: address.address,
                  companyId: company.id,
                  addressCompany: company.code,
                  companyName: company.name,
                  addressCode: address.code,
                  desc_comuna: address.desc_comuna,
                  agent: company.agent,
                  contactIndex: index,
                  itemsData: company.addresses.map((addr) => {
                    return {
                      id: `${company.id}_${addr.code}`,
                      name: addr.address,
                      companyId: company.id,
                      addressCompany: company.code,
                      country: company.country,
                      city: addr.city,
                      province: addr.province,
                      language: company.language,
                      currency: company.currency,
                      identification_number: company.identification_number,
                      fax: addr.fax,
                      agent: company.agent,
                      desc_comuna: addr.desc_comuna,
                      phone: addr.phone,
                      companyName: company.name,
                      addressCode: addr.code,
                    };
                  }),
                };
              })
            );
          });
        });

        this.setItems(items);
      } else {
        this.setEnabled(false);

        const { headquarters } = this.authenticatedUser;

        // head code => address
        // code => company

        const items = [];

        headquarters.forEach((headquarter) => {
          items.push(
            ...headquarter.companies.map((company) => {
              return {
                addressCode: headquarter.headquarter_code,
                addressName: headquarter.headquarter_name,
                name: company.name,
                companyName: company.name,
                companyCode: company.code,
              };
            })
          );
        });

        this.setItems(items);
      }
    },
    realoadPage() {
      // page reloaded
      window.location.reload();
    },
    ...mapActions('contact', [
      'setEnabled',
      'setAddresses',
      'setAddressName',
      'setAddress',
      'setCompany',
      'setCompanyName',
      'setContactIndex',
    ]),
    ...mapActions('clientMenu', ['setItems']),
    ...mapActions('notifications', [
      'getReportsNoSet',
      'setPendingNotifications',
    ]),
    ...mapActions('clientMenu', {
      setMenuAddress: 'setAddress',
    }),
    ...mapActions('user', [
      'setShowTokenPage',
      'logOut',
      'setIsUserLoggedIn',
      'setWebSocket',
      'destroyWebSocket',
      'updateLanguageInterface',
    ]),
  },
};
</script>
<style lang="scss" scoped>
.panel {
  min-width: var(--f7-panel-left-collapsed-width);
}
</style>
