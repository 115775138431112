<template>
  <div>
    <div class="tableContainer" style="padding-right: 80px">
      <f7-row class="card-body">
        <f7-col>
          <f7-row>
            <f7-col>
              <div>{{ $t('agronomySample.summary.sampleCode') }}</div>
              <div class="item-title-bold">
                {{ currentSampleSummary.code }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
        <f7-col>
          <f7-row>
            <f7-col>
              <div>
                {{ $t('agronomySample.summary.sampleType') }}
              </div>
              <div class="item-title-bold">
                {{ currentSampleSummary.sample_type }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>

        <f7-col>
          <f7-row>
            <f7-col>
              <div>{{ $t('foodSamples.analysisType') }}</div>
              <div class="item-title-bold">
                {{ currentSampleSummary.analyse_type }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
        <f7-col>
          <f7-row>
            <f7-col>
              <div>
                {{ $t('foodSamples.receptionDate') }}
              </div>
              <div class="item-title-bold">
                {{
                  $dates.formatDateDayMonthYear(
                    currentSampleSummary.reception_date
                  )
                }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
        <f7-col>
          <f7-row>
            <f7-col>
              <div>{{ $t('foodSamples.endDate') }}</div>
              <div class="item-title-bold">
                {{
                  $dates.formatDateDayMonthYear(
                    currentSampleSummary.end_date
                  )
                }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
        <f7-col>
          <f7-row>
            <f7-col>
              <div>{{ $t('agronomySample.summary.status') }}</div>
              <div class="item-title-bold">
                <div
                  :class="
                    $helpers.getStatusClass(
                      currentSampleSummary.status_code
                    )
                  "
                  style="text-align: center"
                >
                  {{ currentSampleSummary.status }}
                </div>
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
      </f7-row>

      <f7-row>
        <f7-col>
          <f7-row>
            <f7-col>
              <div
                class="btn-right margin-top"
                style="top: 5px; right: 17px"
              >
                <ContextMenu
                  :id="'btnDownloadFoodDetails'"
                  :items="items"
                />
                <!-- <DxButton
                  id="btnDownloadFoodDetails"
                  type="normal"
                  styling-mode="outlined"
                >
                  <i style="cursor: pointer">
                    <img
                      width="45"
                      height="30"
                      :src="getIconPath"
                      alt="download"
                    />

                    <DxContextMenu
                      :data-source="items"
                      target="#btnDownloadFoodDetails"
                      show-event="dxclick"
                    />
                  </i>
                </DxButton> -->
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
      </f7-row>

      <f7-row class="card-body">
        <f7-col>
          <f7-row>
            <f7-col>
              <div>
                {{ $t('agronomySample.summary.description') }}
              </div>
              <div class="item-title-bold">
                {{ currentSampleSummary.sample_name }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
        <f7-col>
          <f7-row>
            <f7-col>
              <div>{{ $t('foodSamples.groupFamily') }}</div>
              <div class="item-title-bold">
                {{ currentSampleSummary.sample_type_family }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>

        <f7-col>
          <f7-row>
            <f7-col>
              <div>{{ $t('foodSamples.projectDescription') }}</div>
              <div class="item-title-bold">
                {{ currentSampleSummary.study }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
        <f7-col>
          <f7-row>
            <f7-col>
              <div>{{ $t('foodSamples.thirdClient') }}</div>
              <div class="item-title-bold">
                {{ currentSampleSummary.third_client }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
        <f7-col>
          <f7-row>
            <f7-col>
              <div>{{ $t('agronomySample.summary.estate') }}</div>
              <div class="item-title-bold">
                {{ currentSampleSummary.farm }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
        <f7-col>
          <f7-row>
            <f7-col>
              <div>{{ $t('agronomySample.summary.parcel') }}</div>
              <div class="item-title-bold">
                {{ currentSampleSummary.parcel }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
      </f7-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import DxButton from 'devextreme-vue/button';
import DxContextMenu from 'devextreme-vue/context-menu';
import ContextMenu from '@/components/contextMenu';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  name: 'FoodSamplesDetailSummary',
  components: {
    DxButton,
    DxContextMenu,
    ContextMenu,
  },
  data() {
    return {
      currentSampleSummary: {},
    };
  },
  computed: {
    items() {
      if (this.currentSampleSummary.status_code !== '2') {
        return [
          {
            icon: 'download',
            text: `${this.$t('download')} ${this.$t(
              'generalReport'
            )}`,
            onClick: this.download,
            disabled: this.currentSampleSummary.status_code !== '3',
          },
          {
            icon: 'download',
            text: `${this.$t('download')} ${this.$t('tracesReport')}`,
            onClick: this.download,
            disabled:
              this.currentSampleSummary.status_code !== '3' ||
              !this.currentSampleSummary.traces_report,
          },
        ];
      }

      return [
        {
          icon: 'refresh',
          text: this.$t('refresh'),
          onClick: this.refresh,
        },
        {
          icon: 'download',
          text: `${this.$t('download')} ${this.$t('generalReport')}`,
          onClick: this.download,
        },
        {
          icon: 'download',
          text: `${this.$t('download')} ${this.$t('tracesReport')}`,
          onClick: this.download,
        },
      ];
    },
    getIconPath() {
      return `${
        this.environment === 'development' ? '../../' : './'
      }static/icons/options_burger.svg`;
    },
    ...mapState('contact', ['enabled']),
    ...mapState('samples', ['sampleFetchedFromEtl']),
  },
  async beforeMount() {
    try {
      this.currentSampleSummary =
        await this.getCurrentSampleBasedOnFilter();

      // if (this.currentSampleSummary.status_code == 2) {
      //   this.$notifyDX(
      //     {
      //       message: this.$t('inProcessSampleResult'),
      //       width: 550,
      //     },
      //     'warning',
      //     7000
      //   );
      // }

      if (
        this.currentSampleSummary?.status_code === '2' &&
        this.currentSampleSummary?.sample_id != null &&
        this.sampleFetchedFromEtl === false
      ) {
        this.setSampleFetchedFromEtl(true);

        this.$f7.preloader.show();
        await this.getSample(this.currentSampleSummary.sample_id);
        this.$f7.preloader.hide();

        this.$f7router.navigate(
          { name: 'FoodSampleDetailPage' },
          {
            animate: true,
            transition: 'f7-fade',
            reloadCurrent: true,
          }
        );
      }
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(error.message).error
            )
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    }
  },
  methods: {
    async refresh() {
      if (
        this.currentSampleSummary?.status_code === '2' &&
        this.currentSampleSummary?.sample_id != null
      ) {
        this.$f7.preloader.show();
        await this.getSample(this.currentSampleSummary.sample_id);
        this.$f7.preloader.hide();
      }

      this.$f7router.navigate(
        { name: 'FoodSampleDetailPage' },
        {
          animate: true,
          transition: 'f7-fade',
          reloadCurrent: true,
        }
      );
    },
    async download(e) {
      const DOC_TYPE_TRACES = 2;

      if (e.itemIndex === 0) {
        await this.downReport();
      } else if (e.itemIndex === 1) {
        await this.downReport(DOC_TYPE_TRACES);
      }
    },
    async downReport(docType = 1) {
      try {
        this.$notifyDX(
          {
            message: this.$t('downloading'),
            width: 350,
          },
          'info',
          2000
        );

        const report = await this.getReport(docType);

        if (report.name && report.pdfB64) {
          const link = document.createElement('a');
          link.href = `data:application/octet-stream;base64,${report.pdfB64}`;
          link.download = report.name;
          link.click();

          URL.revokeObjectURL(link.href);

          this.$notifyDX(
            {
              message: this.$t('downloadedFile'),
              width: 350,
            },
            'success',
            2000
          );
        } else {
          this.$notifyDX(
            {
              message: this.$t('sampleHasNoReport'),
              width: 450,
            },
            'info',
            2000
          );
        }
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t('reportNotAvailable'),
            width: 450,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      }
    },
    ...mapActions('samples', [
      'getCurrentSampleBasedOnFilter',
      'getReport',
      'setSampleFetchedFromEtl',
    ]),
    ...mapActions('riskAnalysis', ['getSample']),
  },
};
</script>
