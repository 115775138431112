export default function createLocalStoragePlugin({
  moduleName,
  localStorageName,
}) {
  return (store) => {
    // remove vuex state from localStorage
    localStorage.removeItem('vuex');

    // restore state from localStorage
    const localStorageState = localStorage.getItem(localStorageName);
    if (localStorageState !== null) {
      // load state from localStorage
      store.replaceState({
        ...store.state,
        [moduleName]: JSON.parse(localStorageState),
      });
    }

    // Called when the store is initialized
    store.subscribe((mutation, state) => {
      // Called after every mutation.
      // The mutation comes in the format of `{ type, payload }`.
      if (mutation.type.startsWith(`${moduleName}/`)) {
        // const data = state[moduleName];

        if (moduleName === 'user') {
          if (
            state[moduleName].authenticatedUser.headquarters != null
          ) {
            state[moduleName].authenticatedUser.headquarters = [];
          }

          // if (state[moduleName].authenticatedUser.contacts != null) {
          //   state[moduleName].authenticatedUser.contacts = [];
          // }
        }

        localStorage.setItem(
          localStorageName,
          JSON.stringify(state[moduleName])
        );
      }
    });
  };
}
