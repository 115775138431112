<template>
  <f7-page>
    <div v-if="loaded">
      <div class="tableContainer">
        <AlertsReportsParamsTable
          :params="params"
          :store="customStore"
        />
      </div>
    </div>
  </f7-page>
</template>

<script>
import { mapActions } from 'vuex';
import AlertsReportsParamsTable from '@/components/Alerts/Reports/ParamsTable/index.vue';
import EventBus from '@/services/event-bus';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  name: 'AlertsParamsReportsPage',
  components: {
    AlertsReportsParamsTable,
  },
  data() {
    return {
      loaded: false,
      customStore: null,
      params: {},
    };
  },
  async beforeMount() {
    this.$f7.preloader.show();
    this.setCurrentFilter('alertReportParams');

    this.setCurrentRoute([
      {
        name: this.$t('alerts'),
        disabled: false,
        page: 'AlertsParamsReportsPage',
        active: false,
      },
      {
        name: this.$t('alert.parameters'),
        disabled: false,
        page: 'AlertsParamsReportsPage',
        active: false,
      },
      {
        name: this.$t('report'),
        disabled: true,
        page: 'AlertsParamsReportsPage',
        active: true,
      },
    ]);

    try {
      this.customStore = await this.getReportsStore();

      EventBus.$on('AlertsParamsReportsPage', async (params) => {
        if (params) {
          this.params = params;
          this.customStore = await this.getReportsStoreWithFilters(
            params
          );
        } else {
          this.params = {};
          this.customStore = await this.getReportsStore();
        }
      });

      this.loaded = true;
      this.$f7.preloader.hide();
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(error.message).error
            )
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    }
  },
  methods: {
    ...mapActions('breadcrumb', ['setCurrentRoute']),
    ...mapActions('alertsParams', [
      'getReportsStore',
      'getReportsStoreWithFilters',
    ]),
    ...mapActions('filter', ['setCurrentFilter']),
  },
};
</script>
