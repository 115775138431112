<template>
  <div class="geotoolbox-tab">
    <GeotoolboxDisclosure :title="$t('geotoolbox.toolCreationTitle')">
      <template v-slot:content>
        <ToolCreation />
      </template>
    </GeotoolboxDisclosure>
    <GeotoolboxDisclosure
      :title="$t('geotoolbox.toolInterpolationTitle')"
    >
      <template v-slot:content>
        <ToolInterpolation :areas="menu.areas" />
      </template>
    </GeotoolboxDisclosure>
    <GeotoolboxDisclosure
      :title="$t('geotoolbox.toolComparationTitle')"
    >
      <template v-slot:content>
        <ToolComparation :images="menu.images" />
      </template>
    </GeotoolboxDisclosure>
  </div>
</template>

<script>
import GeotoolboxDisclosure from './GeotoolboxDisclosure.vue';
import ToolCreation from './Tools/ToolCreation.vue';
import ToolInterpolation from './Tools/ToolInterpolation.vue';
import ToolComparation from './Tools/ToolComparation.vue';

export default {
  name: 'GeoToolBoxTab',
  components: {
    GeotoolboxDisclosure,
    ToolCreation,
    ToolInterpolation,
    ToolComparation,
  },
  props: {
    menu: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped lang="scss">
@import './GeoToolBoxTab.styles.scss';
</style>
