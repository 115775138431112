import db from './db';

export default function createLocalStoragePlugin({
  moduleName,
  localStorageName,
}) {
  return async (store) => {
    let clientMenu = {
      currentAddress: '',
      items: [],
    };

    const localStorageState = localStorage.getItem(localStorageName);
    if (localStorageState !== null) {
      clientMenu = JSON.parse(localStorageState);
    }

    const contacts = db.contacts.toArray();
    const headquarters = db.headquarters.toArray();

    await Promise.all([contacts, headquarters]).then((values) => {
      const items = [...values[0], ...values[1]];

      if (items.length > 0) {
        clientMenu.items = items;

        store.replaceState({
          ...store.state,
          [moduleName]: clientMenu,
        });
      }
    });

    store.subscribe((mutation, state) => {
      if (mutation.type === 'clientMenu/setCurrentAddress') {
        localStorage.setItem(
          localStorageName,
          JSON.stringify({
            currentAddress: mutation.payload,
            items: [],
          })
        );
      }
    });
  };
}
