<template>
  <div class="main-giswms-map">
    <div class="content-gis-map">
      <div id="stations" :style="minHeight" />
    </div>
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '@fortawesome/fontawesome-free';
import { mapState, mapActions } from 'vuex';
import EventBus from '@/services/event-bus';

export default {
  name: 'GISGeoJsonMapTree',
  props: {
    zoom: { type: Number, default: 1 },
    selectedMinHeight: { type: String, default: '50vh' },
    nameMap: { type: String, default: '' },
    treeLocations: {
      type: Boolean,
      default: false,
    },
    enableStations: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentLayerBase: {},
      event: 'updateGIS',
      map: null,
      layerBounds: null,
      groupBounds: null,
      initialDate: Date.now(),
      locationProperties: {},
      actualDate: null,
      center: null,
    };
  },
  computed: {
    minHeight() {
      return `min-height: ${this.selectedMinHeight}`;
    },
    ...mapState('gis', ['osm', 'geoJsonPoints']),
    ...mapState('gisEditor', [
      'centroide',
      'currentGeoFeature',
      'currentParcelGeoFeature',
    ]),
  },
  mounted() {
    this.markers = [];
    if (this.treeLocations) {
      this.renderMap();
      this.addFuncionalities();
      this.addOverlayLayer();

      if (this.enableStations) {
        EventBus.$on('addStationMarker', (index) => {
          this.addGeoJsonMarkers(index);
        });

        EventBus.$on('removeStationMarker', (index) => {
          this.map.eachLayer((layer) => {
            if (layer.feature) {
              if (
                !this.$helpers.isEmptyObject(layer.feature.properties)
              ) {
                if (layer.feature.properties.index === index) {
                  layer.remove();
                }
              }
            }
          });
        });
      } else {
        this.addGeoJsonMarkersMIR();
      }
    }

    this.center = {
      latitude: this.centroide.lat,
      longitude: this.centroide.lng,
    };
  },
  methods: {
    /**
     * Añadimos una capa tanto de tipo GeoJson como WMS a la lista de capas activas
     */
    async addOverlayLayer() {
      this.$f7.preloader.show();
      try {
        let layer;
        let layer2;
        const layerStyles = {
          default: {
            color: 'rgba(255,0,224,0.7)',
            weight: 1,
            fillOpacity: 0.5,
          },
          active: {
            weight: 3,
            color: 'rgba(241,234,234,0.71)',
            dashArray: '',
            fillOpacity: 0.7,
          },
          selected: {
            weight: 2,
            color: 'rgba(250,250,120,0.71)',
            dashArray: '',
            fillOpacity: 0.7,
          },
        };
        let treeLocationIds = {};
        const group = L.featureGroup();
        // Add parent geo data layer
        // Add children geo data children

        layer = L.geoJson(this.currentGeoFeature).addTo(this.map);

        group.addLayer(layer);
        const bounds = layer.getBounds();
        this.layerBounds = bounds;
        treeLocationIds = {};

        this.layersEvents(layer, treeLocationIds);

        layer2 = L.geoJson(this.currentParcelGeoFeature).addTo(
          this.map
        );
        layer2.setStyle(layerStyles.active);

        treeLocationIds = {};
        this.layersEvents(layer2, treeLocationIds);

        if (this.center !== null) {
          this.map.setView(
            [this.center.latitude, this.center.longitude],
            this.zoom
          );
        } else {
          const newBounds = group.getBounds();
          this.groupBounds = newBounds;
          this.map.fitBounds(newBounds);
        }
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },

    /**
     * Centramos el mapa cuando tenemos un GeoJson
     */
    centerMapGeoJsonLayer(layer) {
      const bounds = layer.getBounds();
      this.layerBounds = bounds;
      this.map.flyToBounds(bounds);
    },

    layersEvents(target, treeLocationIds) {
      const self = this;
      target.eachLayer((layer) => {
        layer.on({
          click(e) {
            L.DomEvent.stopPropagation(e);
            self.locationProperties = {};
            // console.log('clicked');
            /** self.openLayerDataPopup(
              e.target.feature.properties,
              e,
              treeLocationIds
            ); */
          },
          dblclick(e) {},
          mouseover(e) {},
          mouseout(e) {
            self.map.closePopup(e);
          },
        });
      });
    },
    addGeoJsonMarkers() {
      const self = this;
      L.geoJSON(this.geoJsonPoints, {
        pointToLayer(feature, latlng) {
          const marker = new L.Marker(latlng, {
            draggable: false,
          });

          const purpleIcon = new L.Icon({
            iconUrl:
              'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-violet.png',
            shadowUrl:
              'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41],
          });

          marker.setIcon(purpleIcon);

          marker.bindPopup(
            `<p>${feature.properties.source_name}</p>`
          );
          return marker;
        },
      }).addTo(this.map);
    },
    addGeoJsonMarkersMIR() {
      const self = this;

      L.geoJSON(this.geoJsonPoints, {
        pointToLayer(feature, latlng) {
          const marker = new L.Marker(latlng, {
            draggable: false,
          });

          const purpleIcon = new L.Icon({
            iconUrl:
              'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-violet.png',
            shadowUrl:
              'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41],
          });

          marker.setIcon(purpleIcon);
          marker.bindPopup(`<div>
            <p>${self.$t('station')}: ${
            feature.properties.source_name
          }</p>
          <p>${self.$t('agronomySample.summary.parcel')}: ${
            feature.properties.parcel
          }</p>
          <button id="stationBtn" data-station=${
            feature.properties.code_station
          } data-parcel=${
            feature.properties.parcel
          } data-station-name=${
            feature.properties.source_name
          } class="standardBtn" style="border:0; padding: 5px; cursor: pointer" id=${
            feature.properties.station
          }>${self.$t('goTo')}</button>
          </div>`);

          return marker;
        },
      }).addTo(this.map);

      this.map.on('popupopen', () => {
        setTimeout(() => {
          const parcel = document.getElementById('stationBtn');

          const stationId = parcel.getAttribute('data-station');
          const stationName = parcel.getAttribute(
            'data-station-name'
          );
          const parcelName = parcel.getAttribute('data-parcel');

          parcel.addEventListener('click', (e) => {
            this.$f7router.navigate(
              {
                name: 'MIRDetail',
                query: {
                  station: stationId,
                  parcelName,
                  stationName,
                },
              },
              {
                animate: true,
                transition: 'f7-fade',
              }
            );
          });
        }, 0);
      });
    },

    renderMap() {
      this.map = L.map('stations');
      L.tileLayer(
        this.osm.googleHybrid.route,
        this.osm.googleHybrid.properties
      ).addTo(this.map);
      this.currentLayerBase = L.tileLayer(
        this.osm.googleHybrid.route,
        this.osm.googleHybrid.properties
      ).addTo(this.map);
      this.currentLayerBase.bringToBack();
    },

    addFuncionalities() {
      const self = this;
      try {
        L.control.scale().addTo(self.map);
        self.map.attributionControl.addAttribution(
          'Margaret from <a href="https://hispatecanalytics.com//">HispatecAnalytics SA</a>'
        );
        L.Control.zoomHome = L.Control.extend({
          options: {
            position: 'topleft',
            zoomHomeText: '<i class="f7-icons">house_fill</i>',
            zoomHomeTitle: 'Zoom home',
          },
          onAdd() {
            const controlName = 'gin-control-zoom';
            const container = L.DomUtil.create(
              'div',
              `${controlName} leaflet-bar`
            );
            const { options } = this;
            // eslint-disable-next-line no-underscore-dangle
            this._zoomHomeButton = this.createButton(
              options.zoomHomeText,
              options.zoomHomeTitle,
              `${controlName}-home`,
              container,
              this.zoomHome
            );

            return container;
          },

          zoomHome() {
            self.map.fitBounds(self.groupBounds);
          },

          createButton(html, title, className, container, fn) {
            const link = L.DomUtil.create('a', className, container);
            link.innerHTML = html;
            link.href = '#';
            link.title = title;
            L.DomEvent.on(
              link,
              'mousedown dblclick',
              L.DomEvent.stopPropagation
            )
              .on(link, 'click', L.DomEvent.stop)
              .on(link, 'click', fn, this);
            return link;
          },
        });
        // eslint-disable-next-line new-cap
        const zoomHome = new L.Control.zoomHome();
        zoomHome.addTo(self.map);
      } catch (e) {
        this.$f7.dialog.alert(e);
      }
    },
    async openLayerDataPopup(properties, e, treeLocationIds) {
      this.locationProperties = {
        ...properties,
        ...treeLocationIds,
      };
      let htmlString = `
      <div class='popup-content'>
        <div>
          <p>
          <img id="find-parcel" src="../../../static/img/find.svg"/>
          <b>${this.$t('Parcel')}:</b> ${properties.parcel}
          </p>`;
      const htmlStringSector = `
          <p>
            <img id="find-sector" src="../../../static/img/find.svg"/>
            <b>${this.$t('Sector')}:</b> ${
        properties.sector ? properties.sector : ''
      }
          </p>`;
      if (typeof treeLocationIds.sectorId !== 'undefined') {
        htmlString += htmlStringSector;
      }
      htmlString += `
        </div>
      </div>`;
      this.openPopup(htmlString, e.latlng, treeLocationIds);
    },
  },
};
</script>
<style lang="scss">
@import './Map.styles.scss';
</style>
