<template>
  <div class="content-gis-editor">
    <div :id="nameMap" :style="minHeight" />
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '@fortawesome/fontawesome-free';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import turf from 'turf';
import * as turfHelper from '@turf/helpers';
import { mapState, mapActions } from 'vuex';
import EventBus from '@/services/event-bus';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  name: 'GISEditor',
  props: {
    zoom: { type: Number, default: 1 },
    height: { type: String, default: '55vh' },
    nameMap: { type: String, default: '' },
    treeLocations: {
      type: Boolean,
      default: false,
    },
    disabledEditParcel: {
      type: Boolean,
      default: true,
    },
    disabledEditSector: {
      type: Boolean,
      default: true,
    },
    createSector: {
      type: Boolean,
      default: false,
    },
    showMarker: {
      type: Boolean,
      default: false,
    },
    showHomeButton: {
      type: Boolean,
      default: true,
    },
    showGPSButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentLayerBase: {},
      event: 'updateGIS',
      map: null,
      layerBounds: null,
      parcel: null,
      sector: null,
      initialDate: Date.now(),
      layerGroup: L.layerGroup(),
      layerGroupParcel: L.layerGroup(),
      copyLayer: null,
      pmControlConfig: {
        position: 'topright',
        drawPolygon: true,
        drawCircle: false,
        drawRectangle: false,
        drawPolyline: false,
        drawMarker: false,
        editMode: false,
        dragMode: false,
        cutPolygon: false,
        removalMode: false,
        drawCircleMarker: false,
        drawText: false,
        rotateMode: false,
      },
      pmControlConfigEdit: {
        position: 'topright',
        drawPolygon: false,
        drawCircle: false,
        drawRectangle: false,
        drawPolyline: false,
        drawMarker: false,
        editMode: true,
        dragMode: false,
        cutPolygon: false,
        removalMode: true,
        drawCircleMarker: false,
        drawText: false,
        rotateMode: false,
      },
      hectareToM2: 10000,
      layerStyles: {
        default: {
          color: 'rgba(255,0,224,0.7)',
          weight: 1,
          fillOpacity: 0.5,
        },
        active: {
          weight: 3,
          color: 'rgba(241,234,234,0.71)',
          dashArray: '',
          fillOpacity: 0.7,
        },
        selected: {
          weight: 2,
          color: 'rgba(250,250,120,0.71)',
          dashArray: '',
          fillOpacity: 0.7,
        },
      },
      markerLayer: L.layerGroup(),
      layer: {},
      layer2: {},
      center: {},
    };
  },
  computed: {
    minHeight() {
      return `min-height: ${this.height}`;
    },
    ...mapState('gis', ['osm']),
    ...mapState('gisEditor', [
      'centroide',
      'currentGeoFeature',
      'currentParcelGeoFeature',
      'tempParcelGeoFeatures',
      'parcelIndex',
    ]),
    ...mapState('registerFarm', [
      'isEditingFarm',
      'isCreatingParcel',
      'isRecreatingParcel',
    ]),
  },
  beforeMount() {
    if (this.isCreatingParcel) {
      EventBus.$on('geoFeaturesUpdated', () => {
        this.map.removeLayer(this.layer);
        this.map.removeLayer(this.layer2);

        if (this.centroide) {
          this.center = {
            latitude: this.centroide.lat,
            longitude: this.centroide.lng,
          };
        }

        this.addUpdatedOverlayLayer();
      });
    }
  },
  beforeDestroy() {
    if (this.isCreatingParcel) {
      EventBus.$off('geoFeaturesUpdated');
    }
  },
  mounted() {
    if (this.isEditingFarm) {
      this.pmControlConfig.dragMode = false;
    } else {
      this.pmControlConfig.dragMode = !this.createSector;
    }

    this.renderMap();

    if (this.centroide) {
      this.center = {
        latitude: this.centroide.lat,
        longitude: this.centroide.lng,
      };
    }

    this.addFuncionalities();
    this.addOverlayLayer();
    this.addDrawControl();
    this.createLayer();
    this.layerEvents();
    this.layerParcelEvents();

    if (
      this.isEditingFarm &&
      this.currentParcelGeoFeature.length > 0
    ) {
      this.addAllParcels(this.tempParcelGeoFeatures);
    } else if (this.isRecreatingParcel) {
      this.addAllParcels(this.currentParcelGeoFeature);
    }
  },
  methods: {
    getCoordinates(latLngs) {
      const coords = [];
      for (const latLng of latLngs) {
        if (Array.isArray(latLng)) {
          for (const latLngInner of latLng) {
            coords.push([latLngInner.lng, latLngInner.lat]);
          }
        } else {
          coords.push([latLng.lng, latLng.lat]);
        }
      }
      return [coords];
    },
    locateUser(setCenter = false) {
      this.map
        .locate({ setView: true, maxZoom: 16 })
        .on('locationfound', (e) => {
          const marker = new L.Marker(e.latlng, {
            draggable: false,
          }).addTo(this.map);

          if (setCenter) {
            this.center = {
              latitude: e.latlng.lat,
              longitude: e.latlng.lng,
            };
          }

          const redIcon = new L.Icon({
            iconUrl:
              'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-violet.png',
            shadowUrl:
              'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41],
          });
          marker.setLatLng(e.latlng);

          marker.setIcon(redIcon);
        })
        .on('locationerror', (error) => {
          this.map.setView(
            [41.6164989248106, -4.873517847881886],
            13
          );
        });
    },
    addUpdatedOverlayLayer() {
      this.map.setView([this.centroide.lat, this.centroide.lng], 15);

      this.layer = L.geoJson(this.currentGeoFeature, {
        pmIgnore: this.disabledEditParcel,
      }).addTo(this.map);

      this.parcel = this.layer;

      if (!this.disabledEditParcel) {
        this.layerGroup.addLayer(this.layer);
      }

      if (this.isCreatingParcel) {
        for (const children of this.currentParcelGeoFeature) {
          this.layer2 = L.geoJson(children, {
            pmIgnore: false,
          }).addTo(this.map).bindPopup(`<ul>
                <li>${this.$t('agronomySample.summary.parcel')}: ${
            children.features[0].properties.parcel_name
          }</li>
              </ul>`);

          this.layer2.setStyle(this.layerStyles.selected);
          if (!this.disabledEditSector) {
            this.layerGroup.addLayer(this.layer2);
          }
        }
      }
    },
    addAllParcels(data) {
      for (const children of data) {
        this.layer2 = L.geoJson(children, {
          pmIgnore: true,
        }).addTo(this.map).bindPopup(`<ul>
                <li>${this.$t('agronomySample.summary.parcel')}: ${
          children.features[0].properties.parcel_name
        }</li>
              </ul>`);

        this.layer2.setStyle(this.layerStyles.selected);
        if (!this.disabledEditSector) {
          this.layerGroup.addLayer(this.layer2);
        }
      }
    },

    /**
     * Añadimos una capa tanto de tipo GeoJson como WMS a la lista de capas activas
     */
    async addOverlayLayer() {
      this.$f7.preloader.show();
      try {
        if (this.treeLocations) {
          this.map.setView(
            [this.centroide.lat, this.centroide.lng],
            this.zoom
          );

          this.layer = L.geoJson(this.currentGeoFeature, {
            pmIgnore: this.disabledEditParcel,
          }).addTo(this.map);

          this.parcel = this.layer;

          if (!this.disabledEditParcel) {
            this.layerGroup.addLayer(this.layer);
          }

          if (this.isEditingFarm) {
            for (const children of this.currentParcelGeoFeature) {
              this.layer2 = L.geoJson(children, {
                pmIgnore: false,
              }).addTo(this.map);

              this.layer2.setStyle(this.layerStyles.active);
              if (!this.disabledEditSector) {
                this.layerGroup.addLayer(this.layer2);
              }
            }
          }
        }
        if (this.center !== null) {
          if (!this.isCreatingParcel) {
            this.map.setView(
              [this.center.latitude, this.center.longitude],
              this.zoom
            );
            if (this.showMarker) {
              this.setMarker();
            }
          }
        } else {
          const bounds = this.layer.getBounds();
          this.map.fitBounds(bounds);
          this.centerMapGeoJsonLayer(this.layer);
        }
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },

    /**
     * Centramos el mapa cuando tenemos un GeoJson
     */
    centerMapGeoJsonLayer(layer) {
      const bounds = layer.getBounds();
      this.layerBounds = bounds;
      this.map.flyToBounds(bounds);
    },

    renderMap() {
      this.map = L.map(this.nameMap);

      L.tileLayer(
        this.osm.googleHybrid.route,
        this.osm.googleHybrid.properties
      ).addTo(this.map);

      this.currentLayerBase = L.tileLayer(
        this.osm.googleHybrid.route,
        this.osm.googleHybrid.properties
      ).addTo(this.map);

      this.currentLayerBase.bringToBack();

      this.layerGroup.addTo(this.map);
      this.markerLayer.addTo(this.map);
      this.layerGroupParcel.addTo(this.map);

      if (
        (!this.createSector && !this.isEditingFarm) ||
        this.isCreatingParcel
      ) {
        this.map
          .locate({ setView: true, maxZoom: 16 })
          .on('locationfound', (e) => {
            const marker = new L.Marker(e.latlng, {
              draggable: false,
            }).addTo(this.map);

            if (!this.isCreatingParcel) {
              this.center = {
                latitude: e.latlng.lat,
                longitude: e.latlng.lng,
              };
            }

            const redIcon = new L.Icon({
              iconUrl:
                'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-violet.png',
              shadowUrl:
                'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
              iconSize: [25, 41],
              iconAnchor: [12, 41],
              popupAnchor: [1, -34],
              shadowSize: [41, 41],
            });
            marker.setLatLng(e.latlng);

            marker.setIcon(redIcon);
          })
          .on('locationerror', (error) => {
            this.map.setView(
              [41.6164989248106, -4.873517847881886],
              13
            );
          });
      }
    },

    setMarker() {
      const marker = new L.Marker(
        {
          lat: this.center.latitude,
          lng: this.center.longitude,
        },
        {
          draggable: false,
        }
      ).addTo(this.map);
      const redIcon = new L.Icon({
        iconUrl:
          'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-violet.png',
        shadowUrl:
          'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      });
      marker.setIcon(redIcon);
    },

    addFuncionalities() {
      const self = this;
      try {
        L.control.scale().addTo(self.map);
        self.map.attributionControl.addAttribution(
          'Margaret from <a href="https://hispatecanalytics.com//">HispatecAnalytics SA</a>'
        );

        if (this.showHomeButton) {
          this.addHomeButton();
        }
        if (this.showGPSButton) {
          this.addGPSButton();
        }
      } catch (e) {
        this.$f7.dialog.alert(e);
      }
    },

    addHomeButton() {
      const self = this;
      L.Control.zoomHome = L.Control.extend({
        options: {
          position: 'topleft',
          zoomHomeText: '<i class="f7-icons">house_fill</i>',
          zoomHomeTitle: 'Zoom home',
        },
        onAdd() {
          const controlName = 'gin-control-zoom';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          this._zoomHomeButton = this.createButton(
            options.zoomHomeText,
            options.zoomHomeTitle,
            `${controlName}-home`,
            container,
            this.zoomHome
          );

          return container;
        },

        zoomHome() {
          if (self.center !== null && self.center !== undefined) {
            if (self.$helpers.isEmptyObject(self.center)) {
              self.$notifyDX(
                {
                  message: self.$t('Gis.chooseGeometry'),
                  width: 350,
                },
                'error',
                NOTIFY_TIME_LONG
              );
            } else {
              self.map.setView(
                [self.center.latitude, self.center.longitude],
                self.zoom
              );
            }
          } else {
            self.map.fitBounds(self.layerBounds);
          }
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      const zoomHome = new L.Control.zoomHome();
      zoomHome.addTo(self.map);
    },

    addGPSButton() {
      const self = this;
      L.Control.zoomLocation = L.Control.extend({
        options: {
          position: 'topleft',
          zoomLocationText: '<i class="f7-icons">placemark_fill</i>',
          zoomLocationTitle: 'Zoom home',
        },
        onAdd() {
          const controlName = 'gin-control-zoom';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          this._zoomLocationButton = this.createButton(
            options.zoomLocationText,
            options.zoomLocationTitle,
            `${controlName}-home`,
            container,
            this.zoomLocation
          );

          return container;
        },

        zoomLocation() {
          let marker;
          self.map
            .locate({
              setView: true,
              maxZoom: 120,
            })
            .on('locationfound', (e) => {
              if (!marker) {
                marker = new L.Marker(e.latlng, {
                  draggable: false,
                }).addTo(self.markerLayer);
                const redIcon = new L.Icon({
                  iconUrl:
                    'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-violet.png',
                  shadowUrl:
                    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                  iconSize: [25, 41],
                  iconAnchor: [12, 41],
                  popupAnchor: [1, -34],
                  shadowSize: [41, 41],
                });
                marker.setIcon(redIcon);
                self.map.setZoom(self.zoom);
              } else {
                marker.setLatLng(e.latlng);
              }
            })
            .on('locationerror', (error) => {
              if (marker) {
                self.map.removeLayer(marker);
                marker = undefined;
              }
            });
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      const zoomLocation = new L.Control.zoomLocation();
      zoomLocation.addTo(self.map);
    },

    addDrawControl() {
      if (!this.treeLocations || this.createSector) {
        this.map.pm.addControls(this.pmControlConfig);
      } else if (
        this.isEditingFarm &&
        this.currentParcelGeoFeature.length > 0
      ) {
        this.map.pm.addControls({
          position: 'topright',
          drawPolygon: false,
          drawCircle: false,
          drawRectangle: false,
          drawPolyline: false,
          drawMarker: false,
          editMode: true,
          dragMode: false,
          cutPolygon: false,
          removalMode: true,
          drawCircleMarker: false,
          drawText: false,
          rotateMode: false,
          edit: {
            featureGroup: this.layer,
          },
        });
      } else {
        this.map.pm.addControls(this.pmControlConfigEdit);
      }
    },

    createLayer() {
      const self = this;
      this.map.on('pm:create', (e) => {
        try {
          self.$f7.preloader.show();
          if (this.parcel !== null && this.disabledEditParcel) {
            const sector = e.layer;

            const sectorToGeoJson = sector.toGeoJSON();
            const parcelToGeoJson = this.parcel.toGeoJSON();

            const sectorToTurf = turf.polygon(
              sectorToGeoJson.geometry.coordinates
            );

            if (
              this.isEditingFarm &&
              this.currentParcelGeoFeature.length > 0
            ) {
              if (
                self.newSectorInstersectsWithOldSectors(sectorToTurf)
              ) {
                this.map.removeLayer(sector);
                this.setParcelOverlaps(true);

                this.$notifyDX(
                  {
                    message: this.$t('parcelOverlaps'),
                    width: 450,
                  },
                  'error',
                  NOTIFY_TIME_LONG
                );
              } else {
                this.setParcelOverlaps(false);
              }
            }

            let parcelToTurf;
            if (
              parcelToGeoJson.features[0].geometry.type === 'Polygon'
            ) {
              parcelToTurf = turf.polygon(
                parcelToGeoJson.features[0].geometry.coordinates
              );
            } else {
              parcelToTurf = turfHelper.multiPolygon(
                parcelToGeoJson.features[0].geometry.coordinates
              );
            }

            const intersection = turf.intersect(
              sectorToTurf,
              parcelToTurf
            );

            const result = L.geoJson(intersection);
            result.setStyle(this.layerStyles.active);
            this.layerGroup.addLayer(result);
            this.map.removeLayer(sector);
            this.sector = result;
            this.map.pm.addControls(this.pmControlConfigEdit);
            this.setLocation(this.getNewLocation(this.sector, 0));
            this.layerEvents();
          } else {
            this.layerGroup.addLayer(e.layer);
            this.map.pm.addControls(this.pmControlConfigEdit);
            this.setLocation(this.getNewLocation(e.layer, 0));
            this.layerEvents();
          }
        } catch (error) {
          this.$f7.dialog.alert(
            this.$t(`${this.$helpers.getFilteredErrorMessage(error)}`)
          );
        } finally {
          self.$f7.preloader.hide();
        }
      });
    },
    getNewLocation(layer, index) {
      const polygon = layer.toGeoJSON();
      let coordinates = [];
      // eslint-disable-next-line no-underscore-dangle
      if (typeof layer._latlngs !== 'undefined') {
        coordinates = this.getCoordinates(layer.getLatLngs()[index]);
        coordinates[0].push(coordinates[0][0]);
      } else {
        coordinates = this.getCoordinates(
          layer.pm._layers[0].getLatLngs()[0]
        );
        coordinates[0].push(coordinates[0][0]);
      }
      return {
        newCoordinates: coordinates,
        index,
        area: turf.area(polygon) / this.hectareToM2,
        centroide: layer.getBounds().getCenter(),
        originSigpacEditor: false,
      };
    },
    newSectorInstersectsWithOldSectorsRecreating(sectorTurf) {
      let atLeastOneSectorIntersects = false;

      for (
        let i = 0;
        i <
        this.currentParcelGeoFeature.splice(0, this.parcelIndex)
          .length;
        i += 1
      ) {
        const intersection = turf.intersect(
          sectorTurf,
          turf.polygon(
            this.currentParcelGeoFeature[i].features[0].geometry
              .coordinates
          )
        );

        if (intersection) {
          atLeastOneSectorIntersects = true;
          break;
        }
      }

      return atLeastOneSectorIntersects;
    },
    newSectorInstersectsWithOldSectors(sectorTurf) {
      let atLeastOneSectorIntersects = false;

      for (let i = 0; i < this.tempParcelGeoFeatures.length; i += 1) {
        const intersection = turf.intersect(
          sectorTurf,
          turf.polygon(
            this.tempParcelGeoFeatures[i].features[0].geometry
              .coordinates
          )
        );

        if (intersection) {
          atLeastOneSectorIntersects = true;
          break;
        }
      }

      return atLeastOneSectorIntersects;
    },
    layerEvents() {
      const self = this;
      this.layerGroup.eachLayer((layer) => {
        layer.on({
          mouseout(e) {
            self.map.closePopup(e);
          },
          'pm:edit': (e) => {
            try {
              self.$f7.preloader.show();
              if (this.parcel !== null && this.disabledEditParcel) {
                const sector = e.layer;
                const sectorToGeoJson = sector.toGeoJSON();
                const parcelToGeoJson = self.parcel.toGeoJSON();
                const sectorToTurf = turf.polygon(
                  sectorToGeoJson.geometry.coordinates
                );

                if (
                  this.isEditingFarm &&
                  this.currentParcelGeoFeature.length > 0
                ) {
                  if (
                    self.newSectorInstersectsWithOldSectors(
                      sectorToTurf
                    )
                  ) {
                    this.setParcelOverlaps(true);

                    this.$notifyDX(
                      {
                        message: this.$t('parcelOverlaps'),
                        width: 450,
                      },
                      'error',
                      NOTIFY_TIME_LONG
                    );
                  } else {
                    this.setParcelOverlaps(false);
                  }
                }

                let parcelToTurf = null;
                if (this.currentGeoFeature.numberReturned) {
                  parcelToTurf = turfHelper.multiPolygon(
                    parcelToGeoJson.features[0].geometry.coordinates
                  );
                } else {
                  parcelToTurf = turf.polygon(
                    parcelToGeoJson.features[0].geometry.coordinates
                  );
                }

                const intersection = turf.intersect(
                  sectorToTurf,
                  parcelToTurf
                );

                const result = L.geoJson(intersection);
                result.setStyle(self.layerStyles.active);
                this.layerGroup.addLayer(result);

                this.sector = result;
                self.map.removeLayer(sector);

                self.layerEvents();
                self.setLocation(self.getNewLocation(this.sector, 0));
              } else {
                self.setLocation(self.getNewLocation(e.layer, 0));
              }
            } catch (error) {
              this.$f7.dialog.alert(e);
            } finally {
              self.$f7.preloader.hide();
            }
          },
          // eslint-disable-next-line no-unused-vars
          'pm:remove': (e) => {
            self.map.pm.addControls(self.pmControlConfig);
            self.resetAll({
              isCreatingParcel: self.isEditingFarm
                ? true
                : self.createSector,
            });
          },
        });
      });
    },
    layerParcelEvents() {
      const self = this;
      this.layerGroupParcel.eachLayer((layer) => {
        layer.on({
          mouseout(e) {
            self.map.closePopup(e);
          },
          'pm:edit': (e) => {
            const parcel = e.layer;
            const latlngs = parcel.getLatLngs();
            this.createTemporalLayer(parcel);
            for (let i = 0; i < latlngs[0].length; i += 1) {
              const vertexMarkers = L.marker([
                latlngs[0][i].lat,
                latlngs[0][i].lng,
              ]);
              const result = self.sector
                .getBounds()
                .contains(vertexMarkers._latlng);
              if (result) {
                self.layerGroupParcel.clearLayers();
                self.layerGroupParcel.addLayer(this.copyLayer);
                this.parcel = this.copyLayer;
                self.layerParcelEvents();
              }
            }
          },
        });
      });
    },
    openPopup(html, latlng) {
      L.popup({
        maxWidth: 40000,
        minWidth: 200,
        offset: [0, 6],
        className: 'SIGPACPopup',
      })
        .setLatLng(latlng)
        .setContent(html)
        .openOn(this.map);
    },
    createTemporalLayer(shape) {
      const geojsonFeature = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: shape.feature.geometry.coordinates,
        },
      };

      this.copyLayer = L.geoJson(geojsonFeature);
    },
    ...mapActions('gisEditor', ['setLocation', 'resetAll']),
    ...mapActions('registerFarm', ['setParcelOverlaps']),
  },
};
</script>
<style lang="scss">
@import './Map.styles.scss';
</style>
