<template>
  <div class="menu-section">
    <div class="menu-title">
      <div class="input-label-container">
        <!-- <div class="input-container">
          <input
            id="Áreas"
            v-model="generalCheckedAreas"
            type="checkbox"
            class="input"
            @change="toggleAll()"
          />
        </div> -->
        <div class="label-container">
          <label
            class="label"
            style="padding-left: 23px; white-space: nowrap"
          >
            {{ $t('geotoolbox.baseMap') }}
          </label>
        </div>
      </div>
      <button
        class="menu-title-arrow"
        type="button"
        @click="showMenu = !showMenu"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          style="width: 1.1rem; height: 1.1rem"
          :class="[showMenu ? 'rotate-180' : 'rotate-0']"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          />
        </svg>
      </button>
    </div>
    <div v-show="showMenu" class="menu-options">
      <div v-for="(item, index) of options" :key="index">
        <div class="input-label-container">
          <div class="input-container">
            <input
              :id="item.name"
              v-model="optionSelected"
              type="radio"
              class="input"
              name="mapa-base"
              :value="item.name"
              @change="changeBaseMap()"
            />
          </div>
          <div class="label-container">
            <label :for="item.name" class="label">
              {{ item.name }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/services/event-bus';

export default {
  name: 'MenuMapaBase',
  data() {
    return {
      showMenu: false,
      options: [
        {
          name: 'Google Hybrid',
          checked: true,
        },
        {
          name: 'OSM',
          checked: false,
        },
      ],
      optionSelected: 'Google Hybrid',
    };
  },
  methods: {
    changeBaseMap() {
      EventBus.$emit('change-base-map', this.optionSelected);
    },
  },
};
</script>

<style scoped lang="scss">
@import './MenuSections.styles.scss';
</style>
