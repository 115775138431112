export default {
  namespaced: true,
  state: {
    selectedSampleTypes: null,
    selectedDestinationCountries: [],
    selectedOriginCountry: null,
    selectedEuropeanOriginCountry: null,
  },
  actions: {
    setSelectedSampleTypes({ commit }, value) {
      commit('setSelectedSampleTypes', value);
    },
    setSelectedDestinationCountries({ commit }, value) {
      commit('setSelectedDestinationCountries', value);
    },
    setSelectedOriginCountry({ commit }, value) {
      commit('setSelectedOriginCountry', value);
    },
    setSelectedEuropeanOriginCountry({ commit }, value) {
      commit('setSelectedEuropeanOriginCountry', value);
    },
    clearFilters({ commit }) {
      commit('clearFilters');
    },
  },
  mutations: {
    setSelectedSampleTypes(state, value) {
      state.selectedSampleTypes = value;
    },
    setSelectedDestinationCountries(state, value) {
      state.selectedDestinationCountries = value;
    },
    setSelectedOriginCountry(state, value) {
      state.selectedOriginCountry = value;
    },
    setSelectedEuropeanOriginCountry(state, value) {
      state.selectedEuropeanOriginCountry = value;
    },
    clearFilters(state) {
      state.selectedSampleTypes = null;
      state.selectedDestinationCountries = [];
      state.selectedOriginCountry = null;
    },
  },
};
