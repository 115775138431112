<template>
  <div class="menu-section">
    <div class="menu-title">
      <div class="input-label-container">
        <div class="input-container">
          <input
            id="Imagenes"
            v-model="generalCheckedAreas"
            type="checkbox"
            class="input"
            @change="toggleAll()"
          />
        </div>
        <div class="label-container">
          <label for="Imagenes" class="label">
            {{ $t('geotoolbox.images') }}
          </label>
        </div>
      </div>
      <button
        class="menu-title-arrow"
        type="button"
        @click="showMenu = !showMenu"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          style="width: 1.1rem; height: 1.1rem"
          :class="[showMenu ? 'rotate-180' : 'rotate-0']"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          />
        </svg>
      </button>
    </div>
    <div v-show="showMenu" class="menu-options">
      <div v-for="(item, index) of images" :key="`image-${index}`">
        <div class="input-label-container">
          <div class="input-container">
            <input
              :id="`image-${index}-input`"
              v-model="item.checked"
              type="checkbox"
              class="input"
              @change="toggleImage(item)"
            />
          </div>
          <div class="label-container">
            <label :for="`image-${index}-input`" class="label">
              <span class="label-text" :title="item.name">
                {{ item.name }}
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/services/event-bus';

export default {
  name: 'MenuImage',
  props: {
    menu: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showMenu: false,
      generalCheckedAreas: true,
    };
  },
  computed: {
    images() {
      return Object.values(this.menu.images).reverse();
    },
  },
  methods: {
    toggleImage(image) {
      if (!this.generalCheckedAreas) return;

      EventBus.$emit('toggle-image', image);

      const first = this.images
        .slice()
        // .reverse()
        .find((i) => i.checked === true);

      EventBus.$emit('update-legend', first);
    },
    toggleAll() {
      this.images.forEach((image) => {
        EventBus.$emit('toggle-image', {
          ...image,
          checked:
            this.generalCheckedAreas === false
              ? false
              : image.checked,
        });
      });

      if (this.generalCheckedAreas === false) {
        EventBus.$emit('update-legend', null);
      } else {
        const first = this.images
          .slice()
          // .reverse()
          .find((i) => i.checked === true);
        EventBus.$emit('update-legend', first);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import './MenuSections.styles.scss';
</style>
