<template>
  <f7-page>
    <div v-if="loaded">
      <MiningSampleSummary />
      <br />
      <MiningSamplesDetailTable
        v-if="showDetails"
        :store="customStore"
      />

      <NotificationSpinner
        v-if="!showDetails"
        :message="$t('pendingPaymentSampleResult')"
        :alert="true"
      />

      <NotificationSpinner
        v-if="inProcess"
        :message="$t('inProcessSampleResult')"
        :warning="true"
      />

      <GoBack
        class="margin-top"
        :url-name="urlName"
        :go-to-url-name="true"
      />
    </div>
  </f7-page>
</template>

<script>
import GoBack from '@/components/UtilityComponents/GoBack/index.vue';
import MiningSampleSummary from '@/components/Mining/SamplesDetails/Summary/index.vue';
import MiningSamplesDetailTable from '@/components/Mining/SamplesDetails/Table/index.vue';
import { mapActions, mapState } from 'vuex';
import EventBus from '@/services/event-bus';
import NotificationSpinner from '@/components/NotificationSpinner';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  name: 'MiningSamplesDetailsPage',
  components: {
    GoBack,
    MiningSamplesDetailTable,
    MiningSampleSummary,
    NotificationSpinner,
  },
  data() {
    return {
      loaded: false,
      customStore: null,
      currentSampleSummary: {},
    };
  },
  computed: {
    urlName() {
      return this.lastRouteHome ? 'Home' : 'MiningSamplesPage';
    },
    showDetails() {
      return this.currentSampleSummary?.status_code !== '5';
    },
    inProcess() {
      return this.currentSampleSummary?.status_code === '2';
    },
    ...mapState('filter', ['currentSampleId']),
    ...mapState('samples', ['lastRouteHome']),
  },
  async mounted() {
    try {
      this.$f7.preloader.show();

      EventBus.$on(
        'miningDetailsFilteredSamples',
        async ({ id, params }) => {
          if (params) {
            this.customStore = await this.getStoreDetailsWithFilters({
              id,
              paramFilters: params,
            });
          } else {
            this.customStore = await this.getDetailsStore();
          }
        }
      );

      this.customStore = await this.getDetailsStore();
    } catch (error) {
      this.customStore = null;
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(error.message).error
            )
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }

    this.currentSampleSummary =
      await this.getCurrentSampleBasedOnFilter();

    // if (!this.showDetails) {
    //   this.$notifyDX(
    //     {
    //       message: this.$t('pendingPaymentSampleResult'),
    //       width: 550,
    //     },
    //     'warning',
    //     7000
    //   );
    // }
  },
  methods: {
    ...mapActions('miningSamples', [
      'getDetailsStore',
      'getStoreDetailsWithFilters',
    ]),
    ...mapActions('filter', ['setCurrentFilter']),
    ...mapActions('samples', ['getCurrentSampleBasedOnFilter']),
  },
};
</script>
