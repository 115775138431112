<template>
  <f7-page>
    <div v-if="loaded" class="tableContainer">
      <DetailsAccordion />
    </div>
    <GoBack v-if="loaded" class="margin-top" url-name="Home" />
  </f7-page>
</template>

<script>
import { mapActions } from 'vuex';
import DetailsAccordion from '@/components/Notifications/DetailsAccordion/index.vue';
import GoBack from '@/components/UtilityComponents/GoBack/index.vue';
import EventBus from '@/services/event-bus';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  name: 'NotificationsDetails',
  components: {
    DetailsAccordion,
    GoBack,
  },
  data() {
    return {
      loaded: false,
      dataSource: [],
    };
  },
  async beforeMount() {
    this.$f7.preloader.show();

    this.setCurrentRoute([
      {
        name: this.$t('notifications.detail'),
        disabled: true,
        page: 'NotificationsDetailsPage',
        active: true,
      },
    ]);
    this.setCurrentFilter('notificationsDetail');

    try {
      await this.getReports({ all: true, params: null });

      EventBus.$on('NotificationsDetails', async (params) => {
        this.$f7.preloader.show();

        if (params) {
          await this.getReports({
            all: false,
            params,
          });
        } else {
          await this.getReports({ all: true, params: null });
        }

        this.$f7.preloader.hide();
      });
      this.$f7.preloader.hide();
      this.loaded = true;
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(error.message).error
            )
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    }
  },
  methods: {
    ...mapActions('breadcrumb', ['setCurrentRoute']),
    ...mapActions('notifications', ['getReports']),
    ...mapActions('filter', ['setCurrentFilter']),
  },
};
</script>
