var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f7-page',[_c('div',[_c('GISEditorSigpac',{attrs:{"tree-locations":_vm.centroide ? true : false,"disabled-edit-parcel":false,"create-sector":_vm.centroide ? false : true,"zoom":15},on:{"disableSaveButton":_vm.disableSaveButton}}),_vm._v(" "),_c('RegisterFarmForm'),_vm._v(" "),_c('f7-row',{staticClass:"margin-top-half"},[_c('f7-col',[_c('GoBack',{attrs:{"special-case":true,"url-name":"AdvancedServicesParcelRegistrationPage","go-to":"AdvancedServicesPage"}})],1),_vm._v(" "),_c('f7-col',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('DxButton',{staticClass:"standardBtn marginTop10",attrs:{"type":"normal","styling-mode":"outlined","text":!_vm.isEditingFarm ? _vm.$t('next') : _vm.$t('save'),"disabled":_vm.saveBtnDisabled},on:{"click":() => {
              if (_vm.isEditingFarm) {
                _vm.onSaveEditFarm();
              } else {
                _vm.nextStep();
              }
            }}})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }