export default [
  {
    mining_ID: 1,
    mining_Head_ID: 0,
    name: 'Samples',
  },
  {
    mining_ID: 2,
    mining_Head_ID: 1,
    name: 'Read',
  },
];
