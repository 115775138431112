/**
 * This is just a helper to manage our backend response
 * in order to get all the error_codes happened in our request
 *
 * @param Array errorsArray
 */
const getErrors = errorsArray => {
  const errorCodes = [];
  errorsArray.forEach(error => errorCodes.push(error.code));
  return errorCodes;
};

/**
 * This is our successful response handler where we
 * can define how we manage a successful response before
 * it gets returned.
 *
 * @param AxiosResponse response
 */
export const successfulResponseHandler = response => {
  if (
    response.status === 200 ||
    response.status === 201 ||
    response.status === 204
  ) {
    return Promise.resolve(response);
  }
  return Promise.reject(response);
};

/**
 * This is our failed response handler where we define how
 * we manage the errors when something was bad with our request before it
 * gets returned.
 *
 * @param AxiosResponse error
 */
export const failedResponseHandler = error => {
  if (error.response) {
    const { errors } = error.response.data;
    let errorCodes = [];
    const httpCode = error.response.status;

    if (error.response.status) {
      switch (error.response.status) {
        case 400:
          errorCodes = getErrors(errors);
          error.response = { httpCode, errorCodes };
          break;
        case 401:
          errorCodes = getErrors(errors);
          error.response = { httpCode, errorCodes };
          break;
        case 403:
          error.response = { httpCode, errorCode: 'not-authorized' };
          break;
        case 500:
          error.response = { httpCode, errorCode: 'server-down' };
          break;
        default:
          break;
      }
    }
  } else {
    error.response = { errorCode: 'connection-refused' };
  }
  return Promise.reject(error.response);
};
