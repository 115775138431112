export default {
  namespaced: true,
  state: {
    initDate: null,
    endDate: null,
    selectedFrequency: null,
  },
  actions: {
    setInitDate({ commit }, value) {
      commit('setInitDate', value);
    },
    setEndDate({ commit }, value) {
      commit('setEndDate', value);
    },
    setSelectedFrequency({ commit }, value) {
      commit('setSelectedFrequency', value);
    },
    clearFilters({ commit }) {
      commit('clearFilters');
    },
  },
  mutations: {
    setInitDate(state, value) {
      state.initDate = value;
    },
    setEndDate(state, value) {
      state.endDate = value;
    },
    setSelectedFrequency(state, value) {
      state.selectedFrequency = value;
    },
    clearFilters(state) {
      state.initDate = null;
      state.endDate = null;
      state.selectedFrequency = null;
    },
  },
};
