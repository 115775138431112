<template>
  <DxPopup
    class="popup-phitomonitoring"
    :visible="true"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-title="true"
    title="FITOMONITOREO"
    height="auto"
    :width="850"
    :show-close-button="true"
    @hiding="close()"
  >
    <f7-block class="padding margin-half">
      <DxDataGrid
        id="gridContainer"
        :data-source="dataSource"
        :allow-column-reordering="false"
        :show-borders="true"
        key-expr="id"
        @row-inserted="RowInserted"
        @row-updated="rowUpdated"
        @row-removing="rowRemoved"
      >
        <DxSearchPanel :visible="true" />
        <DxPaging :page-size="10" />
        <DxPager
          :show-page-size-selector="true"
          :allowed-page-sizes="[5, 10, 20]"
          :show-info="true"
          info-text="{2} items"
          :visible="true"
        />
        <DxEditing
          :allow-updating="true"
          :allow-deleting="true"
          :allow-adding="true"
          mode="row"
        />

        <DxColumn data-field="campaign" caption="Campaña">
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          data-field="production_value"
          caption="Producción (kg/ha)"
        >
          <DxRequiredRule />
        </DxColumn>
        <DxColumn data-field="n" caption="N (UFs/Campaña)">
          <DxRequiredRule />
        </DxColumn>
        <DxColumn data-field="p205" caption="P205 (UFs/Campaña)">
          <DxRequiredRule />
        </DxColumn>
        <DxColumn data-field="k20" caption="k20 (UFs/Campaña)">
          <DxRequiredRule />
        </DxColumn>
        <DxColumn data-field="ca_o" caption="CaO (UFs/Campaña)">
          <DxRequiredRule />
        </DxColumn>
        <DxColumn data-field="mg_o" caption="MgO (UFs/Campaña)">
          <DxRequiredRule />
        </DxColumn>
        <DxColumn
          :width="125"
          data-field="production_quality"
          caption="Calidad"
        >
          <DxRequiredRule />
          <DxLookup :data-source="qualityList" />
        </DxColumn>
        <DxColumn
          data-field="updated_at"
          caption="Fecha de edición"
          data-type="date"
          format="dd/MM/yyyy"
          :min-width="120"
          :allow-editing="false"
        />
        <DxColumn type="buttons">
          <DxButton name="save" icon="save" text="" />
          <DxButton name="edit" icon="edit" text="" />
          <DxButton name="delete" icon="trash" text="" />
          <DxButton name="cancel" icon="remove" text="" />
        </DxColumn>
      </DxDataGrid>
    </f7-block>
  </DxPopup>
</template>

<script>
import { DxPopup } from 'devextreme-vue/popup';
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxPager,
  DxPaging,
  DxLookup,
  DxSearchPanel,
  DxButton,
  DxRequiredRule,
} from 'devextreme-vue/data-grid';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'PopupPhitomonitoring',
  components: {
    DxButton,
    DxPaging,
    DxPager,
    DxEditing,
    DxLookup,
    DxPopup,
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxRequiredRule,
  },
  data() {
    return {
      qualityList: ['Alta', 'Media', 'Mala', 'Sin Calidad'],
    };
  },
  computed: {
    ...mapState('phitomonitoringPopup', ['phitomonitoringData']),
    dataSource() {
      return (
        this.phitomonitoringData?.data?.map((item) => {
          const date = item.updated_at.split(' ')[0].split('/');
          item.updated_at = `${date[2]}/${date[1]}/${date[0]}`;
          return item;
        }) ?? []
      );
    },
  },
  async mounted() {
    await this.getPhitomonitoringData();
  },
  beforeDestroy() {
    this.cleanPhitomonitoringData();
  },
  methods: {
    ...mapActions('phitomonitoringPopup', [
      'getPhitomonitoringData',
      'createPhitomonitoringData',
      'deletePhitomonitoringData',
      'editPhitomonitoringData',
      'cleanPhitomonitoringData',
    ]),
    close() {
      this.$emit('close');
    },
    async RowInserted(e) {
      const element = await this.createPhitomonitoringData({
        campaign: e.data.campaign,
        production_value: e.data.production_value,
        production_quality: e.data.production_quality,
        n: e.data.n,
        p205: e.data.p205,
        k20: e.data.k20,
        ca_o: e.data.ca_o,
        mg_o: e.data.mg_o,
      });

      await this.getPhitomonitoringData();
    },
    async rowUpdated(e) {
      await this.editPhitomonitoringData({
        ...e.data,
        n: parseFloat(e.data.n).toFixed(3),
        p205: parseFloat(e.data.p205).toFixed(3),
        k20: parseFloat(e.data.k20).toFixed(3),
        ca_o: parseFloat(e.data.ca_o).toFixed(3),
        mg_o: parseFloat(e.data.mg_o).toFixed(3),
        production_value: parseFloat(e.data.production_value).toFixed(
          3
        ),
      });
    },
    async rowRemoved(e) {
      await this.deletePhitomonitoringData(e.data.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './PopupPhitomonitoring.styles.scss';
</style>
