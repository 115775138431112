<template>
  <div style="display: flex; justify-content: flex-end">
    <f7-input
      type="datepicker"
      class="recepDate"
      style="height: 24px; width: 180px"
      :placeholder="$t('agronomySample.chooseDates')"
      :clear-button="true"
      :close-on-select="true"
      :calendar-params="{
        value: [initDate, endDate],
        closeOnSelect: true,
        rangePicker: true,
        dateFormat: {
          month: 'numeric',
          day: 'numeric',
          year: 'numeric',
        },
      }"
      @calendar:change="calendarChange"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import format from 'date-fns/format';
import EventBus from '@/services/event-bus';

export default {
  name: 'MIRGraphDatePicker',
  data() {
    return {};
  },
  computed: {
    ...mapState('hidrosoph', ['initDate', 'endDate']),
  },
  methods: {
    formatDate(a, b) {
      return format(a, b);
    },
    calendarChange(e) {
      if (e.length === 2) {
        this.setInitDate(e[0]);
        this.setEndDate(e[1]);

        setTimeout(() => {
          EventBus.$emit('MIRGraphDetailDateChanged');
        }, 0);
      } else if (e.length === 0) {
        // TODO: Cambiar
        e[0] = '2022-03-01';
        e[1] = '2022-03-02';
        this.setInitDate('2022-03-01');
        this.setEndDate('2022-03-02');
        setTimeout(() => {
          EventBus.$emit('MIRGraphDetailDateChanged');
        }, 0);
      }
    },
    ...mapActions('hidrosoph', ['setInitDate', 'setEndDate']),
  },
};
</script>

<style lang="scss" scoped></style>
