<template>
  <div>
    <div class="tableContainer">
      <DxDataGrid
        :data-source="store"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :row-alternation-enabled="true"
        :show-borders="true"
        :show-row-lines="true"
        :no-data-text="$t('noDataTextSummaryOffers')"
        @toolbar-preparing="onToolbarPreparing"
        @contentReady="onInit"
      >
        <DxColumn caption="Código" data-field="Articulo" />
        <DxColumn
          alignment="center"
          caption="Descripción"
          data-field="descripcion"
        />
        <DxColumn
          caption="Tipo de muestra"
          alignment="center"
          data-field="SubFamiliaTM"
        />
        <DxColumn
          alignment="center"
          caption="Importe neto"
          data-field="PrecioBrutoCodDivisa"
        />
        <DxSearchPanel
          :visible="true"
          :highlight-case-sensitive="true"
          :placeholder="$t('search')"
        />

        <DxPager
          :allowed-page-sizes="[10, 25, 50, 100]"
          :show-page-size-selector="true"
          info-text="{2} items"
          :visible="true"
        />
        <DxPaging :page-size="5" />
      </DxDataGrid>
    </div>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxPager,
  DxPaging,
  DxSearchPanel,
} from 'devextreme-vue/data-grid';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPager,
    DxPaging,
    DxSearchPanel,
  },
  props: {
    store: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      pageSizes: [5, 10, 15],
      editButtons: [
        {
          icon: 'edit',
          onClick: this.viewAtc,
        },
      ],
    };
  },
  computed: {
    ...mapState('Offers', ['showDetailsPopup']),
  },
  methods: {
    onInit(e) {
      this.grid = e;
      Array.from(
        document.getElementsByClassName('dx-group-cell')
      ).forEach((el) => {
        el.innerHTML = el.innerHTML.split(':')[1].trim();
      });
    },
    viewAtc() {
      this.setShowDetailsPopup(true);
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items;

      toolbarItems.forEach((item) => {
        if (item.name === 'searchPanel') {
          item.location = 'before';
        }
      });
    },
    ...mapActions('Offers', ['setShowDetailsPopup']),
  },
};
</script>
