<template>
  <div>
    <DxDataGrid
      :data-source="comparatorDs"
      :show-borders="true"
      :allow-column-reordering="false"
      :column-auto-width="true"
      :show-colon="false"
      :hover-state-enabled="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
    >
      <DxScrolling mode="standard" />
      <DxStateStoring
        :enabled="false"
        type="localStorage"
        storage-key="gisComparatorTable"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="[5, 10, 20]"
        :show-info="true"
        info-text="{2} items"
        :visible="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="300"
        :placeholder="$t('search')"
      />
      <DxExport :enabled="false" />
      <DxColumn
        data-field="farm"
        :allow-header-filtering="false"
        :caption="$t('agronomySample.summary.estate')"
        alignment="left"
      />
      <DxColumn
        data-field="parcel"
        :allow-header-filtering="false"
        :caption="$t('agronomySample.summary.parcel')"
        alignment="left"
      />
      <DxColumn
        data-field="cultive"
        :allow-header-filtering="false"
        :caption="$t('agronomySample.cultive')"
        alignment="left"
      />

      <DxColumn
        data-field="variety"
        :allow-header-filtering="false"
        :caption="$t('variety')"
        alignment="left"
      />
      <DxColumn
        data-field="attendant"
        :allow-header-filtering="false"
        :caption="$t('accountable')"
        alignment="left"
      />
      <DxColumn
        data-field="campaign"
        :allow-header-filtering="false"
        :caption="$t('campaign')"
        alignment="left"
      />
      <DxColumn
        data-field="last_sampling"
        :caption="$t('lastSampling')"
        alignment="left"
        data-type="date"
        format="dd/MM/yyyy"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field=""
        alignment="center"
        :caption="$t('left')"
        :allow-header-filtering="false"
        cell-template="leftComparatorTemplate"
      />
      <DxColumn
        data-field=""
        alignment="center"
        :caption="$t('right')"
        :allow-header-filtering="false"
        cell-template="rightComparatorTemplate"
      />

      <template #leftComparatorTemplate="{ data: farm }">
        <div>
          <DxCheckBox
            :value="farm.data.leftComparator"
            @valueChanged="comparatorChanged($event, 'left', farm)"
          />
        </div>
      </template>
      <template #rightComparatorTemplate="{ data: farm }">
        <div>
          <DxCheckBox
            :value="farm.data.rightComparator"
            @valueChanged="comparatorChanged($event, 'right', farm)"
          />
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxStateStoring,
  DxPager,
  DxPaging,
  DxScrolling,
  DxExport,
} from 'devextreme-vue/data-grid';
import { mapActions, mapState } from 'vuex';
import { DxCheckBox } from 'devextreme-vue/check-box';

export default {
  name: 'GisComparatorTable',
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxStateStoring,
    DxPager,
    DxPaging,
    DxScrolling,
    DxExport,
    DxCheckBox,
  },
  data() {
    return {
      loaded: false,
      data: [],
    };
  },
  computed: {
    ...mapState('gisComparator', ['comparatorDs']),
  },
  methods: {
    comparatorChanged(e, comparator, farm) {
      if (e.event) {
        if (comparator === 'left') {
          this.setLeftParcel({
            coordinates: farm.data.coordinates,
            name: farm.data.parcel,
            id: farm.data.parcel_id,
          });
          this.updateLeftComparator(farm.data.parcel_id);
        } else {
          this.setRightParcel({
            coordinates: farm.data.coordinates,
            name: farm.data.parcel,
            id: farm.data.parcel_id,
          });
          this.updateRightComparator(farm.data.parcel_id);
        }
      }
    },
    ...mapActions('gisComparator', [
      'updateLeftComparator',
      'updateRightComparator',
      'setLeftParcel',
      'setRightParcel',
    ]),
  },
};
</script>
<style scoped></style>
