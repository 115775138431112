/**
 * Librería propia para el formato y trabajo con fechas.
 * @author Mihai Laurentiu Iordache
 */

export default {
  formatDateDayMonthYear(originDate) {
    return new Date(originDate).toLocaleDateString('es-Es', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  },
};
