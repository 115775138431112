<template>
  <div class="main-mir-graph">
    <div style="width: 100px">
      <a
        v-if="displayFindBtn"
        style="
          display: flex;
          justify-content: flex-end;
          padding: 10px;
          left: 45%;
          position: absolute;
        "
        class="dx-link dx-icon-find dx-link-icon"
        @click="(e) => goToMirStations()"
      ></a>
    </div>
    <DxChart
      id="chart"
      :data-source="dataSource"
      @legend-click="onLegendClick"
    >
      <DxCommonSeriesSettings
        type="line"
        argument-type="datetime"
        argument-field="date"
      />

      <DxSeries
        v-if="selectedDistance.includes('10 cm')"
        value-field="SOIL_HUMIDITY_10CM"
        :name="'10cm ' + $t('soil')"
      />
      <DxSeries
        v-if="selectedDistance.includes('20 cm')"
        value-field="SOIL_HUMIDITY_20CM"
        :name="'20cm ' + $t('soil')"
      />
      <DxSeries
        v-if="selectedDistance.includes('30 cm')"
        value-field="SOIL_HUMIDITY_30CM"
        :name="'30cm ' + $t('soil')"
      />

      <DxSeries
        v-if="selectedDistance.includes('40 cm')"
        value-field="SOIL_HUMIDITY_40CM"
        :name="'40cm ' + $t('soil')"
      />
      <DxSeries
        v-if="selectedDistance.includes('50 cm')"
        value-field="SOIL_HUMIDITY_50CM"
        :name="'50cm ' + $t('soil')"
      />
      <DxSeries
        v-if="selectedDistance.includes('60 cm')"
        value-field="SOIL_HUMIDITY_60CM"
        :name="'60cm ' + $t('soil')"
      />
      <DxSeries
        v-if="selectedDistance.includes('70 cm')"
        value-field="SOIL_HUMIDITY_70CM"
        :name="'70cm ' + $t('soil')"
      />

      <DxSeries
        v-if="selectedDistance.includes('80 cm')"
        value-field="SOIL_HUMIDITY_80CM"
        :name="'80cm ' + $t('soil')"
      />
      <DxValueAxis title="Humedad (%)" />
      <DxArgumentAxis
        :value-margins-enabled="false"
        discrete-axis-division-mode="crossLabels"
      />
      <DxLegend
        vertical-alignment="bottom"
        horizontal-alignment="center"
        item-text-position="right"
      />
      <DxTitle
        :text="
          !displayFindBtn ? $t('individualSensors') : $t('mirSensors')
        "
        :horizontal-alignment="displayFindBtn ? 'left' : 'center'"
      >
        <DxFont size="14" />
      </DxTitle>
      <DxTooltip
        :enabled="true"
        :z-index="999999"
        :customize-tooltip="customizeTooltip"
        :interactive="true"
      />
    </DxChart>
  </div>
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxLegend,
  DxTitle,
  DxTooltip,
  DxFont,
  DxValueAxis,
} from 'devextreme-vue/chart';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
  name: 'TimelineGraphWMS',
  components: {
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxValueAxis,
    DxCommonSeriesSettings,
    DxLegend,
    DxTitle,
    DxTooltip,
    DxFont,
  },
  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
    displayFindBtn: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('hidrosoph', ['selectedDistance']),
  },
  methods: {
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },
    goToMirStations() {
      this.$f7router.navigate(
        { name: 'MIRStationsPage' },
        {
          animate: true,
          transition: 'f7-fade',
        }
      );
    },
    customizeTooltip(pointInfo) {
      if (this.displayFindBtn) {
        setTimeout(() => {
          document
            .getElementById('mir-graph-btn')
            .addEventListener('click', (e) => {
              this.$f7router.navigate(
                { name: 'MIRDetail' },
                {
                  animate: true,
                  transition: 'f7-fade',
                }
              );
            });
        }, 0);

        return {
          html: `<div><div class='tooltip-header'>${moment(
            pointInfo.argument
          ).format('DD-MM-YYYY')}</div><div class='tooltip-body'>
          <span class='bold'>${this.$t('index')}: </span>${
            pointInfo.seriesName
          }
          </span> </div>
           <span class='bold'>${this.$t('probe')}: </span>${
            pointInfo.point.data.source
          }<br/>
          <span class="bold">Valor:</span> ${`${pointInfo.valueText} %`}
        </span> 
                    <a
                    id="mir-graph-btn"
              style="display: flex; justify-content: flex-end"
              class="dx-link dx-icon-find dx-link-icon"
            ></a>
        </div></div></div>`,
        };
      }
      return {
        html: `<div><div class='tooltip-header'>${moment(
          pointInfo.argument
        ).format('DD-MM-YYYY')}</div><div class='tooltip-body'>
          <span class='bold'>${this.$t('index')}: </span>${
          pointInfo.seriesName
        }
          </span> </div>
           <span class='bold'>${this.$t('probe')}: </span>${
          pointInfo.point.data.source
        }<br/>
          <span class="bold">Valor:</span> ${`${pointInfo.valueText} %`}
        </span> 
        </div></div></div>`,
      };
    },
  },
};
</script>
<style scoped lang="scss">
@import './MIRGraph.styles.scss';
</style>
