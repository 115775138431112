export default {
  namespaced: true,
  state: {
    area: 0,
    parcelArea: 0,
    centroide: null,
    parcelCentroide: null,
    currentGeoFeature: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Polygon',
            coordinates: [],
          },
        },
      ],
    },
    currentParcelGeoFeature: [],
    tempParcelGeoFeatures: [],
    featureInfoSigpac: {},
    sigpacMap: '',
    parcelIndex: 0,
  },
  getters: {
    viewCentroide(state) {
      if (state.centroide === null) {
        return { lat: 0, lng: 0 };
      }
      return state.centroide;
    },
  },
  actions: {
    setLocation(
      { commit },
      {
        newCoordinates,
        index,
        area,
        properties = null,
        centroide,
        originSigpacEditor = false,
        atIndex = -1,
        type = 'Polygon',
      }
    ) {
      if (originSigpacEditor) {
        commit('setArea', area);
        commit('setCentroide', centroide);
        commit('setCurrentGeoFeature', {
          newCoordinates,
          index,
          properties,
          atIndex,
          type,
        });
      } else {
        commit('setParcelArea', area);
        commit('setParcelCentroide', centroide);
        commit('setCurrentGeoFeature', {
          newCoordinates,
          index: index + 1,
          properties,
        });
      }
    },
    setParcelIndex({ commit }, value) {
      commit('setParcelIndex', value);
    },
    setCurrentGeoFeature(
      context,
      { newCoordinates, index, atIndex }
    ) {
      context.commit('setCurrentGeoFeature', {
        newCoordinates,
        index,
        atIndex,
      });
    },
    resetCurrentGeoFeature({ commit }) {
      commit('resetCurrentGeoFeature');
    },
    resetAll({ commit }, { isCreatingParcel = false }) {
      if (isCreatingParcel) {
        commit('resetParcelArea');
        commit('resetParcelCentroide');
      } else {
        commit('resetArea');
        commit('resetCentroide');
        commit('resetCurrentGeoFeature');
      }
    },
    resetCentroide({ commit }) {
      commit('resetCentroide');
      commit('resetArea');
    },
    deleteParcelGeoFeature({ commit }) {
      commit('deleteParcelGeoFeature');
    },
    async fetchFeatureInfoSigpac({ commit }, url) {
      try {
        const requestOptions = {
          method: 'GET',
          redirect: 'follow',
        };

        await fetch(url, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            commit('setFeatureInfoSigpac', result);
          })
          .catch((error) => Promise.reject(error));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error); // Esta llamada no tiene los mensajes de error normalizados
      }
    },
    async fetchSigpacMap({ commit }, url) {
      try {
        const requestOptions = {
          method: 'GET',
          redirect: 'follow',
        };

        await fetch(url, requestOptions)
          .then((response) => {
            commit('setSigpacMap', response);
          })
          .catch((error) => Promise.reject(error));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error); // Esta llamada no tiene los mensajes de error normalizados
      }
    },
    setCentroide({ commit }, centroide) {
      commit('setCentroide', centroide);
    },
    setArea({ commit }, area) {
      commit('setArea', area);
    },
    setParcelArea({ commit }, value) {
      commit('setParcelArea', value);
    },
    setParcelCentroide({ commit }, value) {
      commit('setParcelCentroide', value);
    },
    resetParcelGeoFeature({ commit }, value) {
      commit('resetParcelGeoFeature', value);
    },
    setTempParcelGeoFeatures({ commit }, value) {
      commit('setTempParcelGeoFeatures', value);
    },
    addParcelGeoFeature({ commit }, value) {
      commit('addParcelGeoFeature', value);
    },
    resetSigpacFeatureInfo({ commit }, value) {
      commit('resetSigpacFeatureInfo', value);
    },
    replaceCurrentGeoFeature({ commit }, value) {
      commit('replaceCurrentGeoFeature', value);
    },
    resetParcelGeoFeatureStations({ commit }, value) {
      commit('resetParcelGeoFeatureStations', value);
    },
  },
  mutations: {
    setArea(state, newArea) {
      state.area = newArea;
    },
    setCentroide(state, newCentroide) {
      state.centroide = newCentroide;
    },
    setCurrentGeoFeature(
      state,
      {
        newCoordinates,
        index,
        properties = null,
        atIndex = -1,
        type = 'Polygon',
      }
    ) {
      if (index > 0) {
        state.currentParcelGeoFeature[state.parcelIndex] = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties,
              geometry: {
                type: 'Polygon',
                coordinates: newCoordinates,
              },
            },
          ],
        };
      } else if (index <= 0) {
        if (atIndex >= 0) {
          state.currentGeoFeature.features[
            index
          ].geometry.coordinates[atIndex] = newCoordinates;
        } else {
          state.currentGeoFeature.features[
            index
          ].geometry.coordinates = newCoordinates;
          state.currentGeoFeature.features[
            index
          ].geometry.type = type;
        }
      }
    },
    setParcelArea(state, value) {
      state.parcelArea = value;
    },
    setParcelIndex(state, value) {
      state.parcelIndex = value;
    },
    setParcelCentroide(state, value) {
      state.parcelCentroide = value;
    },
    resetParcelGeoFeature(state, value) {
      state.currentParcelGeoFeature = value;
    },
    resetCurrentGeoFeature(state) {
      state.currentGeoFeature = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Polygon',
              coordinates: [],
            },
          },
        ],
      };
    },
    resetArea(state) {
      state.area = 0;
    },
    resetCentroide(state) {
      state.centroide = null;
    },
    resetParcelArea(state, value) {
      state.parcelArea = value;
    },
    resetParcelCentroide(state, value) {
      state.parcelCentroide = value;
    },
    setFeatureInfoSigpac(state, newFeatureInfoSigpac) {
      state.featureInfoSigpac = newFeatureInfoSigpac;
    },
    setSigpacMap(state, newSigpacMap) {
      state.sigpacMap = newSigpacMap;
    },
    setTempParcelGeoFeatures(state, value) {
      state.tempParcelGeoFeatures = value;
    },
    addParcelGeoFeature(state, value) {
      const parcelFound = state.currentParcelGeoFeature.find(
        (parcel) =>
          parcel.features[0].properties.id ===
          value.features[0].properties.id
      );
      if (parcelFound) return;
      state.currentParcelGeoFeature.push(value);
    },
    resetSigpacFeatureInfo(state, value) {
      state.featureInfoSigpac = {};
    },
    resetParcelGeoFeatureStations(state, value = null) {
      if (value) {
        state.currentParcelGeoFeature = state.currentParcelGeoFeature.splice(
          value.min,
          value.max
        );
      } else {
        state.currentParcelGeoFeature = state.currentParcelGeoFeature.splice(
          state.currentParcelGeoFeature.length - 1
        );
      }
    },
    replaceCurrentGeoFeature(state, value) {
      state.currentGeoFeature = value;
    },
  },
};
