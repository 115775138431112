<template>
  <div>
    <div class="buttonPositionRight">
      <f7-button fill class="whiteFontColor" @click="newContact">
        {{ $t('newContact') }}
      </f7-button>
    </div>

    <DxDataGrid
      :data-source="usersInfo"
      :remote-operations="false"
      :allow-column-reordering="false"
      :row-alternation-enabled="true"
      :show-row-lines="true"
      :show-borders="true"
      @exporting="onExporting"
    >
      <DxExport :enabled="true" />
      <DxColumn
        data-field="name"
        :caption="$t('name')"
        data-type="text"
        alignment="left"
      />
      <DxColumn
        data-field="surname"
        :caption="$t('surname')"
        data-type="text"
      />

      <DxColumn
        data-field="addresses"
        :width="300"
        :allow-resizing="true"
        :caption="$t('address')"
        data-type="text"
      />

      <DxColumn
        data-field="email"
        :caption="$t('email')"
        data-type="string"
      />
      <DxColumn
        data-field="phone"
        :caption="$t('phone')"
        data-type="string"
      />
      <DxColumn
        data-field="language"
        :caption="$t('language')"
        data-type="string"
        :calculate-cell-value="translateLang"
      />
      <DxColumn
        :buttons="editButtons"
        :fixed="true"
        fixed-position="right"
        type="buttons"
        width="80"
      />

      <DxSearchPanel
        :visible="true"
        :width="300"
        :placeholder="$t('search')"
        :highlight-case-sensitive="true"
      />

      <DxPager
        :allowed-page-sizes="pageSizes"
        :show-page-size-selector="true"
        :show-info="true"
        info-text="{2} items"
        :visible="true"
      />
      <DxPaging :page-size="10" />
    </DxDataGrid>

    <NewContactPopup
      v-if="showNewContactPopup"
      :edit-data="editData"
    />
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxPager,
  DxPaging,
  DxSearchPanel,
  DxExport,
} from 'devextreme-vue/data-grid';

import NewContactPopup from '@/components/Contact/NewContactPopup/index.vue';

import users from '@/json/users.json';
import { mapActions, mapGetters, mapState } from 'vuex';

import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import EventBus from '@/services/event-bus';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPager,
    DxPaging,
    DxSearchPanel,
    DxExport,
    NewContactPopup,
  },
  data() {
    return {
      users,
      pageSizes: [5, 10, 15],
      editButtons: [
        {
          icon: 'edit',
          onClick: this.editUser,
        },
        {
          icon: 'trash',
          onClick: this.deleteUserFromCompany,
        },
      ],
      editData: {},
    };
  },
  computed: {
    ...mapGetters('company', ['usersInfo']),
    ...mapGetters('user', ['isAdmin']),
    ...mapState('contact', ['showNewContactPopup']),
  },
  beforeMount() {
    EventBus.$on('ProfilePage', async () => {
      if (!this.isAdmin) {
        this.$f7.preloader.hide();
        const MESSAGE = this.$t(
          'error_user_no_permission_to_show_contacts'
        );
        this.$notifyDX(
          {
            MESSAGE,
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
        return;
      }
      try {
        this.$f7.preloader.show();
        await this.getUsers();
      } catch (e) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(e.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      } finally {
        this.$f7.preloader.hide();
      }
    });
  },
  methods: {
    async editUser(userToEdit) {
      this.setUserToEdit(userToEdit.row.data.id);

      const userData = userToEdit.row.data;

      this.setUsersPermissionsToEdit();
      this.setUsersAddressesToEdit();

      this.editData.data = {};

      this.editData.isEditing = true;
      this.editData.data.name = userData.name;
      this.editData.data.surname = userData.surname;
      this.editData.data.lang = userData.language;
      this.editData.data.phone = userData.phone;
      this.editData.data.email = userData.email;
      this.editData.data.addresses = userData.addressesCodes;
      this.editData.data.contactCode = userData.contactCode;
      this.editData.data.companyId = userData.companyId;

      this.setShowNewContactPopup(true);
    },
    deleteUserFromCompany(userToDelete) {
      this.$f7.dialog.confirm(
        `${this.$t('confirm_delete')} <b>${
          userToDelete.row.data.name
        } ${userToDelete.row.data.surname}</b>?`,
        async () => {
          this.$f7.dialog.preloader(
            `${this.$t('deleting_user')}`,
            'primary'
          );
          try {
            await this.deleteUser(userToDelete.row.data.id);
            this.$f7.dialog.close();
            this.$f7.dialog.alert(`${this.$t('user_deleted')}`);
            this.getUsers();
          } catch (e) {
            this.$notifyDX(
              {
                message: e,
                width: 350,
              },
              'error',
              NOTIFY_TIME_LONG
            );
            this.$f7.dialog.close();
          }
        }
      );
    },
    newContact() {
      this.editData = {};
      this.setShowNewContactPopup(true);
    },
    onExporting(e) {
      e.component.beginUpdate();
      e.component.columnOption('ID', 'visible', true);
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(
        `${this.$t('contactManagement')}`
      );

      exportDataGrid({
        component: e.component,
        worksheet,
      })
        .then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(
              new Blob([buffer], {
                type: 'application/octet-stream',
              }),
              `${this.$t('contactManagement')}.xlsx`
            );
          });
        })
        .then(() => {
          e.component.columnOption('ID', 'visible', false);
          e.component.endUpdate();
        });

      e.cancel = true;
    },
    ...mapActions('company', [
      'getUsers',
      'setUserToEdit',
      'setUsersPermissionsToEdit',
      'setUsersAddressesToEdit',
      'deleteUser',
    ]),
    translateLang(row) {
      return this.$t(`${row.language}`);
    },
    ...mapActions('contact', ['setShowNewContactPopup']),
  },
};
</script>

<style lang="scss" scoped></style>
