<template>
  <f7-page>
    <div v-if="loaded">
      <GeoJsonTreeLocation :tree-locations="true" />
      <RegisterStationTable :ds="ds" />

      <f7-row class="margin-top-half">
        <f7-col>
          <GoBack
            v-if="isEditingFarm"
            url-name="AdvancedServicesPage"
          />
        </f7-col>
        <f7-col style="display: flex; justify-content: flex-end">
          <DxButton
            :width="120"
            class="standardBtn marginTop15"
            type="normal"
            styling-mode="outlined"
            :text="$t('confirm')"
            @click="
              () => {
                if (isEditingFarm) {
                  onSaveEditStation();
                } else {
                  save();
                }
              }
            "
          />
        </f7-col>
      </f7-row>
    </div>
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import GeoJsonTreeLocation from '@/components/Gis/GeoJsonTreeLocation/index.vue';
import RegisterStationTable from '@/components/Agronomy/AdvancedServices/RegisterStationTable/index.vue';
import GoBack from '@/components/UtilityComponents/GoBack/index.vue';
import DxButton from 'devextreme-vue/button';
import { custom } from 'devextreme/ui/dialog';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  components: {
    GeoJsonTreeLocation,
    RegisterStationTable,
    DxButton,
    GoBack,
  },
  data() {
    return {
      ds: [],
      loaded: false,
      stationIdUpdate: '',
      allAssignedStations: 0,
    };
  },
  computed: {
    ...mapState('registerFarm', [
      'stations',
      'isEditingFarm',
      'isCreatingParcel',
      'isRecreatingParcel',
      'selectedStations',
    ]),
    ...mapState('gisEditor', ['currentParcelGeoFeature']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      this.loaded = false;

      if (this.isEditingFarm) {
        this.setCurrentRoute([
          {
            name: this.$t('Agronomy'),
            disabled: false,
            page: 'AgronomySamplesPage',
            active: false,
          },
          {
            name: this.$t('advancedServices'),
            disabled: false,
            page: 'AdvancedServicesPage',
            active: false,
          },
          {
            name: this.$t('editStation'),
            disabled: true,
            page: 'MIRStationsPage',
            active: true,
          },
        ]);
      } else if (
        this.currentParcelGeoFeature.length > 1 ||
        this.isRecreatingParcel ||
        this.isCreatingParcel
      ) {
        // register parcel
        this.setCurrentRoute([
          {
            name: this.$t('Agronomy'),
            disabled: false,
            page: 'AgronomySamplesPage',
            active: false,
          },
          {
            name: this.$t('advancedServices'),
            disabled: false,
            page: 'AdvancedServicesPage',
            active: false,
          },
          {
            name: this.$t('registerParcel'),
            disabled: false,
            page: 'AdvancedServicesParcelRegistrationPage',
            active: false,
          },
          {
            name: this.$t('registerStation'),
            disabled: true,
            page: 'MIRStationsPage',
            active: true,
          },
        ]);
      } else {
        this.setCurrentRoute([
          {
            name: this.$t('Agronomy'),
            disabled: false,
            page: 'AgronomySamplesPage',
            active: false,
          },
          {
            name: this.$t('advancedServices'),
            disabled: false,
            page: 'AdvancedServicesPage',
            active: false,
          },
          {
            name: this.$t('registerFarm'),
            disabled: false,
            page: 'AdvancedServicesManagementPage',
            active: false,
          },
          {
            name: this.$t('registerParcel'),
            disabled: false,
            page: 'AdvancedServicesParcelRegistrationPage',
            active: false,
          },
          {
            name: this.$t('registerStation'),
            disabled: true,
            page: 'MIRStationsPage',
            active: true,
          },
        ]);
      }

      if (!this.isEditingFarm) {
        this.ds = this.formatStations(
          await this.searchStationByGeometry()
        );
      }

      if (!this.isEditingFarm) {
        const res = await this.getFarmGeoFeature();
        this.setParcelId(
          res[0].children[res[0].children.length - 1].id
        );
      }

      if (this.isEditingFarm) {
        const res = await this.searchStationByGeometry();
        const assignedStations = await this.getStations();

        this.stationIdUpdate =
          assignedStations[0] && assignedStations[0].id;

        this.ds = this.assignStations(
          res,
          (assignedStations[0] && assignedStations[0].stations) || []
        );
        this.allAssignedStations =
          this.ds.length > 0
            ? this.ds.filter((station) => station.assigned).length
            : 0;
      }
    } catch (e) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(e.message).error
            )
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    } finally {
      this.loaded = true;

      this.$f7.preloader.hide();
    }
  },
  methods: {
    assignStations(allStations, assignedStations) {
      const data = [];
      allStations.forEach((station) => {
        const stationExists = data.find(
          (existStation) =>
            existStation.station_id === station.station_id
        );

        if (stationExists) {
          return;
        }

        const foundStation = assignedStations.find(
          (assignedStation) =>
            assignedStation.station_id === station.station_id
        );

        if (foundStation) data.push(foundStation);
        else data.push(station);
      });

      return data.map((el, index) => {
        el.geo_json[0].features[0].properties.index = index;

        return { ...el };
      });
    },
    formatStations(stations) {
      return stations.map((station, index) => {
        station.geo_json[0].features[0].properties.index = index;

        return {
          ...station,
        };
      });
    },
    async save() {
      try {
        this.$f7.preloader.show();
        if (this.selectedStations.length > 0) {
          await this.saveStations();
        }

        this.$nextTick(() => {
          this.$notifyDX(
            {
              message: this.$t('registerStationSuccess'),
              width: 350,
            },
            'success',
            3000
          );
        });

        this.$f7.preloader.hide();

        if (!this.isCreatingParcel) {
          const myDialog = custom({
            title: this.$t('registerParcel'),
            messageHtml: `<p style="margin-left:10px">${this.$t(
              'wantsToRegisterNewParcel'
            )}</p>`,
            buttons: [
              {
                text: this.$t('no'),
                onClick: (e) => {
                  return { buttonText: e.component.option('text') };
                },
              },
              {
                text: this.$t('yes'),
                onClick: (e) => {
                  return { buttonText: e.component.option('text') };
                },
              },
              // ...
            ],
            dragEnabled: false,
          });

          const res = await myDialog.show();

          if (res.buttonText === this.$t('yes')) {
            this.setParcelArea(null);
            this.setParcelCentroide(null);
            this.setIsRecreatingParcel(true);
            this.resetRecreateParcel();

            this.$f7router.navigate(
              {
                name: 'AdvancedServicesParcelRegistrationPage',
              },
              {
                animate: true,
                transition: 'f7-fade',
              }
            );
          } else {
            this.resetParcelGeoFeature([]);
            this.setParcelIndex(0);

            this.$f7router.navigate(
              {
                name: 'AdvancedServicesPage',
              },
              {
                animate: true,
                transition: 'f7-fade',
              }
            );
          }
        } else {
          this.setParcelArea(null);
          this.setParcelCentroide(null);
          this.resetParcelGeoFeature([]);
          this.setParcelIndex(0);

          this.$f7router.navigate(
            {
              name: 'AdvancedServicesPage',
            },
            {
              animate: true,
              transition: 'f7-fade',
            }
          );
        }
      } catch (e) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(e.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      }
    },
    async onSaveEditStation() {
      try {
        this.$f7.preloader.show();

        if (this.selectedStations.length > 0) {
          if (this.stationIdUpdate) {
            await this.updateStations(this.stationIdUpdate);
          } else {
            await this.saveStations();
          }
        } else if (
          this.selectedStations.length === 0 &&
          this.allAssignedStations > 0
        ) {
          await this.updateStations(this.stationIdUpdate);
        }

        this.$nextTick(() => {
          this.$notifyDX(
            {
              message: this.$t('editStationsSuccess'),
              width: 350,
            },
            'success',
            3000
          );
        });

        this.setParcelArea(null);
        this.setParcelCentroide(null);
        this.resetParcelGeoFeature([]);
        this.setParcelIndex(0);

        this.$f7router.navigate(
          {
            name: 'AdvancedServicesPage',
          },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(error.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      } finally {
        this.$f7.preloader.hide();
      }
    },
    ...mapActions('breadcrumb', ['setCurrentRoute']),
    ...mapActions('gis', ['getFarmGeoFeature', 'getFarmGeoFeature']),
    ...mapActions('registerFarm', [
      'postFarmStations',
      'setParcelId',
      'resetRecreateParcel',
      'setIsRecreatingParcel',
    ]),
    ...mapActions('gisEditor', [
      'setParcelArea',
      'setParcelCentroide',
      'resetParcelGeoFeature',
      'setParcelIndex',
    ]),
    ...mapActions('hidrosoph', [
      'searchStationByGeometry',
      'saveStations',
      'getStations',
      'updateStations',
    ]),
  },
};
</script>
