<template>
  <f7-page>
    <div class="filterButtonPosition" style="right: 13px">
      <f7-button
        fill
        class="whiteFontColor button-filter"
        panel-toggle="right"
      >
        <span class="ic-filter"></span> {{ $t('filter') }}
      </f7-button>
    </div>
    <div
      class="tableContainer margin-bottom-half"
      style="margin-top: 3rem"
    >
      <div
        v-if="loadingData"
        class="center-ring-spinner"
        style="height: 250px"
      >
        <svg class="ring" viewBox="25 25 50 50" stroke-width="5">
          <circle cx="50" cy="50" r="20" />
        </svg>
      </div>
      <ChartSamplesCount
        v-if="!loadingData"
        :data-source="samplesCountData"
        :samples-title="samplesTitle"
      />
    </div>
    <div class="tableContainer">
      <div
        v-if="loadingData"
        class="center-ring-spinner"
        style="height: 400px"
      >
        <svg class="ring" viewBox="25 25 50 50" stroke-width="5">
          <circle cx="50" cy="50" r="20" />
        </svg>
      </div>
      <ChartSamples
        v-if="!loadingData"
        :data-source="samplesData"
        :samples-title="samplesTitle"
        :title="unitTitle"
      />
    </div>
  </f7-page>
</template>

<script>
import ChartSamples from '@/components/Environment/Stats/chartSamples.vue';
import { mapActions, mapState } from 'vuex';
import Api from '@/services/Api';
import moment from 'moment';
import EventBus from '@/services/event-bus';
import notify from 'devextreme/ui/notify';
import ChartSamplesCount from '@/components/Environment/Stats/ChartSamplesCount.vue';

export default {
  name: 'EnvironmentStatsPage',
  components: { ChartSamples, ChartSamplesCount },
  data() {
    return {
      samplesData: [],
      samplesCountData: [],
      samplesTitle: '',
      loadingData: false,
      unitTitle: '',
    };
  },
  computed: {
    ...mapState('registerFarm', ['farm', 'farmId']),
  },
  async beforeMount() {
    this.setCurrentFilter('environmentStats');

    this.setCurrentRoute([
      {
        name: this.$t('Environment'),
        disabled: false,
        page: 'EnvironmentStatsPage',
        active: false,
      },
      {
        name: this.$t('statsConsultation'),
        disabled: true,
        page: 'EnvironmentStatsPage',
        active: true,
      },
    ]);
  },
  async mounted() {
    EventBus.$on(
      'filter-environment-stats-samples',
      async (filter) => {
        if (
          filter.fromDate == null ||
          filter.toDate == null ||
          filter?.selectedParameters?.parameter_id == null ||
          filter.selectedSampleTypes?.id == null
        ) {
          this.$notifyDX(
            {
              message: this.$t('fillRequiredFields'),
              width: 550,
            },
            'error',
            4000
          );
          return;
        }

        await this.fetchData(filter);
      }
    );
  },
  beforeDestroy() {
    EventBus.$off('filter-environment-stats-samples');
  },
  methods: {
    async fetchData(filter) {
      this.loadingData = true;

      const samplesData = {
        atc_name: 'Medio Ambiente',
        sample_type_id: filter.selectedSampleTypes.id,
        parameter_id: filter.selectedParameters.parameter_id,
        // sample_type_id: 728,
        // parameter_id: 257,
        sampling_date_before: moment(filter.toDate).format(
          'YYYY-MM-DD'
        ),
        sampling_date_after: moment(filter.fromDate).format(
          'YYYY-MM-DD'
        ),
        farm_id: filter.selectedZone?.id || null,
        parcel_id: filter.selectedSamplingPoint?.id || null,
      };

      const cmaData = {
        atc_name: 'Medio Ambiente',
        sample_type_id: filter.selectedSampleTypes.id,
        parameter_id: filter.selectedParameters.parameter_id,
        // sample_type_id: 728,
        // parameter_id: 257,
        sampling_date_before: moment(filter.toDate).format(
          'YYYY-MM-DD'
        ),
        sampling_date_after: moment(filter.fromDate).format(
          'YYYY-MM-DD'
        ),
        farm_id: filter.selectedZone?.id || null,
        parcel_id: filter.selectedSamplingPoint?.id || null,
      };

      const countData = {
        atc_name: 'Medio Ambiente',
        sample_type_id: filter.selectedSampleTypes.id,
        parameter_id: filter.selectedParameters.parameter_id,
        // sample_type_id: 728,
        // parameter_id: 257,
        sampling_date_before: moment(filter.toDate).format(
          'YYYY-MM-DD'
        ),
        sampling_date_after: moment(filter.fromDate).format(
          'YYYY-MM-DD'
        ),
        aggregation: filter.groupByMonth ? 'month' : 'day',
        farm_id: filter.selectedZone?.id || null,
        parcel_id: filter.selectedSamplingPoint?.id || null,
      };

      try {
        const samples = await this.getSamplesData(samplesData);

        const sampleType =
          filter?.selectedSampleTypes?.value?.replace(/\s/g, '_') ||
          '';
        const parameter =
          filter?.selectedParameters?.parameter?.replace(
            /\s/g,
            '_'
          ) || '';

        this.samplesTitle = `${sampleType}-${parameter}`;

        this.samplesData.push(...samples);
      } catch (error) {
        // this.$notifyDX(
        //   {
        //     message: error,
        //     width: 550,
        //   },
        //   'error',
        //   4000
        // );
      }

      try {
        const samples = await this.getCMAandRData(cmaData);

        this.samplesData.push(...samples);
      } catch (error) {
        // this.$notifyDX(
        //   {
        //     message: error,
        //     width: 550,
        //   },
        //   'error',
        //   4000
        // );
      }

      try {
        const samples = await this.getSamplesCountData(countData);

        const getName = (period) => {
          if (filter.groupByMonth) {
            const month = moment(period).format('M');
            const year = moment(period).format('YYYY');
            return `${this.$t(`months.${month}`)} ${year}`;
          }

          return moment(period).format('DD/MM/YYYY');
        };

        const formatted = samples.map((sample) => ({
          samples_count: sample.samples_count,
          period: sample.period,
          name: getName(sample.period),
        }));

        this.samplesCountData = formatted;
      } catch (error) {
        // this.$notifyDX(
        //   {
        //     message: error,
        //     width: 550,
        //   },
        //   'error',
        //   4000
        // );

        this.samplesCountData = [];
      }

      this.loadingData = false;
    },
    async getSamplesData(data) {
      try {
        const samples = await this.getSamples(data);

        this.unitTitle = samples?.[0]?.parameter?.unit ?? '';

        const formatted = samples.map((sample) => ({
          type: 1,
          date: moment(sample.sampling_date).format('DD/MM/YYYY'),
          value: sample.parameter.result,
          sampleCode: sample.sample_code,
          sample_oid: sample.sample_oid,
        }));

        return formatted;
      } catch (error) {
        this.$notifyDX(
          {
            message: error,
            width: 550,
          },
          'error',
          4000
        );

        return [];
      }
    },
    async getCMAandRData(data) {
      try {
        const samples = await this.getCMAandR(data);

        const formatted = samples.map((sample) => ({
          type: 2,
          date: moment(sample.period).format('DD/MM/YYYY'),
          cma_superior_max: sample.cma_superior_max,
          r_min_min: sample.r_min_min,
        }));

        return formatted;
      } catch (error) {
        this.$notifyDX(
          {
            message: error,
            width: 550,
          },
          'error',
          4000
        );

        return [];
      }
    },
    async getSamplesCountData(data) {
      try {
        const samples = await this.getSamplesCount(data);

        return samples;
      } catch (error) {
        this.$notifyDX(
          {
            message: error,
            width: 550,
          },
          'error',
          4000
        );

        return [];
      }
    },
    ...mapActions('breadcrumb', ['setCurrentRoute']),
    ...mapActions('filter', ['setCurrentFilter']),
    ...mapActions('documentation', ['setAtc', 'getTree']),
    ...mapActions('gis', [
      'getFarmsGeometries',
      'getSamplesGeometry',
    ]),
    ...mapActions('environmentStats', [
      'getSamples',
      'getCMAandR',
      'getSamplesCount',
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import './EnvironmentStats.styles.scss';
</style>
