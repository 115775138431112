<template>
  <f7-page>
    <div v-if="loaded">
      <div class="tableContainer">
        <AlertsTable
          :store="customStore"
          :params="params"
          show-switch
        />
      </div>
    </div>
  </f7-page>
</template>
<script>
import { mapActions } from 'vuex';
import AlertsTable from '@/components/Alerts/Settings/SampleTable/index.vue';
import EventBus from '@/services/event-bus';
import Helpers from '@/js/helpers';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  name: 'AlertsSamplePage',
  components: {
    AlertsTable,
  },
  data() {
    return {
      customStore: null,
      loaded: false,
      params: {},
    };
  },
  async beforeMount() {
    this.$f7.preloader.show();

    this.setCurrentRoute([
      {
        name: this.$t('alerts'),
        disabled: false,
        page: 'AlertsSamplePage',
        active: false,
      },
      {
        name: this.$t('sample'),
        disabled: false,
        page: 'AlertsSamplePage',
        active: false,
      },
      {
        name: this.$t('settings'),
        disabled: true,
        page: 'AlertsSamplePage',
        active: true,
      },
    ]);
    this.setCurrentFilter('alertSettingsSample');

    try {
      this.customStore = await this.getStore();

      EventBus.$on('AlertsSamplePage', async (params) => {
        if (params) {
          this.params = params;
          this.customStore = await this.getStoreWithFilters(params);
        } else {
          this.params = {};
          this.customStore = await this.getStore();
        }
      });

      EventBus.$on('newSampleAlert', async (params) => {
        if (Helpers.isEmptyObject(params)) {
          this.customStore = await this.getStore();
        } else {
          this.customStore = await this.getStoreWithFilters(params);
        }
      });

      this.loaded = true;
      this.$f7.preloader.hide();
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(error.message).error
            )
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    }
  },
  methods: {
    ...mapActions('breadcrumb', ['setCurrentRoute']),
    ...mapActions('alertsSample', [
      'getStore',
      'getStoreWithFilters',
    ]),
    ...mapActions('filter', ['setCurrentFilter']),
  },
};
</script>
<style scoped></style>
