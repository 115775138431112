<template>
  <div class="margin-top">
    <div class="label-input">
      {{ $t('agronomySample.summary.samplingDate') }}
    </div>
    <div class="recepDate marginTop5">
      <f7-input
        class="shadow"
        type="datepicker"
        :placeholder="$t('agronomySample.chooseDates')"
        :clear-button="true"
        :close-on-select="true"
        :value="selectedDate"
        :calendar-params="{
          closeOnSelect: true,
          rangePicker: true,
          dateFormat: {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
          },
        }"
        @calendar:change="setSamplingDate"
      />
    </div>

    <div class="margin-top">
      <div class="label-input">{{ $t('category') }}</div>
      <DxSelectBox
        :data-source="categoryList"
        :value="selectedCategory"
        :on-opened="openedCategory"
        :on-value-changed="categoryChanged"
        :on-content-ready="contentReady"
        display-expr="value"
        value-expr="id"
        :show-clear-button="true"
      >
      </DxSelectBox>
    </div>

    <div class="margin-top">
      <div class="label-input">{{ $t('type') }}</div>

      <DxTagBox
        :data-source="typeList"
        :multiline="true"
        :search-enabled="true"
        tag-template="tag"
        :value="selectedType"
        display-expr="value"
        value-expr="id"
        :on-value-changed="typeChanged"
        :on-opened="openedType"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>

    <div class="margin-top">
      <div class="label-input">
        {{ $t('chartValue') }}
        <div
          style="
            display: inline-block;
            position: absolute;
            right: 20px;
            margin-top: -5px;
          "
        >
          <DxRadioGroup
            :items="options"
            :value="greaterThan"
            layout="horizontal"
            value-expr="value"
            display-expr="text"
            @valueChanged="greaterThanChanged"
          />
        </div>
      </div>

      <DxNumberBox
        class="marginTop5"
        :min="0"
        :value="value"
        format="#0"
        :on-change="valueChanged"
      />
    </div>

    <div class="margin-top">
      <div class="label-input">{{ $t('diagnosisTable.unit') }}</div>

      <DxTagBox
        :data-source="unitList"
        :multiline="true"
        :search-enabled="true"
        tag-template="tag"
        :value="selectedUnit"
        display-expr="value"
        value-expr="value"
        :on-value-changed="unitChanged"
        :on-opened="openedUnit"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { DxTagBox } from 'devextreme-vue/tag-box';
import DataSource from 'devextreme/data/data_source';
import DxSelectBox from 'devextreme-vue/select-box';
import { DxLookup, DxDropDownOptions } from 'devextreme-vue/lookup';
import EventBus from '@/services/event-bus';
import DxRadioGroup from 'devextreme-vue/radio-group';
import { DxNumberBox } from 'devextreme-vue/number-box';

export default {
  name: 'PhytomonitoringFilter',
  components: {
    DxNumberBox,
    DxTagBox,
    DxSelectBox,
    DxLookup,
    DxDropDownOptions,
    DxRadioGroup,
  },
  data() {
    return {
      loaded: false,
      selectedDate: [],
      selectedCategory: null,
      selectedList: [],
      selectedType: [],
      selectedValue: [],
      selectedUnit: [],
      greaterThan: true,

      categoryList: [],
      typeList: [],
      valueList: [],
      unitList: [],

      options: [
        { text: '>', value: true },
        { text: '<', value: false },
      ],
    };
  },
  computed: {
    calculatedAgronomySamplingDate() {
      if (
        this.samplingToDate !== null &&
        this.samplingFromDate !== null
      ) {
        return [this.samplingFromDate, this.samplingToDate];
      }
      return [];
    },
    ...mapState('agronomySamples', ['allSamples']),
    ...mapState('phitomonitoringPopup', [
      'category',
      'type',
      'value',
      'unit',
      'date',
    ]),
  },
  mounted() {
    EventBus.$on('clearFiltersPhytomonitoring', () => {
      this.selectedDate = [];
      this.selectedCategory = [];
      this.selectedType = [];
      this.selectedValue = [];
      this.selectedUnit = [];
    });
  },
  methods: {
    contentReady(e) {
      const arrayNotFoundItems = Array.from(
        document.getElementsByClassName('dx-empty-message')
      );

      arrayNotFoundItems.forEach((element) => {
        if (!this.loaded) {
          element.innerHTML = this.$t('loading');
        } else {
          element.innerHTML = this.$t('dataNotAvailable');
        }
      });

      this.loaded = false;
    },

    async openedCategory(e) {
      const categories = await this.getSampleFilterPhytomonitoring({
        attribute: {
          attribute: 'category',
        },
      });

      this.categoryList = new DataSource({
        store: categories,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedType(e) {
      const attribute = {
        attribute: 'type',
      };

      if (this.category != null) {
        attribute.category_id = this.category;
      }

      const types = await this.getSampleFilterPhytomonitoring({
        attribute,
      });

      this.typeList = new DataSource({
        store: types,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedValue(e) {
      const values = await this.getSampleFilterPhytomonitoring({
        attribute: {
          attribute: 'value',
        },
      });

      this.valueList = new DataSource({
        store: values,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedUnit(e) {
      const attribute = {
        attribute: 'unit',
      };

      if (this.category != null) {
        attribute.category_id = this.category;
      }

      if (this.type != null) {
        attribute.type_id = this.type.join(',');
      }

      const units = await this.getSampleFilterPhytomonitoring({
        attribute,
      });

      this.unitList = new DataSource({
        store: units,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },

    setSamplingDate(e) {
      if (e.length === 2) {
        this.setDate([e[0], e[1]]);
        this.selectedDate = e;
      } else if (e.length === 0) {
        this.setDate([null, null]);
        this.selectedDate = [];
      }
    },
    categoryChanged(e) {
      this.setCategory(e.value);
    },
    typeChanged(e) {
      this.setType(e.value);
    },
    valueChanged(event) {
      this.setValue(parseInt(event.component._changedValue, 10));
    },
    unitChanged(e) {
      this.setUnit(e.value);
    },
    greaterThanChanged(e) {
      this.greaterThan = e.value;
      this.setGreaterThan(e.value);
    },
    ...mapActions('samples', [
      'getSampleFilter',
      'getSampleFilterPhytomonitoring',
    ]),
    ...mapActions('agronomySamples', ['getStudySatellite']),
    ...mapActions('phitomonitoringPopup', [
      'setDate',
      'setCategory',
      'setType',
      'setValue',
      'setUnit',
      'setGreaterThan',
    ]),
  },
};
</script>
