<template>
  <div>
    <div class="tableContainer">
      <div class="item-title-bold no-margin no-padding"></div>
      <f7-row class="card-body">
        <f7-col>
          <f7-row>
            <f7-col>
              <div>{{ $t('agronomySample.summary.sampleCode') }}</div>
              <div class="item-title-bold">
                {{ currentSampleSummary.code }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
        <f7-col>
          <f7-row>
            <f7-col>
              <div>
                {{ $t('agronomySample.summary.sampleType') }}
              </div>
              <div class="item-title-bold">
                {{ currentSampleSummary.sample_type }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>

        <f7-col>
          <f7-row>
            <f7-col> </f7-col>
          </f7-row>
        </f7-col>
        <f7-col>
          <f7-row>
            <f7-col>
              <div></div>
              <div class="item-title-bold"></div>
            </f7-col>
          </f7-row>
        </f7-col>
        <f7-col>
          <f7-row>
            <f7-col style="text-align: right">
              <ContextMenu
                :id="'btnDownloadFoodDetails'"
                :items="items"
              />
              <!-- <DxButton
                id="btnDownloadFoodDetails"
                type="normal"
                styling-mode="outlined"
              >
                <i class="" style="cursor: pointer">
                  <img
                    width="45"
                    height="30"
                    :src="getIconPath"
                    alt="download"
                  />

                  <DxContextMenu
                    :data-source="items"
                    target="#btnDownloadFoodDetails"
                    show-event="dxclick"
                  />
                </i>
              </DxButton> -->
            </f7-col>
          </f7-row>
        </f7-col>
      </f7-row>

      <f7-row class="card-body">
        <f7-col>
          <f7-row>
            <f7-col>
              <div>
                {{ $t('agronomySample.summary.description') }}
              </div>
              <div class="item-title-bold">
                {{ currentSampleSummary.sample_name }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
        <f7-col>
          <f7-row>
            <f7-col>
              <div>{{ $t('agronomySample.summary.client') }}</div>
              <div class="item-title-bold">
                {{ currentSampleSummary.company }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>

        <f7-col>
          <f7-row>
            <f7-col>
              <div>{{ $t('agronomySample.summary.estate') }}</div>
              <div class="item-title-bold">
                {{ currentSampleSummary.farm }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
        <f7-col>
          <f7-row>
            <f7-col>
              <div>{{ $t('agronomySample.summary.parcel') }}</div>
              <div class="item-title-bold">
                {{ currentSampleSummary.parcel }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>

        <f7-col>
          <f7-row>
            <f7-col>
              <div>{{ $t('agronomySample.summary.status') }}</div>
              <div class="item-title-bold">
                <div
                  :class="
                    $helpers.getStatusClass(
                      currentSampleSummary.status_code
                    )
                  "
                  style="text-align: center"
                >
                  {{ currentSampleSummary.status }}
                </div>
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
      </f7-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DxButton from 'devextreme-vue/button';
import DxContextMenu from 'devextreme-vue/context-menu';
import ContextMenu from '@/components/contextMenu';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  name: 'AgronomySampleSummary',
  components: {
    DxButton,
    DxContextMenu,
    ContextMenu,
  },
  data() {
    return {
      currentSampleSummary: {},
    };
  },
  computed: {
    items() {
      if (this.currentSampleSummary.status_code !== '2') {
        return [
          {
            icon: 'download',
            text: `${this.$t('download')}`,
            onClick: this.download,
            disabled: this.currentSampleSummary.status_code !== '3',
          },
          // {
          //   icon: 'find',
          //   text: `GeoToolBox`,
          //   onClick: this.goToGeoToolBox,
          //   disabled: false,
          // },
        ];
      }

      return [
        {
          icon: 'refresh',
          text: this.$t('refresh'),
          onClick: this.refresh,
        },
        {
          icon: 'download',
          text: `${this.$t('download')}`,
          onClick: this.download,
          disabled: this.currentSampleSummary.status_code !== '3',
        },
        // {
        //   icon: 'find',
        //   text: `GeoToolBox`,
        //   onClick: this.goToGeoToolBox,
        //   disabled: false,
        // },
      ];
    },
    getIconPath() {
      return `${
        this.environment === 'development' ? '../../' : './'
      }static/icons/options_burger.svg`;
    },
    ...mapState('contact', ['enabled']),
    ...mapState('samples', ['sampleFetchedFromEtl']),
    ...mapState('breadcrumb', { itemsBreadcrumb: 'items' }),
  },
  async beforeMount() {
    if (
      this.itemsBreadcrumb[this.itemsBreadcrumb.length - 1].page ===
      'GisActionsPage'
    ) {
      this.popRoute();
    }

    try {
      this.currentSampleSummary =
        await this.getCurrentSampleBasedOnFilter();

      // if (this.currentSampleSummary.status_code === '2') {
      //   this.$notifyDX(
      //     {
      //       message: this.$t('inProcessSampleResult'),
      //       width: 550,
      //     },
      //     'warning',
      //     7000
      //   );
      // }

      if (
        this.currentSampleSummary?.status_code === '2' &&
        this.currentSampleSummary?.sample_id != null &&
        this.sampleFetchedFromEtl === false
      ) {
        this.setSampleFetchedFromEtl(true);

        this.$f7.preloader.show();
        await this.getSample(this.currentSampleSummary.sample_id);
        this.$f7.preloader.hide();

        this.$f7router.navigate(
          { name: 'AgronomySamplesDetailsPage' },
          {
            animate: true,
            transition: 'f7-fade',
            reloadCurrent: true,
          }
        );
      }
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(error.message).error
            )
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    }
  },
  methods: {
    goToGeoToolBox() {
      this.setFarm(this.currentSampleSummary.id);
      this.setFarmId(this.currentSampleSummary.farm_id);
      this.setFarmStr(this.currentSampleSummary.farm);

      this.itemsBreadcrumb[
        this.itemsBreadcrumb.length - 1
      ].disabled = false;

      this.pushRoute({
        name: 'GeoToolBox',
        disabled: true,
        page: 'GisActionsPage',
        active: true,
      });

      this.$f7router.navigate(
        { name: 'GisActionsPage' },
        {
          animate: true,
          transition: 'f7-fade',
        }
      );
    },
    async refresh() {
      if (
        this.currentSampleSummary?.status_code === '2' &&
        this.currentSampleSummary?.sample_id != null
      ) {
        this.$f7.preloader.show();
        await this.getSample(this.currentSampleSummary.sample_id);
        this.$f7.preloader.hide();
      }

      this.$f7router.navigate(
        { name: 'AgronomySamplesDetailsPage' },
        {
          animate: true,
          transition: 'f7-fade',
          reloadCurrent: true,
        }
      );
    },
    async download(e) {
      this.$notifyDX(
        {
          message: this.$t('downloading'),
          width: 350,
        },
        'info',
        2000
      );
      const report = await this.getReport();

      if (report.name && report.pdfB64) {
        const link = document.createElement('a');
        link.href = `data:application/octet-stream;base64,${report.pdfB64}`;
        link.download = report.name;
        link.click();

        URL.revokeObjectURL(link.href);

        this.$notifyDX(
          {
            message: this.$t('downloadedFile'),
            width: 350,
          },
          'success',
          2000
        );
      } else {
        this.$notifyDX(
          {
            message: this.$t('sampleHasNoReport'),
            width: 450,
          },
          'info',
          2000
        );
      }
    },
    ...mapActions('samples', [
      'getCurrentSampleBasedOnFilter',
      'getReport',
      'setSampleFetchedFromEtl',
    ]),
    ...mapActions('riskAnalysis', ['getSample']),
    ...mapActions('registerFarm', [
      'setFarm',
      'setFarmId',
      'setFarmStr',
    ]),
    ...mapActions('breadcrumb', ['pushRoute', 'popRoute']),
  },
};
</script>

<style scoped></style>
