<template>
  <f7-page>
    <div>
      <GisEditor
        :name-map="'addParcel'"
        :zoom="20"
        :tree-locations="isCreatingParcel ? false : true"
        :disabled-edit-sector="isEditingFarm ? false : true"
        :create-sector="isEditingFarm ? false : true"
      />

      <RegisterParcelForm />
      <f7-row class="margin-top-half">
        <f7-col>
          <GoBack
            v-if="!isRecreatingParcel"
            :special-case="true"
            go-to="AdvancedServicesManagementPage"
            url-name="AdvancedServicesStationRegistrationPage"
          />
        </f7-col>
        <f7-col style="display: flex; justify-content: flex-end">
          <DxButton
            :width="100"
            class="standardBtn marginTop15"
            type="normal"
            styling-mode="outlined"
            :text="$t('save')"
            @click="save"
          />
        </f7-col>
      </f7-row>
    </div>
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import GisEditor from '@/components/Gis/Editor/index.vue';
import GoBack from '@/components/UtilityComponents/GoBack/index.vue';
import DxButton from 'devextreme-vue/button';
import RegisterParcelForm from '@/components/Agronomy/AdvancedServices/RegisterParcelForm/index.vue';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  components: {
    GisEditor,
    RegisterParcelForm,
    GoBack,
    DxButton,
  },
  computed: {
    ...mapState('gisEditor', [
      'parcelCentroide',
      'parcelIndex',
      'centroide',
    ]),
    ...mapState('registerFarm', [
      'farm',
      'farmId',
      'parcel',
      'isEditingFarm',
      'isCreatingParcel',
      'isRecreatingParcel',
      'parcelOverlaps',
    ]),
  },
  beforeMount() {
    if (this.isEditingFarm) {
      this.setCurrentRoute([
        {
          name: this.$t('Agronomy'),
          disabled: false,
          page: 'AgronomySamplesPage',
          active: false,
        },
        {
          name: this.$t('advancedServices'),
          disabled: false,
          page: 'AdvancedServicesPage',
          active: false,
        },
        {
          name: this.$t('editParcel'),
          disabled: true,
          page: 'AdvancedServicesParcelRegistrationPage',
          active: true,
        },
      ]);
    } else if (this.centroide && !this.isRecreatingParcel) {
      this.setCurrentRoute([
        {
          name: this.$t('Agronomy'),
          disabled: false,
          page: 'AgronomySamplesPage',
          active: false,
        },
        {
          name: this.$t('advancedServices'),
          disabled: false,
          page: 'AdvancedServicesPage',
          active: false,
        },
        {
          name: this.$t('registerFarm'),
          disabled: false,
          page: 'AdvancedServicesManagementPage',
          active: false,
        },
        {
          name: this.$t('registerParcel'),
          disabled: true,
          page: 'AdvancedServicesParcelRegistrationPage',
          active: true,
        },
      ]);
    } else {
      this.setCurrentRoute([
        {
          name: this.$t('Agronomy'),
          disabled: false,
          page: 'AgronomySamplesPage',
          active: false,
        },
        {
          name: this.$t('advancedServices'),
          disabled: false,
          page: 'AdvancedServicesPage',
          active: false,
        },
        {
          name: this.$t('registerParcel'),
          disabled: true,
          page: 'AdvancedServicesParcelRegistrationPage',
          active: true,
        },
      ]);
    }
  },
  methods: {
    async save() {
      try {
        if (!this.farm || !this.parcel) {
          this.$notifyDX(
            {
              message: this.$t('fillRequiredFields'),
              width: 350,
            },
            'error',
            NOTIFY_TIME_LONG
          );
          return;
        }

        if (this.parcelOverlaps) {
          this.$notifyDX(
            {
              message: this.$t('parcelOverlaps'),
              width: 450,
            },
            'error',
            NOTIFY_TIME_LONG
          );

          return;
        }

        if (!this.parcelCentroide) {
          this.$notifyDX(
            {
              message: this.$t('Gis.mustDrawGeometry'),
              width: 350,
            },
            'error',
            NOTIFY_TIME_LONG
          );
          return;
        }

        this.$f7.preloader.show();

        if (
          !this.isEditingFarm &&
          !this.isCreatingParcel &&
          !this.isRecreatingParcel
        ) {
          const farmRes = await this.saveFarmGeoFeature({
            includesParent: false,
          });

          await this.saveFarmGeoFeature({
            includesParent: true,
            parentId: farmRes.id,
          });
        } else if (this.isEditingFarm) {
          await this.saveFarmGeoFeature({
            includesParent: true,
            parentId: null,
          });
        } else if (!this.isEditingFarm && this.isCreatingParcel) {
          await this.saveFarmGeoFeature({
            includesParent: true,
            parentId: this.farmId,
          });
        } else {
          await this.saveFarmGeoFeature();
        }

        if (!this.isEditingFarm) {
          this.$notifyDX(
            {
              message: this.$t('registerFarmSuccess'),
              width: 350,
            },
            'success',
            3000
          );
        } else {
          this.$notifyDX(
            {
              message: this.$t('editParcelSuccess'),
              width: 350,
            },
            'success',
            3000
          );
        }

        if (!this.isEditingFarm) {
          this.setParcelIndex(this.parcelIndex + 1);

          this.resetParcelGeoFeatureStations();

          this.$f7router.navigate(
            { name: 'AdvancedServicesStationRegistrationPage' },
            {
              animate: true,
              transition: 'f7-fade',
            }
          );
        } else {
          this.$f7router.navigate(
            { name: 'AdvancedServicesPage' },
            {
              animate: true,
              transition: 'f7-fade',
            }
          );
        }
      } catch (e) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(e.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      } finally {
        this.$f7.preloader.hide();
      }
    },
    ...mapActions('breadcrumb', ['setCurrentRoute']),
    ...mapActions('registerFarm', [
      'postHidrosophStationsForParcels',
      'setStations',
      'saveFarmGeoFeature',
    ]),
    ...mapActions('gisEditor', [
      'setParcelIndex',
      'resetParcelGeoFeatureStations',
    ]),
  },
};
</script>
