import EventBus from '@/services/event-bus';
import Api from '@/services/Api';

export default {
  namespaced: true,
  state: {},
  actions: {
    callWithFilters({ state }, params) {
      EventBus.$emit('AdvancedServicesPage', params);
    },
    clearFilteredData({ state }, params) {
      EventBus.$emit('AdvancedServicesPage', params);
    },
    async getAttributeList({ rootState }, attribute) {
      try {
        const xhr = await Api.getFarmsAttributeList(
          attribute,
          rootState.contact.company,
          rootState.contact.address
        );

        const response = JSON.parse(xhr.response);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getAvaiableDatesForExpertSystem(
      { rootState },
      { farmId, parcelId }
    ) {
      try {
        const xhr = await Api.getAvaiableDatesForExpertSystem(
          rootState.contact.company,
          rootState.contact.address,
          farmId,
          parcelId
          // 'ES20-00033401',
          // '001',
          // 2543,
          // 2427
        );

        const response = JSON.parse(xhr.response);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
  mutations: {},
};
