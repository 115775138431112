<template>
  <f7-page name="usersManagement">
    <f7-navbar class="navbar--specific">
      <f7-nav-left>
        <h3>Create User</h3>
      </f7-nav-left>
      <f7-nav-right>
        <f7-link
          back
          icon-material="navigate_before"
          icon-size="30"
          icon-color="primary"
        >
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <UserForm />
  </f7-page>
</template>
<script>
import UserForm from '@/components/UserForm';

export default {
  components: {
    UserForm,
  },
};
</script>
<style lang="scss" scoped>
.navbar--specific {
  height: 53.75px;
}
</style>
