<template>
  <div>
    <DxTreeList
      :id="`${atcLowerCased}TreeList`"
      :ref="`${atcLowerCased}TreeList`"
      :data-source="permissionsAvailable"
      :show-row-lines="false"
      :show-borders="false"
      :column-auto-width="true"
      :expanded-row-keys="expandedRowKeys"
      :selected-row-keys.sync="selectedPermissions"
      :disabled="arePermissionsTreesDisabled"
      :key-expr="`${atcLowerCased}_ID`"
      :parent-id-expr="`${atcLowerCased}_Head_ID`"
    >
      <DxSelection :recursive="true" mode="multiple" />
      <DxColumn
        data-field="name"
        :caption="$t(permissionsTreeListOf)"
      />
    </DxTreeList>
  </div>
</template>

<script>
import {
  DxTreeList,
  DxSelection,
  DxColumn,
} from 'devextreme-vue/tree-list';

import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('company');

export default {
  components: {
    DxTreeList,
    DxSelection,
    DxColumn,
  },
  props: {
    permissionsAvailable: {
      type: Array,
      default: () => [],
    },
    permissionsTreeListOf: {
      type: String,
      default: 'Agronomy',
    },
  },
  data() {
    return {
      expandedRowKeys: [1],
    };
  },
  computed: {
    ...mapState(['arePermissionsTreesDisabled']),
    atcLowerCased() {
      return this.permissionsTreeListOf.toLowerCase();
    },
    selectedPermissions: {
      get() {
        switch (this.permissionsTreeListOf) {
          case 'Agronomy':
            return this.$store.state.company.agronomySelectedRowKeys;
          case 'Alimentary':
            return this.$store.state.company
              .alimentarySelectedRowKeys;
          case 'Environment':
            return this.$store.state.company
              .environmentSelectedRowKeys;
          case 'Mining':
            return this.$store.state.company.miningSelectedRowKeys;
          default:
            break;
        }
        return this.$store.state.company.agronomySelectedRowKeys;
      },
      set(value) {
        switch (this.permissionsTreeListOf) {
          case 'Agronomy':
            this.$store.commit(
              'company/SET_AGRONOMY_SELECTED_KEYS',
              value
            );
            break;
          case 'Alimentary':
            this.$store.commit(
              'company/SET_ALIMENTARY_SELECTED_KEYS',
              value
            );
            break;
          case 'Environment':
            this.$store.commit(
              'company/SET_ENVIRONMENT_SELECTED_KEYS',
              value
            );
            break;
          case 'Mining':
            this.$store.commit(
              'company/SET_MINING_SELECTED_KEYS',
              value
            );
            break;
          default:
            break;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
