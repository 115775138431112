<template>
  <div class="margin-top">
    <div class="label-input margin-top-half">
      {{ $t('environmentSamples.summary.project') }}
    </div>

    <DxTagBox
      :data-source="projects"
      :multiline="true"
      :search-enabled="true"
      :placeholder="$t('environmentSamples.summary.project')"
      tag-template="tag"
      :value="selectedProjects"
      display-expr="value"
      value-expr="value"
      :on-value-changed="selectedProjectsChanged"
      :on-opened="openedProjects"
      :on-content-ready="contentReady"
      :show-selection-controls="true"
    >
      <template #tag="{ data }">
        <div class="purpleTag">
          <b>{{ data.value }}</b>
          <div class="dx-tag-remove-button xButton"></div>
        </div>
      </template>
    </DxTagBox>

    <div class="label-input margin-top-half">
      {{ $t('agronomySample.summary.samplingDate') }}
    </div>
    <div class="marginTop5 recepDate">
      <f7-input
        class="shadow"
        type="datepicker"
        :placeholder="$t('agronomySample.summary.samplingDate')"
        :clear-button="true"
        :close-on-select="true"
        :value="calculatedSamplingDate"
        :calendar-params="{
          closeOnSelect: true,
          rangePicker: true,
          dateFormat: {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
          },
          disabled: isDisabled,
        }"
        @calendar:change="setSamplingDate"
      />
    </div>

    <div class="margin-top-half">
      <div class="label-input">
        {{ $t('agronomySample.summary.sampleType') }}
      </div>

      <DxTagBox
        :data-source="sampleTypes"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('agronomySample.summary.sampleType')"
        tag-template="tag"
        display-expr="value"
        value-expr="id"
        :value="selectedSampleTypes"
        :on-value-changed="sampleTypesChanged"
        :on-opened="openedSampleTypes"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
    <div class="margin-top-half">
      <div class="label-input">{{ $t('status') }}</div>

      <DxTagBox
        :data-source="statuses"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('status')"
        tag-template="tag"
        display-expr="value"
        value-expr="id"
        :value="selectedStatuses"
        :on-value-changed="statusesChanged"
        :on-opened="openedStatuses"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
    <div class="margin-top-half">
      <div class="label-input">
        {{ $t('environmentSamples.installation') }}
      </div>

      <DxTagBox
        :data-source="farms"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('environmentSamples.installation')"
        tag-template="tag"
        :value="selectedFarms"
        display-expr="value"
        value-expr="value"
        :on-value-changed="farmsChanged"
        :on-opened="openedFarms"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
    <div class="margin-top-half">
      <div class="label-input">
        {{ $t('environmentSamples.samplingPoint') }}
      </div>

      <DxTagBox
        :data-source="parcels"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('environmentSamples.samplingPoint')"
        tag-template="tag"
        :value="selectedParcels"
        display-expr="value"
        value-expr="value"
        :on-value-changed="parcelsChanged"
        :on-opened="openedParcels"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
    <div class="margin-top-half label-input">
      {{ $t('foodSamples.receptionDate') }}
    </div>
    <div class="marginTop5 recepDate">
      <f7-input
        class="shadow"
        type="datepicker"
        :placeholder="$t('foodSamples.receptionDate')"
        :clear-button="true"
        :close-on-select="true"
        :value="calculatedReceptionDate"
        :calendar-params="{
          closeOnSelect: true,
          rangePicker: true,
          dateFormat: {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
          },
          disabled: isDisabled,
        }"
        @calendar:change="setReceptionDate"
      />
    </div>
    <div class="margin-top-half">
      <div class="label-input">
        {{ $t('foodSamples.analysisType') }}
      </div>
      <DxTagBox
        :data-source="analyseTypes"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('foodSamples.analysisType')"
        tag-template="tag"
        :value="selectedAnalyseTypes"
        display-expr="value"
        value-expr="value"
        :on-value-changed="analyseTypesChanged"
        :on-opened="openedAnalyseTypes"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
  </div>
</template>

<script>
import { DxTagBox } from 'devextreme-vue/tag-box';
import { mapActions, mapState } from 'vuex';
import DataSource from 'devextreme/data/data_source';
import moment from 'moment';
import { AFTER_DATE_LIMIT } from '@/js/constants';

export default {
  name: 'MiningSamplesFilter',
  components: {
    DxTagBox,
  },
  data() {
    return {
      sampleTypes: [],
      statuses: [],
      farms: [],
      parcels: [],
      analyseTypes: [],
      projects: [],
      loaded: false,
    };
  },
  computed: {
    calculatedReceptionDate() {
      if (
        this.receptionFromDate !== null &&
        this.receptionToDate !== null
      ) {
        return [this.receptionFromDate, this.receptionToDate];
      }
      return [];
    },
    calculatedSamplingDate() {
      if (
        this.samplingFromDate !== null &&
        this.samplingToDate !== null
      ) {
        return [this.samplingFromDate, this.samplingToDate];
      }
      return [];
    },
    ...mapState('miningFilter', [
      'receptionFromDate',
      'receptionToDate',
      'samplingFromDate',
      'samplingToDate',
      'selectedSampleTypes',
      'selectedFarms',
      'selectedParcels',
      'selectedStatuses',
      'selectedAnalyseTypes',
      'selectedProjects',
    ]),
    ...mapState('miningSamples', ['allSamples']),
  },
  methods: {
    isDisabled(date) {
      return (
        moment(date).isBefore(moment(AFTER_DATE_LIMIT), 'day') ||
        moment(date).isAfter(moment(), 'day')
      );
    },
    async openedSampleTypes(e) {
      const sampleTypes = await this.getSampleFilter({
        atc: 'Minería',
        attribute: 'sample_type',
      });

      this.sampleTypes = new DataSource({
        store: sampleTypes,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedStatuses(e) {
      const statuses = await this.getSampleFilter({
        atc: 'Minería',
        attribute: 'status',
      });

      this.statuses = statuses.sort();
      this.loaded = true;
    },
    async openedFarms(e) {
      const farms = await this.getSampleFilter({
        atc: 'Minería',
        attribute: 'farm',
      });

      this.farms = new DataSource({
        store: farms,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedParcels(e) {
      const parcels = await this.getSampleFilter({
        atc: 'Minería',
        attribute: 'parcel',
      });

      this.parcels = new DataSource({
        store: parcels,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedAnalyseTypes(e) {
      const analyseTypes = await this.getSampleFilter({
        atc: 'Minería',
        attribute: 'analyse_type',
      });

      this.analyseTypes = new DataSource({
        store: analyseTypes,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedProjects(e) {
      this.loaded = false;
      const projects = await this.getSampleFilter({
        atc: 'Minería',
        attribute: 'study',
      });

      this.projects = new DataSource({
        store: projects,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    contentReady(e) {
      const arrayNotFoundItems = Array.from(
        document.getElementsByClassName('dx-empty-message')
      );

      arrayNotFoundItems.forEach((element) => {
        if (!this.loaded) {
          element.innerHTML = this.$t('loading');
        } else {
          element.innerHTML = this.$t('dataNotAvailable');
        }
      });

      this.loaded = false;
    },
    setReceptionDate(e) {
      if (e.length === 2) {
        this.setReceptionFromDate(e[0]);
        this.setReceptionToDate(e[1]);
      } else if (e.length === 0) {
        this.setReceptionFromDate(null);
        this.setReceptionToDate(null);
      }
    },
    setSamplingDate(e) {
      if (e.length === 2) {
        this.setSamplingFromDate(e[0]);
        this.setSamplingToDate(e[1]);
      } else if (e.length === 0) {
        this.setSamplingFromDate(null);
        this.setSamplingToDate(null);
      }
    },
    sampleTypesChanged(e) {
      this.setSelectedSampleTypes(e.value);
    },
    farmsChanged(e) {
      this.setSelectedFarms(e.value);
    },
    parcelsChanged(e) {
      this.setSelectedParcels(e.value);
    },
    statusesChanged(e) {
      this.setSelectedStatuses(e.value);
    },
    analyseTypesChanged(e) {
      this.setSelectedAnalyseTypes(e.value);
    },
    selectedProjectsChanged(e) {
      this.setSelectedProjects(e.value);
    },
    ...mapActions('miningFilter', [
      'setReceptionFromDate',
      'setReceptionToDate',
      'setSamplingFromDate',
      'setSamplingToDate',
      'setSelectedSampleTypes',
      'setSelectedFarms',
      'setSelectedParcels',
      'setSelectedStatuses',
      'setSelectedAnalyseTypes',
      'setSelectedProjects',
    ]),
    ...mapActions('samples', ['getSampleFilter']),
  },
};
</script>
