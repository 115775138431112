<template>
  <div>
    <div class="filterButtonPosition">
      <div class="display-flex">
        <f7-button outline @click="newAlert">
          {{ $t('alert.new') }}
        </f7-button>
        <f7-button
          fill
          class="whiteFontColor button-filter"
          panel-toggle="right"
        >
          <span class="ic-filter"></span> {{ $t('filter') }}
        </f7-button>
      </div>
    </div>

    <DxDataGrid
      :data-source="store"
      :show-borders="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :show-colon="false"
      :focused-row-enabled="true"
      :auto-navigate-to-focused-row="autoFocus"
      :remote-operations="true"
      :hover-state-enabled="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @contentReady="init"
      @focused-row-changed="focusedRowChanged"
    >
      <DxScrolling mode="standard" />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="alertsSampleTable"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="[5, 10, 20]"
        :show-info="true"
        info-text="{2} items"
        :visible="true"
      />

      <DxSearchPanel
        :visible="true"
        :width="300"
        :placeholder="$t('search')"
      />

      <DxColumn
        data-field="atc"
        :caption="$t('alert.area')"
        alignment="left"
        :allow-header-filtering="false"
      />

      <DxColumn
        data-field="sample_type"
        :caption="$t('agronomySample.summary.sampleType')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="farm"
        :caption="$t('foodSamples.samplingSite')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="parcel"
        :caption="$t('environmentSamples.samplingPoint')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="created_at"
        :caption="$t('alert.creationDate')"
        alignment="left"
        data-type="date"
        format="dd/MM/yyyy HH:mm:ss"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="updated_at"
        :caption="$t('alert.modificationDate')"
        alignment="left"
        data-type="date"
        format="dd/MM/yyyy HH:mm:ss"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="n_superior_lmr_parameters"
        :caption="$t('alert.parameters')"
        alignment="left"
        :allow-header-filtering="false"
      />

      <DxColumn
        data-field=""
        :caption="'Nº ' + $t('chains')"
        alignment="left"
        :allow-header-filtering="false"
        cell-template="cellTemplateNrRetailers"
      />
      <DxColumn
        data-field=""
        :caption="$t('chains')"
        alignment="left"
        :allow-header-filtering="false"
        cell-template="cellTemplateRetailers"
      />
      <DxColumn
        data-field="value_report"
        :caption="$t('agronomySample.summary.description')"
        alignment="left"
        :allow-header-filtering="false"
      />

      <DxColumn
        data-field=""
        :caption="$t('status')"
        alignment="left"
        :fixed="true"
        fixed-position="right"
        :allow-header-filtering="false"
        cell-template="cellTemplateStatus"
      />
      <DxColumn
        :buttons="editButtons"
        :fixed="true"
        fixed-position="right"
        type="buttons"
        width="90"
      />

      <template #cellTemplateStatus="{ data }">
        <div>
          <DxSwitch
            :value="data.data.is_active"
            @valueChanged="
              () => {
                updateState(
                  'status',
                  {
                    newState: !data.data.is_active,
                    id: data.data.id,
                  },
                  data
                );
              }
            "
          />
        </div>
      </template>
      <template #cellTemplateNrRetailers="{ data }">
        <div>
          <div v-if="data.data.retailers_num">
            {{ data.data.retailers_num }}
          </div>
          <div v-else>0</div>
        </div>
      </template>
      <template #cellTemplateRetailers="{ data }">
        <div>
          <div v-if="data.data.retailers">
            {{ getRetailers(data.data.retailers) }}
          </div>
          <div v-else></div>
        </div>
      </template>
    </DxDataGrid>

    <NewAlertPopup
      v-if="showNewAlertSamplePopup"
      :params="params"
      :edit-data="editData"
    />
  </div>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxStateStoring,
  DxPager,
  DxPaging,
  DxScrolling,
} from 'devextreme-vue/data-grid';

import { DxSwitch } from 'devextreme-vue/switch';
import { mapActions, mapGetters, mapState } from 'vuex';

import NewAlertPopup from '@/components/Alerts/Settings/NewAlertPopup/Sample/index.vue';
import EventBus from '@/services/event-bus';
import notify from 'devextreme/ui/notify';
import Helpers from '@/js/helpers';
import { NOTIFY_TIME_LONG } from '@/js/constants';

import Api from '@/services/Api';

export default {
  name: 'SampleTable',
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxStateStoring,
    DxPager,
    DxPaging,
    DxScrolling,
    DxSwitch,
    NewAlertPopup,
  },
  props: {
    store: {
      type: Object,
      default: () => {},
    },
    params: {
      type: Object,
      default: () => {},
    },
    showSwitch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editButtons: [
        {
          icon: 'copy',
          onClick: this.duplicateConfirm,
        },
        {
          icon: 'edit',
          onClick: this.editAlert,
        },
        {
          icon: 'trash',
          onClick: this.deleteConfirm,
        },
      ],
      editData: {},
      changedFocused: false,
      autoFocus: !Helpers.isEmptyObject(this.params),
      showNotificationsSwitch: true,
      switchValue: false,
      firstLoad: true,
    };
  },
  computed: {
    ...mapState('alerts', ['showNewAlertSamplePopup']),
    ...mapState('alertsSample', ['focusedRowKey']),
    ...mapState('contact', ['company', 'address']),
    ...mapGetters('user', ['userProfile']),
  },
  methods: {
    onValueChanged(e) {
      const changeTo = e.value;

      try {
        if (changeTo) {
          Api.createReceptionAlert(
            { contactCode: this.userProfile.code },
            this.company,
            this.address
          );
        } else {
          Api.deleteReceptionAlert(
            { contactCode: this.userProfile.code },
            this.company,
            this.address
          );
        }

        this.switchValue = e.value;
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(error.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );

        this.switchValue = !e.value;
      }
    },
    getRetailers(retailers) {
      const replace = retailers.toString().replace(/[\[\]]/g, '');
      const array = replace.split(',');
      return array
        .map((el) => {
          return el.replaceAll("'", '');
        })
        .toString();
    },
    init(e) {
      e.component.option('focusedRowKey', this.focusedRowKey);
      this.changedFocused = true;
    },
    focusedRowChanged(row) {
      if (!row.row) return;

      if (this.changedFocused) {
        if (Helpers.isEmptyObject(this.params)) {
          this.setFocusedRowKey(row.row.data.id);
        }
      }
    },
    async updateState(type, data, grid) {
      const updatedRow = await this.store.update(type, data);

      grid.row.data.is_active = updatedRow.is_active;
      grid.component.repaintRows(grid.rowIndex);
    },
    newAlert() {
      this.editData = {};
      this.setShowNewAlertSamplePopup(true);
    },
    editAlert(e) {
      this.editData.isEditing = true;
      this.editData.data = {};

      this.editData.data.id = e.row.data.id;
      this.editData.data.area = e.row.data.atc;
      this.editData.data.sampleType = e.row.data.sample_type;
      this.editData.data.farm = e.row.data.farm;
      this.editData.data.parcel = e.row.data.parcel;
      this.editData.data.valueReport = e.row.data.value_report;
      this.editData.data.nrSuperiorParams =
        e.row.data.n_superior_lmr_parameters;
      this.editData.data.retailersNum = e.row.data.retailers_num || 0;
      this.editData.data.retailers = e.row.data.retailers || [];
      this.editData.data.study = e.row.data.study || '';
      this.editData.data.sendingNotifications =
        e.row.data.sending_notifications || false;
      this.setShowNewAlertSamplePopup(true);
    },
    deleteConfirm(e) {
      this.$f7.dialog.confirm(
        `${this.$t('alert.deleteConfirm')}`,
        async () => {
          const { id } = e.row.data;

          try {
            notify(
              {
                message: this.$t('alert.deleting'),
                width: 450,
              },
              'info',
              2000
            );

            await this.deleteAlert(id);

            notify(
              {
                closeOnClick: true,
                message: this.$t('alert.deleted'),
                width: 450,
              },
              'success',
              2000
            );
            EventBus.$emit('newSampleAlert', this.params);
            this.$f7.dialog.close();
          } catch (error) {
            this.$notifyDX(
              {
                message: this.$t(
                  this.$helpers.getFilteredErrorMessage(
                    JSON.parse(error.message).error
                  )
                ),
                width: 550,
              },
              'error',
              NOTIFY_TIME_LONG
            );
          }
        }
      );
    },
    duplicateConfirm(e) {
      const rowData = e.row.data;
      const data = {
        nrSuperiorParams: rowData.n_superior_lmr_parameters,
        farm: rowData.farm,
        parcel: rowData.parcel,
        atc: rowData.atc,
        retailers: rowData.retailers,
        retailers_num: rowData.retailers_num,
        sampleTypeId: rowData.sample_type_id,
        sampleType: rowData.sample_type,
        isActive: rowData.is_active,
        valueReport: rowData.value_report,
        sendingNotifications: rowData.sending_notifications,
      };

      this.$f7.dialog.confirm(
        `${this.$t('alert.duplicateConfirm')}`,
        async () => {
          try {
            notify(
              {
                message: this.$t('alert.duplicating'),
                width: 450,
              },
              'info',
              2000
            );

            await this.duplicateAlert(data);

            notify(
              {
                closeOnClick: true,
                message: this.$t('alert.duplicated'),
                width: 450,
              },
              'success',
              2000
            );

            EventBus.$emit('newSampleAlert', this.params);
            this.$f7.dialog.close();
          } catch (error) {
            this.$notifyDX(
              {
                message: this.$t(
                  this.$helpers.getFilteredErrorMessage(
                    JSON.parse(error.message).error
                  )
                ),
                width: 550,
              },
              'error',
              NOTIFY_TIME_LONG
            );
          }
        }
      );
    },
    ...mapActions('alerts', ['setShowNewAlertSamplePopup']),
    ...mapActions('alertsSample', [
      'deleteAlert',
      'duplicateAlert',
      'setFocusedRowKey',
    ]),
  },
};
</script>
