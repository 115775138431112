<template>
  <div>
    <div class="disclosure-section">
      <!-- <div class="disclosure-section-title">section 1</div> -->
      <div
        class="disclosure-section-atributtes-2"
        style="margin-top: 0.6rem"
      >
        <div>
          <label class="disclosure-section-label">
            {{ $t('geotoolbox.activeLayer') }}
          </label>
          <DxSelectBox
            v-model="activeLayer"
            :data-source="activeLayerList"
            display-expr="name"
            value-expr="id"
            :search-enabled="true"
          >
            <template #item="{ data }">
              <div>
                <div :class="{ 'not-valid': data.length < 5 }">
                  {{ data.name }}
                </div>
              </div>
            </template>
          </DxSelectBox>
        </div>
      </div>
    </div>
    <div class="disclosure-section">
      <!-- <div class="disclosure-section-title">section 2</div> -->
      <div class="disclosure-section-atributtes-2">
        <div>
          <div class="disclosure-section-label-with-switch">
            <label class="disclosure-section-label">
              {{ $t('geotoolbox.regionOfInterest') }}
            </label>
            <div class="custom-switch" title="cálculo automático">
              <input
                id="custom-switch"
                v-model="showRegionInterest"
                type="checkbox"
              />
              <label for="custom-switch"></label>
            </div>
            <PopUp position="top" style="margin-left: -15px">
              <div style="padding: 10px">
                {{ $t('geotoolbox.regionOfInterestInfo') }}
              </div>
            </PopUp>
          </div>
          <DxSelectBox
            v-model="RegionInterest"
            :data-source="RegionInterestList"
            display-expr="name"
            :search-enabled="true"
            :disabled="showRegionInterest === true"
          />
        </div>
      </div>
    </div>
    <div class="disclosure-buttons-interpolation">
      <button
        class="button-interpolate"
        type="button"
        :disabled="loadingInterpolation"
        @click="interpolate()"
      >
        <span v-if="!loadingInterpolation">{{
          $t('geotoolbox.execute')
        }}</span>
        <svg
          v-if="loadingInterpolation"
          class="ring"
          viewBox="25 25 50 50"
          stroke-width="5"
        >
          <circle cx="50" cy="50" r="20" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { DxSelectBox } from 'devextreme-vue/select-box';
import EventBus from '@/services/event-bus';
import turf from 'turf';
import { booleanPointInPolygon, simplify } from '@turf/turf';
import Api from '@/services/Api';
import PopUp from '@/components/UtilityComponents/popUpInfo/popUp.vue';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
  name: 'ToolInterpolation',
  components: {
    DxSelectBox,
    PopUp,
  },
  props: {
    areas: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      activeLayer: null,
      activeLayerList: [],
      RegionInterest: null,
      // RegionInterestList: [],
      showRegionInterest: true,
      loadingInterpolation: false,
      colorMaps: ['BuGn', 'BuPu', 'GnBu', 'OrRd', 'PuRd'],
      colorCount: 0,
    };
  },
  computed: {
    RegionInterestList() {
      return Object.values(this.areas);
    },
    ...mapState('contact', ['company', 'address']),
  },
  mounted() {
    EventBus.$on('add-set', (data) => {
      this.activeLayerList.push({
        id: this.activeLayerList.length,
        name: data.title,
        samples: data.samples,
        length: data.samples.length,
      });
    });

    EventBus.$on('delete-sets', () => {
      this.activeLayerList = [];
    });
  },
  methods: {
    async getFeatureInt(name, polygon, points) {
      const featureCollectionPolygon = {
        type: 'FeatureCollection',
        features: [polygon],
      };

      const featureCollectionPoints = {
        type: 'FeatureCollection',
        features: points.samples.map((sample) => ({
          type: 'Feature',
          geometry: sample.geometry,
          properties: {
            value: sample.parameter.result,
          },
        })),
      };

      const refValues = await this.fetchRefValues(points.samples[0]);

      await Api.getFeatureInt({
        legend_desc: `${points.samples[0].sample_type} ${points.samples[0].parameter.parameter}`,
        geometry_points: JSON.stringify(featureCollectionPoints),
        geometry_region: JSON.stringify(featureCollectionPolygon),
        ref_values: refValues,
        color_map:
          this.colorMaps[this.colorCount % this.colorMaps.length],
        name,
      });

      this.colorCount += 1;
    },
    async fetchRefValues(sample) {
      try {
        const xhr = await Api.getRefValues(
          {
            sample_type_family_id: sample.sample_type_family_id,
            sample_type_subfamily_id: sample.sample_type_subfamily_id,
            sample_type_id: sample.sample_type_id,
            parameter_id: sample.parameter.parameter_id,
          },
          this.company,
          this.address
        );

        const response = JSON.parse(xhr.response);

        if (response?.value != null) {
          const values = response?.value;

          return `${values.very_low_level},${values.low_level},${values.high_level},${values.very_high_level}`;
        }
      } catch (error) {
        return '17,19,21,23';
      }

      return '17,19,21,23';
    },
    async interpolate() {
      this.loadingInterpolation = true;
      const selected = this.activeLayerList.find(
        (layer) => layer.id === this.activeLayer
      );

      if (selected == null) {
        this.loadingInterpolation = false;
        this.$notifyDX(
          {
            message: this.$t(
              'gisActions.toolInterpolation.layerNotSelected'
            ),
            width: 550,
          },
          'error',
          1000
        );
        return;
      }

      if (selected.length < 5) {
        this.loadingInterpolation = false;
        this.$notifyDX(
          {
            message: this.$t(
              'gisActions.toolInterpolation.layerNotEnoughSamples'
            ),
            width: 550,
          },
          'error',
          1000
        );
        return;
      }

      if (this.showRegionInterest) {
        const points = [];

        for (const sample of selected.samples) {
          points.push(turf.point(sample.geometry.coordinates));
        }

        const turfCollection = turf.featureCollection(points);
        const hull = turf.convex(turfCollection);

        const buffered = turf.buffer(hull, 0.015);

        const name = `${Math.random()
          .toString(32)
          .slice(2)}-${moment().format('YYMMDDHHmmss')}`;

        await this.getFeatureInt(name, buffered, selected);

        EventBus.$emit('add-interpolation', {
          name: `REGION-${selected.name}`,
          title: `REGION-${selected.name}`,
          feature: buffered,
          rasterImageName: name,
          interpolate: true,
        });
      } else {
        if (this.RegionInterest == null) {
          this.loadingInterpolation = false;
          this.$notifyDX(
            {
              message: this.$t(
                'gisActions.toolInterpolation.areaNotSelected'
              ),
              width: 550,
            },
            'error',
            1000
          );
          return;
        }

        let valid = true;

        const poly = turf.polygon(
          this.RegionInterest.layer.feature.geometry.coordinates
        );

        for (const sample of selected.samples) {
          const pt = turf.point(sample.geometry.coordinates);

          const pointInside = booleanPointInPolygon(pt, poly);

          if (!pointInside) {
            valid = false;
            break;
          }
        }

        if (!valid) {
          this.loadingInterpolation = false;
          this.$notifyDX(
            {
              message: this.$t(
                'gisActions.toolInterpolation.pointsOutsideGeometry'
              ),
              width: 550,
            },
            'error',
            1000
          );

          return;
        }

        // Simplify geometry
        const polySimplify = simplify(
          turf.polygon(
            this.RegionInterest.layer.feature.geometry.coordinates
          ),
          { tolerance: 1, highQuality: false }
        );

        for (const sample of selected.samples) {
          const pt = turf.point(sample.geometry.coordinates);

          const pointInside = booleanPointInPolygon(pt, polySimplify);

          if (!pointInside) {
            valid = false;
            break;
          }
        }

        const selectedFeature = valid
          ? polySimplify
          : this.RegionInterest.layer.feature;

        const name = `${Math.random()
          .toString(32)
          .slice(2)}-${moment().format('YYMMDDHHmmss')}`;

        await this.getFeatureInt(name, selectedFeature, selected);

        const parcel = selected?.samples[0]?.parcel || 'PARCEL';

        EventBus.$emit('add-interpolation', {
          // name: `REGION-${selected.name}`,
          // title: `REGION-${selected.name}`,
          name: `${parcel}-${selected.name}`,
          title: `${parcel}-${selected.name}`,
          feature: selectedFeature,
          rasterImageName: name,
          interpolate: true,
        });
      }

      this.loadingInterpolation = false;

      this.$notifyDX(
        {
          message: this.$t('gisActions.toolInterpolation.success'),
          width: 550,
        },
        'success',
        1000
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import '../GeoToolBoxTab.styles.scss';
</style>
