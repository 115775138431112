var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"margin-top"},[_c('div',{staticClass:"label-input"},[_vm._v(_vm._s(_vm.$t('notifications.date')))]),_vm._v(" "),_c('div',{staticClass:"recepDate marginTop5"},[_c('f7-input',{staticClass:"shadow",attrs:{"type":"datepicker","placeholder":_vm.$t('notifications.date'),"clear-button":true,"close-on-select":true,"value":_vm.calculatedNotificationDate,"calendar-params":{
        closeOnSelect: true,
        rangePicker: true,
        dateFormat: {
          month: 'numeric',
          day: 'numeric',
          year: 'numeric',
        },
      }},on:{"calendar:change":_vm.setNotificationDate}})],1),_vm._v(" "),_c('div',{staticClass:"margin-top-half label-input"},[_vm._v("\n    "+_vm._s(_vm.$t('alert.area'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"marginTop5"},[_c('DxTagBox',{attrs:{"data-source":_vm.atcs,"multiline":true,"placeholder":_vm.$t('alert.area'),"tag-template":"tag","value":_vm.selectedAtcs,"display-expr":"value","value-expr":"id","on-value-changed":_vm.atcsChanged,"on-opened":_vm.openedAtcs,"on-content-ready":_vm.contentReady,"show-selection-controls":true},scopedSlots:_vm._u([{key:"tag",fn:function({ data }){return [_c('div',{staticClass:"purpleTag"},[_c('b',[_vm._v(_vm._s(data.value))]),_vm._v(" "),_c('div',{staticClass:"dx-tag-remove-button xButton"})])]}}])})],1),_vm._v(" "),_c('div',{staticClass:"label-input margin-top-half"},[_vm._v("\n    "+_vm._s(_vm.$t('agronomySample.summary.sampleType'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"marginTop5"},[_c('DxTagBox',{attrs:{"data-source":_vm.sampleTypes,"multiline":true,"placeholder":_vm.$t('agronomySample.summary.sampleType'),"tag-template":"tag","value":_vm.selectedSampleTypes,"on-value-changed":_vm.sampleTypesChanged,"on-opened":_vm.openedSampleTypes,"on-content-ready":_vm.contentReady,"show-selection-controls":true},scopedSlots:_vm._u([{key:"tag",fn:function({ data }){return [_c('div',{staticClass:"purpleTag"},[_c('b',[_vm._v(_vm._s(data))]),_vm._v(" "),_c('div',{staticClass:"dx-tag-remove-button xButton"})])]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }