<template>
  <div
    v-if="loaded"
    class="tableContainer marginTop15"
    style="border-radius: 5px"
  >
    <f7-row>
      <f7-col>
        <DxForm
          style="padding: 5px"
          class="ha-form marginTop15"
          col-count="1"
          :form-data="formData"
        >
          <DxItem
            :editor-options="{
              items: formData.farms,
              onValueChanged: farmChanged,
              value: isEditingFarm ? farmStr : farm,
              width: '235px',
              valueExpr: isEditingFarm ? 'farm' : 'id',
              displayExpr: 'name',
              disabled: isEditingFarm,
              placeholder: $t('select'),
            }"
            data-field=""
            editor-type="dxSelectBox"
          >
            <DxRequiredRule :message="$t('alert.requiredFarm')" />
            <DxLabel :text="$t('agronomySample.summary.estate')" />
          </DxItem>

          <DxItem
            :editor-options="{
              onValueChanged: accountableChanged,
              value: accountable,
              width: '235px',
              disabled: isEditingFarm,
              placeholder: $t('select'),
            }"
            data-field=""
            editor-type="dxTextBox"
          >
            <DxRequiredRule :message="$t('accountableRequired')" />
            <DxLabel :text="$t('accountable')" />
          </DxItem>
        </DxForm>
      </f7-col>
      <f7-col></f7-col>
      <f7-col></f7-col>
      <f7-col></f7-col>
      <f7-col style="margin-top: 15px">
        <span>
          {{ $t('latitude') }}:
          <strong>{{
            centroide ? centroide.lat.toFixed(6) : ''
          }}</strong>
        </span>
        <br />

        <span style="margin-top: 25px">
          {{ $t('longitude') }}:
          <strong>{{
            centroide ? centroide.lng.toFixed(6) : ''
          }}</strong>
        </span>
        <br />

        <span>
          {{ $t('surface') }} (ha):
          <strong>{{ area ? area.toFixed(2) : '' }}</strong>
        </span>
      </f7-col>
    </f7-row>
  </div>
</template>

<script>
import { DxForm, DxItem, DxLabel } from 'devextreme-vue/form';
import { DxRequiredRule } from 'devextreme-vue/validator';
import { mapActions, mapState } from 'vuex';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  components: {
    DxForm,
    DxItem,
    DxLabel,
    DxRequiredRule,
  },
  data() {
    return {
      formData: {
        farms: [],
      },
      loaded: false,
    };
  },
  computed: {
    ...mapState('registerFarm', [
      'farm',
      'farmStr',
      'accountable',
      'reset',
      'isEditingFarm',
    ]),
    ...mapState('gisEditor', ['area', 'centroide']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    if (this.reset) {
      this.setFarm('');
      this.setAccountable('');
    }

    if (!this.isEditingFarm) {
      try {
        this.formData.farms = await this.getFarms(true);
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(error.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      }
    } else {
      this.formData.farms.push({ farm: this.farmStr });
    }

    this.$f7.preloader.hide();
    this.loaded = true;
  },
  methods: {
    farmChanged(e) {
      if (!e.event) return;
      this.setFarmStr(e.event.target.innerText);
      this.setFarm(e.value);
    },
    accountableChanged(e) {
      if (!e.event) return;
      this.setAccountable(e.value);
    },
    ...mapActions('gis', ['getFarms']),
    ...mapActions('registerFarm', [
      'setFarm',
      'setAccountable',
      'setFarmStr',
    ]),
  },
};
</script>
