<template>
  <div>
    <DxChart id="chart" :data-source="ds">
      <DxCommonSeriesSettings
        argument-field="sample_type"
        type="stackedbar"
      />
      <DxValueAxis position="left">
        <DxTitle :text="$t('stats.numSamples')" />
      </DxValueAxis>

      <DxSeries
        value-field="n_lmrs"
        :name="
          $t('positives') + ' > LMR - ' + $t('stats.currentPeriod')
        "
        stack="current"
        color="#FF6384"
      />

      <DxSeries
        value-field="n_positives"
        :name="$t('positives') + ' - ' + $t('stats.currentPeriod')"
        stack="current"
        color="#FFCE56"
      />

      <DxSeries
        value-field="n_samples"
        :name="$t('samples') + ' - ' + $t('stats.currentPeriod')"
        stack="current"
        color="#ffeda0"
      />

      <DxSeries
        value-field="historic_n_lmrs"
        :name="
          $t('positives') + ' > LMR - ' + $t('stats.previousPeriod')
        "
        stack="previous"
        color="#9c88ff"
      />

      <DxSeries
        value-field="historic_n_positives"
        :name="$t('positives') + ' - ' + $t('stats.previousPeriod')"
        stack="previous"
        color="#4BC0C0"
      />

      <DxSeries
        value-field="historic_n_samples"
        :name="$t('samples') + ' - ' + $t('stats.previousPeriod')"
        stack="previous"
        color="#badc58"
      />

      <DxLegend
        vertical-alignment="bottom"
        :column-count="3"
        horizontal-alignment="center"
        item-text-position="top"
      />

      <DxExport :enabled="true" />
      <DxTooltip
        :z-index="999999"
        :customize-tooltip="customizeTooltip"
        :enabled="true"
        location="edge"
      />
    </DxChart>
  </div>
</template>

<script>
import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxValueAxis,
  DxTitle,
  DxLegend,
  DxExport,
  DxTooltip,
} from 'devextreme-vue/chart';
import { mapState } from 'vuex';

export default {
  components: {
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxValueAxis,
    DxTitle,
    DxLegend,
    DxExport,
    DxTooltip,
  },
  computed: {
    ds() {
      const data = [];

      this.aggregatedData.map((el) => {
        const formattedData = el.stats.map((monthData) => {
          const obj = {
            ...monthData.current,
            month: `${new Date(
              monthData.current.year,
              monthData.month === null
                ? 'Invalid'
                : monthData.month - 1,
              1
            )
              .toLocaleString(this.languageInterface, {
                month: 'long',
              })
              .capitalize()} ${monthData.current.year}`,
            sample_type: el.sample_type,
            sample_type_id: el.sample_type_id,
            n_samples:
              monthData.current.n_samples -
              monthData.current.n_positives,
            n_positives:
              monthData.current.n_positives -
              monthData.current.n_lmrs,
            n_samples_original: monthData.current.n_samples,
            n_positives_original: monthData.current.n_positives,
          };

          const { historic } = monthData;

          historic.n_samples_original = historic.n_samples;
          historic.n_positives_original = historic.n_positives;

          Object.keys(historic).forEach((key) => {
            obj[`historic_${key}`] = historic[key];
          });

          obj.historic_n_samples =
            obj.historic_n_samples_original -
            obj.historic_n_positives_original;

          obj.historic_n_positives =
            obj.historic_n_positives_original - obj.historic_n_lmrs;

          delete obj.historic;

          return obj;
        });

        data.push(
          ...formattedData.filter((d) => !d.month.includes('Invalid'))
        );
      });

      return data;
    },
    ...mapState('foodStats', ['aggregatedData']),
  },
  methods: {
    customizeTooltip(pointInfo) {
      const { data } = pointInfo.point;

      // pointInfo.point.data[pointInfo.point._options.valueField]
      const isSamplesHistoric =
        pointInfo.point._options.valueField.includes(
          'historic_n_samples'
        );

      const isPositivesHistoric =
        pointInfo.point._options.valueField.includes(
          'historic_n_positives'
        );

      const isSamples =
        pointInfo.point._options.valueField.includes('samples') &&
        !isSamplesHistoric;

      const isPositives =
        pointInfo.point._options.valueField.includes('positives') &&
        !isPositivesHistoric;

      if (isSamples) {
        return {
          html: `<p>${this.$t('samples')}: ${
            pointInfo.point.data.n_samples_original
          }</p>`,
        };
      }
      if (isSamplesHistoric) {
        return {
          html: `<p>${this.$t('samples')}: ${
            pointInfo.point.data.historic_n_samples_original
          }</p>`,
        };
      }

      if (isPositives) {
        return {
          html: `<p>${this.$t('positives')}: ${
            pointInfo.point.data.n_positives_original
          }</p>`,
        };
      }

      if (isPositivesHistoric) {
        return {
          html: `<p>${this.$t('positives')}: ${
            pointInfo.point.data.historic_n_positives_original
          }</p>`,
        };
      }

      return {
        html: `<p>${this.$t('positives')} > LMR: ${
          pointInfo.point.data[pointInfo.point._options.valueField]
        }</p>`,
      };
    },
  },
};
</script>
