<template>
  <f7-page>
    <div v-if="loaded">
      <div class="tableContainer">
        <FoodStatsTable :aggregated-data="data" />
      </div>

      <br />

      <div class="tableContainerNoPadding">
        <StatsGraphParameter
          v-if="
            selectedParameters.length >= 1 &&
            !isComparative &&
            selectedSampleTypes.length === 1
          "
        />

        <StatsGraphSamples
          v-if="
            selectedSampleTypes.length === 1 &&
            !isComparative &&
            selectedParameters.length === 0
          "
        />

        <StatsGraphSamplesMultiple
          v-if="
            selectedSampleTypes.length > 1 &&
            !isComparative &&
            selectedParameters.length === 0
          "
        />
        <StatsGraphParameterMultiple
          v-if="
            selectedSampleTypes.length > 1 &&
            !isComparative &&
            selectedParameters.length >= 1
          "
        />

        <StatsGraphComparativeSamples
          v-if="
            selectedSampleTypes.length === 1 &&
            isComparative &&
            selectedParameters.length === 0 &&
            !isGlobalAGQ
          "
        />

        <StatsGraphComparativeParameter
          v-if="
            selectedSampleTypes.length === 1 &&
            isComparative &&
            selectedParameters.length >= 1 &&
            !isGlobalAGQ
          "
        />

        <StatsGraphComparativeParameterMultipleSamples
          v-if="
            selectedSampleTypes.length > 1 &&
            isComparative &&
            selectedParameters.length === 0 &&
            !isGlobalAGQ
          "
        />
      </div>
    </div>

    <NotificationSpinner
      v-if="notificationMessage.length > 0"
      :message="notificationMessage"
    />
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import FoodStatsTable from '@/components/Food/Stats/Table/index.vue';
import EventBus from '@/services/event-bus';
import StatsGraphSamples from '@/components/Food/Stats/Graphs/Samples/index.vue';
import StatsGraphSamplesMultiple from '@/components/Food/Stats/Graphs/Samples/Multiple/index.vue';
import StatsGraphParameter from '@/components/Food/Stats/Graphs/Params/index.vue';
import StatsGraphParameterMultiple from '@/components/Food/Stats/Graphs/Params/Multiple/index.vue';
import StatsGraphComparativeSamples from '@/components/Food/Stats/Graphs/Samples/Comparatives/index.vue';
import StatsGraphComparativeParameter from '@/components/Food/Stats/Graphs/Params/Comparatives/index.vue';
import StatsGraphComparativeParameterMultipleSamples from '@/components/Food/Stats/Graphs/Samples/Comparatives/Multiple/index.vue';
import NotificationSpinner from '@/components/NotificationSpinner/index.vue';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  name: 'FoodStatsPage',
  components: {
    FoodStatsTable,
    StatsGraphSamples,
    StatsGraphSamplesMultiple,
    StatsGraphParameter,
    StatsGraphParameterMultiple,
    StatsGraphComparativeSamples,
    StatsGraphComparativeParameter,
    StatsGraphComparativeParameterMultipleSamples,
    NotificationSpinner,
  },
  data() {
    return {
      loaded: true,
      data: [],
      notificationMessage: '',
    };
  },
  computed: {
    loggedUserIsContact() {
      return this.enabled;
    },
    ...mapState('foodStatsFilter', [
      'selectedSampleTypes',
      'isComparative',
      'selectedParameters',
      'isGlobalAGQ',
    ]),
    ...mapState('contact', ['enabled']),

    ...mapState('foodStats', ['afterDates']),
  },
  mounted() {
    this.clearFilters();
    this.clearCurrentFilters();

    this.setCurrentRoute([
      {
        name: this.$t('Food'),
        disabled: false,
        page: 'FoodSamplesPage',
        active: false,
      },
      {
        name: this.$t('statsConsultation'),
        disabled: true,
        page: 'FoodSamplesPage',
        active: true,
      },
    ]);

    if (!this.loggedUserIsContact) {
      this.setIsCompanyAggregated(true);
    }
    this.setAggregatedData([]);

    document.getElementById('panel-right-stats-btn').click();

    EventBus.$on('FoodStatsPageClearFilter', () => {
      this.clearFilters();
    });

    EventBus.$on('FoodStatsPage', async (params) => {
      this.setAggregatedData([]);

      if (!params) {
        this.data = [];
        return;
      }

      if (params.error) {
        this.$notifyDX(
          {
            message: this.$t('stats.requiredFilters'),
            width: 450,
          },
          'error',
          NOTIFY_TIME_LONG
        );

        return;
      }

      this.loaded = false;
      this.notificationMessage = this.$t('retrievingData');

      this.$f7.preloader.show();

      try {
        const aggregatedData = await this.getAggregatedCalculations(
          params
        );

        this.setAggregatedData(aggregatedData);

        const isParameters = Object.keys(
          aggregatedData[0].stats[0]
        ).some((el) => el === 'parameter');

        this.setIsParameters(isParameters);

        this.data = [];

        aggregatedData.forEach((data) => {
          const dataStatsFormatted = data.stats.map((stat) => {
            if (isParameters) {
              return {
                parameter: stat.parameter,
                historic: {
                  parameter: stat.parameter,
                  ...(stat.historic || stat.agq),
                },
                ...stat.current,
                sample_type: data?.sample_type,
                sample_type_id: data?.sample_type_id,
              };
            }

            return {
              month: stat.month,
              historic: {
                month: stat.month,
                ...(stat.historic || stat.agq),
              },
              ...stat.current,
              sample_type: data?.sample_type,
              sample_type_id: data?.sample_type_id,
            };
          });

          this.data.push(...dataStatsFormatted);
        });

        this.loaded = true;

        this.$f7.preloader.hide();
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t('defaultError'),
            width: 350,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      } finally {
        this.loaded = true;

        setTimeout(() => {
          this.notificationMessage = '';
        }, 1000);

        this.$f7.preloader.hide();
      }
    });
  },
  beforeDestroy() {
    EventBus.$off('FoodStatsPage');
    EventBus.$off('FoodStatsPageClearFilter');
  },
  methods: {
    ...mapActions('breadcrumb', ['setCurrentRoute']),
    ...mapActions('foodStats', [
      'getAggregatedCalculations',
      'setIsParameters',
      'setAggregatedData',
      'setBeforeDates',
      'setAfterDates',
    ]),
    ...mapActions('foodStatsFilter', [
      'setIsCompanyAggregated',
      'clearFilters',
    ]),
    ...mapActions('filter', ['clearCurrentFilters']),
  },
};
</script>
