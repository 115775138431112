export default {
  namespaced: true,
  state: {
    selectedParameters: [],
    selectedParameterGroups: [],
  },
  actions: {
    setSelectedParameters({ commit }, value) {
      commit('setSelectedParameters', value);
    },
    setSelectedParameterGroup({ commit }, value) {
      commit('setSelectedParameterGroup', value);
    },
    clearFilters({ commit }) {
      commit('clearFilters');
    },
  },
  mutations: {
    setSelectedParameters(state, value) {
      state.selectedParameters = value;
    },
    setSelectedParameterGroup(state, value) {
      state.selectedParameterGroups = value;
    },
    clearFilters(state) {
      state.selectedParameters = [];
      state.selectedParameterGroups = [];
    },
  },
};
