import Api from '@/services/Api';
import format from 'date-fns/format';

export default {
  namespaced: true,
  state: {
    initDate: '2022-03-01',
    endDate: '2022-03-02',
    irrigationInitDate: '2022-03-01',
    irrigationEndDate: '2022-03-02',
    dataSource: [],
    selectedProbe: 'SOIL_HUMIDITY_10CM',
    pluviometerInitDate: format(
      new Date().setDate(new Date().getDate() - 7),
      "yyyy-MM-dd'T'HH:mm:ss"
    ),
    pluviometerEndDate: format(
      new Date().setDate(new Date().getDate() + 7),
      "yyyy-MM-dd'T'HH:mm:ss"
    ),
    selectedDistance: [],
    avaliableDistances: [],
  },
  actions: {
    setInitDate({ commit }, value) {
      commit('setInitDate', value);
    },
    setEndDate({ commit }, value) {
      commit('setEndDate', value);
    },
    setSelectedProbe({ commit }, value) {
      commit('setSelectedProbe', value);
    },
    setIrrigationInitDate({ commit }, value) {
      commit('setIrrigationInitDate', value);
    },
    setIrrigationEndDate({ commit }, value) {
      commit('setIrrigationEndDate', value);
    },
    setPluviometerInitDate({ commit }, value) {
      commit('setPluviometerInitDate', value);
    },
    setPluviometerEndDate({ commit }, value) {
      commit('setPluviometerEndDate', value);
    },
    setDataSource({ commit }, value) {
      commit('setDataSource', value);
    },
    setSelectedDistance({ commit }, value) {
      commit('setSelectedDistance', value);
    },
    setAvaliableDistances({ commit }, value) {
      commit('setAvaliableDistances', value);
    },
    async getStationsList({ rootState }, id = null) {
      try {
        const xhr = await Api.getStationsList(
          id || rootState.registerFarm.parcelId,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getStationsListFarm({ rootState }) {
      try {
        const xhr = await Api.getStationsListFarm(
          rootState.registerFarm.farmId,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getMirData({ rootState }, value) {
      try {
        let xhr = null;

        if (value.stationId) {
          xhr = await Api.getMirDataStations(
            value,
            rootState.contact.company,
            rootState.contact.address,
            value.stationId
          );
        } else {
          xhr = await Api.getMirData(
            value,
            rootState.contact.company,
            rootState.contact.address,
            rootState.registerFarm.farmId
          );
        }

        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getLastMirData({ rootState }) {
      try {
        const xhr = await Api.getLastMirData(
          rootState.contact.company,
          rootState.contact.address,
          rootState.registerFarm.farmId,
          '62cedf96e33d0f5b403dd818'
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async searchStationByGeometry({ rootState }) {
      try {
        const xhr = await Api.searchStationByGeometry(
          rootState.gisEditor.currentParcelGeoFeature[0].features[0]
            .geometry,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async saveStations({ rootState }) {
      try {
        const xhr = await Api.saveStations(
          rootState.registerFarm.parcelId,
          rootState.registerFarm.selectedStations,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getStations({ rootState }) {
      try {
        const xhr = await Api.getStations(
          rootState.registerFarm.parcelId,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async updateStations({ rootState }, id) {
      try {
        const xhr = await Api.updateStations(
          id,
          rootState.registerFarm.parcelId,
          rootState.registerFarm.selectedStations,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
  mutations: {
    setInitDate(state, value) {
      state.initDate = value;
    },
    setDataSource(state, value) {
      state.dataSource = value;
    },
    setEndDate(state, value) {
      state.endDate = value;
    },
    setIrrigationInitDate(state, value) {
      state.irrigationInitDate = value;
    },
    setSelectedProbe(state, value) {
      state.selectedProbe = value;
    },
    setIrrigationEndDate(state, value) {
      state.irrigationEndDate = value;
    },
    setPluviometerInitDate(state, value) {
      state.pluviometerInitDate = value;
    },
    setPluviometerEndDate(state, value) {
      state.pluviometerEndDate = value;
    },
    setSelectedDistance(state, value) {
      state.selectedDistance = value;
    },
    setAvaliableDistances(state, value) {
      state.avaliableDistances = value;
    },
  },
};
