<template>
  <f7-page class="main-advanced-services-agronomist">
    <f7-block class="tableContainer margin padding">
       <!-- Weather -->
       <div class="content-weather">
        <weather />
      </div>
      <!-- Weather -->

      <!-- Nutritional Following -->
      <div class="tableContainer" id="sensor">
        <NutritionalFollowingTable/>
      </div>
      <!-- /. Nutritional Following -->

      <!-- Geo Map -->
      <div>
        <div v-if="!loaded" class="loadingFalse tableContainer">
          <div class="preloader">
            <span class="preloader-inner">
              <span class="preloader-inner-circle"></span>
            </span>
          </div>
        </div>
        <div v-else class="raster-map-container">
          <div id="titulomap" class="title">{{ $t('vegetationIndex') }}</div>
          <button v-if="false" class="button-geotoolbox" @click="visitGisActions()">
            <span>GeoToolBox</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="icon-external-link">
              <path fill-rule="evenodd"
                d="M4.25 5.5a.75.75 0 00-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 00.75-.75v-4a.75.75 0 011.5 0v4A2.25 2.25 0 0112.75 17h-8.5A2.25 2.25 0 012 14.75v-8.5A2.25 2.25 0 014.25 4h5a.75.75 0 010 1.5h-5z"
                clip-rule="evenodd" />
              <path fill-rule="evenodd"
                d="M6.194 12.753a.75.75 0 001.06.053L16.5 4.44v2.81a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 000 1.5h2.553l-9.056 8.194a.75.75 0 00-.053 1.06z"
                clip-rule="evenodd" />
            </svg>
          </button>
          <button type="button" class="button-toggle-menu" :class="{ 'button-toggle-menu--active': showMenu }"
            @click="showMenu = !showMenu">
            <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24"
              width="20" height="20" xmlns="http://www.w3.org/2000/svg">
              <path
                d="m2.394 15.759s7.554 4.246 9.09 5.109c.165.093.333.132.492.132.178 0 .344-.049.484-.127 1.546-.863 9.155-5.113 9.155-5.113.246-.138.385-.393.385-.656 0-.566-.614-.934-1.116-.654 0 0-7.052 3.958-8.539 4.77-.211.115-.444.161-.722.006-1.649-.928-8.494-4.775-8.494-4.775-.502-.282-1.117.085-1.117.653 0 .262.137.517.382.655zm0-3.113s7.554 4.246 9.09 5.109c.165.093.333.132.492.132.178 0 .344-.049.484-.127 1.546-.863 9.155-5.113 9.155-5.113.246-.138.385-.393.385-.656 0-.566-.614-.934-1.116-.654 0 0-7.052 3.958-8.539 4.77-.211.115-.444.161-.722.006-1.649-.928-8.494-4.775-8.494-4.775-.502-.282-1.117.085-1.117.653 0 .262.137.517.382.655zm10.271-9.455c-.246-.128-.471-.191-.692-.191-.223 0-.443.065-.675.191l-8.884 5.005c-.276.183-.414.444-.414.698 0 .256.139.505.414.664l8.884 5.006c.221.133.447.203.678.203.223 0 .452-.065.689-.203l8.884-5.006c.295-.166.451-.421.451-.68 0-.25-.145-.503-.451-.682zm-8.404 5.686 7.721-4.349 7.72 4.349-7.72 4.35z"
                fill-rule="nonzero" />
            </svg>
          </button>
          <MenuSections :key="menuSectionsKey" class="hidable-menu" :class="{ 'hide-menu': !showMenu }" :menu="menu"
            :show-base-map="false" />
          <GeoJsonRasterMap v-if="loaded" :height="400" :name-map="'map'" :tree-locations-estate="estateGeometrie"
            :tree-locations-parcels="parcelGeometries" :raster-layer="rasterLayer" @menu-areas="menuAreas"
            @menu-images="menuImages" />
        </div>
      </div>
      <!-- /. Geo Map -->

      <!-- Graph Resume-->
      <div>
        <div v-if="!loaded" class="loadingFalse tableContainer">
          <div class="preloader">
            <span class="preloader-inner">
              <span class="preloader-inner-circle"></span>
            </span>
          </div>
        </div>
        <div v-else class="tableContainer margin-top graphresume">
          <MIRGraphResume v-if="loaded" />
          <!-- <MIRGraph :data-source="dataSource" /> -->
        </div>
      </div>
      <!-- /. Graph Resume-->


    </f7-block>
    <GoBack class="margin-top" :go-to-url-name="true" url-name="AdvancedServicesPage" />
  </f7-page>
</template>
<script>
import Api from '@/services/Api';
import { mapActions, mapState } from 'vuex';
import NutritionalFollowingTable from '@/components/Agronomy/AdvancedServices/NutritionalFollowingTable/index.vue';
import MIRGraphResume from '@/components/Agronomy/AdvancedServices/Dashboard/MIRGraphResume/index.vue';
import GoBack from '@/components/UtilityComponents/GoBack/index.vue';
import MenuSections from '@/components/Agronomy/AdvancedServices/GisActions/Menu/MenuSections.vue';
import weatherForecast from '../../../../components/Agronomy/AdvancedServices/Dashboard/weatherForecast.vue';
import GeoJsonRasterMap from '../../../../components/Gis/GeoJsonRasterMap/index.vue';

export default {
  name: 'DashboardPage',
  components: {
    weather: weatherForecast,
    NutritionalFollowingTable,
    GeoJsonRasterMap,
    MIRGraphResume,
    GoBack,
    MenuSections,
  },
  data() {
    return {
      loaded: false,
      ws: Api.getGeoServerWorkspace(),
      rasterLayer: {
        name: 'gis',
        visible: false,
        timeDimension: true,
        baseUrl: Api.getGeoServerProjectWorkSpace(),
        options: {
          layers: '',
          format: 'image/png',
          transparent: true,
          styles: 'NDVI',
        },
      },
      bbox: {},
      estateGeometrie: [],
      parcelGeometries: [],
      dataSource: [],
      menuSectionsKey: 'menu-sections-key',
      showMenu: false,
      menu: {
        areas: {},
        images: {},
      },
    };
  },
  computed: {
    ...mapState('registerFarm', ['farm', 'farmStr']),
    ...mapState('gis', ['currentBoundsWMSLayer']),
    ...mapState('registerFarm', ['parentParcelId', 'parcelsMirRs']),
    ...mapState('gisEditor', [
      'area',
      'parcelArea',
      'centroide',
      'parcelCentroide',
      'currentGeoFeature',
      'currentParcelGeoFeature',
    ]),
    ...mapState('hidrosoph', ['initDate', 'endDate']),
    ...mapState('contact', ['enabled']),
  },
  async mounted() {
    this.setParcelsMirRs([]);
    this.clearGeoJsonPoints();
    this.setCurrentRoute([
      {
        name: this.$t('Agronomy'),
        disabled: false,
        page: 'AgronomySamplesPage',
        active: false,
      },
      {
        name: this.$t('advancedServices'),
        disabled: false,
        page: 'AdvancedServicesPage',
        active: false,
      },
      {
        name: this.farmStr,
        disabled: true,
        page: 'AdvancedServicesPage',
        active: true,
      },
    ]);

    try {
      this.loaded = false;
      this.estateGeometrie = [this.currentGeoFeature];
      this.parcelGeometries = [this.currentParcelGeoFeature[0]];

      if (this.enabled) {
        const res = await this.getParcelServices();
        this.setParcelsMirRs(res.parcels);

        if (this.isParcelAllowed(this.currentParcelGeoFeature[0])) {
          this.rasterLayer.options.layers = `${this.ws}:${this.parentParcelId}`;
          await this.boundsWMSLayer(
            `${this.ws}:${this.parentParcelId}`
          );
          this.bbox = {
            ...this.currentBoundsWMSLayer.coverage.latLonBoundingBox,
          };
        }
      } else {
        this.rasterLayer.options.layers = `${this.ws}:${this.parentParcelId}`;
        await this.boundsWMSLayer(
          `${this.ws}:${this.parentParcelId}`
        );
        this.bbox = {
          ...this.currentBoundsWMSLayer.coverage.latLonBoundingBox,
        };
      }

      setTimeout(() => {
        if (this.currentParcelGeoFeature.length > 1) {
          this.resetParcelGeoFeatureStations({ min: 0, max: 1 });
        }
      }, 0);
    } catch (e) {
      // TODO: implementar error handler
      // this.$notifyDX(
      //   {
      //     message: this.$t(
      //       this.$helpers.getFilteredErrorMessage(
      //         JSON.parse(e.message).error
      //       )
      //     ),
      //     width: 550,
      //   },
      //   'error',
      //   4000
      // );
    } finally {
      this.loaded = true;
    }
  },
  methods: {
    isParcelAllowed(parcel) {
      return this.parcelsMirRs.find(
        (parcelMirRs) =>
          parcelMirRs.parcel_id ===
          +parcel.features[0].properties.parcel_id
      ).rs;
    },
    formatDs(data) {
      if (data[0][0].Data) {
        const formattedData = [];
        const allData = data[0][0].Data;
        for (const prop in allData) {
          allData[prop].forEach((el) => {
            formattedData.push({
              ...el,
              [`${prop}`]: prop.includes('SOIL')
                ? el.available_soil_moisture
                : el.value,
              source: data[0][0].Source,
            });
          });
        }

        this.dataSource = formattedData;
      }
    },
    visitGisActions() {
      this.$f7router.navigate(
        { name: 'GisActionsPage' },
        {
          animate: true,
          transition: 'f7-fade',
        }
      );
    },
    menuAreas(data) {
      this.menu.areas = data;
    },
    menuImages(data) {
      this.menu.images = data;
    },
    ...mapActions('gis', ['boundsWMSLayer', 'clearGeoJsonPoints']),
    ...mapActions('hidrosoph', ['getMirData']),
    ...mapActions('breadcrumb', ['setCurrentRoute']),
    ...mapActions('registerFarm', [
      'getParcelServices',
      'setParcelsMirRs',
    ]),
    ...mapActions('gisEditor', ['resetParcelGeoFeatureStations']),
  },
};
</script>
<style lang="scss" scoped>
@import './Dashboard.styles.scss';
</style>