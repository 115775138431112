<template>
  <div>
    <DxPopover
      :visible="showPopover"
      :shading="false"
      target="#notificationsBar"
      :position="{
        my: 'top center',
        at: 'right bottom',
      }"
      shading-color="rgba(0, 0, 0, 0.5)"
      @hiding="hide"
    >
      <SummaryAccordion :loaded="loaded" />
    </DxPopover>
  </div>
</template>

<script>
import { DxPopover } from 'devextreme-vue/popover';
import { mapActions, mapState } from 'vuex';
import { NOTIFY_TIME_LONG } from '@/js/constants';
import SummaryAccordion from '../SummaryAccordion/index.vue';

export default {
  components: {
    DxPopover,
    SummaryAccordion,
  },
  data() {
    return {
      loaded: false,
      dataSource: null,
    };
  },
  computed: {
    ...mapState('navbar', ['showPopover']),
    ...mapState('contact', ['company', 'address']),
  },
  async beforeMount() {
    try {
      await this.getReports({ all: true, params: { limit: 8 } });
      this.loaded = true;
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(error.message).error
            )
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    }
  },
  methods: {
    hide() {
      this.setShowPopover(false);
    },
    ...mapActions('navbar', ['setShowPopover']),
    ...mapActions('notifications', ['getReports']),
  },
};
</script>
