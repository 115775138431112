<template>
  <f7-page name="PrivacyPolicy">
    <div class="container-privacy-policy">
      <div class="back-to-main-page" @click="leave">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-arrow-left"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <line x1="5" y1="12" x2="19" y2="12"></line>
          <line x1="5" y1="12" x2="11" y2="18"></line>
          <line x1="5" y1="12" x2="11" y2="6"></line>
        </svg>
        <span>Volver</span>
      </div>
      <!-- <div class="text-align-center margin-bottom">
        <img style="width: 100px" :src="APP_LOGO" :alt="APP_NAME" />
      </div> -->
      <!-- <div class="text-separator-bottom">
        <div class="container-privacy-policy-title">
          GDPR Privacy Policy for Company Name
        </div>
        <div style="margin-bottom: 0.3rem">
          At Website Name, accessible at Website.com, one of our main
          priorities is the privacy of our visitors. This Privacy
          Policy document contains types of information that is
          collected and recorded by Website Name and how we use it.
        </div>
        <div>
          If you have additional questions or require more information
          about our Privacy Policy, do not hesitate to contact us
          through email at Email@Website.com
        </div>
      </div>-->

      <h1>Privacy Policy for Besafer</h1>

      <p>
        At Besafer, accessible from besafer.test, one of our main
        priorities is the privacy of our visitors. This Privacy Policy
        document contains types of information that is collected and
        recorded by Besafer and how we use it.
      </p>

      <p>
        If you have additional questions or require more information
        about our Privacy Policy, do not hesitate to contact us. Our
        Privacy Policy was generated with the help of
        <a href="https://www.gdprprivacynotice.com/"
          >GDPR Privacy Policy Generator</a
        >
      </p>

      <h2>General Data Protection Regulation (GDPR)</h2>
      <p>We are a Data Controller of your information.</p>

      <p>
        Besafer legal basis for collecting and using the personal
        information described in this Privacy Policy depends on the
        Personal Information we collect and the specific context in
        which we collect the information:
      </p>
      <ul>
        <li>Besafer needs to perform a contract with you</li>
        <li>You have given Besafer permission to do so</li>
        <li>
          Processing your personal information is in Besafer
          legitimate interests
        </li>
        <li>Besafer needs to comply with the law</li>
      </ul>

      <p>
        Besafer will retain your personal information only for as long
        as is necessary for the purposes set out in this Privacy
        Policy. We will retain and use your information to the extent
        necessary to comply with our legal obligations, resolve
        disputes, and enforce our policies.
      </p>

      <p>
        If you are a resident of the European Economic Area (EEA), you
        have certain data protection rights. If you wish to be
        informed what Personal Information we hold about you and if
        you want it to be removed from our systems, please contact us.
      </p>
      <p>
        In certain circumstances, you have the following data
        protection rights:
      </p>
      <ul>
        <li>
          The right to access, update or to delete the information we
          have on you.
        </li>
        <li>The right of rectification.</li>
        <li>The right to object.</li>
        <li>The right of restriction.</li>
        <li>The right to data portability</li>
        <li>The right to withdraw consent</li>
      </ul>

      <h2>Log Files</h2>

      <p>
        Besafer follows a standard procedure of using log files. These
        files log visitors when they visit websites. All hosting
        companies do this and a part of hosting services' analytics.
        The information collected by log files include internet
        protocol (IP) addresses, browser type, Internet Service
        Provider (ISP), date and time stamp, referring/exit pages, and
        possibly the number of clicks. These are not linked to any
        information that is personally identifiable. The purpose of
        the information is for analyzing trends, administering the
        site, tracking users' movement on the website, and gathering
        demographic information.
      </p>

      <h2>Cookies and Web Beacons</h2>

      <p>
        Like any other website, Besafer uses 'cookies'. These cookies
        are used to store information including visitors' preferences,
        and the pages on the website that the visitor accessed or
        visited. The information is used to optimize the users'
        experience by customizing our web page content based on
        visitors' browser type and/or other information.
      </p>

      <h2>Privacy Policies</h2>

      <p>
        You may consult this list to find the Privacy Policy for each
        of the advertising partners of Besafer.
      </p>

      <p>
        Third-party ad servers or ad networks uses technologies like
        cookies, JavaScript, or Web Beacons that are used in their
        respective advertisements and links that appear on Besafer,
        which are sent directly to users' browser. They automatically
        receive your IP address when this occurs. These technologies
        are used to measure the effectiveness of their advertising
        campaigns and/or to personalize the advertising content that
        you see on websites that you visit.
      </p>

      <p>
        Note that Besafer has no access to or control over these
        cookies that are used by third-party advertisers.
      </p>

      <h2>Third Party Privacy Policies</h2>

      <p>
        Besafer's Privacy Policy does not apply to other advertisers
        or websites. Thus, we are advising you to consult the
        respective Privacy Policies of these third-party ad servers
        for more detailed information. It may include their practices
        and instructions about how to opt-out of certain options.
      </p>

      <p>
        You can choose to disable cookies through your individual
        browser options. To know more detailed information about
        cookie management with specific web browsers, it can be found
        at the browsers' respective websites.
      </p>

      <h2>Children's Information</h2>

      <p>
        Another part of our priority is adding protection for children
        while using the internet. We encourage parents and guardians
        to observe, participate in, and/or monitor and guide their
        online activity.
      </p>

      <p>
        Besafer does not knowingly collect any Personal Identifiable
        Information from children under the age of 13. If you think
        that your child provided this kind of information on our
        website, we strongly encourage you to contact us immediately
        and we will do our best efforts to promptly remove such
        information from our records.
      </p>

      <h2>Online Privacy Policy Only</h2>

      <p>
        Our Privacy Policy applies only to our online activities and
        is valid for visitors to our website with regards to the
        information that they shared and/or collect in Besafer. This
        policy is not applicable to any information collected offline
        or via channels other than this website.
      </p>

      <h2>Consent</h2>

      <p>
        By using our website, you hereby consent to our Privacy Policy
        and agree to its terms.
      </p>
    </div>
  </f7-page>
</template>

<script>
import { mapActions } from 'vuex';
import { APP_NAME, APP_LOGO } from '../js/constants';

export default {
  name: 'PrivacyPolicyPage',
  data() {
    return {
      APP_NAME,
      APP_LOGO,
    };
  },
  methods: {
    ...mapActions('user', ['setShowPrivacyPolicy']),
    leave() {
      this.setShowPrivacyPolicy(false);
      this.$f7.views.main.router.navigate({
        name: 'Home',
      });
    },
  },
};
</script>

<style>
.container-privacy-policy {
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
  padding-bottom: 4rem;
}

.container-privacy-policy-title {
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 0.2rem;
}

.back-to-main-page {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  cursor: pointer;
}

.text-separator-bottom {
  margin-bottom: 1.7rem;
}

.container-privacy-policy h1 {
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
  font-weight: 500;
}

.container-privacy-policy h2 {
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
  font-weight: 500;
}
</style>
