<template>
  <div>
    <div class="disclosure-section">
      <div class="disclosure-section-title">
        {{ $t('alert.parameters') }}
      </div>
      <div class="disclosure-section-atributtes">
        <div>
          <label class="disclosure-section-label">
            {{ $t('geotoolbox.family')
            }}<span class="requiered-icon color-orange">*</span>
          </label>
          <DxSelectBox
            v-model="family"
            :data-source="familyList"
            display-expr="value"
            value-expr="id"
            :search-enabled="true"
            @opened="openedFamilySelectBox()"
            @content-ready="contentReady()"
          />
        </div>
        <div>
          <label class="disclosure-section-label">
            {{ $t('agronomySample.summary.sampleType')
            }}<span class="requiered-icon color-orange">*</span>
          </label>
          <DxLookup
            v-model="sampleType"
            :data-source="sampleTypeList"
            :grouped="true"
            :value="sampleType"
            display-expr="value"
            :search-enabled="false"
            :hide-on-outside-click="true"
            :disabled="family == null || family == ''"
            @opened="openedSampleTypeSelectBox()"
            @content-ready="contentReady()"
          >
            <DxDropDownOptions
              :hide-on-outside-click="true"
              :show-title="false"
            />
          </DxLookup>
        </div>
        <div>
          <label class="disclosure-section-label">
            {{ $t('foodSamples.parameter')
            }}<span class="requiered-icon color-orange">*</span>
          </label>
          <DxSelectBox
            v-model="parameter"
            :data-source="parameterList"
            display-expr="parameter"
            :search-enabled="true"
            :disabled="
              family == null ||
              family == '' ||
              sampleType == null ||
              sampleType == ''
            "
            @opened="openedParameterSelectBox()"
            @content-ready="contentReady()"
          />
        </div>
      </div>
    </div>
    <div class="disclosure-section">
      <div class="disclosure-section-title">{{ $t('filters') }}</div>
      <div class="disclosure-section-atributtes">
        <div>
          <label class="disclosure-section-label">
            {{ $t('dateRange')
            }}<span class="requiered-icon color-orange">*</span>
          </label>
          <div class="recepDate">
            <f7-input
              class="shadow"
              type="datepicker"
              :placeholder="$t('agronomySample.chooseDates')"
              :clear-button="true"
              :close-on-select="true"
              :value="rangeDate"
              :calendar-params="{
                closeOnSelect: true,
                rangePicker: true,
                dateFormat: {
                  month: 'numeric',
                  day: 'numeric',
                  year: 'numeric',
                },
                disabled: isDisabled,
              }"
              @calendar:change="rangeDateChange"
            />
          </div>
        </div>
        <div>
          <label class="disclosure-section-label">
            {{ $t('project') }}
          </label>
          <DxSelectBox
            v-model="project"
            :data-source="projectList"
            display-expr="value"
            value-expr="value"
            @opened="openedProjectSelectBox()"
            @content-ready="contentReady()"
          />
        </div>
        <div>
          <label class="disclosure-section-label">
            {{ $t('agronomySample.summary.parcel') }}
          </label>
          <DxSelectBox
            v-model="parcel"
            :data-source="parcelList"
            display-expr="value"
            value-expr="id"
            @opened="openedParcelSelectBox()"
            @content-ready="contentReady()"
          />
        </div>
      </div>
    </div>
    <div class="disclosure-buttons-creation">
      <div class="center-buttons">
        <button
          class="button-refresh"
          type="button"
          :disabled="creatingSet"
          @click="refreshData()"
        >
          {{ $t('refresh') }}
        </button>
        <button
          class="button-delete"
          type="button"
          :disabled="creatingSet"
          @click="deleteSets()"
        >
          <svg
            clip-rule="evenodd"
            fill-rule="evenodd"
            stroke-linejoin="round"
            stroke-miterlimit="2"
            viewBox="0 0 24 24"
            style="width: 1rem; height: 1rem"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m4.015 5.494h-.253c-.413 0-.747-.335-.747-.747s.334-.747.747-.747h5.253v-1c0-.535.474-1 1-1h4c.526 0 1 .465 1 1v1h5.254c.412 0 .746.335.746.747s-.334.747-.746.747h-.254v15.435c0 .591-.448 1.071-1 1.071-2.873 0-11.127 0-14 0-.552 0-1-.48-1-1.071zm14.5 0h-13v15.006h13zm-4.25 2.506c-.414 0-.75.336-.75.75v8.5c0 .414.336.75.75.75s.75-.336.75-.75v-8.5c0-.414-.336-.75-.75-.75zm-4.5 0c-.414 0-.75.336-.75.75v8.5c0 .414.336.75.75.75s.75-.336.75-.75v-8.5c0-.414-.336-.75-.75-.75zm3.75-4v-.5h-3v.5z"
              fill-rule="nonzero"
            />
          </svg>
        </button>
      </div>
      <div class="center-buttons">
        <button
          class="button-set"
          type="button"
          :disabled="creatingSet"
          @click="showSet()"
        >
          <span v-if="!creatingSet">{{
            $t('geotoolbox.showSet')
          }}</span>
          <svg
            v-if="creatingSet"
            class="ring"
            viewBox="25 25 50 50"
            stroke-width="5"
          >
            <circle cx="50" cy="50" r="20" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { DxSelectBox } from 'devextreme-vue/select-box';
import DataSource from 'devextreme/data/data_source';
import { mapActions, mapState } from 'vuex';
import { DxLookup, DxDropDownOptions } from 'devextreme-vue/lookup';
import moment from 'moment';
import EventBus from '@/services/event-bus';
import { AFTER_DATE_LIMIT } from '@/js/constants';

export default {
  name: 'ToolCreation',
  components: {
    DxSelectBox,
    DxLookup,
    DxDropDownOptions,
  },
  data() {
    return {
      family: '',
      sampleType: '',
      parameter: '',
      project: '',
      parcel: '',
      rangeDate: [],
      familyList: [],
      sampleTypeList: [],
      parameterList: [],
      projectList: [],
      parcelList: [],
      loaded: false,
      creatingSet: false,
      idCounter: {},
    };
  },
  computed: {
    ...mapState('registerFarm', ['farm', 'farmId']),
  },
  methods: {
    uniqueId(prefix = '$unique-id$') {
      if (!this.idCounter[prefix]) {
        this.idCounter[prefix] = 0;
      }

      const id = ++this.idCounter[prefix];
      if (prefix === '$unique-id$') {
        return `${id}`;
      }

      return `${prefix}${id}`;
    },
    isDisabled(date) {
      return (
        moment(date).isBefore(moment(AFTER_DATE_LIMIT), 'day') ||
        moment(date).isAfter(moment(), 'day')
      );
    },
    contentReady() {
      const arrayNotFoundItems = Array.from(
        document.getElementsByClassName('dx-empty-message')
      );

      arrayNotFoundItems.forEach((element) => {
        if (!this.loaded) {
          element.innerHTML = this.$t('loading');
        } else {
          element.innerHTML = this.$t('dataNotAvailable');
        }
      });

      this.loaded = false;
    },
    refreshData() {
      this.family = '';
      this.sampleType = '';
      this.parameter = '';
      this.project = '';
      this.parcel = '';
      this.rangeDate = [];
    },
    deleteSets() {
      EventBus.$emit('delete-sets');
    },
    validForm() {
      if (this.family === '' || this.family == null) {
        this.$notifyDX(
          {
            message: this.$t(
              'gisActions.toolCreation.familyRequired'
            ),
            width: 550,
          },
          'error',
          1000
        );
        return false;
      }

      if (this.sampleType === '' || this.sampleType == null) {
        this.$notifyDX(
          {
            message: this.$t(
              'gisActions.toolCreation.sampleTypeRequired'
            ),
            width: 550,
          },
          'error',
          1000
        );
        return false;
      }

      if (this.parameter === '' || this.parameter == null) {
        this.$notifyDX(
          {
            message: this.$t(
              'gisActions.toolCreation.parameterRequired'
            ),
            width: 550,
          },
          'error',
          1000
        );
        return false;
      }

      if (
        this.rangeDate?.[0] == null ||
        this.rangeDate?.[1] == null
      ) {
        this.$notifyDX(
          {
            message: this.$t(
              'gisActions.toolCreation.rangeDateRequired'
            ),
            width: 550,
          },
          'error',
          1000
        );
        return false;
      }

      return true;
    },
    async showSet() {
      if (!this.validForm()) {
        return;
      }

      this.creatingSet = true;

      try {
        const samplingDateAfter =
          this.rangeDate.length > 0
            ? moment(this.rangeDate[0]).format('YYYY-MM-DD')
            : null;
        const samplingDateBefore =
          this.rangeDate.length > 0
            ? moment(this.rangeDate[1]).format('YYYY-MM-DD')
            : null;

        const newSet = await this.getSamplesGeometry({
          atc_name: 'Agronomía',
          farm_id: this.farmId,
          samplingDate_after: samplingDateAfter,
          samplingDate_before: samplingDateBefore,
          sample_type_family_id: this.family,
          sample_type_id: this.sampleType.id,
          parcel_id: this.parcel,
          parameter: this.parameter.parameter_id,
          study: this.study,
        });

        if (newSet.length === 0 || !Array.isArray(newSet)) {
          this.$notifyDX(
            {
              message: this.$t(
                'gisActions.toolCreation.SetHasNoSamples'
              ),
              width: 550,
            },
            'error',
            1000
          );
          return;
        }

        const uniqueKey = this.uniqueId(
          `${this.parameter?.parameter ?? ''}-`
        );

        const title = [
          this.sampleType?.value,
          // this.parameter?.parameter,
          // moment().format('HHmmss')
          uniqueKey,
        ]
          .map((item) => {
            return item?.toString()?.replace(/ /g, '_') ?? '';
          })
          .filter((item) => item !== null && item !== '')
          .join('-');

        EventBus.$emit('add-set', {
          title,
          samples: newSet,
        });

        this.$notifyDX(
          {
            message: this.$t('gisActions.toolCreation.success'),
            width: 550,
          },
          'success',
          1000
        );
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(error.message).error
              )
            ),
            width: 550,
          },
          'error',
          1000
        );
      } finally {
        this.creatingSet = false;
      }
    },
    rangeDateChange(e) {
      if (e.length === 2) {
        this.rangeDate = e;
      } else if (e.length === 0) {
        this.rangeDate = [];
      }
    },
    async openedFamilySelectBox() {
      const familyTypes = await this.getSampleFilterGeotoolbox({
        attribute: 'sample_type_family',
      });

      this.familyList = new DataSource({
        store: familyTypes,
        key: 'id',
        paginate: true,
        pageSize: 10,
      });

      this.loaded = true;
    },
    async openedSampleTypeSelectBox() {
      const sampleTypes = await this.getSampleFilterGeotoolbox({
        attribute: 'sample_type_family',
        sampleTypeForGeometry: this.family,
      });

      const allSampleTypes = [];

      sampleTypes.forEach((sampleType) => {
        sampleType.sample_types?.forEach((item) => {
          allSampleTypes.push({
            ...sampleType,
            value: item.value,
            id: item.id,
          });
        });
      });

      this.sampleTypeList = new DataSource({
        store: allSampleTypes,
        key: 'sample_subfamily_type_id',
        group: 'sample_subfamily_type',
        paginate: true,
        pageSize: 10,
      });

      this.loaded = true;
    },
    async openedParameterSelectBox() {
      const elements = await this.getParameters({
        atc: 'Agronomía',
        sampleType: this.sampleType.id,
      });

      this.parameterList = new DataSource({
        store: elements,
        key: 'id',
        paginate: true,
        pageSize: 10,
      });

      this.loaded = true;
    },
    async openedProjectSelectBox() {
      const projects = await this.getSampleFilterGeotoolbox({
        attribute: 'study',
      });

      this.projectList = new DataSource({
        store: projects,
        key: 'id',
        paginate: true,
        pageSize: 10,
      });

      this.loaded = true;
    },
    async openedParcelSelectBox() {
      const parcels = await this.getSampleFilterGeotoolbox({
        attribute: 'parcel',
      });

      this.parcelList = new DataSource({
        store: parcels,
        key: 'id',
        paginate: true,
        pageSize: 10,
      });

      this.loaded = true;
    },
    ...mapActions('samples', ['getSampleFilterGeotoolbox']),
    ...mapActions('alertsParams', ['getParameters']),
    ...mapActions('gis', ['getSamplesGeometry']),
  },
};
</script>

<style scoped lang="scss">
@import '../GeoToolBoxTab.styles.scss';
</style>
