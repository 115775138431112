<template>
  <f7-page class="main-gis-comparator">
    <div v-if="!loaded" class="loadingFalse">
      <div class="preloader">
        <span class="preloader-inner">
          <span class="preloader-inner-circle"></span>
        </span>
      </div>
    </div>
    <div v-else class="tableContainer">
      <div class="filterButtonPosition">
        <f7-button
          fill
          class="whiteFontColor button-filter"
          panel-toggle="right"
        >
          <span class="ic-filter"></span> {{ $t('filter') }}
        </f7-button>
      </div>
      <GisComparatorTable />
    </div>

    <div v-if="loaded">
      <f7-row class="no-margin padding-top-half">
        <f7-col>
          <GoBack
            :go-to-url-name="true"
            url-name="TimeDimensionMapPage"
          />
        </f7-col>
        <f7-col class="display-flex justify-content-flex-end">
          <DxButton
            :disabled="
              leftComparator === '' || rightComparator === ''
            "
            :text="$t('comparative')"
            type="default"
            @click="goToComparative"
          />
        </f7-col>
      </f7-row>
    </div>
  </f7-page>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import GisComparatorTable from '@/components/Agronomy/AdvancedServices/Dashboard/GisComparatorTable/index.vue';
import GoBack from '@/components/UtilityComponents/GoBack/index.vue';
import DxButton from 'devextreme-vue/button';
import EventBus from '@/services/event-bus';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  components: {
    GisComparatorTable,
    GoBack,
    DxButton,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState('gisComparator', [
      'leftComparator',
      'rightComparator',
    ]),
    ...mapState('gis', ['selectedIndex']),
    ...mapState('registerFarm', ['farmStr', 'parcelStr']),
  },
  async beforeMount() {
    try {
      this.loaded = false;

      this.setCurrentRoute([
        {
          name: this.$t('Agronomy'),
          disabled: false,
          page: 'AgronomySamplesPage',
          active: false,
        },
        {
          name: this.$t('advancedServices'),
          disabled: false,
          page: 'AdvancedServicesPage',
          active: false,
        },
        {
          name: this.farmStr,
          disabled: false,
          page: 'DashboardPage',
          active: false,
        },
        {
          name: this.parcelStr,
          disabled: false,
          page: 'TimeDimensionMapPage',
          active: false,
        },
        {
          name: this.$t('comparative'),
          disabled: true,
          page: 'GisComparatorPage',
          active: true,
        },
      ]);

      this.setCurrentFilter('gisComparator');

      this.setLeftComparator('');
      this.setRightComparator('');
      const res = await this.getFarms();

      this.setComparatorDs(this.formatComparatorData(res));

      EventBus.$on('GisComparatorPage', async (params) => {
        try {
          if (params) {
            this.loaded = false;

            const data = await this.getFarms(params);

            this.setComparatorDs(this.formatComparatorData(data));
            this.loaded = true;
          } else {
            this.loaded = false;

            const data = await this.getFarms();

            this.setComparatorDs(this.formatComparatorData(data));
            this.loaded = true;
          }
        } catch (error) {
          this.$notifyDX(
            {
              message: this.$t(
                this.$helpers.getFilteredErrorMessage(
                  JSON.parse(error.message).error
                )
              ),
              width: 550,
            },
            'error',
            NOTIFY_TIME_LONG
          );
        }
      });
    } catch (e) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(e.message).error
            )
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    } finally {
      this.loaded = true;
    }
  },
  beforeDestroy() {
    EventBus.$off('GisComparatorPage');
  },
  methods: {
    goToComparative(e) {
      this.$f7router.navigate(
        { name: 'TimeDimensionComparatorPage' },
        {
          animate: true,
          transition: 'f7-fade',
        }
      );
    },
    formatComparatorData(data) {
      return data.map((farm) => {
        return {
          leftComparator: false,
          rightComparator: false,
          ...farm,
        };
      });
    },
    ...mapActions('gisComparator', [
      'getFarms',
      'setComparatorDs',
      'setLeftComparator',
      'setRightComparator',
    ]),
    ...mapActions('breadcrumb', ['setCurrentRoute']),
    ...mapActions('filter', ['setCurrentFilter']),
  },
};
</script>
