var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f7-page',[(_vm.loaded)?_c('div',[_c('div',{staticClass:"tableContainer"},[_c('FoodStatsTable',{attrs:{"aggregated-data":_vm.data}})],1),_vm._v(" "),_c('br'),_vm._v(" "),_c('div',{staticClass:"tableContainerNoPadding"},[(
          _vm.selectedParameters.length >= 1 &&
          !_vm.isComparative &&
          _vm.selectedSampleTypes.length === 1
        )?_c('StatsGraphParameter'):_vm._e(),_vm._v(" "),(
          _vm.selectedSampleTypes.length === 1 &&
          !_vm.isComparative &&
          _vm.selectedParameters.length === 0
        )?_c('StatsGraphSamples'):_vm._e(),_vm._v(" "),(
          _vm.selectedSampleTypes.length > 1 &&
          !_vm.isComparative &&
          _vm.selectedParameters.length === 0
        )?_c('StatsGraphSamplesMultiple'):_vm._e(),_vm._v(" "),(
          _vm.selectedSampleTypes.length > 1 &&
          !_vm.isComparative &&
          _vm.selectedParameters.length >= 1
        )?_c('StatsGraphParameterMultiple'):_vm._e(),_vm._v(" "),(
          _vm.selectedSampleTypes.length === 1 &&
          _vm.isComparative &&
          _vm.selectedParameters.length === 0 &&
          !_vm.isGlobalAGQ
        )?_c('StatsGraphComparativeSamples'):_vm._e(),_vm._v(" "),(
          _vm.selectedSampleTypes.length === 1 &&
          _vm.isComparative &&
          _vm.selectedParameters.length >= 1 &&
          !_vm.isGlobalAGQ
        )?_c('StatsGraphComparativeParameter'):_vm._e(),_vm._v(" "),(
          _vm.selectedSampleTypes.length > 1 &&
          _vm.isComparative &&
          _vm.selectedParameters.length === 0 &&
          !_vm.isGlobalAGQ
        )?_c('StatsGraphComparativeParameterMultipleSamples'):_vm._e()],1)]):_vm._e(),_vm._v(" "),(_vm.notificationMessage.length > 0)?_c('NotificationSpinner',{attrs:{"message":_vm.notificationMessage}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }