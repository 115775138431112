<template>
  <div class="tableContainer">
    <div class="filterButtonPosition" style="right: 64px">
      <f7-button
        fill
        class="whiteFontColor button-filter"
        panel-toggle="right"
      >
        <span class="ic-filter"></span> {{ $t('filter') }}
      </f7-button>
    </div>
    <DxDataGrid
      :data-source="store"
      :show-borders="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :show-colon="false"
      :remote-operations="true"
      :hover-state-enabled="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :no-data-text="$t('noDataTextDetail')"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing"
      @content-ready="init"
    >
      <DxScrolling mode="standard" />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="miningSamplesDetailsTable"
      />
      <DxSelection mode="single" />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="[5, 10, 20]"
        :show-info="true"
        info-text="{2} items"
        :visible="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="300"
        :placeholder="$t('search')"
      />
      <DxExport :enabled="true" />

      <DxColumn
        data-field="parameter_group"
        :caption="$t('group')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="parameter"
        :caption="$t('foodSamples.parameter')"
        :allow-header-filtering="false"
      />

      <DxColumn
        data-field="value.ResultadoPresentacion"
        cell-template="cellTemplateResultadoPresentacion"
        :caption="$t('foodSamples.result')"
        :allow-header-filtering="false"
      />

      <template #cellTemplateResultadoPresentacion="{ data }">
        <div>
          {{
            data.data.value.ResultadoPresentacion.includes(
              'En Proceso'
            )
              ? $t('inProcess')
              : data.data.value.ResultadoPresentacion
          }}
        </div>
      </template>

      <DxColumn
        data-field="value.unit"
        :caption="$t('foodSamples.unit')"
        :allow-header-filtering="false"
      />

      <DxColumn
        data-field=""
        :caption="$t('foodSamples.range')"
        :allow-header-filtering="false"
        cell-template="cellTemplateRange"
      />

      <template #cellTemplateRange="{ data }">
        <div>
          {{ data.data.value.r_min }} - {{ data.data.value.r_max }}
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxStateStoring,
  DxPager,
  DxPaging,
  DxScrolling,
  DxExport,
  DxSelection,
} from 'devextreme-vue/data-grid';

import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxStateStoring,
    DxPager,
    DxPaging,
    DxScrolling,
    DxExport,
    DxSelection,
  },
  props: {
    store: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState('samples', ['reportSampleCode']),
  },
  methods: {
    init(e) {
      this.setSampleDetails(e.component.getDataSource().items());
    },
    onExporting(e) {
      this.setIsExporting(true);
      e.component.beginUpdate();
      e.component.columnOption('ID', 'visible', true);
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(
        `${`${this.$t(
          'agronomySample.summary.sampleSummary'
        )}-${this.$t('Mining')}`}`
      );

      exportDataGrid({
        component: e.component,
        worksheet,
      })
        .then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(
              new Blob([buffer], {
                type: 'application/octet-stream',
              }),
              `besafer-${this.reportSampleCode}.xlsx`
            );
          });
        })
        .then(() => {
          e.component.columnOption('ID', 'visible', false);
          e.component.endUpdate();
        });

      e.cancel = true;
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items;

      toolbarItems.forEach((item) => {
        if (item.name === 'searchPanel') {
          item.location = 'before';
        }
        if (item.name === 'exportButton') {
          item.location = 'after';
        }

        if (item.name === 'groupPanel') {
          item.location = 'after';
        }
      });
    },
    ...mapActions('miningSamples', ['setSampleDetails', 'setIsExporting']),
  },
};
</script>

<style scoped>
::v-deep .dx-toolbar-after {
  left: initial !important;
}
</style>
