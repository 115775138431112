// Don't forget to import the repository if you create a new one!
import UserRepository from './userRepository';
import CompanyRepository from './companyRepository';

/**
 * This object will contain all the repositories needed.
 * You can define a descriptive name:
 * @example
 *  const repositories = {
 *    name: NameRepository,
 *  };
 */
const repositories = {
  user: UserRepository,
  company: CompanyRepository,
};
/**
 * Here is where the magic happens :)
 * This guy will give you the repo you need.
 * @param String (name of the repo)
 * @example
 * import RepositoryFactory from 'path/to/here'
 *
 * const nameRepository = RepositoryFactory.get('name');
 *
 * nameRepository.sayMyName()
 *
 */
// Here is where the magic happens :) This guy here will give you the repo you ask for.
const RepositoryFactory = {
  get: name => repositories[name],
};

export default RepositoryFactory;
