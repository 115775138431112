<template>
  <div class="menu-section">
    <div class="menu-title">
      <div class="input-label-container">
        <div class="input-container">
          <input
            id="Muestras"
            v-model="generalCheckedAreas"
            type="checkbox"
            class="input"
            @change="toggleAll()"
          />
        </div>
        <div class="label-container">
          <label for="Muestras" class="label">
            {{ $t('samples') }}
          </label>
        </div>
      </div>
      <button
        class="menu-title-arrow"
        type="button"
        @click="showMenu = !showMenu"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          style="width: 1.1rem; height: 1.1rem"
          :class="[showMenu ? 'rotate-180' : 'rotate-0']"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          />
        </svg>
      </button>
    </div>
    <div v-show="showMenu" class="menu-options">
      <div v-for="(item, index) of sets" :key="`set-${index}`">
        <div class="input-label-container">
          <div class="input-container">
            <input
              :id="item.name"
              v-model="item.checked"
              type="checkbox"
              class="input"
              @change="toggleSet(item)"
            />
          </div>
          <div class="label-container">
            <label :for="item.name" class="label">
              <svg
                v-if="item.sample_type_family_id == 2"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                :style="`width: 0.9rem; height: 0.9rem; fill: currentColor; color: ${item.color}`"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              <svg
                v-if="item.sample_type_family_id == 4"
                clip-rule="evenodd"
                fill-rule="evenodd"
                stroke-linejoin="round"
                stroke-miterlimit="2"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                :style="`width: 0.9rem; height: 0.9rem; fill: currentColor; color: ${item.color}`"
              >
                <circle
                  cx="11.998"
                  cy="11.998"
                  fill-rule="nonzero"
                  r="9.998"
                />
              </svg>
              <svg
                v-if="item.sample_type_family_id == 5"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                :style="`width: 0.9rem; height: 0.9rem; fill: currentColor; color: ${item.color}`"
              >
                <path d="M24 22h-24l12-20z" />
              </svg>
              <svg
                v-if="item.sample_type_family_id == 6"
                clip-rule="evenodd"
                fill-rule="evenodd"
                stroke-linejoin="round"
                stroke-miterlimit="2"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                stroke="currentColor"
                :style="`width: 0.9rem; height: 0.9rem; fill: currentColor; color: ${item.color}`"
              >
                <path
                  d="m21 4c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1z"
                  fill-rule="nonzero"
                />
              </svg>
              <span class="label-text" :title="item.name">{{
                item.name
              }}</span>
            </label>
          </div>
        </div>
      </div>

      <div
        v-if="areas.length > 0 && sets.length > 0"
        class="break-line"
      ></div>

      <div v-for="(item, index) of areas" :key="`sample-${index}`">
        <div class="input-label-container">
          <div class="input-container">
            <input
              :id="item.name"
              v-model="item.checked"
              type="checkbox"
              class="input"
              @change="toggleArea(item)"
            />
          </div>
          <div class="label-container">
            <label :for="item.name" class="label">
              <svg
                v-if="item.sample_type_family_id == 2"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                :style="`width: 0.9rem; height: 0.9rem; fill: currentColor; color: ${item.color}`"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              <svg
                v-if="item.sample_type_family_id == 4"
                clip-rule="evenodd"
                fill-rule="evenodd"
                stroke-linejoin="round"
                stroke-miterlimit="2"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                :style="`width: 0.9rem; height: 0.9rem; fill: currentColor; color: ${item.color}`"
              >
                <circle
                  cx="11.998"
                  cy="11.998"
                  fill-rule="nonzero"
                  r="9.998"
                />
              </svg>
              <svg
                v-if="item.sample_type_family_id == 5"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                :style="`width: 0.9rem; height: 0.9rem; fill: currentColor; color: ${item.color}`"
              >
                <path d="M24 22h-24l12-20z" />
              </svg>
              <svg
                v-if="item.sample_type_family_id == 6"
                clip-rule="evenodd"
                fill-rule="evenodd"
                stroke-linejoin="round"
                stroke-miterlimit="2"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                stroke="currentColor"
                :style="`width: 0.9rem; height: 0.9rem; fill: currentColor; color: ${item.color}`"
              >
                <path
                  d="m21 4c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1z"
                  fill-rule="nonzero"
                />
              </svg>
              <span class="label-text" :title="item.name">{{
                item.name
              }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/services/event-bus';

export default {
  name: 'MenuMuestras',
  props: {
    menu: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showMenu: true,
      generalCheckedAreas: true,
    };
  },
  computed: {
    areas() {
      if (this.menu?.samples == null) return [];
      return Object.entries(this.menu.samples).map(([key, value]) => {
        return {
          ...value.properties,
          name: value.properties.sample_type,
          layers: value.layers,
          checked: true,
        };
      });
    },
    sets() {
      if (this.menu?.sets == null) return [];
      return Object.entries(this.menu.sets)
        .map(([key, value]) => {
          return {
            ...value.properties,
            name: value.properties.title,
            layers: value.layers,
            checked: true,
          };
        })
        .reverse();
    },
  },
  methods: {
    toggleArea(area) {
      if (!this.generalCheckedAreas) return;

      EventBus.$emit('toggle-sample', {
        family: area.sample_type_family,
        family_id: area.sample_type_family_id,
        layers: area.layers,
        checked: area.checked,
      });
    },
    toggleAll() {
      // EventBus.$emit('toggle-sample-all', {
      //   checked: this.generalCheckedAreas,
      //   layers: this.areas.map((area) => area.layers),
      // });

      this.areas.forEach((element) => {
        EventBus.$emit('toggle-sample', {
          family: element.sample_type_family,
          family_id: element.sample_type_family_id,
          layers: element.layers,
          checked:
            this.generalCheckedAreas === false
              ? false
              : element.checked,
        });
      });

      this.sets.forEach((element) => {
        EventBus.$emit('toggle-sample-set', {
          family: element.sample_type_family,
          family_id: element.sample_type_family_id,
          layers: element.layers,
          checked:
            this.generalCheckedAreas === false
              ? false
              : element.checked,
        });
      });
    },
    toggleSet(area) {
      if (!this.generalCheckedAreas) return;

      EventBus.$emit('toggle-sample-set', {
        family: area.sample_type_family,
        family_id: area.sample_type_family_id,
        layers: area.layers,
        checked: area.checked,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import './MenuSections.styles.scss';
</style>
