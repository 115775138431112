var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"margin-top"},[_c('div',{staticClass:"filter-box"},[_c('div',{staticClass:"label-input"},[_vm._v("\n      "+_vm._s(_vm.$t('agronomySample.summary.sampleType'))),_c('span',{staticClass:"requiered-icon color-orange"},[_vm._v("*")])]),_vm._v(" "),_c('div',{staticClass:"marginTop5"},[_c('DxLookup',{attrs:{"data-source":_vm.sampleTypes,"value":_vm.selectedSampleTypes,"display-expr":"value","search-enabled":false,"hide-on-outside-click":true},on:{"value-changed":_vm.sampleTypesChanged,"opened":function($event){return _vm.openedSampleTypes()},"content-ready":function($event){return _vm.contentReady()}}},[_c('DxDropDownOptions',{attrs:{"hide-on-outside-click":true,"show-title":false}})],1)],1),_vm._v(" "),_c('div',{staticClass:"label-input margin-top-half"},[_vm._v("\n      "+_vm._s(_vm.$t('alert.parameters'))+"\n      "),_c('span',{staticClass:"requiered-icon color-orange"},[_vm._v("*")])]),_vm._v(" "),_c('div',{staticClass:"marginTop5"},[_c('DxSelectBox',{attrs:{"data-source":_vm.parameters,"value":_vm.selectedParameters,"display-expr":"parameter","search-enabled":true},on:{"value-changed":_vm.parameterChanged,"opened":function($event){return _vm.openedParameters()},"content-ready":function($event){return _vm.contentReady()}}})],1),_vm._v(" "),_c('div',{staticClass:"label-input margin-top-half"},[_vm._v("\n      "+_vm._s(_vm.$t('dateRange'))),_c('span',{staticClass:"requiered-icon color-orange"},[_vm._v("*")])]),_vm._v(" "),_c('div',{staticClass:"recepDate marginTop5"},[_c('f7-input',{staticClass:"shadow",attrs:{"type":"datepicker","placeholder":_vm.$t('date'),"clear-button":true,"close-on-select":true,"value":_vm.calculatedDate,"calendar-params":{
          closeOnSelect: true,
          rangePicker: true,
          dateFormat: {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
          },
        }},on:{"calendar:change":_vm.setDates}})],1)]),_vm._v(" "),_c('div',{staticClass:"label-input margin-top-half"},[_vm._v("\n    "+_vm._s(_vm.$t('Gis.Zone'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"marginTop5"},[_c('DxSelectBox',{attrs:{"data-source":_vm.zones,"value":_vm.selectedZone,"display-expr":"value","search-enabled":true},on:{"value-changed":_vm.zonesChanged,"opened":function($event){return _vm.openedZones()},"content-ready":function($event){return _vm.contentReady()}}})],1),_vm._v(" "),_c('div',{staticClass:"label-input margin-top-half"},[_vm._v("\n    "+_vm._s(_vm.$t('environmentSamples.samplingPoint'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"marginTop5"},[_c('DxSelectBox',{attrs:{"data-source":_vm.samplingPoints,"value":_vm.selectedSamplingPoint,"display-expr":"value","search-enabled":true},on:{"value-changed":_vm.samplingPointChanged,"opened":function($event){return _vm.openedSamplingPoint()},"content-ready":function($event){return _vm.contentReady()}}})],1),_vm._v(" "),_c('div',{staticClass:"margin-top-half"},[_c('div',{staticClass:"content-toogle"},[_c('div',{staticClass:"title-label"},[_c('strong',{staticClass:"label-input no-margin-left"},[_vm._v("\n          "+_vm._s(_vm.$t('AggregationByMonth'))+"\n        ")]),_vm._v(" "),_c('span',{staticClass:"analysis-risk-info",attrs:{"id":"global-agq-info"},on:{"mouseenter":_vm.toggleWithAnimationOptionsGlobalAGQ,"mouseleave":_vm.toggleWithAnimationOptionsGlobalAGQ}},[_c('i',{staticClass:"f7-icons"},[_vm._v(" info_circle_fill ")])])]),_vm._v(" "),_c('div',[_c('DxPopover',{attrs:{"width":300,"visible":_vm.withAnimationOptionsVisibleGlobalAGQ,"animation":_vm.animationConfig,"target":"#global-agq-info","position":"top"}},[_c('div',{staticClass:"padding-horizontal-half"},[_c('p',[_vm._v("\n              "+_vm._s(_vm.$t('environmentStatsMessage'))+"\n            ")])])])],1),_vm._v(" "),_c('DxSwitch',{attrs:{"on-value-changed":_vm.groupByMonthChanged,"value":_vm.groupByMonth}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }