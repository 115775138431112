var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"css":false},on:{"leave":_vm.leave}},[_c('f7-page',{staticClass:"main-time-dimension"},[_c('div',{staticClass:"content-devx"},[(!_vm.loadedGIS)?_c('div',{staticClass:"loadingFalse"},[_c('div',{staticClass:"preloader"},[_c('span',{staticClass:"preloader-inner"},[_c('span',{staticClass:"preloader-inner-circle"})])])]):_c('div',[_c('f7-row',[_c('f7-col',[_c('GISWMSTimeDimensionMap',{attrs:{"zoom":_vm.zoom,"name-map":_vm.mainMap,"layer":_vm.layer,"bbox":_vm.bbox,"height":'540px'}})],1),_vm._v(" "),_c('f7-col',[_c('div',{staticClass:"display-flex align-items-flex-end justify-content-space-between"},[_c('DxForm',{attrs:{"label-location":"top"}},[_c('DxItem',{attrs:{"editor-options":{
                    items: _vm.indexes,
                    onValueChanged: _vm.onIndexChange,
                    value: _vm.selectedIndex,
                    valueExpr: 'key',
                    displayExpr: 'value',
                    placeholder: _vm.$t('select'),
                  },"data-field":"","editor-type":"dxSelectBox"}},[_c('DxLabel',{attrs:{"text":_vm.$t('index')}})],1)],1),_vm._v(" "),_c('GisTimeDimensionDatePicker')],1),_vm._v(" "),(_vm.loadedTimeLine)?_c('div',{staticClass:"margin-top"},[_c('TimelineGraph',{attrs:{"timeline-indexes":_vm.timelineIndexes,"index":_vm.selectedIndex}})],1):_vm._e()])],1),_vm._v(" "),_c('f7-row',{staticClass:"no-margin padding-top-half"},[_c('f7-col',[_c('GoBack',{attrs:{"go-to-url-name":true,"url-name":"DashboardPage"}})],1),_vm._v(" "),_c('f7-col',{staticClass:"display-flex justify-content-flex-end"},[_c('DxButton',{attrs:{"type":"default","text":_vm.$t('comparative')},on:{"click":_vm.goToComparative}})],1)],1)],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }