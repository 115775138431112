<template>
  <div id="main" class="main-weather-component">
    <div class="tableContainer">
      <div id="body" class="forecast">
        <div class="vert-align">
          <div class="dark-sky-widget default-embed" data-name="default">
            <p v-if="!currentDayExists" style="text-align: center">
              {{ $t('noClimateData') }}
            </p>

            <div v-if="currentDayExists" class="widget-container">
              <div id="header">
                <p id="location">
                  {{ $t('weather') }}
                </p>

                <i class="fa fa-search padding-half color-green" style="cursor: pointer" @click="goToWeatherGraph"></i>
              </div>
              <div class="container">
                <div v-if="currentDayExists" class="current">
                  <img width="78" height="78" :src="currentDayIcon" :alt="currentDay.icon + ' Icon'" />
                  <div class="current-temp" style="font-size: 30px;">
                    <strong>{{ currentDay.temp }}˚</strong>

                    <div class="wind-section">
                      <img width="20" height="20" :src="windBlowIcon" />
                      <div>
                        {{ currentDay.windSpeed }} {{ windUnits }}
                      </div>
                    </div>
                  </div>
                  <div id="current-summary">
                    <p>
                      {{
                        $t(`weatherWidget.icon_${currentDay.code}`)
                      }}
                    </p>
                    <p>
                      {{ currentDay.hourlySummary }}
                    </p>
                  </div>
                </div>

                <!-- botones para desplegar -->
                <!-- <div>
                  <button @click="semanal =! semanal">
                    Ver Semana Completa
                  </button>
                </div> -->
                <!-- botones para desplegar -->
                
                <div id="daily" class="content-day">
                  <day v-for="(day, index) in forecast" :key="day.name + index" :name="day.name" :icon="day.icon"
                    :high-temp="day.highTemp" :low-temp="day.lowTemp" :sun="day.sun" :water="day.water" :wind="day.wind">
                  </day>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Day from './day.vue';

export default {
  name: 'VueWeatherComponent',
  components: {
    Day,
  },

  props: {
    title: {
      type: String,
      default: 'Forecast',
    },

    windUnits: {
      type: String,
      default: 'kph',
    },

    currentDay: {
      type: Object,
      default() {
        return {};
      },
    },

    forecast: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      semanal: false
    };
  },

  computed: {
    currentDayExists() {
      return !this.$helpers.isEmptyObject(this.currentDay);
    },
    currentDayIcon() {
      return require(`../../../../../../assets/weather-icons/${this.currentDay.icon}.png`)
        .default;
    },
    windBlowIcon() {
      return require(`../../../../../../assets/weather-icons/wind_blow.png`)
        .default;
    },
  },
  methods: {
    goToWeatherGraph() {
      this.$f7router.navigate(
        { name: 'WeatherGraphsPage' },
        {
          animate: true,
          transition: 'f7-fade',
        }
      );
    },
  },
};
</script>

<style scoped>
/* Set overall properties to border box */
#main {
  box-sizing: border-box;
  font-size: 16px;
  height: 100%;
}
.location{
  color: #595295; 
  font-size: 20px;
}
#daily {
  height: 48px;
  overflow-y: auto; /* Agregamos el scroll vertical */
  padding: 1%;
}
/* #default-attribution {
  font-size: 0.9em;
  text-decoration: underline;
} */

/* Header settings */
#header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid #9892cc;
  color: var(--his-berenjena);
  /*visibility: hidden;*/
}

#header p {
  margin: 0 0 6px;
  padding: 0 5px;
}

#header p:first-child {
  font-size: 1em;
  font-weight: bold;
}

#header p:last-child {
  font-size: 0.8em;
  padding-right: 15px;
}

#loading {
  font-size: 3em;
}

.current {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 69px;
}

#current-temp {
  margin: 0 5%;
  font-size: 3.5em;
  color: rgb(51, 51, 51);
}

#current-temp span {
  display: block;
  text-align: left;
  font-size: 0.25em;
}

#current-temp span:first-child {
  margin-top: -12px;
}

#current-temp span:last-child {
  font-size: 0.2em;
  margin-top: 5px;
}

#current canvas {
  width: 80px;
  height: 80px;
}

#current-summary {
  font-size: 1em;
  color: rgb(51, 51, 51);
}

#current-summary p:first-child {
  font-weight: bold;
}

#current-summary p:last-child {
  font-size: 0.8em;
  margin-top: -12px;
}

.content-day {
  display: grid;
  /* grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 6px;
  grid-row-gap: 6px; */
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

/* Customization page styles */

.header {
  display: flex;
  flex-direction: column;
  background-color: #f1f1f1;
  margin-bottom: 50px;
  /*padding-bottom: 100px;*/
}

.header .section-header {
  display: flex;
  justify-content: flex-end;
  color: #ffffff;
  background-color: #333333;
}

.header .section-header p {
  margin-right: 5%;
  font-size: 1em;
  font-weight: 400;
}

.header .section-header p a {
  color: #ffffff;
  text-decoration: underline;
  font-size: 1em;
}

.header .section-header p a:hover {
  text-decoration: none;
}

.embed-name {
  text-align: center;
  font-size: 1.5em;
}

#header {
  visibility: visible;
}

#header p a {
  color: #333;
}

.selected {
  background-color: #eeeeee;
}

#customize-container {
  display: flex;
  flex-direction: column;
}

#custom-tools {
  display: flex;
  flex-direction: column;
}

#custom-controls {
  display: flex;
  justify-content: space-around;
  width: 60%;
  margin: 0 auto;
}

#custom-map {
  /*margin: 0 auto;*/
  display: flex;
  justify-content: center;
}

.content-container {
  text-align: center;
  margin: 25px 0;
  display: none;
}

.content-container input {
  box-sizing: content-box;
  width: 75%;
  height: 20px;
  padding: 3px 3px;
  border-radius: 3px;
  border: 2px solid #c7c7c7;
}

.column p,
form p {
  padding: 0 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.column p span input,
form p span input:not(#submit) {
  padding: 5px 3px;
  border-radius: 3px;
  border: 1px solid grey;
}

#gmaps p span input {
  margin: 0 15px;
}

.column p:hover,
form p:hover {
  background-color: #eee;
}

form p:nth-child(4):hover {
  background-color: #ffffff;
}

.column p span:first-child {
  margin-right: 5px;
}

.description {
  margin: 0 auto;
  width: 60%;
}

button,
#submit {
  /*transition: .3s;*/
  background-color: #228fff;
  color: white;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  border: 2px solid #1f8cfd;
  border-radius: 3px;
  padding: 3px 12px;
  text-transform: uppercase;
}

button:hover {
  /*transition: .3s;*/
  cursor: pointer;
  font-weight: 400;
  /*padding: 5px 15px;*/
  /*font-size: 1.6em;*/
}

.show-hide-map {
  margin: 0 auto;
}

#submit,
.show-hide-map button {
  transition: 0.3s;
  font-size: 1em;
}

#submit:hover,
.show-hide-map button:hover {
  cursor: pointer;
  font-weight: 400;
  /*background-color: #0C4075;*/
  /*font-size: 1em;*/
  /*padding: 3px 12px;*/
}

.button-generate,
.button-reset {
  margin: 10px 0;
  text-align: center;
  height: 50px;
}

.reset {
  display: none;
}

footer {
  min-height: 175px;
  width: 80%;
  margin: 20px auto;
  border: 25px solid grey;
}

footer p {
  padding: 0 5px;
}

footer p code {
  word-wrap: break-word;
}

#custom-tools,
footer {
  display: none;
}

.current {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: var(--his-metal-tint);
  border-radius: 6px;
  margin-top: 6px;
}

.current-temp {
  margin: 0 5%;
  font-size: 3.5em;
  text-align: center;
}

.current-temp span {
  display: block;
  text-align: left;
  font-size: 0.25em;
}

.current-temp span:nth-child(2) {
  position: relative;
  margin-top: -10px;
}

.current-temp span:last-child {
  font-size: 0.2em;
}

.current canvas {
  width: 80px;
  height: 80px;
}

.current-summary {
  font-size: 1em;
}

.current-summary p:first-child {
  font-weight: bold;
}

.wind-section {
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: space-around;
}

.current-summary p:last-child {
  font-size: 0.8em;
  margin-top: -12px;
}

#map-canvas {
  width: 350px;
  height: 250px;
}

.widget-logo {
  position: relative;
  vertical-align: unset;
  width: 18px;
  height: 18px;
  top: 3px;
  left: 2px;
}

/* Media query for mobile devices */

@media screen and (max-width: 740px) {
  .main-container {
    padding: 5%;
    width: auto;
  }

  #container {
    flex-direction: column;
    /*text-align: center;*/
  }

  .widget-description-container {
    flex-direction: column;
  }

  .widget-iframe-container {
    width: auto;
    min-width: 100%;
  }

  .widget-container-description {
    width: auto;
    position: static;
    margin: 0 3%;
  }

  .widget-description-body {
    margin: 0 auto;
  }

  .widget-description-title,
  .button-generate {
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .main-container h1 {
    font-size: 1.7em;
  }

  .main-container h3 {
    font-size: 1.2em;
  }

  #header p:first-child {
    padding-left: 0;
    max-width: 75px;
    margin-right: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
  }

  #header p:last-child {
    padding-right: 0;
  }

  .default-embed #current,
  .default-embed .current {
    justify-content: space-around;
  }

  #current-temp {
    margin: 0;
    font-size: 2.5em;
  }

  #current-temp span {
    font-size: 0.3em;
  }

  .daily-hide {
    display: none;
  }

  .description {
    width: 80%;
  }

  /* Customization Controls */
  #gmaps {
    margin: 0 auto;
    width: 80%;
  }

  #gmaps p:nth-child(4) {
    padding: 0;
  }

  #gmaps p:nth-child(4) span {
    margin: 0 auto;
  }

  #custom-map {
    flex-direction: column;
  }

  #map-canvas {
    margin: 0 auto;
    width: 70%;
  }

  .show-hide-map p {
    text-align: center;
  }
}

@media screen and (max-width: 450px) {

  #current canvas,
  .current canvas {
    width: 60px;
    height: 60px;
  }

  #current-summary p:first-child {
    margin-bottom: 0;
  }

  #current-summary p:nth-child(2) {
    margin-top: 0;
  }

  #custom-controls {
    flex-direction: column;
    width: 80%;
  }
}

@media screen and (max-width: 250px) {
  #main {
    font-size: 14px;
  }

  #current canvas,
  .current canvas {
    width: 60px;
    height: 60px;
  }

  #current-temp {
    font-size: 2em;
  }
}

@media screen and (max-width: 600px) and (max-height: 380px) {

  #current,
  .current {
    display: none;
  }
}

@media screen and (max-height: 300px) {

  #current,
  .current {
    display: none;
  }

  #header {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 600px) and (max-height: 300px) {

  #current,
  .current {
    display: none;
  }
}

@media (max-width: 695px) {
  nav {
    font-size: 15px;
  }

  nav a {
    padding: 2%;
  }
}

@media (max-width: 425px) {
  nav a {
    padding: 1%;
  }

  #header h1 {
    font-size: 2.2em;
  }

  #header h2 {
    font-size: 1.2em;
  }

  .questions-container {
    width: 95%;
  }

  .answers h2,
  .help .question a {
    font-size: 1.1em;
  }

  .answers a {
    font-size: 1em;
  }

  .question .answer {
    font-size: 0.9em;
  }
 
  #wufoo-z1tfajhs1y01cw1,
  #wufoo-rmdmcxh07e9wk8 {
    width: 100%;
  }
}

@media (max-width: 320px) {
  nav>.inner {
    width: 90%;
  }

  nav p {
    padding: 10px 5px;
  }
}

/* Mobile styles for nav */
@media (max-width: 695px) {
  nav {
    font-size: 15px;
  }

  nav a {
    padding: 2%;
  }
}

@media (max-width: 500px) {
  nav>.inner {
    width: auto;
  }

  .low-temp {
    margin-top: 0;
  }

  #footer #footer-links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #footer #footer-links li {
    margin: 15px 0;
  }

  #footer #footer-links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #footer #footer-links li {
    margin: 10px 0;
  }
}

@media (max-width: 335px) {
  nav .inner a {
    padding: 1%;
  }
}
</style>
