var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"tableContainer"},[_c('f7-row',{staticClass:"card-body"},[_c('f7-col',[_c('f7-row',[_c('f7-col',[_c('div',[_vm._v(_vm._s(_vm.$t('agronomySample.summary.sampleCode')))]),_vm._v(" "),_c('div',{staticClass:"item-title-bold"},[_vm._v("\n              "+_vm._s(_vm.currentSampleSummary.code)+"\n            ")])])],1)],1),_vm._v(" "),_c('f7-col',[_c('f7-row',[_c('f7-col',[_c('div',[_vm._v("\n              "+_vm._s(_vm.$t('agronomySample.summary.sampleType'))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"item-title-bold"},[_vm._v("\n              "+_vm._s(_vm.currentSampleSummary.sample_type)+"\n            ")])])],1)],1),_vm._v(" "),_c('f7-col',[_c('f7-row',[_c('f7-col',[_c('div',[_vm._v(_vm._s(_vm.$t('foodSamples.analysisType')))]),_vm._v(" "),_c('div',{staticClass:"item-title-bold"},[_vm._v("\n              "+_vm._s(_vm.currentSampleSummary.analyse_type)+"\n            ")])])],1)],1),_vm._v(" "),_c('f7-col',[_c('f7-row',[_c('f7-col',[_c('div',[_vm._v("\n              "+_vm._s(_vm.$t('foodSamples.receptionDate'))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"item-title-bold"},[_vm._v("\n              "+_vm._s(_vm.$dates.formatDateDayMonthYear(
                  _vm.currentSampleSummary.reception_date
                ))+"\n            ")])])],1)],1),_vm._v(" "),_c('f7-col',[_c('f7-row',[_c('f7-col',[_c('div',[_vm._v("\n              "+_vm._s(_vm.$t('foodSamples.endDate'))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"item-title-bold"},[_vm._v("\n              "+_vm._s(_vm.$dates.formatDateDayMonthYear(
                  _vm.currentSampleSummary.end_date
                ))+"\n            ")])])],1)],1),_vm._v(" "),_c('f7-col',[_c('f7-row',[_c('f7-col',[_c('f7-row',[_c('f7-col',{staticStyle:{"text-align":"right"}},[_c('ContextMenu',{attrs:{"id":'btnDownloadFoodDetails',"items":_vm.items}})],1)],1)],1)],1)],1)],1),_vm._v(" "),_c('f7-row',{staticClass:"card-body"},[_c('f7-col',[_c('f7-row',[_c('f7-col',[_c('div',[_vm._v("\n              "+_vm._s(_vm.$t('agronomySample.summary.description'))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"item-title-bold"},[_vm._v("\n              "+_vm._s(_vm.currentSampleSummary.sample_name)+"\n            ")])])],1)],1),_vm._v(" "),_c('f7-col',[_c('f7-row',[_c('f7-col',[_c('div',[_vm._v("\n              "+_vm._s(_vm.$t('foodSamples.groupFamily'))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"item-title-bold"},[_vm._v("\n              "+_vm._s(_vm.currentSampleSummary.sample_type_family)+"\n            ")])])],1)],1),_vm._v(" "),_c('f7-col',[_c('f7-row',[_c('f7-col',[_c('div',[_vm._v(_vm._s(_vm.$t('foodSamples.projectDescription')))]),_vm._v(" "),_c('div',{staticClass:"item-title-bold"},[_vm._v("\n              "+_vm._s(_vm.currentSampleSummary.study)+"\n            ")])])],1)],1),_vm._v(" "),_c('f7-col',[_c('f7-row',[_c('f7-col',[_c('div',[_vm._v(_vm._s(_vm.$t('foodSamples.samplingSite')))]),_vm._v(" "),_c('div',{staticClass:"item-title-bold"},[_vm._v("\n              "+_vm._s(_vm.currentSampleSummary.farm)+"\n            ")])])],1)],1),_vm._v(" "),_c('f7-col',[_c('f7-row',[_c('f7-col',[_c('div',[_vm._v(_vm._s(_vm.$t('environmentSamples.samplingPoint')))]),_vm._v(" "),_c('div',{staticClass:"item-title-bold"},[_vm._v("\n              "+_vm._s(_vm.currentSampleSummary.parcel)+"\n            ")])])],1)],1),_vm._v(" "),_c('f7-col',[_c('f7-row',[_c('f7-col',[_c('div',[_vm._v(_vm._s(_vm.$t('agronomySample.summary.status')))]),_vm._v(" "),_c('div',{staticClass:"item-title-bold"},[_c('div',{class:_vm.$helpers.getStatusClass(
                    _vm.currentSampleSummary.status_code
                  ),staticStyle:{"text-align":"center"}},[_vm._v("\n                "+_vm._s(_vm.currentSampleSummary.status)+"\n              ")])])])],1)],1)],1),_vm._v(" "),_c('f7-row',{staticClass:"card-body"},[_c('f7-col',[_c('f7-row',[_c('f7-col',[_c('div',[_vm._v("\n              "+_vm._s(_vm.$t('healthSecurity.batch'))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"item-title-bold"},[_vm._v("\n              "+_vm._s(_vm.currentSampleSummary.lot_code)+"\n            ")])])],1)],1),_vm._v(" "),_c('f7-col'),_vm._v(" "),_c('f7-col'),_vm._v(" "),_c('f7-col'),_vm._v(" "),_c('f7-col')],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }