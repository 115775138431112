var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'main-chart-weather-bar-graph content-devx': _vm.applyClass,
  }},[_c('div',{staticClass:"content__barsgraph"},[_c('DxChart',{attrs:{"id":"chart","data-source":_vm.dataSource},on:{"legend-click":_vm.onLegendClick,"initialized":_vm.onInit}},[_c('DxCommonSeriesSettings',{attrs:{"argument-field":"date"}}),_vm._v(" "),_c('DxSeries',{attrs:{"pane":"top","color":_vm.measureColor,"value-field":`avgmeasured${_vm.weatherElement}`,"name":`${_vm.$t('average')} ${_vm.$t(
          _vm.weatherElement
        )}, ${_vm.measurement}`,"type":"bar","bar-width":50}}),_vm._v(" "),_c('DxSeries',{attrs:{"pane":"top","color":_vm.forecastColor,"value-field":`avgforecast${_vm.weatherElement}`,"type":"bar","bar-width":50,"name":`${_vm.$t('averageForecasted')} ${_vm.$t(
          _vm.weatherElement
        )}, ${_vm.measurement}`}}),_vm._v(" "),_c('DxPane',{attrs:{"name":"top"}}),_vm._v(" "),_c('DxArgumentAxis',{attrs:{"discrete-axis-division-mode":"crossLabels","argument-type":"datetime"}},[_c('DxLabel',{attrs:{"display-mode":"stagger","format":"d MMM yyyy - hh:mm"}})],1),_vm._v(" "),_c('DxAdaptiveLayout',{attrs:{"width":80,"keep-labels":true}}),_vm._v(" "),_c('DxValueAxis',{attrs:{"pane":"top"}},[_c('DxGrid',{attrs:{"visible":true}}),_vm._v(" "),_c('DxTitle',{attrs:{"text":`${_vm.$t(_vm.weatherElement)}, ${_vm.measurement}`}})],1),_vm._v(" "),_c('DxLegend',{attrs:{"margin":30,"vertical-alignment":"bottom","horizontal-alignment":"center"}}),_vm._v(" "),_c('DxExport',{attrs:{"enabled":false}}),_vm._v(" "),_c('DxTooltip',{attrs:{"enabled":true,"z-index":999999,"customize-tooltip":_vm.customizeTooltip}}),_vm._v(" "),_c('DxTitle',{attrs:{"text":`${_vm.$t(_vm.weatherElement)}`}},[_c('DxFont',{attrs:{"family":"Roboto","size":"14"}})],1),_vm._v(" "),_c('DxScrollBar',{attrs:{"visible":true}}),_vm._v(" "),_c('DxZoomAndPan',{attrs:{"argument-axis":"both"}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }