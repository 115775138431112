import Api from '@/services/Api';
import EventBus from '@/services/event-bus';

export default {
  namespaced: true,
  state: {
    aggregatedData: [],
    isParameters: false,
    beforeDates: [],
    afterDates: [],
  },
  actions: {
    async getAggregatedCalculations({ rootState }, params) {
      try {
        const xhr = await Api.getFoodAggregatedCalculations(
          params,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getAnalyseTypes({ rootState }, params) {
      try {
        const xhr = await Api.getSatelliteAnalyseType(
          params,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getSamplers({ rootState }) {
      try {
        const xhr = await Api.getSatelliteSampler(
          { company_code: rootState.contact.company },
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getThirdClients({ rootState }) {
      try {
        const xhr = await Api.getSatelliteThirdClient(
          { company_code: rootState.contact.company },
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getSampleTypes({ rootState }, params) {
      try {
        const xhr = await Api.getSatelliteSampleTypes(
          params,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    callStoreWithFilters({ state }, params) {
      EventBus.$emit('FoodStatsPage', params);
    },
    clearFilteredData({ state }, params) {
      EventBus.$emit('FoodStatsPage', params);
    },
    setAggregatedData({ commit }, value) {
      commit('setAggregatedData', value);
    },
    setBeforeDates({ commit }, value) {
      commit('setBeforeDates', value);
    },
    setAfterDates({ commit }, value) {
      commit('setAfterDates', value);
    },
    setIsParameters({ commit }, value) {
      commit('setIsParameters', value);
    },
  },
  mutations: {
    setAggregatedData(state, value) {
      state.aggregatedData = value;
    },
    setIsParameters(state, value) {
      state.isParameters = value;
    },
    setBeforeDates(state, value) {
      state.beforeDates = value;
    },
    setAfterDates(state, value) {
      state.afterDates = value;
    },
  },
};
