import Api from '@/services/Api';
import CustomStore from 'devextreme/data/custom_store';
import EventBus from '@/services/event-bus';

export default {
  namespaced: true,
  state: {
    redirectedFromSamePage: false,
  },
  actions: {
    async getNutritionalMonitoring({ rootState }) {
      try {
        const xhr = await Api.getNutritionalMonitoring(
          rootState.registerFarm.farmId,
          rootState.contact.company,
          rootState.contact.address
        );

        const response = JSON.parse(xhr.response);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getNutritionalMonitoringWithoutGeometry(
      { rootState },
      params
    ) {
      try {
        const xhr = await Api.getNutritionalMonitoringWithoutGeometry(
          rootState.contact.company,
          rootState.contact.address,
          params
        );

        const response = JSON.parse(xhr.response);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getNutritionalMonitoringWithoutGeometryWithFilters(
      { rootState },
      params
    ) {
      try {
        const xhr = await Api.getNutritionalMonitoringWithoutGeometryWithFilters(
          rootState.contact.company,
          rootState.contact.address,
          params
        );

        const response = JSON.parse(xhr.response);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    getStore({ dispatch, rootState }) {
      return new CustomStore({
        key: 'parcel',
        load: async (loadOptions) => {
          let params = '?';
          let sortSettings = [];

          ['skip', 'take', 'sort'].forEach((i) => {
            if (i in loadOptions) {
              if (i === 'sort' && loadOptions[i] !== null) {
                sortSettings = loadOptions[i];
              } else {
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
              }
            }
          });

          params = params.slice(0, -1);
          let subsistuteParams = params.replace('skip', 'offset');
          subsistuteParams = subsistuteParams.replace(
            'take',
            'limit'
          );

          const sortParams = await dispatch(
            'filter/applySort',
            {
              sortSettings,
              isFilter: false,
            },
            { root: true }
          );

          subsistuteParams += sortParams;

          if (!subsistuteParams.startsWith('?')) {
            subsistuteParams = `?${subsistuteParams}`;
          }
          if (loadOptions.filter) {
            if (loadOptions.filter[0]) {
              subsistuteParams += `&filter_search=${loadOptions.filter[0].filterValue}`;
            }
          }
          return dispatch(
            'getNutritionalMonitoringWithoutGeometry',
            subsistuteParams
          );
        },
      });
    },
    async getStoreWithFilters({ rootState, dispatch }, paramFilters) {
      return new CustomStore({
        key: 'parcel',
        load: async (loadOptions) => {
          let params = '?';
          let sortSettings = [];

          ['skip', 'take', 'sort'].forEach((i) => {
            if (i in loadOptions) {
              if (i === 'sort' && loadOptions[i] !== null) {
                sortSettings = loadOptions[i];
              } else {
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
              }
            }
          });

          params = params.slice(0, -1);

          if (params.includes('skip')) {
            let subsistuteParams = params.replace('skip', 'offset');

            subsistuteParams = subsistuteParams.replace(
              'take',
              'limit'
            );

            const offsetLimit = subsistuteParams.split('&');

            const offset = offsetLimit[0].split('=')[1];

            const limit = offsetLimit[1].split('=')[1];
            paramFilters.offset = offset;
            paramFilters.limit = limit;
          } else {
            paramFilters.limit = null;
            paramFilters.offset = null;
          }

          const sortParams = await dispatch(
            'filter/applySort',
            {
              sortSettings,
              isFilter: true,
            },
            { root: true }
          );

          if (loadOptions.filter) {
            if (loadOptions.filter[0]) {
              paramFilters.filter_search =
                loadOptions.filter[0].filterValue;
            }
          } else {
            paramFilters.filter_search = null;
          }

          paramFilters.ordering = sortParams.sort;

          return dispatch(
            'getNutritionalMonitoringWithoutGeometryWithFilters',
            paramFilters
          );
        },
      });
    },
    callWithFilters({ state }, params) {
      EventBus.$emit('NutritionalMonitoringMainPage', params);
    },
    clearFilteredData({ state }, params) {
      EventBus.$emit('NutritionalMonitoringMainPage', params);
    },
    setRedirectedFromSamePage({ commit }, value) {
      commit('SET_REDIRECTED_FROM_SAME_PAGE', value);
    },
  },
  mutations: {
    SET_REDIRECTED_FROM_SAME_PAGE(state, value) {
      state.redirectedFromSamePage = value;
    },
  },
};
