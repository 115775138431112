<template>
  <div style="height: 100%">
    <div :id="mapId" style="height: 100%; border-radius: 5px" />
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-side-by-side';
import * as turf from '@turf/turf';
import { mapState, mapActions } from 'vuex';
import EventBus from '@/services/event-bus';
import Api from '@/services/Api';

export default {
  name: 'GISWMSSwipeMap',
  props: {
    geometry: {
      type: Object,
      default: () => null,
    },
    imageLeft: {
      type: Object,
      default: () => null,
    },
    imageRight: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      mapId: `map-${Math.random().toString(36).substring(2)}`,
      currentLayerBase: null,
      leftSwipeLayer: null,
      rightSwipeLayer: null,
      swipeControl: null,
      //
      map: null,
      initialDate: Date.now(),
      currentLayers: [],
      currentIndexLeftLayer: null,
      currentIndexRightLayer: null,
      rightTextbox: null,
      leftTextbox: null,
      statusMeasureTool: false,
      statusZoningTool: false,
      areaLayer: null,
      lineLayer: null,
      areaLayerPopup: null,
      lineLayerPopup: null,
      toggleZoningButtonclicks: null,
    };
  },
  computed: {
    minHeight() {
      return `min-height: ${this.height}`;
    },
    ...mapState('gis', [
      'osm',
      'googleHybrid',
      'currentOverlayLayer',
      'featureInfo',
      'datesList',
    ]),
    ...mapState('Plantation', ['currentPlantation']),
  },
  watch: {
    imageLeft(newVal, oldVal) {
      this.loadImages();
    },
    imageRight(newVal, oldVal) {
      this.loadImages();
    },
  },
  mounted() {
    this.renderMap();
    // this.locateUser();

    this.loadGeometry();

    this.addHomeButton();
    this.addOpacitySlider();
    this.addMeasureToolButton();
    this.addLegends();

    this.loadImages();
  },
  methods: {
    renderMap() {
      // Load map
      this.map = L.map(this.mapId, {
        worldCopyJump: true,
        // minZoom: 12,
        maxZoom: 18,
      });

      // Change right bottom corner text
      this.map.attributionControl.addAttribution(
        'Margaret from <a href="https://hispatecanalytics.com//">HispatecAnalytics SA</a>'
      );

      // Set the current layer base to Google Hybrid Map
      this.currentLayerBase = L.tileLayer(
        this.osm.googleHybrid.route,
        this.osm.googleHybrid.properties
      ).addTo(this.map);

      this.map.setView([41.6164989248106, -4.873517847881886], 13);
    },
    locateUser() {
      this.map
        .locate({ setView: true, maxZoom: 16 })
        .on('locationfound', (e) => {
          this.map.setView(e.latlng, 16);
        })
        .on('locationerror', () => {
          this.map.setView(
            [41.6164989248106, -4.873517847881886],
            13
          );
        });
    },
    loadGeometry() {
      if (this.geometry) {
        const geojson = L.geoJSON(this.geometry, {
          style: (feat) => {
            return {
              color: feat.properties?.color || '#000000',
              fillColor: feat.properties?.fillColor || '#000000',
              fillOpacity: 0,
            };
          },
        });
        // }).addTo(this.map);
        this.geometryReference = geojson;
        this.map.fitBounds(geojson.getBounds());
      } else {
        this.locateUser();
      }
    },
    loadImages() {
      if (this.swipeControl != null) {
        this.swipeControl.remove();
        this.leftSwipeLayer?.remove();
        this.rightSwipeLayer?.remove();
      }
      const leftItem = {
        baseUrl: Api.getGeoServerProjectWorkSpaceInterpol(),
        options: {
          layers: `int_results:${this.imageLeft?.rasterImageName}`,
          format: 'image/png',
          transparent: true,
          styles: `int_results:${this.imageLeft?.rasterImageName}_${this.imageLeft?.type}`,
        },
      };

      const rightItem = {
        baseUrl: Api.getGeoServerProjectWorkSpaceInterpol(),
        options: {
          layers: `int_results:${this.imageRight?.rasterImageName}`,
          format: 'image/png',
          transparent: true,
          styles: `int_results:${this.imageRight?.rasterImageName}_${this.imageRight?.type}`,
        },
      };

      this.leftSwipeLayer =
        this.imageLeft == null
          ? null
          : L.tileLayer
              .wms(leftItem.baseUrl, leftItem.options)
              .addTo(this.map);

      this.rightSwipeLayer =
        this.imageRight == null
          ? null
          : L.tileLayer
              .wms(rightItem.baseUrl, rightItem.options)
              .addTo(this.map);

      if (this.imageLeft != null || this.imageRight != null) {
        this.swipeControl = L.control.sideBySide(
          this.leftSwipeLayer || {},
          this.rightSwipeLayer || {}
        );

        this.swipeControl.addTo(this.map);

        // eslint-disable-next-line no-underscore-dangle
        const swipeControlDiv = L.DomUtil.get(
          this.swipeControl._container
        );
        L.DomEvent.disableClickPropagation(swipeControlDiv);
      }

      if (this.imageLeft != null) {
        const legendImage = `${Api.getBaseUrlGis()}/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.1.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=int_results:test_mihail_3&LEGEND_OPTIONS=forceLabels:on;forceTitles:on;fontSize=20;dpi=300&STYLE=int_results:${
          this.imageLeft?.rasterImageName
        }_${this.imageLeft?.type}`;
        const legendContent = `<img src="${legendImage}">`;
        this.map.legend.setContent(legendContent);
      }

      if (this.imageRight != null) {
        const legendImage = `${Api.getBaseUrlGis()}/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.1.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=int_results:test_mihail_3&LEGEND_OPTIONS=forceLabels:on;forceTitles:on;fontSize=20;dpi=300&STYLE=int_results:${
          this.imageRight?.rasterImageName
        }_${this.imageRight?.type}`;
        const legendContent = `<img src="${legendImage}">`;
        this.map.legendRight.setContent(legendContent);
      }
    },
    addHomeButton() {
      const self = this;
      L.Control.zoomHome = L.Control.extend({
        options: {
          position: 'topleft',
          // zoomHomeText: '<i class="fg-search-home fa-2x"></i>',
          zoomHomeText: `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-home-search" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M21 12l-9 -9l-9 9h2v7a2 2 0 0 0 2 2h4.7"></path>
                          <path d="M9 21v-6a2 2 0 0 1 2 -2h2"></path>
                          <circle cx="18" cy="18" r="3"></circle>
                          <path d="M20.2 20.2l1.8 1.8"></path>
                        </svg>`,
          zoomHomeTitle: 'Zoom home',
        },
        onAdd() {
          const controlName = 'gin-control-zoom';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          this._zoomHomeButton = this.createButton(
            options.zoomHomeText,
            options.zoomHomeTitle,
            `${controlName}-home`,
            container,
            this.zoomHome
          );

          return container;
        },

        zoomHome() {
          if (self.geometryReference !== null) {
            self.map.fitBounds(self.geometryReference.getBounds());
          } else {
            self.locateUser();
          }
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      const zoomHome = new L.Control.zoomHome();
      zoomHome.addTo(self.map);
    },
    addOpacitySlider() {
      const self = this;
      L.Control.opacitySlider = L.Control.extend({
        update(value) {
          return value;
        },
        options: {
          title: `${self.$t('Gis.Buttons.Opacity')}`,
          position: 'topleft',
          min: 0.0,
          max: 1.0,
          step: 0.1,
          id: 'opacitySlider',
          value: 1.0,
          orientation: 'vertical',
          getValue(value) {
            return value;
          },
          showValue: true,
          syncSlider: true,
        },
        initialize(f, options) {
          L.setOptions(this, options);
          if (typeof f === 'function') {
            this.update = f;
          } else {
            this.update = function (value) {
              if (self.rightSwipeLayer !== null) {
                self.rightSwipeLayer.setOpacity(value);
              }
              if (self.leftSwipeLayer !== null) {
                self.leftSwipeLayer.setOpacity(value);
              }
            };
          }
          if (typeof this.options.getValue !== 'function') {
            this.options.getValue = function (value) {
              return value;
            };
          }
          if (this.options.orientation !== 'vertical') {
            this.options.orientation = 'horizontal';
          }
        },
        onAdd() {
          self.map.opacitySlider = this;
          this.initLayout();
          this.update(`${this.options.value}`);
          return this.container;
        },
        initLayout() {
          const className = 'leaflet-control-opacity-slider';
          this.container = L.DomUtil.create(
            'div',
            `${className} ${className}-${this.options.orientation}`
          );
          if (this.options.showValue) {
            this.sliderValue = L.DomUtil.create(
              'p',
              `${className}-value`,
              this.container
            );
            this.sliderValue.innerHTML = this.options.getValue(
              this.options.value
            );
          }
          this.sliderContainer = L.DomUtil.create(
            'div',
            'leaflet-opacity-slider-container',
            this.container
          );
          this.slider = L.DomUtil.create(
            'input',
            'leaflet-opacity-slider',
            this.sliderContainer
          );
          if (this.options.orientation === 'vertical') {
            this.slider.setAttribute('orient', 'vertical');
          }
          this.slider.setAttribute('title', this.options.title);
          this.slider.setAttribute('id', this.options.id);
          this.slider.setAttribute('type', 'range');
          this.slider.setAttribute('min', this.options.min);
          this.slider.setAttribute('max', this.options.max);
          this.slider.setAttribute('step', this.options.step);
          this.slider.setAttribute('value', this.options.value);

          if (this.options.syncSlider) {
            L.DomEvent.on(
              this.slider,
              'input',
              function (e) {
                this.updateValue();
              },
              this
            );
          } else {
            L.DomEvent.on(
              this.slider,
              'change',
              function (e) {
                this.updateValue();
              },
              this
            );
          }

          L.DomEvent.disableClickPropagation(this.container);
        },
        updateValue() {
          this.value = this.slider.value;
          if (this.options.showValue) {
            this.sliderValue.innerHTML = this.options.getValue(
              this.value
            );
          }
          this.update(this.value);
        },
      });
      // eslint-disable-next-line new-cap
      const opacitySlider = new L.Control.opacitySlider();
      opacitySlider.addTo(self.map);
    },
    addMeasureToolButton() {
      const self = this;
      L.Control.measureArea = L.Control.extend({
        options: {
          position: 'topright',
          measureAreaText: `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-polygon" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                              <circle cx="12" cy="5" r="2"></circle>
                              <circle cx="19" cy="8" r="2"></circle>
                              <circle cx="5" cy="11" r="2"></circle>
                              <circle cx="15" cy="19" r="2"></circle>
                              <path d="M6.5 9.5l3.5 -3"></path>
                              <path d="M14 5.5l3 1.5"></path>
                              <path d="M18.5 10l-2.5 7"></path>
                              <path d="M13.5 17.5l-7 -5"></path>
                            </svg>`,
          measureAreaTitle: `${self.$t('Gis.Buttons.MeasureArea')}`,
        },
        onAdd() {
          const controlName = 'gin-control-measure-area';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          self._measureAreaButton = this.createButton(
            options.measureAreaText,
            options.measureAreaTitle,
            `${controlName}-measureArea`,
            container,
            this.measureArea
          );

          return container;
        },

        measureArea() {
          self.map.pm.enableDraw('Polygon', {
            snappable: true,
            continueDrawing: false,
          });
          self.createLayer();
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.measureArea = new L.Control.measureArea();

      L.Control.measureLineString = L.Control.extend({
        options: {
          position: 'topright',
          measureLineStringText: `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-map-pins" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M10.828 9.828a4 4 0 1 0 -5.656 0l2.828 2.829l2.828 -2.829z"></path>
                                    <line x1="8" y1="7" x2="8" y2="7.01"></line>
                                    <path d="M18.828 17.828a4 4 0 1 0 -5.656 0l2.828 2.829l2.828 -2.829z"></path>
                                    <line x1="16" y1="15" x2="16" y2="15.01"></line>
                                  </svg>`,
          measureLineStringTitle: `${self.$t(
            'Gis.Buttons.MeasureLineString'
          )}`,
        },
        onAdd() {
          const controlName = 'gin-control-measure-line';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          self._measureLineStringButton = this.createButton(
            options.measureLineStringText,
            options.measureLineStringTitle,
            `${controlName}-measureLineString`,
            container,
            this.measureLineString
          );

          return container;
        },

        measureLineString() {
          self.map.pm.enableDraw('Line', {
            snappable: true,
          });
          self.createLayer();
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown click',
            L.DomEvent.stopPropagation
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.measureLineString = new L.Control.measureLineString();

      L.Control.desactiveMeasure = L.Control.extend({
        options: {
          position: 'topright',
          desactiveMeasureText: '<i class="fas fa-times"></i>',
          desactiveMeasureTitle: `${self.$t(
            'Gis.Buttons.DesactiveMeasureMode'
          )}`,
        },
        onAdd() {
          const controlName = 'gin-control-desactive-measure';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`
          );
          const { options } = this;
          this.activeMeasure = this.createButton(
            options.desactiveMeasureText,
            options.desactiveMeasureTitle,
            `${controlName}-desactiveMeasure`,
            container,
            this.desactiveMeasure
          );

          return container;
        },

        desactiveMeasure() {
          self.statusMeasureTool = false;
          self.measureArea.remove(self.map);
          self.measureLineString.remove(self.map);
          // eslint-disable-next-line no-use-before-define
          self.desactiveMeasure.remove(self.map);
          // const buttonId = document.getElementById('activeMeasure');
          // buttonId.style = 'display:block';
          // show all .gin-control-active-measure elements
          const elements = document.getElementsByClassName(
            'gin-control-active-measure'
          );
          for (let i = 0; i < elements.length; i += 1) {
            elements[i].style.display = 'block';
          }
          if (self.areaLayer !== null) {
            self.map.closePopup();
            self.map.removeLayer(self.areaLayer);
          }
          if (self.lineLayer !== null) {
            self.map.closePopup();
            self.map.removeLayer(self.lineLayer);
          }
          self.map.pm.disableDraw();
          // self.map.opacitySlider.addTo(self.map);
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.desactiveMeasure = new L.Control.desactiveMeasure();

      L.Control.activeMeasure = L.Control.extend({
        options: {
          position: 'topright',
          activeMeasureText: `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-ruler-measure" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M19.875 12c.621 0 1.125 .512 1.125 1.143v5.714c0 .631 -.504 1.143 -1.125 1.143h-15.875a1 1 0 0 1 -1 -1v-5.857c0 -.631 .504 -1.143 1.125 -1.143h15.75z"></path>
                                <path d="M9 12v2"></path>
                                <path d="M6 12v3"></path>
                                <path d="M12 12v3"></path>
                                <path d="M18 12v3"></path>
                                <path d="M15 12v2"></path>
                                <path d="M3 3v4"></path>
                                <path d="M3 5h18"></path>
                                <path d="M21 3v4"></path>
                              </svg>`,
          activeMeasureTitle: `${self.$t(
            'Gis.Buttons.ActiveMeasureMode'
          )}`,
        },
        onAdd() {
          const controlName = 'gin-control-active-measure';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`
          );
          const { options } = this;
          this.activeMeasure = this.createButton(
            options.activeMeasureText,
            options.activeMeasureTitle,
            `${controlName}-activeMeasure`,
            container,
            this.activeMeasure
          );

          return container;
        },

        activeMeasure() {
          self.statusMeasureTool = true;
          self.measureArea.addTo(self.map);
          self.measureLineString.addTo(self.map);
          self.desactiveMeasure.addTo(self.map);
          // activeMeasure.remove(self.map);
          // const buttonId = document.getElementById(
          //   '.gin-control-active-measure elements'
          // );
          // buttonId.style = 'display:none';
          // self.map.opacitySlider.remove(self.map);

          // hide all .gin-control-active-measure elements
          const elements = document.getElementsByClassName(
            'gin-control-active-measure'
          );
          for (let i = 0; i < elements.length; i += 1) {
            elements[i].style.display = 'none';
          }
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.id = 'activeMeasure';
          link.href = '#';
          link.style = 'display:block';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.activeMeasure = new L.Control.activeMeasure();
      self.activeMeasure.addTo(self.map);
    },
    addLegends() {
      const self = this;
      L.Control.legend = L.Control.extend({
        options: {
          position: 'bottomleft',
        },
        onAdd() {
          self.map.legend = this;

          const container = L.DomUtil.create(
            'div',
            'legend-control-container'
          );

          if (this.options.content) {
            container.innerHTML = this.options.content;
          }
          return container;
        },
        onRemove() {
          delete self.map.legend;
        },

        setContent(str) {
          this.getContainer().innerHTML = str;
        },
      });
      // eslint-disable-next-line new-cap
      const legend = new L.Control.legend();
      legend.addTo(self.map);

      L.Control.legendRight = L.Control.extend({
        options: {
          position: 'bottomright',
        },
        onAdd() {
          self.map.legendRight = this;

          const container = L.DomUtil.create(
            'div',
            'legend-control-container'
          );

          if (this.options.content) {
            container.innerHTML = this.options.content;
          }
          return container;
        },
        onRemove() {
          delete self.map.legendRight;
        },

        setContent(str) {
          this.getContainer().innerHTML = str;
        },
      });
      // eslint-disable-next-line new-cap
      const legendRight = new L.Control.legendRight();
      legendRight.addTo(self.map);
    },

    createLayer() {
      const self = this;

      this.map.on('pm:create', (e) => {
        try {
          self.$f7.preloader.show();
          if (e.shape === 'Polygon') {
            self.areaLayer = e.layer.addTo(self.map);
            const toGeoJson = self.areaLayer.toGeoJSON();
            const area = turf.area(toGeoJson);

            const PopupMeasureArea = self.$t(
              'Gis.Editor.PopupMeasureArea'
            );
            const PopupMeasureResult = self.$t(
              'Gis.Editor.PopupMeasureResult'
            );
            const PopupMeasureHas = self.$t(
              'Gis.Editor.PopupMeasureHas'
            );

            const html = `<div class='popup-content'>
                              <div class="popup-title">${PopupMeasureArea}</div>
                              <div class="popup-data-table">
                                <table class="table">
                                  <tbody>
                                    <tr>
                                      <td>${PopupMeasureResult}</td>
                                      <td>${(area / 10000).toFixed(
                                        2
                                      )} ${PopupMeasureHas}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>`;

            const popup = L.popup().setContent(html);
            self.areaLayerPopup = self.areaLayer.bindPopup(popup);
            self.areaLayer.openPopup();
            self.map.fitBounds(self.areaLayer.getBounds());
            self.map.pm.disableDraw();
          }
          if (e.shape === 'Line') {
            self.lineLayer = e.layer.addTo(self.map);
            // eslint-disable-next-line no-underscore-dangle
            if (self.lineLayer._latlngs.length > 2) {
              self.map.removeLayer(self.lineLayer);
            } else {
              const from = turf.point([
                // eslint-disable-next-line no-underscore-dangle
                self.lineLayer._latlngs[0].lat,
                // eslint-disable-next-line no-underscore-dangle
                self.lineLayer._latlngs[0].lng,
              ]);
              const to = turf.point([
                // eslint-disable-next-line no-underscore-dangle
                self.lineLayer._latlngs[1].lat,
                // eslint-disable-next-line no-underscore-dangle
                self.lineLayer._latlngs[1].lng,
              ]);
              const options = {
                units: 'kilometers',
              };
              const distance = turf.distance(from, to);

              const PopupMeasureLineString = self.$t(
                'Gis.Editor.PopupMeasureLineString'
              );
              const PopupMeasureResult = self.$t(
                'Gis.Editor.PopupMeasureResult'
              );

              const html = `<div class='popup-content'>
                              <div class="popup-title">${PopupMeasureLineString}</div>
                              <div class="popup-data-table">
                                <table class="table">
                                  <tbody>
                                    <tr>
                                      <td>${PopupMeasureResult}</td>
                                      <td>${distance.toFixed(
                                        2
                                      )}km</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>`;
              const popup = L.popup().setContent(html);
              self.lineLayerPopup = self.lineLayer.bindPopup(popup);
              self.lineLayer.openPopup();
              self.map.fitBounds(self.lineLayer.getBounds());
            }
          }
        } catch (error) {
          this.$notifyDX(
            {
              message: this.$t(`${error}`),
              width: 550,
            },
            'error',
            3000
          );
        } finally {
          self.$f7.preloader.hide();
        }
      });

      if (self.areaLayer !== null) {
        self.map.closePopup();
        self.map.removeLayer(self.areaLayer);
      }
      if (self.lineLayer !== null) {
        self.map.closePopup();
        self.map.removeLayer(self.lineLayer);
      }
      this.map.on('pm:globaldrawmodetoggled', (e) => {
        this.drawMode = e.enabled;
      });
    },
  },
};
</script>

<style scoped>
.content-gis-map {
  min-height: 340px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  overflow: hidden;
}
</style>
