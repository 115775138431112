<template>
  <div>
    <DxDataGrid
      :data-source="details.parameters"
      :show-borders="true"
      :allow-column-reordering="false"
      :column-auto-width="true"
      :show-colon="false"
      :remote-operations="true"
      :hover-state-enabled="true"
    >
      <DxScrolling mode="standard" />
      <DxStateStoring :enabled="false" type="localStorage" />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="[5, 10, 20]"
        :show-info="true"
        info-text="{2} items"
        :visible="false"
      />

      <DxSelection mode="single" />

      <DxSearchPanel
        :visible="false"
        :width="300"
        :placeholder="$t('search')"
      />

      <DxColumn
        data-field="parameter"
        :caption="$t('foodSamples.parameter')"
        alignment="left"
        :allow-header-filtering="false"
      />

      <DxColumn
        data-field=""
        name="result"
        :caption="$t('foodSamples.result')"
        alignment="left"
        :allow-header-filtering="false"
        cell-template="cellTemplateResults"
      />
      <DxColumn
        data-field=""
        name="notificationRange"
        :caption="$t('notificationRange')"
        alignment="left"
        :allow-header-filtering="false"
        cell-template="cellTemplateNotificationResults"
      />

      <template #cellTemplateResults="{ data }">
        <div style="min-width: 100px">
          {{ data.data.value.toFixed(2) }} {{ data.data.unit }}
        </div>
      </template>

      <template #cellTemplateNotificationResults="{ data }">
        <div style="min-width: 100px">
          {{ data.data.min_notification }} -
          {{ data.data.max_notification }}
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxPaging,
  DxScrolling,
} from 'devextreme-vue/data-grid';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxStateStoring,
    DxPager,
    DxPaging,
    DxScrolling,
    DxSelection,
  },
  props: {
    details: {
      type: Object,
      default: () => {},
    },
  },
  methods: {},
};
</script>
