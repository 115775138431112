<template>
  <div>
    <DxChart
      id="chart"
      :data-source="dataSource"
      @legend-click="onLegendClick"
    >
      <DxCommonSeriesSettings
        type="line"
        argument-type="datetime"
        argument-field="date"
      />
      <DxValueAxis :title="title" />
      <DxLegend
        vertical-alignment="bottom"
        horizontal-alignment="center"
        item-text-position="right"
      />
      <DxTooltip
        :enabled="true"
        :z-index="999999"
        :interactive="true"
        :customize-tooltip="customizeTooltip"
      />

      <DxSeries
        value-field="value"
        :name="samplesTitle"
        color="#16a34a"
      />
      <DxSeries
        value-field="cma_superior_max"
        :name="$t('cma_superior')"
        color="#ef444499"
      />
      <DxSeries
        value-field="r_min_min"
        :name="$t('r_min')"
        color="#3b82f699"
      />
    </DxChart>
  </div>
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxLegend,
  DxTooltip,
  DxValueAxis,
} from 'devextreme-vue/chart';
import { mapActions } from 'vuex';

export default {
  components: {
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxLegend,
    DxTooltip,
    DxValueAxis,
  },
  props: {
    dataSource: {
      type: Array,
      default: () => [],
    },
    samplesTitle: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },
    customizeTooltip(pointInfo) {
      const tableStyle =
        'border-spacing: 0;border-collapse: collapse;font-size: 11px;width: 120px;overflow: auto;border-bottom: 1px solid #ccc;';
      const thStyle =
        'width: 33%;min-width: 90px;white-space: nowrap;border: 1px solid #ccc;padding: 0 5px;';
      const tdStyle =
        'width: 70%;border: 1px solid #ccc;padding: 0 14px;';

      if (pointInfo.seriesName === this.samplesTitle) {
        const { sample_oid } = pointInfo.point.data;
        const code = pointInfo.point.data.sampleCode;
        const { date } = pointInfo.point.data;
        const result = pointInfo.point.data.value;

        setTimeout(() => {
          document
            .getElementById('mir-graph-btn')
            .addEventListener('click', async (e) => {
              const sample = await this.getSampleById(sample_oid);

              this.setLastRouteEnvStats(true);
              this.setCurrentSampleId(sample_oid);
              this.setReportSampleId(sample.sample_id);
              this.setCurrentFilter('environmentDetails');
              this.setCurrentSample(sample);
              this.setReportSampleCode(sample.code);

              this.replaceCurrent({
                name: this.$t('sampleSummary'),
                disabled: true,
                page: 'EnvironmentSamplesDetailsPage',
                active: true,
              });

              this.$f7router.navigate(
                { name: 'EnvironmentSamplesDetailsPage' },
                {
                  animate: true,
                  transition: 'f7-fade',
                }
              );
            });
        }, 10);

        return {
          html: `<div>
                  <div style="font-size: 14px;font-weight: 600;margin-bottom: 10px;text-align: center;">${code}</div>
                  <table style="${tableStyle}">
                    <tbody>
                      <tr>
                        <th style="${thStyle}">${this.$t('date')}</th>
                        <td style="${tdStyle}">${date}</td>
                      </tr>
                      <tr>
                        <th style="${thStyle}">
                          ${this.$t('foodSamples.result')}
                        </th>
                        <td style="${tdStyle}">${result}</td>
                      </tr>
                    </tbody>
                  </table>
                  <a 
                    id="mir-graph-btn"
                    style="display: flex; justify-content: center;background: #f3f4f6;margin-top: 10px; padding: 6px;border-radius: 5px;"
                    class="dx-link dx-icon-find dx-link-icon"
                  ></a>
                </div>`,
        };
      }

      const { date } = pointInfo.point.data;
      const resultName =
        this.seriesName === this.$t('cma_superior')
          ? this.$t('cma_superior')
          : this.$t('r_min');
      const result =
        this.seriesName === this.$t('cma_superior')
          ? pointInfo.point.data.cma_superior_max
          : pointInfo.point.data.r_min_min;

      return {
        html: `<div>
          <table style="${tableStyle}">
            <tbody>
              <tr>
                <th style="${thStyle}">${this.$t('date')}</th>
                <td style="${tdStyle}">${date}</td>
              </tr>
              <tr>
                <th style="${thStyle}">${resultName}</th>
                <td style="${tdStyle}">${result}</td>
              </tr>
            </tbody>
          </table>
        </div>`,
      };
    },
    ...mapActions('samples', [
      'setReportSampleId',
      'getReport',
      'setCurrentSample',
      'setReportSampleCode',
      'setDisableContext',
      'getSamplesWithParameters',
      'setAllFilterParams',
      'getSamplesZip',
      'setIsDownloadingReport',
      'setLastRouteHome',
      'setLastRouteEnvStats',
      'getSampleById',
    ]),
    ...mapActions('breadcrumb', ['replaceCurrent']),
    ...mapActions('filter', [
      'setCurrentFilter',
      'setCurrentSampleId',
    ]),
  },
};
</script>
