export default {
  namespaced: true,
  state: {
    notificationFromDate: null,
    notificationToDate: null,
    selectedAtcs: [],
    selectedSampleTypes: [],
  },
  actions: {
    setNotificationToDate({ commit }, value) {
      commit('setNotificationToDate', value);
    },
    setNotificationFromDate({ commit }, value) {
      commit('setNotificationFromDate', value);
    },
    setSelectedAtcs({ commit }, value) {
      commit('setSelectedAtcs', value);
    },
    setSelectedSampleTypes({ commit }, value) {
      commit('setSelectedSampleTypes', value);
    },
    clearFilters({ commit }) {
      commit('clearFilters');
    },
  },
  mutations: {
    setNotificationFromDate(state, value) {
      state.notificationFromDate = value;
    },
    setNotificationToDate(state, value) {
      state.notificationToDate = value;
    },
    setSelectedAtcs(state, value) {
      state.selectedAtcs = value;
    },
    setSelectedSampleTypes(state, value) {
      state.selectedSampleTypes = value;
    },
    clearFilters(state) {
      state.notificationFromDate = null;
      state.notificationToDate = null;
      state.selectedAtcs = [];
      state.selectedSampleTypes = [];
    },
  },
};
