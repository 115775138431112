import Api from '@/services/Api';
import EventBus from '@/services/event-bus';
import CustomStore from 'devextreme/data/custom_store';
import { parseNumber } from 'devextreme/localization';

export default {
  namespaced: true,
  state: {
    phitomonitoringData: {},
    date: [],
    category: null,
    type: [],
    value: null,
    unit: [],
    greaterThan: true,
  },
  actions: {
    callWithFilters({ commit, rootState }, params) {
      EventBus.$emit('phitomonitoringFilters', params);
    },
    setFilteredSamples({ commit }, value) {
      EventBus.$emit('phitomonitoringFilters', null);
    },
    async getPhitomonitoringData({ rootState, commit }, params) {
      try {
        const xhr = await Api.getPhitomonitoringData(
          params,
          rootState.contact.company,
          rootState.contact.address
        );

        const response = JSON.parse(xhr.response);

        return Promise.resolve({
          data: response?.data ?? [],
          totalCount: response.totalCount,
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async createPhitomonitoringData({ rootState, commit }, params) {
      try {
        const xhr = await Api.createPhitomonitoringData(
          {
            ...params,
            address_code: rootState.contact.address,
            company_code: rootState.contact.company,
          },
          rootState.contact.company,
          rootState.contact.address
        );

        const response = JSON.parse(xhr.response);

        // commit('setPhitomonitoringData', response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async editPhitomonitoringData({ rootState, commit }, params) {
      try {
        const xhr = await Api.editPhitomonitoringData(
          {
            ...params,
            address_code: rootState.contact.address,
            company_code: rootState.contact.company,
          },
          rootState.contact.company,
          rootState.contact.address
        );

        const response = JSON.parse(xhr.response);

        // commit('setPhitomonitoringData', response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async deletePhitomonitoringData({ rootState, commit }, id) {
      try {
        const xhr = await Api.deletePhitomonitoringData(
          id,
          rootState.contact.company,
          rootState.contact.address
        );

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    cleanPhitomonitoringData({ commit }) {
      commit('setPhitomonitoringData', {});
    },

    getStore({ dispatch, rootState }) {
      return new CustomStore({
        key: 'id',
        load: async (loadOptions) => {
          let params = '?';
          let sortSettings = [
            { selector: 'reception_date', desc: true },
          ];

          ['skip', 'take', 'sort'].forEach((i) => {
            if (i in loadOptions) {
              if (i === 'sort' && loadOptions[i] !== null) {
                sortSettings = loadOptions[i];
              } else {
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
              }
            }
          });

          params = params.slice(0, -1);
          let subsistuteParams = params.replace('skip', 'offset');
          subsistuteParams = subsistuteParams.replace(
            'take',
            'limit'
          );

          const sortParams = await dispatch(
            'filter/applySort',
            {
              sortSettings,
              isFilter: false,
            },
            { root: true }
          );

          subsistuteParams += sortParams;

          if (!subsistuteParams.startsWith('?')) {
            subsistuteParams = `?${subsistuteParams}`;
          }

          if (loadOptions.filter) {
            if (loadOptions.filter[0]) {
              subsistuteParams += `&filter_search=${loadOptions.filter[0].filterValue}`;
            }
          }

          return dispatch('getPhitomonitoringData', subsistuteParams);
        },
        insert: (values) => {
          // return fetch(
          //   'https://mydomain.com/MyDataService/myEntity',
          //   {
          //     method: 'POST',
          //     body: JSON.stringify(values),
          //     headers: {
          //       'Content-Type': 'application/json',
          //     },
          //   }
          // ).then(handleErrors);
        },
        remove: (key) => {
          // return fetch(
          //   `https://mydomain.com/MyDataService/${encodeURIComponent(
          //     key
          //   )}`,
          //   {
          //     method: 'DELETE',
          //   }
          // ).then(handleErrors);
        },
        update: (key, values) => {
          // return fetch(`https://mydomain.com/MyDataService/${encodeURIComponent(key)}`, {
          //     method: "PUT",
          //     body: JSON.stringify(values),
          //     headers: {
          //         'Content-Type': 'application/json'
          //     }
          // }).then(handleErrors);
        },
      });
    },
    async getStoreWithFilters({ rootState, dispatch }, paramFilters) {
      return new CustomStore({
        key: 'id',
        load: async (loadOptions) => {
          let params = '?';
          let sortSettings = [];

          ['skip', 'take', 'sort'].forEach((i) => {
            if (i in loadOptions) {
              if (i === 'sort' && loadOptions[i] !== null) {
                sortSettings = loadOptions[i];
              } else {
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
              }
            }
          });

          params = params.slice(0, -1);

          if (params.includes('skip')) {
            let subsistuteParams = params.replace('skip', 'offset');
            subsistuteParams = subsistuteParams.replace(
              'take',
              'limit'
            );

            const offsetLimit = subsistuteParams.split('&');

            const offset = offsetLimit[0].split('=')[1];

            const limit = offsetLimit[1].split('=')[1];
            paramFilters.offset = offset;
            paramFilters.limit = limit;
          } else {
            paramFilters.limit = null;
            paramFilters.offset = null;
          }

          const sortParams = await dispatch(
            'filter/applySort',
            {
              sortSettings,
              isFilter: true,
            },
            { root: true }
          );

          if (loadOptions.filter) {
            if (loadOptions.filter[0]) {
              paramFilters.filter_search =
                loadOptions.filter[0].filterValue;
            }
          } else {
            paramFilters.filter_search = null;
          }

          paramFilters.ordering = sortParams.sort;
          return dispatch('getPhitomonitoringData', paramFilters);
        },
        insert: (values) => {},
        remove: (key) => {},
        update: (key, values) => {},
      });
    },

    setCategory({ commit }, category) {
      commit('setCategory', category);
    },
    setType({ commit }, type) {
      commit('setType', type);
    },
    setValue({ commit }, value) {
      commit('setValue', value);
    },
    setUnit({ commit }, unit) {
      commit('setUnit', unit);
    },
    setDate({ commit }, date) {
      commit('setDate', date);
    },
    setGreaterThan({ commit }, greaterThan) {
      commit('setGreaterThan', greaterThan);
    },
    clearFilters({ commit }) {
      commit('clearFilters');
    },
  },
  mutations: {
    setPhitomonitoringData(state, value) {
      state.phitomonitoringData = value;
    },
    setCategory(state, value) {
      state.category = value;
    },
    setType(state, value) {
      state.type = value.filter((item) => item !== null);
    },
    setValue(state, value) {
      state.value = value;
    },
    setUnit(state, value) {
      state.unit = value.filter((item) => item !== null);
    },
    setDate(state, value) {
      state.date = value;
    },
    setGreaterThan(state, value) {
      state.greaterThan = value;
    },
    clearFilters(state) {
      EventBus.$emit('clearFiltersPhytomonitoring');

      state.category = null;
      state.type = [];
      state.value = null;
      state.unit = [];
      state.date = [];
      state.greaterThan = true;
    },
  },
};
