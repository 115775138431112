var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('DxChart',{attrs:{"id":"chart","data-source":_vm.ds.data}},[_c('DxCommonSeriesSettings',{attrs:{"argument-field":"parameter","type":"stackedbar"}}),_vm._v(" "),_c('DxValueAxis',{attrs:{"position":"left"}},[_c('DxTitle',{attrs:{"text":_vm.$t('stats.numSamples')}})],1),_vm._v(" "),(_vm.selectedSampleTypes.length >= 1)?_c('DxSeries',{attrs:{"name":_vm.aggregatedData[0].sample_type +
        ' - ' +
        _vm.$t('positives') +
        ' > LMR',"color":"#FF6384","value-field":`nSuperiorLmrs_` + _vm.aggregatedData[0].sample_type,"stack":"first"}}):_vm._e(),_vm._v(" "),(_vm.selectedSampleTypes.length >= 1)?_c('DxSeries',{attrs:{"name":_vm.aggregatedData[0].sample_type + ' - ' + _vm.$t('positives'),"color":"#FFCE56","value-field":`nPositives_` + _vm.aggregatedData[0].sample_type,"stack":"first"}}):_vm._e(),_vm._v(" "),(_vm.selectedSampleTypes.length >= 1)?_c('DxSeries',{attrs:{"name":_vm.aggregatedData[0].sample_type + ' - ' + _vm.$t('samples'),"value-field":`nSamples_` + _vm.aggregatedData[0].sample_type,"color":"#ffeda0","stack":"first"}}):_vm._e(),_vm._v(" "),(_vm.selectedSampleTypes.length >= 2)?_c('DxSeries',{attrs:{"name":_vm.aggregatedData[1].sample_type +
        ' - ' +
        _vm.$t('positives') +
        ' > LMR',"color":"#9c88ff","value-field":`nSuperiorLmrs_` + _vm.aggregatedData[1].sample_type,"stack":"second"}}):_vm._e(),_vm._v(" "),(_vm.selectedSampleTypes.length >= 2)?_c('DxSeries',{attrs:{"name":_vm.aggregatedData[1].sample_type + ' - ' + _vm.$t('positives'),"color":"#4BC0C0","value-field":`nPositives_` + _vm.aggregatedData[1].sample_type,"stack":"second"}}):_vm._e(),_vm._v(" "),(_vm.selectedSampleTypes.length >= 2)?_c('DxSeries',{attrs:{"name":_vm.aggregatedData[1].sample_type + ' - ' + _vm.$t('samples'),"color":"#6a89cc","value-field":`nSamples_` + _vm.aggregatedData[1].sample_type,"stack":"second"}}):_vm._e(),_vm._v(" "),(_vm.selectedSampleTypes.length >= 3)?_c('DxSeries',{attrs:{"name":_vm.aggregatedData[2].sample_type +
        ' - ' +
        _vm.$t('positives') +
        ' > LMR',"color":"#badc58","value-field":`nSuperiorLmrs_` + _vm.aggregatedData[2].sample_type,"stack":"third"}}):_vm._e(),_vm._v(" "),(_vm.selectedSampleTypes.length >= 3)?_c('DxSeries',{attrs:{"name":_vm.aggregatedData[2].sample_type + ' - ' + _vm.$t('positives'),"color":"#7bed9f","value-field":`nPositives_` + _vm.aggregatedData[2].sample_type,"stack":"third"}}):_vm._e(),_vm._v(" "),(_vm.selectedSampleTypes.length >= 3)?_c('DxSeries',{attrs:{"name":_vm.aggregatedData[2].sample_type + ' - ' + _vm.$t('samples'),"color":"#ced6e0","value-field":`nSamples_` + _vm.aggregatedData[2].sample_type,"stack":"third"}}):_vm._e(),_vm._v(" "),(_vm.selectedSampleTypes.length >= 4)?_c('DxSeries',{attrs:{"name":_vm.aggregatedData[3].sample_type +
        ' - ' +
        _vm.$t('positives') +
        ' > LMR',"color":"#70a1ff","value-field":`nSuperiorLmrs_` + _vm.aggregatedData[3].sample_type,"stack":"fourth"}}):_vm._e(),_vm._v(" "),(_vm.selectedSampleTypes.length >= 4)?_c('DxSeries',{attrs:{"name":_vm.aggregatedData[3].sample_type + ' - ' + _vm.$t('positives'),"color":"#9ecae1","value-field":`nPositives_` + _vm.aggregatedData[3].sample_type,"stack":"fourth"}}):_vm._e(),_vm._v(" "),(_vm.selectedSampleTypes.length >= 4)?_c('DxSeries',{attrs:{"color":"#deebf7","name":_vm.aggregatedData[3].sample_type + ' - ' + _vm.$t('samples'),"value-field":`nSamples_` + _vm.aggregatedData[3].sample_type,"stack":"fourth"}}):_vm._e(),_vm._v(" "),(_vm.selectedSampleTypes.length >= 5)?_c('DxSeries',{attrs:{"name":_vm.aggregatedData[4].sample_type +
        ' - ' +
        _vm.$t('positives') +
        ' > LMR',"color":"#ff4757","value-field":`nSuperiorLmrs_` + _vm.aggregatedData[4].sample_type,"stack":"fifth"}}):_vm._e(),_vm._v(" "),(_vm.selectedSampleTypes.length >= 5)?_c('DxSeries',{attrs:{"name":_vm.aggregatedData[4].sample_type + ' - ' + _vm.$t('positives'),"color":"#ffb8b8","value-field":`nPositives_` + _vm.aggregatedData[4].sample_type,"stack":"fifth"}}):_vm._e(),_vm._v(" "),(_vm.selectedSampleTypes.length >= 5)?_c('DxSeries',{attrs:{"color":"#1dd1a1","name":_vm.aggregatedData[4].sample_type + ' - ' + _vm.$t('samples'),"value-field":`nSamples_` + _vm.aggregatedData[4].sample_type,"stack":"fifth"}}):_vm._e(),_vm._v(" "),_c('DxLegend',{attrs:{"customize-items":_vm.customizeItems,"vertical-alignment":"bottom","column-count":3,"horizontal-alignment":"center","item-text-position":"top"}}),_vm._v(" "),_c('DxExport',{attrs:{"enabled":true}}),_vm._v(" "),_c('DxTooltip',{attrs:{"enabled":true,"z-index":999999,"customize-tooltip":_vm.customizeTooltip}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }