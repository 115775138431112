<template>
  <div>
    <div 
    style="
    background-color: #595295;
    color: white;
    padding-left: 5px;
    font-size: 16px;
    border-bottom: solid #3d3871 3px;
    ">
      {{ $t('NutritionalMonitoring') }}
    </div>
    
    <div v-if="!loaded" class="loadingFalse">
      <div class="preloader">
        <span class="preloader-inner">
          <span class="preloader-inner-circle"></span>
        </span>
      </div>
    </div>
    <div v-else style="margin: 7px;">
      <DxDataGrid
        :data-source="data"
        :show-borders="true"
        :allow-column-reordering="false"
        :column-auto-width="true"
        :show-colon="false"
        :hover-state-enabled="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
      >
        <DxScrolling mode="standard" />
        <DxStateStoring
          :enabled="false"
          type="localStorage"
          storage-key="nutritionalFollowingTable"
        />
        <DxPaging :page-size="5" />
        <DxPager
          :show-page-size-selector="true"
          :allowed-page-sizes="[5, 10, 20]"
          :show-info="true"
          info-text="{2} items"
          :visible="true"
        />
        <DxSearchPanel 
          :visible="true"
          :width="300"
          :placeholder="$t('search')"
        />
        <DxExport :enabled="false" />
        <DxColumn
          data-field="parcel"
          :caption="$t('agronomySample.summary.parcel')"
          alignment="left"
          :allow-header-filtering="false"
        />

        <DxColumn
          data-field="cultive"
          :caption="$t('agronomySample.cultive')"
          alignment="left"
          :allow-header-filtering="false"
        />
        <DxColumn
          data-field="variety"
          :caption="$t('variety')"
          alignment="left"
          :allow-header-filtering="false"
        />
        <DxColumn
          :caption="$t('lastReceipt')"
          alignment="left"
          data-field="sampling_date"
          data-type="date"
          format="dd/MM/yyyy"
          :allow-header-filtering="false"
        />

        <!-- <DxColumn
          data-field="status"
          :caption="$t('status')"
          alignment="left"
          :width="150"
          :allow-header-filtering="false"
          cell-template="cellTemplateStatus"
        /> -->

        <DxColumn
          :buttons="editButtons"
          :fixed="true"
          fixed-position="right"
          type="buttons"
          width="70"
        />

        <template #cellTemplateStatus="{ data }">
          <div class="greenStatus" style="text-align: center">
            {{ data.value }}
          </div>
        </template>
      </DxDataGrid>
    </div>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxStateStoring,
  DxPager,
  DxPaging,
  DxScrolling,
  DxExport,
} from 'devextreme-vue/data-grid';
import { mapActions } from 'vuex';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  name: 'NutritionalFollowingTable',
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxStateStoring,
    DxPager,
    DxPaging,
    DxScrolling,
    DxExport,
  },
  data() {
    return {
      loaded: false,
      data: [],
      editButtons: [
        {
          icon: 'find',
          onClick: this.viewNutritionalMonitoring,
        },
        // {
        //   icon: 'download',
        //   onClick: this.download,
        // },
      ],
    };
  },
  async beforeMount() {
    try {
      this.loaded = false;
      const dataUnformatted = await this.getNutritionalMonitoring();
      this.data =
        dataUnformatted?.data?.sort((a, b) =>
          a.parcel > b.parcel ? 1 : -1
        ) || [];
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(error.message).error
            )
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    } finally {
      this.loaded = true;
    }
  },
  methods: {
    async download(e) {
      this.setReportSampleId(e.row.data.sample_id);

      const report = await this.getReport();

      if (report.name && report.pdfB64) {
        const link = document.createElement('a');
        link.href = `data:application/octet-stream;base64,${report.pdfB64}`;
        link.download = report.name;
        link.click();

        URL.revokeObjectURL(link.href);
      } else {
        this.$notifyDX(
          {
            message: this.$t('sampleHasNoReport'),
            width: 450,
          },
          'info',
          2000
        );
      }
    },
    viewNutritionalMonitoring(e) {
      this.setReportSampleId(e.row.data.sample_id);
      this.setParcelId(e.row.data.parcel_id);
      this.setParcelStr(e.row.data.parcel);
      this.setId(e.row.data.id);
      this.setLastRouteGeotoolbox(false);

      this.$f7router.navigate(
        { name: 'NutritionalMonitoringPage' },
        {
          animate: true,
          transition: 'f7-fade',
        }
      );
    },
    ...mapActions('samples', ['setReportSampleId', 'getReport']),
    ...mapActions('nutritionalFollowing', [
      'getNutritionalMonitoring',
    ]),
    ...mapActions('nutritionalMonitoring', [
      'setParcelId',
      'setId',
      'setLastRouteGeotoolbox',
    ]),
    ...mapActions('registerFarm', ['setParcelStr', 'setParcel']),
  },
};
</script>
<style scoped>
</style>
