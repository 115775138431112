<template>
  <div class="margin-top">
    <div class="margin-top-half">
      <div class="label-input">
        {{ $t('environmentSamples.summary.project') }}
      </div>

      <DxTagBox
        :data-source="projects"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('environmentSamples.summary.project')"
        tag-template="tag"
        :value="selectedProjects"
        display-expr="value"
        value-expr="value"
        :on-value-changed="selectedProjectsChanged"
        :on-opened="openedProjects"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>

    <div class="label-input margin-top-half">
      {{ $t('foodSamples.receptionDate') }}
    </div>
    <div class="recepDate marginTop5">
      <f7-input
        class="shadow"
        type="datepicker"
        :placeholder="$t('foodSamples.receptionDate')"
        :clear-button="true"
        :close-on-select="true"
        :value="calculatedFoodReceptionDate"
        :calendar-params="{
          closeOnSelect: true,
          rangePicker: true,
          dateFormat: {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
          },
          disabled: isDisabled,
        }"
        @calendar:change="setReceptionDate"
      />
    </div>
    <div class="margin-top-half">
      <div class="label-input">
        {{ $t('agronomySample.summary.sampleType') }}
      </div>

      <DxTagBox
        :data-source="sampleTypes"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('agronomySample.summary.sampleType')"
        tag-template="tag"
        :value="selectedSampleTypes"
        display-expr="value"
        value-expr="id"
        :on-value-changed="sampleTypesChanged"
        :on-opened="openedSampleTypes"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
    <div class="margin-top-half">
      <div class="label-input">
        {{ $t('agronomySample.summary.status') }}
      </div>

      <DxTagBox
        :data-source="statuses"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('agronomySample.summary.status')"
        tag-template="tag"
        :value="selectedStatuses"
        display-expr="value"
        value-expr="id"
        :on-value-changed="statusesChanged"
        :on-opened="openedStatuses"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
    <div class="margin-top-half">
      <div class="label-input">
        {{ $t('foodSamples.analysisType') }}
      </div>

      <DxTagBox
        :data-source="analyseType"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('foodSamples.analysisType')"
        tag-template="tag"
        :value="selectedAnalyseTypes"
        display-expr="value"
        value-expr="value"
        :on-value-changed="analyseTypesChanged"
        :on-opened="openedAnalyseType"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
    <div class="margin-top-half">
      <div class="label-input">{{ $t('foodSamples.ecologic') }}</div>
      <DxSelectBox
        :items="ecologicStatuses"
        :value="selectedEcologic"
        :on-opened="openedEcologic"
        :on-value-changed="setEcologicChanged"
        :on-content-ready="contentReady"
        item-template="item"
        field-template="field"
        value-expr="value"
        :show-clear-button="true"
      >
        <template #field="{ data }">
          <div>
            <div v-if="typeof data === 'undefined' || data === null">
              <DxTextBox :placeholder="$t('organic')" />
            </div>
            <div v-else>
              <DxTextBox :value="translateValue(data)" />
            </div>
          </div>
        </template>
        <template #item="{ data }">
          <div v-if="data.value === 'True'">{{ $t('yes') }}</div>
          <div v-else>{{ $t('no') }}</div>
        </template>
      </DxSelectBox>
    </div>
    <div class="margin-top-half">
      <div class="label-input">{{ $t('foodSamples.endDate') }}</div>
      <div class="recepDate marginTop5">
        <f7-input
          class="shadow"
          type="datepicker"
          :placeholder="$t('foodSamples.endDate')"
          :clear-button="true"
          :close-on-select="true"
          :value="calculatedFoodEndDate"
          :calendar-params="{
            closeOnSelect: true,
            rangePicker: true,
            dateFormat: {
              month: 'numeric',
              day: 'numeric',
              year: 'numeric',
            },
            disabled: isDisabled,
          }"
          @calendar:change="setEndDate"
        />
      </div>
    </div>
    <div class="margin-top-half">
      <div class="label-input">
        {{ $t('foodSamples.thirdClient') }}
      </div>

      <DxTagBox
        :data-source="thirdClients"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('foodSamples.thirdClient')"
        tag-template="tag"
        :value="selectedThirdClients"
        display-expr="value"
        value-expr="value"
        :on-value-changed="thirdClientsChanged"
        :on-opened="openedThirdClients"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>

    <div class="margin-top-half">
      <div class="label-input">
        {{ $t('foodSamples.specificationEuropeanChains') }}
      </div>

      <DxTagBox
        :data-source="europeanChains"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('foodSamples.specificationEuropeanChains')"
        tag-template="tag"
        :value="selectedEuropeanChains"
        :on-opened="openedEuropeanChains"
        :on-value-changed="europeanChainsChanged"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
    <div class="margin-top-half">
      <div class="label-input">
        {{ $t('foodSamples.samplingSite') }}
      </div>

      <DxTagBox
        :data-source="farms"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('foodSamples.samplingSite')"
        tag-template="tag"
        :value="selectedFarms"
        display-expr="value"
        value-expr="value"
        :on-value-changed="selectedFarmsChanged"
        :on-opened="openedFarms"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>

    <div class="margin-top-half">
      <div class="label-input">
        {{ $t('environmentSamples.samplingPoint') }}
      </div>

      <DxTagBox
        :data-source="parcels"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('environmentSamples.samplingPoint')"
        tag-template="tag"
        :value="selectedParcels"
        display-expr="value"
        value-expr="value"
        :on-value-changed="selectedParcelsChanged"
        :on-opened="openedParcels"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { DxTagBox } from 'devextreme-vue/tag-box';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxTextBox } from 'devextreme-vue/text-box';
import DataSource from 'devextreme/data/data_source';
import moment from 'moment';
import { AFTER_DATE_LIMIT } from '@/js/constants';

export default {
  components: { DxTagBox, DxSelectBox, DxTextBox },
  data() {
    return {
      sampleTypes: [],
      ecologicStatuses: [],
      statuses: [],
      analyseType: [],
      thirdClients: [],
      europeanChains: [],
      projects: [],
      farms: [],
      parcels: [],
      loaded: false,
    };
  },
  computed: {
    calculatedFoodReceptionDate() {
      if (
        this.receptionToDate !== null &&
        this.receptionFromDate !== null
      ) {
        return [this.receptionFromDate, this.receptionToDate];
      }
      return [];
    },
    calculatedFoodEndDate() {
      if (this.endToDate !== null && this.endFromDate !== null) {
        return [this.endFromDate, this.endToDate];
      }
      return [];
    },
    ...mapState('foodSamples', ['allSamples']),
    ...mapState('foodFilter', [
      'receptionFromDate',
      'receptionToDate',
      'endFromDate',
      'endToDate',
      'selectedSampleTypes',
      'selectedStatuses',
      'selectedAnalyseTypes',
      'selectedEcologic',
      'selectedThirdClients',
      'selectedEuropeanChains',
      'selectedProjects',
      'selectedFarms',
      'selectedParcels',
    ]),
  },
  methods: {
    isDisabled(date) {
      return (
        moment(date).isBefore(moment(AFTER_DATE_LIMIT), 'day') ||
        moment(date).isAfter(moment(), 'day')
      );
    },
    translateValue(value) {
      if (value.value === 'True') return this.$t('yes');
      return this.$t('no');
    },
    async openedEcologic(e) {
      const types = await this.getSampleFilter({
        atc: 'Alimentaria',
        attribute: 'cultive_organic',
      });
      this.ecologicStatuses = types.sort();
      this.loaded = true;
    },
    async openedSampleTypes(e) {
      const sampleTypes = await this.getSampleFilter({
        atc: 'Alimentaria',
        attribute: 'sample_type',
      });

      this.sampleTypes = new DataSource({
        store: sampleTypes,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedEuropeanChains() {
      const chains = await this.getFoodChains();

      this.europeanChains = new DataSource({
        store: chains,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedStatuses(e) {
      const statuses = await this.getSampleFilter({
        atc: 'Alimentaria',
        attribute: 'status',
      });

      this.statuses = statuses.sort();
      this.loaded = true;
    },
    async openedAnalyseType(e) {
      const analyseType = await this.getSampleFilter({
        atc: 'Alimentaria',
        attribute: 'analyse_type',
      });

      this.analyseType = new DataSource({
        store: analyseType,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedThirdClients(e) {
      const thirdClients = await this.getSampleFilter({
        atc: 'Alimentaria',
        attribute: 'third_client',
      });

      this.thirdClients = new DataSource({
        store: thirdClients,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedProjects(e) {
      this.loaded = false;
      const projects = await this.getSampleFilter({
        atc: 'Alimentaria',
        attribute: 'study',
      });

      this.projects = new DataSource({
        store: projects,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedFarms(e) {
      const farms = await this.getSampleFilter({
        atc: 'Alimentaria',
        attribute: 'farm',
      });

      this.farms = new DataSource({
        store: farms,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedParcels(e) {
      const parcels = await this.getSampleFilter({
        atc: 'Alimentaria',
        attribute: 'parcel',
      });

      this.parcels = new DataSource({
        store: parcels,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    contentReady(e) {
      const arrayNotFoundItems = Array.from(
        document.getElementsByClassName('dx-empty-message')
      );

      arrayNotFoundItems.forEach((element) => {
        if (!this.loaded) {
          element.innerHTML = this.$t('loading');
        } else {
          element.innerHTML = this.$t('dataNotAvailable');
        }
      });
      this.loaded = false;
    },
    setEndDate(e) {
      if (e.length === 2) {
        this.setEndFromDate(e[0]);
        this.setEndToDate(e[1]);
      } else if (e.length === 0) {
        this.setEndFromDate(null);
        this.setEndToDate(null);
      }
    },
    statusesChanged(e) {
      this.setSelectedStatuses(e.value);
    },
    sampleTypesChanged(e) {
      this.setSelectedSampleTypes(e.value);
    },
    setReceptionDate(e) {
      if (e.length === 2) {
        this.setReceptionFromDate(e[0]);
        this.setReceptionToDate(e[1]);
      } else if (e.length === 0) {
        this.setReceptionFromDate(null);
        this.setReceptionToDate(null);
      }
    },
    analyseTypesChanged(e) {
      this.setSelectedAnalyseTypes(e.value);
    },
    setEcologicChanged(e) {
      this.setEcologic(e.value);
    },
    thirdClientsChanged(e) {
      this.setSelectedThirdClients(e.value);
    },
    europeanChainsChanged(e) {
      this.setSelectedEuropeanChains(e.value);
    },
    selectedProjectsChanged(e) {
      this.setSelectedProjects(e.value);
    },
    selectedFarmsChanged(e) {
      this.setSelectedFarms(e.value);
    },
    selectedParcelsChanged(e) {
      this.setSelectedParcels(e.value);
    },
    ...mapActions('foodFilter', [
      'setReceptionFromDate',
      'setReceptionToDate',
      'setEndFromDate',
      'setEndToDate',
      'setSelectedSampleTypes',
      'setSelectedStatuses',
      'setSelectedAnalyseTypes',
      'setEcologic',
      'setSelectedThirdClients',
      'setSelectedEuropeanChains',
      'setSelectedProjects',
      'setSelectedFarms',
      'setSelectedParcels',
    ]),
    ...mapActions('foodSamples', ['getFoodChains']),
    ...mapActions('samples', ['getSampleFilter']),
  },
};
</script>
