<template>
  <f7-page name="home">
    <div v-if="loaded">
      <Summary-status />

      <div>
        <div class="tableContainer">
          <Samples-summary-table :store="customStore" />
        </div>
      </div>
    </div>
  </f7-page>
</template>
<script>
import EventBus from '@/services/event-bus';
import { mapActions, mapState } from 'vuex';
import SamplesSummaryTable from '../components/home/SamplesSummaryTable/SamplesSummaryTable.vue';
import SummaryStatus from '../components/home/SummaryStatus/SummaryStatus.vue';

export default {
  name: 'Home',
  components: { SamplesSummaryTable, SummaryStatus },
  data() {
    return {
      loaded: false,
      customStore: null,
    };
  },
  computed: {
    ...mapState('leftPanel', ['hiddenPanel']),
  },
  beforeMount() {
    this.setCurrentFilter('home');
    this.setCurrentRoute([
      {
        name: this.$t('Home'),
        disabled: false,
        page: 'Home',
        active: true,
      },
    ]);
  },
  async mounted() {
    try {
      this.$f7.preloader.show();
      setTimeout(() => {
        this.applyCurrentFilters();
      }, 0);

      EventBus.$on('Home', async (params) => {
        if (params) {
          this.customStore = await this.getStoreWithFilters(params);
        } else {
          this.customStore = await this.getStore();
        }
      });
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t('reEnterCredentials'),
          width: 350,
        },
        'info',
        3000
      );
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },
  beforeDestroy() {
    EventBus.$off('Home');
  },
  methods: {
    ...mapActions('homeSamples', ['getStore', 'getStoreWithFilters']),
    ...mapActions('filter', [
      'setCurrentFilter',
      'applyCurrentFilters',
    ]),
    ...mapActions('breadcrumb', ['setCurrentRoute']),
  },
};
</script>
