import Client from './Clients/Axios/AxiosClient';
import {
  getAuthorizedHeader,
  getAuthorizedHeaderWithCompanyCode,
} from './utils';

const resource = '/user';

export default {
  async authentication(userEmail, userPassword, isEmail = true) {
    try {
      // correo > auth
      // contacto > etl
      const endpoint = isEmail
        ? `${resource}/authorization?no_cache=${Date.now()}`
        : `etl/access_besafer/?no_cache=${Date.now()}`;

      const data = {
        email: userEmail,
        password: userPassword,
      };

      if (!isEmail) {
        data.Usuario = userEmail;
        data.Password = userPassword;
      }
      
      const response = await Client.post(`${endpoint}`, data);
      return Promise.resolve(response);

    } catch (error) {
      return Promise.reject(error);
    }
  },

  confirmResetPassword(tokenFromQueryParam) {
    return Client.post(
      `${resource}/confirm_password?no_cache=${Date.now()}`,
      {
        token: tokenFromQueryParam,
      }
    );
  },

  get(accessToken) {
    return Client.get(
      `${resource}?no_cache=${Date.now()}`,
      getAuthorizedHeader(accessToken)
    );
  },

  refreshAccessToken(refreshToken) {
    return Client.post(`${resource}/refresh?no_cache=${Date.now()}`, {
      refresh: refreshToken,
    });
  },

  resetPassword(email) {
    return Client.post(
      `${resource}/reset_password?no_cache=${Date.now()}`,
      {
        email,
      }
    );
  },

  update(
    userDataObjectToUpdate,
    accessToken,
    userCompanyCode,
    addressCode
  ) {
    return Client.put(
      `${resource}?no_cache=${Date.now()}`,
      userDataObjectToUpdate,
      getAuthorizedHeaderWithCompanyCode(
        accessToken,
        userCompanyCode,
        addressCode,
        userDataObjectToUpdate.code
      )
    );
  },

  updateEmail(newEmail, accessToken) {
    return Client.post(
      `${resource}/reset_password?no_cache=${Date.now()}`,
      {
        newEmail,
      },
      getAuthorizedHeader(accessToken)
    );
  },

  updatePassword(
    newPassword,
    oldPassword,
    accessToken,
    userCompanyCode
  ) {
    return Client.put(
      `${resource}/password?no_cache=${Date.now()}`,
      {
        new_password: newPassword,
        old_password: oldPassword,
      },
      getAuthorizedHeaderWithCompanyCode(accessToken, userCompanyCode)
    );
  },
};
