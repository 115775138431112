<template>
  <div class="main-notification-popup">
    <div v-if="!loaded">
      <div class="padding">
        <f7-preloader class="margin-right" :size="24"></f7-preloader>
        {{ $t('loading') }}
      </div>
    </div>
    <div v-else>
      <f7-block-title class="no-margin card-header">
        {{ $t('recentNotifications') }}
      </f7-block-title>
      <DxAccordion
        id="notificationAccordion"
        :data-source="getReportsFormatted"
        :collapsible="true"
        :multiple="false"
        :hover-state-enabled="false"
        :focus-state-enabled="false"
        :animation-duration="300"
        width="280"
        :selected-index="-1"
        :rtl-enabled="true"
        @selectionChanged="selectionChanged"
      >
        <template v-if="loaded" #title="{ data }">
          <div>
            <div
              :class="
                !data.been_seen
                  ? 'item-title-notification'
                  : 'item-title-notification'
              "
            >
              {{ data.sample_code }}
              <a
                href="#"
                class="dx-link dx-icon-find dx-link-icon icon-notification-search"
                @click="(e) => goToDetails(e, data)"
              ></a>
            </div>
            <span
              :class="!data.been_seen ? 'time-item' : 'time-item'"
            >
              {{
                fromNow(
                  new Date(
                    data.created_at.split(' ')[0].split('-')[2],
                    Number(
                      data.created_at.split(' ')[0].split('-')[1]
                    ) - 1,
                    data.created_at.split(' ')[0].split('-')[0],
                    data.created_at.split(' ')[1].split(':')[0],
                    data.created_at.split(' ')[1].split(':')[1],
                    data.created_at.split(' ')[1].split(':')[2]
                  ),
                  new Date(),
                  {
                    addSuffix: true,
                    includeSeconds: true,
                    locale: locales[curLocale],
                  }
                ).capitalize()
              }}
            </span>
          </div>
        </template>

        <template v-if="loaded" #item="{ data }">
          <div>
            <div class="list media-list no-ios-edges no-margin">
              <ul>
                <li class="item-content">
                  <div class="item-inner">
                    <div class="item-subtitle small">
                      {{ $t('atc') }}
                    </div>
                    <div class="item-title-row">
                      <div class="item-title">{{ data.atc }}</div>
                    </div>
                  </div>
                </li>
                <li class="item-content">
                  <div class="item-inner">
                    <div class="item-subtitle small">
                      {{ $t('company') }}
                    </div>
                    <div class="item-title-row">
                      <div class="item-title">
                        {{ data.company_name }}
                      </div>
                    </div>
                  </div>
                </li>
                <li class="item-content">
                  <div class="item-inner">
                    <div class="item-subtitle small">
                      {{ $t('address') }}
                    </div>
                    <div class="item-title-row">
                      <div class="item-title">
                        {{ data.address_name }}
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  v-if="data.alert_report_type.includes('Parameter')"
                  class="item-content"
                >
                  <div class="item-inner">
                    <div class="item-subtitle small">
                      {{ $t('alert.parameters') }}
                    </div>
                    <div class="item-title-row">
                      <div class="item-title">
                        {{ data.array_values.toString() }}
                      </div>
                    </div>
                  </div>
                </li>
                <li
                  v-else-if="
                    data.alert_report_type.includes(
                      'ReceptionAlertReport'
                    )
                  "
                  class="item-content"
                >
                  <div class="item-inner">
                    <div class="item-subtitle small">
                      {{ $t('agronomySample.summary.sampleType') }}
                    </div>
                    <div class="item-title-row">
                      <div class="item-title">
                        {{ data.sample_type }}
                      </div>
                    </div>
                  </div>
                </li>
                <li v-else class="item-content">
                  <div class="item-inner">
                    <div class="item-subtitle small">
                      {{ $t('sample') }}
                    </div>
                    <div class="item-title-row">
                      <div class="item-title">
                        {{ data.n_values }}
                      </div>
                      <div class="item-after">
                        {{
                          data.sample_alert_type === 'CMA'
                            ? $t('alert.cmaPassed')
                            : $t('alert.lmrPassed')
                        }}
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </DxAccordion>

      <div v-if="reports.length > 0">
        <DxButton
          type="normal"
          styling-mode="outlined"
          @click="goToNotifications"
        >
          {{ $t('alert.viewMore') }}
        </DxButton>
      </div>
    </div>
  </div>
</template>
<script>
import DxAccordion from 'devextreme-vue/accordion';
import { mapActions, mapState } from 'vuex';
import DxButton from 'devextreme-vue/button';
import formatDistance from 'date-fns/formatDistance';
import { es, enGB, fr, pt, it } from 'date-fns/esm/locale';
import moment from 'moment';
import 'moment-timezone';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  components: {
    DxAccordion,
    DxButton,
  },
  props: {
    loaded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      locales: { es, en: enGB, fr, pt, it },
      types: {
        food: this.$t('Food'),
        agronomy: this.$t('Agronomy'),
        environment: this.$t('Environment'),
        mining: this.$t('Mining'),
        healthAndSecurity: this.$t('Health and Security'),
      },
    };
  },
  computed: {
    currenDate() {
      return new Date();
    },
    curLocale() {
      return this.languageInterface;
    },
    getReportsFormatted() {
      return this.reports.map((report) => {
        const data = new Date(
          report.created_at.split(' ')[0].split('/')[2],
          Number(report.created_at.split(' ')[0].split('/')[1]) - 1,
          report.created_at.split(' ')[0].split('/')[0],
          report.created_at.split(' ')[1].split(':')[0],
          report.created_at.split(' ')[1].split(':')[1],
          report.created_at.split(' ')[1].split(':')[2]
        );

        const dateFormat = moment(`${data}+00:00`);

        return {
          ...report,
          created_at: dateFormat
            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
            .format('DD-MM-YYYY HH:mm:ss'),
        };
      });
    },
    ...mapState('notifications', ['reports']),
    ...mapState('user', ['languageInterface']),
    ...mapState('clientMenu', ['items', 'currentAddress']),
  },
  methods: {
    fromNow(o, d, t) {
      return formatDistance(o, d, t);
    },
    async goToDetails(e, data) {
      e.preventDefault();
      e.stopPropagation();

      this.setShowPopover(false);

      this.setAddress(data.address_code);
      this.setAddressName(data.address_name);
      this.setCompany(data.company_code);
      this.setCompanyName(data.company_name);

      await this.setNotificationAsSeen(data.id);

      if (data.alert_report_type.includes('Parameter')) {
        this.setParamReportFocusedRowKey(data.alert_report_id);

        this.$f7.views.main.router.navigate(
          {
            name: 'AlertsParamsReportsPage',
          },
          {
            animate: true,
            transition: 'f7-fade',
            reloadCurrent: true,
          }
        );
      } else if (
        data.alert_report_type.includes('ReceptionAlertReport')
      ) {
        this.viewAtc(data);
      } else {
        this.setSampleReportFocusedRowKey(data.alert_report_id);

        this.$f7.views.main.router.navigate(
          {
            name: 'AlertsSampleReportsPage',
          },
          {
            animate: true,
            transition: 'f7-fade',
            reloadCurrent: true,
          }
        );
      }

      try {
        await this.setNotificationAsSeen(data.id);
      } catch (e) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(e.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      }
    },
    goToNotifications() {
      this.$f7.views.main.router.navigate(
        { name: 'NotificationsDetailsPage' },
        {
          animate: true,
          transition: 'f7-fade',
        }
      );
      this.setShowPopover(false);
    },
    async selectionChanged(e) {
      // TODO:
    },
    async viewAtc(data) {
      this.setSampleFetchedFromEtl(false);
      this.setLastRouteHome(true);
      this.setLastRouteGeotoolboxSamples(false);

      this.setCurrentSampleId(data.sample_id);
      this.setReportSampleId(data.sample_id);
      this.setReportSampleCode(data.sample_code);

      await this.getCurrentSample();

      await this.goToSampleDetailsByAtc(data.atc_code, data);
    },
    async goToSampleDetailsByAtc(atcName, data) {
      this.types = {
        food: this.$t('Food'),
        agronomy: this.$t('Agronomy'),
        environment: this.$t('Environment'),
        mining: this.$t('Mining'),
        healthAndSecurity: this.$t('Health and Security'),
      };

      if (atcName === this.types.food) {
        this.setCurrentFilter('foodDetails');
        this.setIsNoRp(data.value_report === 'CMA');
        this.setSelectedLimits([this.$t('positives')]);

        this.clearRoutes();

        this.pushRoute({
          name: this.$t('Food'),
          disabled: false,
          page: 'FoodSamplesPage',
          active: false,
        });

        this.pushRoute({
          name: this.$t('sampleSummary'),
          disabled: true,
          page: 'FoodSampleDetailPage',
          active: true,
        });

        this.$f7.views.main.router.navigate(
          {
            name: 'FoodSampleDetailPage',
          },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );
      } else if (atcName === this.types.agronomy) {
        this.setCurrentFilter('agronomy');

        this.clearRoutes();

        this.pushRoute({
          name: this.$t('Agronomy'),
          disabled: false,
          page: 'AgronomySamplesPage',
          active: false,
        });

        this.pushRoute({
          name: this.$t('sampleSummary'),
          disabled: true,
          page: 'AgronomySamplesDetailsPage',
          active: true,
        });

        this.$f7.views.main.router.navigate(
          {
            name: 'AgronomySamplesDetailsPage',
          },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );
      } else if (atcName === this.types.environment) {
        this.setCurrentFilter('environmentDetails');

        this.clearRoutes();

        this.pushRoute({
          name: this.$t('Environment'),
          disabled: false,
          page: 'EnvironmentSamplesPage',
          active: false,
        });

        this.pushRoute({
          name: this.$t('sampleSummary'),
          disabled: true,
          page: 'EnvironmentSamplesDetailsPage',
          active: true,
        });

        this.$f7.views.main.router.navigate(
          {
            name: 'EnvironmentSamplesDetailsPage',
          },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );
      } else if (atcName === this.types.mining) {
        this.setCurrentFilter('miningDetails');

        this.clearRoutes();

        this.pushRoute({
          name: this.$t('Mining'),
          disabled: false,
          page: 'MiningSamplesPage',
          active: false,
        });

        this.pushRoute({
          name: this.$t('sampleSummary'),
          disabled: true,
          page: 'MiningSamplesDetailsPage',
          active: true,
        });

        this.$f7.views.main.router.navigate(
          {
            name: 'MiningSamplesDetailsPage',
          },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );
      } else if (atcName === this.types.healthAndSecurity) {
        this.setCurrentFilter('healthSecurityDetails');

        this.clearRoutes();

        this.pushRoute({
          name: this.$t('Health and Security'),
          disabled: false,
          page: 'HealthSecuritySamplesPage',
          active: false,
        });

        this.pushRoute({
          name: this.$t('sampleSummary'),
          disabled: true,
          page: 'HealthSecuritySamplesDetailsPage',
          active: true,
        });

        this.$f7.views.main.router.navigate(
          {
            name: 'HealthSecuritySamplesDetailsPage',
          },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );
      }
    },
    ...mapActions('notifications', [
      'getReports',
      'setNotificationAsSeen',
      'deleteFromSource',
    ]),
    ...mapActions('navbar', ['setShowPopover']),
    ...mapActions('alertsParams', {
      setParamReportFocusedRowKey: 'setReportFocusedRowKey',
    }),
    ...mapActions('alertsSample', {
      setSampleReportFocusedRowKey: 'setReportFocusedRowKey',
    }),
    ...mapActions('contact', [
      'setAddress',
      'setAddressName',
      'setCompany',
      'setCompanyName',
    ]),
    //
    ...mapActions('filter', [
      'setCurrentFilter',
      'setCurrentSampleId',
    ]),
    ...mapActions('samples', [
      'getReport',
      'setReportSampleId',
      'getCurrentSample',
      'setReportSampleCode',
      'setSampleFetchedFromEtl',
      'setLastRouteHome',
      'setLastRouteGeotoolboxSamples',
    ]),
    ...mapActions('foodSamples', ['setIsNoRp']),
    ...mapActions('foodDetailsFilter', ['setSelectedLimits']),
    ...mapActions('breadcrumb', ['pushRoute', 'clearRoutes']),
  },
};
</script>

<style scoped lang="scss">
@import './SummaryAccordion.styles.scss';
</style>
