<template>
  <div class="margin-top">
    <div class="label-input margin-top-half">
      {{ $t('agronomySample.summary.samplingDate') }}
    </div>
    <div class="marginTop5 recepDate">
      <f7-input
        class="shadow"
        type="datepicker"
        :placeholder="$t('agronomySample.summary.samplingDate')"
        :clear-button="true"
        :close-on-select="true"
        :value="calculatedEnvironmentSamplingDate"
        :calendar-params="{
          closeOnSelect: true,
          rangePicker: true,
          dateFormat: {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
          },
          disabled: isDisabled,
        }"
        @calendar:change="setSamplingDate"
      />
    </div>
    <div class="marginTop15">
      <div>{{ $t('agronomySample.summary.sampleType') }}</div>

      <DxTagBox
        :data-source="sampleTypes"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('agronomySample.summary.sampleType')"
        tag-template="tag"
        display-expr="value"
        value-expr="id"
        :value="selectedSampleTypes"
        :on-value-changed="sampleTypesChanged"
        :on-opened="openedSampleTypes"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
    <div class="marginTop15">
      <div>{{ $t('status') }}</div>

      <DxTagBox
        :data-source="statuses"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('status')"
        tag-template="tag"
        :value="selectedStatuses"
        display-expr="value"
        value-expr="id"
        :on-value-changed="statusesChanged"
        :on-opened="openedStatuses"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
    <div class="marginTop15">
      <div>
        {{ $t('environmentSamples.summary.project') }}
      </div>

      <DxTagBox
        :data-source="projects"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('environmentSamples.summary.project')"
        tag-template="tag"
        display-expr="value"
        value-expr="value"
        :value="selectedProjects"
        :on-value-changed="selectedProjectsChanged"
        :on-opened="openedProjects"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
    <div class="marginTop15">
      <div>{{ $t('environmentSamples.installation') }}</div>

      <DxTagBox
        :data-source="farms"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('environmentSamples.installation')"
        tag-template="tag"
        display-expr="value"
        value-expr="value"
        :value="selectedFarms"
        :on-value-changed="farmsChanged"
        :on-opened="openedFarms"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
    <div class="marginTop15">
      <div>{{ $t('environmentSamples.samplingPoint') }}</div>

      <DxTagBox
        :data-source="parcels"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('environmentSamples.samplingPoint')"
        tag-template="tag"
        display-expr="value"
        value-expr="value"
        :value="selectedParcels"
        :on-value-changed="parcelsChanged"
        :on-opened="openedParcels"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>

    <div class="marginTop15">
      {{ $t('foodSamples.receptionDate') }}
    </div>
    <div class="marginTop5 recepDate">
      <f7-input
        class="shadow"
        type="datepicker"
        :placeholder="$t('foodSamples.receptionDate')"
        :clear-button="true"
        :close-on-select="true"
        :value="calculatedEnvironmentReceptionDate"
        :calendar-params="{
          closeOnSelect: true,
          rangePicker: true,
          dateFormat: {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
          },
          disabled: isDisabled,
        }"
        @calendar:change="setReceptionDate"
      />
    </div>

    <div class="marginTop15">
      <div>
        {{ $t('environmentSamples.noOfIneligibles') }}
        <div
          style="
            display: inline-block;
            position: absolute;
            right: 20px;
            margin-top: -5px;
          "
        >
          <DxRadioGroup
            :items="options"
            :value="greaterThan"
            layout="horizontal"
            value-expr="value"
            display-expr="text"
            @valueChanged="changeSelectionPriority"
          />
        </div>
      </div>

      <div class="dx-field">
        <DxNumberBox
          class="marginTop5"
          :value="noOfIneligible"
          :min="0"
          format="#0"
          :on-change="changeNoOfIneligible"
        />
      </div>
    </div>

    <div class="marginTop15">
      <div>
        {{ $t('foodSamples.analysisType') }}
      </div>
      <DxTagBox
        :data-source="analyseTypes"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('foodSamples.analysisType')"
        tag-template="tag"
        :value="selectedAnalyseTypes"
        display-expr="value"
        value-expr="value"
        :on-value-changed="analyseTypesChanged"
        :on-opened="openedAnalyseTypes"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
  </div>
</template>

<script>
import { DxTagBox } from 'devextreme-vue/tag-box';
import { mapActions, mapState } from 'vuex';
import { DxNumberBox } from 'devextreme-vue/number-box';
import DataSource from 'devextreme/data/data_source';
import DxRadioGroup from 'devextreme-vue/radio-group';
import moment from 'moment';
import { AFTER_DATE_LIMIT } from '@/js/constants';

export default {
  name: 'EnvironmentSamplesFilter',
  components: {
    DxTagBox,
    DxNumberBox,
    DxRadioGroup,
  },
  data() {
    return {
      sampleTypes: [],
      statuses: [],
      farms: [],
      parcels: [],
      analyseTypes: [],
      projects: [],
      options: [
        { text: '>', value: true },
        { text: '<', value: false },
      ],
      loaded: false,
    };
  },
  computed: {
    calculatedEnvironmentReceptionDate() {
      if (
        this.receptionFromDate !== null &&
        this.receptionToDate !== null
      ) {
        return [this.receptionFromDate, this.receptionToDate];
      }
      return [];
    },
    calculatedEnvironmentSamplingDate() {
      if (
        this.samplingFromDate !== null &&
        this.samplingToDate !== null
      ) {
        return [this.samplingFromDate, this.samplingToDate];
      }
      return [];
    },
    ...mapState('environmentFilter', [
      'receptionFromDate',
      'receptionToDate',
      'samplingFromDate',
      'samplingToDate',
      'selectedSampleTypes',
      'selectedFarms',
      'selectedParcels',
      'noOfIneligible',
      'selectedStatuses',
      'selectedAnalyseTypes',
      'selectedProjects',
      'greaterThan',
    ]),
    ...mapState('environmentSamples', ['allSamples']),
  },
  methods: {
    isDisabled(date) {
      return (
        moment(date).isBefore(moment(AFTER_DATE_LIMIT), 'day') ||
        moment(date).isAfter(moment(), 'day')
      );
    },
    async openedSampleTypes(e) {
      const sampleTypes = await this.getSampleFilter({
        atc: 'Medio Ambiente',
        attribute: 'sample_type',
      });

      this.sampleTypes = new DataSource({
        store: sampleTypes,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedStatuses(e) {
      const statuses = await this.getSampleFilter({
        atc: 'Medio Ambiente',
        attribute: 'status',
      });

      this.statuses = statuses.sort();
      this.loaded = true;
    },
    async openedAnalyseTypes(e) {
      const analyseTypes = await this.getSampleFilter({
        atc: 'Medio Ambiente',
        attribute: 'analyse_type',
      });

      this.analyseTypes = new DataSource({
        store: analyseTypes,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedProjects(e) {
      this.loaded = false;
      const projects = await this.getSampleFilter({
        atc: 'Medio Ambiente',
        attribute: 'study',
      });

      this.projects = new DataSource({
        store: projects,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedFarms(e) {
      const farms = await this.getSampleFilter({
        atc: 'Medio Ambiente',
        attribute: 'farm',
      });

      this.farms = new DataSource({
        store: farms,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedParcels(e) {
      const parcels = await this.getSampleFilter({
        atc: 'Medio Ambiente',
        attribute: 'parcel',
      });

      this.parcels = new DataSource({
        store: parcels,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    contentReady(e) {
      const arrayNotFoundItems = Array.from(
        document.getElementsByClassName('dx-empty-message')
      );

      arrayNotFoundItems.forEach((element) => {
        if (!this.loaded) {
          element.innerHTML = this.$t('loading');
        } else {
          element.innerHTML = this.$t('dataNotAvailable');
        }
      });

      this.loaded = false;
    },
    setReceptionDate(e) {
      if (e.length === 2) {
        this.setReceptionDateFrom(e[0]);
        this.setReceptionDateTo(e[1]);
      } else if (e.length === 0) {
        this.setReceptionDateFrom(null);
        this.setReceptionDateTo(null);
      }
    },
    setSamplingDate(e) {
      if (e.length === 2) {
        this.setSamplingFromDate(e[0]);
        this.setSamplingToDate(e[1]);
      } else if (e.length === 0) {
        this.setSamplingFromDate(null);
        this.setSamplingToDate(null);
      }
    },
    sampleTypesChanged(e) {
      this.setSelectedSampleTypes(e.value);
    },
    selectedProjectsChanged(e) {
      this.setSelectedProjects(e.value);
    },
    farmsChanged(e) {
      this.setSelectedFarms(e.value);
    },
    parcelsChanged(e) {
      this.setSelectedParcels(e.value);
    },
    analyseTypesChanged(e) {
      this.setSelectedAnalyseTypes(e.value);
    },
    changeNoOfIneligible({ event }) {
      if (+event.target.value === 0) {
        this.setNoOfIneligible(null);
      } else {
        this.setNoOfIneligible(+event.target.value);
      }
    },
    changeSelectionPriority(e) {
      this.setGreaterThan(e.value);
    },
    statusesChanged(e) {
      this.setSelectedStatuses(e.value);
    },
    ...mapActions('environmentFilter', [
      'setReceptionDateFrom',
      'setReceptionDateTo',
      'setSamplingToDate',
      'setSamplingFromDate',
      'setSelectedSampleTypes',
      'setSelectedFarms',
      'setSelectedParcels',
      'setNoOfIneligible',
      'setSelectedStatuses',
      'setSelectedAnalyseTypes',
      'setSelectedProjects',
      'setGreaterThan',
    ]),
    ...mapActions('samples', ['getSampleFilter']),
  },
};
</script>
