<template>
  <div
    v-if="loaded"
    class="tableContainer marginTop15"
    style="border-radius: 5px"
  >
    <f7-row>
      <f7-col>
        <DxForm
          style="padding: 5px"
          class="ha-form marginTop15"
          col-count="1"
          :form-data="formData"
        >
          <DxItem
            :editor-options="{
              items: formData.farms,
              onValueChanged: farmChanged,
              value: isEditingFarm ? farmStr : farm,
              width: '235px',
              valueExpr: isEditingFarm ? 'farm' : 'id',
              displayExpr: 'name',
              disabled: isEditingFarm || isRecreatingParcel,
              placeholder: $t('select'),
            }"
            data-field=""
            editor-type="dxSelectBox"
          >
            <DxRequiredRule :message="$t('alert.requiredFarm')" />
            <DxLabel :text="$t('agronomySample.summary.estate')" />
          </DxItem>

          <DxItem
            :editor-options="{
              items: formData.parcels,
              onValueChanged: parcelChanged,
              value: isEditingFarm ? parcelStr : parcel,
              width: '235px',
              valueExpr: isEditingFarm ? 'parcel' : 'id',
              displayExpr: 'name',
              disabled: isEditingFarm || !farm,
              placeholder: $t('select'),
            }"
            data-field=""
            editor-type="dxSelectBox"
          >
            <DxRequiredRule
              :message="$t('agronomySample.summary.parcel')"
            />
            <DxLabel :text="$t('agronomySample.summary.parcel')" />
          </DxItem>
        </DxForm>
      </f7-col>
      <f7-col></f7-col>
      <f7-col></f7-col>
      <f7-col></f7-col>
      <f7-col style="margin-top: 15px">
        <span>
          {{ $t('latitude') }}:
          <strong>{{
            parcelCentroide ? parcelCentroide.lat.toFixed(6) : ''
          }}</strong>
        </span>
        <br />

        <span style="margin-top: 25px">
          {{ $t('longitude') }}:
          <strong>{{
            parcelCentroide ? parcelCentroide.lng.toFixed(6) : ''
          }}</strong>
        </span>
        <br />

        <span>
          {{ $t('surface') }} (ha):
          <strong>{{
            parcelArea ? parcelArea.toFixed(2) : ''
          }}</strong>
        </span>
      </f7-col>
    </f7-row>
  </div>
</template>

<script>
import { DxForm, DxItem, DxLabel } from 'devextreme-vue/form';
import { DxRequiredRule } from 'devextreme-vue/validator';
import { mapActions, mapState } from 'vuex';
import EventBus from '@/services/event-bus';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  components: {
    DxForm,
    DxItem,
    DxLabel,
    DxRequiredRule,
  },
  data() {
    return {
      formData: {
        farms: [],
        parcels: [],
      },
      loaded: false,
    };
  },
  computed: {
    ...mapState('registerFarm', [
      'farm',
      'farmStr',
      'accountable',
      'reset',
      'parcel',
      'parcelStr',
      'isEditingFarm',
      'isCreatingParcel',
      'isRecreatingParcel',
    ]),
    ...mapState('gisEditor', [
      'parcelArea',
      'parcelCentroide',
      'parcelIndex',
    ]),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    if (this.reset) {
      this.setFarm('');
      this.setParcel('');
    }

    if (
      !this.isEditingFarm &&
      !this.isCreatingParcel &&
      !this.isRecreatingParcel
    ) {
      try {
        this.formData.farms = await this.getFarms(true);
        this.formData.parcels = await this.getParcels();
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(error.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      }
    } else if (this.isCreatingParcel) {
      try {
        const farms = await this.getFarms(false);
        this.formData.farms = farms;
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(error.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      }
    } else if (this.isRecreatingParcel) {
      this.formData.farms = await this.getFarms(false);
      this.formData.parcels = await this.getParcels();
    } else {
      this.formData.parcels.push({ parcel: this.parcelStr });
      this.formData.farms.push({ farm: this.farmStr });
    }

    this.$f7.preloader.hide();
    this.loaded = true;
  },
  methods: {
    async farmChanged(e) {
      if (!e.event) return;
      this.setFarmStr(e.event.target.innerText);
      this.setFarm(e.value);

      if (this.isCreatingParcel) {
        try {
          this.$f7.preloader.show();

          const parcels = await this.getParcelsGeometries(true);
          this.formData.parcels = parcels;
          await this.addFarmGeoFeature();
          EventBus.$emit('geoFeaturesUpdated');
        } catch (e) {
          this.$notifyDX(
            {
              message: this.$t(
                this.$helpers.getFilteredErrorMessage(
                  JSON.parse(e.message).error
                )
              ),
              width: 550,
            },
            'error',
            NOTIFY_TIME_LONG
          );
        } finally {
          this.$f7.preloader.hide();
        }
      }
    },
    async addFarmGeoFeature() {
      const farmGeoFeature = await this.getFarmGeoFeatureByCode();
      this.setFarmId(farmGeoFeature[0].id);
      this.setAccountable(
        farmGeoFeature[0].geo_feature.features[0].properties
          .accountable
      );

      if (farmGeoFeature[0].geo_feature.numberReturned) {
        this.replaceCurrentGeoFeature(farmGeoFeature[0].geo_feature);
        this.setArea(
          farmGeoFeature[0].geo_feature.features[0].properties.area
        );
        this.setCentroide({
          lat: farmGeoFeature[0].geo_feature.features[0].properties
            .latitude,
          lng: farmGeoFeature[0].geo_feature.features[0].properties
            .longitude,
        });
      } else {
        this.resetCurrentGeoFeature();
        this.setLocation({
          newCoordinates:
            farmGeoFeature[0].geo_feature.features[0].geometry
              .coordinates,
          index: 0,
          area: farmGeoFeature[0].geo_feature.features[0].properties
            .area,
          centroide: {
            lat: farmGeoFeature[0].geo_feature.features[0].properties
              .latitude,
            lng: farmGeoFeature[0].geo_feature.features[0].properties
              .longitude,
          },
          originSigpacEditor: true,
        });
      }

      this.setParcelIndex(0);
      this.resetParcelGeoFeature([]);
      // Parcels
      if (farmGeoFeature[0].children.length > 0) {
        farmGeoFeature[0].children.forEach((children) => {
          const { coordinates } =
            children.geo_feature.features[0].geometry;
          const { area } =
            children.geo_feature.features[0].properties;
          const centroide = {
            lat: children.geo_feature.features[0].properties.latitude,
            lng: children.geo_feature.features[0].properties
              .longitude,
          };
          const { properties } = children.geo_feature.features[0];
          this.setLocation({
            newCoordinates: coordinates,
            index: 0,
            area,
            properties,
            centroide,
            originSigpacEditor: false,
          });

          this.setParcelIndex(this.parcelIndex + 1);
        });
      }
      this.resetAll({ isCreatingParcel: true });
    },
    parcelChanged(e) {
      if (!e.event) return;
      const selectedItem = e.component.option('selectedItem');

      this.setParcelId(selectedItem.id);
      this.setParcelStr(e.event.target.innerText);
      this.setParcel(e.value);
    },
    ...mapActions('gis', [
      'getFarms',
      'getParcels',
      'getParcelsGeometries',
      'getFarmGeoFeature',
      'getFarmGeoFeatureByCode',
    ]),
    ...mapActions('gisEditor', [
      'setCurrentGeoFeature',
      'resetAll',
      'setLocation',
      'setParcelIndex',
      'resetParcelGeoFeature',
      'replaceCurrentGeoFeature',
      'setArea',
      'setCentroide',
      'resetCurrentGeoFeature',
    ]),
    ...mapActions('registerFarm', [
      'setFarm',
      'setFarmId',
      'setParcel',
      'setFarmStr',
      'setParcelStr',
      'setParcelId',
      'setAccountable',
    ]),
  },
};
</script>
