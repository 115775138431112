<template>
  <f7-page class="main-nutritional-monitoring">
    <f7-block class="dashboard no-padding no-margin">
      <div class="tableContainer">
        <NutritionalFollowingTable :store="store" />
      </div>
    </f7-block>
  </f7-page>
</template>
<script>
import NutritionalFollowingTable from '@/components/Agronomy/NutritionalFollowing/Table.vue';
import { mapActions } from 'vuex';
import EventBus from '@/services/event-bus';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  name: 'NutritionalMonitoringMainPage',
  components: {
    NutritionalFollowingTable,
  },
  data() {
    return {
      loaded: false,
      store: null,
    };
  },
  beforeMount() {
    this.setCurrentFilter('agronomyNutritionalMonitoring');

    this.setCurrentRoute([
      {
        name: this.$t('Agronomy'),
        disabled: false,
        page: 'AgronomySamplesPage',
        active: false,
      },
      {
        name: this.$t('nutritionalMonitoring'),
        disabled: true,
        page: 'NutritionalMonitoringMainPage',
        active: true,
      },
    ]);
  },
  async mounted() {
    try {
      this.$f7.preloader.show();

      this.store = await this.getStore();

      EventBus.$on(
        'NutritionalMonitoringMainPage',
        async (params) => {
          this.$f7.preloader.show();
          this.loaded = false;

          if (params) {
            this.store = await this.getStoreWithFilters(params);
          } else {
            this.store = await this.getStore();
          }

          this.loaded = true;
          this.$f7.preloader.hide();
        }
      );
    } catch (e) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(e.message).error
            )
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },
  methods: {
    ...mapActions('filter', ['setCurrentFilter']),
    ...mapActions('breadcrumb', [
      'setCurrentRoute',
      'replaceCurrent',
    ]),
    ...mapActions('nutritionalFollowing', [
      'getStore',
      'getStoreWithFilters',
    ]),
  },
};
</script>
<style lang="scss" scoped>
@import './NutritionalMonitoring.styles.scss';
</style>
