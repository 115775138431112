<template>
  <transition name="fade">
    <div v-if="isVisible" class="alert">
      <button class="closebtn" @click="closeBoton">&times;</button>
      <strong>{{ $t('price_Alert') }}</strong>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'NotificationPrices',
  data() {
    return {
      isVisible: true,
    };
  },
  methods: {
    closeBoton() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './NotificationPrices.styles.scss';
</style>
