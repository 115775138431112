<template>
  <div class="margin-top">
    <div class="filter-box">
      <div class="label-input">
        {{ $t('foodSamples.parameter') }}
      </div>
      <div class="marginTop5">
        <DxTagBox
          :data-source="parameterTypes"
          :multiline="true"
          :search-enabled="true"
          :placeholder="$t('foodSamples.parameter')"
          tag-template="tag"
          display-expr="value"
          value-expr="id"
          :value="selectedParameters"
          :on-value-changed="parametersChanged"
          :on-opened="openedParameters"
          :on-content-ready="contentReady"
          :show-selection-controls="true"
        >
          <template #tag="{ data }">
            <div class="purpleTag">
              <b>{{ data.value }}</b>
              <div class="dx-tag-remove-button xButton"></div>
            </div>
          </template>
        </DxTagBox>
        <div class="label-input margin-top-half">
          {{ $t('environmentSamples.limits') }}
        </div>
        <DxSelectBox
          :items="limits"
          :placeholder="$t('environmentSamples.limits')"
          :value="selectedLimits.length > 0 ? selectedLimits[0] : ''"
          :on-value-changed="limitsChanged"
          :on-opened="openedLimitsLMR"
          :on-content-ready="contentReady"
          :show-clear-button="true"
        >
        </DxSelectBox>
      </div>
    </div>

    <div class="label-input margin-top">{{ $t('group') }}</div>
    <div class="marginTop5">
      <DxTagBox
        :data-source="parameterGroups"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('group')"
        tag-template="tag"
        display-expr="value"
        value-expr="value"
        :value="selectedParameterGroups"
        :on-value-changed="parameterGroupChanged"
        :on-opened="openedParameterGroup"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>

    <div v-if="isNoRp" class="margin-top-half">
      <div class="label-input">
        {{ $t('environmentSamples.limits') }}
      </div>
      <DxTagBox
        :data-source="limits"
        :multiline="true"
        :placeholder="$t('environmentSamples.limits')"
        tag-template="tag"
        :value="selectedLimits"
        :on-value-changed="limitsChanged"
        :on-opened="openedLimitsCMA"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
    <div v-else class="margin-top">
      <div
        class="
          item-title-bold
          margin-horizontal-half margin-bottom-half
        "
        style="text-transform: uppercase"
      >
        <strong>{{ $t('stats.comparatives') }}</strong>
      </div>
      <div class="label-input">
        {{ $t('foodSamples.lmr') }}
      </div>
      <DxTagBox
        :data-source="lmrs"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('foodSamples.lmr')"
        tag-template="tag"
        display-expr="value"
        value-expr="code"
        :value="selectedLMRs"
        :on-value-changed="LMRsChanged"
        :on-opened="openedLMRs"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
  </div>
</template>

<script>
import { DxTagBox } from 'devextreme-vue/tag-box';
import { mapActions, mapState } from 'vuex';
import DxSelectBox from 'devextreme-vue/select-box';
import countryTranslationServiceEs from '@/i18n/translated_countries_service_es';
import DataSource from 'devextreme/data/data_source';

export default {
  name: 'FoodDetailsFilter',
  components: {
    DxTagBox,
    DxSelectBox,
  },
  data() {
    return {
      parameterTypes: [],
      parameterGroups: [],
      limits: [],
      lmrs: [],
      loaded: false,
    };
  },
  computed: {
    ...mapState('foodSamples', ['sampleDetails', 'isNoRp']),
    ...mapState('foodDetailsFilter', [
      'selectedParameters',
      'selectedLimits',
      'selectedLMRs',
      'selectedParameterGroups',
    ]),
    ...mapState('filter', ['currentSampleId']),
  },
  mounted() {
    if (this.limits.length === 0) {
      this.limits = ['> LMR', this.$t('positives')];
    }
  },
  methods: {
    async openedParameters(e) {
      const parameters = await this.getSampleFilter({
        atc: 'Alimentaria',
        attribute: 'parameter',
        sampleId: this.currentSampleId,
      });

      this.parameterTypes = new DataSource({
        store: parameters,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedParameterGroup(e) {
      const parameters = await this.getSampleFilter({
        atc: 'Alimentaria',
        attribute: 'parameter_group',
        sampleId: this.currentSampleId,
      });

      this.parameterGroups = new DataSource({
        store: parameters,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    openedLimitsCMA() {
      this.limits = ['> CMA'];
      this.loaded = true;
    },
    openedLimitsLMR() {
      this.limits = ['> LMR', this.$t('positives')];
      this.loaded = true;
    },
    async openedLMRs() {
      this.lmrs = [];
      const lmrs = await this.getParameterLMRs();

      if (lmrs.length > 0) {
        lmrs.forEach((lmr) => {
          this.lmrs.push({
            code: lmr,
            value:
              countryTranslationServiceEs.getCountryNameByCode(lmr),
          });
        });
      } else {
        this.lmrs = [];
      }

      this.loaded = true;
    },
    contentReady(e) {
      const arrayNotFoundItems = Array.from(
        document.getElementsByClassName('dx-empty-message')
      );

      arrayNotFoundItems.forEach((element) => {
        if (!this.loaded) {
          element.innerHTML = this.$t('loading');
        } else {
          element.innerHTML = this.$t('dataNotAvailable');
        }
      });

      this.loaded = false;
    },
    parametersChanged(e) {
      this.setSelectedParameters(e.value);
    },
    parameterGroupChanged(e) {
      this.setSelectedParameterGroup(e.value);
    },
    limitsChanged(e) {
      this.setSelectedLimits(e.value);
    },
    LMRsChanged(e) {
      this.setSelectedLMRs(e.value);
    },
    ...mapActions('foodDetailsFilter', [
      'setSelectedParameters',
      'setSelectedLimits',
      'setSelectedLMRs',
      'setSelectedParameterGroup',
    ]),
    ...mapActions('samples', ['getSampleFilter']),
    ...mapActions('foodSamples', ['getParameterLMRs']),
    ...mapActions('foodDetailsFilter', ['setSelectedLimits']),
  },
};
</script>
