<template>
  <div>
    <div class="filterButtonPosition">
      <f7-button
        fill
        class="whiteFontColor button-filter"
        panel-toggle="right"
      >
        <span class="ic-filter"></span> {{ $t('filter') }}
      </f7-button>
    </div>
    <DxDataGrid
      :data-source="samples"
      :show-borders="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :show-colon="false"
      :hover-state-enabled="true"
      :remote-operations="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
    >
      <DxScrolling mode="standard" />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="[5, 10, 20]"
        :show-info="true"
        info-text="{2} items"
        :visible="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="300"
        :placeholder="$t('search')"
      />
      <DxColumn
        data-field="sample_code"
        :caption="$t('agronomySample.summary.sampleCode')"
        alignment="left"
        :allow-header-filtering="false"
        :fixed="true"
        fixed-position="left"
      />
      <DxColumn
        data-field="sampling_date"
        :caption="$t('agronomySample.summary.samplingDate')"
        cell-template="cellTemplateDate"
        alignment="left"
        :allow-header-filtering="false"
      />
      <template #cellTemplateDate="{ data }">
        <div>{{ readableDate(data) }}</div>
      </template>
      <DxColumn
        data-field="sample_type_family"
        :caption="$t('geotoolbox.sampleFamily')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="sample_type_subfamily"
        :caption="$t('geotoolbox.sampleSubfamily')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="sample_type"
        :caption="$t('agronomySample.summary.sampleType')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="parcel"
        :caption="$t('agronomySample.summary.parcel')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="study"
        :caption="$t('project')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field=""
        alignment="left"
        :allow-header-filtering="false"
        cell-template="cellTemplateButtons"
        :fixed="true"
        fixed-position="right"
      />
      <template #cellTemplateButtons="{ data }">
        <div class="buttons-group">
          <button
            type="button"
            class="icon-button icon-zoom"
            @click="zoomToSample(data)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-map-search"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                stroke="none"
                d="M0 0h24v24H0z"
                fill="none"
              ></path>
              <path d="M11 18l-2 -1l-6 3v-13l6 -3l6 3l6 -3v10"></path>
              <path d="M9 4v13"></path>
              <path d="M15 7v5"></path>
              <circle cx="16.5" cy="17.5" r="2.5"></circle>
              <path d="M18.5 19.5l2.5 2.5"></path>
            </svg>
          </button>
          <button
            type="button"
            class="icon-button icon-nutritional"
            @click="goToNutritionalMonitoring(data)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-leaf"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                stroke="none"
                d="M0 0h24v24H0z"
                fill="none"
              ></path>
              <path d="M5 21c.5 -4.5 2.5 -8 7 -10"></path>
              <path
                d="M9 18c6.218 0 10.5 -3.288 11 -12v-2h-4.014c-9 0 -11.986 4 -12 9c0 1 0 3 2 5h3z"
              ></path>
            </svg>
          </button>
          <button
            type="button"
            class="icon-button icon-detail"
            @click="goToDetail(data)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-search"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                stroke="none"
                d="M0 0h24v24H0z"
                fill="none"
              ></path>
              <circle cx="10" cy="10" r="7"></circle>
              <line x1="21" y1="21" x2="15" y2="15"></line>
            </svg>
          </button>
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
// import { mapActions, mapState } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  // DxStateStoring,
  DxPager,
  DxPaging,
  DxScrolling,
  // DxExport,
} from 'devextreme-vue/data-grid';
// import PopUp from '@/components/UtilityComponents/popUpInfo/popUp.vue';
// import ContextMenu from '@/components/contextMenu/index.vue';
// import NotificationSpinner from '@/components/NotificationSpinner';
import EventBus from '@/services/event-bus';
import { mapActions } from 'vuex';

export default {
  name: 'LocationTab',
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    // DxStateStoring,
    DxPager,
    DxPaging,
    DxScrolling,
    // DxExport,
    // PopUp,
    // ContextMenu,
    // NotificationSpinner,
  },
  props: {
    samples: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    readableDate(data) {
      return new Date(data.data.sampling_date).toLocaleDateString(
        'es-ES'
      );
    },
    // Acceso directo a la vista de Detalle de muestra asociada.
    async goToDetail(data) {
      this.setSampleFetchedFromEtl(false);
      this.setLastRouteHome(false);
      this.setLastRouteGeotoolboxSamples(true);

      this.setCurrentSampleId(data.data.sample_oid); // 620ba809100ecd445bc4bd72
      this.setReportSampleId(data.data.sample_id); // 3384199
      this.setReportSampleCode(data.data.sample_code); // V-19/011488

      await this.getCurrentSample();

      // await this.goToSampleDetailsByAtc(e.row.data.atc_name, e); // atc_name: Agronomía

      this.setCurrentFilter('agronomy');

      // this.pushRoute({
      //   name: this.$t('Agronomy'),
      //   disabled: false,
      //   page: 'AgronomySamplesPage',
      //   active: false,
      // });

      this.pushRoute({
        name: this.$t('sampleSummary'),
        disabled: true,
        page: 'AgronomySamplesDetailsPage',
        active: true,
      });

      this.$f7router.navigate(
        {
          name: 'AgronomySamplesDetailsPage',
        },
        {
          animate: true,
          transition: 'f7-fade',
        }
      );
    },
    // Acceso a la vista de Seguimiento Nutricional de Servicios Avanzados asociado a la finca y parcela de la muestra.
    goToNutritionalMonitoring(data) {
      this.setParcelId(data.data.parcel_id);
      this.setParcelStr(data.data.parcel);
      this.setLastRouteGeotoolbox(true);

      this.$f7router.navigate(
        { name: 'NutritionalMonitoringPage' },
        {
          animate: true,
          transition: 'f7-fade',
        }
      );
    },
    // Zoom a la muestra el visor GIS.
    zoomToSample(data) {
      const { coordinates } = data.data.geometry;

      EventBus.$emit('zoom-to-sample', coordinates);
    },

    ...mapActions('nutritionalMonitoring', [
      'setParcelId',
      'setLastRouteGeotoolbox',
    ]),
    ...mapActions('registerFarm', ['setParcelStr']),
    ...mapActions('filter', [
      'setCurrentFilter',
      'setCurrentSampleId',
    ]),
    ...mapActions('samples', [
      'setReportSampleId',
      'getCurrentSample',
      'setReportSampleCode',
      'setSampleFetchedFromEtl',
      'setLastRouteHome',
      'setLastRouteGeotoolboxSamples',
    ]),
    ...mapActions('breadcrumb', ['pushRoute']),
  },
};
</script>

<style lang="scss" scoped>
@import './LocationTab.styles.scss';
</style>
