<template>
  <DxBullet
    v-if="cellData.value.very_high_level != null"
    :show-target="true"
    :show-zero-level="true"
    :color="colorOfBullet"
    :value="Number(cellData.value.result)"
    :start-scale-value="0"
    :end-scale-value="cellData.value.very_high_level"
  >
    <DxSize :width="150" :height="35" />
    <DxMargin :top="5" :bottom="0" :left="5" />
    <DxTooltip
      :enabled="true"
      :padding-top-bottom="2"
      :z-index="999999"
      :customize-tooltip="customizeTooltip"
    >
      <DxFont :size="18" />
    </DxTooltip>
  </DxBullet>
</template>
<script>
import {
  DxBullet,
  DxFont,
  DxMargin,
  DxSize,
  DxTooltip,
} from 'devextreme-vue/bullet';

export default {
  components: {
    DxBullet,
    DxFont,
    DxMargin,
    DxSize,
    DxTooltip,
  },
  props: {
    cellData: {
      type: Object,
      default: () => {},
    },
    maxValue: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    colorOfBullet() {
      if (
        this.cellData.data.value.result <
        this.cellData.data.value.very_low_level
      ) {
        return '#ff6384';
      }

      if (
        this.cellData.data.value.result <
        this.cellData.data.value.low_level
      ) {
        return '#ffce56';
      }

      if (
        this.cellData.data.value.result <
        this.cellData.data.value.high_level
      ) {
        return '#4bc0c0';
      }

      if (
        this.cellData.data.value.result <
        this.cellData.data.value.very_high_level
      ) {
        return '#ffce56';
      }

      return '#ff6384';
    },
    toolTipText() {
      if (
        this.cellData.data.value.result <
        this.cellData.data.value.very_low_level
      ) {
        return this.$t('veryLowLevel');
      }

      if (
        this.cellData.data.value.result <
        this.cellData.data.value.low_level
      ) {
        return this.$t('lowLevel');
      }

      if (
        this.cellData.data.value.result <
        this.cellData.data.value.high_level
      ) {
        return this.$t('optimalLevel');
      }

      if (
        this.cellData.data.value.result <
        this.cellData.data.value.very_high_level
      ) {
        return this.$t('highLevel');
      }

      return this.$t('veryHighLevel');
    },
  },
  methods: {
    customizeTooltip() {
      return {
        text: this.toolTipText ?? '',
      };
    },
  },
};
</script>
<style>
.dx-datagrid .dx-data-row > td.bullet {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
