<template>
  <DxDataGrid
    v-show="showTable"
    class="margin-bottom brown-table-style"
    :show-borders="true"
    :data-source="getTableData"
    :allow-column-resizing="true"
    :column-auto-width="true"
    :customize-columns="customizeColumns"
  >
    <DxColumn
      data-field="sample_type"
      :caption="caption"
      :allow-sorting="false"
      :width="170"
    />
    <DxColumn
      data-field="Textura.value"
      :caption="$t('sampleTypeParameterTexture')"
      :allow-sorting="false"
      :min-width="72"
      alignment="right"
    />
    <DxColumn
      data-field="Arena.value"
      caption="Arena"
      :allow-sorting="false"
      :min-width="72"
      alignment="right"
    />
    <DxColumn
      data-field="Limo.value"
      caption="Limo"
      :allow-sorting="false"
      :min-width="72"
      alignment="right"
    />
    <DxColumn
      data-field="Arcilla.value"
      caption="Arcilla"
      :allow-sorting="false"
      :min-width="72"
      alignment="right"
    />
    <DxColumn
      data-field="pH.value"
      caption="pH"
      :allow-sorting="false"
      :min-width="72"
      alignment="right"
    />
    <DxColumn
      data-field="CE1_5.value"
      caption="CE 1/5"
      :allow-sorting="false"
      :min-width="72"
      alignment="right"
    />
    <DxColumn
      data-field="CalAct.value"
      caption="Cal. Act."
      :allow-sorting="false"
      :min-width="72"
      alignment="right"
    />
    <DxColumn
      data-field="MO.value"
      caption="MO"
      :allow-sorting="false"
      :min-width="72"
      alignment="right"
    />
    <DxColumn
      data-field="N_Total.value"
      caption="N"
      :allow-sorting="false"
      :min-width="72"
      alignment="right"
    />
    <DxColumn
      data-field="C_N.value"
      caption="C/N"
      :allow-sorting="false"
      :min-width="72"
      alignment="right"
    />
    <DxColumn
      data-field="PDOlsen.value"
      caption="P D Olsen"
      :allow-sorting="false"
      :min-width="72"
      alignment="right"
    />
    <DxColumn
      data-field="CaD.value"
      caption="Ca D"
      :allow-sorting="false"
      :min-width="72"
      alignment="right"
    />
    <DxColumn
      data-field="MgD.value"
      caption="Mg D"
      :allow-sorting="false"
      :min-width="72"
      alignment="right"
    />
    <DxColumn
      data-field="KD.value"
      caption="K D"
      :allow-sorting="false"
      :min-width="72"
      alignment="right"
    />
    <DxColumn
      data-field="NaD.value"
      caption="Na D"
      :allow-sorting="false"
      :min-width="72"
      alignment="right"
    />
    <DxColumn
      data-field="SumaBasesD.value"
      caption="Suma Bases D"
      :allow-sorting="false"
      :min-width="72"
      alignment="right"
    />
    <DxColumn
      data-field="B.value"
      caption="B"
      :allow-sorting="false"
      :min-width="72"
      alignment="right"
    />
    <DxColumn
      data-field="ZnDTPA.value"
      caption="Zn DTPA"
      :allow-sorting="false"
      :min-width="72"
      alignment="right"
    />
    <DxColumn
      data-field="CuDTPA.value"
      caption="Cu DTPA"
      :allow-sorting="false"
      :min-width="72"
      alignment="right"
    />
  </DxDataGrid>
</template>

<script>
import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';
import { mapState } from 'vuex';
import format from 'date-fns/format';

export default {
  name: 'DetailedTableSuelo',
  components: {
    DxDataGrid,
    DxColumn,
  },
  props: {
    nutritionalMonitoringData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      tableName: 'Suelo',
      texturas: [
        'Manual',
        'Arcillosa',
        'Arcillo-Arenosa',
        'Arcillo-Limosa',
        'Franco-Arcillosa',
        'Franco-Arcillo-Limosa',
        'Franco-Arcillo-Arenosa',
        'Franca',
        'Franca-Limosa',
        'Limosa',
        'Franco-Arenosa',
        'Arenosa',
        'Arena-Franca',
      ],
    };
  },
  computed: {
    showTable() {
      let show = false;

      if (
        this.nutritionalMonitoringData?.[this.tableName]?.data == null
      ) {
        return false;
      }

      this.nutritionalMonitoringData[this.tableName].data.forEach(
        (element) => {
          Object.entries(element?.parameters ?? {}).forEach(
            ([key, value]) => {
              if ((value?.value.ResultadoPresentacion ?? '') !== '') {
                show = true;
              }
            }
          );
        }
      );

      return show;
    },
    caption() {
      const date =
        this.nutritionalMonitoringData?.[this.tableName]
          ?.sampling_date;

      if (date != null) {
        const formattedDate = format(new Date(date), 'dd-MM-yyyy');
        return `${this.$t('sampleTypeSoil')} (${formattedDate})`;
      }

      return this.$t('sampleTypeSoil');
    },
    getTableData() {
      if (
        this.nutritionalMonitoringData?.[this.tableName]?.data == null
      ) {
        return [];
      }

      const result = [];

      this.nutritionalMonitoringData[this.tableName].data.forEach(
        (element) => {
          const parameters = {};

          Object.entries(element?.parameters ?? {}).forEach(
            ([key, value]) => {
              parameters[key] = {
                name: value?.parameter ?? '',
                value: value?.value?.ResultadoPresentacion ?? '',
                unit: value?.value?.unit ?? '',
              };
            }
          );

          result.push({
            sample_type: element.sample_type,
            ...parameters,
          });
        }
      );

      return result;
    },
    ...mapState('nutritionalMonitoring', [
      // 'NutritionalMonitoringData',
    ]),
  },
  methods: {
    customizeColumns(columns) {
      if (this.getTableData.length === 0) {
        return;
      }

      const columnsToTranslate = [
        'Textura',
        'Arena',
        'Limo',
        'Arcilla',
        'CE',
        'CalAct',
        'MO',
        'N_Total',
        'SumaBasesD',
      ];

      const columnsCustomTranslate = [
        'Textura',
        'Arena',
        'Limo',
        'Arcilla',
        'CalAct',
        'MO',
        'SumaBasesD',
        'N_Total',
      ];

      columns.forEach((column) => {
        if (column.dataField !== 'sample_type') {
          const { name, unit } =
            this.getTableData[0][
              column.dataField.replace('.value', '')
            ];

          const dataField = column.dataField.replace('.value', '');
          const { caption } = column;

          if (name === '') {
            column.caption = caption;
          } else {
            const translate = columnsToTranslate.includes(dataField);
            const customTranslate =
              columnsCustomTranslate.includes(dataField);

            let parameter = '';
            if (translate && customTranslate) {
              parameter = this.$t(`sampleTypeParameter${dataField}`);
            } else if (translate && !customTranslate) {
              parameter = name;
            } else {
              parameter = caption;
            }

            if (unit) {
              column.caption = `${parameter} (${unit})`;
            } else {
              column.caption = parameter;
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .dx-header-row td[role='columnheader'] {
  text-transform: none;
  font-size: 11px;
}
</style>
