<template>
  <div v-if="loaded">
    <DxLookup
      id="companyAddressLookup"
      :data-source="ds"
      :grouped="enabled"
      width="400"
      style="margin-right: 10px"
      display-expr="name"
      :search-expr="
        enabled
          ? ['companyName', 'name']
          : ['companyName', 'addressName', 'companyCode']
      "
      styling-mode="filled"
      field-template="field-item"
      item-template="list-item"
      :page-load-mode="enabled ? null : 'nextButton'"
      :next-button-text="enabled ? null : $t('alert.viewMore')"
      @item-click="itemClick"
    >
      <template #field-item="{ data }">
        <div class="flex">
          <div class="bold txt-ellipsis">
            {{ companyName }}
          </div>
          <div class="italic txt-ellipsis">
            - {{ currentAddress }}
          </div>
        </div>
      </template>
      <template #list-item="{ data }">
        <div v-if="data.name.length > 0">
          <span v-if="!enabled">
            <div>
              <strong class="fourtyChars">
                {{ data.companyCode }} - {{ data.name }}
              </strong>
            </div>

            <span class="fourtyChars">
              {{ data.addressName }}
            </span>
          </span>

          <div v-if="enabled">{{ data.name }}</div>
        </div>
      </template>
      <DxDropDownOptions
        height="500"
        :shading="true"
        :close-on-outside-click="true"
        :show-title="false"
      />
    </DxLookup>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import EventBus from '@/services/event-bus';
import { DxLookup, DxDropDownOptions } from 'devextreme-vue/lookup';
import DataSource from 'devextreme/data/data_source';
import { ALL_LANGUAGES } from '@/js/constants';

import esMessages from '@/json/langs/es.json';
import frMessages from '@/json/langs/fr.json';
import ptMessages from '@/json/langs/pt.json';
import enMessages from '@/json/langs/en.json';
import itMessages from '@/json/langs/it.json';
import { locale, loadMessages } from 'devextreme/localization';

export default {
  components: {
    DxLookup,
    DxDropDownOptions,
  },
  data() {
    const showSubmenuModes = [
      {
        name: 'onHover',
        delay: { show: 0, hide: 500 },
      },
      {
        name: 'onClick',
        delay: { show: 0, hide: 300 },
      },
    ];
    return {
      loaded: false,
      showFirstSubmenuModes: showSubmenuModes[1],
      // ds: null,
      langsDx: {
        esMessages,
        frMessages,
        ptMessages,
        enMessages,
        itMessages,
      },
    };
  },
  computed: {
    groupEnabled() {
      return !this.enabled;
    },
    ds() {
      if (this.enabled) {
        return new DataSource({
          store: Array.isArray(this.items) ? this.items : [],
          key: 'id',
          group: 'companyName',
        });
      }

      return new DataSource({
        store: Array.isArray(this.items) ? this.items : [],
        key: 'companyName',
        paginate: true,
        pageSize: 10,
      });
    },
    ...mapState('clientMenu', ['currentAddress']),
    ...mapGetters('clientMenu', ['items']),
    ...mapState('contact', [
      'enabled',
      'companyName',
      'hasAccessToAdvanceService',
    ]),
    ...mapState('leftPanel', ['hiddenPanel']),
    ...mapGetters('user', ['userProfile']),
  },
  async beforeMount() {
    this.loaded = false;
    // if (this.enabled) {
    //   this.ds = new DataSource({
    //     store: Array.isArray(this.items) ? this.items : [],
    //     key: 'id',
    //     group: 'companyName',
    //   });
    // } else {
    //   this.ds = new DataSource({
    //     store: Array.isArray(this.items) ? this.items : [],
    //     key: 'companyName',
    //     paginate: true,
    //     pageSize: 10,
    //   });
    // }

    if (this.enabled) {
      if (this.items.length !== 0) {
        this.setAddress(this.items[0].addressCode);
        this.setCompany(this.items[0].addressCompany);
        this.setAddressName(this.items[0].name);
        this.setCompanyName(this.items[0].companyName);

        this.setMenuAddress();

        this.items.forEach((contact) => {
          if (contact.id === this.items[0].id) {
            this.setAddresses(contact.itemsData);
          }
        });
      }
    } else {
      
      if (this.items.length !== 0) {
        this.setAddress(this.items[0].addressCode);
        this.setAddressName(this.items[0].addressName);
        this.setCompany(this.items[0].companyCode);
        this.setCompanyName(this.items[0].companyName);

        this.setMenuAddress();
      }
    }

    try {
      if (this.items.length !== 0) {
        await this.checkAccessToAdvanceService();
        await this.checkAccessToNutritionalMonitoring();
      }
    } catch {
      // handle errror
    }

    this.loaded = true;
  },
  mounted() {
    EventBus.$emit('onChangeCompany', true);
  },
  methods: {
    async itemClick(e) {
      if (this.enabled) {
        if (e.itemData.addressCode) {
          this.setAddressName(e.itemData.name);
          this.setAddress(e.itemData.addressCode);
          this.setCompany(e.itemData.addressCompany);
          this.setCompanyName(e.itemData.companyName);
          this.setContactIndex(e.itemData.contactIndex);
          this.setMenuAddress();

          this.items.forEach((company) => {
            if (company.id === e.itemData.id) {
              this.setAddresses(company.itemsData);
            }
          });
        }
      } else {
        this.setAddressName(e.itemData.addressName);
        this.setAddress(e.itemData.addressCode);
        this.setCompanyName(e.itemData.companyName);
        this.setCompany(e.itemData.companyCode);
        this.setMenuAddress();
      }

      try {
        await this.checkAccessToAdvanceService();
        await this.checkAccessToNutritionalMonitoring();
      } catch {
        // handle errror
      }

      if (this.enabled) {
        this.clearSamplesFilters();
        setTimeout(() => {
          const lang = this.userProfile.language
            ? this.translateLanguage(this.userProfile.language)
            : navigator.language.substring(0, 2).toLowerCase();

          this.$i18n.i18next.changeLanguage(lang);
          this.updateLanguageInterface(lang);

          locale(`${lang}-${lang.toUpperCase()}`);
          loadMessages(this.langsDx[`${lang}Messages`]);
        }, 0);
      }

      const currentRouteName =
        this.$f7.views.main.router.currentRoute.name;
      const currentRoutePath =
        this.$f7.views.main.router.currentRoute.path;

      if (currentRouteName || currentRoutePath) {
        if (currentRouteName.toLowerCase().includes('detail')) {
          const previousRoute =
            this.$f7.views.main.router.previousRoute.name;
          this.$f7.views.main.router.navigate(
            {
              name: previousRoute,
            },
            {
              animate: true,
              transition: 'f7-fade',
              reloadCurrent: true,
            }
          );
        } else if (
          (currentRoutePath != null &&
            currentRoutePath
              .toLowerCase()
              .includes('/advanced-services')) ||
          currentRoutePath.toLowerCase().includes('/gis')
        ) {
          const routeToGo =
            this.enabled === false ||
            (this.enabled && this.hasAccessToAdvanceService)
              ? 'AdvancedServicesPage'
              : 'Home';

          this.$f7.views.main.router.navigate(
            {
              name: routeToGo,
            },
            {
              animate: true,
              transition: 'f7-fade',
              reloadCurrent: true,
            }
          );
        } else {
          EventBus.$emit(currentRouteName, null);
          if (currentRouteName === 'FoodStatsPage') {
            EventBus.$emit('FoodStatsPageClearFilter', null);
          }
        }
      }
    },
    translateLanguage(value) {
      let key = '';
      for (const lang in ALL_LANGUAGES) {
        if (ALL_LANGUAGES[lang].key === value) {
          key = ALL_LANGUAGES[lang].value;
        }
      }

      return key;
    },
    ...mapActions('contact', [
      'setAddressName',
      'setAddress',
      'setCompany',
      'setCompanyName',
      'setAddresses',
      'setContactIndex',
      'checkAccessToAdvanceService',
      'checkAccessToNutritionalMonitoring',
    ]),
    ...mapActions('clientMenu', {
      setMenuAddress: 'setAddress',
    }),
    ...mapActions('filter', ['clearSamplesFilters']),
    ...mapActions('user', ['updateLanguageInterface']),
  },
};
</script>
<style scoped lang="scss">
@import './ClientMenu.styles.scss';
</style>
