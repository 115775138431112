import EventBus from '@/services/event-bus';
import db from '../db';

export default {
  namespaced: true,
  state: {
    items: [],
    currentAddress: '',
  },
  getters: {
    items: (state) => state.items,
  },
  actions: {
    setItems({ commit }, value) {
      commit('setItems', value);
    },
    setAddress({ commit, rootState }) {
      commit('setCurrentAddress', `${rootState.contact.addressName}`);

      setTimeout(() => {
        if (rootState.contact.enabled) {
          EventBus.$emit('onChangeCompany', true);
        }
      }, 0);
    },
  },
  mutations: {
    setItems(state, value) {
      state.items = value;

      if (value.length === 0) {
        return;
      }

      if (value[0]?.companyId == null) {
        db.headquarters.bulkPut(value);
      } else {
        db.contacts.bulkPut(value);
      }
    },
    setCurrentAddress(state, value) {
      state.currentAddress = value;
    },
  },
};

