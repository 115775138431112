<template>
  <DxChart
    id="chart"
    :data-source="getDs"
    @legend-click="onLegendClick"
  >
    <DxCommonSeriesSettings type="line" argument-field="date" />
    <DxSeries
      argument-field="date"
      value-field="value"
      type="line"
      color="#000"
      name="Humedad del suelo (mm)"
    />
    <DxValueAxis title="Humedad del suelo (mm)">
      <DxStrip
        :start-value="0"
        :end-value="endValueBrown"
        color="#bab06a"
      >
      </DxStrip>
      <DxStrip
        :start-value="endValueBrown"
        :end-value="endValueGreen"
        color="#a7cb8d"
      >
      </DxStrip>
      <DxStrip
        :start-value="endValueGreen"
        :end-value="endValueBlue"
        color="#97c0ea"
      >
      </DxStrip>
    </DxValueAxis>

    <DxLegend
      vertical-alignment="bottom"
      horizontal-alignment="center"
      item-text-position="right"
    />
    <DxTitle :text="$t('soilHumidity')">
      <DxFont family="Roboto" size="14" />
    </DxTitle>
    <DxTooltip
      :enabled="true"
      :z-index="999999"
      :interactive="false"
      :customize-tooltip="customizeTooltip"
    />
    <!-- <DxStripStyle>
      <DxLabel>
        <DxFont :weight="500" :size="14" />
      </DxLabel>
    </DxStripStyle> -->
  </DxChart>
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxLegend,
  DxTitle,
  DxTooltip,
  DxStrip,
  DxStripStyle,
  DxValueAxis,
  DxLabel,
  DxFont,
} from 'devextreme-vue/chart';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
  name: 'MIRGraphHumidity',
  components: {
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxLegend,
    DxTitle,
    DxTooltip,
    DxStrip,
    DxStripStyle,
    DxValueAxis,
    DxLabel,
    DxFont, 
  },
  props: {
    filteredDs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {
    getDs() {
      return this.dataSource?.TOTAL_SOIL_MOISTURE ?? [];
    },
    endValueBrown() {
      const IS = this.dataSource?.INITIAL_STRESS?.[0]?.value ?? 0;
      return IS;
    },
    endValueGreen() {
      const CC = this.dataSource?.FIELD_CAPACITY?.[0]?.value ?? 0;
      return CC;
    },
    endValueBlue() {
      const CC = this.dataSource?.FIELD_CAPACITY?.[0]?.value ?? 0;
      return CC * 1.2;
    },
    ...mapState('hidrosoph', ['dataSource']),
  },
  methods: {
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },
    customizeTooltip(pointInfo) {
      return {
        text: `Valor a <strong>${pointInfo.argument}</strong>: <strong>${pointInfo.value}</strong> mm`,
      };
    },
  },
};
</script>
<style scoped lang="scss">
@import './MIRGraphHumidity.styles.scss';
</style>
