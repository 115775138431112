<template>
  <transition :css="false" @leave="leave">
    <f7-page class="main-time-dimension">
      <div class="content-devx">
        <div v-if="!loadedGIS" class="loadingFalse">
          <div class="preloader">
            <span class="preloader-inner">
              <span class="preloader-inner-circle"></span>
            </span>
          </div>
        </div>
        <div v-else>
          <f7-row>
            <f7-col>
              <GISWMSTimeDimensionMap
                :zoom="zoom"
                :name-map="mainMap"
                :layer="layer"
                :bbox="bbox"
                :height="'540px'"
              />
            </f7-col>
            <f7-col>
              <div
                class="display-flex align-items-flex-end justify-content-space-between"
              >
                <DxForm label-location="top">
                  <DxItem
                    :editor-options="{
                      items: indexes,
                      onValueChanged: onIndexChange,
                      value: selectedIndex,
                      valueExpr: 'key',
                      displayExpr: 'value',
                      placeholder: $t('select'),
                    }"
                    data-field=""
                    editor-type="dxSelectBox"
                  >
                    <DxLabel :text="$t('index')" />
                  </DxItem>
                </DxForm>
                <GisTimeDimensionDatePicker />
              </div>

              <div v-if="loadedTimeLine" class="margin-top">
                <TimelineGraph
                  :timeline-indexes="timelineIndexes"
                  :index="selectedIndex"
                />
              </div>
            </f7-col>
          </f7-row>
          <f7-row class="no-margin padding-top-half">
            <f7-col>
              <GoBack
                :go-to-url-name="true"
                url-name="DashboardPage"
              />
            </f7-col>
            <f7-col class="display-flex justify-content-flex-end">
              <DxButton
                type="default"
                :text="$t('comparative')"
                @click="goToComparative"
              />
            </f7-col>
          </f7-row>
        </div>
      </div>
    </f7-page>
  </transition>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import Api from '@/services/Api';
import GISWMSTimeDimensionMap from '@/components/Gis/WMSTimeDimension/index.vue';
import GisTimeDimensionDatePicker from '@/components/Gis/WMSTimeDimension/datePicker/index.vue';
import { DxItem, DxForm, DxLabel } from 'devextreme-vue/form';
import format from 'date-fns/format';
import moment from 'moment';
import TimelineGraph from '@/components/Gis/WMSTimeDimension/timeLineGraph/index.vue';
import EventBus from '@/services/event-bus';
import GoBack from '@/components/UtilityComponents/GoBack/index.vue';
import DxButton from 'devextreme-vue/button';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  name: 'TimeDimensionMapPage',
  components: {
    GISWMSTimeDimensionMap,
    GisTimeDimensionDatePicker,
    DxItem,
    DxLabel,
    DxForm,
    TimelineGraph,
    GoBack,
    DxButton,
  },
  data() {
    return {
      loadedGIS: false,
      loadedTimeLine: false,
      zoom: 17,
      mainMap: 'GisTimeDimension',
      layer: {
        name: 'gis',
        visible: false,
        timeDimension: true,
        baseUrl: Api.getGeoServerProjectWorkSpace(),
        options: {
          layers: '',
          format: 'image/png',
          transparent: true,
          styles: 'NDVI',
        },
      },
      center: {},
      bbox: {},
      filteredTasks: null,
      ws: Api.getGeoServerWorkspace(),
      indexes: [{ key: 'NDVI', value: 'NDVI' }],
      selectedIndex: 'NDVI',
    };
  },
  computed: {
    ...mapState('registerFarm', [
      'parcelId',
      'parcelGisId',
      'farmStr',
      'parcelStr',
    ]),
    ...mapState('gis', [
      'timelineIndexes',
      'currentBoundsWMSLayer',
      'currentInitIntervalDates',
      'currentEndIntervalDates',
      'selectedIndex',
    ]),
  },
  async beforeMount() {
    this.setSelectedIndex(this.selectedIndex);

    this.setCurrentRoute([
      {
        name: this.$t('Agronomy'),
        disabled: false,
        page: 'AgronomySamplesPage',
        active: false,
      },
      {
        name: this.$t('advancedServices'),
        disabled: false,
        page: 'AdvancedServicesPage',
        active: false,
      },
      {
        name: this.farmStr,
        disabled: false,
        page: 'DashboardPage',
        active: false,
      },
      // {
      //   name: this.$t('teledetection'),
      //   disabled: true,
      //   page: 'TimeDimensionMapPage',
      //   active: true,
      // },
      {
        name: this.parcelStr,
        disabled: true,
        page: 'TimeDimensionMapPage',
        active: true,
      },
    ]);
  },
  beforeDestroy() {
    EventBus.$off('updateTimeLineIndexes');
  },
  async mounted() {
    try {
      this.loadedGIS = false;
      this.setDates();
      this.layer.options.layers = `${this.ws}:${this.parcelId}`;
      await this.boundsWMSLayer(`${this.ws}:${this.parcelGisId}`);
      this.bbox = {
        ...this.currentBoundsWMSLayer.coverage.latLonBoundingBox,
      };

      EventBus.$on(`updateTimeLineIndexes`, async () => {
        this.loadedTimeLine = false;
        await this.fetchTimelineIndexes({
          location: this.parcelId,
          initDate: moment(
            this.currentInitIntervalDates[this.mainMap]
          ).format('YYYY-MM-DD'),
          endDate: moment(
            this.currentEndIntervalDates[this.mainMap]
          ).format('YYYY-MM-DD'),
          index: this.selectedIndex,
        });
        this.loadedTimeLine = true;
      });
    } catch (e) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(e.message).error
            )
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    } finally {
      this.loadedGIS = true;
    }
  },
  methods: {
    onIndexChange(e) {
      this.selectedIndex = e.value;
    },
    goToComparative(e) {
      this.$f7router.navigate(
        {
          name: 'GisComparatorPage',
        },
        {
          animate: true,
          transition: 'f7-fade',
        }
      );
    },
    formatDate(a, b) {
      return format(a, b);
    },
    leave() {
      const timeDimension = document.getElementsByClassName(
        'timecontrol-play'
      )[0];

      if (timeDimension) {
        const timeDimensionClassList = timeDimension.classList;
        if (timeDimensionClassList.contains('pause')) {
          timeDimension.click();
        }
      }
    },
    setDates() {
      const today = new Date();
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
      this.setCurrentInitIntervalDates({
        key: this.mainMap,
        newCurrentInitIntervalDate: new Date(threeMonthsAgo),
      });
      this.setCurrentEndIntervalDates({
        key: this.mainMap,
        newCurrentEndIntervalDate: today,
      });
    },

    ...mapActions('breadcrumb', [
      'pushRoute',
      'replaceCurrent',
      'setCurrentRoute',
    ]),
    ...mapActions('gis', [
      'setCurrentIndexes',
      'setCurrentInitIntervalDates',
      'setCurrentEndIntervalDates',
      'fetchTimelineIndexes',
      'setTimelineIndexes',
      'boundsWMSLayer',
      'setSelectedIndex',
    ]),
  },
};
</script>
