var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-chart-weather-line-graph"},[_c('div',{staticClass:"content-devx no-padding"},[_c('f7-block',{staticClass:"content-linesgraph"},[_c('DxChart',{attrs:{"id":"chart","data-source":_vm.dataSource},on:{"legend-click":_vm.onLegendClick,"initialized":_vm.onInit}},[_c('DxCommonSeriesSettings',{attrs:{"argument-field":"date"}}),_vm._v(" "),_c('DxSeries',{attrs:{"pane":"top","color":"#36A2EB","value-field":`minmeasured${_vm.weatherElement}`,"name":`Min ${_vm.$t(_vm.weatherElement)}, ${_vm.measurement}`}}),_vm._v(" "),_c('DxSeries',{attrs:{"pane":"top","color":"#9c88ff","value-field":`avgmeasured${_vm.weatherElement}`,"name":`${_vm.$t('average')} ${_vm.$t(
            _vm.weatherElement
          )}, ${_vm.measurement}`}}),_vm._v(" "),_c('DxSeries',{attrs:{"color":"#FF6384","value-field":`maxmeasured${_vm.weatherElement}`,"name":`Max ${_vm.$t(_vm.weatherElement)}, ${_vm.measurement}`}}),_vm._v(" "),_c('DxSeries',{attrs:{"pane":"top","value-field":`minforecast${_vm.weatherElement}`,"type":"line","color":"#82ccdd","name":`${_vm.$t('minForecasted')} ${_vm.$t(
            _vm.weatherElement
          )}, ${_vm.measurement}`}}),_vm._v(" "),_c('DxSeries',{attrs:{"pane":"top","value-field":`avgforecast${_vm.weatherElement}`,"type":"line","color":"#1dd1a1","name":`${_vm.$t('averageForecasted')} ${_vm.$t(
            _vm.weatherElement
          )}, ${_vm.measurement}`}}),_vm._v(" "),_c('DxSeries',{attrs:{"pane":"top","value-field":`maxforecast${_vm.weatherElement}`,"type":"line","color":"#b71540","name":`${_vm.$t('maxForecasted')} ${_vm.$t(
            _vm.weatherElement
          )}, ${_vm.measurement}`}}),_vm._v(" "),_c('DxPane',{attrs:{"name":"top"}}),_vm._v(" "),_c('DxArgumentAxis',{attrs:{"discrete-axis-division-mode":"crossLabels","argument-type":"datetime","value-margins-enabled":false}},[_c('DxLabel',{attrs:{"display-mode":"stagger","format":"d MMM yyyy - hh:mm"}})],1),_vm._v(" "),_c('DxAdaptiveLayout',{attrs:{"width":80,"keep-labels":true}}),_vm._v(" "),_c('DxValueAxis',{attrs:{"pane":"top","axis-division-factor":20}},[_c('DxGrid',{attrs:{"visible":true}}),_vm._v(" "),_c('DxTitle',{attrs:{"text":`${_vm.$t(_vm.weatherElement)}, ${_vm.measurement}`}})],1),_vm._v(" "),_c('DxLegend',{attrs:{"margin":30,"vertical-alignment":"bottom","horizontal-alignment":"center","item-text-position":"top"}}),_vm._v(" "),_c('DxExport',{attrs:{"enabled":false}}),_vm._v(" "),_c('DxTooltip',{attrs:{"enabled":true,"z-index":999999,"customize-tooltip":_vm.customizeTooltip}}),_vm._v(" "),_c('DxTitle',{attrs:{"text":`${_vm.$t(_vm.weatherElement)}`}},[_c('DxFont',{attrs:{"family":"Roboto","size":"14"}})],1),_vm._v(" "),_c('DxScrollBar',{attrs:{"visible":true}}),_vm._v(" "),_c('DxZoomAndPan',{attrs:{"argument-axis":"both"}})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }