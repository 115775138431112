<template>
  <f7-page>
    <div v-if="loaded" class="tableContainer">
      <p>{{ $t('stations') }}</p>
      <GeoJsonTreeLocation
        :tree-locations="true"
        :enable-stations="false"
      />
    </div>
    <GoBack
      class="margin-top-half"
      :go-to-url-name="true"
      url-name="DashboardPage"
    />
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import GeoJsonTreeLocation from '@/components/Gis/GeoJsonTreeLocation/index.vue';
import GoBack from '@/components/UtilityComponents/GoBack/index.vue';
import turf from 'turf';
import { booleanPointInPolygon } from '@turf/turf';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  name: 'MIRStationsPage',
  components: {
    GeoJsonTreeLocation,
    GoBack,
  },
  data() {
    return {
      geometries: [],
      loaded: false,
      stations: [],
      points: [],
    };
  },
  computed: {
    ...mapState('registerFarm', ['farmStr', 'farm']),
    ...mapState('gisEditor', [
      'currentGeoFeature',
      'currentParcelGeoFeature',
      'tempParcelGeoFeatures',
    ]),
  },

  async beforeMount() {
    this.clearGeoJsonPoints();

    this.setCurrentRoute([
      {
        name: this.$t('Agronomy'),
        disabled: false,
        page: 'AgronomySamplesPage',
        active: false,
      },
      {
        name: this.$t('advancedServices'),
        disabled: false,
        page: 'AdvancedServicesPage',
        active: false,
      },
      {
        name: this.farmStr,
        disabled: false,
        page: 'DashboardPage',
        active: false,
      },
      {
        name: `${this.$t('probesTitle')} MIR`,
        disabled: true,
        page: 'MIRStationsPage',
        active: true,
      },
    ]);
  },
  async mounted() {
    this.tempParcelGeoFeatures.forEach((parcel) => {
      this.addParcelGeoFeature(parcel);
    });

    try {
      this.loaded = false;
      this.$f7.preloader.show();

      const res = await this.getStationsListFarm();

      res.data.forEach((stations) => {
        stations.stations.forEach((station) => {
          this.points.push({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [station.latitude, station.longitude],
            },
            properties: station.geo_json[0].features[0].properties,
          });
        });
      });

      this.currentParcelGeoFeature.forEach((parcel) => {
        this.points.forEach((point, index) => {
          const turfPoint = turf.point(point.geometry.coordinates);
          const turfParcel = turf.polygon(
            parcel.features[0].geometry.coordinates
          );
          if (booleanPointInPolygon(turfPoint, turfParcel)) {
            this.points[index].properties.parcel =
              parcel.features[0].properties.parcel_name;
          }
        });
      });

      this.points.forEach((point) => {
        this.setGeoJsonPoints(point);
      });
    } catch (e) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(e.message).error
            )
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },

  methods: {
    ...mapActions('breadcrumb', ['setCurrentRoute']),
    ...mapActions('gisEditor', ['addParcelGeoFeature']),
    ...mapActions('gis', ['setGeoJsonPoints', 'clearGeoJsonPoints']),
    ...mapActions('hidrosoph', ['getStationsListFarm']),
  },
};
</script>
