<template>
  <div class="margin-top">
    <div class="label-input">
      {{ $t('agronomySample.summary.samplingDate') }}
    </div>
    <div class="recepDate marginTop5">
      <f7-input
        class="shadow"
        type="datepicker"
        :placeholder="$t('agronomySample.chooseDates')"
        :clear-button="true"
        :close-on-select="true"
        :value="calculatedSamplingDate"
        :calendar-params="{
          closeOnSelect: true,
          rangePicker: true,
          dateFormat: {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
          },
        }"
        @calendar:change="setSamplingDate"
      />
    </div>

    <div class="margin-top-half">
      <div class="label-input">
        {{ $t('agronomySample.summary.estate') }}
      </div>

      <DxTagBox
        :data-source="farms"
        :multiline="true"
        :placeholder="$t('agronomySample.summary.estate')"
        tag-template="tag"
        :value="selectedFarms"
        :on-value-changed="farmsChanged"
        :on-opened="openedFarms"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
    <div class="margin-top-half">
      <div class="label-input">
        {{ $t('agronomySample.cultive') }}
      </div>

      <DxTagBox
        :data-source="crops"
        :multiline="true"
        :placeholder="$t('agronomySample.cultive')"
        tag-template="tag"
        :value="selectedCrops"
        :on-value-changed="cropsChanged"
        :on-opened="openedCrops"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
    <div class="margin-top-half">
      <div class="label-input">{{ $t('variety') }}</div>

      <DxTagBox
        :data-source="varieties"
        :multiline="true"
        :placeholder="$t('variety')"
        tag-template="tag"
        :value="selectedVarieties"
        :on-value-changed="varietiesChanged"
        :on-opened="openedVarieties"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { DxTagBox } from 'devextreme-vue/tag-box';

export default {
  components: { DxTagBox },
  data() {
    return {
      farms: [],
      crops: [],
      varieties: [],
      loaded: false,
    };
  },
  computed: {
    calculatedSamplingDate() {
      if (
        this.samplingToDate !== null &&
        this.samplingFromDate !== null
      ) {
        return [this.samplingFromDate, this.samplingToDate];
      }
      return [];
    },
    ...mapState('advancedServicesFilter', [
      'samplingFromDate',
      'samplingToDate',
      'selectedFarms',
      'selectedVarieties',
      'selectedCrops',
    ]),
  },
  methods: {
    async openedFarms(e) {
      const farms = await this.getAttributeList('farm');

      this.farms = farms;
      this.loaded = true;
    },
    async openedCrops(e) {
      const crops = await this.getAttributeList('cultive');

      this.crops = crops;
      this.loaded = true;
    },
    async openedVarieties(e) {
      const varieties = await this.getAttributeList('variety');

      this.varieties = varieties;
      this.loaded = true;
    },
    contentReady(e) {
      const arrayNotFoundItems = Array.from(
        document.getElementsByClassName('dx-empty-message')
      );

      arrayNotFoundItems.forEach((element) => {
        if (!this.loaded) {
          element.innerHTML = this.$t('loading');
        } else {
          element.innerHTML = this.$t('dataNotAvailable');
        }
      });

      this.loaded = false;
    },
    setSamplingDate(e) {
      if (e.length === 2) {
        this.setSamplingFromDate(e[0]);
        this.setSamplingToDate(e[1]);
      } else if (e.length === 0) {
        this.setSamplingFromDate(null);
        this.setSamplingToDate(null);
      }
    },
    farmsChanged(e) {
      this.setSelectedFarms(e.value);
    },
    cropsChanged(e) {
      this.setSelectedCrops(e.value);
    },
    varietiesChanged(e) {
      this.setSelectedVarieties(e.value);
    },
    ...mapActions('advancedServicesFilter', [
      'setSamplingFromDate',
      'setSamplingToDate',
      'setSelectedFarms',
      'setSelectedCrops',
      'setSelectedVarieties',
    ]),
    ...mapActions('advancedServices', ['getAttributeList']),
  },
};
</script>
