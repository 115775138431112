<template>
  <f7-page login-screen>
    <f7-block>
      <div class="text-align-center margin-bottom">
        <img class="img_logo" :src="APP_LOGO" :alt="APP_NAME" />
      </div>
      <div name="txtDataAccess" class="login__label">
        {{ $t('password_recovery') }}
      </div>
      <f7-list form class="no-margin-top">
        <f7-list-input
          outline
          item-label
          type="password"
          :placeholder="$t('password')"
          clear-button
          max="255"
          required
          autocomplete="current-password"
          validate
          :error-message="$t('completeThisField')"
          @input="password = $event.target.value"
        />

        <f7-list-input
          outline
          item-label
          type="password"
          :placeholder="$t('confirm') + ' ' + $t('password')"
          clear-button
          max="255"
          required
          autocomplete="current-password"
          validate
          :error-message="$t('completeThisField')"
          @input="confirmPassword = $event.target.value"
        />
      </f7-list>
      <!-- Buttons -->
      <f7-block>
        <f7-row>
          <f7-col>
            <f7-button
              name="btnGoBack"
              style="color: white"
              button
              button-large
              fill
              outline
              @click="goToLogin"
            >
              {{ $t('cancel') }}
            </f7-button>
          </f7-col>
          <f7-col>
            <f7-button
              name="recoverPasswordButton "
              class="botonPrimary"
              style="color: white"
              button
              button-large
              fill
              round
              @click="resetPassword"
            >
              {{ $t('request_reset_password') }}
            </f7-button>
          </f7-col>
        </f7-row>
      </f7-block>
    </f7-block>
  </f7-page>
</template>

<script>
import { mapActions } from 'vuex';
import {
  APP_NAME,
  APP_LOGO,
  NOTIFY_TIME_LONG,
} from '../../../js/constants';

export default {
  data() {
    return {
      APP_NAME,
      APP_LOGO,
      password: '',
      confirmPassword: '',
      token: null,
    };
  },
  mounted() {
    // dispatch al event loop
    setTimeout(() => {
      const { token } = this.$f7.views.main.router.currentRoute.query;
      this.token = token;
    }, 0);
  },
  methods: {
    goToLogin() {
      setTimeout(() => {
        window.history.pushState(
          {},
          document.title,
          window.location.pathname
        );
      }, 0);
      this.setShowTokenPage(false);
    },
    async resetPassword() {
      if (this.validatePassword()) {
        try {
          await this.confirmChangePassword({
            password: this.password,
            token: this.token,
          });

          this.setShowTokenPage(false);

          setTimeout(() => {
            window.history.pushState(
              {},
              document.title,
              window.location.pathname
            );
          }, 0);

          this.$notifyDX(
            {
              message: this.$t('password_updated'),
              width: 450,
            },
            'success',
            3000
          );
        } catch (error) {
          setTimeout(() => {
            window.history.pushState(
              {},
              document.title,
              window.location.pathname
            );
          }, 0);

          this.setShowTokenPage(false);
          this.$notifyDX(
            {
              message: this.$t('invalidToken'),
              width: 450,
            },
            'error',
            NOTIFY_TIME_LONG
          );
        }
      } else {
        this.$notifyDX(
          {
            message: this.$t('new_password_not_match'),
            width: 450,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      }
    },
    validatePassword() {
      if (
        this.password.trim() === this.confirmPassword.trim() &&
        this.password.trim() !== '' &&
        this.confirmPassword.trim() !== ''
      ) {
        return true;
      }

      return false;
    },
    ...mapActions('user', [
      'setShowTokenPage',
      'confirmChangePassword',
    ]),
  },
};
</script>

<style scoped>
.txt-data-access {
  text-align: center;
  font-weight: 300;
  font-size: 20px;
  line-height: 33px;
  color: #333;
}
.img_logo {
  width: 200px;
  height: auto;
}
</style>
