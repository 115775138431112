import Api from '@/services/Api';

export default {
  namespaced: true,
  state: {
    // NutritionalMonitoringData: {},
    NutritionalGraphicData: {},
    parcelId: '',
    showPopUpPhitomonitoring: false,
    samplingDate: null,
    lastRouteGeotoolbox: false,
    varietyId: '',
    cultiveId: '',
    samplingDateProfile: '',
    id: '',
  },
  actions: {
    async getAttributeList({ rootState }, attribute) {
      try {
        const xhr =
          await Api.getNutritionalMonitoringWGRAttributeList(
            attribute,
            rootState.contact.company,
            rootState.contact.address
          );

        const response = JSON.parse(xhr.response);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getNutritionalProfile({ rootState }, queryParams) {
      try {
        const xhr = await Api.getElementsByQueryParams(
          rootState.contact.company,
          rootState.contact.address,
          'nutritional_profile/',
          queryParams
        );

        const response = JSON.parse(xhr.response);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getNutritionalProfileEvaluation({ rootState }, data) {
      try {
        const xhr = await Api.getNutritionalProfileEvaluation(
          rootState.contact.company,
          rootState.contact.address,
          data.id,
          data.params
        );

        const response = JSON.parse(xhr.response);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getDetalledNutritionalMonitoring(
      { rootState, commit },
      params
    ) {
      try {
        const xhr = await Api.getElementsByQueryParams(
          rootState.contact.company,
          rootState.contact.address,
          `nutritional_profile/${rootState.nutritionalMonitoring.id}/get_detalled_nutritional_monitoring/`,
          params,
        );
        const response = JSON.parse(xhr.response);
        for (const key in response) {
          if (Object.hasOwnProperty.call(response, key)) {
            const nutritionalProfile = response[key];
            if (nutritionalProfile.sampling_date) {
              commit('setSamplingDate', nutritionalProfile.sampling_date);
            }
          }
        }
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getNutritionalGraphicData({ rootState, commit }, params) {
      try {
        const xhr = await Api.getNutritionalGraphicData(
          params,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        commit('setNutritionalGraphicData', response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getSamplesWithParameterByFarmAndParcel(
      { rootState },
      data
    ) {
      try {
        const xhr = await Api.getSamplesWithParameterByFarmAndParcel(
          rootState.contact.company,
          rootState.contact.address,
          { parcelId: data.parcel_id, farmId: data.farm_id }
        );

        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    setLastRouteGeotoolbox({ commit }, value) {
      commit('setLastRouteGeotoolbox', value);
    },
    togglePopUpPhitomonitoring({ commit }, value) {
      commit('setPopUpPhitomonitoring', value);
    },
    cleanData({ commit }) {
      commit('cleanData');
    },
    cleanDataChart({ commit }) {
      commit('cleanDataChart');
    },
    setParcelId({ commit }, value) {
      commit('setParcelId', value);
    },
    setVarietyId({ commit }, value) {
      commit('setVarietyId', value);
    },
    setCultiveId({ commit }, value) {
      commit('setCultiveId', value);
    },
    setSamplingDateProfile({ commit }, value) {
      commit('setSamplingDateProfile', value);
    },
    setId({ commit }, value) {
      commit('setId', value);
    },
  },
  mutations: {
    cleanData(state) {
      state.NutritionalMonitoringData = {};
    },
    cleanDataChart(state) {
      state.NutritionalGraphicData = {};
    },
    setNutritionalMonitoringData(state, { type, data }) {
      state.NutritionalMonitoringData[type] = data[type];
    },
    setNutritionalGraphicData(state, data) {
      state.NutritionalGraphicData = data;
    },
    setPopUpPhitomonitoring(state, value) {
      state.showPopUpPhitomonitoring = value;
    },
    setParcelId(state, value) {
      state.parcelId = value;
    },
    setVarietyId(state, value) {
      state.varietyId = value;
    },
    setCultiveId(state, value) {
      state.cultiveId = value;
    },
    setSamplingDateProfile(state, value) {
      state.samplingDateProfile = value;
    },
    setLastRouteGeotoolbox(state, value) {
      state.lastRouteGeotoolbox = value;
    },
    setSamplingDate(state, value) {
      if (value == null) {
        return;
      }

      if (
        state.samplingDate !== null &&
        new Date(value) > new Date(state.samplingDate)
      ) {
        return;
      }

      state.samplingDate = value;
    },
    setId(state, value) {
      state.id = value;
    },
  },
};
