<template>
  <div class="margin-top">
    <div>
      <div class="label-input">
        {{ $t('agronomySample.summary.estate') }}
      </div>

      <DxTagBox
        :data-source="farms"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('agronomySample.summary.estate')"
        tag-template="tag"
        :value="selectedFarms"
        :on-value-changed="farmsChanged"
        :on-opened="openedFarms"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
    <div class="margin-top-half">
      <div class="label-input">
        {{ $t('agronomySample.summary.parcel') }}
      </div>

      <DxTagBox
        :data-source="parcels"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('agronomySample.summary.parcel')"
        tag-template="tag"
        :value="selectedParcels"
        :on-value-changed="parcelsChanged"
        :on-opened="openedParcels"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>

    <div class="margin-top-half">
      <div class="label-input">
        {{ $t('agronomySample.cultive') }}
      </div>

      <DxTagBox
        :data-source="cultives"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('agronomySample.cultive')"
        tag-template="tag"
        :value="selectedCultives"
        :on-value-changed="cultivesChanged"
        :on-opened="openedCultives"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>

    <div class="margin-top-half">
      <div class="label-input">{{ $t('variety') }}</div>

      <DxTagBox
        :data-source="varieties"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('variety')"
        tag-template="tag"
        :value="selectedVarieties"
        :on-value-changed="varietiesChanged"
        :on-opened="openedVarieties"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { DxTagBox } from 'devextreme-vue/tag-box';

export default {
  components: { DxTagBox },
  data() {
    return {
      farms: [],
      parcels: [],
      cultives: [],
      varieties: [],
      loaded: false,
    };
  },
  computed: {
    ...mapState('gisComparatorFilter', [
      'selectedFarms',
      'selectedParcels',
      'selectedCultives',
      'selectedVarieties',
    ]),
  },
  methods: {
    async openedSampleTypes(e) {
      const sampleTypes = await this.getSampleFilter({
        atc: 'Agronomía',
        attribute: 'sample_type',
      });

      this.sampleTypes = sampleTypes.sort();
      this.loaded = true;
    },
    analyseTypesChanged(e) {
      this.setSelectedAnalyseTypes(e.value);
    },
    async openedFarms(e) {
      const farms = await this.getGisAttributeList('farm');

      this.farms = farms.sort();
      this.loaded = true;
    },
    async openedParcels(e) {
      const parcels = await this.getGisAttributeList('parcel');
      this.parcels = parcels.sort();
      this.loaded = true;
    },

    async openedCultives(e) {
      const cultives = await this.getGisAttributeList('cultive');

      this.cultives = cultives.sort();
      this.loaded = true;
    },
    async openedVarieties(e) {
      const varieties = await this.getGisAttributeList('variety');

      this.varieties = varieties;
      this.loaded = true;
    },
    contentReady(e) {
      const arrayNotFoundItems = Array.from(
        document.getElementsByClassName('dx-empty-message')
      );

      arrayNotFoundItems.forEach((element) => {
        if (!this.loaded) {
          element.innerHTML = this.$t('loading');
        } else {
          element.innerHTML = this.$t('dataNotAvailable');
        }
      });

      this.loaded = false;
    },

    farmsChanged(e) {
      this.setSelectedFarms(e.value);
    },
    cultivesChanged(e) {
      this.setSelectedCultives(e.value);
    },
    parcelsChanged(e) {
      this.setSelectedParcels(e.value);
    },
    varietiesChanged(e) {
      this.setSelectedVarieties(e.value);
    },
    ...mapActions('gisComparatorFilter', [
      'setSelectedFarms',
      'setSelectedCultives',
      'setSelectedParcels',
      'setSelectedVarieties',
    ]),
    ...mapActions('gisComparator', ['getGisAttributeList']),
  },
};
</script>
