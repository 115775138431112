<template>
  <div>
    <DxChart id="chart" :data-source="ds">
      <DxCommonSeriesSettings
        argument-field="month"
        type="stackedbar"
      />
      <DxValueAxis position="left">
        <DxTitle :text="$t('stats.numSamples')" />
      </DxValueAxis>

      <DxSeries color="#FF6384" value-field="n_lmrs" name=" > LMR" />
      <DxSeries
        color="#FFCE56"
        value-field="n_positives"
        :name="$t('positives')"
      />
      <DxSeries
        color="#ffeda0"
        value-field="n_samples"
        :name="$t('samples')"
      />

      <DxLegend
        vertical-alignment="bottom"
        horizontal-alignment="center"
        item-text-position="top"
      />

      <DxExport :enabled="true" />
      <DxTooltip
        :z-index="999999"
        :enabled="true"
        location="edge"
        :customize-tooltip="customizeTooltip"
      />
    </DxChart>
  </div>
</template>

<script>
import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxValueAxis,
  DxTitle,
  DxLegend,
  DxExport,
  DxTooltip,
} from 'devextreme-vue/chart';
import { mapState } from 'vuex';

export default {
  components: {
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxValueAxis,
    DxTitle,
    DxLegend,
    DxExport,
    DxTooltip,
  },
  computed: {
    ds() {
      const data = [];
      this.aggregatedData.map((el) => {
        const formattedData = el.stats.map((monthData) => {
          return {
            ...monthData.current,
            month: `${new Date(
              monthData.current.year,
              monthData.month === null
                ? 'Invalid'
                : monthData.month - 1,
              1
            )
              .toLocaleString(this.languageInterface, {
                month: 'long',
              })
              .capitalize()} ${monthData.current.year}`,
            sample_type: el.sample_type,
            n_samples_original: monthData.current.n_samples,
            n_samples:
              monthData.current.n_samples -
              monthData.current.n_positives,
            n_positives_original: monthData.current.n_positives,
            n_positives:
              monthData.current.n_positives -
              monthData.current.n_lmrs,
            sample_type_id: el.sample_type_id,
          };
        });

        data.push(
          ...formattedData.filter((d) => !d.month.includes('Invalid'))
        );
      });

      return data;
    },
    ...mapState('user', ['languageInterface']),
    ...mapState('foodStats', ['aggregatedData']),
  },
  methods: {
    customizeTooltip(pointInfo) {
      const isSamples = pointInfo.point._options.valueField
        .toLowerCase()
        .includes('n_samples');

      const isPositives = pointInfo.point._options.valueField
        .toLowerCase()
        .includes('n_positives');

      if (isSamples) {
        return {
          html: `<p>${this.$t('samples')}: ${
            pointInfo.point.data.n_samples_original
          }</p>`,
        };
      }
      if (isPositives) {
        return {
          html: `<p>${this.$t('positives')}: ${
            pointInfo.point.data.n_positives_original
          }</p>`,
        };
      }
      return {
        html: `> LMR: ${pointInfo.point.data.n_lmrs}</p>`,
      };
    },
  },
};
</script>
