<template>
  <div v-if="showBanner" class="consent-container">
    <div class="consent-banner">
      <div v-if="!showConfig" class="consent-banner-content">
        <div class="consent-text">
          <div class="consent-text-title">
            {{ $t('cookiesBanner.title') }}
          </div>
          {{ $t('cookiesBanner.message') }}
          <span class="consent-learn-more" @click="goToLearnMore">
            {{ $t('cookiesBanner.learnMore') + ' →' }}
          </span>
        </div>
        <div class="consent-buttons">
          <button
            type="button"
            class="consent-button consent-button-config"
            @click="showConfig = true"
          >
            {{ $t('cookiesBanner.config') }}
          </button>
          <button
            type="button"
            class="consent-button consent-button-cancel"
            @click="doNotAccept"
          >
            {{ $t('cookiesBanner.resign') }}
          </button>
          <button
            type="button"
            class="consent-button consent-button-accept"
            @click="accept"
          >
            {{ $t('cookiesBanner.accept') }}
          </button>
        </div>
      </div>
      <div v-else>
        <div class="consent-text">
          <div class="consent-text-title margin-bottom-half">
            {{ $t('cookiesBanner.preferences') }}
          </div>
          <div class="cookies-preference-option">
            <DxCheckBox
              v-model="enabledGoogleAnalytics"
              :value="enabledGoogleAnalytics"
              :icon-size="18"
            />
            <span>Google Analytics</span>
          </div>
        </div>
        <div class="consent-buttons">
          <button
            type="button"
            class="consent-button consent-button-cancel"
            @click="showConfig = false"
          >
            {{ $t('goBack') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { DxCheckBox } from 'devextreme-vue/check-box';

export default {
  name: 'ConsentGDPR',
  components: {
    DxCheckBox,
  },
  data() {
    return {
      disableStr: 'ga-disable-G-FSF6PYKPGD',
      showBanner: localStorage['consent-gdpr'] !== 'true',
      showConfig: false,
      enabledGoogleAnalytics: true,
    };
  },
  methods: {
    ...mapActions('user', ['setShowPrivacyPolicy']),
    goToLearnMore() {
      window.open(
        'https://hispatec.com/politica-privacidad/',
        '_blank'
      );
    },
    accept() {
      if (!this.enabledGoogleAnalytics) {
        this.doNotAccept();
        return;
      }

      document.cookie = `${this.disableStr}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
      window[this.disableStr] = false;
      localStorage['consent-gdpr'] = true;

      this.showBanner = false;

      window.location.reload();
    },
    doNotAccept() {
      document.cookie = `${this.disableStr}=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/`;
      window[this.disableStr] = true;
      localStorage['consent-gdpr'] = true;

      this.showBanner = false;

      window.location.reload();
    },
  },
};
</script>

<style>
.consent-container {
  max-width: 80rem;

  margin-left: auto;
  margin-right: auto;

  padding: 0 2rem;

  position: absolute;
  bottom: 1rem;
  z-index: 99999;
  left: 0;
  right: 0;
}

.consent-text {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
}

.consent-text-title {
  font-weight: 600;
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
}

.consent-banner-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

@media (max-width: 800px) {
  .consent-banner-content {
    display: block;
  }

  .consent-text {
    padding-bottom: 0.8rem;
  }
}

.consent-banner {
  background: var(--f7-theme-color);
  color: white;
  padding: 1rem;

  border-radius: 8px;
}

.back-to-main-text {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;

  padding: 0;
  border: none;
  outline: none;
  background: none;
  color: white;

  font-weight: 600;
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
}

.consent-learn-more {
  color: #fff !important;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
}

.cookies-preference-option {
  padding: 0.2rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
}

.consent-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.3rem;
}

.consent-button {
  border-radius: 0.375rem;
  padding: 0.6rem 1rem;
  color: white;
  border: none;
  outline: none;
  width: max-content;

  color: var(--f7-theme-color);
  font-weight: 600;
  cursor: pointer;
}

.consent-button-cancel {
  background: #fff;
  color: #000;
}

.consent-button-cancel:hover {
  background: #e5e7eb;
}

.consent-button-config {
  background: var(--f7-theme-color-shade);
  color: white;
}

.consent-button-config:hover {
  background: var(--f7-theme-color-tint);
}

.consent-button-accept {
  background: #fff;
  color: #000;
}

.consent-button-accept:hover {
  background: #e5e7eb;
}
</style>
