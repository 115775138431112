<template>
  <div>
    <div class="tableContainer">
      <DxDataGrid
        :data-source="store"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :allow-column-auto-width="true"
        :row-alternation-enabled="true"
        :show-borders="true"
        :show-row-lines="true"
        :no-data-text="$t('noDataTextSummaryOffers')"
        @toolbar-preparing="onToolbarPreparing"
        @contentReady="onInit"
      >
        <DxColumn
          :caption="this.$t('article')"
          data-field="Articulo"
        />
        <DxColumn
          alignment="center"
          :caption="this.$t('agronomySample.summary.sampleType')"
          data-field="SubFamiliaTM"
        />
        <DxColumn
          alignment="center"
          :caption="this.$t('netAmount')"
          data-field="PrecioBrutoCodDivisa"
        />
        <DxColumn
          :buttons="updatedEditButtons"
          :fixed="true"
          fixed-position="right"
          type="buttons"
          width="80"
        />
        <DxSearchPanel
          :visible="true"
          :highlight-case-sensitive="true"
          :placeholder="$t('search')"
        />

        <DxPager
          :allowed-page-sizes="[10, 25, 50, 100]"
          :show-page-size-selector="true"
          info-text="{2} items"
          :visible="true"
        />
        <DxPaging :page-size="5" />
      </DxDataGrid>

      <showDetailsPopUp
        v-if="showDetailsPopup"
        :store="detallesLineas"
      />
    </div>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxPager,
  DxPaging,
  DxSearchPanel,
} from 'devextreme-vue/data-grid';
import { mapActions, mapState } from 'vuex';
import showDetailsPopUp from '@/components/OffersList/showDetailsPopUp/index.vue';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxPager,
    DxPaging,
    DxSearchPanel,
    showDetailsPopUp,
  },
  props: {
    store: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      pageSizes: [5, 10, 15],
      editButtons: [
        {
          icon: 'find',
          onClick: this.viewAtc,
        },
      ],
      CodArticulo: '',
      IdCentroProduccionSeleccionado: '',
      IdFamilia: '',
      IdSubFamilia: '',
      isQCA: false,
    };
  },
  computed: {
    ...mapState('Offers', ['showDetailsPopup', 'detallesLineas']),
    updatedEditButtons() {
      return [
        {
          icon: 'find',
          onClick: this.viewAtc,
        },
      ];
    },
  },
  methods: {
    onInit(e) {
      this.grid = e;
      Array.from(
        document.getElementsByClassName('dx-group-cell')
      ).forEach((el) => {
        el.innerHTML = el.innerHTML.split(':')[1].trim();
      });
    },
    viewAtc(e) {
      this.setPopupTitle(e.row.data.Articulo);
      this.setShowDetailsPopup(true);
      this.CodArticulo = e.row.data.CodArticulo.replace(/\D/g, '');
      this.IdCentroProduccionSeleccionado =
        e.row.data.IdCentroProduccionSeleccionado;
      this.IdFamilia = e.row.data.IdFamilia;
      this.IdSubFamilia = e.row.data.IdSubFamilia;
      this.fetchDetallesLineas({
        idAnalisisTipo: this.CodArticulo,
        idEmpresa: this.IdCentroProduccionSeleccionado,
        idFamiliaTM: this.IdFamilia,
        idSubFamiliaTM: this.IdSubFamilia,
        idAgrupacionFamiliaTM: 0,
      });
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items;
      toolbarItems.forEach((item) => {
        if (item.name === 'searchPanel') {
          item.location = 'before';
        }
      });
    },
    ...mapActions('Offers', [
      'setShowDetailsPopup',
      'setPopupTitle',
      'fetchDetallesLineas',
    ]),
  },
};
</script>
