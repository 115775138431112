<template>
  <div>
    <f7-input
      type="datepicker"
      class="recepDate padding-horizontal"
      :placeholder="$t('agronomySample.chooseDates')"
      :clear-button="true"
      :close-on-select="true"
      :calendar-params="{
        value: [
          currentInitIntervalDates[key],
          currentEndIntervalDates[key],
        ],
        closeOnSelect: true,
        rangePicker: true,
        dateFormat: {
          month: 'numeric',
          day: 'numeric',
          year: 'numeric',
        },
      }"
      @calendar:change="calendarChange"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import format from 'date-fns/format';
import EventBus from '@/services/event-bus';

export default {
  name: 'GisTimeDimensionDatePicker',
  data() {
    return {
      key: 'GisTimeDimension',
    };
  },
  computed: {
    ...mapState('gis', ['currentInitIntervalDates']),
    ...mapState('gis', ['currentEndIntervalDates']),
  },
  methods: {
    formatDate(a, b) {
      return format(a, b);
    },
    calendarChange(e) {
      if (e.length === 2) {
        this.setCurrentInitIntervalDates({
          key: this.key,
          newCurrentInitIntervalDate: e[0],
        });
        this.setCurrentEndIntervalDates({
          key: this.key,
          newCurrentEndIntervalDate: e[1],
        });
        EventBus.$emit(`updateTimeDimension${this.key}`);
        setTimeout(() => {
          EventBus.$emit('updateTimeLineIndexes');
        }, 0);
      } else if (e.length === 0) {
        const today = new Date();
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
        this.setCurrentInitIntervalDates({
          key: this.key,
          newCurrentInitIntervalDate: new Date(threeMonthsAgo),
        });
        this.setCurrentEndIntervalDates({
          key: this.key,
          newCurrentEndIntervalDate: today,
        });
        EventBus.$emit(`updateTimeDimension${this.key}`);
        setTimeout(() => {
          EventBus.$emit(`updateTimeLineIndexes`);
        }, 0);
      }
    },
    ...mapActions('gis', [
      'setCurrentInitIntervalDates',
      'setCurrentEndIntervalDates',
    ]),
  },
};
</script>

<style lang="scss" scoped></style>
