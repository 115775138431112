<template>
  <div>
    <div class="filterButtonPosition" style="right: 64px">
      <f7-button
        id="panel-right-stats-btn"
        fill
        class="whiteFontColor button-filter"
        panel-toggle="right"
      >
        <span class="ic-filter"></span> {{ $t('filter') }}
      </f7-button>
    </div>
    <DxDataGrid
      :data-source="aggregatedData"
      :show-borders="true"
      :allow-column-reordering="false"
      :column-auto-width="true"
      :show-colon="false"
      :remote-operations="false"
      :hover-state-enabled="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @initialized="init"
      @toolbar-preparing="onToolbarPreparing"
      @exporting="onExporting"
      @contentReady="contentReady"
    >
      <DxScrolling mode="standard" />

      <DxStateStoring :enabled="false" type="localStorage" />

      <DxPaging :page-size="20" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="[5, 10, 20]"
        :show-info="true"
        info-text="{2} items"
        :visible="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="300"
        :placeholder="$t('search')"
      />

      <DxExport :enabled="true" />

      <DxGrouping />
      <DxGroupPanel :visible="false" />

      <DxColumn
        :caption="$t('level')"
        cell-template="sampleDate"
        data-field="level"
      />

      <template #sampleDate="{ data }">
        <div>
          <div v-if="data.data.parameter">
            {{ data.data.parameter }}
          </div>
          <div v-else-if="data.data.month">
            {{
              new Date(data.data.year, data.data.month - 1, 1)
                .toLocaleString(languageInterface, {
                  month: 'long',
                })
                .capitalize() +
              ' ' +
              data.data.year
            }}
          </div>
          <div v-else>
            <strong>{{ $t('stats.total') }}</strong>
          </div>
        </div>
      </template>

      <DxColumn
        v-if="!isComparative || isGlobalAGQ"
        :caption="$t('samples')"
        alignment="left"
        cell-template="nrSamples"
        data-field="nrSamples"
        name="nrSamplesSolo"
      />

      <DxColumn
        :visible="isComparative && !isGlobalAGQ"
        :caption="$t('samples')"
        alignment="left"
        data-field="nrSamplesMain"
      >
        <DxColumn
          cell-template="nrSamples"
          :caption="$t('stats.currentPeriod')"
          data-field="nrSamples"
        />
        <DxColumn
          cell-template="nrSamplesComparative"
          :caption="$t('stats.previousPeriod')"
          data-field="nrSamplesComparative"
        />
      </DxColumn>

      <template #nrSamplesComparative="{ data }">
        <div
          style="
            color: #5243aa;
            font-weight: bold;
            text-align: center;
          "
        >
          <div v-if="data.data.month || data.data.parameter">
            {{ data.data.historic.n_samples }}
          </div>
          <div v-else>
            <strong> {{ data.data.historic.n_samples }}</strong>
          </div>
        </div>
      </template>

      <template #nrSamples="{ data }">
        <div style="text-align: center">
          <div v-if="data.data.month || data.data.parameter">
            {{ data.data.n_samples }}
          </div>
          <div v-else>
            <strong>{{ data.data.n_samples }}</strong>
          </div>
        </div>
      </template>

      <DxColumn
        v-if="!isComparative || isGlobalAGQ"
        :caption="$t('stats.positiveSamples')"
        alignment="left"
        cell-template="nrPositives"
        data-field="nrPositives"
        name="nrPositivesSolo"
      />

      <DxColumn
        :visible="isComparative && !isGlobalAGQ"
        :caption="$t('stats.positiveSamples')"
        alignment="left"
        data-field="nrPositivesMain"
      >
        <DxColumn
          cell-template="nrPositives"
          :caption="$t('stats.currentPeriod')"
          data-field="nrPositives"
        />
        <DxColumn
          cell-template="nrPositivesComparative"
          :caption="$t('stats.previousPeriod')"
          data-field="nrPositivesComparative"
        />
      </DxColumn>

      <template #nrPositives="{ data }">
        <div style="text-align: center">
          <div v-if="data.data.month || data.data.parameter">
            {{ data.data.n_positives }}
          </div>
          <div v-else>
            <strong>{{ data.data.n_positives }}</strong>
          </div>
        </div>
      </template>

      <template #nrPositivesComparative="{ data }">
        <div
          style="
            color: #5243aa;
            font-weight: bold;
            text-align: center;
          "
        >
          <div v-if="data.data.month || data.data.parameter">
            {{ data.data.historic.n_positives }}
          </div>
          <div v-else>
            <strong> {{ data.data.historic.n_positives }}</strong>
          </div>
        </div>
      </template>

      <DxColumn
        :visible="!isComparative"
        :caption="' % ' + $t('samples') + ' ' + $t('positivesRatio')"
        alignment="left"
        cell-template="calculatePositivesTotalSamples"
        data-field="positivesTotalSamples"
        name="positivesTotalSamplesSolo"
      />

      <DxColumn
        :visible="isComparative || isGlobalAGQ"
        :caption="' % ' + $t('samples') + ' ' + $t('positivesRatio')"
        alignment="left"
        data-field="positivesTotalSamplesMain"
      >
        <DxColumn
          cell-template="calculatePositivesTotalSamples"
          :caption="$t('stats.currentPeriod')"
          data-field="positivesTotalSamples"
        />
        <DxColumn
          cell-template="calculatePositivesTotalSamplesComparative"
          :caption="
            isGlobalAGQ
              ? $t('historicAGQ')
              : $t('stats.previousPeriod')
          "
          data-field="positivesTotalSamplesComparative"
        />
      </DxColumn>

      <template #calculatePositivesTotalSamples="{ data }">
        <div style="text-align: center">
          <div v-if="data.data.month || data.data.parameter">
            {{ data.data.per_positives.toFixed(2) }}
          </div>
          <div v-else>
            <strong>{{ data.data.per_positives.toFixed(2) }}</strong>
          </div>
        </div>
      </template>

      <template #calculatePositivesTotalSamplesComparative="{ data }">
        <div
          style="
            color: #5243aa;
            font-weight: bold;
            text-align: center;
          "
        >
          <div v-if="data.data.month || data.data.parameter">
            {{ data.data.historic.per_positives.toFixed(2) }}
          </div>
          <div v-else>
            <strong>{{
              data.data.historic.per_positives.toFixed(2)
            }}</strong>
          </div>
        </div>
      </template>

      <DxColumn
        :visible="!isComparative || isGlobalAGQ"
        :caption="
          $t('samples') +
          ' ' +
          $t('positivesRatio') +
          '> ' +
          $t('foodSamples.lmr')
        "
        alignment="left"
        cell-template="superiorLmrs"
        data-field="superiorLmrs"
        name="superiorLmrsSolo"
      />

      <DxColumn
        :visible="isComparative && !isGlobalAGQ"
        :caption="
          $t('samples') +
          ' ' +
          $t('positivesRatio') +
          '> ' +
          $t('foodSamples.lmr')
        "
        alignment="left"
        data-field="superiorLmrsMain"
      >
        <DxColumn
          cell-template="superiorLmrs"
          :caption="$t('stats.currentPeriod')"
          data-field="superiorLmrs"
        />
        <DxColumn
          cell-template="superiorLmrsComparative"
          :caption="$t('stats.previousPeriod')"
          data-field="superiorLmrsComparative"
        />
      </DxColumn>

      <template #superiorLmrs="{ data }">
        <div style="text-align: center">
          <div v-if="data.data.month || data.data.parameter">
            {{ data.data.n_lmrs }}
          </div>
          <div v-else>
            <strong>{{ data.data.n_lmrs }}</strong>
          </div>
        </div>
      </template>

      <template #superiorLmrsComparative="{ data }">
        <div
          style="
            color: #5243aa;
            font-weight: bold;
            text-align: center;
          "
        >
          <div
            v-if="
              data.data.historic.month || data.data.historic.parameter
            "
          >
            {{ data.data.historic.n_lmrs }}
          </div>
          <div v-else>
            <strong>{{ data.data.historic.n_lmrs }}</strong>
          </div>
        </div>
      </template>

      <DxColumn
        v-if="!isComparative && !isGlobalAGQ"
        :caption="'% ' + $t('badSamples')"
        alignment="left"
        cell-template="calculateLmrTotal"
        data-field="lmrTotal"
        name="lmrTotalSolo"
      />

      <DxColumn
        :visible="isComparative || isGlobalAGQ"
        :caption="'% ' + $t('badSamples')"
        alignment="left"
        data-field="lmrTotalMain"
      >
        <DxColumn
          cell-template="calculateLmrTotal"
          :caption="$t('stats.currentPeriod')"
          data-field="lmrTotal"
        />
        <DxColumn
          cell-template="calculateLmrTotalComparative"
          :caption="
            isGlobalAGQ
              ? $t('historicAGQ')
              : $t('stats.previousPeriod')
          "
          data-field="lmrTotalComparative"
        />
      </DxColumn>

      <template #calculateLmrTotal="{ data }">
        <div style="text-align: center">
          <div v-if="data.data.month || data.data.parameter">
            {{ data.data.per_lmrs.toFixed(2) }}
          </div>
          <div v-else>
            <strong>{{ data.data.per_lmrs.toFixed(2) }}</strong>
          </div>
        </div>
      </template>

      <template #calculateLmrTotalComparative="{ data }">
        <div
          style="
            color: #5243aa;
            font-weight: bold;
            text-align: center;
          "
        >
          <div
            v-if="
              data.data.historic.month || data.data.historic.parameter
            "
          >
            {{ data.data.historic.per_lmrs.toFixed(2) }}
          </div>
          <div v-else>
            <strong>{{
              data.data.historic.per_lmrs.toFixed(2)
            }}</strong>
          </div>
        </div>
      </template>

      <DxColumn
        :visible="!isComparative || isGlobalAGQ"
        :caption="$t('withTraces')"
        alignment="left"
        data-field="n_traces"
        cell-template="nrTraces"
        name="nrTracesSolo"
      />

      <DxColumn
        :visible="isComparative && !isGlobalAGQ"
        :caption="$t('withTraces')"
        alignment="left"
      >
        <DxColumn
          cell-template="nrTraces"
          :caption="$t('stats.currentPeriod')"
          data-field="n_traces"
        />
        <DxColumn
          cell-template="nrTracesComparatives"
          :caption="$t('stats.previousPeriod')"
          data-field="n_tracesComparative"
        />
      </DxColumn>

      <template #nrTraces="{ data }">
        <div style="text-align: center">
          <div v-if="data.data.month || data.data.parameter">
            {{ data.data.n_traces }}
          </div>
          <div v-else>
            <strong>{{ data.data.n_traces }}</strong>
          </div>
        </div>
      </template>

      <template #nrTracesComparatives="{ data }">
        <div
          style="
            color: #5243aa;
            font-weight: bold;
            text-align: center;
          "
        >
          <div
            v-if="
              data.data.historic.month || data.data.historic.parameter
            "
          >
            {{ data.data.historic.n_traces }}
          </div>
          <div v-else>
            <strong>{{ data.data.historic.n_traces }}</strong>
          </div>
        </div>
      </template>

      <DxColumn
        :caption="''"
        :group-index="0"
        data-field="sample_type"
        :allow-grouping="true"
      />
    </DxDataGrid>
  </div>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxStateStoring,
  DxPager,
  DxPaging,
  DxGrouping,
  DxGroupPanel,
  DxScrolling,
  DxExport,
} from 'devextreme-vue/data-grid';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxStateStoring,
    DxPager,
    DxPaging,
    DxGrouping,
    DxGroupPanel,
    DxScrolling,
    DxExport,
  },
  props: {
    aggregatedData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dataGrid: null,
    };
  },
  computed: {
    ...mapState('foodStats', [
      'isParameters',
      'beforeDates',
      'afterDates',
    ]),
    ...mapState('user', ['languageInterface']),
    ...mapState('foodStatsFilter', ['isComparative', 'isGlobalAGQ']),
    ...mapState('samples', ['reportSampleCode']),
  },
  beforeMount() {
    this.setCurrentFilter('foodStats');
  },
  methods: {
    contentReady(e) {
      Array.from(
        document.getElementsByClassName('dx-group-cell')
      ).forEach((el) => {
        el.innerHTML = el.innerHTML.split(':')[1].trim();
      });
    },
    init(e) {
      this.dataGrid = e.component;
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items;

      toolbarItems.forEach((item) => {
        if (item.name === 'searchPanel') {
          item.location = 'before';
        }
        if (item.name === 'exportButton') {
          item.location = 'after';
        }

        if (item.name === 'groupPanel') {
          item.location = 'after';
        }
      });
    },
    onExporting(e) {
      e.component.beginUpdate();
      e.component.columnOption('ID', 'visible', true);
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(
        `${`${this.$t(
          'agronomySample.summary.sampleSummary'
        )}-${this.$t('Agronomy')}`}`
      );

      exportDataGrid({
        component: e.component,
        worksheet,
        customizeCell: ({ gridCell, excelCell }) => {
          if (gridCell.rowType === 'group') {
            excelCell.value = gridCell.value;
          }

          if (gridCell.rowType === 'data') {
            if (gridCell.column.dataField === 'level') {
              if (gridCell.data.parameter) {
                excelCell.value = gridCell.data.parameter;
              } else if (gridCell.data.month) {
                excelCell.value = `${new Date(
                  gridCell.data.year,
                  gridCell.data.month - 1,
                  1
                )
                  .toLocaleString(this.languageInterface, {
                    month: 'long',
                  })
                  .capitalize()} ${gridCell.data.year}`;
              } else {
                excelCell.value = this.$t('stats.total');

                excelCell.font = {
                  bold: true,
                };
              }
            }

            if (gridCell.column.dataField === 'nrSamples') {
              excelCell.value = gridCell.data.n_samples;

              if (!(gridCell.data.month || gridCell.data.parameter)) {
                excelCell.font = {
                  bold: true,
                };
              }
            }

            if (
              gridCell.column.dataField === 'nrSamplesComparative'
            ) {
              excelCell.value = gridCell.data.historic.n_samples;

              if (gridCell.data.month || gridCell.data.parameter) {
                // excelCell.fill = {
                //   // type: 'pattern',
                //   // pattern: 'solid',
                //   // fgColor: { argb: '5243aa' },
                //   color: { argb: '5243aa' },
                // };

                excelCell.font = {
                  bold: true,
                  color: { argb: '5243aa' },
                };
              } else {
                excelCell.font = {
                  bold: true,
                };
              }
            }

            if (
              gridCell.column.dataField === 'nrPositivesComparative'
            ) {
              excelCell.value = gridCell.data.historic.n_positives;

              if (gridCell.data.month || gridCell.data.parameter) {
                // excelCell.fill = {
                //   // type: 'pattern',
                //   // pattern: 'solid',
                //   // fgColor: { argb: '5243aa' },
                //   color: { argb: '5243aa' },
                // };

                excelCell.font = {
                  bold: true,
                  color: { argb: '5243aa' },
                };
              } else {
                excelCell.font = {
                  bold: true,
                };
              }
            }

            if (gridCell.column.dataField === 'nrPositives') {
              excelCell.value = gridCell.data.n_positives;

              if (!(gridCell.data.month || gridCell.data.parameter)) {
                excelCell.font = {
                  bold: true,
                };
              }
            }

            if (
              gridCell.column.dataField === 'positivesTotalSamples'
            ) {
              excelCell.value =
                gridCell.data.per_positives.toFixed(2);

              if (!(gridCell.data.month || gridCell.data.parameter)) {
                excelCell.font = {
                  bold: true,
                };
              }
            }

            if (
              gridCell.column.dataField ===
              'positivesTotalSamplesComparative'
            ) {
              excelCell.value =
                gridCell.data.historic.per_positives.toFixed(2);

              if (gridCell.data.month || gridCell.data.parameter) {
                // excelCell.fill = {
                //   type: 'pattern',
                //   pattern: 'solid',
                //   // fgColor: { argb: '5243aa' },
                //   color: { argb: '5243aa' },
                // };

                excelCell.font = {
                  bold: true,
                  color: { argb: '5243aa' },
                };
              } else {
                excelCell.font = {
                  bold: true,
                };
              }
            }

            if (gridCell.column.dataField === 'superiorLmrs') {
              excelCell.value = gridCell.data.n_lmrs;

              if (!(gridCell.data.month || gridCell.data.parameter)) {
                excelCell.font = {
                  bold: true,
                };
              }
            }

            if (
              gridCell.column.dataField === 'superiorLmrsComparative'
            ) {
              excelCell.value = gridCell.data.historic.n_lmrs;

              if (
                gridCell.data.historic.month ||
                gridCell.data.historic.parameter
              ) {
                // excelCell.fill = {
                //   type: 'pattern',
                //   pattern: 'solid',
                //   // fgColor: { argb: '5243aa' },
                //   color: { argb: '5243aa' },
                // };

                excelCell.font = {
                  bold: true,
                  color: { argb: '5243aa' },
                };
              } else {
                excelCell.font = {
                  bold: true,
                };
              }
            }

            if (gridCell.column.dataField === 'lmrTotal') {
              excelCell.value = gridCell.data.per_lmrs.toFixed(2);

              if (!(gridCell.data.month || gridCell.data.parameter)) {
                excelCell.font = {
                  bold: true,
                };
              }
            }

            if (gridCell.column.dataField === 'lmrTotalComparative') {
              excelCell.value =
                gridCell.data.historic.per_lmrs.toFixed(2);

              if (
                gridCell.data.historic.month ||
                gridCell.data.historic.parameter
              ) {
                // excelCell.fill = {
                //   type: 'pattern',
                //   pattern: 'solid',
                //   // fgColor: { argb: '5243aa' },
                //   color: { argb: '5243aa' },
                // };

                excelCell.font = {
                  bold: true,
                  color: { argb: '5243aa' },
                };
              } else {
                excelCell.font = {
                  bold: true,
                };
              }
            }

            if (gridCell.column.dataField === 'n_traces') {
              excelCell.value = gridCell.data.n_traces;

              if (!(gridCell.data.month || gridCell.data.parameter)) {
                excelCell.font = {
                  bold: true,
                };
              }
            }

            if (gridCell.column.dataField === 'n_tracesComparative') {
              excelCell.value = gridCell.data.historic.n_traces;

              if (
                gridCell.data.historic.month ||
                gridCell.data.historic.parameter
              ) {
                // excelCell.fill = {
                //   type: 'pattern',
                //   pattern: 'solid',
                //   // fgColor: { argb: '5243aa' },
                //   color: { argb: '5243aa' },
                // };

                excelCell.font = {
                  bold: true,
                  color: { argb: '5243aa' },
                };
              } else {
                excelCell.font = {
                  bold: true,
                };
              }
            }
          }
        },
      })
        .then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(
              new Blob([buffer], {
                type: 'application/octet-stream',
              }),
              // `besafer-${this.reportSampleCode}.xlsx`
              `besafer.xlsx`
            );
          });
        })
        .then(() => {
          e.component.columnOption('ID', 'visible', false);
          e.component.endUpdate();
        });

      e.cancel = true;
    },
    ...mapActions('filter', ['setCurrentFilter']),
  },
};
</script>
<style scoped>
::v-deep .dx-toolbar-after {
  left: initial !important;
}
</style>
