<template>
  <div>
    <DxChart id="chart" :data-source="ds.data">
      <DxCommonSeriesSettings
        argument-field="parameter"
        type="stackedbar"
      />

      <DxValueAxis position="left">
        <DxTitle :text="$t('stats.numSamples')" />
      </DxValueAxis>

      <DxSeries
        v-if="selectedSampleTypes.length >= 1"
        :name="
          aggregatedData[0].sample_type +
          ' - ' +
          $t('positives') +
          ' > LMR'
        "
        color="#FF6384"
        :value-field="
          `nSuperiorLmrs_` + aggregatedData[0].sample_type
        "
        stack="first"
      />

      <DxSeries
        v-if="selectedSampleTypes.length >= 1"
        :name="
          aggregatedData[0].sample_type + ' - ' + $t('positives')
        "
        color="#FFCE56"
        :value-field="`nPositives_` + aggregatedData[0].sample_type"
        stack="first"
      />

      <DxSeries
        v-if="selectedSampleTypes.length >= 1"
        :name="aggregatedData[0].sample_type + ' - ' + $t('samples')"
        :value-field="`nSamples_` + aggregatedData[0].sample_type"
        color="#ffeda0"
        stack="first"
      />

      <DxSeries
        v-if="selectedSampleTypes.length >= 2"
        :name="
          aggregatedData[1].sample_type +
          ' - ' +
          $t('positives') +
          ' > LMR'
        "
        color="#9c88ff"
        :value-field="
          `nSuperiorLmrs_` + aggregatedData[1].sample_type
        "
        stack="second"
      />

      <DxSeries
        v-if="selectedSampleTypes.length >= 2"
        :name="
          aggregatedData[1].sample_type + ' - ' + $t('positives')
        "
        color="#4BC0C0"
        :value-field="`nPositives_` + aggregatedData[1].sample_type"
        stack="second"
      />

      <DxSeries
        v-if="selectedSampleTypes.length >= 2"
        :name="aggregatedData[1].sample_type + ' - ' + $t('samples')"
        color="#6a89cc"
        :value-field="`nSamples_` + aggregatedData[1].sample_type"
        stack="second"
      />

      <DxSeries
        v-if="selectedSampleTypes.length >= 3"
        :name="
          aggregatedData[2].sample_type +
          ' - ' +
          $t('positives') +
          ' > LMR'
        "
        color="#badc58"
        :value-field="
          `nSuperiorLmrs_` + aggregatedData[2].sample_type
        "
        stack="third"
      />

      <DxSeries
        v-if="selectedSampleTypes.length >= 3"
        :name="
          aggregatedData[2].sample_type + ' - ' + $t('positives')
        "
        color="#7bed9f"
        :value-field="`nPositives_` + aggregatedData[2].sample_type"
        stack="third"
      />

      <DxSeries
        v-if="selectedSampleTypes.length >= 3"
        :name="aggregatedData[2].sample_type + ' - ' + $t('samples')"
        color="#ced6e0"
        :value-field="`nSamples_` + aggregatedData[2].sample_type"
        stack="third"
      />

      <DxSeries
        v-if="selectedSampleTypes.length >= 4"
        :name="
          aggregatedData[3].sample_type +
          ' - ' +
          $t('positives') +
          ' > LMR'
        "
        color="#70a1ff"
        :value-field="
          `nSuperiorLmrs_` + aggregatedData[3].sample_type
        "
        stack="fourth"
      />

      <DxSeries
        v-if="selectedSampleTypes.length >= 4"
        :name="
          aggregatedData[3].sample_type + ' - ' + $t('positives')
        "
        color="#9ecae1"
        :value-field="`nPositives_` + aggregatedData[3].sample_type"
        stack="fourth"
      />

      <DxSeries
        v-if="selectedSampleTypes.length >= 4"
        color="#deebf7"
        :name="aggregatedData[3].sample_type + ' - ' + $t('samples')"
        :value-field="`nSamples_` + aggregatedData[3].sample_type"
        stack="fourth"
      />

      <DxSeries
        v-if="selectedSampleTypes.length >= 5"
        :name="
          aggregatedData[4].sample_type +
          ' - ' +
          $t('positives') +
          ' > LMR'
        "
        color="#ff4757"
        :value-field="
          `nSuperiorLmrs_` + aggregatedData[4].sample_type
        "
        stack="fifth"
      />

      <DxSeries
        v-if="selectedSampleTypes.length >= 5"
        :name="
          aggregatedData[4].sample_type + ' - ' + $t('positives')
        "
        color="#ffb8b8"
        :value-field="`nPositives_` + aggregatedData[4].sample_type"
        stack="fifth"
      />

      <DxSeries
        v-if="selectedSampleTypes.length >= 5"
        color="#1dd1a1"
        :name="aggregatedData[4].sample_type + ' - ' + $t('samples')"
        :value-field="`nSamples_` + aggregatedData[4].sample_type"
        stack="fifth"
      />

      <DxLegend
        :customize-items="customizeItems"
        vertical-alignment="bottom"
        :column-count="3"
        horizontal-alignment="center"
        item-text-position="top"
      />

      <DxExport :enabled="true" />
      <DxTooltip
        :enabled="true"
        :z-index="999999"
        :customize-tooltip="customizeTooltip"
      />
    </DxChart>
  </div>
</template>

<script>
import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxValueAxis,
  DxTitle,
  DxLegend,
  DxExport,
  DxTooltip,
} from 'devextreme-vue/chart';
import { mapState } from 'vuex';

export default {
  components: {
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxValueAxis,
    DxTitle,
    DxLegend,
    DxExport,
    DxTooltip,
  },
  data() {
    return {
      samplesStack: [],
    };
  },
  computed: {
    ds() {
      const data = [];
      const samples = [];

      if (!('parameter' in this.aggregatedData[0].stats[0]))
        return { data, samples };

      this.aggregatedData.map((el) => {
        samples.push(el.sample_type);

        const formattedData = el.stats.map((parameter, index) => {
          const obj = {
            ...parameter.current,
            parameter: parameter.parameter,
            sample_type: el.sample_type,
            sample_type_id: el.sample_type_id,
            n_samples_original: parameter.current.n_samples,
            n_positives_original: parameter.current.n_positives,
          };

          obj[`nSamples_${el.sample_type}`] =
            obj.n_samples - obj.n_positives;
          obj[`nPositives_${el.sample_type}`] =
            obj.n_positives - obj.n_lmrs;
          obj[`nSuperiorLmrs_${el.sample_type}`] = obj.n_lmrs;

          return obj;
        });

        data.push(...formattedData.filter((d) => d.parameter));
      });

      return { data, samples };
    },
    ...mapState('foodStats', ['aggregatedData']),
    ...mapState('foodStatsFilter', ['selectedSampleTypes']),
  },
  methods: {
    customizeTooltip(pointInfo) {
      const { data } = pointInfo.point;

      // pointInfo.point.data[pointInfo.point._options.valueField]

      const isSamples =
        pointInfo.point._options.valueField.includes('nSamples');

      const isPositives =
        pointInfo.point._options.valueField.includes('nPositives');
      if (isSamples) {
        return {
          html: `<p>${this.$t('samples')}: ${
            pointInfo.point.data.n_samples_original
          }</p>`,
        };
      }
      if (isPositives) {
        return {
          html: `<p>${this.$t('positives')}: ${
            pointInfo.point.data.n_positives_original
          }</p>`,
        };
      }
      return {
        html: `<p>${this.$t('positives')} > LMR: ${
          pointInfo.point.data[pointInfo.point._options.valueField]
        }</p>`,
      };
    },
    customizeItems(items) {
      /** const filteredElements = items.filter(
        (v, i, a) => a.findIndex((t) => t.text === v.text) === i
      ); */

      return items;
    },
  },
};
</script>
