<template>
  <div class="content-devx">
    <DxDataGrid
      :data-source="ds"
      :show-borders="true"
      :remote-operations="true"
      :allow-column-reordering="true"
      :column-auto-width="true"
      :show-colon="false"
    >
      <DxHeaderFilter :visible="true" />
      <DxGroupPanel :visible="false" />
      <DxPaging :page-size="10" />

      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
        info-text="{2} items"
      />
      <DxExport :enabled="false" />

      <DxColumn
        data-field="geo_json[0].features[0].properties.source_name"
        :allow-header-filtering="false"
        :caption="$t('stationId')"
        alignment="left"
      />

      <DxColumn
        data-field="last_read"
        data-type="date"
        :allow-header-filtering="false"
        :caption="$t('lastRead')"
        alignment="left"
      />

      <DxColumn
        data-field="latitude"
        :allow-header-filtering="false"
        :caption="$t('latitude')"
        alignment="left"
        cell-template="latitude"
      />

      <template #latitude="{ data }">
        <div>
          {{ Number(data.data.latitude).toFixed(6) }}
        </div>
      </template>

      <DxColumn
        data-field="longitude"
        :allow-header-filtering="false"
        :caption="$t('longitude')"
        alignment="left"
        cell-template="longitude"
      />

      <template #longitude="{ data }">
        <div>
          {{ Number(data.data.longitude).toFixed(6) }}
        </div>
      </template>

      <DxColumn
        alignment="center"
        :allow-header-filtering="false"
        cell-template="radioBtnTemplate"
      />

      <template #radioBtnTemplate="{ data: parcelInfo }">
        <div>
          <DxCheckBox
            :value="isEditingFarm ? parcelInfo.data.assigned : false"
            @valueChanged="addStationMarker($event, parcelInfo)"
            @contentReady="
              isEditingFarm ? onInitEdit(parcelInfo) : null
            "
          />
        </div>
      </template>

      <DxScrolling column-rendering-mode="standard" :visible="true" />
    </DxDataGrid>

    <NotificationSpinner
      v-if="
        notificationMessage.length > 0 &&
        selectedStations.length === 0 &&
        ds.length > selectedStations.length
      "
      :message="notificationMessage"
    />
  </div>
</template>
<script>
import { DxCheckBox } from 'devextreme-vue/check-box';
import EventBus from '@/services/event-bus';
import NotificationSpinner from '@/components/NotificationSpinner';

import {
  DxDataGrid,
  DxColumn,
  DxExport,
  DxGroupPanel,
  DxHeaderFilter,
  DxScrolling,
  DxPager,
  DxPaging,
} from 'devextreme-vue/data-grid';

import { mapActions, mapState } from 'vuex';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxCheckBox,
    DxExport,
    DxGroupPanel,
    DxHeaderFilter,
    DxScrolling,
    DxPager,
    DxPaging,
    NotificationSpinner,
  },
  props: {
    ds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pageSizes: [5, 10, 20],
      notificationMessage: '',
    };
  },
  computed: {
    ...mapState('registerFarm', [
      'selectedStations',
      'isEditingFarm',
    ]),
  },
  beforeMount() {
    this.emptySelectedStations([]);
  },
  mounted() {
    setTimeout(() => {
      this.notificationMessage = this.$t('selectStations');
    }, 500);
  },
  beforeDestroy() {
    this.notificationMessage = '';
  },
  methods: {
    onInitEdit(parcelInfo) {
      this.addStationMarker(
        { value: parcelInfo.data.assigned },
        parcelInfo
      );
    },
    addStationMarker(e, data) {
      if (!e.value) {
        this.removeGeoJsonPoint(
          data.row.data.geo_json[0].features[0].properties.station_id
        );

        EventBus.$emit(
          'removeStationMarker',
          data.row.data.geo_json[0].features[0].properties.index
        );

        if (this.selectedStations.length > 0) {
          this.removeSelectedStation(data.data.station_id);
        }
      } else {
        const rowData = data.row.data.geo_json[0].features[0];

        this.setGeoJsonPoints(rowData);

        EventBus.$emit(
          'addStationMarker',
          data.row.data.geo_json[0].features[0].properties.index
        );
        if (this.selectedStations.length > 0) {
          this.selectedStations.forEach((station) => {
            if (station.station_id !== data.data.station_id) {
              data.data.assigned = true;
              this.setSelectedStations(data.data);
            }
          });
        } else {
          data.data.assigned = true;
          this.setSelectedStations(data.data);
        }
      }
    },
    ...mapActions('gis', ['setGeoJsonPoints', 'removeGeoJsonPoint']),
    ...mapActions('registerFarm', [
      'setSelectedStations',
      'removeSelectedStation',
      'emptySelectedStations',
    ]),
  },
};
</script>
<style lang="scss" scoped></style>
