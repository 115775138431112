<template>
  <transition name="fade">
    <div
      class="report-notify-container dx-overlay dx-overlay-wrapper dx-toast-wrapper"
    >
      <div
        :class="`report-notify-notification${alert ? '-alert' : ''}${
          warning ? '-warning' : ''
        }`"
      >
        <div
          v-if="!alert && !warning"
          class="spinner-notify-container"
        >
          <div class="spinner-notify" />
        </div>
        <div class="notify-text">
          {{ message }}
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'NotificationSpinner',
  props: {
    message: {
      type: String,
      default: '',
    },
    alert: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import './NotificationSpinner.styles.scss';
</style>
