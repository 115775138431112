export default {
  namespaced: true,
  state: {
    creationFromDate: null,
    creationToDate: null,
    selectedAtcs: [],
    selectedSampleTypes: [],
    selectedParameters: [],
    selectedFarms: [],
    selectedParcels: [],
  },
  actions: {
    setCreationToDate({ commit }, value) {
      commit('setCreationToDate', value);
    },
    setCreationFromDate({ commit }, value) {
      commit('setCreationFromDate', value);
    },
    setSelectedAtcs({ commit }, value) {
      commit('setSelectedAtcs', value);
    },
    setSelectedSampleTypes({ commit }, value) {
      commit('setSelectedSampleTypes', value);
    },
    setSelectedParameters({ commit }, value) {
      commit('setSelectedParameters', value);
    },
    setSelectedFarms({ commit }, value) {
      commit('setSelectedFarms', value);
    },
    setSelectedParcels({ commit }, value) {
      commit('setSelectedParcels', value);
    },
    setSelectedStatuses({ commit }, value) {
      commit('setSelectedStatuses', value);
    },
    clearFilters({ commit }) {
      commit('clearFilters');
    },
  },
  mutations: {
    setCreationFromDate(state, value) {
      state.creationFromDate = value;
    },
    setCreationToDate(state, value) {
      state.creationToDate = value;
    },
    setSelectedAtcs(state, value) {
      state.selectedAtcs = value;
    },
    setSelectedParameters(state, value) {
      state.selectedParameters = value;
    },
    setSelectedFarms(state, value) {
      state.selectedFarms = value;
    },
    setSelectedSampleTypes(state, value) {
      state.selectedSampleTypes = value;
    },
    setSelectedParcels(state, value) {
      state.selectedParcels = value;
    },
    setSelectedStatuses(state, value) {
      state.selectedStatuses = value;
    },
    clearFilters(state) {
      state.creationFromDate = null;
      state.creationToDate = null;
      state.selectedAtcs = [];
      state.selectedParameters = [];
      state.selectedSampleTypes = [];
      state.selectedFarms = [];
      state.selectedParcels = [];
    },
  },
};
