<template>
  <div>
    <div
      :id="`${nameMap}_${initialDate}`"
      :style="{
        height: 'calc(100vh - 135px)',
        'border-radius': '5px',
      }"
    ></div>
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import { mapState, mapActions } from 'vuex';
import EventBus from '@/services/event-bus';
import Api from '@/services/Api';

export default {
  name: 'GeoToolBoxVisor',
  props: {
    height: {
      type: Number,
      default: 500,
    },
    zoom: { type: Number, default: 1 },
    selectedMinHeight: { type: String, default: '50vh' },
    nameMap: { type: String, default: '' },
    treeLocations: {
      type: Array,
      default: () => [],
    },
    geometriesSamples: {
      type: Array,
      default: () => [],
    },
    center: {
      type: Array,
      default: () => null,
    },
  },
  data() {
    return {
      currentLayerBase: null,
      event: 'updateGIS',
      map: null,
      layerBounds: null,
      groupBounds: null,
      initialDate: Date.now(),
      locationProperties: {},
      actualDate: new Date(),
      // --
      featureGroupAreas: null,
      featureGroupSamples: null,
      featureGroupSets: null,
      menu: {
        areas: {},
        samples: {},
        images: {},
        sets: {},
        interpolations: {},
      },
      rasterLayer: {
        name: 'gis',
        visible: false,
        timeDimension: true,
        baseUrl: Api.getGeoServerProjectWorkSpace(),
        options: {
          layers: '',
          format: 'image/png',
          transparent: true,
          styles: 'NDVI',
        },
      },
      wsmLayer: null,
      areaColorIndex: 0,
      sampleColorIndex: {},
    };
  },
  computed: {
    getAreaColor() {
      const colors = [
        // Rose
        // '#fecdd3',
        '#fda4af',
        '#fb7185',
        '#f43f5e',
        '#e11d48',
        '#be123c',
        '#9f1239',
        '#881337',
        // Cyan
        // '#a5f3fc',
        '#67e8f9',
        '#22d3ee',
        '#06b6d4',
        '#0e7490',
        '#155e75',
        '#164e63',
      ];
      // random color from array
      return colors[this.areaColorIndex % colors.length];
    },
    ...mapState('gis', ['osm', 'treeLocationData']),
    ...mapState('registerFarm', ['farm', 'parentParcelId', 'farmId']),
  },
  mounted() {
    this.renderMap();
    // this.addFuncionalities();
    // this.locateUser();

    // Load menu 'Áreas':
    this.loadMenuAreas();
    this.loadGeometriesSamples();

    this.addLegend();

    EventBus.$on('update-legend', this.updateLegend);
  },
  beforeDestroy() {
    EventBus.$off('change-base-map', this.ChangeBaseMap);
    EventBus.$off('update-legend', this.updateLegend);

    EventBus.$off('toggle-image');
    EventBus.$off('delete-sets');
    EventBus.$off('toggle-sample-set');
    EventBus.$off('add-set');
    EventBus.$off('redraw-samples-on-map');
    EventBus.$off('zoom-to-sample');
    EventBus.$off('toggle-sample-all');
    EventBus.$off('toggle-sample');
    EventBus.$off('add-interpolation');
    EventBus.$off('toggle-area');
  },
  methods: {
    renderMap() {
      // Load map
      this.map = L.map(`${this.nameMap}_${this.initialDate}`, {
        worldCopyJump: true,
        // minZoom: 12,
        maxZoom: 18,
      });

      // Change right bottom corner text
      this.map.attributionControl.addAttribution(
        'Margaret from <a href="https://hispatecanalytics.com//">HispatecAnalytics SA</a>'
      );

      // Set the current layer base to Google Hybrid Map
      this.currentLayerBase = L.tileLayer(
        this.osm.googleHybrid.route,
        this.osm.googleHybrid.properties
      ).addTo(this.map);

      // Event to change base map
      EventBus.$on('change-base-map', this.ChangeBaseMap);
    },
    locateUser() {
      this.map
        .locate({ setView: true, maxZoom: 16 })
        .on('locationfound', (e) => {
          this.map.setView(e.latlng, 16);
        })
        .on('locationerror', () => {
          this.map.setView(
            [41.6164989248106, -4.873517847881886],
            13
          );
        });
    },
    loadMenuAreas() {
      this.featureGroupAreas = L.featureGroup().addTo(this.map);

      // const farm = this.treeLocations.find(
      //   (item) => item.id === this.farm
      // );
      const farm = this.treeLocations.find(
        (item) => item.code == this.farmId
      );

      if (!farm) {
        this.locateUser();
        return;
      }

      for (const child of farm.children) {
        const color = this.getAreaColor;
        this.areaColorIndex += 1;

        const feature = child.geo_feature.features[0];

        feature.properties.id = child.id;
        feature.properties.name = child.name;
        feature.properties.color = color;
        feature.properties.parcel_id = child.code;

        const geoJson = L.geoJSON(feature, {
          style: (feat) => {
            return {
              color,
              fillColor: feat.properties?.fillColor || color,
              fillOpacity: 0,
            };
          },
          onEachFeature: (feat, layer) => {
            this.menu.areas[feat.properties.id] = {
              ...feat.properties,
              id: feat.properties.id,
              name: feat.properties.name,
              checked: true,
              layer,
            };
          },
        });

        this.featureGroupAreas.addLayer(geoJson);
      }

      this.map.fitBounds(this.featureGroupAreas.getBounds(), {
        padding: [130, 130],
      });

      this.featureGroupAreas.on('click', (e) => {
        const { layer } = e;

        // if (layer instanceof L.Polygon) {

        const style = `background: ${layer.feature.properties.color};`;
        const title = layer.feature.properties.name;
        const id = Math.random().toString(36).substring(7);

        const disabled =
          Object.values(this.menu.images)
            .slice()
            .reverse()
            .find((i) => i.checked === true && i.title === title) ===
          undefined;

        L.popup()
          .setLatLng(e.latlng)
          .setContent(
            `
              <div class="popup-title" title="${title}" style="${style}">${title}</div>
              <div class="popup-buttons-polygon">
                <button id="nutritional-${id}" class="btn-detail">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="10" cy="10" r="7"></circle>
                    <line x1="21" y1="21" x2="15" y2="15"></line>
                  </svg>
                </button>
                <button id="comparation-${id}" class="btn-comparation">
                  <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="m6 18h-3c-.48 0-1-.379-1-1v-14c0-.481.38-1 1-1h14c.621 0 1 .522 1 1v3h3c.621 0 1 .522 1 1v14c0 .621-.522 1-1 1h-14c-.48 0-1-.379-1-1zm1.5-10.5v13h13v-13zm9-1.5v-2.5h-13v13h2.5v-9.5c0-.481.38-1 1-1z" fill-rule="nonzero"/>
                  </svg>
                </button>
              </div>
            `
          )
          .openOn(this.map);

        const buttonNutritional = document.getElementById(
          `nutritional-${id}`
        );

        const buttonComparation = document.getElementById(
          `comparation-${id}`
        );

        buttonComparation.disabled = disabled;
        buttonNutritional.disabled =
          layer.feature.properties.parcel_id === undefined;

        buttonNutritional.addEventListener('click', () => {
          this.viewNutritionalMonitoring({
            sample_id: layer.feature.properties.sample_id,
            parcel: layer.feature.properties.name,
            parcel_id: layer.feature.properties.parcel_id,
          });
        });

        buttonComparation.addEventListener('click', () => {
          const first = Object.values(this.menu.images)
            .slice()
            .reverse()
            .find((i) => i.checked === true && i.title === title);

          const index = Object.values(this.menu.images).indexOf(
            first
          );

          EventBus.$emit('selected-layer-to-compare', {
            geometry: layer.toGeoJSON(),
            images: Object.values(this.menu.images),
            selected: index ?? null,
          });
        });
      });

      this.$emit('menu-areas', this.menu.areas);

      EventBus.$on('toggle-area', (data) => {
        if (data.checked) {
          data.layer.getElement().style.display = 'block';
        } else {
          data.layer.getElement().style.display = 'none';
        }
      });

      EventBus.$on('add-interpolation', (data) => {
        this.addInterpolation(data);
        this.addImage(data);

        const first = Object.values(this.menu.images)
          .slice()
          .reverse()
          .find((i) => i.checked === true);
        EventBus.$emit('update-legend', first);
      });
    },
    drawSamplePoints(points, color, menuType, title = '') {
      const id = Math.random().toString(36).substring(3);

      for (const sample of points) {
        const feature = {
          type: 'Feature',
          geometry: sample.geometry,
          properties: {
            ...sample,
            id: sample.sample_id,
            title: title || sample.parcel,
            color,
          },
        };
        const myIcon = L.divIcon({
          className: 'my-div-icon',
          iconSize: [20, 20],
          iconAnchor: [10, 10],
          html: this.getSvg(sample.sample_type_family_id, color),
        });

        const geoJson = L.geoJSON(feature, {
          pointToLayer: (feat, latlng) => {
            return L.marker(latlng, { icon: myIcon });
          },
          onEachFeature: (feat, layer) => {
            if (this.menu[menuType][id] == null) {
              this.menu[menuType][id] = {
                properties: {
                  ...feat.properties,
                },
                // checked: true,
                layers: [],
              };
            }

            this.menu[menuType][id].layers.push(layer);
          },
        });

        this.featureGroupSamples.addLayer(geoJson);
      }
    },
    addPopupSamples() {
      this.featureGroupSamples.on('click', (e) => {
        const { layer } = e;

        const style = `background: ${layer.feature.properties.color};`;
        const title = layer.feature.properties.sample_type_family;
        const { id } = layer.feature.properties;
        const {
          sample_code,
          sampling_date,
          parcel,
          study,
          sample_type_family_id,
          sample_type_family,
          sample_type_subfamily_id,
          sample_type_subfamily,
          sample_type_id,
          sample_type,
          parameter,
        } = layer.feature.properties;

        const readableDate = new Date(
          sampling_date
        ).toLocaleDateString('es-ES');

        const unit = parameter?.unit ?? '';

        const additionalData =
          parameter != null
            ? `
          <tr>
            <td>${this.$t('foodSamples.parameter')}</td>
            <td>${parameter.parameter}</td>
          </tr>
          <tr>
            <td>${this.$t('foodSamples.result')}</td>
            <td>${parameter?.result}</td>
          </tr>
          <tr>
            <td>${this.$t('foodSamples.unit')}</td>
            <td>${unit}</td>
          </tr>
        `
            : '';

        L.popup()
          .setLatLng(e.latlng)
          .setContent(
            `
              <div class="popup-title" title="${title}" style="${style}">${title}</div>

              <div class="popup-data-table">
                <table class="table">
                  <tbody>
                    <tr>
                      <td>${this.$t(
                        'agronomySample.summary.sampleCode'
                      )}</td>
                      <td>${sample_code}</td>
                    </tr>
                    <tr>
                      <td>${this.$t(
                        'agronomySample.summary.samplingDate'
                      )}</td>
                      <td>${readableDate}</td>
                    </tr>
                    <tr>
                      <td>${this.$t(
                        'agronomySample.summary.parcel'
                      )}</td>
                      <td>${parcel}</td>
                    </tr>
                    <tr>
                      <td>${this.$t('project')}</td>
                      <td>${study}</td>
                    </tr>
                    <tr>
                      <td>${this.$t('geotoolbox.family')}</td>
                      <td>${sample_type_family}</td>
                    </tr>
                    <tr>
                      <td>${this.$t(
                        'geotoolbox.sampleSubfamily'
                      )}</td>
                      <td>${sample_type_subfamily}</td>
                    </tr>
                    <tr>
                      <td>${this.$t(
                        'agronomySample.summary.sampleType'
                      )}</td>
                      <td>${sample_type}</td>
                    </tr>
                    ${additionalData}
                  </tbody>
                </table>
              </div>


              <div class="popup-buttons-polygon">
                <button id="nutritional-${id}" class="btn-nutritional">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-leaf" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5 21c.5 -4.5 2.5 -8 7 -10"></path>
                    <path d="M9 18c6.218 0 10.5 -3.288 11 -12v-2h-4.014c-9 0 -11.986 4 -12 9c0 1 0 3 2 5h3z"></path>
                  </svg>
                </button>
                <button id="detail-${id}" class="btn-detail">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="10" cy="10" r="7"></circle>
                    <line x1="21" y1="21" x2="15" y2="15"></line>
                  </svg>
                </button>
              </div>
            `
          )
          .openOn(this.map);

        const buttonNutritional = document.getElementById(
          `nutritional-${id}`
        );

        const buttonDetail = document.getElementById(`detail-${id}`);

        buttonNutritional.addEventListener('click', () => {
          this.viewNutritionalMonitoring({
            sample_id: layer.feature.properties.sample_id,
            parcel: layer.feature.properties.name,
            parcel_id: layer.feature.properties.parcel_id,
          });
        });

        buttonDetail.addEventListener('click', async () => {
          await this.viewDetail({
            id: layer.feature.properties.sample_oid,
            sample_id: layer.feature.properties.sample_id,
            code: layer.feature.properties.sample_code,
          });
        });
      });
    },
    loadGeometriesSamples() {
      this.featureGroupSamples = L.featureGroup().addTo(this.map);

      const groupedSamples = this.geometriesSamples.reduce(
        (acc, item) => {
          const familyType = item.sample_type_family_id;
          const sampleType = item.sample_type_id;
          if (!acc[`${familyType}-${sampleType}`]) {
            acc[`${familyType}-${sampleType}`] = [];
          }
          acc[`${familyType}-${sampleType}`].push(item);
          return acc;
        },
        {}
      );

      for (const [key, samples] of Object.entries(groupedSamples)) {
        const [familyType, sampleType] = key.split('-');

        const color = this.getSampleColor(familyType);

        this.drawSamplePoints(samples, color, 'samples');
      }

      this.addPopupSamples();

      this.$emit('menu-samples', this.menu.samples);

      EventBus.$on('toggle-sample', (data) => {
        data.layers.forEach((layer) => {
          if (data.checked) {
            layer.getElement().style.display = 'block';
          } else {
            layer.getElement().style.display = 'none';
          }
        });
      });

      EventBus.$on('toggle-sample-all', (data) => {
        data.layers.forEach((layerType) => {
          layerType.forEach((layer) => {
            if (data.checked) {
              layer.getElement().style.display = 'block';
            } else {
              layer.getElement().style.display = 'none';
            }
          });
        });
      });

      EventBus.$on('zoom-to-sample', (coordinates) => {
        this.map.setView([coordinates[1], coordinates[0]], 18);
      });

      EventBus.$on('redraw-samples-on-map', (samples) => {
        this.redrawSamples(samples);
      });

      EventBus.$on('add-set', (data) => {
        this.addSet(data);
      });
    },
    redrawSamples(newSamples) {
      // remove only samples
      const keys = Object.keys(this.menu.samples);

      for (const key of keys) {
        this.menu.samples[key].layers.forEach((layer) => {
          // this.featureGroupSamples.removeLayer(layer);
          this.map.removeLayer(layer);
        });
        // delete this.menu.samples[key];
      }

      // this.featureGroupSamples.clearLayers();
      this.menu.samples = {};
      this.sampleColorIndex = {};

      const groupedSamples = newSamples.reduce((acc, item) => {
        const familyType = item.sample_type_family_id;
        const sampleType = item.sample_type_id;
        if (!acc[`${familyType}-${sampleType}`]) {
          acc[`${familyType}-${sampleType}`] = [];
        }
        acc[`${familyType}-${sampleType}`].push(item);
        return acc;
      }, {});

      for (const [key, samples] of Object.entries(groupedSamples)) {
        const [familyType, sampleType] = key.split('-');

        const color = this.getSampleColor(familyType);

        this.drawSamplePoints(samples, color, 'samples');
      }

      // TODO: enable buttons links to other pages

      this.$emit('menu-samples', this.menu.samples);
    },
    addSet(data) {
      const groupedSamples = data.samples.reduce((acc, item) => {
        const familyType = item.sample_type_family_id;
        const sampleType = item.sample_type_id;
        if (!acc[`${familyType}-${sampleType}`]) {
          acc[`${familyType}-${sampleType}`] = [];
        }
        acc[`${familyType}-${sampleType}`].push(item);
        return acc;
      }, {});

      for (const [key, samples] of Object.entries(groupedSamples)) {
        const [familyType, sampleType] = key.split('-');

        const color = this.getSampleColor(familyType);

        this.drawSamplePoints(samples, color, 'sets', data.title);
      }

      this.$emit('menu-sets', this.menu.sets);

      EventBus.$on('toggle-sample-set', (sampleData) => {
        sampleData.layers.forEach((layer) => {
          if (sampleData.checked) {
            layer.getElement().style.display = 'block';
          } else {
            layer.getElement().style.display = 'none';
          }
        });
      });

      EventBus.$on('delete-sets', () => {
        Object.values(this.menu.sets).forEach((sampleData) => {
          sampleData.layers.forEach((layer) => {
            // layer.getElement().style.display = 'none';
            this.map.removeLayer(layer);
          });
        });

        this.menu.sets = {};
        this.$emit('menu-sets', this.menu.sets);
      });
    },
    addInterpolation(data) {
      const color = this.getAreaColor;
      this.areaColorIndex += 1;

      const { feature } = data;

      feature.properties.name = data.name;
      feature.properties.color = color;

      const geoJson = L.geoJSON(feature, {
        style: (feat) => {
          return {
            color,
            fillColor: feat.properties?.fillColor || color,
            fillOpacity: 0,
          };
        },
        onEachFeature: (feat, layer) => {
          const random = Math.random().toString(36).substring(7);

          this.menu.interpolations[`${data.name}-${random}`] = {
            ...feat.properties,
            name: data.name,
            checked: true,
            layer,
          };
        },
      });

      this.featureGroupAreas.addLayer(geoJson);

      this.$emit('menu-interpolations', this.menu.interpolations);
    },
    addImage(data) {
      let customRaster = {
        name: 'gis',
        visible: false,
        timeDimension: true,
        baseUrl: Api.getGeoServerProjectWorkSpaceInterpol(),
        options: {
          layers: `int_results:${data.rasterImageName}`,
          format: 'image/png',
          transparent: true,
          styles: `int_results:${data.rasterImageName}_con`,
        },
      };

      this.menu.images[`con-${data.rasterImageName}`] = {
        name: `CON-${data.name}`,
        layer: L.tileLayer
          .wms(customRaster.baseUrl, customRaster.options)
          .addTo(this.map)
          .setOpacity(1),
        checked: true,
        rasterImageName: data.rasterImageName,
        geometry: data.feature,
        title: data.title,
        type: 'con',
      };

      customRaster = {
        name: 'gis',
        visible: false,
        timeDimension: true,
        baseUrl: Api.getGeoServerProjectWorkSpaceInterpol(),
        options: {
          layers: `int_results:${data.rasterImageName}`,
          format: 'image/png',
          transparent: true,
          styles: `int_results:${data.rasterImageName}_dis`,
        },
      };

      this.menu.images[`dis-${data.rasterImageName}`] = {
        name: `REF-${data.name}`,
        layer: L.tileLayer
          .wms(customRaster.baseUrl, customRaster.options)
          .addTo(this.map)
          .setOpacity(1),
        checked: true,
        rasterImageName: data.rasterImageName,
        geometry: data.feature,
        title: data.title,
        type: 'dis',
      };

      this.$emit('menu-images', this.menu.images);

      EventBus.$on('toggle-image', (dataImage) => {
        if (dataImage.checked) {
          dataImage.layer.setOpacity(1);
        } else {
          dataImage.layer.setOpacity(0);
        }
      });
    },
    getSvg(familyType, color) {
      const style = `width: 1.3rem; height: 1.3rem; color: ${color}; fill: currentColor;`;

      if (familyType === 2) {
        return `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="${style}">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
              `;
      }

      if (familyType === 4) {
        return `<svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" style="${style}"><circle cx="11.998" cy="11.998" fill-rule="nonzero" r="9.998"/></svg>
              `;
      }

      if (familyType === 5) {
        return `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style="${style}" viewBox="0 0 24 24"><path d="M24 22h-24l12-20z"/></svg>
              `;
      }

      if (familyType === 6) {
        return `<svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" style="${style}"><path d="m21 4c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1z" fill-rule="nonzero"/></svg>
              `;
      }

      return '';
    },
    getSampleColor(familyId) {
      const colors = {
        2: [
          // '#ddd6fe',
          '#c4b5fd',
          '#a78bfa',
          '#8b5cf6',
          '#7c3aed',
          '#6d28d9',
          '#5b21b6',
          '#4c1d95',
          '#3f185a',
          '#2f123b',
        ],
        4: [
          // '#bfdbfe',
          '#93c5fd',
          '#60a5fa',
          '#3b82f6',
          '#2563ed',
          '#1d4ed8',
          '#1e40af',
          '#1e3373',
          '#1e2545',
          '#191e2e',
        ],
        5: [
          // '#bbf7d0',
          '#86efac',
          '#4ade80',
          '#22c55e',
          '#16a34a',
          '#15803d',
          '#166534',
          '#14532d',
          '#123f21',
          '#0f2c17',
        ],
        6: [
          // '#fed7aa',
          '#fdba74',
          '#fb923c',
          '#f97316',
          '#ea580c',
          '#c2410c',
          '#9a3412',
          '#7c2d12',
          '#5a2417',
          '#42171c',
        ],
      };

      if (this.sampleColorIndex[familyId] == null) {
        this.sampleColorIndex[familyId] = 0;
      }

      const index = this.sampleColorIndex[familyId];
      const color = colors[familyId][index % colors[familyId].length];

      this.sampleColorIndex[familyId] += 1;

      return color;
    },
    viewNutritionalMonitoring({ sample_id, parcel, parcel_id }) {
      if (parcel_id == null) {
        return;
      }

      this.setLastRouteGeotoolbox(true);
      this.setParcelId(parcel_id);
      this.setParcelStr(parcel);

      this.$f7router.navigate(
        { name: 'NutritionalMonitoringPage' },
        {
          animate: true,
          transition: 'f7-fade',
        }
      );
    },
    async viewDetail(data) {
      this.setSampleFetchedFromEtl(false);
      this.setLastRouteHome(false);
      this.setLastRouteGeotoolboxSamples(true);

      this.setCurrentSampleId(data.id); // 620ba809100ecd445bc4bd72
      this.setReportSampleId(data.sample_id); // 3384199
      this.setReportSampleCode(data.code); // V-19/011488

      await this.getCurrentSample();

      // await this.goToSampleDetailsByAtc(e.row.data.atc_name, e); // atc_name: Agronomía

      this.setCurrentFilter('agronomy');

      // this.pushRoute({
      //   name: this.$t('Agronomy'),
      //   disabled: false,
      //   page: 'AgronomySamplesPage',
      //   active: false,
      // });

      this.pushRoute({
        name: this.$t('sampleSummary'),
        disabled: true,
        page: 'AgronomySamplesDetailsPage',
        active: true,
      });

      this.$f7router.navigate(
        {
          name: 'AgronomySamplesDetailsPage',
        },
        {
          animate: true,
          transition: 'f7-fade',
        }
      );
    },
    addLegend() {
      const self = this;
      L.Control.legend = L.Control.extend({
        options: {
          position: 'bottomleft',
        },
        onAdd() {
          self.map.legend = this;

          const container = L.DomUtil.create(
            'div',
            'legend-control-container'
          );

          if (this.options.content) {
            container.innerHTML = this.options.content;
          }
          return container;
        },
        onRemove() {
          delete self.map.legend;
        },

        setContent(str) {
          this.getContainer().innerHTML = str;
        },
      });
      // eslint-disable-next-line new-cap
      const legend = new L.Control.legend();
      legend.addTo(self.map);
    },

    // EventBuses methods
    ChangeBaseMap(map) {
      const selectedMap = {
        'Google Hybrid': 'googleHybrid',
        OSM: 'osm',
      }[map];

      this.map.removeLayer(this.currentLayerBase);

      this.currentLayerBase = L.tileLayer(
        this.osm[selectedMap].route,
        this.osm[selectedMap].properties
      ).addTo(this.map);

      this.currentLayerBase.bringToBack();
    },
    updateLegend(legend) {
      if (legend != null) {
        const legendImage = `${Api.getBaseUrlGis()}/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.1.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=int_results:test_mihail_3&LEGEND_OPTIONS=forceLabels:on;forceTitles:on;fontSize=20;dpi=300&STYLE=int_results:${
          legend?.rasterImageName
        }_${legend?.type}`;
        const legendContent = `<img src="${legendImage}">`;
        this.map.legend.setContent(legendContent);
      } else {
        this.map.legend.setContent('');
      }
    },
    ...mapActions('gis', ['setTreeLocationData']),
    ...mapActions('nutritionalMonitoring', [
      'setParcelId',
      'setLastRouteGeotoolbox',
    ]),
    ...mapActions('registerFarm', ['setParcelStr']),
    ...mapActions('filter', [
      'setCurrentFilter',
      'setCurrentSampleId',
    ]),
    ...mapActions('samples', [
      'setReportSampleId',
      'getCurrentSample',
      'setReportSampleCode',
      'setSampleFetchedFromEtl',
      'setLastRouteHome',
      'setLastRouteGeotoolboxSamples',
    ]),
    ...mapActions('breadcrumb', ['pushRoute']),
  },
};
</script>

<style scoped lang="scss">
@import './GeoToolBoxVisor.styles.scss';
</style>
