var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('f7-input',{staticClass:"recepDate",staticStyle:{"height":"24px","width":"180px"},attrs:{"type":"datepicker","placeholder":_vm.$t('agronomySample.chooseDates'),"clear-button":true,"close-on-select":true,"calendar-params":{
      value: [_vm.initDate, _vm.endDate],
      closeOnSelect: true,
      rangePicker: true,
      dateFormat: {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
      },
    }},on:{"calendar:change":_vm.calendarChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }