<template>
  <div class="margin-top">
    <div class="label-input">
      {{ $t('foodSamples.receptionDate') }}
    </div>
    <div class="recepDate marginTop5">
      <f7-input
        class="shadow"
        type="datepicker"
        :placeholder="$t('foodSamples.receptionDate')"
        :clear-button="true"
        :close-on-select="true"
        :value="calculatedHomeReceptionDate"
        :calendar-params="{
          closeOnSelect: true,
          rangePicker: true,
          dateFormat: {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
          },
          disabled: isDisabled,
        }"
        @calendar:change="setReceptionDate"
      />
    </div>

    <div class="margin-top-half">
      <div class="label-input">{{ $t('foodSamples.endDate') }}</div>
      <div class="recepDate marginTop5">
        <f7-input
          type="datepicker"
          :placeholder="$t('foodSamples.endDate')"
          :clear-button="true"
          :close-on-select="true"
          :value="calculatedHomeEndDate"
          :calendar-params="{
            closeOnSelect: true,
            rangePicker: true,
            dateFormat: {
              month: 'numeric',
              day: 'numeric',
              year: 'numeric',
            },
            disabled: isDisabled,
          }"
          @calendar:change="setEndDate"
        />
      </div>
    </div>
    <div class="margin-top-half">
      <div class="label-input">{{ $t('atc') }}</div>
      <DxTagBox
        :data-source="atcs"
        :multiline="true"
        placeholder="Tipo"
        tag-template="tag"
        display-expr="value"
        value-expr="id"
        :value="selectedATCs"
        :on-content-ready="contentReady"
        :on-value-changed="ATCsChanged"
        :on-opened="openedAtcs"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
    <div class="margin-top-half">
      <div class="label-input">
        {{ $t('agronomySample.summary.sampleType') }}
      </div>
      <DxTagBox
        :data-source="sampleTypes"
        :multiline="true"
        :placeholder="$t('agronomySample.summary.sampleType')"
        tag-template="tag"
        display-expr="value"
        value-expr="id"
        :search-enabled="true"
        :value="selectedSampleTypes"
        :on-value-changed="sampleTypesChanged"
        :on-opened="openedSampleTypes"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>

    <div class="margin-top-half">
      <div class="label-input">
        {{ $t('foodSamples.analysisType') }}
      </div>
      <DxTagBox
        :data-source="analyseTypes"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('foodSamples.analysisType')"
        tag-template="tag"
        display-expr="value"
        value-expr="value"
        :value="selectedAnalyseTypes"
        :on-value-changed="analyseTypesChanged"
        :on-opened="openedAnalyseTypes"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>

    <div class="margin-top-half">
      <div class="label-input">
        {{ $t('agronomySample.summary.status') }}
      </div>

      <DxTagBox
        :data-source="statuses"
        :multiline="true"
        :placeholder="$t('agronomySample.summary.status')"
        tag-template="tag"
        :value="selectedStatuses"
        display-expr="value"
        value-expr="id"
        :on-content-ready="contentReady"
        :on-opened="openedStatuses"
        :search-enabled="true"
        :on-value-changed="statusesChanged"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
  </div>
</template>
<script>
import { DxTagBox } from 'devextreme-vue/tag-box';
import { mapActions, mapState } from 'vuex';
import DataSource from 'devextreme/data/data_source';
import moment from 'moment';
import { AFTER_DATE_LIMIT } from '@/js/constants';

export default {
  components: { DxTagBox },
  data() {
    return {
      sampleTypes: [],
      atcs: [],
      statuses: [],
      analyseTypes: [],
      loaded: false,
    };
  },
  computed: {
    calculatedHomeReceptionDate() {
      if (
        this.receptionToDate !== null &&
        this.receptionFromDate !== null
      ) {
        return [this.receptionFromDate, this.receptionToDate];
      }
      return [];
    },
    calculatedHomeEndDate() {
      if (this.endToDate !== null && this.endFromDate !== null) {
        return [this.endFromDate, this.endToDate];
      }
      return [];
    },
    ...mapState('homeFilter', [
      'selectedStatuses',
      'selectedATCs',
      'selectedSampleTypes',
      'receptionFromDate',
      'receptionToDate',
      'endFromDate',
      'endToDate',
      'selectedAnalyseTypes',
    ]),
  },
  methods: {
    isDisabled(date) {
      return (
        moment(date).isBefore(moment(AFTER_DATE_LIMIT), 'day') ||
        moment(date).isAfter(moment(), 'day')
      );
    },
    sampleTypesChanged(e) {
      this.setSelectedSampleTypes(e.value);
    },
    statusesChanged(e) {
      this.setSelectedStatuses(e.value);
    },
    ATCsChanged(e) {
      this.setSelectedATCs(e.value);
    },
    analyseTypesChanged(e) {
      this.setSelectedAnalyseTypes(e.value);
    },
    async openedSampleTypes(e) {
      const sampleTypes = await this.getSampleFilter({
        atc: '',
        attribute: 'sample_type',
      });

      this.sampleTypes = new DataSource({
        store: sampleTypes,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedAnalyseTypes(e) {
      const analyseTypes = await this.getSampleFilter({
        atc: '',
        attribute: 'analyse_type',
      });

      this.analyseTypes = new DataSource({
        store: analyseTypes,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedAtcs(e) {
      const atcs = await this.getSampleFilter({
        atc: '',
        attribute: 'atc_name',
      });

      this.atcs = atcs.sort();
      this.loaded = true;
    },
    async openedStatuses(e) {
      const statuses = await this.getSampleFilter({
        atc: '',
        attribute: 'status',
      });

      this.statuses = statuses.sort();
      this.loaded = true;
    },
    contentReady(e) {
      const arrayNotFoundItems = Array.from(
        document.getElementsByClassName('dx-empty-message')
      );

      arrayNotFoundItems.forEach((element) => {
        if (!this.loaded) {
          element.innerHTML = this.$t('loading');
        } else {
          element.innerHTML = this.$t('dataNotAvailable');
        }
      });

      this.loaded = false;
    },
    setReceptionDate(e) {
      if (e.length === 2) {
        this.setReceptionFromDate(e[0]);
        this.setReceptionToDate(e[1]);
      } else if (e.length === 0) {
        this.setReceptionFromDate(null);
        this.setReceptionToDate(null);
      }
    },
    setEndDate(e) {
      if (e.length === 2) {
        this.setEndFromDate(e[0]);
        this.setEndToDate(e[1]);
      } else if (e.length === 0) {
        this.setEndFromDate(null);
        this.setEndToDate(null);
      }
    },
    ...mapActions('homeFilter', [
      'setSelectedStatuses',
      'setSelectedATCs',
      'setSelectedSampleTypes',
      'setReceptionFromDate',
      'setReceptionToDate',
      'setEndFromDate',
      'setEndToDate',
      'setSelectedAnalyseTypes',
    ]),
    ...mapActions('samples', ['getSampleFilter']),
  },
};
</script>
