<template>
  <f7-page>
    <div class="tableContainer">
      <div v-if="!loaded" class="loadingFalse">
        <div class="preloader">
          <span class="preloader-inner">
            <span class="preloader-inner-circle"></span>
          </span>
        </div>
      </div>
      <div v-else style="position: relative">
        <f7-row>
          <f7-col
            style="
              text-align: right;
              position: absolute;
              left: 0;
              top: 0;
            "
            width="10"
            class="marginBottom10"
          >
            <f7-button
              style="width: 100px"
              fill
              class="whiteFontColor"
              @click="goToStations"
            >
              {{ $t('stations') }}
            </f7-button>
          </f7-col>
        </f7-row>
      </div>
      <MIRGraphDatePicker />
      <MIRGraphHumidity v-if="loaded" />
      <MIRGraphDistancePicker v-if="loaded" />
      <MIRGraph
        v-if="loaded"
        :display-find-btn="false"
        :data-source="formatDsSensors"
      />
      <WeatherBarGraphMir
        v-if="loaded"
        :apply-class="false"
        :data-source="dataPluviometer"
        weather-element="pluviometer"
      />
    </div>

    <f7-row>
      <f7-col>
        <GoBack
          class="margin-top"
          :go-to-url-name="true"
          url-name="DashboardPage"
        />
      </f7-col>
    </f7-row>
  </f7-page>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import MIRGraph from '@/components/Agronomy/AdvancedServices/Dashboard/MIRGraph/index.vue';
import MIRGraphDatePicker from '@/components/Agronomy/AdvancedServices/Dashboard/MIRGraph/datePicker/index.vue';
import MIRGraphDistancePicker from '@/components/Agronomy/AdvancedServices/Dashboard/MIRGraph/distancePicker/index.vue';
import MIRGraphHumidity from '@/components/Agronomy/AdvancedServices/Dashboard/MIRGraphHumidity/index.vue';
import moment from 'moment';
import EventBus from '@/services/event-bus';
import WeatherBarGraphMir from '@/components/Agronomy/AdvancedServices/Dashboard/weather/WeatherBarGraphMir/index.vue';
import GoBack from '@/components/UtilityComponents/GoBack/index.vue';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  components: {
    MIRGraph,
    MIRGraphDatePicker,
    MIRGraphDistancePicker,
    MIRGraphHumidity,
    WeatherBarGraphMir,
    GoBack,
  },
  data() {
    return {
      loaded: false,
      loadedPluviometry: false,
      irrigationDs: [],
      stationId: null,
    };
  },
  computed: {
    ...mapState('registerFarm', ['farmStr']),
    formatDsSensors() {
      const avaliableDistances = [];
      const distances = [
        'SOIL_HUMIDITY_10CM',
        'SOIL_HUMIDITY_20CM',
        'SOIL_HUMIDITY_30CM',
        'SOIL_HUMIDITY_40CM',
        'SOIL_HUMIDITY_50CM',
        'SOIL_HUMIDITY_60CM',
        'SOIL_HUMIDITY_70CM',
        'SOIL_HUMIDITY_80CM',
      ];

      const data = [];

      for (const distance of distances) {
        if (
          this.dataSource[distance] != null &&
          this.dataSource[distance].length > 0
        ) {
          const number = distance.match(/\d+/g)[0];
          avaliableDistances.push(`${number} cm`);

          this.dataSource[distance].forEach((element) => {
            data.push({
              [distance]: element.value,
              date: element.date,
              source: element.available_soil_moisture,
            });
          });
        }
      }

      this.setAvaliableDistances(avaliableDistances);

      return data;
    },
    dataPluviometer() {
      const result = [];
      const seenDate = {};
      this.dataSource.irrigation_volume.forEach((element) => {
        const date = moment(element.date).format('YYYY-MM-DD');
        if (seenDate[date] == null) {
          result.push({
            ...element,
            date,
          });

          seenDate[date] = true;
        }
      });

      return result;
    },
    ...mapState('hidrosoph', [
      'initDate',
      'endDate',
      'dataSource',
      'irrigationInitDate',
      'irrigationEndDate',
      'selectedProbe',
      'pluviometerInitDate',
      'pluviometerEndDate',
    ]),
  },
  beforeMount() {
    this.setCurrentRoute([
      {
        name: this.$t('Agronomy'),
        disabled: false,
        page: 'AgronomySamplesPage',
        active: false,
      },
      {
        name: this.$t('advancedServices'),
        disabled: false,
        page: 'AdvancedServicesPage',
        active: false,
      },
      {
        name: this.farmStr,
        disabled: false,
        page: 'DashboardPage',
        active: false,
      },
      {
        name: `${this.$t('probesTitle')} MIR`,
        disabled: true,
        page: 'MIRStationsPage',
        active: true,
      },
    ]);
  },
  beforeDestroy() {
    EventBus.$off('MIRGraphDetailDateChanged');
    EventBus.$off('MIRGraphDetailIrrigationDateChanged');
    EventBus.$off('MIRGraphDetailPluviometerDateChanged');
  },
  async mounted() {
    this.loadedPluviometry = false;

    EventBus.$on('MIRGraphDetailDateChanged', async () => {
      this.loaded = false;

      try {
        setTimeout(() => {
          this.getStation();
        }, 0);

        setTimeout(async () => {
          this.$f7.preloader.show();

          const d = await this.getMirData({
            initDate: moment(this.initDate).format(
              'YYYY-MM-DD HH:mm:ss'
            ),
            endDate: moment(this.endDate).format(
              'YYYY-MM-DD HH:mm:ss'
            ),
            stationId: this.stationId,
          });
          this.setDataSource(d[0].data ?? {});
          this.loaded = true;
          this.$f7.preloader.hide();
        }, 1);
      } catch (e) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(e.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      } finally {
        this.$f7.preloader.hide();
      }

      this.preloader = true;
    });

    EventBus.$on('MIRGraphDetailIrrigationDateChanged', async () => {
      this.loaded = false;

      try {
        setTimeout(() => {
          this.getStation();
        }, 0);
        setTimeout(async () => {
          this.$f7.preloader.show();

          const d = await this.getMirData({
            initDate: moment(this.irrigationInitDate).format(
              'YYYY-MM-DD HH:mm:ss'
            ),
            endDate: moment(this.irrigationEndDate).format(
              'YYYY-MM-DD HH:mm:ss'
            ),
            stationId: this.stationId,
          });
          this.loaded = true;
          this.irrigationDs = this.formatDs(d);
          this.$f7.preloader.hide();
        }, 1);
      } catch (e) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(e.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      } finally {
        this.loaded = true;
      }
    });
  },
  methods: {
    goToStations() {
      this.$f7router.navigate(
        { name: 'MIRStationsPage' },
        {
          animate: true,
          transition: 'f7-fade',
        }
      );
    },
    getStation() {
      if (
        !this.$helpers.isEmptyObject(
          this.$f7router.currentRoute.query
        )
      ) {
        this.stationId = +this.$f7router.currentRoute.query.station;
      }
    },
    formatDs(data) {
      if (data[0][0].Data) {
        let formattedData = [];
        const allData = data[0][0].Data;
        for (const prop in allData) {
          allData[prop].forEach((el, index) => {
            formattedData.push({
              ...el,
              [`${prop}`]: el.value,
              source: data[0][0].Source,
            });
          });
        }

        formattedData.forEach((serie, index) => {
          for (const objProp in serie) {
            if (objProp.startsWith('SOIL')) {
              formattedData[index] = {
                ...formattedData[index],
                stress: this.getHidStress(formattedData).value,
                fieldCapacity: Number(
                  Math.abs(
                    this.getHidStress(formattedData).value -
                      this.getFieldCapacity(formattedData).value
                  ).toFixed(2)
                ),
              };
            }
          }
        });

        formattedData = formattedData.filter((serie) => {
          let isElligible = false;
          for (const prop in serie) {
            if (prop.startsWith('SOIL')) isElligible = true;
          }

          return isElligible;
        });

        return formattedData;
      }
    },
    getHidStress(ds) {
      let el = null;

      ds.forEach((serie) => {
        if (!el) {
          if (serie.INITIAL_STRESS) {
            el = serie;
          }
        }
      });

      return el;
    },
    getFieldCapacity(ds) {
      let el = null;

      ds.forEach((serie) => {
        if (!el) {
          if (serie.FIELD_CAPACITY) {
            el = serie;
          }
        }
      });

      return el;
    },
    getStartValue(ds) {
      const filteredDs = ds.filter(
        (serie) => serie[this.selectedProbe]
      );
      const sortedArr = filteredDs.sort((a, b) => a.date - b.date);

      return sortedArr[0];
    },
    formatParamsMeasured(data) {
      for (const param in data.measured.parameters) {
        const weatherElements = param.split('_');
        const weatherElementUnits = param.split('_');

        let weatherElement = null;
        let weatherElementUnit = null;

        if (weatherElements.length - 1 === 2) {
          weatherElement = `${weatherElements[0]}_${weatherElements[1]}`;
        } else {
          weatherElement = weatherElements[0];
        }
        if (weatherElementUnits.includes('MIN')) {
          weatherElementUnit = 'MIN';
        } else if (weatherElementUnits.includes('MAX')) {
          weatherElementUnit = 'MAX';
        }

        let key = '';
        if (!weatherElementUnit || !weatherElement) {
          key = `avgmeasured${param.toLowerCase()}`;
        } else {
          key = `${weatherElementUnit.toLowerCase()}measured${weatherElement.toLowerCase()}`;
        }

        if (key.endsWith('_avg')) {
          const index = key.indexOf('_avg');
          key = key.substring(0, index);
        } else if (key.endsWith('_min')) {
          const index = key.indexOf('_min');
          key = key.substring(0, index);
        } else if (key.endsWith('_max')) {
          const index = key.indexOf('_max');
          key = key.substring(0, index);
        }

        data.measured.parameters[param] = data.measured.parameters[
          param
        ].map((parameter) => {
          return {
            unit: parameter.unit,
            [key]: parameter.value,
            date: parameter.date,
          };
        });
      }

      const paramsFormatted = [];
      for (const param in data.measured.parameters) {
        paramsFormatted.push(...data.measured.parameters[param]);
      }

      return paramsFormatted;
    },
    formatParamsForecasted(data) {
      for (const param in data.forecast.parameters) {
        const weatherElements = param.split('_');
        const weatherElementUnits = param.split('_');

        let weatherElementUnit = null;
        let weatherElement = null;

        if (weatherElements.length - 1 === 2) {
          weatherElement = `${weatherElements[0]}_${weatherElements[1]}`;
        } else {
          weatherElement = weatherElements[0];
        }

        if (weatherElementUnits.includes('MIN')) {
          weatherElementUnit = 'MIN';
        } else if (weatherElementUnits.includes('MAX')) {
          weatherElementUnit = 'MAX';
        }

        let key = '';
        if (!weatherElementUnit || !weatherElement) {
          key = `avgforecast${param.toLowerCase()}`;
        } else {
          key = `${weatherElementUnit.toLowerCase()}forecast${weatherElement.toLowerCase()}`;
        }

        if (key.endsWith('_avg')) {
          const index = key.indexOf('_avg');
          key = key.substring(0, index);
        } else if (key.endsWith('_min')) {
          const index = key.indexOf('_min');
          key = key.substring(0, index);
        } else if (key.endsWith('_max')) {
          const index = key.indexOf('_max');
          key = key.substring(0, index);
        }

        data.forecast.parameters[param] = data.forecast.parameters[
          param
        ].map((parameter) => {
          return {
            unit: parameter.unit,
            [key]: parameter.value,
            date: parameter.date,
          };
        });
      }

      const paramsFormatted = [];
      for (const param in data.forecast.parameters) {
        paramsFormatted.push(...data.forecast.parameters[param]);
      }

      return paramsFormatted;
    },
    ...mapActions('breadcrumb', ['setCurrentRoute']),
    ...mapActions('hidrosoph', [
      'getMirData',
      'setDataSource',
      'setSelectedDistance',
      'setAvaliableDistances',
    ]),
    ...mapActions('weather', ['getClimateData']),
  },
};
</script>
