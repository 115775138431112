<template>
  <f7-page class="main-time-dimension-comparator">
    <div v-if="loadedGIS">
      <!-- Two Gis -->
      <div class="content-comparation">
        <!-- Item Map -->
        <div class="card">
          <div class="card-header color-primary">
            <div>{{ leftParcel.name }}</div>
          </div>
          <div class="card-content padding">
            <WMSMap
              :zoom="zoom"
              :name-map="`${mainMap}Left`"
              :layer="layerLeft"
              :index="currentIndexes[`${mainMap}Left`]"
              :height="'450px'"
              :center="leftParcel.coordinates"
            />
          </div>
          <div class="card-footer justify-content-center">
            <DatePicker
              :key-id="`${mainMap}Left`"
              :default-index="'NDVI'"
              :dates-list="datesListLeft"
            />
          </div>
        </div>
        <!-- Item Map -->
        <div class="card">
          <div class="card-header color-primary">
            <div>{{ rightParcel.name }}</div>
          </div>
          <div class="card-content padding">
            <WMSMap
              :zoom="zoom"
              :name-map="`${mainMap}Right`"
              :layer="layerRight"
              :index="currentIndexes[`${mainMap}Right`]"
              :height="'450px'"
              :center="rightParcel.coordinates"
            />
          </div>
          <div class="card-footer justify-content-center">
            <DatePicker
              :key-id="`${mainMap}Right`"
              :default-index="'NDVI'"
              :dates-list="datesListRight"
            />
          </div>
        </div>
      </div>
      <!-- /. Two Gis -->

      <div class="card">
        <div
          class="card-header color-primary display-flex justify-content-space-between align-items-center"
        >
          <div>{{ $t('temporalLine') }}</div>
          <div>
            <f7-input
              type="datepicker"
              class="recepDate"
              :placeholder="$t('agronomySample.chooseDates')"
              :clear-button="true"
              :close-on-select="true"
              :calendar-params="{
                value: [timeLineIndexInitDate, timeLineIndexEndDate],
                closeOnSelect: true,
                rangePicker: true,
                dateFormat: {
                  month: 'numeric',
                  day: 'numeric',
                  year: 'numeric',
                },
              }"
              @calendar:change="calendarChange"
            />
          </div>
        </div>
        <div class="card-content padding-half">
          <TimelineGraphWMS
            :timeline-indexes="timeLineIndexesWMS"
            :index="selectedIndex"
          />
        </div>
      </div>
      <f7-row class="padding">
        <f7-col>
          <GoBack
            :go-to-url-name="true"
            url-name="GisComparatorPage"
          />
        </f7-col>
      </f7-row>
    </div>
  </f7-page>
</template>

<script>
import Api from '@/services/Api';
import { mapActions, mapState } from 'vuex';
import WMSMap from '@/components/Gis/WMSMap/index.vue';
import DatePicker from '@/components/Gis/WMSMap/datePicker/index.vue';
import moment from 'moment';
import TimelineGraphWMS from '@/components/Gis/WMSMap/timeLineGraph/index.vue';
import GoBack from '@/components/UtilityComponents/GoBack/index.vue';

export default {
  components: {
    WMSMap,
    DatePicker,
    TimelineGraphWMS,
    GoBack,
  },
  data() {
    return {
      loadedGIS: false,
      loadedTimeLine: false,
      zoom: 17,
      mainMap: 'gis',
      datesListLeft: {},
      datesListRight: {},
      layerLeft: {
        name: 'gis',
        visible: false,
        timeDimension: true,
        baseUrl: Api.getGeoServerBesafer(),
        options: {
          layers: '',
          format: 'image/png',
          transparent: true,
          styles: 'NDVI',
        },
      },
      layerRight: {
        name: 'gis',
        visible: false,
        timeDimension: true,
        baseUrl: Api.getGeoServerBesafer(),
        options: {
          layers: '',
          format: 'image/png',
          transparent: true,
          styles: 'NDVI',
        },
      },
    };
  },
  computed: {
    ...mapState('gisComparator', [
      'leftComparator',
      'rightComparator',
      'leftParcel',
      'rightParcel',
      'timeLineIndexInitDate',
      'timeLineIndexEndDate',
    ]),
    ...mapState('gis', [
      'datesList',
      'currentIndexes',
      'timeLineIndexesWMS',
      'selectedIndex',
    ]),
  },
  beforeMount() {
    this.replaceCurrent({
      name: this.$t('comparative'),
      disabled: false,
      page: 'GisComparatorPage',
      active: true,
    });
  },
  async mounted() {
    this.$f7.preloader.show();
    this.setDates();
    try {
      this.loadedGIS = false;
      this.layerLeft.options.layers = `${Api.getGeoServerWorkspace()}:${
        this.leftComparator
      }`;
      this.layerRight.options.layers = `${Api.getGeoServerWorkspace()}:${
        this.rightComparator
      }`;
      await this.fetchDatesLayer(
        this.layerLeft.options.layers.split(':')[1]
      );
      this.datesListLeft = this.datesList;
      await this.fetchDatesLayer(
        this.layerRight.options.layers.split(':')[1]
      );
      this.datesListRight = this.datesList;
    } catch (error) {
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.$f7.preloader.hide();
      this.loadedGIS = true;
    }
  },
  methods: {
    setDates() {
      const today = new Date();
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

      this.setTimeLineIndexInitDate(threeMonthsAgo);
      this.setTimeLineIndexEndDate(today);
    },
    async calendarChange(e) {
      if (e.length === 2) {
        this.setTimeLineIndexInitDate(e[0]);
        this.setTimeLineIndexEndDate(e[1]);

        this.requestNewData();
      } else if (e.length === 0) {
        const today = new Date();
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
        this.setTimeLineIndexInitDate(threeMonthsAgo);
        this.setTimeLineIndexEndDate(today);

        this.requestNewData();
      }
    },
    async requestNewData() {
      this.loadedTimeLine = false;
      const timeLineIndexLeft = await this.fetchTimelineIndexesWMS({
        location: this.leftComparator,
        initDate: moment(this.timeLineIndexInitDate).format(
          'YYYY-MM-DD'
        ),
        endDate: moment(this.timeLineIndexEndDate).format(
          'YYYY-MM-DD'
        ),
        index: this.selectedIndex,
      });

      const leftIndexesFormatted = timeLineIndexLeft.map((el) => {
        return {
          ...el,
          values: el.values.map((value) => {
            return {
              index: value.index,
              count: value.count,
              [`${this.leftParcel.id} max`]: value.max,
              [`${this.leftParcel.id} mean`]: value.mean,
              [`${this.leftParcel.id} min`]: value.min,
              [`${this.leftParcel.id} stddev`]: value.stddev,
            };
          }),
        };
      });

      const timeLineIndexRight = await this.fetchTimelineIndexesWMS({
        location: this.rightComparator,
        initDate: moment(this.timeLineIndexInitDate).format(
          'YYYY-MM-DD'
        ),
        endDate: moment(this.timeLineIndexEndDate).format(
          'YYYY-MM-DD'
        ),
        index: this.selectedIndex,
      });

      const rightIndexesFormatted = timeLineIndexRight.map((el) => {
        return {
          ...el,
          values: el.values.map((value) => {
            return {
              index: value.index,
              count: value.count,
              [`${this.rightParcel.id} max`]: value.max,
              [`${this.rightParcel.id} mean`]: value.mean,
              [`${this.rightParcel.id} min`]: value.min,
              [`${this.rightParcel.id} stddev`]: value.stddev,
            };
          }),
        };
      });

      const timeLineIndexes = leftIndexesFormatted.concat(
        rightIndexesFormatted
      );

      this.setTimelineIndexesWMS(timeLineIndexes);
      this.loadedTimeLine = true;
    },
    ...mapActions('gis', [
      'setCurrentIndexes',
      'setCurrentInitIntervalDates',
      'setCurrentEndIntervalDates',
      'fetchDatesLayer',
      'fetchTimelineIndexesWMS',
      'setTimelineIndexesWMS',
    ]),
    ...mapActions('gisComparator', [
      'setTimeLineIndexInitDate',
      'setTimeLineIndexEndDate',
    ]),
    ...mapActions('breadcrumb', ['replaceCurrent']),
  },
};
</script>
<style>
.content-comparation {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
</style>
