<template>
  <div>
    <div
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
      "
    >
      <p>
        <strong>
          {{ $t('Lysimeter') }}
        </strong>
      </p>

      <div style="cursor: pointer" @click="openNewTab">
        <a
          href="#"
          target="_blank"
          style="
            font: 20px/1 DXIcons !important;
            pointer-events: none;
          "
          class="dx-link dx-icon-find dx-link-icon"
        ></a>
      </div>
    </div>
    <div class="tableContainer">
      <DxDataGrid
        :data-source="tableData"
        :show-borders="true"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-colon="false"
        :remote-operations="true"
        :hover-state-enabled="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
      >
        <DxScrolling mode="standard" />
        <DxPaging :page-size="10" />
        <DxPager
          :show-page-size-selector="true"
          :allowed-page-sizes="[5, 10, 20]"
          :show-info="true"
          info-text="{2} items"
          :visible="true"
        />
        <DxColumn
          data-field="date"
          :caption="$t('date')"
          alignment="left"
          data-type="date"
          format="dd/MM/yyyy"
          :allow-header-filtering="false"
        />
        <DxColumn
          data-field="sampleType"
          :caption="$t('agronomySample.summary.sampleType')"
          alignment="left"
          :allow-header-filtering="false"
        />
        <DxColumn
          data-field="ph"
          caption="pH"
          alignment="left"
          :allow-header-filtering="false"
        />
        <DxColumn
          data-field="ce"
          caption="CE"
          alignment="left"
          :allow-header-filtering="false"
        />
      </DxDataGrid>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPager,
  DxPaging,
  DxScrolling,
} from 'devextreme-vue/data-grid';

export default {
  name: 'LysimeterIoTPage',
  components: {
    DxDataGrid,
    DxColumn,
    DxPager,
    DxPaging,
    DxScrolling,
  },
  data() {
    return {
      tableData: [],
    };
  },
  computed: {
    ...mapState('nutritionalMonitoring', ['parcelId']),
    ...mapState('registerFarm', ['farmId']),
  },
  async mounted() {
    try {
      const data = await this.getSamplesWithParameterByFarmAndParcel({
        parcel_id: this.parcelId,
        farm_id: this.farmId,
      });

      data.forEach((item) => {
        const ph = item.parameters.find(
          (parameter) => parameter.parameter_id === 977
        );
        const ce = item.parameters.find(
          (parameter) => parameter.parameter_id === 391
        );

        this.tableData.push({
          date: item.sampling_date,
          sampleType: item.sample_type,
          ph: ph?.result_round ?? '',
          ce: ce?.result_round ?? '',
        });
      });
    } catch {
      this.tableData = [];
    }
  },
  methods: {
    openNewTab() {
      window.open(
        'https://dev.irristrat.com/lysimeter/login.php',
        '_blank'
      );
    },
    ...mapActions('nutritionalMonitoring', [
      'getSamplesWithParameterByFarmAndParcel',
    ]),
  },
};
</script>
