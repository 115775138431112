import Api from '@/services/Api';

export default {
  namespaced: true,
  state: {
    presupuestos: [], // Almacenará los presupuestos obtenidos del servicio
    detallesPresupuestos: [], // Almacenará las lineas de los detalles de los presupuestos
    detallesPresupuestosSummary: [], // Almacenará todo el objeto de los detalles de presupuestos
    detallesClientePresupuesto: [], // Almacenará el objeto de los detalles del cliente para el presupuesto que le pertenece
    detallesLineas: [], // Almacenará los detalles de las lineas de los presupuestos
    centroProduccion: [], // Almacenará las empresas para definir el centro de producción
    subFamilias: [], // Almacenará los PNTs y PTs de las subfamilias
    analisisTipoDescrip: [], // Almacenará los tipos de análisis para obtener la descripción
    detailsNoAnalyticsDescro: [],
    showDetailsPopup: false, // Controla la visibilidad del popup del detalles de los detalles del presupuesto
    showPtsPopup: false, // Controla la visibilidad del popup de los PTs
    popupTitle: '', // Almacena el titulo del popup
    divisa: '', // Almacena la divisa del presupuesto
    isQCA: false, // Controla si el presupuesto es QCA
    isQMT: false, // Controla si el presupuesto es QMT
  },
  actions: {
    async fetchPresupuestos({ commit, rootState }, estado = null) {
      try {
        const xhr = await Api.getOfferList(
          rootState.contact.company,
          estado
        );
        const response = JSON.parse(xhr.response);
        commit('setPresupuestos', response.presupuestos);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async fetchDetallesPresupuestos({ commit }, codigoPresupuesto) {
      try {
        const xhr = await Api.getOfferDetails(codigoPresupuesto);
        if (codigoPresupuesto.substring(0, 3) === 'QCA') {
          commit('setisQCA', true);
        } else {
          commit('setisQCA', false);
        }

        if (codigoPresupuesto.substring(0, 3) === 'QMT') {
          commit('setisQMT', true);
        } else {
          commit('setisQMT', false);
        }

        const response = JSON.parse(xhr.response);
        commit('setDetallesPresupuestos', response.Lineas);
        commit('setDetallesPresupuestosSummary', response);
        commit('setDivisa', response.CodDivisa);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async fetchDetallesPresupuestosSummary({ commit, rootState }) {
      try {
        const xhr = await Api.getOfferSummaryDetails(
          rootState.contact.company
        );
        const response = JSON.parse(xhr.response);
        commit('setDetallesClientePresupuesto', response);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async fetchDetallesLineas({ commit }, data) {
      try {
        const xhr = await Api.getLineDetails(data);
        const response = JSON.parse(xhr.response);
        commit('setDetallesLineas', response.parametros);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async fetchAnalisisTipo({ commit }, data) {
      try {
        const xhr = await Api.getAnalysisType(data);
        const response = JSON.parse(xhr.response);
        commit('setAnalisisTipoDescrip', response.analisisTipo);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async get_empresas_centro_produccion({ commit }) {
      try {
        const xhr = await Api.getProductionCenterCompanies();
        const response = JSON.parse(xhr.response);
        commit('setCentroProduccion', response);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    setPresupuestos({ commit }, value) {
      commit('setPresupuestos', value);
    },
    setDetallesPresupuestos({ commit }, value) {
      commit('setDetallesPresupuestos', value);
    },
    setDetallesPresupuestosSummary({ commit }, value) {
      commit('setDetallesPresupuestosSummary', value);
    },
    setDetallesClientePresupuesto({ commit }, value) {
      commit('setDetallesClientePresupuesto', value);
    },
    setShowDetailsPopup({ commit }, value) {
      commit('setShowDetailsPopup', value);
    },
    setShowPtsPopup({ commit }, value) {
      commit('setShowPtsPopup', value);
    },
    setisQCA({ commit }, value) {
      commit('setisQCA', value);
    },
    setisQMT({ commit }, value) {
      commit('setisQMT', value);
    },
    setPopupTitle({ commit }, value) {
      commit('setPopupTitle', value);
    },
    setDetallesLineas({ commit }, value) {
      commit('setDetallesLineas', value);
    },
    setCentroProduccion({ commit }, value) {
      commit('setCentroProduccion', value);
    },
    setDivisa({ commit }, value) {
      commit('setDivisa', value);
    },
    setSubFamilias({ commit }, value) {
      commit('setSubFamilias', value);
    },
    setAnalisisTipoDescrip({ commit }, value) {
      commit('setAnalisisTipoDescrip', value);
    },
    setDetailsNoAnalytics({ commit }, value) {
      commit('setDetailsNoAnalytics', value);
    },
    resetData({ commit }) {
      commit('resetData');
    },
  },
  mutations: {
    setPresupuestos(state, value) {
      state.presupuestos = value;
    },
    setDetallesPresupuestos(state, value) {
      state.detallesPresupuestos = value;
    },
    setDetallesPresupuestosSummary(state, value) {
      state.detallesPresupuestosSummary = value;
    },
    setDetallesClientePresupuesto(state, value) {
      state.detallesClientePresupuesto = value;
    },
    setShowDetailsPopup(state, value) {
      state.showDetailsPopup = value;
    },
    setShowPtsPopup(state, value) {
      state.showPtsPopup = value;
    },
    setisQCA(state, value) {
      state.isQCA = value;
    },
    setisQMT(state, value) {
      state.isQMT = value;
    },
    setPopupTitle(state, value) {
      state.popupTitle = value;
    },
    setDetallesLineas(state, value) {
      state.detallesLineas = value;
    },
    setCentroProduccion(state, value) {
      state.centroProduccion = value;
    },
    setSubFamilias(state, value) {
      state.subFamilias = value;
    },
    setAnalisisTipoDescrip(state, value) {
      state.analisisTipoDescrip = value;
    },
    setDivisa(state, value) {
      state.divisa = value;
    },
    setDetailsNoAnalytics(state, value) {
      state.detailsNoAnalyticsDescro = value;
    },
    resetData(state) {
      state.presupuestos = [];
      state.detallesPresupuestos = [];
      state.detallesPresupuestosSummary = [];
      state.detallesClientePresupuesto = [];
      state.showDetailsPopup = false;
      state.showPtsPopup = false;
      state.isQCA = false;
      state.isQMT = false;
      state.popupTitle = '';
      state.detallesLineas = [];
      state.centroProduccion = [];
      state.subFamilias = [];
      state.analisisTipoDescrip = [];
      state.divisa = '';
      state.detailsNoAnalyticsDescro = [];
    },
  },
};
