<template>
  <div>
    <div class="disclosure-section">
      <!-- <div class="disclosure-section-title">section 1</div> -->
      <div
        class="disclosure-section-atributtes-2"
        style="margin-top: 0.6rem"
      >
        <div>
          <label class="disclosure-section-label">
            {{ $t('geotoolbox.images') }}
          </label>
          <DxSelectBox
            v-model="image"
            :data-source="imagesList"
            display-expr="name"
            :search-enabled="true"
          >
            <template #item="{ data }">
              <div>
                <div :class="{ 'not-valid': false }">
                  {{ data.name }}
                </div>
              </div>
            </template>
          </DxSelectBox>
        </div>
      </div>
    </div>
    <div class="disclosure-buttons-interpolation">
      <button
        class="button-interpolate"
        type="button"
        @click="comparate()"
      >
        {{ $t('geotoolbox.execute') }}
      </button>
    </div>
  </div>
</template>

<script>
import { DxSelectBox } from 'devextreme-vue/select-box';
import EventBus from '@/services/event-bus';

export default {
  name: 'ToolInterpolation',
  components: {
    DxSelectBox,
  },
  props: {
    images: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      image: null,
      selectKey: 'select-key',
      imagesList: [],
    };
  },
  mounted() {
    this.imagesList = Object.values(this.images);

    EventBus.$on('add-interpolation', () => {
      setTimeout(() => {
        this.imagesList = Object.values(this.images);
      }, 200);
    });
  },
  methods: {
    comparate() {
      if (this.image === null) {
        this.$notifyDX(
          {
            message: this.$t(
              'gisActions.toolComparation.notSelected'
            ),
            width: 550,
          },
          'error',
          1000
        );
        return;
      }

      const key = `${this.image.type}-${this.image.rasterImageName}`;
      const index = Object.keys(this.images).indexOf(key);

      EventBus.$emit('selected-layer-to-compare', {
        geometry: this.image.geometry,
        images: Object.values(this.images),
        selected: index,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '../GeoToolBoxTab.styles.scss';
</style>
