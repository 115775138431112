<template>
  <div class="margin-top">
    <div class="label-input">{{ $t('date') }}</div>
    <div class="recepDate marginTop5">
      <f7-input
        class="shadow"
        type="datepicker"
        :placeholder="$t('date')"
        :clear-button="true"
        :close-on-select="true"
        :value="calculatedDates"
        :calendar-params="{
          closeOnSelect: true,
          rangePicker: true,
          dateFormat: {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
          },
        }"
        @calendar:change="setDates"
      />
    </div>

    <div class="margin-top-half">
      <div class="label-input">{{ $t('dataFrequency') }}</div>
      <DxSelectBox
        :items="dataFrequency"
        :value="selectedFrequency"
        :on-value-changed="setFrequency"
        display-expr="value"
        value-expr="key"
        :on-content-ready="contentReady"
        :show-clear-button="true"
      >
      </DxSelectBox>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import DxSelectBox from 'devextreme-vue/select-box';

export default {
  components: { DxSelectBox },
  data() {
    return {
      loaded: false,
      dataFrequency: [{ value: this.$t('daily'), key: 'DAY' }],
    };
  },
  computed: {
    calculatedDates() {
      if (this.initDate !== null && this.endDate !== null) {
        return [this.initDate, this.endDate];
      }
      return [];
    },
    ...mapState('weatherGraphFilter', [
      'initDate',
      'endDate',
      'selectedFrequency',
    ]),
  },
  methods: {
    contentReady(e) {
      const arrayNotFoundItems = Array.from(
        document.getElementsByClassName('dx-empty-message')
      );

      arrayNotFoundItems.forEach((element) => {
        if (!this.loaded) {
          element.innerHTML = this.$t('loading');
        } else {
          element.innerHTML = this.$t('dataNotAvailable');
        }
      });

      this.loaded = false;
    },
    setFrequency(e) {
      this.setSelectedFrequency(e.value);
    },
    setDates(e) {
      if (e.length === 2) {
        this.setInitDate(e[0]);
        this.setEndDate(e[1]);
      } else if (e.length === 0) {
        this.setInitDate(null);
        this.setEndDate(null);
      }
    },
    ...mapActions('weatherGraphFilter', [
      'setInitDate',
      'setEndDate',
      'setSelectedFrequency',
    ]),
  },
};
</script>
