<template>
  <div>
    <DxChart id="chart" :data-source="ds.data">
      <DxCommonSeriesSettings
        argument-field="month"
        type="stackedbar"
      />

      <DxValueAxis position="left" :tick-interval="1">
        <DxTitle :text="$t('stats.numSamples')" />
      </DxValueAxis>

      <DxSeries
        v-if="
          selectedSampleTypes.length >= 1 && aggregatedData.length > 0
        "
        :name="aggregatedData[0].sample_type + ' > LMR'"
        color="#FF6384"
        :value-field="
          `nSuperiorLmrs_` + aggregatedData[0].sample_type
        "
        stack="first"
      />

      <DxSeries
        v-if="
          selectedSampleTypes.length >= 1 && aggregatedData.length > 0
        "
        :name="
          aggregatedData[0].sample_type + ' - ' + $t('positives')
        "
        color="#FFCE56"
        :value-field="`nPositives_` + aggregatedData[0].sample_type"
        stack="first"
      />

      <DxSeries
        v-if="
          selectedSampleTypes.length >= 1 && aggregatedData.length > 0
        "
        :name="aggregatedData[0].sample_type + ' - ' + $t('samples')"
        :value-field="`nSamples_` + aggregatedData[0].sample_type"
        color="#ffeda0"
        stack="first"
      />

      <DxSeries
        v-if="
          selectedSampleTypes.length >= 2 && aggregatedData.length > 0
        "
        :name="aggregatedData[1].sample_type + ' > LMR'"
        color="#9c88ff"
        :value-field="
          `nSuperiorLmrs_` + aggregatedData[1].sample_type
        "
        stack="second"
      />

      <DxSeries
        v-if="
          selectedSampleTypes.length >= 2 && aggregatedData.length > 0
        "
        :name="
          aggregatedData[1].sample_type + ' - ' + $t('positives')
        "
        color="#4BC0C0"
        :value-field="`nPositives_` + aggregatedData[1].sample_type"
        stack="second"
      />

      <DxSeries
        v-if="
          selectedSampleTypes.length >= 2 && aggregatedData.length > 0
        "
        :name="aggregatedData[1].sample_type + ' - ' + $t('samples')"
        color="#6a89cc"
        :value-field="`nSamples_` + aggregatedData[1].sample_type"
        stack="second"
      />

      <DxSeries
        v-if="
          selectedSampleTypes.length >= 3 && aggregatedData.length > 0
        "
        :name="aggregatedData[2].sample_type + ' > LMR'"
        color="#badc58"
        :value-field="
          `nSuperiorLmrs_` + aggregatedData[2].sample_type
        "
        stack="third"
      />

      <DxSeries
        v-if="
          selectedSampleTypes.length >= 3 && aggregatedData.length > 0
        "
        :name="
          aggregatedData[2].sample_type + ' - ' + $t('positives')
        "
        color="#7bed9f"
        :value-field="`nPositives_` + aggregatedData[2].sample_type"
        stack="third"
      />

      <DxSeries
        v-if="
          selectedSampleTypes.length >= 3 && aggregatedData.length > 0
        "
        :name="aggregatedData[2].sample_type + ' - ' + $t('samples')"
        color="#ced6e0"
        :value-field="`nSamples_` + aggregatedData[2].sample_type"
        stack="third"
      />

      <DxSeries
        v-if="
          selectedSampleTypes.length >= 4 && aggregatedData.length > 0
        "
        :name="aggregatedData[3].sample_type + ' > LMR'"
        color="#70a1ff"
        :value-field="
          `nSuperiorLmrs_` + aggregatedData[3].sample_type
        "
        stack="fourth"
      />

      <DxSeries
        v-if="
          selectedSampleTypes.length >= 4 && aggregatedData.length > 0
        "
        :name="
          aggregatedData[3].sample_type + ' - ' + $t('positives')
        "
        color="#9ecae1"
        :value-field="`nPositives_` + aggregatedData[3].sample_type"
        stack="fourth"
      />

      <DxSeries
        v-if="
          selectedSampleTypes.length >= 4 && aggregatedData.length > 0
        "
        color="#deebf7"
        :name="aggregatedData[3].sample_type + ' - ' + $t('samples')"
        :value-field="`nSamples_` + aggregatedData[3].sample_type"
        stack="fourth"
      />
      <DxSeries
        v-if="
          selectedSampleTypes.length >= 5 && aggregatedData.length > 0
        "
        :name="aggregatedData[4].sample_type + ' > LMR'"
        color="#ff4757"
        :value-field="
          `nSuperiorLmrs_` + aggregatedData[4].sample_type
        "
        stack="fifth"
      />

      <DxSeries
        v-if="
          selectedSampleTypes.length >= 5 && aggregatedData.length > 0
        "
        :name="
          aggregatedData[4].sample_type + ' - ' + $t('positives')
        "
        color="#ffb8b8"
        :value-field="`nPositives_` + aggregatedData[4].sample_type"
        stack="fifth"
      />

      <DxSeries
        v-if="
          selectedSampleTypes.length >= 5 && aggregatedData.length > 0
        "
        color="#1dd1a1"
        :name="aggregatedData[4].sample_type + ' - ' + $t('samples')"
        :value-field="`nSamples_` + aggregatedData[4].sample_type"
        stack="fifth"
      />

      <DxLegend
        :customize-items="customizeItems"
        vertical-alignment="bottom"
        :column-count="3"
        horizontal-alignment="center"
        item-text-position="top"
      />

      <DxExport :enabled="true" />
      <DxTooltip
        :enabled="true"
        :z-index="999999"
        :customize-tooltip="customizeTooltip"
      />
    </DxChart>
  </div>
</template>

<script>
import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxValueAxis,
  DxTitle,
  DxLegend,
  DxExport,
  DxTooltip,
} from 'devextreme-vue/chart';
import { mapState } from 'vuex';

export default {
  components: {
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxValueAxis,
    DxTitle,
    DxLegend,
    DxExport,
    DxTooltip,
  },
  data() {
    return {
      samplesStack: [],
    };
  },
  computed: {
    ds() {
      const data = [];
      const samples = [];
      this.aggregatedData.map((el) => {
        samples.push(el.sample_type);

        const formattedData = el.stats.map((monthData, index) => {
          const obj = {
            ...monthData.current,
            month: `${new Date(
              monthData.current.year,
              monthData.month === null
                ? 'Invalid'
                : monthData.month - 1,
              1
            )
              .toLocaleString(this.languageInterface, {
                month: 'long',
              })
              .capitalize()} ${monthData.current.year}`,
            sample_type: el.sample_type,
            sample_type_id: el.sample_type_id,
            n_samples_original: monthData.current.n_samples,
            n_positives_original: monthData.current.n_positives,
          };

          obj[`nSamples_${el.sample_type}`] =
            obj.n_samples - obj.n_positives;
          obj[`nPositives_${el.sample_type}`] =
            obj.n_positives - obj.n_lmrs;

          obj[`nSuperiorLmrs_${el.sample_type}`] = obj.n_lmrs;

          return obj;
        });

        data.push(
          ...formattedData.filter((d) => !d.month.includes('Invalid'))
        );
      });

      return { data, samples };
    },
    ...mapState('user', ['languageInterface']),
    ...mapState('foodStats', ['aggregatedData']),
    ...mapState('foodStatsFilter', [
      'selectedSampleTypes',
      'sampleTypesIdName',
    ]),
  },
  methods: {
    customizeTooltip(pointInfo) {
      const isSamples =
        pointInfo.point._options.valueField.includes('nSamples');

      const isPositives =
        pointInfo.point._options.valueField.includes('nPositives');

      if (isSamples) {
        return {
          html: `<p>${this.$t('samples')}: ${
            pointInfo.point.data.n_samples_original
          }</p>`,
        };
      }
      if (isPositives) {
        return {
          html: `<p>${this.$t('positives')}: ${
            pointInfo.point.data.n_positives_original
          }</p>`,
        };
      }
      return {
        html: `> LMR: ${pointInfo.point.data.n_lmrs}</p>`,
      };
    },
    customizeItems(items) {
      /** const filteredElements = items.filter(
        (v, i, a) => a.findIndex((t) => t.text === v.text) === i
      ); */

      return items;
    },
  },
};
</script>
