<template>
  <div>
    <div
      :id="`${nameMap}_${initialDate}`"
      :style="{ height: height + 'px', 'border-radius': '5px' }"
    ></div>
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import { mapState, mapActions } from 'vuex';
import EventBus from '@/services/event-bus';
import Api from '../../../services/Api';

export default {
  props: {
    height: {
      type: Number,
      default: 620,
    },
    zoom: { type: Number, default: 1 },
    selectedMinHeight: { type: String, default: '50vh' },
    nameMap: { type: String, default: '' },
    treeLocationsEstate: {
      type: Array,
      default: () => [],
    },
    treeLocationsParcels: {
      type: Array,
      default: () => [],
    },
    center: {
      type: Array,
      default: () => null,
    },
    rasterLayer: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentLayerBase: {},
      event: 'updateGIS',
      map: null,
      layerBounds: null,
      groupBounds: null,
      initialDate: Date.now(),
      locationProperties: {},
      actualDate: new Date(),
      menu: {
        areas: {},
        images: {},
      },
      areaColorIndex: 0,
    };
  },
  computed: {
    getAreaColor() {
      const colors = [
        // Rose
        // '#fecdd3',
        '#fda4af',
        '#fb7185',
        '#f43f5e',
        '#e11d48',
        '#be123c',
        '#9f1239',
        '#881337',
        // Cyan
        // '#a5f3fc',
        '#67e8f9',
        '#22d3ee',
        '#06b6d4',
        '#0e7490',
        '#155e75',
        '#164e63',
      ];
      // random color from array
      return colors[this.areaColorIndex % colors.length];
    },

    ...mapState('gis', ['osm']),
    ...mapState('gisEditor', ['tempParcelGeoFeatures']),
    ...mapState('registerFarm', ['parcelsMirRs']),
    ...mapState('contact', ['enabled']),
    ...mapState('registerFarm', ['farm', 'farmStr']),
  },
  mounted() {
    if (this.treeLocationsParcels.length > 0) {
      this.renderMap();
      this.addFuncionalities();
      this.addOverlayLayer();
    } else {
      this.renderMap();
      this.addFuncionalities();
      this.locateUser();
    }
  },
  methods: {
    locateUser() {
      this.map
        .locate({ setView: true, maxZoom: 16 })
        .on('locationfound', (e) => {
          const marker = new L.Marker(e.latlng, {
            draggable: false,
          }).addTo(this.map);
          const group = L.featureGroup();
          group.addLayer(marker);
          this.groupBounds = group.getBounds();

          const redIcon = new L.Icon({
            iconUrl:
              'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-violet.png',
            shadowUrl:
              'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41],
          });
          marker.setLatLng(e.latlng);

          marker.setIcon(redIcon);
        })
        .on('locationerror', (error) => {
          this.map.setView(
            [41.6164989248106, -4.873517847881886],
            13
          );
        });
    },
    /**
     * Añadimos una capa tanto de tipo GeoJson como WMS a la lista de capas activas
     */
    async addOverlayLayer() {
      this.$f7.preloader.show();
      try {
        let layerParcel;
        const layerStyles = {
          default: {
            color: 'rgba(255,0,224,0.7)',
            weight: 1,
            fillOpacity: 0.5,
          },
          active: {
            weight: 3,
            color: 'rgba(241,234,234,0.71)',
            dashArray: '',
            fillOpacity: 0.7,
          },
          selected: {
            weight: 2,
            color: 'rgba(250,250,120,0.71)',
            dashArray: '',
            fillOpacity: 0.7,
          },
        };
        let treeLocationIds = {};
        const group = L.featureGroup().addTo(this.map);
        // this.rasterLayer.options.pane = 'raster';
        // const rasterLayer = L.tileLayer
        //   .wms(this.rasterLayer.baseUrl, this.rasterLayer.options)
        //   .addTo(this.map);

        const layerEstate = L.geoJson(this.treeLocationsEstate, {
          style: () => {
            return {
              color: '#6366f1',
              fillOpacity: 0,
            };
          },
          onEachFeature: (feat, layer) => {
            const id = Math.random().toString(36).substring(3);

            feat.properties.parcel_name = this.farmStr;
            feat.properties.color = '#6366f1';
            feat.properties.isFarm = true;

            this.menu.areas[id] = {
              ...feat.properties,
              id: feat.properties.id,
              name: this.farmStr,
              color: '#6366f1',
              checked: true,
              layer,
            };
          },
        });

        group.addLayer(layerEstate);
        // this.treeLocationsParcels.push(this.tempParcelGeoFeatures);

        if (this.enabled) {
          setTimeout(() => {
            this.treeLocationsParcels.forEach((parcel) => {
              if (Array.isArray(parcel)) {
                parcel.forEach((tempParcel) => {
                  if (
                    !isNaN(
                      tempParcel.features[0].properties.parcel_id
                    )
                  ) {
                    tempParcel.features[0].properties.rs =
                      this.parcelsMirRs.find(
                        (parcelMirRs) =>
                          parcelMirRs.parcel_id ===
                          +tempParcel.features[0].properties.parcel_id
                      ).rs;
                  }
                });
              } else if (
                !isNaN(parcel.features[0].properties.parcel_id)
              ) {
                parcel.features[0].properties.rs =
                  this.parcelsMirRs.find(
                    (parcelMirRs) =>
                      parcelMirRs.parcel_id ===
                      +parcel.features[0].properties.parcel_id
                  ).rs;
              }
            });
          }, 0);
        }

        const parcels = this.treeLocationsParcels.concat(
          this.tempParcelGeoFeatures
        );

        for (const geoData of parcels) {
          const color = this.getAreaColor;
          this.areaColorIndex += 1;

          geoData.features[0].properties.color = color;

          layerParcel = L.geoJson(geoData, {
            style: (feat) => {
              return {
                color: color || '#D8DEDA',
                fillColor: feat.properties?.fillColor || color,
                fillOpacity: 0,
              };
            },
            onEachFeature: (feat, layer) => {
              const id = Math.random().toString(36).substring(3);

              this.menu.areas[id] = {
                ...feat.properties,
                id: feat.properties.id,
                name: feat.properties.parcel_name,
                color,
                checked: true,
                layer,
              };
            },
          });
          group.addLayer(layerParcel);

          const bounds = layerParcel.getBounds();
          this.layerBounds = bounds;

          treeLocationIds = {};

          // this.layersEvents(layerParcel, treeLocationIds);
          /** layer2 = L.geoJson(geoData.children_geofeatures).addTo(
            this.map
          );
          layer2.setStyle(layerStyles.active);
          treeLocationIds = {};
          this.layersEvents(layer2, treeLocationIds); */
        }

        this.$emit('menu-areas', this.menu.areas);

        EventBus.$on('toggle-area', (data) => {
          if (data.checked) {
            data.layer.getElement().style.display = 'block';
          } else {
            data.layer.getElement().style.display = 'none';
          }
        });

        for (const rasterId of parcels) {
          const id = `besafer:${rasterId.features[0].properties.gis_id}`;

          if (this.enabled) {
            if (isNaN(+rasterId.features[0].properties.parcel_id)) {
              continue;
            }

            if (
              !this.parcelsMirRs.find(
                (parcelMirRs) =>
                  parcelMirRs.parcel_id ===
                  +rasterId.features[0].properties.parcel_id
              ).rs
            ) {
              continue;
            }
          }

          const rasterOptions = {
            layers: id,
            format: 'image/png',
            transparent: true,
            styles: 'NDVI',
            pane: 'raster',
          };
          // L.tileLayer
          //   .wms(this.rasterLayer.baseUrl, rasterOptions)
          //   .addTo(this.map)
          //   .setOpacity(1);

          const randomId = Math.random().toString(36).substring(3);

          this.menu.images[randomId] = {
            name: `NDVI - ${rasterId.features[0].properties.parcel_name}`,
            layer: L.tileLayer
              .wms(this.rasterLayer.baseUrl, rasterOptions)
              .addTo(this.map)
              .setOpacity(1),
            checked: true,
          };
        }

        this.$emit('menu-images', this.menu.images);

        EventBus.$on('toggle-image', (data) => {
          if (data.checked) {
            data.layer.setOpacity(1);
          } else {
            data.layer.setOpacity(0);
          }
        });

        group.on('click', (e) => {
          const { layer } = e;

          const style = `background: ${layer.feature.properties.color};`;
          const title = layer.feature.properties.parcel_name;
          const id = Math.random().toString(36).substring(7);
          const isFarm = layer.feature.properties.isFarm || false;

          if (isFarm) {
            L.popup()
              .setLatLng(e.latlng)
              .setContent(
                `
              <div class="popup-title" style="${style}">${title}</div>
              `
              )
              .openOn(this.map);
          } else {
            L.popup()
              .setLatLng(e.latlng)
              .setContent(
                `
                <div class="popup-title" style="${style}">${title}</div>
                <div class="popup-buttons-polygon">
                  <button id="comparation-${id}" class="btn-comparation">
                    <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="m6 18h-3c-.48 0-1-.379-1-1v-14c0-.481.38-1 1-1h14c.621 0 1 .522 1 1v3h3c.621 0 1 .522 1 1v14c0 .621-.522 1-1 1h-14c-.48 0-1-.379-1-1zm1.5-10.5v13h13v-13zm9-1.5v-2.5h-13v13h2.5v-9.5c0-.481.38-1 1-1z" fill-rule="nonzero"/>
                    </svg>
                  </button>
                  <button id="nutritional-${id}" class="btn-nutritional">
                    <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m15.97 17.031c-1.479 1.238-3.384 1.985-5.461 1.985-4.697 0-8.509-3.812-8.509-8.508s3.812-8.508 8.509-8.508c4.695 0 8.508 3.812 8.508 8.508 0 2.078-.747 3.984-1.985 5.461l4.749 4.75c.146.146.219.338.219.531 0 .587-.537.75-.75.75-.192 0-.384-.073-.531-.22zm-5.461-13.53c-3.868 0-7.007 3.14-7.007 7.007s3.139 7.007 7.007 7.007c3.866 0 7.007-3.14 7.007-7.007s-3.141-7.007-7.007-7.007z" fill-rule="nonzero"/></svg>
                  </button>
                </div>
              `
              )
              .openOn(this.map);

            const buttonNutritional = document.getElementById(
              `nutritional-${id}`
            );

            const buttonComparation = document.getElementById(
              `comparation-${id}`
            );

            if (buttonNutritional) {
              buttonNutritional.addEventListener('click', () => {
                const { id, gis_id } = layer.feature.properties;
                if (id !== undefined) {
                  this.setParcelId(id);
                  this.setParcelGisId(gis_id);
                  this.$f7router.navigate(
                    { name: 'TimeDimensionMapPage' },
                    {
                      animate: true,
                      transition: 'f7-fade',
                    }
                  );
                }
              });
            }

            if (buttonComparation) {
              buttonComparation.addEventListener('click', () => {
                const { id, gis_id } = layer.feature.properties;
                if (id !== undefined) {
                  this.setParcelId(id);
                  this.setParcelGisId(gis_id);
                  this.$f7router.navigate(
                    {
                      name: 'GisComparatorPage',
                    },
                    {
                      animate: true,
                      transition: 'f7-fade',
                    }
                  );
                }
              });
            }
          }
        });

        if (this.center !== null) {
          this.map.setView(
            [this.center.latitude, this.center.longitude],
            this.zoom
          );
        } else {
          const bounds = group.getBounds();
          this.groupBounds = bounds;
          this.map.fitBounds(bounds);
        }
      } catch (error) {
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },

    /**
     * Centramos el mapa cuando tenemos un GeoJson
     */
    centerMapGeoJsonLayer(layer) {
      const bounds = layer.getBounds();
      this.layerBounds = bounds;
      this.map.flyToBounds(bounds);
    },

    layersEvents(target, treeLocationIds) {
      const self = this;
      target.eachLayer((layer) => {
        layer.on({
          click(e) {
            L.DomEvent.stopPropagation(e);
            self.locationProperties = {};
            self.openLayerDataPopup(
              e.target.feature.properties,
              e,
              treeLocationIds
            );
          },
          dblclick(e) {},
          mouseover(e) {},
          mouseout(e) {
            self.map.closePopup(e);
          },
        });
      });
    },

    renderMap() {
      this.map = L.map(`${this.nameMap}_${this.initialDate}`, {
        minZoom: 12,
        maxZoom: 18,
      });
      L.tileLayer(
        this.osm.googleHybrid.route,
        this.osm.googleHybrid.properties
      ).addTo(this.map);
      this.currentLayerBase = L.tileLayer(
        this.osm.googleHybrid.route,
        this.osm.googleHybrid.properties
      ).addTo(this.map);
      this.currentLayerBase.bringToBack();
      this.map.createPane('raster');
      this.map.getPane('raster').style.pointerEvents = 'none';
      this.map.getPane('raster').style.zIndex = 399;
    },

    getDefaultStyle() {
      return {
        color: '#D8DEDA',
        weight: 5,
        opacity: 0.65,
        fillOpacity: 0,
      };
    },

    addFuncionalities() {
      const self = this;
      try {
        L.control.scale().addTo(self.map);
        self.map.attributionControl.addAttribution(
          'Margaret from <a href="https://hispatecanalytics.com//">HispatecAnalytics SA</a>'
        );
        this.addHomeButton();
        this.addLegend();
      } catch (e) {
        this.$f7.dialog.alert(e);
      }
    },

    addHomeButton() {
      const self = this;
      L.Control.zoomHome = L.Control.extend({
        options: {
          position: 'topleft',
          zoomHomeText: '<i class="f7-icons">house_fill</i>',
          zoomHomeTitle: 'Ver geometrías',
        },
        onAdd() {
          const controlName = 'gin-control-zoom';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          this._zoomHomeButton = this.createButton(
            options.zoomHomeText,
            options.zoomHomeTitle,
            `${controlName}-home`,
            container,
            this.zoomHome
          );

          return container;
        },

        zoomHome() {
          self.map.fitBounds(self.groupBounds);
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      const zoomHome = new L.Control.zoomHome();
      zoomHome.addTo(self.map);
    },
    addLegend() {
      const self = this;
      L.Control.legend = L.Control.extend({
        options: {
          position: 'bottomright',
        },
        onAdd() {
          self.map.legend = this;

          const container = L.DomUtil.create(
            'div',
            'legend-control-container'
          );

          if (this.options.content) {
            container.innerHTML = this.options.content;
          }
          return container;
        },
        onRemove() {
          delete self.map.legend;
        },

        setContent(str) {
          this.getContainer().innerHTML = str;
        },
      });
      // eslint-disable-next-line new-cap
      const legend = new L.Control.legend();
      legend.addTo(self.map);
      this.indexLayer();
    },
    async openLayerDataPopup(properties, e, treeLocationIds) {
      this.locationProperties = {
        ...properties,
        ...treeLocationIds,
      };

      let htmlString =
        (this.enabled && properties.rs === false) ||
        (isNaN(properties.parcel_id) && this.enabled)
          ? `
      <div class='popup-content'>
        <div>
          <p>

          <b>${this.$t('agronomySample.summary.parcel')}:</b> ${
              properties.parcel_name
            }
          </p>
            <b>${this.$t('accountable')}:</b> ${
              properties.accountable
            }
            </p>

          </div>
          </div>
      `
          : `
      <div class='popup-content'>
        <div>
          <p>

          <b>${this.$t('agronomySample.summary.parcel')}:</b> ${
              properties.parcel_name
            }
          </p>
            <b>${this.$t('accountable')}:</b> ${
              properties.accountable
            }
            </p>
            <a
              id='parcelBtn'
              data-parcel="${properties.id}"
              data-parcel-name="${properties.parcel_name}"
              style="display: flex; justify-content: flex-end"
              class="dx-link dx-icon-find dx-link-icon"
            ></a>
          </div>
          </div>`;

      const htmlStringSector = ``;
      if (typeof treeLocationIds.sectorId !== 'undefined') {
        htmlString += htmlStringSector;
      }

      htmlString += `
        </div>
      </div>`;
      this.openPopup(htmlString, e.latlng, treeLocationIds);

      setTimeout(() => {
        const parcel = document.getElementById('parcelBtn');

        parcel.addEventListener('click', (e) => {
          const id = e.target.getAttribute('data-parcel');
          const name = e.target.getAttribute('data-parcel-name');
          if (id !== 'undefined') {
            this.setParcelId(id);
            this.setParcelStr(name);
            this.$f7router.navigate(
              { name: 'TimeDimensionMapPage' },
              {
                animate: true,
                transition: 'f7-fade',
              }
            );
          }
        });
      }, 0);
    },
    getLayerDataPropertiesHtml(properties) {
      let listHtml = '';
      for (const property in properties) {
        // eslint-disable-next-line no-continue
        if (properties[property] == null) continue;
        if (typeof properties[property] === 'object') {
          listHtml += this.getLayerDataPropertiesHtml(
            properties[property]
          );
        } else {
          listHtml += `<li><b>${property}</b>: ${properties[property]}</li>`;
        }
      }
      return listHtml;
    },
    openPopup(html, latlng, treeLocationIds) {
      this.map.openPopup(html, latlng, {
        maxHeight: 4000,
      });
    },
    indexLayer() {
      const legendContent = `<h6 class="legend-index-name">NDVI</h6><img src=${Api.getGeoServerProjectWorkSpace()}REQUEST=GetLegendGraphic&VERSION=2.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=10&LAYER=${
        this.rasterLayer.options.layers.split(':')[1]
      }&style=NDVI&legend_options=fontName:Times%20New%20Roman;fontAntiAliasing:true;fontColor:0x000033;fontSize:8;bgColor:0xFFFFFF;dpi:91&SCALE=1001; style="opacity:0.8";>`;

      this.map.legend.setContent(legendContent);
    },
    ...mapActions('registerFarm', [
      'setParcelId',
      'setParcelStr',
      'setParcelGisId',
    ]),
  },
};
</script>
<style lang="scss">
@import './Map.styles.scss';
</style>
