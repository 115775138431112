<template>
  <div class="margin-top">
    <div class="label-input">{{ $t('notifications.date') }}</div>
    <div class="recepDate marginTop5">
      <f7-input
        class="shadow"
        type="datepicker"
        :placeholder="$t('notifications.date')"
        :clear-button="true"
        :close-on-select="true"
        :value="calculatedNotificationDate"
        :calendar-params="{
          closeOnSelect: true,
          rangePicker: true,
          dateFormat: {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
          },
        }"
        @calendar:change="setNotificationDate"
      />
    </div>

    <div class="margin-top-half label-input">
      {{ $t('alert.area') }}
    </div>
    <div class="marginTop5">
      <DxTagBox
        :data-source="atcs"
        :multiline="true"
        :placeholder="$t('alert.area')"
        tag-template="tag"
        :value="selectedAtcs"
        display-expr="value"
        value-expr="id"
        :on-value-changed="atcsChanged"
        :on-opened="openedAtcs"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>

    <div class="label-input margin-top-half">
      {{ $t('agronomySample.summary.sampleType') }}
    </div>
    <div class="marginTop5">
      <DxTagBox
        :data-source="sampleTypes"
        :multiline="true"
        :placeholder="$t('agronomySample.summary.sampleType')"
        tag-template="tag"
        :value="selectedSampleTypes"
        :on-value-changed="sampleTypesChanged"
        :on-opened="openedSampleTypes"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
  </div>
</template>

<script>
import { DxTagBox } from 'devextreme-vue/tag-box';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'NotificationsDetailFilter',
  components: {
    DxTagBox,
  },
  data() {
    return {
      loaded: false,
      atcs: [],
      sampleTypes: [],
    };
  },
  computed: {
    calculatedNotificationDate() {
      if (
        this.notificationToDate !== null &&
        this.notificationFromDate !== null
      ) {
        return [this.notificationFromDate, this.notificationToDate];
      }
      return [];
    },
    ...mapState('notificationsDetailFilter', [
      'notificationToDate',
      'notificationFromDate',
      'selectedAtcs',
      'selectedSampleTypes',
    ]),
  },
  methods: {
    setNotificationDate(e) {
      if (e.length === 2) {
        this.setNotificationFromDate(e[0]);
        this.setNotificationToDate(e[1]);
      } else if (e.length === 0) {
        this.setNotificationFromDate(null);
        this.setNotificationToDate(null);
      }
    },
    atcsChanged(e) {
      this.setSelectedAtcs(e.value);
    },
    sampleTypesChanged(e) {
      this.setSelectedSampleTypes(e.value);
    },
    async openedAtcs(e) {
      const atcs = await this.getAttributeList('atc');

      this.atcs = atcs.sort();
      this.loaded = true;
    },
    async openedSampleTypes(e) {
      const sampleTypes = await this.getAttributeList('sample_type');

      this.sampleTypes = sampleTypes.sort();
      this.loaded = true;
    },
    contentReady(e) {
      const arrayNotFoundItems = Array.from(
        document.getElementsByClassName('dx-empty-message')
      );

      arrayNotFoundItems.forEach((element) => {
        if (!this.loaded) {
          element.innerHTML = this.$t('loading');
        } else {
          element.innerHTML = this.$t('dataNotAvailable');
        }
      });

      this.loaded = false;
    },
    ...mapActions('notificationsDetailFilter', [
      'setNotificationToDate',
      'setNotificationFromDate',
      'setSelectedAtcs',
      'setSelectedSampleTypes',
    ]),
    ...mapActions('notifications', ['getAttributeList']),
  },
};
</script>
