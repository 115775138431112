<template>
  <div v-if="loaded">
    <div class="top-selectors">
      <div class="date-selector">
        <DxDateBox
          display-format="dd-MM-yyyy"
          picker-type="calendar"
          type="date"
          width="100%"
          :accept-custom-value="false"
          :open-on-field-click="true"
          :value="lastDate"
          :disabled-dates="disabledDates"
          @value-changed="onChangeInitDate"
        >
          <DxCalendarOptions zoom-level="decade" />
        </DxDateBox>
      </div>
    </div>

    <DxTabPanel
      class="normal-tabs"
      :data-source="[
        {
          id: 0,
          text: $t('expertSystemTiles.panel'),
          selectedIndex: 1,
        },
        {
          id: 1,
          text: $t('expertSystemTiles.tabla'),
          selectedIndex: 2,
        },
      ]"
      :loop="false"
      :animation-enabled="true"
      :swipe-enabled="false"
    >
      <template #title="{ data: company }">
        <span>{{ company.text }}</span>
      </template>
      <template #item="{ data: company }">
        <div v-if="company.selectedIndex === 1" class="header">
          <div class="card">
            <div class="card-header">
              {{ $t('plantStatus') }}
            </div>
            <div class="card-content">
              <f7-row no-gap>
                <f7-col class="custom-card-table">
                  <ExpertSystemTile
                    v-for="(data, index) in mockData"
                    :key="`${keyFirstTile}-${index}`"
                    :parent="data.parent"
                    :children="data.children"
                  />
                </f7-col>
                <f7-col>
                  <Legends v-if="chartData.length !== 0"></Legends>
                  <DxPieChart
                    id="pie"
                    :data-source="chartData"
                    :palette="chartPalette"
                  >
                    <DxSeries
                      argument-field="state"
                      value-field="value"
                    >
                      <DxLabel
                        :visible="true"
                        :customize-text="formatLabel"
                        position="columns"
                      >
                        <DxConnector :visible="true" :width="0.5" />
                        <DxFont :size="16" color="black" />
                      </DxLabel>
                    </DxSeries>
                    <DxLegend
                      :visible="false"
                      :column-count="4"
                      orientation="horizontal"
                      item-text-position="right"
                      horizontal-alignment="center"
                      vertical-alignment="bottom"
                    />
                    <DxSize :height="300" />
                  </DxPieChart>
                </f7-col>
              </f7-row>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              {{ $t('fertilizationStatus') }}
            </div>
            <div class="card-content">
              <f7-row>
                <f7-col class="custom-card-table">
                  <ExpertSystemTile
                    v-for="(data, index) in mockData2"
                    :key="`${keySecondTile}-${index}`"
                    :parent="data.parent"
                    :children="data.children"
                  />
                </f7-col>
                <f7-col>
                  <Legends v-if="chartData2.length !== 0"></Legends>
                  <DxPieChart
                    id="pie"
                    :data-source="chartData2"
                    :palette="chartPalette2"
                  >
                    <DxSeries
                      argument-field="state"
                      value-field="value"
                    >
                      <DxLabel
                        :visible="true"
                        :customize-text="formatLabel"
                        position="columns"
                      >
                        <DxConnector :visible="true" :width="0.5" />
                        <DxFont :size="16" color="black" />
                      </DxLabel>
                    </DxSeries>
                    <DxLegend
                      :visible="false"
                      :column-count="4"
                      orientation="horizontal"
                      item-text-position="right"
                      horizontal-alignment="center"
                      vertical-alignment="bottom"
                    />
                    <DxSize :height="300" />
                  </DxPieChart>
                </f7-col>
              </f7-row>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="padding-half border-bottom">
            {{ $t('diagnosisCorrectiveMeasures') }}
          </div>
          <DiagnosisTable />
        </div>
      </template>
    </DxTabPanel>
  </div>
</template>

<script>
import { DxTabPanel } from 'devextreme-vue/tab-panel';
import {
  DxDateBox,
  DxCalendarOptions,
} from 'devextreme-vue/date-box';
import ExpertSystemTile from '@/components/Agronomy/AdvancedServices/ExpertSystem/Tile/index.vue';
import DiagnosisTable from '@/components/Agronomy/AdvancedServices/ExpertSystem/DiagnosisTable/index.vue';
import Legends from '@/components/Agronomy/Legends/Legends.vue';

import DxPieChart, {
  DxSeries,
  DxLegend,
  DxLabel,
  DxConnector,
  DxFont,
  DxSize,
} from 'devextreme-vue/pie-chart';
import { mapActions, mapState } from 'vuex';
import moment from 'moment';

export default {
  name: 'ExpertSystemPage',
  components: {
    DxTabPanel,
    ExpertSystemTile,
    DiagnosisTable,
    Legends,
    DxDateBox,
    DxCalendarOptions,
    DxPieChart,
    DxSeries,
    DxLegend,
    DxLabel,
    DxConnector,
    DxFont,
    DxSize,
  },
  data() {
    return {
      seslectedDate: null,
      displayMode: true,
      avaiableDates: {},
      formattedData: {},
      mappedDates: {
        month: null,
        year: null,
        decade: null,
        century: null,
      },
      keyFirstTile: 'key-first-tile',
      keySecondTile: 'key-second-tile',
      lastDate: null,
      profileId: null,
      loaded: false,
    };
  },
  computed: {
    ...mapState('nutritionalMonitoring', [
      'parcelId',
      'varietyId',
      'cultiveId',
      'samplingDateProfile',
    ]),
    ...mapState('registerFarm', ['farmId']),
    mockData() {
      return [
        {
          parent: {
            name: this.$t('expertSystemTiles.macronutrients'),
            type: 1,
          },
          children: [
            {
              id: 1,
              name: 'N',
              type: this.getItem('3111').alert,
              visible: false,
              value: this.getItem('3111').dop,
              ...this.getItem('3111'),
            },
            {
              id: 2,
              name: 'P',
              type: this.getItem('3112').alert,
              visible: false,
              value: this.getItem('3112').dop,
              ...this.getItem('3112'),
            },
            {
              id: 3,
              name: 'K',
              type: this.getItem('3113').alert,
              visible: false,
              value: this.getItem('3113').dop,
              ...this.getItem('3113'),
            },
            {
              id: 4,
              name: 'Ca',
              type: this.getItem('3114').alert,
              visible: false,
              value: this.getItem('3114').dop,
              ...this.getItem('3114'),
            },
            {
              id: 5,
              name: 'Mg',
              type: this.getItem('3115').alert,
              visible: false,
              value: this.getItem('3115').dop,
              ...this.getItem('3115'),
            },
            {
              id: 55,
              name: 'S',
              type: this.getItem('3116').alert,
              visible: false,
              value: this.getItem('3116').dop,
              ...this.getItem('3116'),
            },
          ],
        },
        {
          parent: {
            name: this.$t('expertSystemTiles.micronutrients'),
            type: 2,
          },
          children: [
            {
              id: 6,
              name: 'Fe',
              type: this.getItem('3121').alert,
              visible: false,
              value: this.getItem('3121').dop,
              ...this.getItem('3121'),
            },
            {
              id: 7,
              name: 'Mn',
              type: this.getItem('3122').alert,
              visible: false,
              value: this.getItem('3122').dop,
              ...this.getItem('3122'),
            },
            {
              id: 8,
              name: 'Zn',
              type: this.getItem('3123').alert,
              visible: false,
              value: this.getItem('3123').dop,
              ...this.getItem('3123'),
            },
            {
              id: 832,
              name: 'B',
              type: this.getItem('3125').alert,
              visible: false,
              value: this.getItem('3125').dop,
              ...this.getItem('3125'),
            },
            {
              id: 8322,
              name: 'Cu',
              type: this.getItem('3124').alert,
              visible: false,
              value: this.getItem('3124').dop,
              ...this.getItem('3124'),
            },
            {
              id: 684553,
              name: 'Mo',
              type: this.getItem('3126').alert,
              visible: false,
              value: this.getItem('3126').dop,
              ...this.getItem('3126'),
            },
          ],
        },
        {
          parent: {
            name: this.$t('expertSystemTiles.fitotoxics'),
            type: 3,
          },
          children: [
            {
              id: 9,
              name: 'Na',
              type: this.getItem('3142').alert,
              visible: false,
              value: this.getItem('3142').dop,
              ...this.getItem('3142'),
            },
            {
              id: 10,
              name: 'Cl',
              type: this.getItem('3141').alert,
              visible: false,
              value: this.getItem('3141').dop,
              ...this.getItem('3141'),
            },
            {
              id: 11,
              name: 'Al',
              type: this.getItem('3143').alert,
              visible: false,
              value: this.getItem('3143').dop,
              ...this.getItem('3143'),
            },
          ],
        },
      ];
    },
    mockData2() {
      return [
        {
          parent: {
            name: this.$t('expertSystemTiles.salinity'),
            type: 4,
          },
          children: [
            {
              id: 1,
              name: 'CE',
              type: this.getItem('1211').alert,
              visible: false,
              value: this.getItem('1211').dop,
              ...this.getItem('1211'),
            },
            {
              id: 1,
              name: 'Lav.',
              type: this.getItem('1411').alert,
              visible: false,
              value: this.getItem('1411').dop,
              ...this.getItem('1411'),
            },
          ],
        },
        {
          parent: {
            name: this.$t('expertSystemTiles.fertilization'),
            type: 5,
          },
          children: [
            {
              id: 771,
              name: 'pH',
              type: this.getItem('2111').alert,
              visible: false,
              value: this.getItem('2111').dop,
              ...this.getItem('2111'),
            },
            {
              id: 361,
              name: 'N',
              type: this.getItem('2211').alert,
              visible: false,
              value: this.getItem('2211').dop,
              ...this.getItem('2211'),
            },
            {
              id: 34561,
              name: 'P',
              type: this.getItem('2221').alert,
              visible: false,
              value: this.getItem('2221').dop,
              ...this.getItem('2221'),
            },
            {
              id: 1436,
              name: 'K',
              type: this.getItem('2231').alert,
              visible: false,
              value: this.getItem('2231').dop,
              ...this.getItem('2231'),
            },
            {
              id: 34561345,
              name: 'Ca',
              type: this.getItem('2241').alert,
              visible: false,
              value: this.getItem('2241').dop,
              ...this.getItem('2241'),
            },
            {
              id: 3461653,
              name: 'Mg',
              type: this.getItem('2251').alert,
              visible: false,
              value: this.getItem('2251').dop,
              ...this.getItem('2251'),
            },
          ],
        },
        {
          parent: {
            name: this.$t('expertSystemTiles.leaching'),
            type: 6,
          },
          children: [
            {
              id: 432425621,
              name: 'NO₃',
              type: this.getItem('2311').alert,
              visible: false,
              value: this.getItem('2311').dop,
              ...this.getItem('2311'),
            },
          ],
        },
        {
          parent: {
            name: this.$t('expertSystemTiles.phytotoxicity'),
            type: 7,
          },
          children: [
            {
              id: 132312,
              name: 'Na',
              type: this.getItem('1511').alert,
              visible: false,
              value: this.getItem('1511').dop,
              ...this.getItem('1511'),
            },
            {
              id: 23211,
              name: 'Cl',
              type: this.getItem('1521').alert,
              visible: false,
              value: this.getItem('1521').dop,
              ...this.getItem('1521'),
            },
          ],
        },
      ];
    },
    chartData() {
      if (Object.values(this.formattedData).length === 0) return [];

      const elements = [
        3111, 3112, 3113, 3114, 3115, 3116, 3121, 3122, 3123, 3125,
        3124, 3126, 3142, 3141, 3143,
      ];

      const counter = {
        2: { state: 'Alarma', value: 0, color: '#de7e7e' },
        1: { state: 'Alerta', value: 0, color: '#ffd966' },
        0: { state: 'Adecuado', value: 0, color: '#D9F0C0' },
        4: { state: 'NA', value: 0, color: '#d1d5db' },
      };

      const data = Object.values(this.formattedData);

      for (let i = 0; i < data.length; i += 1) {
        if (elements.includes(data[i].eval_id)) {
          counter[parseInt(data[i].alert, 10)].value += 1;
        }
      }

      return Object.values(counter).filter((item) => item.value > 0);
    },
    chartPalette() {
      return (
        Object.values(this.chartData).map((item) => item.color) ?? []
      );
    },
    chartData2() {
      if (Object.values(this.formattedData).length === 0) return [];

      const elements = [
        1211, 1411, 2111, 2211, 2221, 2231, 2241, 2251, 2311, 1511,
        1521,
      ];

      const counter = {
        2: { state: 'Alarma', value: 0, color: '#de7e7e' },
        1: { state: 'Alerta', value: 0, color: '#ffd966' },
        0: { state: 'Adecuado', value: 0, color: '#D9F0C0' },
        4: { state: 'NA', value: 0, color: '#d1d5db' },
      };

      const data = Object.values(this.formattedData);

      for (let i = 0; i < data.length; i += 1) {
        if (elements.includes(data[i].eval_id)) {
          counter[parseInt(data[i].alert, 10)].value += 1;
        }
      }

      return Object.values(counter).filter((item) => item.value > 0);
    },
    chartPalette2() {
      return (
        Object.values(this.chartData2).map((item) => item.color) ?? []
      );
    },
  },
  async mounted() {
    try {
      this.clearFilters();
      this.$f7.preloader.show();
      await Promise.all([this.fetchDates(), this.fetchProfileId()]);
      await this.getNutritionalProfileEval();
      this.loaded = true;
    } catch (error) {
      this.$f7.dialog.alert(error.message);
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    async fetchDates() {
      return this.getAvaiableDatesForExpertSystem({
        farmId: this.farmId,
        parcelId: this.parcelId,
      })
        .then((response) => {
          const dates = response?.data;
          if (dates != null) {
            this.avaiableDates = dates.reduce((acc, date) => {
              acc[moment(date.sampling_date).format('YYYY-MM-DD')] =
                date;
              return acc;
            }, {});

            this.lastDate =
              moment(response?.data[0]?.sampling_date) ?? null;
          }
          return Promise.resolve();
        })
        .catch((error) => {
          Promise.reject(error);
        });
    },
    async fetchProfileId() {
      return this.getNutritionalProfile({
        farm_id: this.farmId,
        cultive_id: this.cultiveId,
        variety_id: this.varietyId,
        parcel_id: this.parcelId,
        sampling_date: this.samplingDateProfile,
      })
        .then((response) => {
          if (response.data.length === 0) {
            this.$f7.dialog.alert(
              this.$t('notNutritionalProfileDate')
            );
            return Promise.reject();
          }
          this.profileId = response.data[0].profile_id;
          return Promise.resolve();
        })
        .catch((error) => {
          Promise.reject(error);
        });
    },
    getItem(id) {
      const data = this.formattedData?.[id];
      if (data == null) {
        return {
          alert: 4,
          dop: '',
          item: '',
          diagnostic: '',
          amendment: '',
        };
      }
      return {
        alert:
          parseInt(data.alert, 10) === 4
            ? 4
            : parseInt(data.alert, 10) + 1,
        dop: parseInt(data.dop, 10),
        item: data.item,
        diagnostic: data.diagnostic,
        amendment: data.amendment,
      };
    },
    async onChangeInitDate(e) {
      if (typeof e.event === 'undefined') return;
      this.selectedDate =
        this.avaiableDates[
          moment(e.value).format('YYYY-MM-DD')
        ]?.profile_id;
      await this.getNutritionalProfileEval();

      this.keyFirstTile = `key-tile-${Math.floor(
        Math.random() * 1000
      )}`;

      this.keySecondTile = `key-tile-${Math.floor(
        Math.random() * 1000
      )}`;
    },
    async getNutritionalProfileEval() {
      if (!this.profileId) return;
      this.$f7.preloader.show();
      try {
        const response = await this.getNutritionalProfileEvaluation({
          id: this.profileId,
          params: null,
        });

        const data = response.map((item) => {
          item.eval_id = Number(item.tag.replace(/[^0-9]/g, '')) ?? 0;
          return item;
        });

        this.setTableData(data);

        this.formattedData = data.reduce((acc, element) => {
          acc[element.eval_id] = {
            alert: element.alert ?? 4,
            dop: element.dop ?? 0,
            module_seq: parseInt(element.module_seq, 10),
            eval_id: parseInt(element.eval_id, 10),
            item: element.item ?? '',
            diagnostic: element.diagnostic ?? '',
            amendment: element.amendment ?? '',
          };
          return acc;
        }, {});
      } catch (error) {
        this.$f7.dialog.alert(error.message);
      } finally {
        this.$f7.preloader.hide();
      }
    },
    changeDisplayMode() {
      this.displayMode = !this.displayMode;
    },
    formatLabel(pointInfo) {
      return `${pointInfo.argumentText} (${pointInfo.percentText})`;
    },
    disabledDates(args) {
      if (args.view === 'century') {
        if (this.mappedDates.century == null) {
          this.mappedDates.century = {};
          Object.values(this.avaiableDates).forEach((currentDate) => {
            this.mappedDates.century[
              parseInt(
                parseInt(moment(currentDate).format('YYYY')) / 10
              ) * 10
            ] = true;
          });
        } else {
          return !(
            this.mappedDates.century[
              parseInt(
                parseInt(moment(args.date).format('YYYY')) / 10
              ) * 10
            ] ?? false
          );
        }
      }
      if (args.view === 'decade') {
        if (this.mappedDates.decade == null) {
          this.mappedDates.decade = {};
          Object.values(this.avaiableDates).forEach((currentDate) => {
            this.mappedDates.decade[
              moment(currentDate.sampling_date).format('YYYY')
            ] = true;
          });
        } else {
          return !(
            this.mappedDates.decade[
              moment(args.date).format('YYYY')
            ] ?? false
          );
        }
      }

      if (args.view === 'year') {
        if (this.mappedDates.year == null) {
          this.mappedDates.year = {};
          Object.values(this.avaiableDates).forEach((currentDate) => {
            this.mappedDates.year[
              moment(currentDate.sampling_date).format('YYYY-MM')
            ] = true;
          });
        } else {
          return !(
            this.mappedDates.year[
              moment(args.date).format('YYYY-MM')
            ] ?? false
          );
        }
      }

      if (this.mappedDates.month == null) {
        this.mappedDates.month = {};
        Object.values(this.avaiableDates).forEach((currentDate) => {
          this.mappedDates.month[
            moment(currentDate.sampling_date).format('YYYY-MM-DD')
          ] = true;
        });
      } else {
        return (
          !this.mappedDates.month[
            moment(args.date).format('YYYY-MM-DD')
          ] ?? false
        );
      }
    },
    ...mapActions('advancedServices', [
      'getAvaiableDatesForExpertSystem',
    ]),
    ...mapActions('nutritionalMonitoring', [
      'getNutritionalProfileEvaluation',
      'getNutritionalProfile',
    ]),
    ...mapActions('expertSystemFilter', [
      'setTableData',
      'clearFilters',
    ]),
  },
};
</script>

<style scoped lang="scss">
@import './ExpertSystem.styles.scss';

.normal-tabs {
  ::v-deep .dx-tab {
    border-bottom: 1px solid #cccccc;
    width: 170px !important;
  }

  ::v-deep .dx-tabs-wrapper {
    display: table-row !important;
  }
}
</style>
