<template>
  <f7-block>
    <f7-row>
      <f7-col>
        <div class="ha-card card">
          <div class="ha-card__title">
            {{ $t('personal_information') }}
          </div>
          <div class="ha-card__content">
            <DxForm
              id="user-personal-info"
              class="ha-form"
              :col-count="1"
              :form-data="user"
            >
              <DxSimpleItem
                class="ha-form__first-name"
                :validation-rules="validationRules.name"
                :editor-options="{ disabled: false }"
                data-field="name"
              >
                <DxLabel :text="$t('name')" />
              </DxSimpleItem>

              <DxSimpleItem
                class="ha-form__last-name"
                :validation-rules="validationRules.surname"
                :editor-options="{ disabled: false }"
                data-field="surname"
              >
                <DxLabel :text="$t('surname')" />
              </DxSimpleItem>

              <DxSimpleItem
                class="ha-form__email"
                data-field="email"
                :validation-rules="validationRules.email"
                :editor-options="{ disabled: false }"
              >
                <DxLabel :text="$t('email')" />
              </DxSimpleItem>

              <DxSimpleItem
                class="ha-form__phone"
                :editor-options="{
                  mask: '+34 000-000-000',
                  maskRules: { X: /[02-9]/ },
                }"
                data-field="phone"
              >
                <DxLabel :text="$t('phone')" />
              </DxSimpleItem>
              <DxSimpleItem
                :editor-options="{
                  value: user.role,
                  searchEnabled: true,
                  items: roles.roles,
                  onValueChanged: roleChanged,
                }"
                :validation-rules="validationRules.role"
                data-field="role"
                editor-type="dxSelectBox"
              >
                <DxLabel :text="$t('role')" />
              </DxSimpleItem>
            </DxForm>
          </div>
        </div>
      </f7-col>
      <f7-col>
        <div class="ha-card ha-card--addresses card">
          <div class="ha-card__title">
            {{ $t('addresses') }}
          </div>
          <div class="ha-card__content">
            <AddressesTreeList />
          </div>
        </div>
      </f7-col>
    </f7-row>
    <div class="ha-card ha-card--permissions card">
      <div class="ha-card__title">
        {{ $t('permissions') }}
      </div>
      <f7-row>
        <f7-col>
          <f7-row>
            <f7-col>
              <div class="ha-card card">
                <PermissionsTreeList
                  :permissions-available="agronomyPermissions"
                  permissions-tree-list-of="Agronomy"
                />
              </div>
            </f7-col>
            <f7-col>
              <div class="ha-card card">
                <PermissionsTreeList
                  :permissions-available="alimentaryPermissions"
                  permissions-tree-list-of="Alimentary"
                />
              </div>
            </f7-col>
            <f7-col>
              <div class="ha-card card">
                <PermissionsTreeList
                  :permissions-available="miningPermissions"
                  permissions-tree-list-of="Mining"
                />
              </div>
            </f7-col>
            <f7-col>
              <div class="ha-card card">
                <PermissionsTreeList
                  :permissions-available="environmentPermissions"
                  permissions-tree-list-of="Environment"
                />
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
      </f7-row>
    </div>
    <f7-row>
      <div class="flex-center">
        <DxButton
          :width="240"
          :text="$t('create_user')"
          type="success"
          styling-mode="contained"
          :disabled="areAllRequiredFieldsFullfilled"
          @click="createNewUser"
        />
      </div>
    </f7-row>
  </f7-block>
</template>

<script>
import { DxForm, DxSimpleItem, DxLabel } from 'devextreme-vue/form';
import DxButton from 'devextreme-vue/button';
import * as overlay from 'devextreme/ui/overlay';
import roles from '@/json/roles.json';
import agronomyPermissions from '@/json/agronomyPermissions';
import alimentaryPermissions from '@/json/alimentaryPermissions';
import environmentPermissions from '@/json/environmentPermissions';
import miningPermissions from '@/json/miningPermissions';

import AddressesTreeList from '@/components/UserForm/AddressesTreeList';
import PermissionsTreeList from '@/components/UserForm/PermissionsTreeList.vue';

import { createNamespacedHelpers } from 'vuex';

const { mapState, mapActions } = createNamespacedHelpers('company');

export default {
  components: {
    DxForm,
    DxSimpleItem,
    DxButton,
    AddressesTreeList,
    PermissionsTreeList,
    DxLabel,
  },
  data() {
    return {
      user: {
        name: '',
        surname: '',
        email: '',
        role: '',
      },
      roles,
      recursive: true,
      agronomyPermissions,
      alimentaryPermissions,
      environmentPermissions,
      miningPermissions,
      validationRules: {
        email: [
          {
            type: 'required',
            message: `${this.$t('email_required')}`,
          },
        ],
        name: [
          {
            type: 'required',
            message: `${this.$t('name_required')}`,
          },
        ],
        surname: [
          {
            type: 'required',
            message: `${this.$t('surname_required')}`,
          },
        ],
        role: [
          {
            type: 'required',
            message: `${this.$t('role_required')}`,
          },
        ],
      },
    };
  },
  computed: {
    ...mapState(['addressesSelectedRowKeys']),
    areAllRequiredFieldsFullfilled() {
      if (
        this.user.name !== '' &&
        this.user.surname !== '' &&
        this.user.email !== '' &&
        this.user.role !== '' &&
        this.addressesSelectedRowKeys.length > 0
      ) {
        return false;
      }
      return true;
    },
  },
  beforeMount() {
    overlay.baseZIndex(9999);
    this.clearPermissionsTrees();
    this.clearAddresses();
  },
  beforeDestroy() {
    this.disablePermissionsTrees();
  },
  methods: {
    ...mapActions([
      'updateAgronomySelectedKeys',
      'updateAlimentarySelectedKeys',
      'updateMiningSelectedKeys',
      'updateEnvironmentSelectedKeys',
      'enablePermissionsTrees',
      'clearPermissionsTrees',
      'clearAddresses',
      'createUser',
      'disablePermissionsTrees',
    ]),
    roleChanged() {
      const selectedRole = this.user.role;
      const selectedATC = selectedRole.split(' ')[0].toLowerCase();
      switch (selectedATC) {
        case 'agronomy':
          this.updateAgronomySelectedKeys([1]);
          break;

        case 'alimentary':
          this.updateAlimentarySelectedKeys([1]);
          break;

        case 'mining':
          this.updateMiningSelectedKeys([1]);
          break;

        case 'environment':
          this.updateEnvironmentSelectedKeys([1]);
          break;
        case 'custom':
          this.enablePermissionsTrees();
          break;
        default:
          break;
      }
    },
    async createNewUser() {
      const self = this;
      try {
        this.$f7.dialog.preloader(
          `${this.$t('adding_user')}`,
          'primary'
        );
        await this.createUser(this.user);
        setTimeout(() => {
          self.$f7.dialog.close();
        }, 1000);
        this.disablePermissionsTrees();
        this.$f7.dialog.alert(`${this.$t('user_added')}`);
        this.$f7router.navigate('/users', {
          animate: true,
          transition: 'f7-fade',
        });
      } catch (error) {
        this.$f7.dialog.close();
        this.$f7.dialog.alert(`${this.$t(error.errorCodes[0])}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './UserForm.styles.scss';

.flex-center {
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
