<template>
  <div class="geotoolbox-disclosure">
    <div class="disclosure-title" @click="showMenu = !showMenu">
      <div class="disclosure-title-text">
        {{ title }}
      </div>
      <button class="menu-title-arrow" type="button">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          style="width: 1.1rem; height: 1.1rem"
          :class="[showMenu ? 'rotate-180' : 'rotate-0']"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          />
        </svg>
      </button>
    </div>
    <div v-show="showMenu" class="disclosure-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GeotoolboxDisclosure',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showMenu: false,
    };
  },
};
</script>

<style scoped lang="scss">
@import './GeotoolboxDisclosure.styles.scss';
</style>
