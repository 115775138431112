var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('DxPopup',{attrs:{"visible":_vm.showNewAlertSamplePopup,"drag-enabled":false,"close-on-outside-click":false,"show-close-button":true,"show-title":true,"width":"800","height":"auto","container":".dx-viewport","title":!_vm.editData.isEditing
        ? `${_vm.$t('alert.register')} - ${_vm.$t('sample')}`
        : `${_vm.$t('alert.edit')} - ${_vm.$t('sample')}`},on:{"hiding":_vm.hide}},[_c('DxScrollView',{attrs:{"scroll-by-content":true,"scroll-by-thumb":true,"show-scrollbar":"onScroll","use-native":true,"bounce-enabled":true}},[_c('DxForm',{staticClass:"ha-form",staticStyle:{"padding":"20px"},attrs:{"col-count":"2","form-data":_vm.formData}},[_c('DxItem',{attrs:{"editor-options":{
            items: _vm.formData.areas,
            onValueChanged: _vm.areaChanged,
            value: _vm.selectedArea,
            width: '230px',
            placeholder: _vm.$t('select'),
          },"data-field":"","editor-type":"dxSelectBox"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('alert.requiredArea')}}),_vm._v(" "),_c('DxLabel',{attrs:{"text":_vm.$t('alert.area')}})],1),_vm._v(" "),_c('DxItem',{attrs:{"editor-options":{
            items: _vm.formData.farms,
            onValueChanged: _vm.farmChanged,
            displayExpr: 'name',
            valueExpr: 'name',
            value: _vm.selectedFarm,
            width: '230px',
            placeholder: _vm.$t('select'),
          },"data-field":"","editor-type":"dxSelectBox"}},[_c('DxLabel',{attrs:{"text":_vm.$t('agronomySample.summary.estate')}})],1),_vm._v(" "),_c('DxItem',{attrs:{"editor-options":{
            items: _vm.formData.valueReports,
            onValueChanged: _vm.valueReportChanged,
            value: _vm.selectedValueReport,
            width: '230px',
            placeholder: _vm.$t('select'),
            visible: _vm.showSelectedValueReport,
          },"data-field":"","editor-type":"dxSelectBox"}},[_c('DxLabel',{attrs:{"visible":_vm.showSelectedValueReport,"text":_vm.$t('valueReport')}}),_vm._v(" "),_c('DxRequiredRule',{attrs:{"message":_vm.$t('alert.requiredValueReport')}})],1),_vm._v(" "),_c('DxEmptyItem',{attrs:{"editor-options":{ visible: _vm.showSelectedValueReport }}}),_vm._v(" "),_c('DxItem',{attrs:{"editor-options":{
            items: _vm.formData.sampleTypes,
            onValueChanged: _vm.sampleTypeChanged,
            displayExpr: 'value',
            valueExpr: 'value',
            value: _vm.selectedSampleType,
            width: '235px',
            placeholder: _vm.$t('select'),
          },"data-field":"","editor-type":"dxSelectBox"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('alert.requiredSampleType')}}),_vm._v(" "),_c('DxLabel',{attrs:{"text":_vm.$t('agronomySample.summary.sampleType')}})],1),_vm._v(" "),_c('DxItem',{attrs:{"editor-options":{
            items: _vm.formData.parcels,
            onValueChanged: _vm.parcelChanged,
            displayExpr: 'name',
            valueExpr: 'name',
            value: _vm.selectedParcel,
            width: '235px',
            placeholder: _vm.$t('select'),
          },"data-field":"","editor-type":"dxSelectBox"}},[_c('DxLabel',{attrs:{"text":_vm.$t('agronomySample.summary.parcel')}})],1),_vm._v(" "),_c('DxItem',{attrs:{"editor-options":{
            items: _vm.formData.studies,
            onValueChanged: _vm.studyChanged,
            value: _vm.selectedStudy,
            width: '235px',
            placeholder: _vm.$t('select'),
          },"data-field":"","editor-type":"dxSelectBox"}},[_c('DxLabel',{attrs:{"text":_vm.$t('project')}})],1)],1),_vm._v(" "),_c('div',{staticClass:"marginLeft20 marginBottom10"},[_c('DxCheckBox',{attrs:{"value":_vm.sendingNotifications},on:{"valueChanged":_vm.sendingNotificationsChanged}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('sendingNotifications')))])],1),_vm._v(" "),_c('span',{staticStyle:{"padding":"20px","font-weight":"bold"}},[_vm._v("\n        "+_vm._s(_vm.$t('alertCriteria'))+"\n      ")]),_vm._v(" "),_c('DxForm',{staticClass:"ha-form",staticStyle:{"padding":"20px"},attrs:{"col-count":"4","label-location":"top","form-data":_vm.formDataCriteria}},[_c('DxGroupItem',[_c('DxLabel',{attrs:{"location":"left","text":_vm.$t('alert.notifyIf'),"show-colon":false}}),_vm._v(" "),_c('DxItem',{staticStyle:{"width":"100px"},attrs:{"editor-options":{
              disabled: false,
              width: '60px',
              format: ';#',
            },"data-field":"selectedNumOfParams","editor-type":"dxNumberBox"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('alert.requiredNumParams')}}),_vm._v(" "),_c('DxLabel',{attrs:{"alignment":"right","location":"right","text":_vm.$t('alert.notifySample') +
                ' ' +
                (_vm.selectedValueReport ? _vm.selectedValueReport : '') +
                `${_vm.selectedValueReport ? '(s)' : ''}`,"show-colon":false}})],1)],1)],1),_vm._v(" "),_c('DxForm',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSelectedValueReport),expression:"showSelectedValueReport"}],staticClass:"ha-form",staticStyle:{"padding":"20px"},attrs:{"col-count":"4","label-location":"top","form-data":_vm.formDataCriteria}},[_c('DxGroupItem',[_c('DxLabel',{attrs:{"location":"left","text":_vm.$t('notifyIfChains'),"show-colon":false}}),_vm._v(" "),_c('DxItem',{staticStyle:{"width":"100px"},attrs:{"editor-options":{
              disabled: false,
              width: '60px',
              format: ';#',
            },"data-field":"selectedNumOfChains","editor-type":"dxNumberBox"}},[_c('DxLabel',{attrs:{"alignment":"right","location":"right","text":_vm.$t('chains').toLowerCase(),"show-colon":false}})],1)],1)],1),_vm._v(" "),_c('DxForm',{staticClass:"ha-form",staticStyle:{"padding":"20px"},attrs:{"col-count":"1","label-location":"top","form-data":_vm.formDataCriteria}},[_c('DxGroupItem',[_c('DxLabel',{attrs:{"location":"left","text":_vm.$t('notifyIfChains'),"show-colon":false}}),_vm._v(" "),_c('DxItem',{attrs:{"editor-options":{
              disabled: false,
              width: '170px',
              items: _vm.formData.chains,
              searchEnabled: true,
              value: _vm.formDataCriteria.selectedChains,
              onValueChanged: _vm.setSelectedChains,
            },"editor-type":"dxTagBox"}})],1)],1),_vm._v(" "),_c('f7-row',[_c('f7-col',{staticClass:"marginTop15",staticStyle:{"text-align":"right","padding":"20px"},attrs:{"width":"100"}},[_c('DxButton',{staticClass:"standardBtn",attrs:{"text":_vm.$t('save'),"type":"outlined"},on:{"click":_vm.save}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }