<template>
  <div>
    <DxPopup
      :visible="showNewAlertPopup"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-close-button="true"
      :show-title="true"
      width="800"
      height="auto"
      container=".dx-viewport"
      :title="
        !editData.isEditing
          ? `${$t('alert.register')} - ${$t('foodSamples.parameter')}`
          : `${$t('alert.edit')} - ${$t('foodSamples.parameter')}`
      "
      @hiding="hide"
    >
      <DxScrollView
        :scroll-by-content="true"
        :scroll-by-thumb="true"
        show-scrollbar="onScroll"
        :use-native="true"
        :bounce-enabled="true"
      >
        <f7-row class="padding-top padding-horizontal">
          <f7-col>
            <div class="card no-margin-top">
              <div class="card-header">
                {{ $t('alertCriteria') }}
              </div>
              <div class="card-content padding">
                <DxForm
                  col-count="1"
                  :form-data="formData"
                  label-location="top"
                >
                  <DxItem
                    :editor-options="{
                      items: formData.areas,
                      onValueChanged: areaChanged,
                      value: selectedArea,
                      placeholder: $t('select'),
                      disabled: editData.isEditing,
                    }"
                    data-field=""
                    editor-type="dxSelectBox"
                  >
                    <DxRequiredRule
                      :message="$t('alert.requiredArea')"
                    />
                    <DxLabel :text="$t('alert.area')" />
                  </DxItem>

                  <DxItem
                    :editor-options="{
                      items: formData.sampleTypes,
                      onValueChanged: sampleTypeChanged,
                      displayExpr: 'value',
                      valueExpr: 'value',
                      value: selectedSampleType,
                      placeholder: $t('select'),
                      disabled: editData.isEditing,
                    }"
                    data-field=""
                    editor-type="dxSelectBox"
                  >
                    <DxRequiredRule
                      :message="$t('alert.requiredSampleType')"
                    />
                    <DxLabel
                      :text="$t('agronomySample.summary.sampleType')"
                    />
                  </DxItem>

                  <DxItem
                    :editor-options="{
                      items: formData.farms,
                      onValueChanged: farmChanged,
                      displayExpr: 'value',
                      valueExpr: 'value',
                      value: selectedFarm,
                      placeholder: $t('select'),
                    }"
                    data-field=""
                    editor-type="dxSelectBox"
                  >
                    <DxLabel
                      :text="$t('agronomySample.summary.estate')"
                    />
                  </DxItem>

                  <DxItem
                    :editor-options="{
                      items: formData.parcels,
                      displayExpr: 'value',
                      valueExpr: 'value',
                      onValueChanged: parcelChanged,
                      value: selectedParcel,

                      placeholder: $t('select'),
                    }"
                    data-field=""
                    editor-type="dxSelectBox"
                  >
                    <DxLabel
                      :text="$t('agronomySample.summary.parcel')"
                    />
                  </DxItem>

                  <DxItem
                    :editor-options="{
                      items: formData.studies,
                      onValueChanged: studyChanged,
                      value: selectedStudy,
                      placeholder: $t('select'),
                    }"
                    data-field=""
                    editor-type="dxSelectBox"
                  >
                    <DxLabel :text="$t('project')" />
                  </DxItem>
                </DxForm>
              </div>
            </div>
            <div class="card no-margin-top">
              <div class="card-header">
                {{ $t('notification') }}
              </div>
              <div class="card-content padding">
                <DxCheckBox
                  :value="sendingNotifications"
                  @valueChanged="sendingNotificationsChanged"
                />
                <span class="margin-left-half">{{
                  $t('sendingNotifications')
                }}</span>
              </div>
            </div>
          </f7-col>
          <f7-col>
            <!-- Edit Parameters -->
            <div class="card no-margin-top">
              <div class="card-header">
                {{ $t('alertCriteria') }}
              </div>
              <DxForm
                v-show="
                  paramLoaded && (
                    !editData.isEditing ||
                  (editData.isEditing &&
                    selectedParameters.parameters[0].parameter
                      .length > 0)
                  )
                "
                class="card-content padding"
                col-count="1"
                :form-data="selectedParameters"
              >
                <DxGroupItem
                  v-for="(parameter, index) in paramOptionsSelectors"
                  :key="index"
                  :col-count="1"
                >
                  <DxItem
                    v-if="paramLoaded && selectedParameters.parameters[index].parameter"
                    :editor-options="parameter"
                    :data-field="
                      'parameters[' + index + '].parameter'
                    "
                  >
                    <DxRequiredRule
                      :message="$t('alert.requiredParam')"
                    />
                    <DxLabel :text="$t('foodSamples.parameter')" />
                  </DxItem>
                  <DxItem
                    v-else
                    :editor-options="parameter"
                    editor-type="dxSelectBox"
                    :data-field="
                      'parameters[' + index + '].parameter'
                    "
                  >
                    <DxRequiredRule
                      :message="$t('alert.requiredParam')"
                    />
                    <DxLabel :text="$t('foodSamples.parameter')" />
                  </DxItem>
                  <DxGroupItem
                    v-if="paramLoaded"
                    :caption="$t('notificationRange')"
                  >
                    <DxItem
                      v-if="paramLoaded"
                      :editor-options="parameter.notificationRange[0]"
                      :data-field="
                        'parameters[' + index + '].min_notification'
                      "
                      editor-type="dxNumberBox"
                    >
                      <DxRequiredRule
                        :message="$t('alert.requiredMinNotification')"
                      />
                      <DxLabel :text="$t('min')" />
                    </DxItem>

                    <DxItem
                      v-if="paramLoaded"
                      :editor-options="parameter.notificationRange[1]"
                      :data-field="
                        'parameters[' + index + '].max_notification'
                      "
                      editor-type="dxNumberBox"
                    >
                      <DxRequiredRule
                        :message="$t('alert.requiredMaxNotification')"
                      />
                      <DxLabel
                        :text="$t('max')"
                        :show-colon="false"
                      />
                    </DxItem>
                    <DxItem
                      v-if="typeof selectedParameters.parameters[index].unit === 'string'"
                      :editor-options="{ readOnly: true }"
                      :data-field="'parameters[' + index + '].unit'"
                      editor-type="dxTextBox"
                    >
                      <DxLabel
                        :text="$t('foodSamples.unit')"
                        :show-colon="true"
                      />
                    </DxItem>
                    <DxItem
                      v-else
                      :editor-options="{
                        items: selectedParameters.parameters[index].unit,
                        onValueChanged: unitChanged,
                        readOnly: false,
                        value: selectedParameters.parameters[index].unit,
                        placeholder: selectedParameters.parameters[index].unit ? selectedParameters.parameters[index].unit[0] !== '' ? $t('select') : $t('unknownUnit') : $t('unknownUnit'),
                      }"
                      editor-type="dxSelectBox"
                    >
                      <DxLabel
                        :text="$t('foodSamples.unit')"
                        :show-colon="true"
                      />
                    </DxItem>
                  </DxGroupItem>
                </DxGroupItem>
              </DxForm>
              <div
                class="card-footer padding-horizontal no-padding-bottom"
              >
                <DxButton
                  :disabled="
                    !paramLoaded 
                  "
                  class="margin-bottom-half"
                  :text="'+' + $t('addParam')"
                  styling-mode="outlined"
                  type="default"
                  @click="addParameter"
                />
              </div>
            </div>
            <!-- /. Edit Parameters -->
          </f7-col>
        </f7-row>

        <f7-row class="margin">
          <f7-col class="display-flex justify-content-flex-end">
            <DxButton
              success
              :text="$t('save_changes')"
              type="success"
              @click="save"
            />
          </f7-col>
        </f7-row>
      </DxScrollView>
    </DxPopup>
  </div>
</template>
<script>
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxPopup } from 'devextreme-vue/popup';
import notify from 'devextreme/ui/notify';
import { mapActions, mapState } from 'vuex';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import { DxRequiredRule } from 'devextreme-vue/validator';
import EventBus from '@/services/event-bus';
import {
  DxForm,
  DxItem,
  DxLabel,
  DxGroupItem,
} from 'devextreme-vue/form';
import DxButton from 'devextreme-vue/button';

import { ATCS_TRANSLATED, NOTIFY_TIME_LONG } from '@/js/constants';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';

export default {
  name: 'Params',
  components: {
    DxPopup,
    DxForm,
    DxItem,
    DxLabel,
    DxButton,
    DxGroupItem,
    DxScrollView,
    DxRequiredRule,
    DxCheckBox,
  },
  props: {
    editData: {
      type: Object,
      default: () => {
        return {
          isEditing: false,
          data: null,
        };
      },
    },
    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formData: {
        areas: Object.values(ATCS_TRANSLATED).map((el) => {
          return this.$t(el);
        }),
        sampleTypes: [],
        farms: [],
        parcels: [],
        studies: [],
      },
      paramLoaded: false,
      selectedParameters: {
        parameters: [
          {
            parameter: '',
            min_measure: 1,
            max_measure: 1.5,
            min_notification: '',
            max_notification: '',
            parameter_id: '',
            unit: '',
          },
        ],
      },
      showParams: false,
      paramOptionsSelectors: [],
      selectedArea: '',
      selectedFarm: '',
      selectedUnit: [],
      selectedSampleType: '',
      selectedParcel: '',
      selectedStudy: '',
      sendingNotifications: false,
      formDataParameters: [],
      customDataSource: null,
      customStore: null,
      maxSelectorsAllowed: 6,
    };
  },
  computed: {
    ...mapState('alerts', ['showNewAlertPopup']),
    ...mapState('contact', ['company']),
  },
  async beforeMount() {
    if (this.editData.isEditing) {
      this.showParams = true;
    }
    this.customStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: () => {
        return this.formDataParameters;
      },
    });

    this.customDataSource = new DataSource({
      store: this.customStore,
      paginate: true,
      pageSize: 8,
    });

    if (this.editData.isEditing) {
      this.selectedSampleType = this.editData.isEditing
        ? this.editData.data.sampleType
        : '';
      this.selectedFarm = this.editData.isEditing
        ? this.editData.data.farm
        : '';
      this.selectedParcel = this.editData.isEditing
        ? this.editData.data.parcel
        : '';
      this.selectedStudy = this.editData.isEditing
        ? this.editData.data.study
        : '';
      this.sendingNotifications = this.editData.isEditing
        ? this.editData.data.sendingNotifications
        : false;
      await this.areaChanged({
        value: this.editData.data.area,
      });
      await this.addParameterOnEdit();
    } else {
      this.paramOptionsSelectors.push({
        items: [],
        displayExpr: 'name',
        valueExpr: 'name',
        width: '200',
        searchEnabled: true,
        onValueChanged: this.paramChanged,
        placeholder: this.$t('foodSamples.parameter'),
        measureRange: [
          {
            // rmin / rmax
            value: 1,
            placeholder: this.$t('min'),
            disabled: true,
          },
          {
            value: 1.5,
            placeholder: this.$t('max'),
            disabled: true,
          },
        ],
        notificationRange: [
          {
            placeholder: this.$t('min'),
            disabled: false,
          },
          {
            placeholder: this.$t('max'),
            disabled: false,
          },
        ],
      });
    }
  },
  methods: {
    hide() {
      this.setShowNewAlertPopup(false);
    },
    addParameter() {
      const selector = {
        dataSource: this.customDataSource,
        width: '180px',
        displayExpr: 'name',
        valueExpr: 'name',
        searchEnabled: true,
        dropDownOptions: {
          height: 300,
        },
        onValueChanged: this.paramChanged,
        placeholder: this.$t('foodSamples.parameter'),
        buttons: [
          {
            location: 'after',
            name: 'eliminar',
            options: {
              icon: 'trash',
              type: 'danger',
              stylingMode: 'outlined',
              onClick: this.delete,
            },
          },
        ],
        measureRange: [
          {
            value: 1,
            placeholder: this.$t('min'),
            disabled: true,
          },
          {
            value: 1.5,
            placeholder: this.$t('max'),
            disabled: true,
          },
        ],
        notificationRange: [
          {
            items: [],
            placeholder: this.$t('min'),
            disabled: false,
          },
          {
            items: [],
            placeholder: this.$t('max'),
            disabled: false,
          },
        ],
      };
      this.paramOptionsSelectors.push(selector);

      this.selectedParameters.parameters.push({
        parameter: '',
        min_measure: selector.measureRange[0].value,
        max_measure: selector.measureRange[1].value,
        min_notification: '',
        max_notification: '',
        parameter_id: '',
        unit: '',
      });
    },
    async addParameterOnEdit() {
      const sampleTypeFound = this.formData.sampleTypes.find(item => item.value === this.selectedSampleType);
      const sampleTypeId = sampleTypeFound ? sampleTypeFound : {id:this.editData.data.sampleTypeId};
      try {
        const params = await this.getParametersUnits({
          atc: this.selectedArea,
          sampleType: sampleTypeId,
        });
        this.formDataParameters = params.map((param) => {
          return {
            name: param.parameter,
            id: param.parameter_id,
            lmr: param.lmr || undefined,
            unit: param.unit,
          };
        });
        this.editData.data.parameters.forEach((param, index) => {
          if (index === 0) {
            this.paramOptionsSelectors.push({
              dataSource: this.customDataSource,
              displayExpr: 'name',
              valueExpr: 'name',
              width: '180px',
              searchEnabled: true,
              readOnly: true,
              dropDownOptions: {
                height: 300,
              },
              onValueChanged: this.paramChanged,
              placeholder: this.$t('foodSamples.parameter'),
              buttons: [
                {
                  location: 'after',
                  name: 'eliminar',
                  options: {
                    icon: 'trash',
                    type: 'danger',
                    stylingMode: 'outlined',
                    onClick: this.delete,
                    disabled: false,
                  },
                },
              ],
              measureRange: [
                {
                  // rmin / rmax
                  value: 1,
                  placeholder: this.$t('min'),
                  disabled: true,
                },
                {
                  value: 1.5,
                  placeholder: this.$t('max'),
                  disabled: true,
                },
              ],
              notificationRange: [
                {
                  placeholder: this.$t('min'),
                  disabled: false,
                },
                {
                  placeholder: this.$t('max'),
                  disabled: false,
                },
              ],
            });
            this.selectedParameters.parameters[0].parameter =
              this.editData.data.parameters[0].parameter;
            this.selectedParameters.parameters[0].min_notification =
              this.editData.data.parameters[0].min_notification;
            this.selectedParameters.parameters[0].max_notification =
              this.editData.data.parameters[0].max_notification;
            this.selectedParameters.parameters[0].parameter_id =
              +this.editData.data.parameters[0].parameter_id;
            this.selectedParameters.parameters[0].unit =
              this.editData.data.parameters[0].unit;
          } else {
            this.paramOptionsSelectors.push({
              dataSource: this.customDataSource,
              width: '180px',
              displayExpr: 'name',
              valueExpr: 'name',
              readOnly: true,
              searchEnabled: true,
              dropDownOptions: {
                height: 300,
              },
              onValueChanged: this.paramChanged,
              placeholder: this.$t('foodSamples.parameter'),
              buttons: [
                {
                  location: 'after',
                  name: 'eliminar',
                  options: {
                    icon: 'trash',
                    type: 'danger',
                    stylingMode: 'outlined',
                    onClick: this.delete,
                    disabled: false,
                  },
                },
              ],
              measureRange: [
                {
                  value: 1,
                  placeholder: this.$t('min'),
                  disabled: true,
                },
                {
                  value: 1.5,
                  placeholder: this.$t('max'),
                  disabled: true,
                },
              ],
              notificationRange: [
                {
                  items: [],
                  placeholder: this.$t('min'),
                  disabled: false,
                },
                {
                  items: [],
                  placeholder: this.$t('max'),
                  disabled: false,
                },
              ],
            });

            this.selectedParameters.parameters.push({
              parameter: param.parameter,
              min_measure: param.min_measure,
              max_measure: param.max_measure,
              min_notification: param.min_notification,
              max_notification: param.max_notification,
              parameter_id: param.parameter_id,
              unit: param.unit,
            });
          }
        });

        this.paramLoaded = true;
        this.$f7.preloader.hide();
      } catch (e) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(e.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      }
    },
    sendingNotificationsChanged(e) {
      this.sendingNotifications = !this.sendingNotifications;
    },
    paramChanged(e) {
      if (!e.event) return;
      const keyIndex = e.component
        .$element()[0]
        .innerHTML.split('name')[1]
        .split('parameters')[1]
        .split('[')[1];

      const index = Object.keys(this.formDataParameters).find(
        (key) => this.formDataParameters[key].name === e.value
      );

      const paramIndex = keyIndex.split(']')[0];

      this.selectedParameters.parameters[paramIndex].parameter =
        e.value;
      this.selectedParameters.parameters[paramIndex].parameter_id =
        this.formDataParameters[index].id;
      this.selectedParameters.parameters[paramIndex].unit =
        this.formDataParameters[index].unit;
    },
    async areaChanged(e) {
      if (!e.value) return;
      this.selectedArea = e.value;
      try {
        this.showParams = false;
        if (!this.editData.isEditing) {
          this.selectedParameters.parameters.forEach(parameter => {
          parameter.parameter = ''; // Restablecer el valor del parámetro
          parameter.min_notification = null; // Restablecer la notificación mínima
          parameter.max_notification = null; // Restablecer la notificación máxima
          parameter.unit = null; // Restablecer la unidad
        });
          this.customStore = new CustomStore({
            key: 'id',
            loadMode: 'raw',
            load: () => {
              return this.formDataParameters;
            },
          });

          this.customDataSource = new DataSource({
            store: this.customStore,
            paginate: true,
            pageSize: 8,
          });

          if (this.paramOptionsSelectors.length > 1) {
            this.resetSelectors();
          } else {
            this.resetFirstSelector();
          }
        }
        else {
          this.showParams = true;
        }
        const actNames = {
          agronomy: 'Agronomia',
          food: 'Alimentaria',
          environment: 'Medio Ambiente',
          mining: 'Mineria',
          healthSecurity: 'Salud y Seguridad',
        };

        const index = this.formData.areas.findIndex(
          (area) => area === e.value
        );

        const atcName = Object.values(actNames)[index];

        const farms = await this.getSampleFilter({
          atc: atcName,
          attribute: 'farm',
        });
        this.formData.farms = farms;
        const sampleTypes = await this.getSampleFilter({
          atc: atcName,
          attribute: 'sample_type',
        });
        
        this.formData.sampleTypes = sampleTypes;
        const existSample = this.formData.sampleTypes.find(
          (sample) => sample.id === this.editData.data.sampleTypeId
        );
        if (!existSample) {
          this.formData.sampleTypes.push({id: this.editData.data.sampleTypeId, value: this.editData.data.sampleType})
        }
        const parcels = await this.getSampleFilter({
          atc: atcName,
          attribute: 'parcel',
        });
        this.formData.parcels = parcels;

        const studies = await this.getStudySatellite({
          company_code: this.company,
          atc_name: atcName,
        });
        this.formData.studies = studies;
        this.$f7.preloader.hide();
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(error.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      } finally {
        this.$f7.preloader.hide();
      }
    },
    async farmChanged(e) {
      if (!e.value) return;
      this.selectedFarm = e.value;
    },
    async unitChanged(e) {
      if (!e.value) return;
      this.selectedUnit = e.value;
    },
    async sampleTypeChanged(e) {
      if (!e.value || !e.event) return;
      this.selectedSampleType = e.value;
      this.showParams = true;
      // this.$f7.preloader.show();

      const actNames = {
        agronomy: 'Agronomia',
        environment: 'Medio Ambiente',
        food: 'Alimentaria',
        mining: 'Mineria',
        healthSecurity: 'Salud y Seguridad',
      };

      const index = this.formData.areas.findIndex(
        (area) => area === e.value
      );

      const atcName = Object.values(actNames)[index];

      try {
        const sampleTypeId = this.formData.sampleTypes.find(item => item.value === this.selectedSampleType);
        if (!this.editData.isEditing) {
          const params = await this.getParametersUnits({
            atc: this.selectedArea,
            sampleType: sampleTypeId,
          });
          this.formDataParameters = params.map((param) => {
            return {
              name: param.parameter,
              id: param.parameter_id,
              lmr: param.lmr || undefined,
              unit: param.unit,
            };
          });
          this.paramLoaded = false;

          this.customStore = new CustomStore({
            key: 'id',
            loadMode: 'raw',
            load: () => {
              return this.formDataParameters;
            },
          });

          this.customDataSource = new DataSource({
            store: this.customStore,
            paginate: true,
            pageSize: 8,
          });

          this.paramLoaded = true;

          if (this.paramOptionsSelectors.length > 1) {
            this.resetSelectors();
          } else {
            this.resetFirstSelector();
          }
        }
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(error.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      } finally {
        this.$f7.preloader.hide();
      }
    },
    async parcelChanged(e) {
      if (!e.value) return;
      this.selectedParcel = e.value;
    },
    async studyChanged(e) {
      if (!e.value) return;
      this.selectedStudy = e.value;
    },
    async save() {
      try {
        if (this.validate()) {
          if (this.editData.isEditing) {
            await this.saveOnEdit();
            return;
          }

          notify(
            {
              message: this.$t('alert.save'),
              width: 450,
            },
            'info',
            2000
          );
          const dataMapped =  this.selectedParameters.parameters.map(item => {
              return {
                ...item,
                   unit: Array.isArray(item.unit) ? item.unit[0] : item.unit ? item.unit: null,
              };
            }).map(item => {
              // Eliminar la propiedad "unit" si está vacía
              if (item.unit === null || item.unit === '') {
                delete item.unit;
              }
              return item;
            });
          const sampleTypeId = this.formData.sampleTypes.find(item => item.value === this.selectedSampleType);
          await this.saveAlert({
            parameters: dataMapped,
            atc: this.selectedArea,
            farm: this.selectedFarm,
            parcel: this.selectedParcel,
            sampleTypeId,
            sampleType: this.selectedSampleType,
            study: this.selectedStudy,
            sendingNotifications: this.sendingNotifications,
          });

          notify(
            {
              closeOnClick: true,
              message: this.$t('alert.saved'),
              width: 450,
            },
            'success',
            2000
          );

          this.setShowNewAlertPopup(false);

          EventBus.$emit('newParamAlert');
        } else {
          notify(
            {
              closeOnClick: true,
              message: this.$t('saveError'),
              width: 450,
            },
            'warning',
            2000
          );
        }
      } catch (e) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(e.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      }
    },
    validate() {
      if (!this.selectedArea) {
        this.selectedArea = null;
        return false;
      }

      if (!this.selectedSampleType) {
        this.selectedSampleType = null;
        return false;
      }

      if (this.selectedParameters.parameters.length > 0) {
        for (
          let i = 0;
          i < this.selectedParameters.parameters.length;
          i += 1
        ) {
          if (!this.selectedParameters.parameters[i].parameter) {
            this.selectedParameters.parameters[i].parameter = null;
            return false;
          }

          if (
            !this.selectedParameters.parameters[i].min_notification
          ) {
            this.selectedParameters.parameters[i].min_notification =
              null;
            return false;
          }

          if (
            !this.selectedParameters.parameters[i].max_notification
          ) {
            this.selectedParameters.parameters[i].max_notification =
              null;
            return false;
          }

          if (
            this.selectedParameters.parameters[i].min_notification >
            this.selectedParameters.parameters[i].max_notification
          ) {
            return false;
          }
        }
      }
      return true;
    },
    async saveOnEdit() {
      try {
        notify(
          {
            message: this.$t('editing'),
            width: 450,
          },
          'info',
          2000
        );
        const dataMapped =  this.selectedParameters.parameters.map(item => {
            return {
              ...item,
              unit: Array.isArray(item.unit) ? item.unit[0] : item.unit ? item.unit: null,
            };
          }).map(item => {
            // Eliminar la propiedad "unit" si está vacía
            if (item.unit === null || item.unit === '') {
              delete item.unit;
            }
            return item;
          });
        await this.updateAlert({
          id: this.editData.data.id,
          params: {
            parameters: dataMapped,
            atc: this.selectedArea,
            farm: this.selectedFarm,
            parcel: this.selectedParcel,
            sampleType: this.selectedSampleType,
            study: this.selectedStudy,
            sendingNotifications: this.sendingNotifications,
          },
        });

        notify(
          {
            closeOnClick: true,
            message: this.$t('editSuccess'),
            width: 450,
          },
          'success',
          2000
        );

        this.setShowNewAlertPopup(false);

        EventBus.$emit('newParamAlert', this.params);
      } catch (e) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(e.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      }
    },
    resetFirstSelector() {
      this.paramOptionsSelectors[0] = {
        dataSource: this.customDataSource,
        displayExpr: 'name',
        valueExpr: 'name',
        width: '180px',
        searchEnabled: true,
        dropDownOptions: {
          height: 300,
        },
        onValueChanged: this.paramChanged,
        placeholder: this.$t('foodSamples.parameter'),
        measureRange: [
          {
            // rmin / rmax
            value: 1,
            placeholder: this.$t('min'),
            disabled: true,
          },
          {
            value: 1.5,
            placeholder: this.$t('max'),
            disabled: true,
          },
        ],
        notificationRange: [
          {
            placeholder: this.$t('min'),
            disabled: false,
          },
          {
            placeholder: this.$t('max'),
            disabled: false,
          },
        ],
      };
    },
    resetSelectors() {
      this.paramOptionsSelectors = this.paramOptionsSelectors.map(
        (selector) => {
          return {
            dataSource: this.customDataSource,
            displayExpr: 'name',
            valueExpr: 'name',
            width: '180px',
            searchEnabled: true,
            dropDownOptions: {
              height: 300,
            },
            onValueChanged: this.paramChanged,
            placeholder: this.$t('foodSamples.parameter'),
            buttons: [
              {
                location: 'after',
                name: 'eliminar',
                options: {
                  icon: 'trash',
                  type: 'danger',
                  stylingMode: 'outlined',
                  onClick: this.delete,
                },
              },
            ],
            measureRange: [
              {
                // rmin / rmax
                value: 1,
                placeholder: this.$t('min'),
                disabled: true,
              },
              {
                value: 1.5,
                placeholder: this.$t('max'),
                disabled: true,
              },
            ],
            notificationRange: [
              {
                placeholder: this.$t('min'),
                disabled: false,
              },
              {
                placeholder: this.$t('max'),
                disabled: false,
              },
            ],
          };
        }
      );
    },
    delete(e) {
      const keyIndex = e.component
        .$element()
        .offsetParent()[0]
        .children[0].innerHTML.split('id')[1]
        .split('parameters')[1]
        .split('[')[1];

      const paramIndex = keyIndex.split(']')[0];

      this.paramOptionsSelectors.splice(paramIndex, 1);
      this.selectedParameters.parameters.splice(paramIndex, 1);
    },
    ...mapActions('alerts', ['setShowNewAlertPopup']),
    ...mapActions('alertsParams', [
      'getEntity',
      'saveAlert',
      'getParameters',
      'getParametersUnits',
      'updateAlert',
    ]),
    ...mapActions('samples', ['getSampleFilter']),
    ...mapActions('agronomySamples', ['getStudySatellite']),
  },
};
</script>
<style scoped></style>
