<template>
  <div class="margin-top">
    <div class="label-input">{{ $t('foodSamples.parameter') }}</div>
    <div class="marginTop5">
      <DxTagBox
        :data-source="parameterTypes"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('foodSamples.parameter')"
        tag-template="tag"
        display-expr="value"
        value-expr="id"
        :value="selectedParameters"
        :on-value-changed="parametersChanged"
        :on-opened="openedParameters"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
    <div class="label-input margin-top-half">{{ $t('group') }}</div>
    <div class="marginTop5">
      <DxTagBox
        :data-source="parameterGroups"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('group')"
        tag-template="tag"
        display-expr="value"
        value-expr="value"
        :value="selectedParameterGroups"
        :on-value-changed="parameterGroupChanged"
        :on-opened="openedParameterGroup"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
    <div class="margin-top-half">
      <div class="label-input">
        {{ $t('environmentSamples.limits') }}
      </div>
      <DxTagBox
        :data-source="limits"
        :multiline="true"
        :placeholder="$t('environmentSamples.limits')"
        tag-template="tag"
        :value="selectedLimits"
        :on-value-changed="limitsChanged"
        :on-opened="openedLimitsCMA"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div>
  </div>
</template>

<script>
import { DxTagBox } from 'devextreme-vue/tag-box';
import { mapActions, mapState } from 'vuex';
import DataSource from 'devextreme/data/data_source';

export default {
  name: 'EnvironmentDetailsFilter',
  components: {
    DxTagBox,
  },
  data() {
    return {
      parameterTypes: [],
      parameterGroups: [],
      limits: [],
      loaded: false,
    };
  },
  computed: {
    ...mapState('environmentSamples', ['sampleDetails']),
    ...mapState('environmentDetailsFilter', [
      'selectedParameters',
      'selectedLimits',
      'selectedParameterGroups',
    ]),
    ...mapState('filter', ['currentSampleId']),
  },
  methods: {
    parametersChanged(e) {
      this.setSelectedParameters(e.value);
    },
    parameterGroupChanged(e) {
      this.setSelectedParameterGroup(e.value);
    },
    async openedParameters(e) {
      const parameters = await this.getSampleFilter({
        atc: 'Medio Ambiente',
        attribute: 'parameter',
        sampleId: this.currentSampleId,
      });

      this.parameterTypes = new DataSource({
        store: parameters,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedParameterGroup(e) {
      const parameters = await this.getSampleFilter({
        atc: 'Medio Ambiente',
        attribute: 'parameter_group',
        sampleId: this.currentSampleId,
      });

      this.parameterGroups = new DataSource({
        store: parameters,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    limitsChanged(e) {
      this.setSelectedLimits(e.value);
    },
    openedLimitsCMA() {
      this.limits = ['> CMA'];
      this.loaded = true;
    },
    contentReady(e) {
      const arrayNotFoundItems = Array.from(
        document.getElementsByClassName('dx-empty-message')
      );

      arrayNotFoundItems.forEach((element) => {
        if (!this.loaded) {
          element.innerHTML = this.$t('loading');
        } else {
          element.innerHTML = this.$t('dataNotAvailable');
        }
      });

      this.loaded = false;
    },
    ...mapActions('environmentDetailsFilter', [
      'setSelectedParameters',
      'setSelectedLimits',
      'setSelectedParameterGroup',
    ]),
    ...mapActions('samples', ['getSampleFilter']),
  },
};
</script>
