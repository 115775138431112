export default {
  namespaced: true,
  state: {
    receptionFromDate: null,
    receptionToDate: null,
    endFromDate: null,
    endToDate: null,
    selectedEcologic: null,
    selectedStatuses: [],
    selectedSampleTypes: [],
    selectedAnalyseTypes: [],
    selectedThirdClients: [],
    selectedEuropeanChains: [],
    selectedProjects: [],
    selectedFarms: [],
    selectedParcels: [],
  },
  actions: {
    setReceptionToDate({ commit }, value) {
      commit('setReceptionToDate', value);
    },
    setReceptionFromDate({ commit }, value) {
      commit('setReceptionFromDate', value);
    },
    setEndFromDate({ commit }, value) {
      commit('setEndFromDate', value);
    },
    setEndToDate({ commit }, value) {
      commit('setEndToDate', value);
    },
    setSelectedSampleTypes({ commit }, value) {
      commit('setSelectedSampleTypes', value);
    },
    setSelectedStatuses({ commit }, value) {
      commit('setSelectedStatuses', value);
    },
    setSelectedAnalyseTypes({ commit }, value) {
      commit('setSelectedAnalyseTypes', value);
    },
    setEcologic({ commit }, value) {
      commit('setEcologic', value);
    },
    setSelectedThirdClients({ commit }, value) {
      commit('setSelectedThirdClients', value);
    },
    setSelectedEuropeanChains({ commit }, value) {
      commit('setSelectedEuropeanChains', value);
    },
    setSelectedProjects({ commit }, value) {
      commit('setSelectedProjects', value);
    },
    setSelectedFarms({ commit }, value) {
      commit('setSelectedFarms', value);
    },
    setSelectedParcels({ commit }, value) {
      commit('setSelectedParcels', value);
    },
    clearFilters({ commit }) {
      commit('clearFilters');
    },
  },
  mutations: {
    setReceptionFromDate(state, value) {
      state.receptionFromDate = value;
    },
    setReceptionToDate(state, value) {
      state.receptionToDate = value;
    },
    setSelectedAnalyseTypes(state, value) {
      state.selectedAnalyseTypes = value;
    },
    setEcologic(state, value) {
      state.selectedEcologic = value;
    },
    setSelectedThirdClients(state, value) {
      state.selectedThirdClients = value;
    },
    setSelectedEuropeanChains(state, value) {
      state.selectedEuropeanChains = value;
    },
    setSelectedProjects(state, value) {
      state.selectedProjects = value;
    },
    setSelectedFarms(state, value) {
      state.selectedFarms = value;
    },
    setSelectedParcels(state, value) {
      state.selectedParcels = value;
    },
    setEndFromDate(state, value) {
      state.endFromDate = value;
    },
    setEndToDate(state, value) {
      state.endToDate = value;
    },
    setSelectedSampleTypes(state, value) {
      state.selectedSampleTypes = value;
    },
    setSelectedStatuses(state, value) {
      state.selectedStatuses = value;
    },
    clearFilters(state) {
      state.receptionFromDate = null;
      state.receptionToDate = null;
      state.endFromDate = null;
      state.endToDate = null;
      state.selectedEcologic = null;
      state.selectedStatuses = [];
      state.selectedSampleTypes = [];
      state.selectedAnalyseTypes = [];
      state.selectedThirdClients = [];
      state.selectedEuropeanChains = [];
      state.selectedProjects = [];
      state.selectedFarms = [];
      state.selectedParcels = [];
    },
  },
};
