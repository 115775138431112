<template>
  <div>
    <div class="tableContainer">
      <div class="item-title-bold"></div>

      <f7-row class="card-body">
        <f7-col>
          <f7-row>
            <f7-col>
              <div>{{ $t('agronomySample.summary.sampleCode') }}</div>
              <div class="item-title-bold">
                {{ currentSampleSummary.code }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
        <f7-col>
          <f7-row>
            <f7-col>
              <div>
                {{ $t('agronomySample.summary.sampleType') }}
              </div>
              <div class="item-title-bold">
                {{ currentSampleSummary.sample_type }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>

        <f7-col>
          <f7-row>
            <f7-col>
              <div></div>
              <div class="item-title-bold"></div>
            </f7-col>
          </f7-row>
        </f7-col>

        <f7-col>
          <f7-row>
            <f7-col>
              <div>
                {{ $t('foodSamples.receptionDate') }}
              </div>
              <div class="item-title-bold">
                {{
                  $dates.formatDateDayMonthYear(
                    currentSampleSummary.reception_date
                  )
                }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
        <f7-col style="text-align: right; margin-top: -20px">
          <f7-row>
            <f7-col>
              <ContextMenu
                :id="'btnDownloadFoodDetails'"
                :items="items"
              />
              <!-- <DxButton
                id="btnDownloadFoodDetails"
                style=""
                type="normal"
                styling-mode="outlined"
              >
                <i class="" style="cursor: pointer">
                  <img
                    width="45"
                    height="30"
                    :src="getIconPath"
                    alt="download"
                  />

                  <DxContextMenu
                    :data-source="items"
                    target="#btnDownloadFoodDetails"
                    show-event="dxclick"
                  />
                </i>
              </DxButton> -->
            </f7-col>
          </f7-row>
        </f7-col>
      </f7-row>

      <f7-row class="card-body">
        <f7-col>
          <f7-row>
            <f7-col>
              <div>
                {{ $t('agronomySample.summary.description') }}
              </div>
              <div class="item-title-bold">
                {{ currentSampleSummary.sample_name }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
        <f7-col>
          <f7-row>
            <f7-col>
              <div>
                {{ $t('environmentSamples.summary.project') }}
              </div>
              <div class="item-title-bold">
                {{ currentSampleSummary.study }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>

        <f7-col>
          <f7-row>
            <f7-col>
              <div>{{ $t('environmentSamples.installation') }}</div>
              <div class="item-title-bold">
                {{ currentSampleSummary.farm }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
        <f7-col>
          <f7-row>
            <f7-col>
              <div>{{ $t('environmentSamples.samplingPoint') }}</div>
              <div class="item-title-bold">
                {{ currentSampleSummary.parcel }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
        <f7-col>
          <f7-row>
            <f7-col>
              <div>{{ $t('agronomySample.summary.status') }}</div>
              <div class="item-title-bold">
                <div
                  :class="
                    $helpers.getStatusClass(
                      currentSampleSummary.status_code
                    )
                  "
                  style="text-align: center"
                >
                  {{ currentSampleSummary.status }}
                </div>
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
      </f7-row>

      <f7-row class="card-body">
        <f7-col>
          <f7-row>
            <f7-col>
              <div>
                {{ $t('environmentSamples.summary.legislation') }}
              </div>
              <div class="item-title-bold">
                {{ currentSampleSummary.legislation }}
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
        <f7-col>
          <f7-row>
            <f7-col>
              <div>
                {{ $t('environmentSamples.summary.passFailRatio') }}
              </div>
              <div class="item-title-bold">
                <f7-row>
                  <f7-col>
                    <DxChart
                      :data-source="dataSource"
                      :rotated="true"
                      :bar-group-padding="0.5"
                      :size="size"
                      :legend="legend"
                    >
                      <DxCommonSeriesSettings
                        argument-field="text"
                        type="rangebar"
                        range-value1-field="first"
                        range-value2-field="second"
                        bar-overlap-group="values"
                      />
                      <DxSeriesTemplate
                        name-field="value"
                        :customize-series="customizeSeries"
                      />
                      <DxAnimation :enabled="true" />

                      <DxTooltip
                        :enabled="true"
                        :z-index="999999"
                        :customize-tooltip="customizeTooltip"
                      />
                    </DxChart>
                  </f7-col>
                  <f7-col></f7-col>
                </f7-row>
              </div>
            </f7-col>
          </f7-row>
        </f7-col>
        <f7-col> </f7-col>
        <f7-col></f7-col>
        <f7-col></f7-col>
      </f7-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DxContextMenu from 'devextreme-vue/context-menu';
import ContextMenu from '@/components/contextMenu';

import {
  DxChart,
  DxCommonSeriesSettings,
  DxAnimation,
  DxSeriesTemplate,
  DxTooltip,
} from 'devextreme-vue/chart';

import DxButton from 'devextreme-vue/button';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  name: 'EnvironmentSampleSummary',
  components: {
    DxButton,
    DxTooltip,
    DxChart,
    DxCommonSeriesSettings,
    DxAnimation,
    DxSeriesTemplate,
    DxContextMenu,
    ContextMenu,
  },
  props: {
    count: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentSampleSummary: {},
      showNotElegible: false,
      showElegible: false,
      dataSource: [
        {
          text: ' ',
          first: 0,
          second: this.count.apts,
          value: this.count.apts === 0 ? 0 : this.count.apts,
          color: '#4BC0C0',
        },
        {
          text: ' ',
          first:
            this.count.apts === 100 ? undefined : this.count.apts,
          second: 100,
          value: this.count.no_apts === 0 ? 0 : this.count.no_apts,
          color: '#FF6384',
        },
      ],
      counterColor: 0,
      colors: ['#4BC0C0', '#FF6384'],
      size: {
        height: 50,
      },
      legend: {
        visible: false,
      },
      environment: process.env.NODE_ENV,
    };
  },
  computed: {
    items() {
      if (this.currentSampleSummary.status_code !== '2') {
        return [
          {
            icon: 'download',
            text: `${this.$t('download')}`,
            onClick: this.download,
            disabled: this.currentSampleSummary.status_code !== '3',
          },
        ];
      }

      return [
        {
          icon: 'refresh',
          text: this.$t('refresh'),
          onClick: this.refresh,
        },
        {
          icon: 'download',
          text: `${this.$t('download')}`,
          onClick: this.download,
          disabled: this.currentSampleSummary.status_code !== '3',
        },
      ];
    },
    getIconPath() {
      return `${
        this.environment === 'development' ? '../../' : './'
      }static/icons/options_burger.svg`;
    },
    ...mapState('samples', ['sampleFetchedFromEtl']),
  },
  async beforeMount() {
    try {
      this.currentSampleSummary =
        await this.getCurrentSampleBasedOnFilter();

      // if (this.currentSampleSummary.status_code === '2') {
      //   this.$notifyDX(
      //     {
      //       message: this.$t('inProcessSampleResult'),
      //       width: 550,
      //     },
      //     'warning',
      //     7000
      //   );
      // }

      if (
        this.currentSampleSummary?.status_code === '2' &&
        this.currentSampleSummary?.sample_id != null &&
        this.sampleFetchedFromEtl === false
      ) {
        this.setSampleFetchedFromEtl(true);

        this.$f7.preloader.show();

        await this.getSample(this.currentSampleSummary.sample_id);

        this.$f7.preloader.hide();

        this.$f7router.navigate(
          { name: 'EnvironmentSamplesDetailsPage' },
          {
            animate: true,
            transition: 'f7-fade',
            reloadCurrent: true,
          }
        );
      }
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(error.message).error
            )
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    }
  },
  methods: {
    customizeText({ valueText }) {
      return `${valueText} %`;
    },
    customizeSeries(val) {
      return { color: this.colors[this.counterColor++] };
    },
    customizeTooltip(pointInfo) {
      return {
        html: `
        <div>
          <div>
            ${pointInfo.argumentText}
          </div>
          <div>
            <span class='item-title-bold'>${Number(
              pointInfo.seriesName
            ).toFixed(2)} %</span>
          </div>
        </div>`,
      };
    },
    toggleNotElegible() {
      this.showNotElegible = !this.showNotElegible;
    },
    toggleElegible() {
      this.showElegible = !this.showElegible;
    },
    async refresh() {
      if (
        this.currentSampleSummary?.status_code === '2' &&
        this.currentSampleSummary?.sample_id != null
      ) {
        this.$f7.preloader.show();
        await this.getSample(this.currentSampleSummary.sample_id);
        this.$f7.preloader.hide();
      }

      this.$f7router.navigate(
        { name: 'EnvironmentSamplesDetailsPage' },
        {
          animate: true,
          transition: 'f7-fade',
          reloadCurrent: true,
        }
      );
    },
    async download(e) {
      this.$notifyDX(
        {
          message: this.$t('downloading'),
          width: 350,
        },
        'info',
        2000
      );
      const report = await this.getReport();

      if (report.name && report.pdfB64) {
        const link = document.createElement('a');
        link.href = `data:application/octet-stream;base64,${report.pdfB64}`;
        link.download = report.name;
        link.click();

        URL.revokeObjectURL(link.href);

        this.$notifyDX(
          {
            message: this.$t('downloadedFile'),
            width: 350,
          },
          'success',
          2000
        );
      } else {
        this.$notifyDX(
          {
            message: this.$t('sampleHasNoReport'),
            width: 450,
          },
          'info',
          2000
        );
      }
    },
    ...mapActions('samples', [
      'getCurrentSampleBasedOnFilter',
      'getReport',
      'setSampleFetchedFromEtl',
    ]),
    ...mapActions('riskAnalysis', ['getSample']),
  },
};
</script>

<style scoped></style>
