<template>
  <f7-page>
    <div v-if="loaded">
      <div
        class="content-devx display-flex align-items-center justify-content-space-between"
      >
        <div class="no-margin-bottom">
          <strong>{{ $t('station') }}:</strong>
          {{ station }}
        </div>
        <div class="filterButtonPosition">
          <f7-button
            fill
            class="whiteFontColor button-filter"
            panel-toggle="right"
          >
            <span class="ic-filter"></span> {{ $t('filter') }}
          </f7-button>
        </div>
      </div>

      <div class="content-graphs">
        <div>
          <WeatherLineGraph
            :data-source="data"
            weather-element="temperature"
          />
        </div>
        <div>
          <WeatherLineGraph
            :data-source="data"
            weather-element="humidity"
          />
        </div>
        <div>
          <WeatherLineGraph
            :data-source="data"
            weather-element="solar_radiation"
          />
        </div>
        <div>
          <WeatherLineGraph
            :data-source="data"
            weather-element="wind_speed"
          />
        </div>
        <div>
          <WeatherBarGraph
            :data-source="data"
            weather-element="pluviometer"
          />
        </div>
      </div>
    </div>

    <GoBack
      class="margin-top"
      :go-to-url-name="true"
      url-name="DashboardPage"
    />
  </f7-page>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import format from 'date-fns/format';
import WeatherLineGraph from '@/components/Agronomy/AdvancedServices/Dashboard/weather/WeatherLineGraph/index.vue';
import WeatherBarGraph from '@/components/Agronomy/AdvancedServices/Dashboard/weather/WeatherBarGraph/index.vue';
import EventBus from '@/services/event-bus';
import GoBack from '@/components/UtilityComponents/GoBack/index.vue';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  name: 'WeatherGraphsPage',
  components: {
    WeatherLineGraph,
    WeatherBarGraph,
    GoBack,
  },
  data() {
    return {
      data: null,
      loaded: false,
    };
  },
  computed: {
    ...mapState('registerFarm', ['farmStr']),
    ...mapState('weather', ['parameters', 'station']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    this.setCurrentFilter('weatherGraph');
    this.setCurrentRoute([
      {
        name: this.$t('Agronomy'),
        disabled: false,
        page: 'AgronomySamplesPage',
        active: false,
      },
      {
        name: this.$t('advancedServices'),
        disabled: false,
        page: 'AdvancedServicesPage',
        active: false,
      },
      {
        name: this.farmStr,
        disabled: false,
        page: 'DashboardPage',
        active: false,
      },
      {
        name: this.$t('meteorology'),
        disabled: true,
        page: 'WeatherGraphsPage',
        active: true,
      },
    ]);

    try {
      const data = await this.getClimateData({
        initDate: format(
          new Date().setDate(new Date().getDate() - 7),
          "yyyy-MM-dd'T'HH:mm:ss"
        ),
        endDate: format(
          new Date().setDate(new Date().getDate() + 7),
          "yyyy-MM-dd'T'HH:mm:ss"
        ),
        parameters: this.parameters,
        grouped: '',
        formula: '',
      });

      const measured = this.formatParamsMeasured(data);
      const forecast = this.formatParamsForecasted(data);
      this.data = measured.concat(forecast);

      EventBus.$on('WeatherGraphsPageFilter', async (params) => {
        try {
          this.loaded = false;
          this.$f7.preloader.show();

          if (params) {
            const newData = await this.getClimateData({
              initDate: format(
                params.init_date ||
                  new Date().setDate(new Date().getDate() - 7),
                "yyyy-MM-dd'T'HH:mm:ss"
              ),
              endDate: format(
                params.end_date ||
                  new Date().setDate(new Date().getDate() + 7),
                "yyyy-MM-dd'T'HH:mm:ss"
              ),
              parameters: this.parameters,
              grouped: params.grouped || '',
              formula: params.formula || '',
            });

            const newMeasured = this.formatParamsMeasured(newData);
            const newForecast = this.formatParamsForecasted(newData);
            this.data = newMeasured.concat(newForecast);
          } else {
            const newData = await this.getClimateData({
              initDate: format(
                new Date().setDate(new Date().getDate() - 7),
                "yyyy-MM-dd'T'HH:mm:ss"
              ),
              endDate: format(
                new Date().setDate(new Date().getDate() + 7),
                "yyyy-MM-dd'T'HH:mm:ss"
              ),
              parameters: this.parameters,
              grouped: '',
              formula: '',
            });

            const newMeasured = this.formatParamsMeasured(newData);
            const newForecast = this.formatParamsForecasted(newData);
            this.data = newMeasured.concat(newForecast);
          }
        } catch (e) {
          this.$notifyDX(
            {
              message: this.$t(
                this.$helpers.getFilteredErrorMessage(
                  JSON.parse(e.message).error
                )
              ),
              width: 550,
            },
            'error',
            NOTIFY_TIME_LONG
          );
        } finally {
          this.loaded = true;
          this.$f7.preloader.hide();
        }
      });
    } catch (e) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(e.message).error
            )
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    } finally {
      this.$f7.preloader.hide();

      this.loaded = true;
    }
  },
  methods: {
    formatParamsMeasured(data) {
      for (const param in data.measured.parameters) {
        const weatherElements = param.split('_');
        const weatherElementUnits = param.split('_');

        let weatherElement = null;
        let weatherElementUnit = null;

        if (weatherElements.length - 1 === 2) {
          weatherElement = `${weatherElements[0]}_${weatherElements[1]}`;
        } else {
          weatherElement = weatherElements[0];
        }
        if (weatherElementUnits.includes('MIN')) {
          weatherElementUnit = 'MIN';
        } else if (weatherElementUnits.includes('MAX')) {
          weatherElementUnit = 'MAX';
        }

        let key = '';
        if (!weatherElementUnit || !weatherElement) {
          key = `avgmeasured${param.toLowerCase()}`;
        } else {
          key = `${weatherElementUnit.toLowerCase()}measured${weatherElement.toLowerCase()}`;
        }

        if (key.endsWith('_avg')) {
          const index = key.indexOf('_avg');
          key = key.substring(0, index);
        } else if (key.endsWith('_min')) {
          const index = key.indexOf('_min');
          key = key.substring(0, index);
        } else if (key.endsWith('_max')) {
          const index = key.indexOf('_max');
          key = key.substring(0, index);
        }

        data.measured.parameters[param] = data.measured.parameters[
          param
        ].map((parameter) => {
          return {
            unit: parameter.unit,
            [key]: parameter.value,
            date: parameter.date,
          };
        });
      }

      const paramsFormatted = [];
      for (const param in data.measured.parameters) {
        paramsFormatted.push(...data.measured.parameters[param]);
      }

      return paramsFormatted;
    },
    formatParamsForecasted(data) {
      for (const param in data.forecast.parameters) {
        const weatherElements = param.split('_');
        const weatherElementUnits = param.split('_');

        let weatherElementUnit = null;
        let weatherElement = null;

        if (weatherElements.length - 1 === 2) {
          weatherElement = `${weatherElements[0]}_${weatherElements[1]}`;
        } else {
          weatherElement = weatherElements[0];
        }

        if (weatherElementUnits.includes('MIN')) {
          weatherElementUnit = 'MIN';
        } else if (weatherElementUnits.includes('MAX')) {
          weatherElementUnit = 'MAX';
        }

        let key = '';
        if (!weatherElementUnit || !weatherElement) {
          key = `avgforecast${param.toLowerCase()}`;
        } else {
          key = `${weatherElementUnit.toLowerCase()}forecast${weatherElement.toLowerCase()}`;
        }

        if (key.endsWith('_avg')) {
          const index = key.indexOf('_avg');
          key = key.substring(0, index);
        } else if (key.endsWith('_min')) {
          const index = key.indexOf('_min');
          key = key.substring(0, index);
        } else if (key.endsWith('_max')) {
          const index = key.indexOf('_max');
          key = key.substring(0, index);
        }

        data.forecast.parameters[param] = data.forecast.parameters[
          param
        ].map((parameter) => {
          return {
            unit: parameter.unit,
            [key]: parameter.value,
            date: parameter.date,
          };
        });
      }

      const paramsFormatted = [];
      for (const param in data.forecast.parameters) {
        paramsFormatted.push(...data.forecast.parameters[param]);
      }

      return paramsFormatted;
    },
    ...mapActions('weather', ['getClimateData']),
    ...mapActions('filter', ['setCurrentFilter']),
    ...mapActions('breadcrumb', ['setCurrentRoute']),
  },
};
</script>
