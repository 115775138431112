<template>
  <div>
    <f7-row>
      <f7-col>
        <div class="ha-card card">
          <f7-row>
            <f7-col>
              <div class="ha-card__title">
                {{ $t('userInfo') }}
              </div>
            </f7-col>
          </f7-row>
          <f7-row>
            <f7-col class="marginTop40">
              <div v-if="userInfo.avatar" class="editUser">
                <Avatar :size="100" :src="userInfo.avatar" />
              </div>
            </f7-col>
            <f7-col>
              <div class="ha-card__content">
                <DxForm
                  id="user-personal-info"
                  class="ha-form"
                  :col-count="1"
                  :form-data="userInfo"
                >
                  <DxSimpleItem
                    class="ha-form__first-name"
                    :editor-options="{ disabled: true }"
                    data-field="name"
                  >
                    <DxLabel :text="$t('name')" />
                  </DxSimpleItem>

                  <DxSimpleItem
                    class="ha-form__last-name"
                    :editor-options="{ disabled: true }"
                    data-field="surname"
                  >
                    <DxLabel :text="$t('surname')" />
                  </DxSimpleItem>
                  <DxSimpleItem
                    class="recepDate"
                    :editor-options="{ disabled: true }"
                    data-field="language"
                  >
                    <DxLabel class="hola" :text="$t('language')" />
                  </DxSimpleItem>
                </DxForm>
              </div>
            </f7-col>

            <f7-col>
              <div class="ha-card__content">
                <DxForm
                  id="user-personal-info"
                  class="ha-form"
                  :col-count="1"
                  :form-data="userInfo"
                >
                  <DxSimpleItem
                    class="ha-form__phone"
                    :editor-options="{
                      mask: '+34 000-000-000',
                      maskRules: { X: /[02-9]/ },
                      disabled: true,
                    }"
                    data-field="phone"
                  >
                    <DxLabel :text="$t('phone')" />
                  </DxSimpleItem>

                  <DxSimpleItem
                    class="ha-form__email"
                    data-field="email"
                    :editor-options="{ disabled: true }"
                  >
                    <DxLabel :text="$t('email')" />
                  </DxSimpleItem>
                </DxForm>
              </div>
            </f7-col>
          </f7-row>
        </div>
      </f7-col>
    </f7-row>
    <f7-row>
      <f7-col>
        <div class="ha-card ha-card--addresses card">
          <div class="ha-card__title">
            {{ $t('addresses') }}
          </div>
          <div class="ha-card__content">
            <AddressesTreeList />
          </div>
        </div>
      </f7-col>
    </f7-row>
  </div>
</template>

<script>
import { DxForm, DxSimpleItem, DxLabel } from 'devextreme-vue/form';
import * as overlay from 'devextreme/ui/overlay';
import roles from '@/json/roles.json';
import agronomyPermissions from '@/json/agronomyPermissions';
import alimentaryPermissions from '@/json/alimentaryPermissions';
import environmentPermissions from '@/json/environmentPermissions';
import miningPermissions from '@/json/miningPermissions';

import AddressesTreeList from '@/components/UserForm/AddressesTreeList';
import Avatar from 'vue-avatar';

import { createNamespacedHelpers } from 'vuex';

const { mapState, mapActions, mapGetters } = createNamespacedHelpers(
  'company'
);

export default {
  components: {
    DxForm,
    DxSimpleItem,
    AddressesTreeList,
    DxLabel,
    Avatar,
  },
  data() {
    return {
      roles,
      recursive: true,
      agronomyPermissions,
      alimentaryPermissions,
      environmentPermissions,
      miningPermissions,
      validationRules: {},
    };
  },
  computed: {
    ...mapState(['addressesSelectedRowKeys']),
    ...mapGetters(['userInfo']),
  },
  beforeMount() {
    overlay.baseZIndex(9999);
    if (this.userInfo.role === 'Custom') {
      this.enablePermissionsTrees();
    }
  },

  methods: {
    ...mapActions([
      'updateAgronomySelectedKeys',
      'updateAlimentarySelectedKeys',
      'updateMiningSelectedKeys',
      'updateEnvironmentSelectedKeys',
      'enablePermissionsTrees',
      'clearPermissionsTrees',
      'clearAddresses',
      'createUser',
      'disablePermissionsTrees',
      'updateUser',
    ]),
    roleChanged() {
      if (
        Object.keys(this.userInfo).length > 0 &&
        this.userInfo.role
      ) {
        const selectedRole = this.userInfo.role;
        const selectedATC = selectedRole.split(' ')[0].toLowerCase();
        switch (selectedATC) {
          case 'agronomy':
            this.updateAgronomySelectedKeys([1]);
            break;

          case 'alimentary':
            this.updateAlimentarySelectedKeys([1]);
            break;

          case 'mining':
            this.updateMiningSelectedKeys([1]);
            break;

          case 'environment':
            this.updateEnvironmentSelectedKeys([1]);
            break;
          case 'custom':
            this.enablePermissionsTrees();
            break;
          default:
            break;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './UserEditForm.styles.scss';

.flex-center {
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
