<template>
  <div>
    <DxButton
      :id="id"
      :width="50"
      class="standardOutlinedBtn"
      type="normal"
      styling-mode="outlined"
      style="padding: 2px"
    >
      <svg
        width="20"
        height="15"
        viewBox="0 0 20 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0H20V2.5H0V0ZM0 6.25H20V8.75H0V6.25ZM0 12.5H20V15H0V12.5Z"
          fill="#23465E"
        />
      </svg>
    </DxButton>
    <DxContextMenu
      :data-source="items"
      :target="'#' + id"
      show-event="dxclick"
      @item-click="itemClick"
    >
      <template #item="{ data }">
        <div class="context-menu-item">
          <Icons :name="data.icon" />
          <span class="padding-left-half">{{ data.text }}</span>
        </div>
      </template>
    </DxContextMenu>
  </div>
</template>

<script>
import DxButton from 'devextreme-vue/button';
import DxContextMenu from 'devextreme-vue/context-menu';
import Icons from '@/components/contextMenu/icons.vue';

export default {
  name: 'ContextMenu',
  components: {
    DxButton,
    DxContextMenu,
    Icons,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: 'btnDownloadFoodDetails',
    },
  },
  methods: {
    itemClick(e) {
      const { itemData } = e;
      itemData?.onItemClick?.();
    },
  },
};
</script>

<style lang="scss" scoped>
@import './contextMenu.styles.scss';
</style>
