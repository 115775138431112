<template>
  <div>
    <DxPopup
      :visible="showReportPopup"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-close-button="true"
      :show-title="true"
      height="auto"
      width="700"
      container=".dx-viewport"
      :title="$t('alert.sampleSummary')"
      @hiding="hide"
    >
      <div style="padding: 15px">
        <f7-row>
          <f7-col>
            <f7-row>
              <f7-col>
                <div style="padding: 5px">
                  <strong>{{ $t('company') }}:</strong>
                  {{ companyName }}
                </div>
              </f7-col>
            </f7-row>
          </f7-col>
          <f7-col>
            <f7-row>
              <f7-col>
                <div style="padding: 5px">
                  <strong>{{ $t('address') }}:</strong>
                  {{ addressName }}
                </div>
              </f7-col>
            </f7-row>
          </f7-col>
        </f7-row>

        <f7-row>
          <f7-col>
            <f7-row>
              <f7-col>
                <div style="padding: 5px">
                  <strong>
                    {{ $t('agronomySample.summary.sampleCode') }}:
                  </strong>
                  {{ details.sample_code }}
                </div>
              </f7-col>
            </f7-row>
          </f7-col>
        </f7-row>

        <f7-row>
          <f7-col>
            <f7-row>
              <f7-col>
                <div style="padding: 5px">
                  <strong>{{ $t('atc') }}:</strong>
                  {{ details.atc }}
                </div>
              </f7-col>
            </f7-row>
          </f7-col>

          <f7-col>
            <f7-row>
              <f7-col>
                <div style="padding: 5px">
                  <strong>
                    {{ $t('agronomySample.summary.sampleType') }}:
                  </strong>
                  {{ details.sample_type }}
                </div>
              </f7-col>
            </f7-row>
          </f7-col>
        </f7-row>

        <f7-row>
          <f7-col>
            <f7-row>
              <f7-col>
                <div style="padding: 5px">
                  <strong>
                    {{ $t('environmentSamples.samplingPoint') }}:
                  </strong>
                  {{ details.parcel }}
                </div>
              </f7-col>
            </f7-row>
          </f7-col>
          <f7-col>
            <f7-row>
              <f7-col>
                <div style="padding: 5px">
                  <strong>
                    {{ $t('foodSamples.samplingSite') }}:
                  </strong>
                  {{ details.farm }}
                </div>
              </f7-col>
            </f7-row>
          </f7-col>
        </f7-row>

        <f7-row>
          <f7-col>
            <f7-row>
              <f7-col>
                <div style="padding: 5px">
                  <strong>
                    {{ $t('agronomySample.summary.description') }}:
                  </strong>
                  {{ details.sample_name || '' }}
                </div>
              </f7-col>
            </f7-row>
          </f7-col>
        </f7-row>

        <f7-row>
          <f7-col>
            <f7-row>
              <f7-col>
                <div style="padding: 5px">
                  <strong> {{ $t('project') }}: </strong>
                  {{ details.study || '' }}
                </div>
              </f7-col>
            </f7-row>
          </f7-col>
        </f7-row>

        <f7-row>
          <SampleDetailsTable
            class="marginTop15"
            :details="details"
          />
        </f7-row>

        <f7-row>
          <f7-col></f7-col>
          <f7-col>
            <DxButton
              class="standard-outlined-button marginTop15"
              :use-submit-behavior="true"
              :text="$t('alert.edit')"
              type="outlined"
              @click="goToAlertEdit"
            />
          </f7-col>
          <f7-col>
            <DxButton
              class="standardBtn marginTop15"
              :use-submit-behavior="true"
              :text="$t('viewSample')"
              type="outlined"
              @click="goToSampleDetailsWithFilters"
            />
          </f7-col>
          <f7-col></f7-col>
        </f7-row>
      </div>
    </DxPopup>
  </div>
</template>

<script>
import { DxPopup } from 'devextreme-vue/popup';
import { mapActions, mapState } from 'vuex';

import DxButton from 'devextreme-vue/button';
import { ATCS_ES } from '@/js/constants';
import SampleDetailsTable from './Table/index.vue';

export default {
  components: {
    DxPopup,
    SampleDetailsTable,
    DxButton,
  },
  props: {
    details: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('alertsSample', ['showReportPopup']),
    ...mapState('contact', ['companyName', 'addressName']),
  },
  beforeMount() {},
  methods: {
    hide() {
      this.setShowReportPopup(false);
    },
    goToAlertEdit() {
      this.setShowReportPopup(false);
      this.setFocusedRowKey(this.details.sample_alert);

      this.$f7router.navigate(
        {
          name: 'AlertsSamplePage',
        },
        {
          animate: true,
          transition: 'f7-fade',
        }
      );
    },
    async goToSampleDetailsWithFilters() {
      this.setCurrentSampleId(this.details.sample);
      await this.getCurrentSample();
      this.setShowReportPopup(false);

      await this.goToSampleDetailsByAtc(this.details.atc);
    },
    async goToSampleDetailsByAtc(atcName) {
      if (atcName === ATCS_ES.food) {
        this.setCurrentFilter('foodDetails');
        this.setFoodDetailsSelectedParameters([]);

        if (this.details.parameters[0].cma_value !== null) {
          this.setFoodDetailsSelectedLimits(['> CMA']);
        } else {
          this.setFoodDetailsSelectedLimits(['> LMR']);
        }

        this.$f7router.navigate(
          {
            name: 'FoodSampleDetailPage',
          },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );

        this.applyCurrentFilters();
      } else if (atcName === ATCS_ES.agronomy) {
        this.setCurrentFilter('agronomy');

        this.$f7router.navigate(
          {
            name: 'AgronomySamplesDetailsPage',
          },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );
      } else if (atcName === ATCS_ES.environment) {
        this.setCurrentFilter('environmentDetails');

        this.setEnvironmentDetailsSelectedParameters([]);

        this.setEnvironmentSelectedLimits(['> CMA']);

        this.$f7router.navigate(
          {
            name: 'EnvironmentSamplesDetailsPage',
          },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );

        this.applyCurrentFilters();
      } else if (atcName === ATCS_ES.mining) {
        this.setCurrentFilter('miningDetails');

        this.setMiningDetailsSelectedParameters([]);

        this.$f7router.navigate(
          {
            name: 'MiningSamplesDetailsPage',
          },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );

        this.applyCurrentFilters();
      } else if (atcName === ATCS_ES.healthAndSecurity) {
        this.setCurrentFilter('healthSecurityDetails');

        this.setHealthSecurityDetailsSelectedParameters([]);
        this.setHealthSecuritySelectedLimits(['> CMA']);

        this.$f7router.navigate(
          {
            name: 'HealthSecuritySamplesDetailsPage',
          },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );

        this.applyCurrentFilters();
      }
    },
    ...mapActions('alertsSample', [
      'setShowReportPopup',
      'setFocusedRowKey',
    ]),
    ...mapActions('filter', [
      'setCurrentFilter',
      'setCurrentSampleId',
    ]),
    ...mapActions('samples', [
      'setReportSampleId',
      'getCurrentSample',
    ]),
    ...mapActions('foodDetailsFilter', {
      setFoodDetailsSelectedLimits: 'setSelectedLimits',
      setFoodDetailsSelectedParameters: 'setSelectedParameters',
    }),
    ...mapActions('environmentDetailsFilter', {
      setEnvironmentDetailsSelectedParameters:
        'setSelectedParameters',
      setEnvironmentSelectedLimits: 'setSelectedLimits',
    }),
    ...mapActions('miningDetailsFilter', {
      setMiningDetailsSelectedParameters: 'setSelectedParameters',
    }),
    ...mapActions('healthSecurityDetailsFilter', {
      setHealthSecuritySelectedLimits: 'setSelectedLimits',
      setHealthSecurityDetailsSelectedParameters:
        'setSelectedParameters',
    }),
    ...mapActions('filter', ['applyCurrentFilters']),
  },
};
</script>
