import Api from '@/services/Api';
import EventBus from '@/services/event-bus';

export default {
  namespaced: true,
  state: {
    comparatorDs: [],
    leftComparator: '',
    rightComparator: '',
    leftParcel: {},
    rightParcel: {},
    timeLineIndexInitDate: null,
    timeLineIndexEndDate: null,
  },
  actions: {
    async getFarms({ rootState }, params = null) {
      try {
        const xhr = await Api.getComparativeGisTable(
          rootState.contact.company,
          rootState.contact.address,
          params
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getGisAttributeList({ rootState }, value) {
      try {
        const xhr = await Api.getGisAttributeList(
          value,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    setTimeLineIndexInitDate({ commit }, value) {
      commit('setTimeLineIndexInitDate', value);
    },
    setTimeLineIndexEndDate({ commit }, value) {
      commit('setTimeLineIndexEndDate', value);
    },
    setLeftParcel({ commit }, value) {
      commit('setLeftParcel', value);
    },
    setRightParcel({ commit }, value) {
      commit('setRightParcel', value);
    },
    setComparatorDs({ commit }, value) {
      commit('setComparatorDs', value);
    },
    setLeftComparator({ commit }, value) {
      commit('setLeftComparator', value);
    },
    setRightComparator({ commit }, value) {
      commit('setRightComparator', value);
    },
    updateLeftComparator({ state }, id) {
      for (const farm of state.comparatorDs) {
        if (farm.parcel_id === id) {
          if (!farm.leftComparator) {
            farm.leftComparator = true;
            state.leftComparator = farm.parcel_id;
          } else {
            farm.leftComparator = false;
            state.leftComparator = '';
          }
        } else {
          farm.leftComparator = false;
        }
      }
    },
    updateRightComparator({ state }, id) {
      for (const farm of state.comparatorDs) {
        if (farm.parcel_id === id) {
          if (!farm.rightComparator) {
            farm.rightComparator = true;
            state.rightComparator = farm.parcel_id;
          } else {
            farm.rightComparator = false;
            state.rightComparator = '';
          }
        } else {
          farm.rightComparator = false;
        }
      }
    },
    callStoreWithFilters({ commit }, params) {
      EventBus.$emit('GisComparatorPage', params);
    },
  },
  mutations: {
    setComparatorDs(state, value) {
      state.comparatorDs = value;
    },
    setTimeLineIndexInitDate(state, value) {
      state.timeLineIndexInitDate = value;
    },
    setTimeLineIndexEndDate(state, value) {
      state.timeLineIndexEndDate = value;
    },
    setLeftParcel(state, value) {
      state.leftParcel = value;
    },
    setRightParcel(state, value) {
      state.rightParcel = value;
    },
    setLeftComparator(state, value) {
      state.leftComparator = value;
    },
    setRightComparator(state, value) {
      state.rightComparator = value;
    },
  },
};
