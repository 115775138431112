var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"filterButtonPosition",staticStyle:{"right":"64px"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[_c('div',[_c('a',{staticClass:"analysis-risk-info",attrs:{"id":"analysis-risk-info"},on:{"mouseenter":_vm.toggleWithAnimationOptions,"mouseleave":_vm.toggleWithAnimationOptions}},[_c('i',{staticClass:"f7-icons"},[_vm._v("info_circle_fill")])])]),_vm._v(" "),_c('f7-button',{staticClass:"whiteFontColor button-filter",attrs:{"id":"panel-right-risk-btn","fill":"","panel-toggle":"right"}},[_c('span',{staticClass:"ic-filter"}),_vm._v(" "+_vm._s(_vm.$t('filter'))+"\n      ")])],1)]),_vm._v(" "),_c('DxDataGrid',{attrs:{"data-source":_vm.data,"show-borders":true,"allow-column-reordering":false,"column-auto-width":true,"show-colon":false,"remote-operations":false,"hover-state-enabled":true,"show-row-lines":true,"row-alternation-enabled":true},on:{"toolbar-preparing":_vm.onToolbarPreparing,"exporting":_vm.onExporting},scopedSlots:_vm._u([{key:"templateCountry",fn:function({ data }){return [_c('div',{staticStyle:{"text-align":"center"}},[_c('span',[_vm._v("\n          "+_vm._s(data.data[
              `lmr${
                _vm.selectedOriginCountry != null
                  ? _vm.selectedOriginCountry.code
                  : _vm.userProfile.userCountry
              }`
            ])+"\n        ")])])]}},{key:"params",fn:function({ data }){return [_c('div',[_vm._v("\n        "+_vm._s(data.data.Parametro)+"\n      ")])]}},{key:"templateDestination",fn:function({ data }){return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('div',{class:{
            lowRisk:
              +data.data[`lmr${_vm.getOriginCountry}`] <
              +data.data[`lmr${data.column.caption}`],
            avgRisk:
              +data.data[`lmr${_vm.getOriginCountry}`] ==
              +data.data[`lmr${data.column.caption}`],
            highRisk:
              +data.data[`lmr${_vm.getOriginCountry}`] >
              +data.data[`lmr${data.column.caption}`],
          }},[_vm._v("\n          "+_vm._s(data.data[`lmr${data.column.caption}`])+"\n        ")])])]}}])},[_c('DxScrolling',{attrs:{"mode":"standard"}}),_vm._v(" "),_c('DxStateStoring',{attrs:{"enabled":true,"type":"localStorage","storage-key":"riskAnalysisTable"}}),_vm._v(" "),_c('DxPaging',{attrs:{"page-size":20}}),_vm._v(" "),_c('DxPager',{attrs:{"show-page-size-selector":true,"allowed-page-sizes":[5, 10, 20],"show-info":true,"visible":true,"info-text":"{2} items"}}),_vm._v(" "),_c('DxSearchPanel',{attrs:{"visible":true,"width":300,"placeholder":_vm.$t('search')}}),_vm._v(" "),_c('DxExport',{attrs:{"enabled":true}}),_vm._v(" "),_c('DxGrouping'),_vm._v(" "),_c('DxGroupPanel',{attrs:{"visible":false}}),_vm._v(" "),_c('DxColumn',{attrs:{"caption":_vm.selectedSampleTypes !== null
          ? `${_vm.$t('agronomySample.summary.sampleType')}: ${
              _vm.selectedSampleTypes.name
            }`
          : _vm.$t('agronomySample.summary.sampleType')}},[_c('DxColumn',{attrs:{"name":_vm.data.Parametro,"caption":_vm.$t('foodSamples.parameter'),"data-field":"Parametro"}})],1),_vm._v(" "),_c('DxColumn',{attrs:{"caption":_vm.$t('originCountry'),"alignment":"center","data-field":"originCountryMain"}},[_c('DxColumn',{attrs:{"caption":_vm.getOriginCountryTranslated,"alignment":"center","cell-template":"templateCountry","data-field":"originCountry"}})],1),_vm._v(" "),_c('DxColumn',{attrs:{"caption":_vm.$t('destinationCountry'),"alignment":"center","data-field":"destinationCountryMain"}},_vm._l((_vm.selectedDestinationCountries),function(columnData){return _c('DxColumn',{key:columnData,attrs:{"caption":_vm.getDestinationCountryTranslated(columnData),"alignment":"center","cell-template":"templateDestination","data-field":"destinationCountry"}})}),1)],1),_vm._v(" "),_c('DxPopover',{attrs:{"width":200,"visible":_vm.withAnimationOptionsVisible,"animation":_vm.animationConfig,"target":"#analysis-risk-info","position":"bottom"}},[_c('div',{staticStyle:{"padding":"10px"}},[_c('f7-row',[_c('f7-col',[_c('div',{staticClass:"highRisk",staticStyle:{"height":"20px","margin-bottom":"10px"}})]),_vm._v(" "),_c('f7-col',[_vm._v(" "+_vm._s(_vm.$t('highRisk'))+" ")])],1),_vm._v(" "),_c('f7-row',[_c('f7-col',[_c('div',{staticClass:"avgRisk",staticStyle:{"height":"20px","margin-bottom":"10px"}})]),_vm._v(" "),_c('f7-col',[_vm._v(" "+_vm._s(_vm.$t('avgRisk'))+" ")])],1),_vm._v(" "),_c('f7-row',[_c('f7-col',[_c('div',{staticClass:"lowRisk",staticStyle:{"height":"20px","margin-bottom":"10px"}})]),_vm._v(" "),_c('f7-col',[_vm._v(" "+_vm._s(_vm.$t('lowRisk'))+" ")])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }