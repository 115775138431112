import Api from '@/services/Api';
import EventBus from '@/services/event-bus';

export default {
  namespaced: true,
  state: {
    parameters: [
      'TEMPERATURE_MIN',
      'WIND_SPEED_MAX',
      'TEMPERATURE_MAX',
      'PLUVIOMETER',
      'HUMIDITY',
      'TEMPERATURE',
      'WIND_SPEED',
      'SOLAR_RADIATION',
    ],
    station: '',
  },
  actions: {
    async getClimateData({ rootState }, data) {
      try {
        const xhr = await Api.getClimateData(
          rootState.registerFarm.farm,
          data,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    setStation({ commit }, value) {
      commit('setStation', value);
    },
    async getWidgetData({ rootState }, data) {
      try {
        const xhr = await Api.getWeatherWidgetData(
          rootState.registerFarm.farm,
          data,
          rootState.contact.company,
          rootState.contact.address
        );
        const response = JSON.parse(xhr.response);

        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    callWithFilters({ state }, params) {
      EventBus.$emit('WeatherGraphsPageFilter', params);
    },
  },
  mutations: {
    setStation(state, value) {
      state.station = value;
    },
  },
};
