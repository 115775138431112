<template>
  <div>
    <DxTreeList
      id="addressesTreeList"
      ref="addressesTreeList"
      :data-source="availableAddresses"
      :show-row-lines="false"
      :show-borders="false"
      :column-auto-width="true"
      :expanded-row-keys="expandedRowKeys"
      :selected-row-keys.sync="addressesSelectedRowKeys"
      key-expr="code"
      parent-id-expr="head_code"
    >
      <DxSelection :recursive="true" mode="multiple" />
      <DxColumn data-field="name" caption="GLOBAL BIOTECH, S.L" />
    </DxTreeList>
  </div>
</template>

<script>
import {
  DxTreeList,
  DxSelection,
  DxColumn,
} from 'devextreme-vue/tree-list';

import { mapGetters, mapState } from 'vuex';

export default {
  components: {
    DxTreeList,
    DxSelection,
    DxColumn,
  },
  data() {
    return {
      expandedRowKeys: [1],
    };
  },

  computed: {
    addressesSelectedRowKeys: {
      get() {
        return this.$store.state.company.addressesSelectedRowKeys;
      },
      set(value) {
        this.$store.commit(
          'company/SET_ADDRESSES_SELECTED_KEYS',
          value
        );
      },
    },
    ...mapGetters('company', ['availableAddresses']),
    ...mapState('company', ['userToEdit']),
  },
};
</script>

<style lang="scss" scoped></style>
