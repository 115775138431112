var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.loaded)?_c('div',[_vm._m(0)]):_c('div',[_c('div',{staticClass:"dashboard-content"},[_c('div',{staticClass:"card no-margin-horizontal no-margin-top"},[_c('div',{staticClass:"card-header"},[_vm._v("\n          "+_vm._s(_vm.$t('Food'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"card-content"},[_vm._v("\n          "+_vm._s(_vm.$t('inProcess'))+"\n          "),_c('span',{staticClass:"badge",class:{
              'badge-nodata':
                _vm.aggregateInfo[1][_vm.types.food].in_process === 0,
            }},[_vm._v(_vm._s(_vm.aggregateInfo[1][_vm.types.food].in_process)+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"card-content"},[_vm._v("\n          "+_vm._s(_vm.$t('analyzed'))+"\n          "),_c('span',{staticClass:"badge",class:{
              'badge-nodata':
                _vm.aggregateInfo[1][_vm.types.food].analyzed === 0,
            }},[_vm._v(_vm._s(_vm.aggregateInfo[1][_vm.types.food].analyzed)+"\n          ")])])]),_vm._v(" "),_c('div',{staticClass:"card no-margin-horizontal no-margin-top"},[_c('div',{staticClass:"card-header"},[_vm._v("\n          "+_vm._s(_vm.$t('Agronomy'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"card-content"},[_vm._v("\n          "+_vm._s(_vm.$t('inProcess'))+"\n          "),_c('span',{staticClass:"badge",class:{
              'badge-nodata':
                _vm.aggregateInfo[0][_vm.types.agronomy].in_process === 0,
            }},[_vm._v(_vm._s(_vm.aggregateInfo[0][_vm.types.agronomy].in_process)+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"card-content"},[_vm._v("\n          "+_vm._s(_vm.$t('analyzed'))+"\n          "),_c('span',{staticClass:"badge",class:{
              'badge-nodata':
                _vm.aggregateInfo[0][_vm.types.agronomy].analyzed === 0,
            }},[_vm._v(_vm._s(_vm.aggregateInfo[0][_vm.types.agronomy].analyzed)+"\n          ")])])]),_vm._v(" "),_c('div',{staticClass:"card no-margin-horizontal no-margin-top"},[_c('div',{staticClass:"card-header"},[_vm._v("\n          "+_vm._s(_vm.$t('Environment'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"card-content"},[_vm._v("\n          "+_vm._s(_vm.$t('inProcess'))+"\n          "),_c('span',{staticClass:"badge",class:{
              'badge-nodata':
                _vm.aggregateInfo[2][_vm.types.environment].in_process ===
                0,
            }},[_vm._v(_vm._s(_vm.aggregateInfo[2][_vm.types.environment].in_process)+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"card-content"},[_vm._v("\n          "+_vm._s(_vm.$t('analyzed'))+"\n          "),_c('span',{staticClass:"badge",class:{
              'badge-nodata':
                _vm.aggregateInfo[2][_vm.types.environment].analyzed === 0,
            }},[_vm._v(_vm._s(_vm.aggregateInfo[2][_vm.types.environment].analyzed)+"\n          ")])])]),_vm._v(" "),_c('div',{staticClass:"card no-margin-horizontal no-margin-top"},[_c('div',{staticClass:"card-header"},[_vm._v("\n          "+_vm._s(_vm.$t('Mining'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"card-content"},[_vm._v("\n          "+_vm._s(_vm.$t('inProcess'))+"\n          "),_c('span',{staticClass:"badge",class:{
              'badge-nodata':
                _vm.aggregateInfo[3][_vm.types.mining].in_process === 0,
            }},[_vm._v(_vm._s(_vm.aggregateInfo[3][_vm.types.mining].in_process))])]),_vm._v(" "),_c('div',{staticClass:"card-content"},[_vm._v("\n          "+_vm._s(_vm.$t('analyzed'))+"\n          "),_c('span',{staticClass:"badge",class:{
              'badge-nodata':
                _vm.aggregateInfo[3][_vm.types.mining].analyzed === 0,
            }},[_vm._v(_vm._s(_vm.aggregateInfo[3][_vm.types.mining].analyzed))])])]),_vm._v(" "),_c('div',{staticClass:"card no-margin-horizontal no-margin-top"},[_c('div',{staticClass:"card-header"},[_vm._v("\n          "+_vm._s(_vm.$t('Health and Security'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"card-content"},[_vm._v("\n          "+_vm._s(_vm.$t('inProcess'))+"\n          "),_c('span',{staticClass:"badge",class:{
              'badge-nodata':
                _vm.aggregateInfo[4][_vm.types.healthAndSecurity]
                  .in_process === 0,
            }},[_vm._v(_vm._s(_vm.aggregateInfo[4][_vm.types.healthAndSecurity].in_process)+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"card-content"},[_vm._v("\n          "+_vm._s(_vm.$t('analyzed'))+"\n          "),_c('span',{staticClass:"badge",class:{
              'badge-nodata':
                _vm.aggregateInfo[4][_vm.types.healthAndSecurity]
                  .analyzed === 0,
            }},[_vm._v(_vm._s(_vm.aggregateInfo[4][_vm.types.healthAndSecurity].analyzed)+"\n          ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loadingFalse"},[_c('div',{staticClass:"preloader"},[_c('span',{staticClass:"preloader-inner"},[_c('span',{staticClass:"preloader-inner-circle"})])])])
}]

export { render, staticRenderFns }