<template>
  <DxDataGrid
    v-show="showTable"
    class="margin-bottom green-table-style"
    :show-borders="true"
    :data-source="getTableData"
    :allow-column-resizing="true"
    :column-auto-width="true"
    :customize-columns="customizeColumns"
  >
    <DxColumn
      data-field="sample_type"
      :caption="caption"
      :allow-sorting="false"
      :width="170"
    />
    <DxColumn
      data-field="N_Total.value"
      caption="N"
      :allow-sorting="false"
      :min-width="80"
      alignment="right"
    />
    <DxColumn
      data-field="P.value"
      caption="P"
      :allow-sorting="false"
      :min-width="80"
      alignment="right"
    />
    <DxColumn
      data-field="K.value"
      caption="K"
      :allow-sorting="false"
      :min-width="80"
      alignment="right"
    />
    <DxColumn
      data-field="Ca.value"
      caption="Ca"
      :allow-sorting="false"
      :min-width="80"
      alignment="right"
    />
    <DxColumn
      data-field="Mg.value"
      caption="Mg"
      :allow-sorting="false"
      :min-width="80"
      alignment="right"
    />
    <DxColumn
      data-field="S.value"
      caption="S"
      :allow-sorting="false"
      :min-width="80"
      alignment="right"
    />
    <DxColumn
      data-field="Na.value"
      caption="Na"
      :allow-sorting="false"
      :min-width="80"
      alignment="right"
    />
    <DxColumn
      data-field="Cl.value"
      caption="Cl"
      :allow-sorting="false"
      :min-width="80"
      alignment="right"
    />
    <DxColumn
      data-field="B.value"
      caption="B"
      :allow-sorting="false"
      :min-width="80"
      alignment="right"
    />
    <DxColumn
      data-field="Fe.value"
      caption="Fe"
      :allow-sorting="false"
      :min-width="80"
      alignment="right"
    />
    <DxColumn
      data-field="Mn.value"
      caption="Mn"
      :allow-sorting="false"
      :min-width="80"
      alignment="right"
    />
    <DxColumn
      data-field="Cu.value"
      caption="Cu"
      :allow-sorting="false"
      :min-width="80"
      alignment="right"
    />
    <DxColumn
      data-field="Zn.value"
      caption="Zn"
      :allow-sorting="false"
      :min-width="80"
      alignment="right"
    />
    <DxColumn
      data-field="Mo.value"
      caption="Mo"
      :allow-sorting="false"
      :min-width="80"
      alignment="right"
    />
  </DxDataGrid>
</template>

<script>
import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';
import { mapState } from 'vuex';
import format from 'date-fns/format';

export default {
  name: 'DetailedTableFloresFrutos',
  components: {
    DxDataGrid,
    DxColumn,
  },
  props: {
    nutritionalMonitoringData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      tableName: 'Flores_y_frutos',
    };
  },
  computed: {
    showTable() {
      let show = false;

      if (
        this.nutritionalMonitoringData?.[this.tableName]?.data == null
      ) {
        return false;
      }

      this.nutritionalMonitoringData[this.tableName].data.forEach(
        (element) => {
          Object.entries(element?.parameters ?? {}).forEach(
            ([key, value]) => {
              if ((value?.value.ResultadoPresentacion ?? '') !== '') {
                show = true;
              }
            }
          );
        }
      );

      return show;
    },
    caption() {
      const date =
        this.nutritionalMonitoringData?.[this.tableName]
          ?.sampling_date;

      if (date != null) {
        const formattedDate = format(new Date(date), 'dd-MM-yyyy');
        return `${this.$t('sampleTypeFlowers')} (${formattedDate})`;
      }

      return this.$t('sampleTypeFlowers');
    },
    getTableData() {
      if (
        this.nutritionalMonitoringData?.[this.tableName]?.data == null
      ) {
        return [];
      }

      const result = [];

      this.nutritionalMonitoringData[this.tableName].data.forEach(
        (element) => {
          const parameters = {};

          Object.entries(element?.parameters ?? {}).forEach(
            ([key, value]) => {
              parameters[key] = {
                name: value?.parameter ?? '',
                value: value?.value?.ResultadoPresentacion ?? '',
                unit: value?.value?.unit ?? '',
              };
            }
          );

          result.push({
            sample_type: element.sample_type,
            ...parameters,
          });
        }
      );

      return result;
    },
    ...mapState('nutritionalMonitoring', [
      // 'NutritionalMonitoringData',
    ]),
  },
  methods: {
    customizeColumns(columns) {
      if (this.getTableData.length === 0) {
        return;
      }

      const columnsToTranslate = ['MO', 'N_Total'];

      const columnsCustomTranslate = ['MO', 'N_Total'];

      columns.forEach((column) => {
        if (column.dataField !== 'sample_type') {
          const { name, unit } =
            this.getTableData[0][
              column.dataField.replace('.value', '')
            ];

          const dataField = column.dataField.replace('.value', '');
          const { caption } = column;

          if (name === '') {
            column.caption = caption;
          } else {
            const translate = columnsToTranslate.includes(dataField);
            const customTranslate =
              columnsCustomTranslate.includes(dataField);

            let parameter = '';
            if (translate && customTranslate) {
              parameter = this.$t(`sampleTypeParameter${dataField}`);
            } else if (translate && !customTranslate) {
              parameter = name;
            } else {
              parameter = caption;
            }

            if (unit) {
              column.caption = `${parameter} (${unit})`;
            } else {
              column.caption = parameter;
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .dx-header-row td[role='columnheader'] {
  text-transform: none;
  font-size: 11px;
}
</style>
