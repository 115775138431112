import RepositoryFactory from '@/services/repositories/RepositoryFactory';
import Api from '../../services/Api';

const companyRepository = RepositoryFactory.get('company');

export default {
  namespaced: true,
  state: {
    users: [],
    userToEdit: {},
    agronomySelectedRowKeys: [],
    alimentarySelectedRowKeys: [],
    miningSelectedRowKeys: [],
    environmentSelectedRowKeys: [],
    addressesSelectedRowKeys: [],
    arePermissionsTreesDisabled: true,
  },
  getters: {
    usersInfo(state) {
      const usersInfo = [];
      state.users.forEach((user) => {
        if (user.contact.admin) return;

        const userInfo = {
          id: user.id,
          email: user.email,
          status: '',
          name: '',
          surname: '',
          phone: '',
          role: '',
          addresses: '',
          addressesCodes: [],
          language: '',
          contactCode: '',
          companyCode: '',
          companyId: '',
        };

        if (user.contact.code) {
          userInfo.contactCode = user.contact.code;
        }

        if (user.contact.name) {
          userInfo.name = user.contact.name;
        }
        if (user.contact.surname) {
          userInfo.surname = user.contact.surname;
        }
        if (user.contact.phone && user.contact.phone.length > 0) {
          userInfo.phone = user.contact.phone[0].number;
        }
        if (user.status) {
          userInfo.status = user.status;
        }
        if (user.contact.role) {
          userInfo.role = user.contact.role;
        }

        if (user.contact.company) {
          userInfo.companyCode = user.contact.company.code;
          userInfo.companyId = user.contact.company.id;
        }

        if (user.contact.company.addresses.length > 0) {
          for (
            let i = 0;
            i < user.contact.company.addresses.length;
            i += 1
          ) {
            userInfo.addressesCodes.push({
              code: user.contact.company.addresses[i].code,
              company: userInfo.companyCode,
              addressId: `${userInfo.companyId.trim()}_${user.contact.company.addresses[
                i
              ].code.trim()}`,
            });
            userInfo.addresses += `${user.contact.company.addresses[
              i
            ].address
              .toString()
              .trim()}, `;
          }
        }

        if (user.contact.language) {
          userInfo.language = user.contact.language;
        }

        userInfo.addresses = userInfo.addresses
          .trim()
          .replace(/,\s*$/, '');
        usersInfo.push(userInfo);
      });
      return usersInfo;
    },
    availableAddresses(state, getters, rootState) {
      const addresses = [];
      const addressesArray =
        rootState.user.authenticatedUser.contacts[0].companies[0]
          .addresses;
      addressesArray.forEach((address) => {
        const addressInfo = {
          code: address.code,
          head_code: 0,
          name: address.city,
        };
        addresses.push(addressInfo);
      });
      return addresses;
    },
    userInfo(state) {
      if (Object.keys(state.userToEdit).length > 0) {
        const user = state.userToEdit;
        const userInfo = {
          id: user.id,
          email: user.email,
          name: '',
          surname: '',
          phone: '',
          role: '',
          language: '',
          avatar: '',
        };

        if (user.contact.name) {
          userInfo.name = user.contact.name;
        }
        if (user.contact.surname) {
          userInfo.surname = user.contact.surname;
        }
        if (user.contact.phone && user.contact.phone.length > 0) {
          userInfo.phone = user.contact.phone[0].number;
        }
        if (user.contact.role) {
          userInfo.role = user.contact.role;
        }

        if (
          user.contact.company.country &&
          user.contact.company.language
        ) {
          userInfo.language = `${user.contact.company.country} - ${user.contact.company.language}`;
        }

        if (user.contact.avatar) {
          userInfo.avatar = user.contact.avatar;
        }

        return userInfo;
      }
      return {};
    },
    userPermissionsToEdit(state) {
      if (
        Object.keys(state.userToEdit).length > 0 &&
        Object.keys(state.userToEdit.contact.permissions).length > 0
      ) {
        return state.userToEdit.contact.permissions;
      }
      return {};
    },
    userAddressesToEdit(state) {
      if (
        Object.keys(state.userToEdit).length > 0 &&
        state.userToEdit.contact.company.addresses.length > 0
      ) {
        return state.userToEdit.contact.company.addresses;
      }
      return [];
    },
  },

  mutations: {
    SET_USERS(state, payload) {
      state.users = payload;
    },
    SET_USER_TO_EDIT(state, payload) {
      state.userToEdit = payload;
    },
    SET_AGRONOMY_SELECTED_KEYS(state, payload) {
      state.agronomySelectedRowKeys = payload;
    },
    SET_ALIMENTARY_SELECTED_KEYS(state, payload) {
      state.alimentarySelectedRowKeys = payload;
    },
    SET_MINING_SELECTED_KEYS(state, payload) {
      state.miningSelectedRowKeys = payload;
    },
    SET_ENVIRONMENT_SELECTED_KEYS(state, payload) {
      state.environmentSelectedRowKeys = payload;
    },
    SET_ADDRESSES_SELECTED_KEYS(state, payload) {
      state.addressesSelectedRowKeys = payload;
    },
    CLEAR_PERMISSIONS_TREES(state) {
      state.agronomySelectedRowKeys = [];
      state.alimentarySelectedRowKeys = [];
      state.miningSelectedRowKeys = [];
      state.environmentSelectedRowKeys = [];
    },
    CLEAR_ADDRESSES(state) {
      state.addressesSelectedRowKeys = [];
    },
    CLEAR_USER_TO_EDIT(state) {
      state.userToEdit = {};
    },
    ENABLE_PERMISSIONS_TREES(state) {
      state.arePermissionsTreesDisabled = false;
    },
    DISABLE_PERMISSIONS_TREES(state) {
      state.arePermissionsTreesDisabled = true;
    },
  },
  actions: {
    async getUsers({ commit, rootState }) {
      try {
        const response = await companyRepository.getUsers(
          rootState.user.accessToken,
          rootState.contact.company,
          rootState.contact.address
        );
        const { data } = response.data;
        commit('SET_USERS', data);
        return Promise.resolve(data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async createUser({ state, rootState, rootGetters }, userToAdd) {
      const newUser = {
        email: userToAdd.email,
        name: userToAdd.name,
        surname: userToAdd.surname,
        role: userToAdd.role,
        addresses: state.addressesSelectedRowKeys,
        phone: userToAdd.phone,
      };

      const permissions = [];

      if (state.agronomySelectedRowKeys.length > 0) {
        permissions.push({
          code: 'agronomy',
          value: true,
          childs: [{ code: 'samples', value: true }],
        });
      }

      if (state.alimentarySelectedRowKeys.length > 0) {
        permissions.push({
          code: 'alimentary',
          value: true,
          childs: [{ code: 'samples', value: true }],
        });
      }

      if (state.miningSelectedRowKeys.length > 0) {
        permissions.push({
          code: 'mining',
          value: true,
          childs: [{ code: 'samples', value: true }],
        });
      }

      if (state.environmentSelectedRowKeys.length > 0) {
        permissions.push({
          code: 'environment',
          value: true,
          childs: [{ code: 'samples', value: true }],
        });
      }

      newUser.permissions = permissions;

      try {
        await companyRepository.addUserToCompany(
          rootState.user.accessToken,
          rootGetters['user/selectedCompanyCode'],
          newUser
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async updateUser({ state, rootState, getters, rootGetters }) {
      const newPermissions = [];
      const newAddresses = state.addressesSelectedRowKeys;
      const { accessToken } = rootState.user;
      const companyCode = rootGetters['user/selectedCompanyCode'];

      /**
       * Destructuring with aliases
       *
       * For more info: https://davidwalsh.name/destructuring-alias
       */
      const { id: userID, role: userRole } = getters.userInfo;

      if (state.agronomySelectedRowKeys.length > 0) {
        newPermissions.push({
          code: 'agronomy',
          value: true,
          childs: [{ code: 'samples', value: true }],
        });
      }

      if (state.alimentarySelectedRowKeys.length > 0) {
        newPermissions.push({
          code: 'alimentary',
          value: true,
          childs: [{ code: 'samples', value: true }],
        });
      }

      if (state.miningSelectedRowKeys.length > 0) {
        newPermissions.push({
          code: 'mining',
          value: true,
          childs: [{ code: 'samples', value: true }],
        });
      }

      if (state.environmentSelectedRowKeys.length > 0) {
        newPermissions.push({
          code: 'environment',
          value: true,
          childs: [{ code: 'samples', value: true }],
        });
      }
      try {
        await companyRepository.updateUsersPermissions(
          accessToken,
          companyCode,
          userID,
          userRole,
          newPermissions
        );

        await companyRepository.updateUsersAddresses(
          accessToken,
          companyCode,
          userID,
          newAddresses
        );

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async deleteUser({ rootState, rootGetters }, userID) {
      try {
        await companyRepository.deleteUserFromCompany(
          rootState.user.accessToken,
          rootState.contact.company,
          userID,
          rootState.contact.address
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    updateAgronomySelectedKeys({ commit }, payload) {
      commit('DISABLE_PERMISSIONS_TREES');
      commit('CLEAR_PERMISSIONS_TREES');
      commit('SET_AGRONOMY_SELECTED_KEYS', payload);
    },
    updateAlimentarySelectedKeys({ commit }, payload) {
      commit('DISABLE_PERMISSIONS_TREES');
      commit('CLEAR_PERMISSIONS_TREES');
      commit('SET_ALIMENTARY_SELECTED_KEYS', payload);
    },
    updateMiningSelectedKeys({ commit }, payload) {
      commit('DISABLE_PERMISSIONS_TREES');
      commit('CLEAR_PERMISSIONS_TREES');
      commit('SET_MINING_SELECTED_KEYS', payload);
    },
    updateEnvironmentSelectedKeys({ commit }, payload) {
      commit('DISABLE_PERMISSIONS_TREES');
      commit('CLEAR_PERMISSIONS_TREES');
      commit('SET_ENVIRONMENT_SELECTED_KEYS', payload);
    },
    updateAddressesSelectedKeys({ commit }, payload) {
      commit('SET_ADDRESSES_SELECTED_KEYS', payload);
    },
    enablePermissionsTrees({ commit }) {
      commit('ENABLE_PERMISSIONS_TREES');
    },
    disablePermissionsTrees({ commit }) {
      commit('DISABLE_PERMISSIONS_TREES');
    },
    clearPermissionsTrees({ commit }) {
      commit('CLEAR_PERMISSIONS_TREES');
    },
    clearAddresses({ commit }) {
      commit('CLEAR_ADDRESSES');
    },
    setUserToEdit({ state, commit }, userId) {
      const userToEdit = state.users.filter(
        (user) => user.id === userId
      );
      commit('SET_USER_TO_EDIT', userToEdit[0]);
    },
    setUsersPermissionsToEdit({ getters, commit }) {
      const userPermissions = getters.userPermissionsToEdit;
      const userRole = getters.userInfo.role;
      const arrayOfPermissionsKeys = Object.keys(userPermissions);
      if (arrayOfPermissionsKeys.length > 0) {
        arrayOfPermissionsKeys.forEach((permission) => {
          switch (permission) {
            case 'agronomy':
              commit('SET_AGRONOMY_SELECTED_KEYS', [1]);
              break;
            case 'alimentary':
              commit('SET_ALIMENTARY_SELECTED_KEYS', [1]);
              break;
            case 'environment':
              commit('SET_ENVIRONMENT_SELECTED_KEYS', [1]);
              break;
            case 'mining':
              commit('SET_MINING_SELECTED_KEYS', [1]);
              break;
            default:
              break;
          }
        });
      }
      if (userRole === 'Custom') {
        commit('ENABLE_PERMISSIONS_TREES');
      }
    },
    setUsersAddressesToEdit({ getters, commit }) {
      const userAddresses = getters.userAddressesToEdit;
      if (userAddresses.length > 0) {
        let addressesSelected = userAddresses.map((address) => {
          if (address.is_enabled) {
            return address.code;
          }
        });

        addressesSelected = addressesSelected.filter((addr) => addr);
        commit('SET_ADDRESSES_SELECTED_KEYS', addressesSelected);
      }
    },
    resetStatesForNewEditOrCreate({ commit }) {
      commit('DISABLE_PERMISSIONS_TREES');
      commit('CLEAR_ADDRESSES');
      commit('CLEAR_PERMISSIONS_TREES');
      commit('CLEAR_USER_TO_EDIT');
    },
    async updateUserAddresses({ state, rootState }) {
      const deselectedAddresses = [];

      if (state.userToEdit.contact.company.addresses.length > 0) {
        state.userToEdit.contact.company.addresses.forEach(
          (address) => {
            if (
              !state.addressesSelectedRowKeys.includes(address.code)
            ) {
              deselectedAddresses.push(address.code);
            }
          }
        );
      }

      try {
        await Api.updateUserAddresses(
          state.userToEdit.id,
          state.addressesSelectedRowKeys,
          rootState.contact.company,
          rootState.contact.address
        );

        if (deselectedAddresses.length > 0) {
          await Api.deleteUserAddresses(
            state.userToEdit.id,
            deselectedAddresses,
            rootState.contact.company,
            rootState.contact.address
          );
        }

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};