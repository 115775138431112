var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('DxForm',{staticClass:"input-full"},[_c('DxGroupItem',{attrs:{"col-count":_vm.setThreeCols ? 3 : 2}},[_c('DxSimpleItem',{attrs:{"col-span":1,"data-field":_vm.$t('index'),"caption":_vm.$t('index'),"editor-type":"dxSelectBox","editor-options":{
          items: _vm.indexAvailables,
          value: _vm.currentIndexes[`${_vm.keyId}`],
          placeholder: _vm.$t('index'),
          onValueChanged: _vm.onSelectedIndex,
        }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxRegisterCampania_end_date_require')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"col-span":1},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('f7-input',{staticClass:"recepDate",attrs:{"type":"datepicker","placeholder":_vm.$t('agronomySample.chooseDates'),"clear-button":true,"close-on-select":true,"calendar-params":{
              value: _vm.actualDate,
              closeOnSelect: true,
              rangePicker: false,
              dateFormat: {
                month: 'numeric',
                day: 'numeric',
                year: 'numeric',
              },
              disabled: _vm.disabledDates,
            }},on:{"calendar:change":_vm.onChangeInitDate}})]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }