<template>
  <div>
    <f7-button
      class="goBack"
      outline
      :disabled="disabled"
      name="ButtonBack"
      @click="goBack"
    >
      {{ $t('goBack') }}
    </f7-button>
  </div>
</template>

<script>
export default {
  name: 'GoBack',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    specialCase: {
      type: Boolean,
      default: false,
    },
    goToUrlName: {
      type: Boolean,
      default: false,
    },
    urlName: {
      type: String,
      default: '',
    },
    urlPath: {
      type: String,
      default: '',
    },
    goTo: {
      type: String,
      default: '',
    },
  },
  methods: {
    goBack() {
      if (this.goToUrlName) {
        this.$f7router.navigate(
          { name: this.urlName },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );

        return;
      }

      if (this.specialCase) {
        if (this.$f7router.previousRoute.name === this.urlName) {
          this.$f7router.navigate(
            { name: this.goTo },
            {
              animate: true,
              transition: 'f7-fade',
            }
          );
        } else {
          this.$f7router.navigate(
            { name: this.$f7router.previousRoute.name },
            {
              animate: true,
              transition: 'f7-fade',
            }
          );
        }
      } else {
        this.$f7router.navigate(
          { name: this.$f7router.previousRoute.name },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );
      }
    },
    comesFromHome() {
      const router = this.$f7router;
      const isHome = router.previousRoute.path.toString() === '/';

      return isHome;
    },
    comesFromNotifications() {
      const router = this.$f7router;
      const isNotifications =
        router.previousRoute.name === 'NotificationsDetailsPage';

      return isNotifications;
    },
    comesFromParamReport() {
      const router = this.$f7router;
      const isReports =
        router.previousRoute.name === 'AlertsParamsReportsPage';

      return isReports;
    },
    comesFromSampleReport() {
      const router = this.$f7router;
      const isReports =
        router.previousRoute.name === 'AlertsSampleReportsPage';

      return isReports;
    },
  },
};
</script>
