<template>
  <f7-page>
    <div>
      <GISEditorSigpac
        :tree-locations="centroide ? true : false"
        :disabled-edit-parcel="false"
        :create-sector="centroide ? false : true"
        :zoom="15"
        @disableSaveButton="disableSaveButton"
      />
      <RegisterFarmForm />

      <f7-row class="margin-top-half">
        <f7-col>
          <GoBack
            :special-case="true"
            url-name="AdvancedServicesParcelRegistrationPage"
            go-to="AdvancedServicesPage"
          />
        </f7-col>
        <f7-col style="display: flex; justify-content: flex-end">
          <DxButton
            class="standardBtn marginTop10"
            type="normal"
            styling-mode="outlined"
            :text="!isEditingFarm ? $t('next') : $t('save')"
            :disabled="saveBtnDisabled"
            @click="
              () => {
                if (isEditingFarm) {
                  onSaveEditFarm();
                } else {
                  nextStep();
                }
              }
            "
          />
        </f7-col>
      </f7-row>
    </div>
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import GISEditorSigpac from '@/components/Gis/EditorSigpac/index.vue';
import RegisterFarmForm from '@/components/Agronomy/AdvancedServices/RegisterFarmForm/index.vue';
import GoBack from '@/components/UtilityComponents/GoBack/index.vue';
import DxButton from 'devextreme-vue/button';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  components: {
    GISEditorSigpac,
    RegisterFarmForm,
    GoBack,
    DxButton,
  },
  data() {
    return {
      saveBtnDisabled: false,
    };
  },
  computed: {
    ...mapState('gisEditor', ['centroide']),
    ...mapState('registerFarm', [
      'farm',
      'accountable',
      'isEditingFarm',
      'farmStr',
    ]),
  },
  beforeMount() {
    if (this.isEditingFarm) {
      this.setCurrentRoute([
        {
          name: this.$t('Agronomy'),
          disabled: false,
          page: 'AgronomySamplesPage',
          active: false,
        },
        {
          name: this.$t('advancedServices'),
          disabled: false,
          page: 'AdvancedServicesPage',
          active: false,
        },
        {
          name: this.$t('editFarm'),
          disabled: true,
          page: 'AdvancedServicesPage',
          active: true,
        },
      ]);
    } else {
      this.setCurrentRoute([
        {
          name: this.$t('Agronomy'),
          disabled: false,
          page: 'AgronomySamplesPage',
          active: false,
        },
        {
          name: this.$t('advancedServices'),
          disabled: false,
          page: 'AdvancedServicesPage',
          active: false,
        },
        {
          name: this.$t('registerFarm'),
          disabled: true,
          page: 'AdvancedServicesPage',
          active: true,
        },
      ]);
    }

    this.setParcelIndex(0);
    this.resetParcelGeoFeature([]);
  },
  methods: {
    disableSaveButton(value) {
      this.saveBtnDisabled = value;
    },
    nextStep() {
      if (!this.farm || !this.accountable) {
        this.$notifyDX(
          {
            message: this.$t('fillRequiredFields'),
            width: 350,
          },
          'error',
          NOTIFY_TIME_LONG
        );
        return;
      }

      if (!this.centroide) {
        this.$notifyDX(
          {
            message: this.$t('Gis.mustDrawGeometry'),
            width: 350,
          },
          'error',
          NOTIFY_TIME_LONG
        );

        return;
      }

      this.setParcelArea(null);
      this.setParcelCentroide(null);

      this.$f7router.navigate(
        { name: 'AdvancedServicesParcelRegistrationPage' },
        {
          animate: true,
          transition: 'f7-fade',
        }
      );
    },
    async onSaveEditFarm() {
      try {
        if (!this.farm || !this.accountable) {
          this.$notifyDX(
            {
              message: this.$t('fillRequiredFields'),
              width: 350,
            },
            'error',
            NOTIFY_TIME_LONG
          );
          return;
        }

        if (!this.centroide) {
          this.$notifyDX(
            {
              message: this.$t('Gis.mustDrawGeometry'),
              width: 350,
            },
            'error',
            NOTIFY_TIME_LONG
          );
        }

        this.$f7.preloader.show();

        await this.updateFarmGeoFeature();

        this.$notifyDX(
          {
            message: this.$t('editFarmSuccess'),
            width: 350,
          },
          'success',
          3000
        );

        this.$f7router.navigate(
          { name: 'AdvancedServicesPage' },
          {
            animate: true,
            transition: 'f7-fade',
          }
        );
      } catch (e) {
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                JSON.parse(e.message).error
              )
            ),
            width: 550,
          },
          'error',
          NOTIFY_TIME_LONG
        );
      } finally {
        this.$f7.preloader.hide();
      }
    },
    ...mapActions('breadcrumb', ['setCurrentRoute']),
    ...mapActions('gisEditor', [
      'setParcelArea',
      'setParcelCentroide',
      'setParcelIndex',
      'resetParcelGeoFeature',
    ]),
    ...mapActions('registerFarm', ['updateFarmGeoFeature']),
  },
};
</script>
