<template>
  <f7-page>
    <div v-show="!showComparation" class="gis-actions">
      <f7-row>
        <f7-col
          style="transition: 0.2s"
          :class="{ 'map-full-width': !showToolBox }"
        >
          <div v-if="loadingGeometries" class="placeholder-box">
            <svg class="ring" viewBox="25 25 50 50" stroke-width="5">
              <circle cx="50" cy="50" r="20" />
            </svg>
          </div>
          <div v-else style="position: relative; overflow: hidden">
            <button
              type="button"
              class="button-toggle-menu"
              :class="{ 'button-toggle-menu--active': showMenu }"
              @click="showMenu = !showMenu"
            >
              <svg
                clip-rule="evenodd"
                fill-rule="evenodd"
                stroke-linejoin="round"
                stroke-miterlimit="2"
                viewBox="0 0 24 24"
                width="20"
                height="20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m2.394 15.759s7.554 4.246 9.09 5.109c.165.093.333.132.492.132.178 0 .344-.049.484-.127 1.546-.863 9.155-5.113 9.155-5.113.246-.138.385-.393.385-.656 0-.566-.614-.934-1.116-.654 0 0-7.052 3.958-8.539 4.77-.211.115-.444.161-.722.006-1.649-.928-8.494-4.775-8.494-4.775-.502-.282-1.117.085-1.117.653 0 .262.137.517.382.655zm0-3.113s7.554 4.246 9.09 5.109c.165.093.333.132.492.132.178 0 .344-.049.484-.127 1.546-.863 9.155-5.113 9.155-5.113.246-.138.385-.393.385-.656 0-.566-.614-.934-1.116-.654 0 0-7.052 3.958-8.539 4.77-.211.115-.444.161-.722.006-1.649-.928-8.494-4.775-8.494-4.775-.502-.282-1.117.085-1.117.653 0 .262.137.517.382.655zm10.271-9.455c-.246-.128-.471-.191-.692-.191-.223 0-.443.065-.675.191l-8.884 5.005c-.276.183-.414.444-.414.698 0 .256.139.505.414.664l8.884 5.006c.221.133.447.203.678.203.223 0 .452-.065.689-.203l8.884-5.006c.295-.166.451-.421.451-.68 0-.25-.145-.503-.451-.682zm-8.404 5.686 7.721-4.349 7.72 4.349-7.72 4.35z"
                  fill-rule="nonzero"
                />
              </svg>
            </button>
            <button
              type="button"
              class="button-toggle-menu-tool-box tool-box"
              :class="[
                {
                  'button-toggle-menu-tool-box--active': showMenu,
                },
                {
                  'tool-box--active': showToolBox,
                },
              ]"
              @click="showToolBox = !showToolBox"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                width="20"
                height="20"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5"
                />
              </svg>
            </button>
            <MenuSections
              :key="menuSectionsKey"
              class="hidable-menu"
              :class="{ 'hide-menu': !showMenu }"
              :menu="menu"
            />
            <GeoToolBoxVisor
              :key="geoToolBoxVisorKey"
              :name-map="'map'"
              :tree-locations="geometries"
              :geometries-samples="geometriesSamples"
              @menu-areas="menuAreas"
              @menu-samples="menuSamples"
              @menu-images="menuImages"
              @menu-sets="menuSets"
              @menu-interpolations="menuInterpolations"
            />
          </div>
        </f7-col>
        <f7-col
          v-show="showToolBox"
          :class="{
            'show-tool-box-animation':
              !loadingGeometries && showToolBox,
          }"
        >
          <div v-if="loadingGeometries" class="placeholder-box">
            <svg class="ring" viewBox="25 25 50 50" stroke-width="5">
              <circle cx="50" cy="50" r="20" />
            </svg>
          </div>
          <div v-if="!loadingGeometries" class="tabs-container">
            <div
              class="tab-item"
              :class="{ active: selectedTab === 1 }"
              @click="selectedTab = 1"
            >
              {{ $t('geotoolbox.localization') }}
            </div>
            <div
              class="tab-item"
              :class="{ active: selectedTab === 2 }"
              @click="selectedTab = 2"
            >
              {{ $t('geotoolbox.geotoolbox') }}
            </div>
          </div>
          <div v-if="!loadingGeometries" class="tabs-content">
            <LocationTab
              v-show="selectedTab === 1"
              :samples="geometriesSamples"
              class="padding-half"
            />
            <GeoToolBoxTab
              v-show="selectedTab === 2"
              class="padding-half"
              :menu="menu"
            />
          </div>
        </f7-col>
      </f7-row>
      <GoBack
        class="padding-top-half"
        url-name="DashboardPage"
        :go-to-url-name="true"
      />
    </div>
    <div v-if="showComparation">
      <GisActionComparation
        :geometry="selectedGeometryToCompare"
        :images="imagesToCompare"
        :selected-image="selectedImageToCompare"
      />
      <f7-button
        type="button"
        class="goBack margin-top-half"
        @click="closeComparation()"
      >
        {{ $t('goBack') }}
      </f7-button>
    </div>
  </f7-page>
</template>

<script>
import GeoToolBoxVisor from '@/components/Agronomy/AdvancedServices/GisActions/GeoToolBoxVisor.vue';
import { mapActions, mapState } from 'vuex';
import MenuSections from '@/components/Agronomy/AdvancedServices/GisActions/Menu/MenuSections.vue';
import LocationTab from '@/components/Agronomy/AdvancedServices/GisActions/LocationTab.vue';
import EventBus from '@/services/event-bus';
import moment from 'moment';
import GeoToolBoxTab from '@/components/Agronomy/AdvancedServices/GisActions/GeoToolBoxTab.vue';
import GisActionComparation from '@/components/Agronomy/AdvancedServices/GisActions/GisActionComparation.vue';
import Vue from 'vue';
import GoBack from '@/components/UtilityComponents/GoBack/index.vue';

export default {
  name: 'GisActions',
  components: {
    GeoToolBoxVisor,
    MenuSections,
    LocationTab,
    GeoToolBoxTab,
    GisActionComparation,
    GoBack,
  },
  data() {
    return {
      showMenu: true,
      showToolBox: true,
      menuSectionsKey: 'menu-sections-key',
      loadingGeometries: true,
      geometries: [],
      geometriesSamples: [],
      menu: {
        areas: {},
        samples: {},
        images: {},
        sets: {},
        interpolations: {},
      },
      selectedTab: 1,
      geoToolBoxVisorKey: 'geo-tool-box-visor-key',
      showComparation: false,
      selectedGeometryToCompare: null,
    };
  },
  computed: {
    ...mapState('registerFarm', ['farm', 'farmId']),
    ...mapState('breadcrumb', ['items']),
  },
  watch: {
    showToolBox() {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 200);
    },
  },
  mounted() {
    this.clearFilters();
    this.setCurrentFilter('geotoolbox');

    this.fetchGeometries();
    this.fetchSamplesGeometry();

    if (
      this.items[this.items.length - 1].page ===
      'AgronomySamplesDetailsPage'
    ) {
      this.popRoute();
    }

    EventBus.$on('filter-geotoolbox-samples', (filters) => {
      this.fetchSamplesGeometryWithFilters(filters);
    });

    EventBus.$on('selected-layer-to-compare', (data) => {
      this.selectedGeometryToCompare = data.geometry;
      this.imagesToCompare = data.images;
      this.showComparation = true;
      this.selectedImageToCompare = data?.selected ?? null;
    });
  },
  beforeDestroy() {
    EventBus.$off('filter-geotoolbox-samples');
    EventBus.$off('selected-layer-to-compare');
  },
  methods: {
    async fetchGeometries() {
      try {
        this.geometries = await this.getFarmsGeometries();
      } catch {
        // TODO: show error
        this.geometries = [];
      } finally {
        // this.loadingGeometries = false;
      }
    },
    async fetchSamplesGeometry() {
      try {
        this.geometriesSamples = await this.getSamplesGeometry({
          farm_id: this.farmId,
        });
      } catch {
        // TODO: show error
        this.geometriesSamples = [];
      } finally {
        this.loadingGeometries = false;
      }
    },
    async fetchSamplesGeometryWithFilters(filters) {
      try {
        const samplingDate_after =
          filters.sampling_date[0] != null
            ? moment(filters.sampling_date[0]).format('YYYY-MM-DD')
            : null;
        const samplingDate_before =
          filters.sampling_date[1] != null
            ? moment(filters.sampling_date[1]).format('YYYY-MM-DD')
            : null;

        this.geometriesSamples = await this.getSamplesGeometry({
          farm_id: this.farmId,
          // sampling_date: dateFormated,
          samplingDate_after,
          samplingDate_before,
          sample_type_family_id: filters.sample_type_family_id,
          // sample_type_subfamily_id: '',
          sample_type_id: filters.sample_type_id?.value,
          parcel_id: filters.parcel_id,
          study: filters.study,
        });

        EventBus.$emit(
          'redraw-samples-on-map',
          this.geometriesSamples
        );
      } catch {
        // TODO: HANDLE ERROR
        this.geometriesSamples = [];
      } finally {
        this.loadingGeometries = false;
      }
    },
    menuAreas(data) {
      this.menu.areas = data;
    },
    menuSamples(data) {
      this.menu.samples = data;
    },
    menuImages(data) {
      this.menu.images = data;
    },
    menuSets(data) {
      Vue.set(this.menu, 'sets', data);
      this.menuSectionsKey = `menu-sections-key-${Date.now()}`;
    },
    menuInterpolations(data) {
      Vue.set(this.menu, 'interpolations', data);
      this.menuSectionsKey = `menu-sections-key-${Date.now()}`;
    },
    closeComparation() {
      this.showComparation = false;

      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 100);
    },
    ...mapActions('gis', [
      'getFarmsGeometries',
      'getSamplesGeometry',
    ]),
    ...mapActions('filter', ['setCurrentFilter']),
    ...mapActions('geotoolbox', ['clearFilters']),
    ...mapActions('breadcrumb', ['popRoute']),
  },
};
</script>

<style scoped lang="scss">
@import './GisActions.styles.scss';
</style>
