export default [
  {
    alimentary_ID: 1,
    alimentary_Head_ID: 0,
    name: 'Samples',
  },
  {
    alimentary_ID: 2,
    alimentary_Head_ID: 1,
    name: 'Read',
  },
];
