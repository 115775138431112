<template>
  <div class="menu-section">
    <div class="menu-title">
      <div class="input-label-container">
        <div class="input-container">
          <input
            id="Areas"
            v-model="generalCheckedAreas"
            type="checkbox"
            class="input"
            @change="toggleAll()"
          />
        </div>
        <div class="label-container">
          <label for="Areas" class="label">
            {{ $t('geotoolbox.areas') }}
          </label>
        </div>
      </div>
      <button
        class="menu-title-arrow"
        type="button"
        @click="showMenu = !showMenu"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          style="width: 1.1rem; height: 1.1rem"
          :class="[showMenu ? 'rotate-180' : 'rotate-0']"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          />
        </svg>
      </button>
    </div>
    <div v-show="showMenu" class="menu-options">
      <div
        v-for="(item, index) of interpolations"
        :key="`intersection-${index}`"
      >
        <div class="input-label-container">
          <div class="input-container">
            <input
              :id="`intersection-${index}-input`"
              v-model="item.checked"
              type="checkbox"
              class="input"
              @change="toggleIntersection(item)"
            />
          </div>
          <div class="label-container">
            <label :for="`intersection-${index}-input`" class="label">
              <svg
                clip-rule="evenodd"
                fill-rule="evenodd"
                stroke-linejoin="round"
                stroke-miterlimit="2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                :style="`width: 0.9rem; height: 0.9rem; fill: currentColor; color: ${item.color}`"
              >
                <path
                  d="m11.467 3.183c.175-.122.38-.183.584-.183.206 0 .413.063.589.186 1.778 1.252 7.104 4.997 8.93 6.282.274.194.43.505.43.826 0 .11-.018.221-.056.329-.697 2.016-2.668 7.718-3.351 9.693-.142.41-.53.684-.965.684h-11.153c-.432 0-.818-.27-.962-.674-.7-1.964-2.732-7.667-3.454-9.694-.04-.111-.059-.225-.059-.338 0-.324.157-.636.435-.829 1.853-1.289 7.239-5.035 9.032-6.282zm-7.868 7.3 3.213 9.017h10.475l3.118-9.017-8.355-5.878z"
                  fill-rule="nonzero"
                />
              </svg>
              <span class="label-text" :title="item.name">{{
                item.name
              }}</span>
            </label>
          </div>
        </div>
      </div>

      <div
        v-if="areas.length > 0 && interpolations.length > 0"
        class="break-line"
      ></div>

      <div v-for="(item, index) of areas" :key="`area-${index}`">
        <div class="input-label-container">
          <div class="input-container">
            <input
              :id="`area-${index}-input`"
              v-model="item.checked"
              type="checkbox"
              class="input"
              @change="toggleArea(item)"
            />
          </div>
          <div class="label-container">
            <label :for="`area-${index}-input`" class="label">
              <svg
                clip-rule="evenodd"
                fill-rule="evenodd"
                stroke-linejoin="round"
                stroke-miterlimit="2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                :style="`width: 0.9rem; height: 0.9rem; fill: currentColor; color: ${item.color}`"
              >
                <path
                  d="m11.467 3.183c.175-.122.38-.183.584-.183.206 0 .413.063.589.186 1.778 1.252 7.104 4.997 8.93 6.282.274.194.43.505.43.826 0 .11-.018.221-.056.329-.697 2.016-2.668 7.718-3.351 9.693-.142.41-.53.684-.965.684h-11.153c-.432 0-.818-.27-.962-.674-.7-1.964-2.732-7.667-3.454-9.694-.04-.111-.059-.225-.059-.338 0-.324.157-.636.435-.829 1.853-1.289 7.239-5.035 9.032-6.282zm-7.868 7.3 3.213 9.017h10.475l3.118-9.017-8.355-5.878z"
                  fill-rule="nonzero"
                />
              </svg>
              <span class="label-text" :title="item.name">{{
                item.name
              }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/services/event-bus';

export default {
  name: 'MenuSections',
  props: {
    menu: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showMenu: true,
      generalCheckedAreas: true,
    };
  },
  computed: {
    areas() {
      if (this.menu?.areas == null) return [];
      return Object.values(this.menu.areas).reverse();
    },
    interpolations() {
      if (this.menu?.interpolations == null) return [];
      return Object.values(this.menu.interpolations).reverse();
    },
  },
  methods: {
    toggleArea(area) {
      if (!this.generalCheckedAreas) return;

      EventBus.$emit('toggle-area', area);
    },
    toggleAll() {
      this.areas.forEach((area) => {
        EventBus.$emit('toggle-area', {
          ...area,
          checked:
            this.generalCheckedAreas === false ? false : area.checked,
        });
      });

      this.interpolations.forEach((area) => {
        EventBus.$emit('toggle-area', {
          ...area,
          checked:
            this.generalCheckedAreas === false ? false : area.checked,
        });
      });
    },
    toggleIntersection(intersection) {
      if (!this.generalCheckedAreas) return;

      EventBus.$emit('toggle-area', intersection);
    },
  },
};
</script>

<style scoped lang="scss">
@import './MenuSections.styles.scss';
</style>
