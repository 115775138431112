import EventBus from '@/services/event-bus';

export default {
  namespaced: true,
  state: {
    selectedStatuses: [],
    tipoMuestras: [],
  },
  actions: {
    setSelectedStatuses({ commit }, value) {
      commit('setSelectedStatuses', value);
    },
    setTipoMuestras({ commit }, value) {
      commit('setTipoMuestras', value);
    },
    clearFilters({ commit }) {
      commit('clearFilters');
    },
    callOffersWithFilters({ state }) {
      EventBus.$emit('offersList', state.selectedStatuses[0]);
    },
    callSamplesTypeForFilters({ state }) {
      EventBus.$emit('offersDetails', state.tipoMuestras[0]);
    },
  },
  mutations: {
    setSelectedStatuses(state, value) {
      state.selectedStatuses = value;
    },
    setTipoMuestras(state, value) {
      state.tipoMuestras = value;
    },
    clearFilters(state) {
      state.selectedStatuses = [];
      state.tipoMuestras = [];
    },
  },
};
