import LeftPanelOptions from '@/json/leftPanelOptions.json';

export default {
  namespaced: true,
  state: {
    options: [],
    hiddenPanel: false,
  },
  actions: {
    setOptionsVisible({ commit, rootState }, value) {
      commit('setOptionsVisible', rootState.contact.enabled);
    },
    setOptionsHidden({ commit }, value) {
      commit('setOptionsHidden', value);
    },
  },
  mutations: {
    setOptionsVisible(state, value) {
      state.hiddenPanel = false;
      state.options = LeftPanelOptions.map((option) => {
        option.visible = true;
        return option;
      });

      if (!value) {
        state.options[state.options.length - 1].visible = false;
      }
    },
    setOptionsHidden(state, value) {
      state.hiddenPanel = true;
      state.options = state.options.map((option) => {
        if (option.name !== 'alerts') {
          option.visible = false;
        }

        return option;
      });
    },
  },
};
