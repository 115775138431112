<template>
  <div class="margin-top">
    <div class="label-input">
      {{ $t('agronomySample.summary.samplingDate') }}
    </div>
    <div class="recepDate marginTop5">
      <f7-input
        class="shadow"
        type="datepicker"
        :placeholder="$t('agronomySample.chooseDates')"
        :clear-button="true"
        :close-on-select="true"
        :value="calculatedAgronomySamplingDate"
        :calendar-params="{
          closeOnSelect: true,
          rangePicker: true,
          dateFormat: {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
          },
        }"
        @calendar:change="setSamplingDate"
      />
    </div>

    <div class="margin-top-half">
      <div class="label-input">Familia de tipo de muestra</div>

      <DxSelectBox
        :data-source="familyTypes"
        :multiline="true"
        :search-enabled="true"
        tag-template="tag"
        :value="selectedFamilyTypes"
        display-expr="value"
        value-expr="id"
        :on-value-changed="FamilyTypesChanged"
        :on-opened="openedFamilyTypes"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxSelectBox>
    </div>
    <!-- 
    <div class="margin-top-half">
      <div class="label-input">Subfamilia de tipo de muestra</div>

      <DxTagBox
        :data-source="subfamilyTypes"
        :multiline="true"
        :search-enabled="true"
        tag-template="tag"
        :value="selectedSubfamilyTypes"
        display-expr="value"
        value-expr="id"
        :on-value-changed="SubfamilyTypesChanged"
        :on-opened="openedSubfamilyTypes"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox>
    </div> -->

    <div class="margin-top-half">
      <div class="label-input">
        {{ $t('agronomySample.summary.sampleType') }}
      </div>

      <DxLookup
        :data-source="sampleTypes"
        :grouped="true"
        :value="selectedSampleTypes"
        :on-content-ready="contentReady"
        :on-value-changed="sampleTypesChanged"
        :on-opened="openedSampleTypes"
        display-expr="id"
      >
        <DxDropDownOptions
          :hide-on-outside-click="true"
          :show-title="false"
        />
      </DxLookup>
      <!-- <DxTagBox
        :data-source="sampleTypes"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('agronomySample.summary.sampleType')"
        tag-template="tag"
        :value="selectedSampleTypes"
        display-expr="value"
        value-expr="id"
        :on-value-changed="sampleTypesChanged"
        :on-opened="openedSampleTypes"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxTagBox> -->
    </div>

    <div class="margin-top-half">
      <div class="label-input">
        {{ $t('agronomySample.summary.parcel') }}
      </div>

      <DxSelectBox
        :data-source="parcels"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('agronomySample.summary.parcel')"
        tag-template="tag"
        :value="selectedParcels"
        display-expr="value"
        value-expr="id"
        :on-value-changed="parcelsChanged"
        :on-opened="openedParcels"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxSelectBox>
    </div>

    <div class="margin-top-half">
      <div class="label-input">{{ $t('project') }}</div>

      <DxSelectBox
        :data-source="studies"
        :multiline="true"
        :search-enabled="true"
        :placeholder="$t('project')"
        tag-template="tag"
        :value="selectedStudies"
        display-expr="value"
        value-expr="value"
        :on-value-changed="studiesChanged"
        :on-opened="openedStudies"
        :on-content-ready="contentReady"
        :show-selection-controls="true"
      >
        <template #tag="{ data }">
          <div class="purpleTag">
            <b>{{ data.value }}</b>
            <div class="dx-tag-remove-button xButton"></div>
          </div>
        </template>
      </DxSelectBox>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { DxTagBox } from 'devextreme-vue/tag-box';
import DataSource from 'devextreme/data/data_source';
import DxSelectBox from 'devextreme-vue/select-box';
import { DxLookup, DxDropDownOptions } from 'devextreme-vue/lookup';

export default {
  components: { DxTagBox, DxSelectBox, DxLookup, DxDropDownOptions },
  data() {
    return {
      sampleTypes: [],
      farms: [],
      parcels: [],
      services: [],
      cultives: [],
      studies: [],
      clients: [],
      analyseTypes: [],
      statuses: [],
      loaded: false,
      //
      familyTypes: [],
      subfamilyTypes: [],
    };
  },
  computed: {
    calculatedAgronomySamplingDate() {
      if (
        this.samplingToDate !== null &&
        this.samplingFromDate !== null
      ) {
        return [this.samplingFromDate, this.samplingToDate];
      }
      return [];
    },
    ...mapState('agronomySamples', ['allSamples']),
    ...mapState('geotoolbox', [
      'selectedSampleTypes',
      'samplingFromDate',
      'samplingToDate',
      'selectedFarms',
      'selectedParcels',
      'selectedServices',
      'selectedCultives',
      'selectedClients',
      'selectedStudies',
      'selectedAnalyseTypes',
      'selectedStatuses',
      //
      'selectedFamilyTypes',
      'selectedSubfamilyTypes',
    ]),
  },
  methods: {
    async openedSampleTypes(e) {
      const sampleTypes = await this.getSampleFilterGeotoolbox({
        // atc: 'Agronomía',
        attribute: 'sample_type_family',
        sampleTypeForGeometry: this.selectedFamilyTypes,
      });

      const allSampleTypes = [];

      sampleTypes.forEach((sampleType) => {
        sampleType.sample_types?.forEach((item) => {
          allSampleTypes.push({
            ...sampleType,
            value: item.id,
            id: item.value,
          });
        });
      });

      this.sampleTypes = new DataSource({
        store: allSampleTypes,
        key: 'sample_subfamily_type_id',
        group: 'sample_subfamily_type',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    analyseTypesChanged(e) {
      this.setSelectedAnalyseTypes(e.value);
    },
    async openedFarms(e) {
      const farms = await this.getSampleFilterGeotoolbox({
        atc: 'Agronomía',
        attribute: 'farm',
      });

      this.farms = new DataSource({
        store: farms,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedParcels(e) {
      const parcels = await this.getSampleFilterGeotoolbox({
        // atc: 'Agronomía',
        attribute: 'parcel',
      });

      this.parcels = new DataSource({
        store: parcels,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedAnalyseTypes(e) {
      const analyseTypes = await this.getSampleFilterGeotoolbox({
        atc: 'Agronomía',
        attribute: 'analyse_type',
      });

      this.analyseTypes = new DataSource({
        store: analyseTypes,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedServices(e) {
      const services = await this.getSampleFilterGeotoolbox({
        atc: 'Agronomía',
        attribute: 'type_service_code',
      });

      this.services = services.sort();
      this.loaded = true;
    },
    async openedStatuses(e) {
      const statuses = await this.getSampleFilterGeotoolbox({
        atc: 'Agronomía',
        attribute: 'status',
      });

      this.statuses = statuses.sort();
      this.loaded = true;
    },
    async openedCultives(e) {
      const cultives = await this.getSampleFilterGeotoolbox({
        atc: 'Agronomía',
        attribute: 'cultive',
      });

      this.cultives = cultives.sort();
      this.loaded = true;
    },
    async openedClients(e) {
      const clients = await this.getSampleFilterGeotoolbox({
        atc: 'Agronomía',
        attribute: 'company',
      });

      this.clients = clients.sort();
      this.loaded = true;
    },
    async openedStudies(e) {
      const studies = await this.getSampleFilterGeotoolbox({
        // atc: 'Agronomía',
        attribute: 'study',
      });
      this.studies = new DataSource({
        store: studies,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedFamilyTypes(e) {
      const familyTypes = await this.getSampleFilterGeotoolbox({
        // atc: 'Agronomía',
        attribute: 'sample_type_family',
      });

      this.familyTypes = new DataSource({
        store: familyTypes,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    async openedSubfamilyTypes(e) {
      const subfamilyTypes = await this.getSampleFilterGeotoolbox({
        // atc: 'Agronomía',
        attribute: 'sample_type_subfamily',
      });

      this.subfamilyTypes = new DataSource({
        store: subfamilyTypes,
        key: '',
        paginate: true,
        pageSize: 10,
      });
      this.loaded = true;
    },
    contentReady(e) {
      const arrayNotFoundItems = Array.from(
        document.getElementsByClassName('dx-empty-message')
      );

      arrayNotFoundItems.forEach((element) => {
        if (!this.loaded) {
          element.innerHTML = this.$t('loading');
        } else {
          element.innerHTML = this.$t('dataNotAvailable');
        }
      });

      this.loaded = false;
    },
    setSamplingDate(e) {
      //   this.setSamplingFromDate(e);
      if (e.length === 2) {
        this.setSamplingFromDate(e[0]);
        this.setSamplingToDate(e[1]);
      } else if (e.length === 0) {
        this.setSamplingFromDate(null);
        this.setSamplingToDate(null);
      }
    },
    farmsChanged(e) {
      this.setSelectedFarms(e.value);
    },
    cultivesChanged(e) {
      this.setSelectedCultives(e.value);
    },
    servicesChanged(e) {
      this.setSelectedServices(e.value);
    },
    parcelsChanged(e) {
      this.setSelectedParcels(e.value);
    },
    sampleTypesChanged(e) {
      this.setSelectedSampleTypes(e.value);
    },
    clientsChanged(e) {
      this.setSelectedClients(e.value);
    },
    statusesChanged(e) {
      this.setSelectedStatuses(e.value);
    },
    studiesChanged(e) {
      this.setSelectedStudies(e.value);
    },
    FamilyTypesChanged(e) {
      this.setSelectedFamilyTypes(e.value);
    },
    SubfamilyTypesChanged(e) {
      this.setSelectedSubfamilyTypes(e.value);
    },
    ...mapActions('geotoolbox', [
      'setSelectedSampleTypes',
      'setSamplingFromDate',
      'setSamplingToDate',
      'setSelectedFarms',
      'setSelectedCultives',
      'setSelectedServices',
      'setSelectedParcels',
      'setSelectedClients',
      'setSelectedStudies',
      'setSelectedAnalyseTypes',
      'setSelectedStatuses',
      //
      'setSelectedFamilyTypes',
      'setSelectedSubfamilyTypes',
    ]),
    ...mapActions('samples', [
      'getSampleFilter',
      'getSampleFilterGeotoolbox',
    ]),
    ...mapActions('agronomySamples', ['getStudySatellite']),
  },
};
</script>
