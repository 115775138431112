<template>
  <div>
    <div v-if="!loaded">
      <div class="loadingFalse">
        <div class="preloader">
          <span class="preloader-inner">
            <span class="preloader-inner-circle"></span>
          </span>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="dashboard-content">
        <!-- Item Food -->
        <div class="card no-margin-horizontal no-margin-top">
          <div class="card-header">
            {{ $t('Food') }}
          </div>
          <div class="card-content">
            {{ $t('inProcess') }}
            <span
              class="badge"
              :class="{
                'badge-nodata':
                  aggregateInfo[1][types.food].in_process === 0,
              }"
              >{{ aggregateInfo[1][types.food].in_process }}
            </span>
          </div>
          <div class="card-content">
            {{ $t('analyzed') }}
            <span
              class="badge"
              :class="{
                'badge-nodata':
                  aggregateInfo[1][types.food].analyzed === 0,
              }"
              >{{ aggregateInfo[1][types.food].analyzed }}
            </span>
          </div>
        </div>
        <!-- /. Item Food -->

        <!-- Item Agronomy -->
        <div class="card no-margin-horizontal no-margin-top">
          <div class="card-header">
            {{ $t('Agronomy') }}
          </div>
          <div class="card-content">
            {{ $t('inProcess') }}
            <span
              class="badge"
              :class="{
                'badge-nodata':
                  aggregateInfo[0][types.agronomy].in_process === 0,
              }"
              >{{ aggregateInfo[0][types.agronomy].in_process }}
            </span>
          </div>
          <div class="card-content">
            {{ $t('analyzed') }}
            <span
              class="badge"
              :class="{
                'badge-nodata':
                  aggregateInfo[0][types.agronomy].analyzed === 0,
              }"
              >{{ aggregateInfo[0][types.agronomy].analyzed }}
            </span>
          </div>
        </div>
        <!-- /. Item Agronomy -->

        <!-- Item Environment -->
        <div class="card no-margin-horizontal no-margin-top">
          <div class="card-header">
            {{ $t('Environment') }}
          </div>
          <div class="card-content">
            {{ $t('inProcess') }}
            <span
              class="badge"
              :class="{
                'badge-nodata':
                  aggregateInfo[2][types.environment].in_process ===
                  0,
              }"
              >{{ aggregateInfo[2][types.environment].in_process }}
            </span>
          </div>
          <div class="card-content">
            {{ $t('analyzed') }}
            <span
              class="badge"
              :class="{
                'badge-nodata':
                  aggregateInfo[2][types.environment].analyzed === 0,
              }"
              >{{ aggregateInfo[2][types.environment].analyzed }}
            </span>
          </div>
        </div>
        <!-- /. Item Environment -->

        <!-- Item Mining -->
        <div class="card no-margin-horizontal no-margin-top">
          <div class="card-header">
            {{ $t('Mining') }}
          </div>
          <div class="card-content">
            {{ $t('inProcess') }}
            <span
              class="badge"
              :class="{
                'badge-nodata':
                  aggregateInfo[3][types.mining].in_process === 0,
              }"
              >{{ aggregateInfo[3][types.mining].in_process }}</span>
          </div>
          <div class="card-content">
            {{ $t('analyzed') }}
            <span
              class="badge"
              :class="{
                'badge-nodata':
                  aggregateInfo[3][types.mining].analyzed === 0,
              }"
              >{{ aggregateInfo[3][types.mining].analyzed }}</span>
          </div>
        </div>
        <!-- /. Item Mining -->

        <!-- Item Health and Security -->
        <div class="card no-margin-horizontal no-margin-top">
          <div class="card-header">
            {{ $t('Health and Security') }}
          </div>
          <div class="card-content">
            {{ $t('inProcess') }}
            <span
              class="badge"
              :class="{
                'badge-nodata':
                  aggregateInfo[4][types.healthAndSecurity]
                    .in_process === 0,
              }"
              >{{
                aggregateInfo[4][types.healthAndSecurity].in_process
              }}
            </span>
          </div>
          <div class="card-content">
            {{ $t('analyzed') }}
            <span
              class="badge"
              :class="{
                'badge-nodata':
                  aggregateInfo[4][types.healthAndSecurity]
                    .analyzed === 0,
              }"
              >{{
                aggregateInfo[4][types.healthAndSecurity].analyzed
              }}
            </span>
          </div>
        </div>
        <!-- /. Item Health and Security -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import EventBus from '@/services/event-bus';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  data() {
    return {
      types: {
        food: 'Alimentaria',
        agronomy: 'Agronomía',
        environment: 'Medio Ambiente',
        mining: 'Minería',
        healthAndSecurity: 'Salud y Seguridad',
      },
      loaded: false,
    };
  },
  computed: {
    ...mapState('samples', ['aggregateInfo']),
  },
  async beforeMount() {
    try {
      this.loaded = false;

      EventBus.$on('Home', async (params) => {
        this.loaded = false;

        await this.getAggregateInfo();

        this.loaded = true;
      });

      this.loaded = true;
    } catch (e) {
      this.$notifyDX(
        {
          message: this.$t(
            this.$helpers.getFilteredErrorMessage(
              JSON.parse(e.message).error
            )
          ),
          width: 550,
        },
        'error',
        NOTIFY_TIME_LONG
      );
    }
  },
  methods: {
    ...mapActions('samples', ['getAggregateInfo']),
  },
};
</script>
<style scoped>
.dashboard-content {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

@media (min-width: 991px) and (max-width: 1120px) {
  .dashboard-content {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 850px) and (max-width: 990px) {
  .dashboard-content {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 849px) {
  .dashboard-content {
    grid-template-columns: repeat(2, 1fr);
  }
}

.dashboard-content .card-header {
  background: var(--his-arandano);
  color: var(--his-arandano-white);
  padding-top: 6px;
  padding-bottom: 6px;
  min-height: auto;
  font-weight: 600;
  letter-spacing: 0.02em;
  font-size: 12px;
  text-transform: uppercase;
}

.dashboard-content .card-content {
  color: var(--f7-theme-color-shade);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 16px;
}

.dashboard-content .badge-nodata {
  opacity: 0.3;
}

th {
  text-align: left;
  white-space: nowrap;
}
td {
  word-break: break-all;
  font-size: 12px;
}
.rectangle {
  color: white;
  padding: 4px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 6px 2px 12px;
}
.circle {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  font-size: 22px;
  color: #fff;
  text-align: center;
  background: #000;
}
.purple {
  background-color: rgba(92, 83, 146, 0.6);
}
.yellow {
  background-color: rgba(194, 171, 49, 0.6);
}
.green {
  background-color: rgba(101, 183, 72, 0.6);
}
.red {
  background-color: rgba(233, 78, 44, 0.6);
}
.marginRight5 {
  margin-right: 5px;
}
</style>
