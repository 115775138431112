import Api from '@/services/Api';
import EventBus from '@/services/event-bus';

export default {
  namespaced: true,
  state: {
    fromDate: null,
    toDate: null,
    selectedSampleTypes: null,
    // selectedLMRs: null,
    selectedParameters: null,
    // isParamsSelected: false,
    // isCompanyAggregated: false,
    // selectedAnalyseTypes: [],
    // selectedThirdClients: [],
    // selectedSamplers: [],
    selectedZone: null,
    selectedSamplingPoint: null,
    // availableLmrs: [],
    isComparative: false,
    // isGlobalAGQ: false,
    groupByMonth: false,
  },
  actions: {
    setToDate({ commit }, value) {
      commit('setToDate', value);
    },
    setFromDate({ commit }, value) {
      commit('setFromDate', value);
    },
    setSelectedSampleTypes({ commit }, value) {
      commit('setSelectedSampleTypes', value);
    },
    setSelectedLmrs({ commit }, value) {
      commit('setSelectedLmrs', value);
    },
    setSelectedParameters({ commit }, value) {
      commit('setSelectedParameters', value);
    },
    setIsParamsSelected({ commit }, value) {
      commit('setIsParamsSelected', value);
    },
    setIsCompanyAggregated({ commit }, value) {
      commit('setIsCompanyAggregated', value);
    },
    setSelectedAnalyseTypes({ commit }, value) {
      commit('setSelectedAnalyseTypes', value);
    },
    setSelectedThirdClients({ commit }, value) {
      commit('setSelectedThirdClients', value);
    },
    setSelectedSamplers({ commit }, value) {
      commit('setSelectedSamplers', value);
    },
    setIsComparative({ commit }, value) {
      commit('setIsComparative', value);
    },
    setIsGlobalAGQ({ commit }, value) {
      commit('setIsGlobalAGQ', value);
    },
    setAvailableLmrs({ commit }, value) {
      commit('setAvailableLmrs', value);
    },
    setSelectedZone({ commit }, value) {
      commit('setSelectedZone', value);
    },
    setGroupByMonth({ commit }, value) {
      commit('setGroupByMonth', value);
    },
    setSelectedSamplingPoint({ commit }, value) {
      commit('setSelectedSamplingPoint', value);
    },
    filterSamples({ commit, rootState }) {
      EventBus.$emit(
        'filter-environment-stats-samples',
        rootState.environmentStats
      );
    },
    async getSamples({ commit, rootState }, data) {
      try {
        const xhr = await Api.getSamplesParameterResult(
          data,
          rootState.contact.company,
          rootState.contact.address
        );

        const response = JSON.parse(xhr.response);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getCMAandR({ commit, rootState }, data) {
      try {
        const xhr = await Api.getSamplesParametersCmaSuperiorAndRmin(
          data,
          rootState.contact.company,
          rootState.contact.address
        );

        const response = JSON.parse(xhr.response);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getSamplesCount({ commit, rootState }, data) {
      try {
        const xhr = await Api.getSamplesCount(
          data,
          rootState.contact.company,
          rootState.contact.address
        );

        const response = JSON.parse(xhr.response);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    clearFilters({ commit }) {
      commit('clearFilters');
    },
  },
  mutations: {
    setFromDate(state, value) {
      state.fromDate = value;
    },
    setToDate(state, value) {
      state.toDate = value;
    },
    setSelectedSampleTypes(state, value) {
      state.selectedSampleTypes = value;
    },
    setSelectedLmrs(state, value) {
      state.selectedLMRs = value;
    },
    setSelectedParameters(state, value) {
      state.selectedParameters = value;
    },
    setIsParamsSelected(state, value) {
      state.isParamsSelected = value;
    },
    setIsCompanyAggregated(state, value) {
      state.isCompanyAggregated = value;
    },
    setSelectedAnalyseTypes(state, value) {
      state.selectedAnalyseTypes = value;
    },
    setSelectedThirdClients(state, value) {
      state.selectedThirdClients = value;
    },
    setSelectedSamplers(state, value) {
      state.selectedSamplers = value;
    },
    setIsComparative(state, value) {
      state.isComparative = value;
    },
    setIsGlobalAGQ(state, value) {
      state.isGlobalAGQ = value;
    },
    setAvailableLmrs(state, value) {
      state.availableLmrs = [];
      state.availableLmrs = value;
    },
    setSelectedZone(state, value) {
      state.selectedZone = value;
    },
    setGroupByMonth(state, value) {
      state.groupByMonth = value;
    },
    setSelectedSamplingPoint(state, value) {
      state.selectedSamplingPoint = value;
    },
    clearFilters(state) {
      state.fromDate = null;
      state.toDate = null;
      state.selectedSampleTypes = [];
      state.selectedLMRs = [];
      state.selectedParameters = [];
      state.selectedZone = null;
      state.selectedSamplingPoint = null;
      state.isParamsSelected = false;
      state.isCompanyAggregated = false;
      state.selectedAnalyseTypes = [];
      state.selectedThirdClients = [];
      state.selectedSamplers = [];
      state.availableLmrs = [];
      state.isComparative = false;
      state.isGlobalAGQ = false;
      state.groupByMonth = false;
    },
  },
};
