import Helpers from '@/js/helpers';
import { ATCS_ES } from '@/js/constants';
import moment from 'moment';

export default {
  namespaced: true,
  state: {
    currentFilter: '',
    currentSampleId: '',
  },
  // after->from
  // before->to
  actions: {
    async applyCurrentFilters({ state, dispatch, rootState }) {
      let params = {};
      if (state.currentFilter === 'home') {
        params = {
          receptionDate_after: Helpers.getDateFormatedForBackEnd(
            rootState.homeFilter.receptionFromDate
          ),
          receptionDate_before: Helpers.getDateFormatedForBackEnd(
            rootState.homeFilter.receptionToDate
          ),
          endDate_after: Helpers.getDateFormatedForBackEnd(
            rootState.homeFilter.endFromDate
          ),
          endDate_before: Helpers.getDateFormatedForBackEnd(
            rootState.homeFilter.endToDate
          ),
          atc_name: rootState.homeFilter.selectedATCs.toString(),
          status: rootState.homeFilter.selectedStatuses.toString(),
          sample_type: Helpers.applySeparator(
            rootState.homeFilter.selectedSampleTypes
          ).toString(),
          analyse_type: Helpers.applySeparator(
            rootState.homeFilter.selectedAnalyseTypes
          ).toString(),
        };

        dispatch('samples/setAllFilterParams', params, {
          root: true,
        });

        dispatch('homeSamples/callStoreWithFilters', params, {
          root: true,
        });
      } else if (state.currentFilter === 'agronomy') {
        params = {
          sample_type: Helpers.applySeparator(
            rootState.agronomyFilter.selectedSampleTypes
          ).toString(),
          samplingDate_before: Helpers.getDateFormatedForBackEnd(
            rootState.agronomyFilter.samplingToDate
          ),
          samplingDate_after: Helpers.getDateFormatedForBackEnd(
            rootState.agronomyFilter.samplingFromDate
          ),
          farm: Helpers.applySeparator(
            rootState.agronomyFilter.selectedFarms
          ).toString(),
          parcel: Helpers.applySeparator(
            rootState.agronomyFilter.selectedParcels
          ).toString(),
          type_service_code: Helpers.applySeparator(
            rootState.agronomyFilter.selectedServices
          ).toString(),
          cultive: Helpers.applySeparator(
            rootState.agronomyFilter.selectedCultives
          ).toString(),
          company: Helpers.applySeparator(
            rootState.agronomyFilter.selectedClients,
            ','
          ).toString(),
          atc_name: ATCS_ES.agronomy,
          study: Helpers.applySeparator(
            rootState.agronomyFilter.selectedStudies
          ).toString(),
          analyse_type: Helpers.applySeparator(
            rootState.agronomyFilter.selectedAnalyseTypes
          ).toString(),
          status:
            rootState.agronomyFilter.selectedStatuses.toString(),
        };

        dispatch('samples/setAllFilterParams', params, {
          root: true,
        });

        dispatch('agronomySamples/callStoreWithFilters', params, {
          root: true,
        });
      } else if (state.currentFilter === 'food') {
        /**
         * TODO: Incluir los siguientes filtros cuando se disponga de la información
         * !Especificaciones cadenas europeas - selectedEuropeanChains
         */
        params = {
          receptionDate_after: Helpers.getDateFormatedForBackEnd(
            rootState.foodFilter.receptionFromDate
          ),
          receptionDate_before: Helpers.getDateFormatedForBackEnd(
            rootState.foodFilter.receptionToDate
          ),
          endDate_after: Helpers.getDateFormatedForBackEnd(
            rootState.foodFilter.endFromDate
          ),
          endDate_before: Helpers.getDateFormatedForBackEnd(
            rootState.foodFilter.endToDate
          ),
          sample_type: Helpers.applySeparator(
            rootState.foodFilter.selectedSampleTypes
          ).toString(),
          status: rootState.foodFilter.selectedStatuses.toString(),
          analyse_type: Helpers.applySeparator(
            rootState.foodFilter.selectedAnalyseTypes
          ).toString(),
          third_client: Helpers.applySeparator(
            rootState.foodFilter.selectedThirdClients
          ).toString(),
          study: Helpers.applySeparator(
            rootState.foodFilter.selectedProjects
          ).toString(),
          retailers: Helpers.applySeparator(
            rootState.foodFilter.selectedEuropeanChains
          ).toString(),
          cultive_organic: rootState.foodFilter.selectedEcologic,
          farm: Helpers.applySeparator(
            rootState.foodFilter.selectedFarms
          ).toString(),
          parcel: Helpers.applySeparator(
            rootState.foodFilter.selectedParcels
          ).toString(),
          atc_name: ATCS_ES.food,
        };

        dispatch('samples/setAllFilterParams', params, {
          root: true,
        });

        dispatch('foodSamples/callStoreWithFilters', params, {
          root: true,
        });
      } else if (state.currentFilter === 'foodDetails') {
        params = {
          parameter: Helpers.applySeparator(
            rootState.foodDetailsFilter.selectedParameters,
            ','
          ).toString(),
          parameter_group: Helpers.applySeparator(
            rootState.foodDetailsFilter.selectedParameterGroups
          ).toString(),
        };

        if (
          rootState.foodSamples.isNoRp &&
          (await dispatch('foodDetailsFilter/includesCMA', null, {
            root: true,
          }))
        ) {
          params.superior_cma = true;
        }

        if (!rootState.foodSamples.isNoRp) {
          params.lmrs = Helpers.applySeparator(
            rootState.foodDetailsFilter.selectedLMRs
          ).toString();
        }

        if (
          !rootState.foodSamples.isNoRp &&
          (await dispatch('foodDetailsFilter/includesLMR', null, {
            root: true,
          }))
        ) {
          params.superior_lmr = true;
        }

        if (
          await dispatch(
            'foodDetailsFilter/includesPositives',
            null,
            {
              root: true,
            }
          )
        ) {
          params.lmr_positives = true;
        }

        dispatch(
          'foodSamples/callStoreDetailsWithfilters',
          { id: state.currentSampleId, params },
          {
            root: true,
          }
        );
      } else if (state.currentFilter === 'environment') {
        params = {
          receptionDate_before: Helpers.getDateFormatedForBackEnd(
            rootState.environmentFilter.receptionToDate
          ),
          receptionDate_after: Helpers.getDateFormatedForBackEnd(
            rootState.environmentFilter.receptionFromDate
          ),
          samplingDate_before: Helpers.getDateFormatedForBackEnd(
            rootState.environmentFilter.samplingToDate
          ),
          samplingDate_after: Helpers.getDateFormatedForBackEnd(
            rootState.environmentFilter.samplingFromDate
          ),
          sample_type: Helpers.applySeparator(
            rootState.environmentFilter.selectedSampleTypes
          ).toString(),
          status:
            rootState.environmentFilter.selectedStatuses.toString(),
          farm: Helpers.applySeparator(
            rootState.environmentFilter.selectedFarms
          ).toString(),
          parcel: Helpers.applySeparator(
            rootState.environmentFilter.selectedParcels
          ).toString(),
          n_apts: rootState.environmentFilter.noOfIneligible,
          atc_name: ATCS_ES.environment,
          analyse_type: Helpers.applySeparator(
            rootState.environmentFilter.selectedAnalyseTypes
          ).toString(),
          study: Helpers.applySeparator(
            rootState.environmentFilter.selectedProjects
          ).toString(),
          greater_than: rootState.environmentFilter.greaterThan,
        };

        dispatch('samples/setAllFilterParams', params, {
          root: true,
        });

        dispatch('environmentSamples/callStoreWithFilters', params, {
          root: true,
        });
      } else if (state.currentFilter === 'environmentDetails') {
        params = {
          parameter: Helpers.applySeparator(
            rootState.environmentDetailsFilter.selectedParameters,
            ','
          ).toString(),
          parameter_group: Helpers.applySeparator(
            rootState.environmentDetailsFilter.selectedParameterGroups
          ).toString(),
        };

        if (
          await dispatch(
            'environmentDetailsFilter/includesCMA',
            null,
            {
              root: true,
            }
          )
        ) {
          params.superior_cma = true;
        }

        dispatch(
          'environmentSamples/callStoreDetailsWithfilters',
          { id: state.currentSampleId, params },
          {
            root: true,
          }
        );
      } else if (state.currentFilter === 'mining') {
        params = {
          receptionDate_before: Helpers.getDateFormatedForBackEnd(
            rootState.miningFilter.receptionToDate
          ),
          receptionDate_after: Helpers.getDateFormatedForBackEnd(
            rootState.miningFilter.receptionFromDate
          ),
          samplingDate_before: Helpers.getDateFormatedForBackEnd(
            rootState.miningFilter.samplingToDate
          ),
          samplingDate_after: Helpers.getDateFormatedForBackEnd(
            rootState.miningFilter.samplingFromDate
          ),
          sample_type: Helpers.applySeparator(
            rootState.miningFilter.selectedSampleTypes
          ).toString(),
          status: rootState.miningFilter.selectedStatuses.toString(),
          farm: Helpers.applySeparator(
            rootState.miningFilter.selectedFarms
          ).toString(),
          parcel: Helpers.applySeparator(
            rootState.miningFilter.selectedParcels
          ).toString(),
          atc_name: ATCS_ES.mining,
          study: Helpers.applySeparator(
            rootState.miningFilter.selectedProjects
          ).toString(),
          analyse_type: Helpers.applySeparator(
            rootState.miningFilter.selectedAnalyseTypes
          ).toString(),
        };

        dispatch('samples/setAllFilterParams', params, {
          root: true,
        });

        dispatch('miningSamples/callStoreWithFilters', params, {
          root: true,
        });
      } else if (state.currentFilter === 'miningDetails') {
        params = {
          parameter: Helpers.applySeparator(
            rootState.miningDetailsFilter.selectedParameters,
            ','
          ).toString(),
          parameter_group: Helpers.applySeparator(
            rootState.miningDetailsFilter.selectedParameterGroups
          ).toString(),
        };

        dispatch(
          'miningSamples/callStoreDetailsWithfilters',
          { id: state.currentSampleId, params },
          {
            root: true,
          }
        );
      } else if (state.currentFilter === 'healthSecurity') {
        params = {
          receptionDate_before: Helpers.getDateFormatedForBackEnd(
            rootState.healthSecurityFilter.receptionToDate
          ),
          receptionDate_after: Helpers.getDateFormatedForBackEnd(
            rootState.healthSecurityFilter.receptionFromDate
          ),
          endDate_after: Helpers.getDateFormatedForBackEnd(
            rootState.healthSecurityFilter.endFromDate
          ),
          endDate_before: Helpers.getDateFormatedForBackEnd(
            rootState.healthSecurityFilter.endToDate
          ),
          sample_type: Helpers.applySeparator(
            rootState.healthSecurityFilter.selectedSampleTypes
          ).toString(),
          status:
            rootState.healthSecurityFilter.selectedStatuses.toString(),
          analyse_type: Helpers.applySeparator(
            rootState.healthSecurityFilter.selectedAnalyseTypes
          ).toString(),
          atc_name: ATCS_ES.healthSecurity,
        };

        dispatch('samples/setAllFilterParams', params, {
          root: true,
        });

        dispatch(
          'healthSecuritySamples/callStoreWithFilters',
          params,
          {
            root: true,
          }
        );
      } else if (state.currentFilter === 'healthSecurityDetails') {
        params = {
          parameter: Helpers.applySeparator(
            rootState.healthSecurityDetailsFilter.selectedParameters,
            ','
          ).toString(),
          parameter_group: Helpers.applySeparator(
            rootState.healthSecurityDetailsFilter
              .selectedParameterGroups
          ).toString(),
        };

        if (
          await dispatch(
            'healthSecurityDetailsFilter/includesCMA',
            null,
            {
              root: true,
            }
          )
        ) {
          params.superior_cma = true;
        }

        dispatch(
          'healthSecuritySamples/callStoreDetailsWithfilters',
          { id: state.currentSampleId, params },
          {
            root: true,
          }
        );
      } else if (state.currentFilter === 'alertSettingsParams') {
        params = {
          createdAt_before: Helpers.getDateFormatedForBackEnd(
            rootState.alertSettingsParamsFilter.creationToDate
          ),
          createdAt_after: Helpers.getDateFormatedForBackEnd(
            rootState.alertSettingsParamsFilter.creationFromDate
          ),
          updatedAt_before: Helpers.getDateFormatedForBackEnd(
            rootState.alertSettingsParamsFilter.updateToDate
          ),
          updatedAt_after: Helpers.getDateFormatedForBackEnd(
            rootState.alertSettingsParamsFilter.updateFromDate
          ),
          area: rootState.alertSettingsParamsFilter.selectedAtcs.toString(),
          sample_type: Helpers.applySeparator(
            rootState.alertSettingsParamsFilter.selectedSampleTypes
          ).toString(),
          parameters: Helpers.applySeparator(
            rootState.alertSettingsParamsFilter.selectedParameters
          ).toString(),
          farm: Helpers.applySeparator(
            rootState.alertSettingsParamsFilter.selectedFarms
          ).toString(),
          parcel: Helpers.applySeparator(
            rootState.alertSettingsParamsFilter.selectedParcels
          ).toString(),
          status:
            rootState.alertSettingsParamsFilter.selectedStatuses ===
            'Activas'
              ? true
              : rootState.alertSettingsParamsFilter
                  .selectedStatuses === 'Inactivas'
              ? false
              : null,
        };

        dispatch('alertsParams/callStoreWithFilters', params, {
          root: true,
        });
      } else if (state.currentFilter === 'alertSettingsSample') {
        params = {
          createdAt_before: Helpers.getDateFormatedForBackEnd(
            rootState.alertSettingsSampleFilter.creationToDate
          ),
          createdAt_after: Helpers.getDateFormatedForBackEnd(
            rootState.alertSettingsSampleFilter.creationFromDate
          ),
          updatedAt_before: Helpers.getDateFormatedForBackEnd(
            rootState.alertSettingsSampleFilter.updateToDate
          ),
          updatedAt_after: Helpers.getDateFormatedForBackEnd(
            rootState.alertSettingsSampleFilter.updateFromDate
          ),
          area: rootState.alertSettingsSampleFilter.selectedAtcs.toString(),
          sample_type: Helpers.applySeparator(
            rootState.alertSettingsSampleFilter.selectedSampleTypes
          ).toString(),
          farm: Helpers.applySeparator(
            rootState.alertSettingsSampleFilter.selectedFarms
          ).toString(),
          parcel: Helpers.applySeparator(
            rootState.alertSettingsSampleFilter.selectedParcels
          ).toString(),
          value_report: Helpers.applySeparator(
            rootState.alertSettingsSampleFilter.selectedValueReports
          ).toString(),
          status:
            rootState.alertSettingsSampleFilter.selectedStatuses ===
            'Activas'
              ? true
              : rootState.alertSettingsSampleFilter
                  .selectedStatuses === 'Inactivas'
              ? false
              : null,
        };

        dispatch('alertsSample/callStoreWithFilters', params, {
          root: true,
        });
      } else if (state.currentFilter === 'alertReportParams') {
        params = {
          createdAt_before: Helpers.getDateFormatedForBackEnd(
            rootState.alertReportParamsFilter.creationToDate
          ),
          createdAt_after: Helpers.getDateFormatedForBackEnd(
            rootState.alertReportParamsFilter.creationFromDate
          ),
          area: rootState.alertReportParamsFilter.selectedAtcs.toString(),
          sample_type: Helpers.applySeparator(
            rootState.alertReportParamsFilter.selectedSampleTypes
          ).toString(),
          parameters: Helpers.applySeparator(
            rootState.alertReportParamsFilter.selectedParameters
          ).toString(),
          farm: Helpers.applySeparator(
            rootState.alertReportParamsFilter.selectedFarms
          ).toString(),
          parcel: Helpers.applySeparator(
            rootState.alertReportParamsFilter.selectedParcels
          ).toString(),
        };

        dispatch('alertsParams/callReportStoreWithFilters', params, {
          root: true,
        });
      } else if (state.currentFilter === 'alertReportSample') {
        params = {
          createdAt_before: Helpers.getDateFormatedForBackEnd(
            rootState.alertReportSampleFilter.creationToDate
          ),
          createdAt_after: Helpers.getDateFormatedForBackEnd(
            rootState.alertReportSampleFilter.creationFromDate
          ),
          area: rootState.alertReportSampleFilter.selectedAtcs.toString(),
          sample_type: Helpers.applySeparator(
            rootState.alertReportSampleFilter.selectedSampleTypes
          ).toString(),
          parameters: Helpers.applySeparator(
            rootState.alertReportSampleFilter.selectedParameters
          ).toString(),
          farm: Helpers.applySeparator(
            rootState.alertReportSampleFilter.selectedFarms
          ).toString(),
          parcel: Helpers.applySeparator(
            rootState.alertReportSampleFilter.selectedParcels
          ).toString(),
        };

        dispatch('alertsSample/callReportStoreWithFilters', params, {
          root: true,
        });
      } else if (state.currentFilter === 'notificationsDetail') {
        params = {
          createdAt_before: Helpers.getDateFormatedForBackEnd(
            rootState.notificationsDetailFilter.notificationToDate
          ),
          createdAt_after: Helpers.getDateFormatedForBackEnd(
            rootState.notificationsDetailFilter.notificationFromDate
          ),
          area: rootState.notificationsDetailFilter.selectedAtcs.toString(),
          sample_type: Helpers.applySeparator(
            rootState.notificationsDetailFilter.selectedSampleTypes
          ).toString(),
        };

        dispatch('notifications/callWithFilters', params, {
          root: true,
        });
      } else if (state.currentFilter === 'riskAnalysis') {
        let error = false;
        if (
          !rootState.riskAnalysisFilter.selectedSampleTypes ||
          !rootState.riskAnalysisFilter.selectedOriginCountry ||
          rootState.riskAnalysisFilter.selectedDestinationCountries
            .length <= 0
        ) {
          error = true;
        }
        dispatch(
          'riskAnalysis/callStoreWithFilters',
          { call: true, error },
          {
            root: true,
          }
        );
      } else if (state.currentFilter === 'foodStats') {
        if (
          !(
            rootState.foodStatsFilter.selectedSampleTypes.length >
              0 &&
            rootState.foodStatsFilter.selectedLMRs !== null &&
            rootState.foodStatsFilter.toDate !== null &&
            rootState.foodStatsFilter.fromDate !== null
          )
        ) {
          dispatch(
            'foodStats/callStoreWithFilters',
            { error: true },
            { root: true }
          );

          return;
        }
        params = {
          sample_type:
            rootState.foodStatsFilter.selectedSampleTypes.toString(),
          lmr: rootState.foodStatsFilter.selectedLMRs,
          end_date: Helpers.getDateFormatedForBackEnd(
            rootState.foodStatsFilter.toDate
          ),
          init_date: Helpers.getDateFormatedForBackEnd(
            rootState.foodStatsFilter.fromDate
          ),
          parameters: rootState.foodStatsFilter.selectedParameters,
          aggregate_by_company:
            rootState.foodStatsFilter.isCompanyAggregated,
          third_client: Helpers.applySeparator(
            rootState.foodStatsFilter.selectedThirdClients
          ).toString(),
          analyse_type: Helpers.applySeparator(
            rootState.foodStatsFilter.selectedAnalyseTypes
          ).toString(),
          agq_global: rootState.foodStatsFilter.isGlobalAGQ,
        };

        dispatch('foodStats/callStoreWithFilters', params, {
          root: true,
        });
      } else if (state.currentFilter === 'advancedServices') {
        params = {
          samplingDate_before: Helpers.getDateFormatedForBackEnd(
            rootState.advancedServicesFilter.samplingToDate
          ),
          samplingDate_after: Helpers.getDateFormatedForBackEnd(
            rootState.advancedServicesFilter.samplingFromDate
          ),
          farm: Helpers.applySeparator(
            rootState.advancedServicesFilter.selectedFarms
          ).toString(),
          cultive: Helpers.applySeparator(
            rootState.advancedServicesFilter.selectedCrops
          ).toString(),
          variety: Helpers.applySeparator(
            rootState.advancedServicesFilter.selectedVarieties
          ).toString(),
        };

        dispatch('advancedServices/callWithFilters', params, {
          root: true,
        });
      } else if (
        state.currentFilter === 'advancedServicesPhytomonitoring'
      ) {
        const initDate = rootState.phitomonitoringPopup.date?.[0]
          ? moment(rootState.phitomonitoringPopup.date?.[0]).format(
              'YYYY-MM-DD'
            )
          : null;

        const endDate = rootState.phitomonitoringPopup.date?.[1]
          ? moment(rootState.phitomonitoringPopup.date?.[1]).format(
              'YYYY-MM-DD'
            )
          : null;

        params = {
          init_date: initDate,
          end_date: endDate,
          // category_id: Helpers.applySeparator(
          //   rootState.phitomonitoringPopup.category
          // ).toString(),
          category_id: rootState.phitomonitoringPopup.category,
          type_id: Helpers.applySeparator(
            rootState.phitomonitoringPopup.type
          ).toString(),
          // type_id: rootState.phitomonitoringPopup.type?.[0],
          // value: Helpers.applySeparator(
          //   rootState.phitomonitoringPopup.value
          // ).toString(),
          unit: Helpers.applySeparator(
            rootState.phitomonitoringPopup.unit
          ).toString(),
          // n_apts: rootState.phitomonitoringPopup.value,
          // greater_than: rootState.phitomonitoringPopup.greaterThan,
        };

        if (rootState.phitomonitoringPopup.value != null) {
          // params.n_apts = rootState.phitomonitoringPopup.value;

          if (rootState.phitomonitoringPopup.greaterThan) {
            params.value_gt = rootState.phitomonitoringPopup.value;
          } else {
            params.value_lt = rootState.phitomonitoringPopup.value;
          }
        }

        dispatch('phitomonitoringPopup/callWithFilters', params, {
          root: true,
        });
      } else if (state.currentFilter === 'weatherGraph') {
        params = {
          init_date: rootState.weatherGraphFilter.initDate,
          end_date: rootState.weatherGraphFilter.endDate,
          grouped: rootState.weatherGraphFilter.selectedFrequency,
          formula: rootState.weatherGraphFilter.selectedFrequency
            ? 'AVG'
            : '',
        };

        dispatch('weather/callWithFilters', params, {
          root: true,
        });
      } else if (state.currentFilter === 'gisComparator') {
        params = {
          farm: Helpers.applySeparator(
            rootState.gisComparatorFilter.selectedFarms
          ).toString(),
          cultive: Helpers.applySeparator(
            rootState.gisComparatorFilter.selectedCultives
          ).toString(),
          variety: Helpers.applySeparator(
            rootState.gisComparatorFilter.selectedVarieties
          ).toString(),
          parcel: Helpers.applySeparator(
            rootState.gisComparatorFilter.selectedParcels
          ).toString(),
        };

        dispatch('gisComparator/callStoreWithFilters', params, {
          root: true,
        });
      } else if (
        state.currentFilter === 'agronomyNutritionalMonitoring'
      ) {
        params = {
          samplingDate_before: Helpers.getDateFormatedForBackEnd(
            rootState.agronomyNutritionalMonitoringFilter
              .samplingToDate
          ),
          samplingDate_after: Helpers.getDateFormatedForBackEnd(
            rootState.agronomyNutritionalMonitoringFilter
              .samplingFromDate
          ),
          farm_id: Helpers.applySeparator(
            rootState.agronomyNutritionalMonitoringFilter
              .selectedFarms
          ).toString(),
          parcel_id: Helpers.applySeparator(
            rootState.agronomyNutritionalMonitoringFilter
              .selectedParcels
          ).toString(),
          cultive_id: Helpers.applySeparator(
            rootState.agronomyNutritionalMonitoringFilter
              .selectedCrops
          ).toString(),
          variety_id: Helpers.applySeparator(
            rootState.agronomyNutritionalMonitoringFilter
              .selectedVarieties
          ).toString(),
        };

        dispatch('nutritionalFollowing/callWithFilters', params, {
          root: true,
        });
      } else if (state.currentFilter === 'expertSystem') {
        dispatch('expertSystemFilter/filterData', null, {
          root: true,
        });
      } else if (state.currentFilter === 'geotoolbox') {
        dispatch('geotoolbox/filterSamples', null, {
          root: true,
        });
      } else if (state.currentFilter === 'environmentStats') {
        dispatch('environmentStats/filterSamples', null, {
          root: true,
        });
      } else if (state.currentFilter === 'offersList') {
        dispatch('OffersListFilters/callOffersWithFilters', null, {
          root: true,
        });
      } else if (state.currentFilter === 'offersDetails') {
        dispatch(
          'OffersListFilters/callSamplesTypeForFilters',
          null,
          {
            root: true,
          }
        );
      }
    },
    setCurrentSampleId({ commit }, value) {
      commit('SET_CURRENT_SAMPLE_ID', value);
    },
    clearSamplesFilters({ dispatch }) {
      setTimeout(() => {
        dispatch('homeFilter/clearFilters', null, {
          root: true,
        });

        dispatch('agronomyFilter/clearFilters', null, {
          root: true,
        });
        dispatch('foodFilter/clearFilters', null, {
          root: true,
        });

        dispatch('foodDetailsFilter/clearFilters', null, {
          root: true,
        });

        dispatch('environmentFilter/clearFilters', null, {
          root: true,
        });

        dispatch('miningFilter/clearFilters', null, {
          root: true,
        });

        dispatch('healthSecurityFilter/clearFilters', null, {
          root: true,
        });

        dispatch('geotoolbox/clearFilters', null, {
          root: true,
        });
      }, 0);
    },
    clearCurrentFilters({ state, dispatch }) {
      if (state.currentFilter === 'home') {
        dispatch(
          'samples/setAllFilterParams',
          { atc_name: '' },
          {
            root: true,
          }
        );
        dispatch('homeFilter/clearFilters', null, {
          root: true,
        });
      }
      if (state.currentFilter === 'agronomy') {
        dispatch(
          'samples/setAllFilterParams',
          { atc_name: ATCS_ES.agronomy },
          {
            root: true,
          }
        );
        dispatch('agronomyFilter/clearFilters', null, {
          root: true,
        });
      }
      if (state.currentFilter === 'food') {
        dispatch(
          'samples/setAllFilterParams',
          { atc_name: ATCS_ES.food },
          {
            root: true,
          }
        );
        dispatch('foodFilter/clearFilters', null, {
          root: true,
        });
      }
      if (state.currentFilter === 'foodDetails') {
        dispatch('foodDetailsFilter/clearFilters', null, {
          root: true,
        });
      }
      if (state.currentFilter === 'environment') {
        dispatch(
          'samples/setAllFilterParams',
          { atc_name: ATCS_ES.environment },
          {
            root: true,
          }
        );

        dispatch('environmentFilter/clearFilters', null, {
          root: true,
        });
      }
      if (state.currentFilter === 'environmentDetails') {
        dispatch('environmentDetailsFilter/clearFilters', null, {
          root: true,
        });
      }
      if (state.currentFilter === 'advancedServicesPhytomonitoring') {
        dispatch('phitomonitoringPopup/clearFilters', null, {
          root: true,
        });

        return;
      }
      if (state.currentFilter === 'geotoolbox') {
        dispatch('geotoolbox/clearFilters', null, {
          root: true,
        });

        return;
      }
      if (state.currentFilter === 'mining') {
        dispatch(
          'samples/setAllFilterParams',
          { atc_name: ATCS_ES.mining },
          {
            root: true,
          }
        );
        dispatch('miningFilter/clearFilters', null, {
          root: true,
        });
      }
      if (state.currentFilter === 'miningDetails') {
        dispatch('miningDetailsFilter/clearFilters', null, {
          root: true,
        });
      }

      if (state.currentFilter === 'healthSecurity') {
        dispatch(
          'samples/setAllFilterParams',
          { atc_name: ATCS_ES.healthSecurity },
          {
            root: true,
          }
        );
        dispatch('healthSecurityFilter/clearFilters', null, {
          root: true,
        });
      }

      if (state.currentFilter === 'healthSecurityDetails') {
        dispatch('healthSecurityDetailsFilter/clearFilters', null, {
          root: true,
        });
      }

      if (state.currentFilter === 'alertSettingsParams') {
        dispatch('alertSettingsParamsFilter/clearFilters', null, {
          root: true,
        });
      }

      if (state.currentFilter === 'alertSettingsSample') {
        dispatch('alertSettingsSampleFilter/clearFilters', null, {
          root: true,
        });
      }

      if (state.currentFilter === 'alertReportParams') {
        dispatch('alertReportParamsFilter/clearFilters', null, {
          root: true,
        });
      }

      if (state.currentFilter === 'alertReportSample') {
        dispatch('alertReportSampleFilter/clearFilters', null, {
          root: true,
        });
      }

      if (state.currentFilter === 'environmentStats') {
        dispatch('environmentStats/clearFilters', null, {
          root: true,
        });

        return;
      }

      if (state.currentFilter === 'notificationsDetail') {
        dispatch('notificationsDetailFilter/clearFilters', null, {
          root: true,
        });
      }

      if (state.currentFilter === 'riskAnalysis') {
        dispatch('riskAnalysisFilter/clearFilters', null, {
          root: true,
        });

        dispatch('riskAnalysis/clearFilteredData', null, {
          root: true,
        });

        return;
      }

      if (state.currentFilter === 'foodStats') {
        dispatch('foodStatsFilter/clearFilters', null, {
          root: true,
        });

        dispatch('foodStats/clearFilteredData', null, {
          root: true,
        });

        return;
      }

      if (state.currentFilter === 'advancedServices') {
        dispatch('advancedServicesFilter/clearFilters', null, {
          root: true,
        });

        dispatch('advancedServices/clearFilteredData', null, {
          root: true,
        });

        return;
      }

      if (state.currentFilter === 'weatherGraph') {
        dispatch('weatherGraphFilter/clearFilters', null, {
          root: true,
        });

        dispatch('weather/callWithFilters', null, {
          root: true,
        });

        return;
      }

      if (state.currentFilter === 'gisComparator') {
        dispatch('gisComparatorFilter/clearFilters', null, {
          root: true,
        });

        dispatch('gisComparator/callStoreWithFilters', null, {
          root: true,
        });

        return;
      }

      if (state.currentFilter === 'agronomyNutritionalMonitoring') {
        dispatch(
          'agronomyNutritionalMonitoringFilter/clearFilters',
          null,
          {
            root: true,
          }
        );

        dispatch('nutritionalFollowing/clearFilteredData', null, {
          root: true,
        });

        return;
      }

      // TODO: REFACTORIZAR
      if (state.currentFilter.includes('alert')) {
        const names = state.currentFilter.match(/[A-Z][a-z]+/g);

        dispatch(
          `alerts${names[1]}/clearFilteredData${names[0]}`,
          null,
          {
            root: true,
          }
        );
      } else if (state.currentFilter.includes('notification')) {
        dispatch('notifications/clearFilteredDataDetails', null, {
          root: true,
        });
      } else if (state.currentFilter.includes('offersList')) {
        dispatch('OffersListFilters/clearFilters', null, {
          root: true,
        });
      } else if (state.currentFilter.includes('offersDetails')) {
        dispatch('OffersListFilters/clearFilters', null, {
          root: true,
        });
      } else {
        dispatch(
          `${
            state.currentFilter.includes('Details')
              ? state.currentFilter.split('Details')[0]
              : state.currentFilter
          }Samples/${
            state.currentFilter.includes('Details')
              ? 'setFilteredSampleDetails'
              : 'setFilteredSamples'
          }`,
          null,
          {
            root: true,
          }
        );
      }
    },
    setCurrentFilter({ commit }, filter) {
      commit('SET_CURRENT_FILTER', filter);
    },
    applySort({ state }, { sortSettings, isFilter }) {
      let params = '';

      const sortSettingsFiltered = sortSettings.filter(
        (sortSetting) => sortSetting.selector !== 'id'
      );
      if (isFilter) {
        params = {};

        sortSettingsFiltered.forEach((column) => {
          params.sort = `${column.desc ? '-' : ''}${column.selector}`;
        });
      } else if (sortSettingsFiltered.length > 0) {
        params = '&ordering=';

        sortSettingsFiltered.forEach((column) => {
          params += `${column.desc ? '-' : ''}${column.selector},`;
        });
      }

      return params;
    },
  },
  mutations: {
    SET_CURRENT_FILTER(state, value) {
      state.currentFilter = value;
    },
    SET_CURRENT_SAMPLE_ID(state, value) {
      state.currentSampleId = value;
    },
  },
};
