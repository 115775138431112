<template>
  <div class="tableContainer">
    <div>
      <div class="filterButtonPosition" style="right: 64px">
        <f7-button
          fill
          class="whiteFontColor button-filter"
          panel-toggle="right"
        >
          <span class="ic-filter"></span> {{ $t('filter') }}
        </f7-button>
      </div>
      <DxDataGrid
        :data-source="store"
        :show-borders="true"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-colon="false"
        :hover-state-enabled="true"
        :remote-operations="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        :no-data-text="$t('noDataTextDetail')"
        @exporting="onExporting"
        @toolbar-preparing="onToolbarPreparing"
        @content-ready="init"
      >
        <DxScrolling mode="standard" />
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="foodSamplesDetailNoRpTable"
        />
        <DxSelection mode="single" />
        <DxPaging :page-size="10" />
        <DxPager
          :show-page-size-selector="true"
          :allowed-page-sizes="[5, 10, 20]"
          :show-info="true"
          info-text="{2} items"
          :visible="true"
        />

        <DxSearchPanel
          :visible="true"
          :width="300"
          :placeholder="$t('search')"
        />

        <DxColumn
          data-field="lmr_semaphore"
          caption=""
          header-cell-template="cellTemplateHeaderDots"
          :width="35"
          :allow-sorting="false"
          cell-template="cellTemplateDots"
          :fixed="true"
          fixed-position="left"
        />

        <template #cellTemplateDots="{ data }">
          <div class="dots-parent-container">
            <img
              width="20"
              height="20"
              :src="getDotColorImage(data.value)"
            />
          </div>
        </template>

        <DxColumn
          data-field="parameter_group"
          :caption="$t('group')"
          alignment="left"
          :allow-header-filtering="false"
        />
        <DxColumn
          data-field="parameter"
          :caption="$t('foodSamples.parameter')"
          alignment="left"
          :allow-header-filtering="false"
        />

        <DxColumn
          data-field="value.ResultadoPresentacion"
          cell-template="cellTemplateResultadoPresentacion"
          :caption="$t('foodSamples.result')"
          alignment="left"
          :allow-header-filtering="false"
        />

        <template #cellTemplateResultadoPresentacion="{ data }">
          <div>
            {{
              data.data.value.ResultadoPresentacion.includes(
                'En Proceso'
              )
                ? $t('inProcess')
                : data.data.value.ResultadoPresentacion
            }}
          </div>
        </template>

        <DxColumn
          data-field="value.unit"
          :caption="$t('foodSamples.unit')"
          alignment="left"
          :allow-header-filtering="false"
        />

        <DxColumn
          :caption="$t('foodSamples.range')"
          alignment="left"
          :allow-header-filtering="false"
          :calculate-cell-value="calculateRange"
        />

        <DxColumn
          :caption="$t('cma')"
          data-field="cma[0].CMASuperior"
          alignment="left"
          :allow-header-filtering="false"
        />

        <DxExport :enabled="true" />
        <template #cellTemplateHeaderDots="{ data }">
          <div>
            <PopUp position="top">
              <div style="padding: 10px">
                <div class="pop-up-info">
                  <img
                    :src="getDotImage('grey')"
                    width="15"
                    height="15"
                  />
                  <div style="padding-left: 5px">
                    {{
                      $t('foodSamples.detailNotRP.greyDotDescription')
                    }}
                  </div>
                </div>
                <div class="pop-up-info">
                  <img
                    :src="getDotImage('green')"
                    width="15"
                    height="15"
                  />
                  <div style="padding-left: 5px">
                    {{
                      $t(
                        'foodSamples.detailNotRP.greenDotDescription'
                      )
                    }}
                  </div>
                </div>
                <div class="pop-up-info">
                  <img
                    :src="getDotImage('red')"
                    width="15"
                    height="15"
                  />
                  <div style="padding-left: 5px">
                    {{
                      $t('foodSamples.detailNotRP.redDotDescription')
                    }}
                  </div>
                </div>
                <div class="pop-up-info">
                  <img
                    :src="getDotImage('orange')"
                    width="15"
                    height="15"
                  />
                  <div style="padding-left: 5px">
                    {{
                      $t('foodSamples.summary.orangeDotDescription')
                    }}
                  </div>
                </div>
              </div>
            </PopUp>
          </div>
        </template>
      </DxDataGrid>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxStateStoring,
  DxPager,
  DxPaging,
  DxScrolling,
  DxExport,
  DxSelection,
  DxSearchPanel,
} from 'devextreme-vue/data-grid';

import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import PopUp from '@/components/UtilityComponents/popUpInfo/popUp.vue';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxStateStoring,
    DxPager,
    DxPaging,
    DxScrolling,
    DxExport,
    DxSelection,
    DxSearchPanel,
    PopUp,
  },
  props: {
    store: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState('samples', ['reportSampleCode']),
  },
  methods: {
    getDotImage(color) {
      return require(`../../../../../../assets/dot-colors/${color}.svg`)
        .default;
    },
    getDotColorImage(color) {
      return require(`../../../../../../assets/dot-colors/${color}.svg`)
        .default;
    },
    defaultNoValue() {
      return 0;
    },
    init(e) {
      this.setSampleDetails(e.component.getDataSource().items());
    },
    calculateRange(rowData) {
      return `${rowData.value.r_min} - ${rowData.value.r_max}`;
    },
    onExporting(e) {
      e.component.beginUpdate();
      e.component.columnOption('ID', 'visible', true);
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(
        `${`${this.$t(
          'agronomySample.summary.sampleSummary'
        )}-${this.$t('Food')}`} No RP`
      );

      exportDataGrid({
        component: e.component,
        worksheet,
      })
        .then(() => {
          workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(
              new Blob([buffer], {
                type: 'application/octet-stream',
              }),
              `besafer-${this.reportSampleCode}.xlsx`
            );
          });
        })
        .then(() => {
          e.component.columnOption('ID', 'visible', false);
          e.component.endUpdate();
        });

      e.cancel = true;
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items;

      toolbarItems.forEach((item) => {
        if (item.name === 'searchPanel') {
          item.location = 'before';
        }
        if (item.name === 'exportButton') {
          item.location = 'after';
        }

        if (item.name === 'groupPanel') {
          item.location = 'after';
        }
      });
    },
    ...mapActions('foodSamples', ['setSampleDetails']),
  },
};
</script>

<style scoped>
::v-deep .dx-toolbar-after {
  left: initial !important;
}
</style>
