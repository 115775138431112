<template>
  <div>
    <div class="filterButtonPosition">
      <f7-button
        fill
        class="whiteFontColor button-filter"
        panel-toggle="right"
      >
        <span class="ic-filter"></span> {{ $t('filter') }}
      </f7-button>
    </div>
    <DxDataGrid
      :data-source="store"
      :show-borders="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :show-colon="false"
      :remote-operations="true"
      :hover-state-enabled="true"
      :focused-row-enabled="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :auto-navigate-to-focused-row="autoFocus"
      @contentReady="init"
      @focused-row-changed="focusedRowChanged"
    >
      <DxScrolling mode="standard" />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="alertsReportsParamsTable"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="[5, 10, 20]"
        :show-info="true"
        info-text="{2} items"
        :visible="true"
      />

      <DxSearchPanel
        :visible="true"
        :width="300"
        :placeholder="$t('search')"
      />

      <DxColumn
        data-field="atc"
        :caption="$t('alert.area')"
        alignment="left"
        :allow-header-filtering="false"
      />

      <DxColumn
        data-field="sample_type"
        :caption="$t('agronomySample.summary.sampleType')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="farm"
        :caption="$t('foodSamples.samplingSite')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="parcel"
        :caption="$t('environmentSamples.samplingPoint')"
        alignment="left"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="created_at"
        :caption="$t('alert.creationDate')"
        alignment="left"
        data-type="date"
        format="dd/MM/yyyy"
        :allow-header-filtering="false"
      />

      <DxColumn
        data-field="parameters"
        :caption="$t('alert.parameters')"
        alignment="left"
        :allow-header-filtering="false"
        cell-template="cellTemplateParams"
      />
      <DxColumn
        :buttons="editButtons"
        :fixed="true"
        fixed-position="right"
        type="buttons"
        width="90"
      />

      <template #cellTemplateParams="{ data }">
        <div style="min-width: 100px">
          {{
            data.value.flatMap((param) => param.parameter).toString()
          }}
        </div>
      </template>
    </DxDataGrid>

    <ParamsDetailsPopup v-if="showReportPopup" :details="details" />
  </div>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxStateStoring,
  DxPager,
  DxPaging,
  DxScrolling,
} from 'devextreme-vue/data-grid';

import { mapActions, mapState } from 'vuex';

import notify from 'devextreme/ui/notify';
import ParamsDetailsPopup from '@/components/Alerts/Reports/DetailsPopup/Params/index.vue';
import EventBus from '@/services/event-bus';
import Helpers from '@/js/helpers';
import { NOTIFY_TIME_LONG } from '@/js/constants';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxStateStoring,
    DxPager,
    DxPaging,
    DxScrolling,
    ParamsDetailsPopup,
  },
  props: {
    store: {
      type: Object,
      default: () => {},
    },
    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      editButtons: [
        {
          icon: 'find',
          onClick: this.showDetails,
        },
        {
          icon: 'trash',
          onClick: this.delete,
        },
      ],
      details: [],
    };
  },
  computed: {
    autoFocus() {
      return Helpers.isEmptyObject(this.params);
    },
    ...mapState('alertsParams', [
      'showReportPopup',
      'focusedReportRowKey',
    ]),
  },
  methods: {
    init(e) {
      e.component.option('focusedRowKey', this.focusedReportRowKey);
      this.changedFocused = true;
    },
    focusedRowChanged(row) {
      if (!row.row) return;

      if (this.changedFocused) {
        if (Helpers.isEmptyObject(this.params)) {
          this.setReportFocusedRowKey(row.row.data.id);
        }
      }
    },
    delete(data) {
      this.$f7.dialog.confirm(
        `${this.$t('reports.deleteConfirm')}`,
        async () => {
          const { id } = data.row.data;

          try {
            notify(
              {
                message: this.$t('reports.deleting'),
                width: 450,
              },
              'info',
              2000
            );

            await this.deleteAlertReport(id);

            notify(
              {
                closeOnClick: true,
                message: this.$t('reports.deleted'),
                width: 450,
              },
              'success',
              2000
            );

            EventBus.$emit('AlertsParamsReportsPage', this.params);

            this.$f7.dialog.close();
          } catch (error) {
            this.$notifyDX(
              {
                message: this.$t(
                  this.$helpers.getFilteredErrorMessage(
                    JSON.parse(error.message).error
                  )
                ),
                width: 550,
              },
              'error',
              NOTIFY_TIME_LONG
            );
          }
        }
      );
    },
    showDetails(e) {
      const { data } = e.row;
      this.details = data;

      this.setShowReportPopup(true);
    },
    ...mapActions('alertsParams', [
      'setShowReportPopup',
      'deleteAlertReport',
      'setReportFocusedRowKey',
    ]),
  },
};
</script>
