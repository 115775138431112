import Api from '@/services/Api';

export default {
  namespaced: true,
  state: {
    showNewAlertPopup: false,
    showNewAlertSamplePopup: false,
  },
  actions: {
    setShowNewAlertPopup({ commit }, value) {
      commit('setShowNewAlertPopup', value);
    },
    setShowNewAlertSamplePopup({ commit }, value) {
      commit('setShowNewAlertSamplePopup', value);
    },
  },
  mutations: {
    setShowNewAlertPopup(state, value) {
      state.showNewAlertPopup = value;
    },
    setShowNewAlertSamplePopup(state, value) {
      state.showNewAlertSamplePopup = value;
    },
  },
};
