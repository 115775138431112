var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loaded)?_c('div',{staticClass:"tableContainer marginTop15",staticStyle:{"border-radius":"5px"}},[_c('f7-row',[_c('f7-col',[_c('DxForm',{staticClass:"ha-form marginTop15",staticStyle:{"padding":"5px"},attrs:{"col-count":"1","form-data":_vm.formData}},[_c('DxItem',{attrs:{"editor-options":{
            items: _vm.formData.farms,
            onValueChanged: _vm.farmChanged,
            value: _vm.isEditingFarm ? _vm.farmStr : _vm.farm,
            width: '235px',
            valueExpr: _vm.isEditingFarm ? 'farm' : 'id',
            displayExpr: 'name',
            disabled: _vm.isEditingFarm,
            placeholder: _vm.$t('select'),
          },"data-field":"","editor-type":"dxSelectBox"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('alert.requiredFarm')}}),_vm._v(" "),_c('DxLabel',{attrs:{"text":_vm.$t('agronomySample.summary.estate')}})],1),_vm._v(" "),_c('DxItem',{attrs:{"editor-options":{
            onValueChanged: _vm.accountableChanged,
            value: _vm.accountable,
            width: '235px',
            disabled: _vm.isEditingFarm,
            placeholder: _vm.$t('select'),
          },"data-field":"","editor-type":"dxTextBox"}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('accountableRequired')}}),_vm._v(" "),_c('DxLabel',{attrs:{"text":_vm.$t('accountable')}})],1)],1)],1),_vm._v(" "),_c('f7-col'),_vm._v(" "),_c('f7-col'),_vm._v(" "),_c('f7-col'),_vm._v(" "),_c('f7-col',{staticStyle:{"margin-top":"15px"}},[_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('latitude'))+":\n        "),_c('strong',[_vm._v(_vm._s(_vm.centroide ? _vm.centroide.lat.toFixed(6) : ''))])]),_vm._v(" "),_c('br'),_vm._v(" "),_c('span',{staticStyle:{"margin-top":"25px"}},[_vm._v("\n        "+_vm._s(_vm.$t('longitude'))+":\n        "),_c('strong',[_vm._v(_vm._s(_vm.centroide ? _vm.centroide.lng.toFixed(6) : ''))])]),_vm._v(" "),_c('br'),_vm._v(" "),_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('surface'))+" (ha):\n        "),_c('strong',[_vm._v(_vm._s(_vm.area ? _vm.area.toFixed(2) : ''))])])])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }