<template>
  <div class="tableContainer">
    <div style="position: absolute; right: 20px">
      <DxButton
        v-if="!enabled"
        :width="100"
        class="standardBtn"
        type="normal"
        styling-mode="outlined"
        @click="displayPopup"
      >
        <i class="f7-icons standardIconWidth">cloud_upload</i>
        {{ $t('upload') }}
      </DxButton>
    </div>
    <div style="margin-bottom: 10px">
      <f7-row>
        <f7-col>
          <DxTextBox
            :value="searchText"
            :placeholder="$t('search')"
            @value-changed="onSearch"
          />
        </f7-col>
        <f7-col>
          <div class="padding-half recepDate width-200">
            <i class="f7-icons"> calendar </i>
            <f7-input
              class="shadow"
              type="datepicker"
              :placeholder="$t('chooseUploadDates')"
              :clear-button="true"
              :close-on-select="true"
              :value="setUploadFileDate"
              :calendar-params="{
                closeOnSelect: true,
                rangePicker: true,
                dateFormat: {
                  month: 'numeric',
                  day: 'numeric',
                  year: 'numeric',
                },
              }"
              @calendar:change="setUploadFileDateChange"
            />
          </div>
        </f7-col>
        <f7-col></f7-col>
        <f7-col></f7-col>
      </f7-row>
    </div>

    <DxFileManager
      :file-system-provider="fsProvider ? fsProvider : fileSystem"
      :customize-thumbnail="customizeIcon"
      @itemDownloading="download"
      @contextMenuShowing="contextMenuShowing"
      @contextMenuItemClick="contextMenuItemClick"
      @selectionChanged="selectionChanged"
      @initialized="init"
      @itemDeleting="deleteData"
      @currentDirectoryChanged="setAddressOnDirectoryChanged"
    >
      <DxPermissions :delete="!enabled" :download="true" />

      <DxItemView :show-parent-folder="false">
        <DxDetails>
          <DxColumn data-field="thumbnail" />
          <DxColumn data-field="name" :caption="$t('name')" />
          <DxColumn
            data-field="contract_number"
            alignment="center"
            :caption="$t('contractNum')"
          />
          <DxColumn
            data-field="description"
            alignment="center"
            :caption="$t('agronomySample.summary.description')"
          />
          <DxColumn
            data-field="author"
            alignment="center"
            :caption="$t('owner')"
          />
          <DxColumn
            data-field="updated_at"
            alignment="center"
            :caption="$t('lastUpdated')"
            :width="150"
          />
          <DxColumn
            data-field="size"
            alignment="center"
            :caption="$t('size')"
            :width="150"
          />
          <DxColumn
            data-field="extension"
            alignment="center"
            :caption="$t('format')"
          />
        </DxDetails>
      </DxItemView>

      <DxToolbar :file-selection-items="selectionItems">
        <DxItem name="switchView" location="before" />
        <DxItem name="separator" />
        <DxItem name="refresh" location="before" icon="refresh" />
      </DxToolbar>
    </DxFileManager>

    <UploadFormPopup
      v-if="showUploadFormPopup"
      :edit-data="editData"
      :address="currentAddress"
    />
  </div>
</template>

<script>
import {
  DxFileManager,
  DxPermissions,
  DxToolbar,
  DxItem,
  DxItemView,
  DxDetails,
  DxColumn,
} from 'devextreme-vue/file-manager';

import DxTextBox from 'devextreme-vue/text-box';
import DxButton from 'devextreme-vue/button';
import EventBus from '@/services/event-bus';
import { mapActions, mapState } from 'vuex';
import isWithinInterval from 'date-fns/isWithinInterval';
import notify from 'devextreme/ui/notify';
import UploadFormPopup from '../UploadFormPopup/index.vue';

export default {
  components: {
    DxFileManager,
    DxPermissions,
    DxToolbar,
    DxItem,
    DxItemView,
    DxDetails,
    DxColumn,
    DxTextBox,
    DxButton,
    UploadFormPopup,
  },
  props: {
    fileSystem: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectionItems: [
        {
          name: 'clearSelection',
          location: 'after',
          text: this.$t('clearSelection'),
        },
        {
          name: 'download',
          location: 'after',
          text: this.$t('download'),
        },
        {
          name: 'delete',
          location: 'after',
          text: this.$t('delete'),
        },
      ],
      searchText: '',
      fsProvider: null,
      fsProviderUpdated: null,
      uploadDateTo: null,
      uploadDateFrom: null,
      editData: {},
      selectedItems: 0,
      currentAddress: '',
      environment: process.env.NODE_ENV,
    };
  },
  computed: {
    setUploadFileDate() {
      return this.uploadDateFrom !== null &&
        this.uploadDateTo !== null
        ? [this.uploadDateFrom, this.uploadDateTo]
        : [];
    },
    ...mapState('documentation', ['showUploadFormPopup']),
    ...mapState('contact', ['enabled']),
  },
  beforeMount() {
    EventBus.$on('fileUploaded', (data) => {
      this.fsProvider = data;
      this.fsProviderUpdated = data;
    });

    this.fsProviderUpdated = this.fileSystem;
  },
  methods: {
    contextMenuShowing(e) {
      if (
        this.selectedItems >= 2 &&
        e.fileSystemItem !== undefined &&
        !e.fileSystemItem.dataItem.isDirectory
      ) {
        e.component.option('contextMenu.items', [
          {
            name: 'delete',
            text: this.$t('delete'),
          },
          {
            name: 'download',
            icon: 'download',
            text: this.$t('download'),
          },
        ]);

        return;
      }

      if (
        e.fileSystemItem !== undefined &&
        !e.fileSystemItem.dataItem.isDirectory
      ) {
        e.component.option('contextMenu.items', [
          {
            name: 'delete',
            text: this.$t('delete'),
          },
          {
            name: 'edit',
            icon: 'edit',
            text: this.$t('advancedEdit'),
            visible: !this.enabled,
          },
          {
            name: 'download',
            icon: 'download',
            text: this.$t('download'),
          },
        ]);
      } else {
        e.component.option('contextMenu.items', ['refresh']);
      }
    },
    contextMenuItemClick(e) {
      if (e.itemData.name === 'edit') {
        this.editData.isEditing = true;

        const itemData = e.fileSystemItem.dataItem;

        this.editData.data = {};

        this.editData.data.id = itemData.id;
        this.editData.data.name = itemData.name;
        this.editData.data.contractNumber = itemData.contract_number;
        this.editData.data.description = itemData.description;
        this.editData.data.emails =
          itemData.emails.length > 0
            ? itemData.emails.split(',')
            : [];

        this.setUploadFormPopup(true);
      }
    },
    selectionChanged(e) {
      if (!this.enabled) {
        if (
          e.selectedItems.length > 0 &&
          !e.selectedItems[0].isDirectory
        ) {
          this.currentAddress =
            e.selectedItems[0].dataItem.address_code;
        }
      }
      this.selectedItems = 0;

      e.selectedItems.forEach((item) => {
        if (!item.isDirectory) {
          this.selectedItems += 1;
        }
      });
    },
    download(e) {
      e.cancel = true;
      const attachedFile = e.item.dataItem.attached_file;

      const spplited = attachedFile.split(';base64,');

      const link = document.createElement('a');
      link.href = `${spplited[0]}/octet-stream;base64,${spplited[1]}`;
      link.download = `${e.item.name}.${e.item.dataItem.extension}`;
      link.click();

      URL.revokeObjectURL(link.href);

      notify(
        {
          message: this.$t('downloadedFile'),
          width: 450,
        },
        'success',
        1500
      );
    },
    async deleteData(e) {
      await this.deleteFile(e.item.dataItem.id);
      this.fsProviderUpdated = this.fileSystem
        ? this.fileSystem
        : this.fsProvider;
      notify(
        {
          message: this.$t('deletedFile'),
          width: 450,
        },
        'success',
        1500
      );
    },
    displayPopup() {
      this.editData = {};
      this.setUploadFormPopup(true);
    },
    onSearch(e) {
      this.searchText = e.value;

      if (this.searchText.length > 0) {
        this.fsProvider = this.findNode(
          this.searchText.toLowerCase(),
          this.fsProviderUpdated
        );
      } else {
        this.fsProvider = this.fsProviderUpdated;
      }
    },
    findNode(searchText, array) {
      const res = [];

      for (const node of array) {
        if (!node.isDirectory) {
          if (
            node.filename.toLowerCase().includes(searchText) ||
            node.extension.toLowerCase().includes(searchText) ||
            node.contract_number.toLowerCase().includes(searchText) ||
            node.description.toLowerCase().includes(searchText) ||
            node.author.toLowerCase().includes(searchText)
          )
            res.push(node);
        }

        if (node.items) {
          const child = this.findNode(searchText, node.items);
          if (child) {
            res.push(...child);
          }
        }
      }

      return res;
    },
    init(e) {
      if (!this.enabled) {
        if (this.fileSystem.length > 0) {
          this.currentAddress = this.fileSystem[0].items[0].code;
        }
      }
    },
    setAddressOnDirectoryChanged(e) {
      if (!this.enabled) {
        if (e.directory.dataItem) {
          if (!e.directory.dataItem.isRoot) {
            this.currentAddress = e.directory.dataItem.code;
          } else {
            this.currentAddress = this.fileSystem[0].items[0].code;
          }
        }
      }
    },
    setUploadFileDateChange(e) {
      if (e.length === 2) {
        this.uploadDateFrom = e[0];
        this.uploadDateTo = e[1];

        this.applyDateFilter();
      } else if (e.length === 0) {
        this.uploadDateFrom = null;
        this.uploadDateTo = null;

        this.fsProvider = this.fsProviderUpdated;
      }
    },
    applyDateFilter() {
      const dateFrom = this.$dates
        .formatDateDayMonthYear(this.uploadDateFrom)
        .split('/');

      const dateTo = this.$dates
        .formatDateDayMonthYear(this.uploadDateTo)
        .split('/');

      const dataFiltered = this.findNode('', this.fsProviderUpdated);
      const data = dataFiltered.filter((file) => {
        const fileDateSplitted = file.updated_at.split('/');
        fileDateSplitted[fileDateSplitted.length - 1] =
          fileDateSplitted[fileDateSplitted.length - 1].split(' ')[0];

        return isWithinInterval(
          new Date(
            fileDateSplitted[2],
            fileDateSplitted[1],
            fileDateSplitted[0]
          ),
          {
            start: new Date(dateFrom[2], dateFrom[1], dateFrom[0]),
            end: new Date(dateTo[2], dateTo[1], dateTo[0]),
          }
        );
      });

      this.fsProvider = data;
    },
    customizeIcon(fileSystemItem) {
      if (fileSystemItem.dataItem.isDirectory) {
        return `${
          this.environment === 'development' ? '../../' : './'
        }static/img/file-icons/folder.svg`;
      }

      return `${
        this.environment === 'development' ? '../../' : './'
      }static/img/file-icons/${
        fileSystemItem.dataItem.extension
      }.svg`;
    },
    ...mapActions('documentation', [
      'setUploadFormPopup',
      'deleteFile',
    ]),
  },
};
</script>
